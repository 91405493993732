import { updateFavorited } from "./profile";

export const SEARCH_USERS_START = 'search/UsersStart'
export const SEARCH_USERS_SUCCESS = 'search/UsersSuccess'
export const SEARCH_USERS_FAIL = 'search/UsersFail'

export const UPDATE_FAV_OF_STORED_SEARCH_PROFILES =  'search/UPDATE_FAV_OF_STORED_SEARCH_PROFILES'

const PER_PAGE = 40

const initialState = {
	usersSearchResults: [],
	isSearchingUsers: false,
}

export default function search(state = initialState, action) {
	switch (action.type) {
		case SEARCH_USERS_START:
			return {
				...state,
				isSearchingUsers: true,
			}

		case SEARCH_USERS_SUCCESS:
			return {
				...state,
				isSearchingUsers: false,
				usersSearchResults:
					action.page === 1 ? action.result.data.data : state.usersSearchResults.concat(action.result.data.data),
			}

		case SEARCH_USERS_FAIL:
			return {
				...state,
				isSearchingUsers: false,
			}
		default:
			return state
	}
}

export function searchUsers(q, page) {
	const qs = `?${q ? `${q}&` : ''}per_page=${PER_PAGE}`

	return {
		page,
		types: [SEARCH_USERS_START, SEARCH_USERS_SUCCESS, SEARCH_USERS_FAIL],
		promise: client => client.get(`/api/search/usersByCategoryName${qs}`),
	}
}
