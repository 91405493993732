import React, { Component } from 'react'
import '../../../styles/components/band/BandContactInfo.scss'
import { Field, reduxForm } from 'redux-form'
import { RadioButton, RadioButtonGroup } from 'material-ui/RadioButton'
import { Categories } from '../Category'
import RaisedButton from 'material-ui/RaisedButton'
import validate from '../../../helpers/validators/group/PlansAndTalents'
import '../../../styles/components/band/PlansAndBandGenre.scss'
import table from '../../../assets/table.png'
import { connect } from 'react-redux'
import { getValues } from '../../../helpers/methods'
import classnames from 'classnames'
import { Asterisk } from '../../common/Asterisk/Asterisk'
import { registrationConfig } from '../../../config'

class PlansAndGroupTalents extends Component {
	constructor(props) {
		super(props)

		this.state = {
			isOpened: true,
			plans: '',
    }
	}

	onCheckPlan(value) {
		window.scrollTo(0, 0)
		this.setState({
			plans: value,
			isOpened: false,
		})
	}

	togglePlansMenu(isOpened) {
		window.scrollTo(0, 0)
		this.setState({
			isOpened,
		})
	}

	render() {
		const { handleSubmit, initialValues } = this.props

		return (
			<form onSubmit={handleSubmit} className="plans-and-venue-profile">
				<div>
					<div className={classnames('band-contact-info__info', { filled: initialValues.categories })}>
						Group talent & Styles
					</div>
					<div className="band-genre-form__wrapper">
						<Field name="categories" type="group.basic" categoryTypeText={'group talent'} component={Categories} />
					</div>
				</div>
			</form>
		)
	}
}

PlansAndGroupTalents = reduxForm({
	form: 'PlansAndGroupTalents',
	destroyOnUnmount: false,
	forceUnregisterOnUnmount: false,
	validate,
})(PlansAndGroupTalents)

export default connect(({ user, form }) => ({
	currentUser: user.currentUser,
	initialValues: getValues(
		form.PlansAndGroupTalents,
		user.currentUser
			? {
					categories: user.currentUser.categories.length
						? getCategoriesFromUser(user.currentUser)
						: [
								{
									id: '',
									name: '',
									styles: [],
								},
						  ],
			  }
			: { categories: [{ id: '', name: '', styles: [] }] }
	),
}))(PlansAndGroupTalents)

//for now support only one category
function getCategoriesFromUser(user) {
	let userCat = [...user.categories]
	// const userCat = [user.categories[0]]   // workaround for 2405
	const userStyles = [...user.styles]

  userCat = userCat.filter(category => category.type === "talent");

  return userCat.map(cat => ({
		id: cat.id,
		name: cat.name,
		styles: userStyles.filter(s => s.category_id == cat.id).map(style => ({ name: style.name, type: style.type, top_position: style.top_position })),
  }))
}
