  import './ArtistGeneralCategoryBlock.scss';
  import React, { Component } from 'react';
  import PropTypes from 'prop-types';
  import ProfileCard from '../../../common/ProfileCard/ProfileCard';
  import { withRouter } from 'react-router';
  import { RaisedButton } from 'material-ui';
  import { connect } from 'react-redux';
  import { IMAGES_CATEGORY } from '../../../discover/GigFeedTab/PrimaryGigFeed/PrimaryGigFeed';
  import {translate} from "../../../../translator";

  const PLAN_ORDER = {
    premium: 1,
    pro: 2,
    free: 3
  };

  @connect(({ compare, globalConfig }) => ({
    itemsToCompare: compare.itemsToCompare,
    globalConfig: globalConfig
  }))
    class ArtistGeneralCategoryBlock extends Component {
    static propTypes = {
      onAddToCompare: PropTypes.func
    };

    constructor(props) {
      super(props);
    }

    redirectToSpecificCategory = (categoryName) => {
      const { artisticCategory, rootCategoryName } = this.props

      // ToDo refactor code
      const isProductionCategory = false; //artisticCategory && artisticCategory.category && artisticCategory.category.name === 'Production' && rootCategoryName !== 'production'
      if( isProductionCategory && this.props.changeCategory ) {
        this.props.changeCategory('production')
      }
      else {
        const rootUndefined = (this.props.rootCategoryName === undefined)
        const isArtistOrProd = rootUndefined || (rootCategoryName === 'artists' || rootCategoryName === 'production')
        this.props.history.push(`/discover/${isArtistOrProd ? 'artists' : this.props.rootCategoryName }/${categoryName}`);
      }
    };

    sortUsersByPlanWeight = (users, planOrder) => {
      return users.sort(function(x,y) {
        const xPlan = x.name.split(' ');
        const yPlan = y.name.split(' ');

        return planOrder[xPlan[0].toLowerCase()] - planOrder[yPlan[0].toLowerCase()];
      });
    };

    render() {
      const { artisticCategory: { category, users }, globalConfig, history } = this.props;

      const orderedUsers = this.sortUsersByPlanWeight(users, PLAN_ORDER);
      const HEADER_ARTIST_CATEGORY = {
        'Visual Arts': 'Photographer: Laura Vanselow<br/>Model: Denise Ba',
        'Music': 'Musician: Xingye Li',
        'Performing Arts': 'Performer: Mika Hula Hoop',
        'Production': 'Videographer: Francisco Fa',
      };
      const DESRIPTION_ARTIST_CATEGORY = {
        'Visual Arts': 'A photograph says a thousand words. Better yet, a video is a thousand photographs. ' +
          '<b>Find your visual story.</b>',
        'Music': 'Spice up the mood of your event. <b>Find the musicians for any occasion.</b>',
        'Performing Arts': 'Elegant dancers, dramatic actors, glamorous models, funny comedians. ' +
          '<b>What else do you need?</b>',
        'Production': 'Need someone to edit videos or photographs? Need a spotlight for a performer? ' +
          'Hire a director for your film.<b> They\'re ready when you are.<b/>',
      };
      let categoryName = category.name.replace(/\s/g, '');
      return (
        <div className={"ArtistCategoryBlock category-block" + (this.props.fullWidth ? " full-width" : '')}>
          <div className="size-block">
            <div className="container full-width d-flex">
              <div
                style={{
                  backgroundImage: `url(${IMAGES_CATEGORY.artistCategories[category.name]})`,
                }}
                onClick={() => globalConfig.isMobile && this.redirectToSpecificCategory(categoryName)}
                className="category-description">
                <div
                  className='credits'
                  dangerouslySetInnerHTML={{ __html: HEADER_ARTIST_CATEGORY[category.name] }} />
                <h1>
                  {category.name === 'Artist Occasions' ? 'Occasions' : category.name}
                </h1>
                <div
                  className='description'
                  dangerouslySetInnerHTML={{ __html: DESRIPTION_ARTIST_CATEGORY[category.name] }} />
                <RaisedButton
                  className='view-all-button'
                  style={{
                    width: `${350 / 16 / 2}rem`,
                    height: `${100 / 16 / 2}rem`,
                    lineHeight: `${100 / 16 / 2}rem`,
                    backgroundColor: '#ebebeb',
                    color: '#000'
                  }}
                  labelStyle={{
                    fontSize: '.9rem',
                    fonwWeight: 'bold'
                  }}
                  onClick={() => this.redirectToSpecificCategory(categoryName)}>
                  {translate('discover.ArtistsVisualArtsDiscoverMore')}
                </RaisedButton>
              </div>
              <div className="category-artists">
                {
                  orderedUsers
                    .map((user, idx) => {
                      if (user && user.is_complete) {
                        return (
                          <ProfileCard
                            key={idx}
                            profile={user}
                            history={history}
                          />)
                      }
                    })
                }
              </div>
            </div>
          </div>
        </div>
      );
    }
  }

  export default withRouter(ArtistGeneralCategoryBlock);
