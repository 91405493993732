import reduce from 'lodash/reduce'

export const routes = {
	login: 'login',
	logout: 'logout',
	register: 'register',
	users: 'api/users',
	profile: 'profile',
  forgotPassword: 'api/password/email',
  resetPassword: 'api/password/reset'
}

export const SMALL_BREAKPOINT_MAX = 519
export const MEDIA_WIDTH = 767 // bootstrap min md min width: 768px
export const TABLET_WIDTH = 991 // bootstrap min lg width: 992px
export const LAPTOP_WIDTH = 1199 // bootstrap min xl width 1200

export const MAX_BRIEF_INTRODUCTION_LENGTH = 155
export const MAX_ABOUT_INTRODUCTION_LENGTH = 1000

export const PRIMARY_COLOR = '#ffd210'
export const LINK_COLOR = '#0056b3'
export const SUCCESS_COLOR = '#64a75c'
export const DANGER_COLOR = '#a50104'
export const WARNING_COLOR = '#dba522'

export const GREY_COLOR_LIGHTER = '#f5f5f5';
export const GREY_COLOR_DARKER = '#4a4a4a';

export const MIN_BUDGET = 0
export const MAX_BUDGET = 10000

export const PAYMENT_CYCLES = {
	'3_MONTHS': '3_MONTHS',
	'6_MONTHS': '6_MONTHS',
	'12_MONTHS': '12_MONTHS',
	'1_MONTH': '1_MONTH',
}

export const SEPA_COUNTRIES = [
	'AD',
	'AT',
	'BE',
	'BG',
	'HR',
	'CY',
	'CZ',
	'DK',
	'EE',
	'FI',
	'FR',
	'DE',
	'GR',
	'HU',
	'IS',
	'IE',
	'IT',
	'LV',
	'LI',
	'LT',
	'LU',
	'MT',
	'MC',
	'NL',
	'NO',
	'PL',
	'PT',
	'RO',
	'SM',
	'SK',
	'SI',
	'ES',
	'SE',
	'CH',
	'GB'
]

export const NOTIFICATIONS_STATUSES = {
	GIG_APPLY: 'GIG_APPLY',
    GIG_NEW_APPLY: 'GIG_NEW_APPLY',
    GIG_V_INVITE_APPLY: "GIG_V_INVITE_APPLY",
	GIG_V_INVITE_ACCEPT: 'GIG_V_INVITE_ACCEPT',
	GIG_V_INVITE_DECLINE: 'GIG_V_INVITE_DECLINE',
	GIG_BOOK: 'GIG_BOOK',
	INSTANT_BOOKING: 'INSTANT_BOOKING',
	GIG_BOOK_CONFIRM : 'GIG_BOOK_CONFIRM',
    GIG_BOOK_DECLINED: 'GIG_BOOK_DECLINED',
    GIG_DISPUTE: 'GIG_DISPUTE',
	GIG_CONTRACT_CREATED : 'GIG_CONTRACT_CREATED',
	GIG_VENDOR_SIGN : 'GIG_VENDOR_SIGN',
	GIG_CLIENT_SIGN : 'GIG_CLIENT_SIGN',
	GIG_CLIENT_PAID : 'GIG_CLIENT_PAID',
	GIG_C_CONTRACT_NOT_SIGN: 'GIG_C_CONTRACT_NOT_SIGN',
	GIG_V_CONTRACT_NOT_SIGN: 'GIG_V_CONTRACT_NOT_SIGN',
	GIG_CANCELED: 'GIG_CANCELED',
	GIG_CHANGE_TO_V: 'GIG_CHANGE_TO_V',
	GIG_CHANGE_TO_V2: 'GIG_CHANGE_TO_V2',
	GIG_CHANGE_TO_C: 'GIG_CHANGE_TO_C',
	GIG_EXPIRATION: 'GIG_EXPIRATION',
	CON_BANK_TRANSFER: 'CON_BANK_TRANSFER',
	CON_INVOICE: 'CON_INVOICE',
	CON_DEPOSITE: 'CON_DEPOSITE',
	CON_PAY_PROCESSED: 'CON_PAY_PROCESSED',
	GIG_BOOK_NOT_CONFIRM: 'GIG_BOOK_NOT_CONFIRM',
	CON_GIG_DAY: 'CON_GIG_DAY',
	CON_AFTER_GIG_DAY: 'CON_AFTER_GIG_DAY',
	GIG_REVIEW: 'GIG_REVIEW',
	GIG_C_NO_REVIEW: 'GIG_C_NO_REVIEW',
	GIG_V_NO_REVIEW: 'GIG_V_NO_REVIEW',
	PROFILE_INCOMPLETE: 'PROFILE_INCOMPLETE',
	PROFILE_COMPLETE: 'PROFILE_COMPLETE',
	NEW_GIG: 'NEW_GIG',
	MEMBERSHIP_UPGRADE: 'MEMBERSHIP_UPGRADE',
	MEMBERSHIP_DOWNGRADE: 'MEMBERSHIP_DOWNGRADE',
	NEW_MEMBER: 'NEW_MEMBER',
	NEW_MEMBER_CONFIRM: 'NEW_MEMBER_CONFIRM',
    NEW_CHAT_MESSAGE: 'ASK_FOR_A_FREE_QUOTE',
    KYC_BOOKED_NOTIFY: 'KYC_BOOKED_NOTIFY'
}

export const HIDDEN_ACTIONS = [
    NOTIFICATIONS_STATUSES.NEW_CHAT_MESSAGE,
    NOTIFICATIONS_STATUSES.GIG_DISPUTE,
]

export const PROFILE_ACTIONS = [
	NOTIFICATIONS_STATUSES.PROFILE_COMPLETE,
	NOTIFICATIONS_STATUSES.PROFILE_INCOMPLETE,
]

export const DASHBOARD_BOOKING_ACTIONS = [
	NOTIFICATIONS_STATUSES.GIG_APPLY,
    NOTIFICATIONS_STATUSES.GIG_BOOK,
	NOTIFICATIONS_STATUSES.GIG_V_INVITE_ACCEPT,
	NOTIFICATIONS_STATUSES.GIG_V_INVITE_DECLINE,
	NOTIFICATIONS_STATUSES.GIG_BOOK_NOT_CONFIRM,
]

export const PLAN_UPGRADE_ACTIONS = [
	NOTIFICATIONS_STATUSES.MEMBERSHIP_DOWNGRADE,
	NOTIFICATIONS_STATUSES.MEMBERSHIP_UPGRADE,
]

export const KYC_ACTIONS = [
    NOTIFICATIONS_STATUSES.KYC_BOOKED_NOTIFY,
]


export const DASHBOARD_NOTIFICATIONS = [
	NOTIFICATIONS_STATUSES.GIG_APPLY,
    NOTIFICATIONS_STATUSES.GIG_NEW_APPLY,
    NOTIFICATIONS_STATUSES.GIG_V_INVITE_APPLY,
	NOTIFICATIONS_STATUSES.GIG_V_INVITE_ACCEPT,
	NOTIFICATIONS_STATUSES.GIG_V_INVITE_DECLINE,
	NOTIFICATIONS_STATUSES.GIG_BOOK,
	NOTIFICATIONS_STATUSES.GIG_BOOK_CONFIRM,
    NOTIFICATIONS_STATUSES.GIG_BOOK_DECLINED,
    NOTIFICATIONS_STATUSES.GIG_DISPUTE,
	NOTIFICATIONS_STATUSES.GIG_VENDOR_SIGN,
	NOTIFICATIONS_STATUSES.GIG_CONTRACT_CREATED,
	NOTIFICATIONS_STATUSES.GIG_CLIENT_SIGN,
	NOTIFICATIONS_STATUSES.GIG_CLIENT_PAID,
	NOTIFICATIONS_STATUSES.NEW_GIG,
	NOTIFICATIONS_STATUSES.GIG_EXPIRATION,
	NOTIFICATIONS_STATUSES.GIG_CANCELED,
	NOTIFICATIONS_STATUSES.GIG_CHANGE_TO_C,
	NOTIFICATIONS_STATUSES.GIG_CHANGE_TO_V,
	NOTIFICATIONS_STATUSES.GIG_CHANGE_TO_V2,
	NOTIFICATIONS_STATUSES.CON_DEPOSITE,
	NOTIFICATIONS_STATUSES.CON_BANK_TRANSFER,
	NOTIFICATIONS_STATUSES.CON_INVOICE,
	NOTIFICATIONS_STATUSES.GIG_BOOK_NOT_CONFIRM,
	NOTIFICATIONS_STATUSES.GIG_REVIEW,
	NOTIFICATIONS_STATUSES.CON_PAY_PROCESSED,
	NOTIFICATIONS_STATUSES.GIG_C_NO_REVIEW,
	NOTIFICATIONS_STATUSES.GIG_V_NO_REVIEW,
	NOTIFICATIONS_STATUSES.CON_GIG_DAY,
	NOTIFICATIONS_STATUSES.CON_AFTER_GIG_DAY,
	NOTIFICATIONS_STATUSES.GIG_C_CONTRACT_NOT_SIGN,
	NOTIFICATIONS_STATUSES.GIG_V_CONTRACT_NOT_SIGN,
]

export const OWNER_ACTIONS = [
	NOTIFICATIONS_STATUSES.GIG_NEW_APPLY,
	NOTIFICATIONS_STATUSES.GIG_V_INVITE_ACCEPT,
    NOTIFICATIONS_STATUSES.GIG_V_INVITE_DECLINE,
	NOTIFICATIONS_STATUSES.GIG_BOOK_CONFIRM,
	NOTIFICATIONS_STATUSES.GIG_BOOK_DECLINED,
	NOTIFICATIONS_STATUSES.GIG_VENDOR_SIGN,
	NOTIFICATIONS_STATUSES.GIG_EXPIRATION,
	NOTIFICATIONS_STATUSES.NEW_GIG,
	NOTIFICATIONS_STATUSES.CON_GIG_DAY,
	NOTIFICATIONS_STATUSES.CON_AFTER_GIG_DAY,
	NOTIFICATIONS_STATUSES.GIG_CANCELED,
	NOTIFICATIONS_STATUSES.GIG_CHANGE_TO_C,
	NOTIFICATIONS_STATUSES.CON_INVOICE,
	NOTIFICATIONS_STATUSES.CON_DEPOSITE,
	NOTIFICATIONS_STATUSES.CON_BANK_TRANSFER,
	NOTIFICATIONS_STATUSES.GIG_BOOK_NOT_CONFIRM,
	NOTIFICATIONS_STATUSES.GIG_C_CONTRACT_NOT_SIGN,
	NOTIFICATIONS_STATUSES.GIG_C_NO_REVIEW,
]

export const VENDOR_ACTIONS = [
	NOTIFICATIONS_STATUSES.GIG_BOOK,
	NOTIFICATIONS_STATUSES.GIG_CONTRACT_CREATED,
	NOTIFICATIONS_STATUSES.GIG_CLIENT_SIGN,
	NOTIFICATIONS_STATUSES.GIG_CLIENT_PAID,
	NOTIFICATIONS_STATUSES.GIG_CHANGE_TO_V,
	NOTIFICATIONS_STATUSES.GIG_CHANGE_TO_V2,
	NOTIFICATIONS_STATUSES.CON_INVOICE,
	NOTIFICATIONS_STATUSES.CON_PAY_PROCESSED,
	NOTIFICATIONS_STATUSES.CON_GIG_DAY,
	NOTIFICATIONS_STATUSES.GIG_REVIEW,
	NOTIFICATIONS_STATUSES.GIG_V_NO_REVIEW,
	NOTIFICATIONS_STATUSES.GIG_V_CONTRACT_NOT_SIGN
]

export const personTypes = {
    Individual: 'INDIVIDUAL',
    Entity: 'ENTITY'
}

export const entityTypes = {
    Business: 'BUSINESS',
    Organization: 'ORGANIZATION',
    Soletrader: 'SOLETRADER',
}

export const skipKYC = {
	skip: 1,
}

export const documentErrors = {
    DOCUMENT_UNREADABLE: {
        IDENTITY_PROOF: '- Document is fuzzy, unreadable\n- MRZ band is cut, must be perfectly readable (no flash)',
        REGISTRATION_PROOF: 'A clear copy of your Up-to-date Extract from the Companies Register is required. The one provided is not clear enough',
        ARTICLES_OF_ASSOCIATION: 'A clear copy of Up-to-date Articles of Association is required. The one provided is not clear enough',
        SHAREHOLDER_DECLARATION: 'A clear copy of Up-to-date shareholder declaration is required. The one provided is not clear enough'
    },
    DOCUMENT_NOT_ACCEPTED: {
        IDENTITY_PROOF: '- Document is not acceptable\n- Document is not a national identity card, passport required out of the EEA\n- Document is not an ID proof accepted by our terms and conditions guideline',
        REGISTRATION_PROOF: 'Document is not acceptable, it doesn\'t fit the KYC requirements guideline agreed with us',
        ARTICLES_OF_ASSOCIATION: 'Document is not acceptable, it doesn\'t fit the KYC requirements guideline agreed with us',
        SHAREHOLDER_DECLARATION: 'Document is not acceptable, it doesn\'t fit the KYC requirements guideline agreed with us'
    },
    DOCUMENT_HAS_EXPIRED: {
        IDENTITY_PROOF: '- ID proof is expired\nOnly for FR, extended validity period from 10 to 15 years is not applicable if:\n- individual was underage when document was issued\n- the document was issued before the 02/01/2004',
        REGISTRATION_PROOF: '- Document is outdated (more than 3 months)\n- A recent copy of Up-to-date Extract from the Companies Register is required (maximum 3 months old)',
        ARTICLES_OF_ASSOCIATION: '- Document is outdated\n- A copy of Up-to-date Articles of Association (which include the last modifications made) is required',
    },
    DOCUMENT_INCOMPLETE: {
        IDENTITY_PROOF: '- Document incomplete\n- Front or backside of the document is missing',
        REGISTRATION_PROOF: '- A recent copy of Up-to-date Extract from the Companies Register is required\n- All the pages of the document need to be provided',
        ARTICLES_OF_ASSOCIATION: 'The full copy of the Up-to-date Articles of Association is required (all the pages signed of the document need to be provided)',
        SHAREHOLDER_DECLARATION: '- Date, name or signature is missing\n- Document was left blanked\n- Only legal entity legal person chart is completed and not the individual one\n- Important to know who INDIRECTLY own the company if a holding company has the first level ownership'
    },
    DOCUMENT_DO_NOT_MATCH_USER_DATA: {
        IDENTITY_PROOF: '- Declarative data and document data do not match\n- NATURAL USER: declarative data FirstName and LastName do not match with document data\n- LEGAL_PERSONALITY: declarative data LegalRepresentative FirstName and LegalRepresentative LastName do not match with document data\n- Declarative data should be edited and the document resubmitted: First name and Last name are compulsory, middle name is optional',
    },
    DOCUMENT_DO_NOT_MATCH_ACCOUNT_DATA: {
        IDENTITY_PROOF: 'Bank account title do not match wallet owner data',
    },
    DOCUMENT_FALSIFIED: {
        IDENTITY_PROOF: 'Document is falsified:\n- Account will be blocked\n- Partner will be contacted, meanwhile fraud team will check all related transactions',
    },
    UNDERAGE_PERSON: {
        IDENTITY_PROOF: 'Individual is underage and can not process transactions through MGP 18+ only',
    },
    SPECIFIC_CASE: {
        IDENTITY_PROOF: 'Specific case, please refer to RefusedReasonMessage for further informations',
    }
}

export const fontSizes = {
	xx_small: '0.5rem',
	x_small: '0.625rem',
	small: '0.75rem',
	medium: '1rem',
	large: '1.25rem',
	x_large: '1.5rem',
	xx_large: '2rem',
	xxx_large: '2.5rem'
}

export const defaultFontSize = { fontSize: fontSizes['large'] };
export const MAX_MEDIA_HASHTAGS_COUNT = 5;
export const MAX_MEDIA_DESCRIPTION_LENGTH = 30;

export const registerTabs = [
	{
		name: 'AccountInfo',    //1
		title: 'Account Info',
	},
	{
		name: 'ArtistPersonalInfo',    //2
		title: 'Artist Contact Info',
	},
	{
		name: 'PlansArtist',    //3
		title: 'Plans & Payments',
	},
	{
		name: 'PlansBand',    //4
		title: 'Plans & Payments',
	},
	{
		name: 'PlansGroup',    //5
		title: 'Plans & Payments',
	},
	{
		name: 'PlansVenue',    //6
		title: 'Plans & Payments',
	},
	{
		name: 'PlansAndTalents',    //7
		title: 'Talent & Styles',
	},
	/*{
		name: 'TalentInfo',    //8
		title: 'Artist Description',
	},*/
	{
		name: 'TalentDetails',    //9
		title: 'Description & Booking Fees',
	},
	{
		name: 'CancellationPolicy',    //10
		title: 'Cancellation Policy',
	},
	{
		name: 'VenueInfo',    //11
		title: 'Venue Contact Info',
	},
	{
		name: 'PlansAndVenueProfile',    //12
		title: 'Venue Type & Occasions',
	},
	{
		name: 'BusinessInfo',    //13
		title: 'Venue Description',
	},
	{
		name: 'BookingInfo',    //14
		title: 'Description & Booking Fees',
	},
	{
		name: 'BandContactInfo',    //15
		title: 'Band Contact Info',
	},
	{
		name: 'PlansAndBandGenre',    //16
		title: 'Band Genre',
	},
	{
		name: 'BandInfo',    //17
		title: 'Band Description',
	},
	{
		name: 'BandDetails',    //18
		title: 'Description & Booking Fees',
	},
	{
		name: 'PlansAndGroupTalents',    //19
		title: 'Group Talent',
	},
	{
		name: 'GroupInfo',    //20
		title: 'Group Description',
	},
	{
		name: 'GroupDetails',    //21
		title: 'Group Booking Fees & Details',
	},
	{
		name: 'GroupContactInfo',    //22
		title: 'Group Contact Info',
	},
	{
		name: 'CompanyInfo',    //23
		title: 'Company Description',
	},
	{
		name: 'CompanyContactInfo',    //24
		title: 'Personal Contact Info',
	},
	{
		name: 'ClientInfo',    //25
		title: 'Client Description',
	},
	{
		name: 'ClientPersonalInfo',    //26
		title: 'Client Contact Info',
	},
	{
		name: 'FanInfo',    //27
		title: 'Fan Info',
	},
	{
		name: 'FanPersonalInfo',    //28
		title: 'Personal Info',
	},
  {
		name: 'KYC',    //30
		title: 'KYC',
	},
	{
		name: 'Coupons', // 31
		title: 'Promotion',
	},
  {
    name: 'EditProfilePhotos',
    title: 'Edit Profile Photos',
  },
  {
    name: 'EditVideos',
    title: 'Edit Videos',
  },
  {
    name: 'EditAudio',
    title: 'Edit Audio',
  },
  {
    name: 'EditPictures',
    title: 'Edit Pictures',
  }
]

export const registerProfileTabCodes = reduce(
	registerTabs,
	(res, item, index) => {
		res[item.name] = index + 1

		return res
	},
	{}
)

export const translateProfileForms = reduce(
	registerTabs,
	(res, item, index) => {
		res[index + 1] = item.title

		return res
	},
	{}
)

export const registerProfileTabCodesConvert = reduce(
	registerTabs,
	(res, item, index) => {
		res[index + 1] = item.name

		return res
	},
	{}
)

export const profileFormNames = registerTabs.map(item => item.name)

export const registerMediaTabCodes = {
	EditProfilePhotos: 30,
	EditVideos: 31,
	EditAudio: 32,
	EditPictures: 33,
}

export const registerMediaTabCodesConvert = {
	30: 'EditProfilePhotos',
	31: 'EditVideos',
	32: 'EditAudio',
	33: 'EditPictures',
}

export const mediaFormNames = ['EditProfilePhotos', 'EditVideos', 'EditAudio', 'EditPictures']

export const genders = [
	{ label: 'Male', value: 'm' },
	{ label: 'Female', value: 'f' },
	{ label: 'Transgender', value: 't' },
]

export const translateMediaForms = {
	1: 'Edit Profile Photos',
	2: 'Edit Videos',
	3: 'Edit Audio',
	4: 'Edit Pictures',
}

export const GIG_CREATE_STEPS = {
	start: 1,
	serviceType: 2,
	chooseVenue: 3,
	hireArtist: 4,
	summary: 5,
	published: 6,
}

export const currency = [
	// {
	//   value: 'USD',
	//   label: '$'
	// },
	{
		value: 'EUR',
		label: '€',
	},
	// {
	//   value: 'GBP',
	//   label: '£'
	// },
	// {
	//   value: 'JPY',
	//   label: '¥'
	// },
	// {
	//   value: 'UAH',
	//   label: '₴'
	// },
	// {
	//   value: 'RUB',
	//   label: 'ք'
	// },
]

export const DISTANCE_UNITS = [
	{ value: 'km', label: 'km' },
	{ value: 'mil', label: 'miles' },
]

export const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']

export const period = {
    common: [
        {
            value: 'hour',
            label: 'per hour',
        },
        {
            value: 'half day',
            label: 'half day',
        },
        {
            value: 'day',
            label: 'day',
        },
        {
            value: 'week',
            label: 'week',
        },
        {
            value: 'month',
            label: 'month',
        },
        {
            value: 'gig',
            label: 'gig',
        },
        {
            value: 'session',
            label: 'session',
        },
        {
            value: 'post',
            label: 'post',
        },
        {
            value: 'pack',
            label: 'pack',
        },
        {
            value: 'other',
            label: 'other',
        },
    ],
    venue: [
        {
            value: 'person',
            label: 'person',
        },
        {
            value: 'guest',
            label: 'guest',
        },
        {
            value: 'ticket',
            label: 'ticket',
        },
        {
            value: 'series',
            label: 'series',
        },
        {
            value: 'class',
            label: 'class',
        },
        {
            value: 'workshop',
            label: 'workshop',
        },
    ]
}

export const YEARS_IN_BUSINESS_LOW = [
	{
		value: 1,
		label: '0 - 6 months',
	},
	{
		value: 2,
		label: '7 - 12 months',
	},
	{
		value: 3,
		label: '1 - 2 years',
	},
]

export const YEARS_IN_BUSINESS_HIGH = [
	{
		value: 4,
		label: '3 - 5 years',
	},
	{
		value: 5,
		label: '6 - 9 years',
	},
	{
		value: 6,
		label: '10+ years',
	},
]

export const SORT_BY_OPTIONS = [
	{
		label: ' -  -  - ',
		value: null,
	},
	{
		label: 'Prices Low to High',
		value: 'prices',
	},
	{
		label: 'Prices High to Low',
		value: 'prices_reverse',
	},
	{
		label: 'Reviews',
		value: 'reviews',
	},
	{
		label: 'A to Z',
		value: 'alfabetical',
	},
	{
		label: 'Z to A',
		value: 'alfabetical_reverse',
	}
]


export const REVIEWS_SORT_BY_OPTIONS = [
	{
		label: 'Most Recent',
		value: 'latest',
	},
	{
		label: 'Least Recent',
		value: 'earliest',
	},
]

export const CheckboxColor = {
	ACTIVE_COLOR: '#ffd210',
	DISABLED_COLOR: '#ededed',
}

export const nationalities = [
	{ label: 'Afghan', value: '1' },
	{ label: 'Albanian', value: '2' },
	{ label: 'Algerian', value: '3' },
	{ label: 'American', value: '4' },
	{ label: 'Argentine', value: '5' },
	{ label: 'Argentinian', value: '6' },
	{ label: 'Australian', value: '7' },
	{ label: 'Austrian', value: '8' },
	{ label: 'Bangladeshi', value: '9' },
	{ label: 'Batswana', value: '10' },
	{ label: 'Belgian', value: '11' },
	{ label: 'Bolivian', value: '12' },
	{ label: 'Brazilian', value: '13' },
	{ label: 'British', value: '14' },
	{ label: 'Bulgarian', value: '15' },
	{ label: 'Cambodian', value: '16' },
	{ label: 'Cameroonian', value: '17' },
	{ label: 'Canadian', value: '18' },
	{ label: 'Chilean', value: '19' },
	{ label: 'Chinese', value: '20' },
	{ label: 'Colombian', value: '21' },
	{ label: 'Costa Rican', value: '22' },
	{ label: 'Croatian', value: '23' },
	{ label: 'Cuban', value: '24' },
	{ label: 'Czech', value: '25' },
	{ label: 'Danish', value: '26' },
	{ label: 'Dominican', value: '27' },
	{ label: 'Dutch', value: '28' },
	{ label: 'Ecuadorian', value: '29' },
	{ label: 'Egyptian', value: '30' },
	{ label: 'Emirati', value: '31' },
	{ label: 'English', value: '32' },
	{ label: 'Estonian', value: '33' },
	{ label: 'Ethiopian', value: '34' },
	{ label: 'Fijian', value: '35' },
	{ label: 'Finnish', value: '36' },
	{ label: 'French', value: '37' },
	{ label: 'German', value: '38' },
	{ label: 'Ghanaian', value: '39' },
	{ label: 'Greek', value: '40' },
	{ label: 'Guatemalan', value: '41' },
	{ label: 'Haitian', value: '42' },
	{ label: 'Honduran', value: '43' },
	{ label: 'Hungarian', value: '44' },
	{ label: 'Icelandic', value: '45' },
	{ label: 'Indian', value: '46' },
	{ label: 'Indonesian', value: '47' },
	{ label: 'Iranian', value: '48' },
	{ label: 'Iraqi', value: '49' },
	{ label: 'Irish', value: '50' },
	{ label: 'Israeli', value: '51' },
	{ label: 'Italian', value: '52' },
	{ label: 'Jamaican', value: '53' },
	{ label: 'Japanese', value: '54' },
	{ label: 'Jordanian', value: '55' },
	{ label: 'Kenyan', value: '56' },
	{ label: 'Korean', value: '57' },
	{ label: 'Kuwaiti', value: '58' },
	{ label: 'Lao', value: '59' },
	{ label: 'Latvian', value: '60' },
	{ label: 'Lebanese', value: '61' },
	{ label: 'Libyan', value: '62' },
	{ label: 'Lithuanian', value: '63' },
	{ label: 'Malaysian', value: '64' },
	{ label: 'Malian', value: '65' },
	{ label: 'Maltese', value: '66' },
	{ label: 'Mexican', value: '67' },
	{ label: 'Mongolian', value: '68' },
	{ label: 'Moroccan', value: '69' },
	{ label: 'Mozambican', value: '70' },
	{ label: 'Namibian', value: '71' },
	{ label: 'Nepalese', value: '72' },
	{ label: 'New Zealand', value: '73' },
	{ label: 'Nicaraguan', value: '74' },
	{ label: 'Nigerian', value: '75' },
	{ label: 'Norwegian', value: '76' },
	{ label: 'Pakistani', value: '77' },
	{ label: 'Panamanian', value: '78' },
	{ label: 'Paraguayan', value: '79' },
	{ label: 'Peruvian', value: '80' },
	{ label: 'Philippine', value: '81' },
	{ label: 'Polish', value: '82' },
	{ label: 'Portuguese', value: '83' },
	{ label: 'Romanian', value: '84' },
	{ label: 'Russian', value: '85' },
	{ label: 'Salvadorian', value: '86' },
	{ label: 'Saudi', value: '87' },
	{ label: 'Scottish', value: '88' },
	{ label: 'Senegalese', value: '89' },
	{ label: 'Serbian', value: '90' },
	{ label: 'Singaporean', value: '91' },
	{ label: 'Slovak', value: '92' },
	{ label: 'South African', value: '93' },
	{ label: 'Spanish', value: '94' },
	{ label: 'Sri Lankan', value: '95' },
	{ label: 'Sudanese', value: '96' },
	{ label: 'Swedish', value: '97' },
	{ label: 'Swiss', value: '98' },
	{ label: 'Syrian', value: '99' },
	{ label: 'Taiwanese', value: '100' },
	{ label: 'Tajikistani', value: '101' },
	{ label: 'Thai', value: '102' },
	{ label: 'Tongan', value: '103' },
	{ label: 'Tunisian', value: '104' },
	{ label: 'Turkish', value: '105' },
	{ label: 'Ukrainian', value: '106' },
	{ label: 'Uruguayan', value: '107' },
	{ label: 'Venezuelan', value: '108' },
	{ label: 'Vietnamese', value: '109' },
	{ label: 'Welsh', value: '110' },
	{ label: 'Zambian', value: '111' },
	{ label: 'Zimbabwean', value: '112' }
]

export const dropdownQuestions = {
	artistQuestions: [
		{
		  value: 1,
		  label: 'Why do you do what you do?',
		},
		{
		  value: 2,
		  label: 'What attracts your audience?',
		},
		{
		  value: 3,
		  label: 'Where is your favourite place to perform?',
		},
		{
		  value: 4,
		  label: 'What is your dream gig?',
		},
		{
		  value: 5,
		  label: 'Who to you aspire to become?',
		},
		{
		  value: 6,
		  label: 'How would you like to impact the world through your art?',
		},
		{
		  value: 7,
		  label: 'What is a social cause that you care about?',
		},
		{
		  value: 99,
		  label: 'Freestyle writing',
		}
	],
	venueQuestions: [
		{
		  value: 101,
		  label: 'Does your venue have historical value?',
		},
		{
		  value: 102,
		  label: 'Is it close to the city center?',
		},
		{
		  value: 103,
		  label: 'Who frequents your venue? Movie stars, music moguls, hipsters, college students?',
		},
		{
		  value: 104,
		  label: 'What does the ambiance feel like?',
		},
		{
		  value: 105,
		  label: 'Is there a famous dish your cook makes?',
		},
		{
		  value: 106,
		  label: 'Why do people like going there?',
		},
		{
		  value: 107,
		  label: 'Is it affordable? Food is delicious? Atmosphere? Convenient?',
		},
		{
		  value: 108,
		  label: 'Tell us about the surroundings',
		},
		{
		  value: 199,
		  label: 'Freestyle writing',
		}
	],
	bandQuestions: [
		{
		  value: 201,
		  label: 'How did the band come together? Were you friends before?',
		},
		{
		  value: 202,
		  label: 'Which songs or atmosphere does the band enjoy playing the most?',
		},
		{
		  value: 203,
		  label: 'Which famous band do you aspire to be?',
		},
		{
		  value: 204,
		  label: 'If you could play anywhere in the world, where would you play and why?',
		},
		{
		  value: 205,
		  label: 'Does your soul or mind get transported somewhere when you play your instrument on stage?',
		},
		{
		  value: 206,
		  label: 'If you could jam with any artist, who would it be?',
		},
    	{
    	  value: 299,
    	  label: 'Freestyle writing',
    	}
	],
	groupQuestions: [
		{
		  value: 301,
		  label: 'How did you form this group of professional artists?',
		},
		{
		  value: 302,
		  label: 'When working as a team, what do you want to deliver for the client?',
		},
		{
		  value: 303,
		  label: 'How do you find the synergy to create the best form of art?',
		},
		{
		  value: 304,
		  label: 'Why do you like working together?',
		},
    	{
    	  value: 399,
    	  label: 'Freestyle writing',
    	}
  	]
}


export const abilityTree  = [
	{
		title: 'Global',
		level: 0,
		value: '0',
		key: '0',
		children: [
			{
				title: 'Africa',
				level: 1,
				value: '0-0',
				children: [
					{
						title: 'Algeria',
						level: 2,
						value: '0-0-0',
					},
					{
						title: 'Angola',
						level: 2,
						value: '0-0-1',
					},
					{
						title: 'Benin',
						level: 2,
						value: '0-0-2',
					},
					{
						title: 'Botswana',
						level: 2,
						value: '0-0-3',
					},
					{
						title: 'Burkina Faso',
						level: 2,
						value: '0-0-4',
					},
					{
						title: 'Burundi',
						level: 2,
						value: '0-0-5',
					},
					{
						title: 'Cabo Verde',
						level: 2,
						value: '0-0-6',
					},
					{
						title: 'Cameroon',
						level: 2,
						value: '0-0-7',
					},
					{
						title: 'Central African Republic',
						level: 2,
						value: '0-0-8',
					},
					{
						title: 'Chad',
						level: 2,
						value: '0-0-9',
					},
					{
						title: 'Comoros',
						level: 2,
						value: '0-0-10',
					},
					{
						title: 'Congo, Democratic Republic of the',
						level: 2,
						value: '0-0-11',
					},
					{
						title: 'Congo, Republic of the',
						level: 2,
						value: '0-0-12',
					},
					{
						title: 'Cote d\'Ivoire',
						level: 2,
						value: '0-0-13',
					},
					{
						title: 'Djibouti',
						level: 2,
						value: '0-0-14',
					},
					{
						title: 'Egypt',
						level: 2,
						value: '0-0-15',
					},
					{
						title: 'Equatorial Guinea',
						level: 2,
						value: '0-0-16',
					},
					{
						title: 'Eritrea',
						level: 2,
						value: '0-0-17',
					},
					{
						title: 'Eswatini',
						level: 2,
						value: '0-0-18',
					},
					{
						title: 'Ethiopia',
						level: 2,
						value: '0-0-19',
					},
					{
						title: 'Gabon',
						level: 2,
						value: '0-0-20',
					},
					{
						title: 'Gambia',
						level: 2,
						value: '0-0-21',
					},
					{
						title: 'Ghana',
						level: 2,
						value: '0-0-22',
					},
					{
						title: 'Guinea',
						level: 2,
						value: '0-0-23',
					},
					{
						title: 'Guinea-Bissau',
						level: 2,
						value: '0-0-24',
					},
					{
						title: 'Kenya',
						level: 2,
						value: '0-0-25',
					},
					{
						title: 'Lesotho',
						level: 2,
						value: '0-0-26',
					},
					{
						title: 'Liberia',
						level: 2,
						value: '0-0-27',
					},
					{
						title: 'Libya',
						level: 2,
						value: '0-0-28',
					},
					{
						title: 'Madagascar',
						level: 2,
						value: '0-0-29',
					},
					{
						title: 'Malawi',
						level: 2,
						value: '0-0-30',
					},
					{
						title: 'Mali',
						level: 2,
						value: '0-0-31',
					},
					{
						title: 'Mauritania',
						level: 2,
						value: '0-0-32',
					},
					{
						title: 'Mauritius',
						level: 2,
						value: '0-0-33',
					},
					{
						title: 'Morocco',
						level: 2,
						value: '0-0-34',
					},
					{
						title: 'Mozambique',
						level: 2,
						value: '0-0-35',
					},
					{
						title: 'Namibia',
						level: 2,
						value: '0-0-36',
					},
					{
						title: 'Niger',
						level: 2,
						value: '0-0-37',
					},
					{
						title: 'Nigeria',
						level: 2,
						value: '0-0-38',
					},
					{
						title: 'Rwanda',
						level: 2,
						value: '0-0-39',
					},
					{
						title: 'Sao Tome and Principe',
						level: 2,
						value: '0-0-40',
					},
					{
						title: 'Senegal',
						level: 2,
						value: '0-0-41',
					},
					{
						title: 'Seychelles',
						level: 2,
						value: '0-0-42',
					},
					{
						title: 'Sierra Leone',
						level: 2,
						value: '0-0-43',
					},
					{
						title: 'Somalia',
						level: 2,
						value: '0-0-44',
					},
					{
						title: 'South Africa',
						level: 2,
						value: '0-0-45',
					},
					{
						title: 'South Sudan',
						level: 2,
						value: '0-0-46',
					},
					{
						title: 'Sudan',
						level: 2,
						value: '0-0-47',
					},
					{
						title: 'Tanzania',
						level: 2,
						value: '0-0-48',
					},
					{
						title: 'Togo',
						level: 2,
						value: '0-0-49',
					},
					{
						title: 'Tunisia',
						level: 2,
						value: '0-0-50',
					},
					{
						title: 'Uganda',
						level: 2,
						value: '0-0-51',
					},
					{
						title: 'Zambia',
						level: 2,
						value: '0-0-52',
					},
					{
						title: 'Zimbabwe',
						level: 2,
						value: '0-0-53',
					}
				]
			},
			{
				title: 'Asia',
				level: 1,
				value: '0-1',
				children: [
					{
						title: 'Afghanistan',
						level: 2,
						value: '0-1-0',
					},
					{
						title: 'Armenia',
						level: 2,
						value: '0-1-1',
					},
					{
						title: 'Azerbaijan',
						level: 2,
						value: '0-1-2',
					},
					{
						title: 'Bahrain',
						level: 2,
						value: '0-1-3',
					},
					{
						title: 'Bangladesh',
						level: 2,
						value: '0-1-4',
					},
					{
						title: 'Bhutan',
						level: 2,
						value: '0-1-5',
					},
					{
						title: 'Brunei',
						level: 2,
						value: '0-1-6',
					},
					{
						title: 'Cambodia',
						level: 2,
						value: '0-1-7',
					},
					{
						title: 'China',
						level: 2,
						value: '0-1-8',
					},
					{
						title: 'Cyprus',
						level: 2,
						value: '0-1-9',
					},
					{
						title: 'Georgia',
						level: 2,
						value: '0-1-10',
					},
					{
						title: 'India',
						level: 2,
						value: '0-1-11',
					},
					{
						title: 'Indonesia',
						level: 2,
						value: '0-1-12',
					},
					{
						title: 'Iran',
						level: 2,
						value: '0-1-13',
					},
					{
						title: 'Iraq',
						level: 2,
						value: '0-1-14',
					},
					{
						title: 'Israel',
						level: 2,
						value: '0-1-15',
					},
					{
						title: 'Japan',
						level: 2,
						value: '0-1-16',
					},
					{
						title: 'Jordan',
						level: 2,
						value: '0-1-17',
					},
					{
						title: 'Kazakhstan',
						level: 2,
						value: '0-1-18',
					},
					{
						title: 'Kuwait',
						level: 2,
						value: '0-1-19',
					},
					{
						title: 'Kyrgyzstan',
						level: 2,
						value: '0-1-20',
					},
					{
						title: 'Laos',
						level: 2,
						value: '0-1-21',
					},
					{
						title: 'Lebanon',
						level: 2,
						value: '0-1-22',
					},
					{
						title: 'Malaysia',
						level: 2,
						value: '0-1-23',
					},
					{
						title: 'Maldives',
						level: 2,
						value: '0-1-24',
					},
					{
						title: 'Mongolia',
						level: 2,
						value: '0-1-25',
					},
					{
						title: 'Myanmar',
						level: 2,
						value: '0-1-26',
					},
					{
						title: 'Nepal',
						level: 2,
						value: '0-1-27',
					},
					{
						title: 'North Korea',
						level: 2,
						value: '0-1-28',
					},
					{
						title: 'Oman',
						level: 2,
						value: '0-1-29',
					},
					{
						title: 'Pakistan',
						level: 2,
						value: '0-1-30',
					},
					{
						title: 'Palestine',
						level: 2,
						value: '0-1-31',
					},
					{
						title: 'Philippines',
						level: 2,
						value: '0-1-32',
					},
					{
						title: 'Qatar',
						level: 2,
						value: '0-1-33',
					},
					{
						title: 'Russia',
						level: 2,
						value: '0-1-34',
					},
					{
						title: 'Saudi Arabia',
						level: 2,
						value: '0-1-35',
					},
					{
						title: 'Singapore',
						level: 2,
						value: '0-1-36',
					},
					{
						title: 'South Korea',
						level: 2,
						value: '0-1-37',
					},
					{
						title: 'Sri Lanka',
						level: 2,
						value: '0-1-38',
					},
					{
						title: 'Syria',
						level: 2,
						value: '0-1-39',
					},
					{
						title: 'Taiwan',
						level: 2,
						value: '0-1-40',
					},
					{
						title: 'Tajikistan',
						level: 2,
						value: '0-1-41',
					},
					{
						title: 'Thailand',
						level: 2,
						value: '0-1-42',
					},
					{
						title: 'Timor-Leste',
						level: 2,
						value: '0-1-43',
					},
					{
						title: 'Turkey',
						level: 2,
						value: '0-1-44',
					},
					{
						title: 'Turkmenistan',
						level: 2,
						value: '0-1-45',
					},
					{
						title: 'United Arab Emirates',
						level: 2,
						value: '0-1-46',
					},
					{
						title: 'Uzbekistan',
						level: 2,
						value: '0-1-47',
					},
					{
						title: 'Vietnam',
						level: 2,
						value: '0-1-48',
					},
					{
						title: 'Yemen',
						level: 2,
						value: '0-1-49',
					},
				]
			},
			{
	  			title: 'Australia & Oceania',
				level: 1,
				value: '0-2',
				children: [
					{
						title: 'Australia',
						level: 2,
						value: '0-2-0',
					},
					{
						title: 'Fiji',
						level: 2,
						value: '0-2-1',
					},
					{
						title: 'Kiribati',
						level: 2,
						value: '0-2-2',
					},
					{
						title: 'Marshall Islands',
						level: 2,
						value: '0-2-3',
					},
					{
						title: 'Micronesia',
						level: 2,
						value: '0-2-4',
					},
					{
						title: 'Nauru',
						level: 2,
						value: '0-2-5',
					},
					{
						title: 'New Zealand',
						level: 2,
						value: '0-2-6',
					},
					{
						title: 'Palau',
						level: 2,
						value: '0-2-7',
					},
					{
						title: 'Papua New Guinea',
						level: 2,
						value: '0-2-8',
					},
					{
						title: 'Samoa',
						level: 2,
						value: '0-2-9',
					},
					{
						title: 'Solomon Islands',
						level: 2,
						value: '0-2-10',
					},
					{
						title: 'Tonga',
						level: 2,
						value: '0-2-11',
					},
					{
						title: 'Tuvalu',
						level: 2,
						value: '0-2-12',
					},
					{
						title: 'Vanuatu',
						level: 2,
						value: '0-2-13',
					},
				]
			},
			{
				title: 'Europe',
				level: 1,
				value: '0-3',
				children: [
					{
						title: 'Albania',
						level: 2,
						value: '0-3-0',
					},
					{
						title: 'Andorra',
						level: 2,
						value: '0-3-1',
					},
					{
						title: 'Armenia',
						level: 2,
						value: '0-3-2',
					},
					{
						title: 'Austria',
						level: 2,
						value: '0-3-3',
					},
					{
						title: 'Azerbaijan',
						level: 2,
						value: '0-3-4',
					},
					{
						title: 'Belarus',
						level: 2,
						value: '0-3-5',
					},
					{
						title: 'Belgium',
						level: 2,
						value: '0-3-6',
					},
					{
						title: 'Bosnia and Herzegovina',
						level: 2,
						value: '0-3-7',
					},
					{
						title: 'Bulgaria',
						level: 2,
						value: '0-3-8',
					},
					{
						title: 'Croatia',
						level: 2,
						value: '0-3-9',
					},
					{
						title: 'Cyprus',
						level: 2,
						value: '0-3-10',
					},
					{
						title: 'Czechia',
						level: 2,
						value: '0-3-11',
					},
					{
						title: 'Denmark',
						level: 2,
						value: '0-3-12',
					},
					{
						title: 'Estonia',
						level: 2,
						value: '0-3-13',
					},
					{
						title: 'Finland',
						level: 2,
						value: '0-3-14',
					},
					{
						title: 'France',
						level: 2,
						value: '0-3-15',
					},
					{
						title: 'Georgia',
						level: 2,
						value: '0-3-16',
					},
					{
						title: 'Germany',
						level: 2,
						value: '0-3-17',
					},
					{
						title: 'Greece',
						level: 2,
						value: '0-3-18',
					},
					{
						title: 'Hungary',
						level: 2,
						value: '0-3-19',
					},
					{
						title: 'Iceland',
						level: 2,
						value: '0-3-20',
					},
					{
						title: 'Ireland',
						level: 2,
						value: '0-3-21',
					},
					{
						title: 'Italy',
						level: 2,
						value: '0-3-22',
					},
					{
						title: 'Kazakhstan',
						level: 2,
						value: '0-3-23',
					},
					{
						title: 'Kosovo',
						level: 2,
						value: '0-3-24',
					},
					{
						title: 'Latvia',
						level: 2,
						value: '0-3-25',
					},
					{
						title: 'Liechtenstein',
						level: 2,
						value: '0-3-26',
					},
					{
						title: 'Lithuania',
						level: 2,
						value: '0-3-27',
					},
					{
						title: 'Luxembourg',
						level: 2,
						value: '0-3-28',
					},
					{
						title: 'Malta',
						level: 2,
						value: '0-3-29',
					},
					{
						title: 'Moldova',
						level: 2,
						value: '0-3-30',
					},
					{
						title: 'Monaco',
						level: 2,
						value: '0-3-31',
					},
					{
						title: 'Montenegro',
						level: 2,
						value: '0-3-32',
					},
					{
						title: 'Netherlands',
						level: 2,
						value: '0-3-33',
					},
					{
						title: 'North Macedonia',
						level: 2,
						value: '0-3-34',
					},
					{
						title: 'Norway',
						level: 2,
						value: '0-3-35',
					},
					{
						title: 'Poland',
						level: 2,
						value: '0-3-36',
					},
					{
						title: 'Portugal',
						level: 2,
						value: '0-3-37',
					},
					{
						title: 'Romania',
						level: 2,
						value: '0-3-38',
					},
					{
						title: 'Russia',
						level: 2,
						value: '0-3-39',
					},
					{
						title: 'San Marino',
						level: 2,
						value: '0-3-40',
					},
					{
						title: 'Serbia',
						level: 2,
						value: '0-3-41',
					},
					{
						title: 'Slovakia',
						level: 2,
						value: '0-3-42',
					},
					{
						title: 'Slovenia',
						level: 2,
						value: '0-3-43',
					},
					{
						title: 'Spain',
						level: 2,
						value: '0-3-44',
					},
					{
						title: 'Sweden',
						level: 2,
						value: '0-3-45',
					},
					{
						title: 'Switzerland',
						level: 2,
						value: '0-3-46',
					},
					{
						title: 'Turkey',
						level: 2,
						value: '0-3-47',
					},
					{
						title: 'Ukraine',
						level: 2,
						value: '0-3-48',
					},
					{
						title: 'United Kingdom',
						level: 2,
						value: '0-3-49',
					},
					{
						title: 'Vatican City',
						level: 2,
						value: '0-3-50',
					},
				]
			},
			{
				title: 'Northern America',
				level: 1,
				value: '0-4',
				children: [
					{
						title: 'Antigua and Barbuda',
						level: 2,
						value: '0-4-0',
					},
					{
						title: 'Bahamas',
						level: 2,
						value: '0-4-1',
					},
					{
						title: 'Barbados',
						level: 2,
						value: '0-4-2',
					},
					{
						title: 'Belize',
						level: 2,
						value: '0-4-3',
					},
					{
						title: 'Canada',
						level: 2,
						value: '0-4-4',
					},
					{
						title: 'Costa Rica',
						level: 2,
						value: '0-4-5',
					},
					{
						title: 'Cuba',
						level: 2,
						value: '0-4-6',
					},
					{
						title: 'Dominica',
						level: 2,
						value: '0-4-7',
					},
					{
						title: 'Dominican Republic',
						level: 2,
						value: '0-4-8',
					},
					{
						title: 'El Salvador',
						level: 2,
						value: '0-4-9',
					},
					{
						title: 'Grenada',
						level: 2,
						value: '0-4-10',
					},
					{
						title: 'Guatemala',
						level: 2,
						value: '0-4-11',
					},
					{
						title: 'Haiti',
						level: 2,
						value: '0-4-12',
					},
					{
						title: 'Honduras',
						level: 2,
						value: '0-4-13',
					},
					{
						title: 'Jamaica',
						level: 2,
						value: '0-4-14',
					},
					{
						title: 'Mexico',
						level: 2,
						value: '0-4-15',
					},
					{
						title: 'Nicaragua',
						level: 2,
						value: '0-4-16',
					},
					{
						title: 'Panama',
						level: 2,
						value: '0-4-17',
					},
					{
						title: 'Saint Kitts and Nevis',
						level: 2,
						value: '0-4-18',
					},
					{
						title: 'Saint Lucia',
						level: 2,
						value: '0-4-19',
					},
					{
						title: 'Saint Vincent and the Grenadines',
						level: 2,
						value: '0-4-20',
					},
					{
						title: 'Trinidad and Tobago',
						level: 2,
						value: '0-4-21',
					},
					{
						title: 'United States of America (USA)',
						level: 2,
						value: '0-4-22',
					},
				]
			},
			{
				title: 'Southern America',
				level: 1,
				value: '0-5',
				children: [
					{
						title: 'Argentina',
						level: 2,
						value: '0-5-0',
					},
					{
						title: 'Bolivia',
						level: 2,
						value: '0-5-1',
					},
					{
						title: 'Brazil',
						level: 2,
						value: '0-5-2',
					},
					{
						title: 'Chile',
						level: 2,
						value: '0-5-3',
					},
					{
						title: 'Colombia',
						level: 2,
						value: '0-5-4',
					},
					{
						title: 'Ecuador',
						level: 2,
						value: '0-5-5',
					},
					{
						title: 'Guyana',
						level: 2,
						value: '0-5-6',
					},
					{
						title: 'Paraguay',
						level: 2,
						value: '0-5-7',
					},
					{
						title: 'Peru',
						level: 2,
						value: '0-5-8',
					},
					{
						title: 'Suriname',
						level: 2,
						value: '0-5-9',
					},
					{
						title: 'Uruguay',
						level: 2,
						value: '0-5-10',
					},
					{
						title: 'Venezuela',
						level: 2,
						value: '0-5-11',
					},
				]
			}
		]
	}
];

export const topMusicStyles = [
  "Country",
  "Club",
  "Dance",
  "Disco",
  "Electro House",
  "Folk",
  "Hard Rock",
  "Hip Hop",
  "House",
  "Jazz",
  "Latin",
  "Metal",
  "Opera",
  "Pop",
  "Rap",
  "Reggae",
  "Reggaeton",
  "Rock",
  "Rock&Roll",
  "Salsa",
  "Smooth Jazz",
  "Soul",
  "Techno",
  "World Music",
];
