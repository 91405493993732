import React from 'react';
import './RichPopover.scss'
import GeminiScrollbar from 'react-gemini-scrollbar'
import { Popover } from 'antd';
import 'antd/es/popover/style/css'

class RichPopover extends React.PureComponent {
    render() {
        const { overlayClassName, children, content, ...restProps} = this.props;

        return <Popover
            placement="bottomRight"
            content={<GeminiScrollbar>{content}</GeminiScrollbar>}
            overlayClassName={`RichPopover ${overlayClassName}`}
            trigger="click"
            {...restProps}
            >
            {children}
        </Popover>
    }
}

export default RichPopover