import React from 'react'
import {connect} from 'react-redux'
import { change, reduxForm } from 'redux-form'
import {getCountriesTree} from '../../../redux/modules/dashboard';
import * as _ from 'lodash';
import {entityTypes, personTypes} from '../../../utils/constants';
import validate from '../../../helpers/validators/KYCValidator'
import {translate} from '../../../translator';
import './KYC.scss';
import KycHeader from "./KycHeader";
import KycClientForm from "./KycClientForm";
import KycEntityForm from "./KycEntityForm";
import {KycStatusType} from "./KycConstants";
import {Asterisk} from "../../common/Asterisk/Asterisk";
import KycSkipForm from "./KycSkipForm";

class KYC extends React.PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            personType: null,
            entityType: null,
            skipKYC: null,
            checkboxSkipKYC: null
        }
    }

    componentDidMount() {
        const { initialValues } = this.props
        this.props.getCountriesTree()

        this.setState({
            personType: initialValues.kyc_person_type,
            entityType: initialValues.kyc_entity_type
        })
    }

    selectPersonType = (type) => {
        this.props.change('kyc_person_type', type)
        this.props.change('kyc_entity_type', null)
        this.props.change('skip_kyc', null)

        this.setState({ personType: type, skipKYC: null, checkboxSkipKYC: null })
    }

    isPersonTypeSelected = (type) => {
        return this.state.personType === type
    }

    selectEntityType = (type) => {
        this.props.change('kyc_entity_type', type)
        this.props.change('skip_kyc', null)

        this.setState({ entityType: type, skipKYC: null, checkboxSkipKYC: null })
    }

    isEntityTypeSelected = (type) => {
        return this.state.entityType === type
    }

    selectSkipKYC = (status) => {
		this.props.change('kyc_person_type', null)
		this.props.change('kyc_entity_type', null)

		this.setState({ skipKYC: status, personType: null, entityType: null})
    }

    isSkipKYCSelected = (status) => {
		return this.state.skipKYC === status
    }


    acceptCheckboxSkipKYC = (evt, val) => {
		this.setState({ checkboxSkipKYC: val})
    }

    isAcceptCheckboxSkipKYC = (value) => {
		return this.state.checkboxSkipKYC === value
    }

    render() {
        const { initialValues, currentUser } = this.props;

        if (!currentUser.email) {
          return <div className="KYC_container" style={{
            transform: 'translateX(30%)'
          }}>
            You need to confirm your email first
          </div>
        }

        return (
            <div className="KYC_container">
              <KycHeader status={initialValues.kyc_status} />

              { this.renderBody()  }

              {currentUser.profile_type !== 'client' && <div className="horizontal_line" /> }
              <p className="footer-notice">{translate('kyc.footerNotice')}</p>

              {this.props.initialValues.kyc_status !== KycStatusType.VALIDATED && currentUser.profile_type !== 'client' && this.renderSkipKYC()}
            </div>
        )
    }

    renderBody() {
      if(this.isBlocked()) {
        return this.renderBlockedBody();
      }
      else if(this.isAwaitingVerification()) {
        return this.renderPendingBody();
      }
      else if(this.isVerified()) {
        return this.renderVerifiedBody();
      }
      return this.renderForm();
    }

    isBlocked() {
      return this.props.currentKYC.is_blocked;
    }

    isAwaitingVerification() {
      return this.props.initialValues.kyc_status === KycStatusType.VALIDATION_ASKED;
    }

    isVerified() {
      return this.props.initialValues.kyc_status === KycStatusType.VALIDATED;
    }

    renderBlockedBody() {
      return (
        <div className="section">
          <span className="section_header">{translate('kyc.youAreBlocked')}</span>
        </div>
      );
    }

    renderVerifiedBody() {
      return (
        <div className="section">
          <span className="section_header">{translate('kyc.youAreVerified')}</span>
        </div>
      );
    }

    renderPendingBody() {
      return (
        <div className="section">
          <span className="section_header">{translate('kyc.awaitingVerification')}</span>
        </div>
      );
    }

    renderForm() {
      const { currentUser, touch, showValidationError, countriesList, currentProfile } = this.props;

      return(
        <>
          <div className="section">
            <span className="section_header">{translate('kyc.selectPersonType')} <Asterisk /></span>
          </div>

          {currentUser.profile_type === 'client' &&
          <KycClientForm
            showValidationError={showValidationError}
            touch={touch}
            isPersonTypeSelected={this.isPersonTypeSelected}
            selectPersonType={this.selectPersonType}
            countriesList={countriesList}
          />
          }

          <div className="horizontal_line" />

          {currentUser.profile_type !== 'client' &&
          <KycEntityForm
            showValidationError={showValidationError}
            touch={touch}
            isPersonTypeSelected={this.isPersonTypeSelected}
            selectPersonType={this.selectPersonType}
            isEntityTypeSelected={this.isEntityTypeSelected}
            selectEntityType={this.selectEntityType}
            countriesList={countriesList}
          />
          }
        </>
      );
    }

  renderSkipKYC() {
    const { currentUser, touch, showValidationError, currentProfile } = this.props;

    return(
      <>
        <div className="horizontal_line" />

        {
        <KycSkipForm
          showValidationError={showValidationError}
          touch={touch}
          isSkipKYCSelected={this.isSkipKYCSelected}
          selectSkipKYC={this.selectSkipKYC}
          isAcceptCheckboxSkipKYC={this.isAcceptCheckboxSkipKYC}
          acceptCheckboxSkipKYC={this.acceptCheckboxSkipKYC}
          currentProfile={currentProfile}
        />
        }
      </>
    );
  }
}

KYC = reduxForm({
    form: 'KYC',
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true,
    enableReinitialize: true,
    validate,
})(KYC)


const getRepresentativeLocation = user => {
    if (_.get(user, 'currentKYC.location', false)) {
        return JSON.parse(user.currentKYC.location)
    }
    return _.get(user, 'currentUser.profile.manager_location', _.get(user, 'currentUser.profile.location'))
};

const checkProfileTypeAndSubType = (user, type, subtype) => {
  return _.get(user, 'currentUser.profile_type', false) === type
    && _.get(user, 'currentUser.profile_subtype', false) === subtype;
};

export default connect(({ user, form, dashboard }) => {

    return {
        currentUser: user.currentUser,
        currentKYC: user.currentKYC,
        isGettingUser: user.isGettingUser,
        countriesList: dashboard.countriesList,
        currentProfile: user.currentProfile,
        initialValues: user.currentUser && user.currentUser.profile ? {
            kyc_person_type: _.get(user, 'currentKYC.kyc_type'),
            kyc_entity_type: _.get(user, 'currentKYC.kyc_subtype'),
            kyc_status: _.get(user, 'currentKYC.status'),
            kyc_first_name: _.get(user, 'currentKYC.first_name', checkProfileTypeAndSubType(user, 'client', 'company') ? user.currentUser.profile.manager_first_name : user.currentUser.profile.first_name),
            kyc_last_name: _.get(user, 'currentKYC.last_name', checkProfileTypeAndSubType(user, 'client', 'company') ? user.currentUser.profile.manager_last_name : user.currentUser.profile.last_name),
            kyc_birth_date: _.get(user, 'currentKYC.born_date', checkProfileTypeAndSubType(user, 'client', 'company') ? user.currentUser.profile.manager_birth_date : user.currentUser.profile.birth_date),
            kyc_over18: !!_.get(user, 'currentKYC.over18', !!user.currentUser.profile.over18),
            kyc_nationality: String(_.get(user, 'currentKYC.nationality', '')) || String(user.currentUser.profile.nationality || ''),
            kyc_email: _.get(user, 'currentKYC.email', user.currentUser.email),
            kyc_location: _.get(user, 'currentKYC.location', false) ? JSON.parse(user.currentKYC.location) : (user.currentUser.profile.location || user.currentUser.profile.manager_location),
            kyc_business_email: _.get(user, 'currentKYC.email', _.get(user, 'currentUser.email', _.get(user, 'currentUser.business_email', user.currentUser.email))),
            kyc_business_name: _.get(user, 'currentKYC.name', user.currentUser.profile.name.trim()),
            kyc_business_location: _.get(user, 'currentKYC.business_location', false) ? JSON.parse(user.currentKYC.business_location) : (user.currentUser.profile.location || user.currentUser.profile.manager_location),
            kyc_company_number: _.get(user, 'currentKYC.company_number'),
            kyc_representative_first_name: _.get(user, 'currentKYC.first_name', _.get(user, 'currentUser.profile.manager_first_name', _.get(user, 'currentUser.profile.first_name', ''))),
            kyc_representative_last_name: _.get(user, 'currentKYC.last_name', _.get(user, 'currentUser.profile.manager_last_name', _.get(user, 'currentUser.profile.last_name', ''))),
            kyc_representative_nationality: ''+_.get(user, 'currentKYC.nationality', _.get(user, 'currentUser.profile.manager_nationality', _.get(user, 'currentUser.profile.nationality', ''))),
            kyc_representative_birth_date: _.get(user, 'currentKYC.born_date', _.get(user, 'currentUser.profile.birth_date', _.get(user, 'currentUser.profile.manager_birth_date', ''))),
            kyc_representative_over18: _.get(user, 'currentKYC.over18', !!_.get(user, 'currentKYC.over18', !!user.currentUser.profile.over18)),
            kyc_representative_email: _.get(user, 'currentKYC.email', _.get(user, 'currentUser.profile.manager_email', _.get(user, 'currentUser.email', ''))),
            kyc_representative_location: getRepresentativeLocation(user),
            kyc_ubo_declaration: _.get(user, 'currentKYC.files', []).filter(file => file.type === 'DECLARATION_UBO'),
            kyc_ind_identity_proof: _.get(user, 'currentKYC.kyc_type') === personTypes.Individual ? _.get(user, 'currentKYC.files', []).filter(file => file.type === 'IDENTITY_PROOF') : [],
            kyc_ent_identity_proof: _.get(user, 'currentKYC.kyc_type') === personTypes.Entity ? _.get(user, 'currentKYC.files', []).filter(file => file.type === 'IDENTITY_PROOF') : [],
            kyc_articles_association: _.get(user, 'currentKYC.files', []).filter(file => file.type === 'ARTICLES_OF_ASSOCIATION'),
            kyc_registration_proof: _.get(user, 'currentKYC.files', []).filter(file => file.type === 'REGISTRATION_PROOF')
        }
            : {},
    }
}, {
    getCountriesTree
})(KYC)


