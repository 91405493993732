import React, { Component } from 'react';
import PropTypes from 'prop-types';
import '../../styles/components/ReviewsRating.scss';

export default class ReviewsRating extends Component {
  static propTypes = {
    rating: PropTypes.object.isRequired,
    onClick: PropTypes.func.isRequired
  };

  constructor(props){
    super(props);

    this.state = {
      selectedRating: 5
    };
  }

  getPercent = (all, part)=>{
    if (all === 0 ){
      return 0;
    } else {
      return Math.round(part*100/all);
    }
  };

  onRatingClick = (number)=>{
    const { onClick } = this.props;
    this.setState({
      selectedRating: number
    });
    onClick(number);
  };

  render() {
    const { rating } = this.props;
    const votesQnt = Object.values(rating.votes).reduce((a, b) => a + b, 0);

    const { selectedRating } = this.state;

    const percent = {
      1: this.getPercent(votesQnt, rating.votes['1']),
      2: this.getPercent(votesQnt, rating.votes['2']),
      3: this.getPercent(votesQnt, rating.votes['3']),
      4: this.getPercent(votesQnt, rating.votes['4']),
      5: this.getPercent(votesQnt, rating.votes['5']),
    };

    return (
      <div className="reviews-ratings">
        <div onClick={(e)=>{ this.onRatingClick(5); }}
          className={selectedRating === 5 ? 'review-item selected' : 'review-item'}>
          <span>5 star</span>
          <div className="review-bar">
            <div style={{width: percent['5'] + '%'}}></div>
          </div>
          <span>{percent['5']}%</span>
        </div>
        <div onClick={(e)=>{ this.onRatingClick(4); }}
          className={selectedRating === 4 ? 'review-item selected' : 'review-item'}>
          <span>4 star</span>
          <div className="review-bar">
            <div style={{width: percent['4'] + '%'}}></div>
          </div>
          <span>{percent['4']}%</span>
        </div>
        <div onClick={(e)=>{ this.onRatingClick(3); }}
          className={selectedRating === 3 ? 'review-item selected' : 'review-item'}>
          <span>3 star</span>
          <div className="review-bar">
            <div style={{width: percent['3'] + '%'}}></div>
          </div>
          <span>{percent['3']}%</span>
        </div>
        <div onClick={(e)=>{ this.onRatingClick(2); }}
          className={selectedRating === 2 ? 'review-item selected' : 'review-item'}>
          <span>2 star</span>
          <div className="review-bar">
            <div style={{width: percent['2'] + '%'}}></div>
          </div>
          <span>{percent['2']}%</span>
        </div>
        <div onClick={(e)=>{ this.onRatingClick(1); }}
          className={selectedRating === 1 ? 'review-item selected' : 'review-item'}>
          <span>1 star</span>
          <div className="review-bar">
            <div style={{width: percent['1'] + '%'}}></div>
          </div>
          <span>{percent['1']}%</span>
        </div>
      </div>
    );
  }
}
