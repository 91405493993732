import './DateRangePicker.scss';
import React from 'react';
import { DateRange } from 'react-date-range';
import { months } from '../../../utils/constants';

export const themeDataRangePicker = {
  DateRange: {
    background: '#fff',
    borderTop: '3px solid #fed135'
  },
  Calendar: {
    width: 350,
    maxWidth: '100%',
    background: '#fff',
    color: '#95a5a6',
    padding: '10px 15px'
  },
  MonthAndYear: {
    background: '#fff',
    color: '#000'
  },
  MonthButton: {},
  MonthArrowPrev: {
    borderRightColor: '#fff',
  },
  MonthArrowNext: {
    borderLeftColor: '#fff',
  },
  Weekday: {
    background: '#fff',
    color: '#a3a3a3',
    fontSize: '16px',
    fontWeight: '500',
  },
  Day: {},
  DaySelected: {
    background: '#fff',
    color: '#a3a3a3',
    border: '1px solid #ffd210',
    borderRadius: '12px'
  },
  DayActive: {
    background: '#ffd210',
    transform: 'translate(-50%,-50%)',
    borderRadius: 10
  },
  DayInRange: {
    background: '#fff',
    color: '#a3a3a3',
    borderTop: '1px solid #ffd210',
    borderBottom: '1px solid #ffd210',
  },
  DayHover: {
    background: 'transparent'
  }
};

export const DateRangePicker = ({ selectDate, readonly, onChange, startDay, endDay, dateIsRange, year, startMonth, endMonth }) => {
  const getSelectedDays = () => {
    const selectedDays = [];
    for (let i = startDay; i <= endDay; i++) {
      selectedDays.push(i);
    }
    return selectedDays.join(', ');
  };

  return (
    <div className={dateIsRange ? 'DataRangePicker isRange' : 'DataRangePicker'}>
      <DateRange
        disableDaysBeforeToday={true}
        onInit={(date) => selectDate && selectDate(date) || onChange && onChange(date)}
        firstDayOfWeek={1}        
        calendars={1}
        theme={themeDataRangePicker}
        onChange={(date) => !readonly && selectDate && selectDate(date) || onChange && onChange(date) }
      />
      <div className="DataRangePicker__bottom">
        <div className="month">
          { months[startMonth] }
        </div>
        <div className="days">
          <span>
            { startMonth === endMonth && endDay - startDay < 4 ? getSelectedDays() : '...' }
          </span>
        </div>
        <div className="year">
          {year}
        </div>
      </div>
    </div>
  );
};
