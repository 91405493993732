const validate = values => {
  const errors = {}

  if (!values.ability_to_travel) {
    errors.ability_to_travel = 'Required'
  }

  return errors
}

export default validate
