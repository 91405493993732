import React, { Component } from 'react';
import Slider from 'material-ui/Slider';
import AvatarEditor from 'react-avatar-editor';
import {SliderComponent} from '../components/register/CommonComponent';

import '../styles/components/AvatarEditor.scss';
import { Asterisk } from './common/Asterisk/Asterisk'

const MAX_FILE_SIZE_IN_MB = 10

export default class PhotoEditor extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  getDataURL(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();

      reader.onload = () => {
        resolve(reader.result);

        reader.onload = null;
      };

      reader.readAsDataURL(file);
    });
  }

  fileChangeHandler = (evt) => {
    const files = evt.target.files;
    const maxFileSize = MAX_FILE_SIZE_IN_MB * 1024 * 1024;

    if (files[0]) {
      if (files[0].size < maxFileSize) {
        this.getDataURL(files[0]).then((data) => {
          this.fileChanged = true;

          this.setState({
            file: files[0],
            photo: data,
          });

          this.positionChangeHandler();
        });
      } else {
        this.props.showValidationError(`${MAX_FILE_SIZE_IN_MB} MB file size exceeded`);
      }
    }
  }

  zoomChangeHandler = (evt, val) => {
    const { input: { onChange, value } } = this.props;

    if (this.state.photo) {
      this.positionChangeHandler({
        x: +value.x,
        y: +value.y,
      }, val - 1);
    }
  }

  positionChangeHandler = (position, zoom) => {
    const { input: { onChange, value } } = this.props;

    let result = {
      ...value,
    };

    if (position) {
      const img = this.editor.getImage();
      const absolutePosition = this.editor.calculatePosition();

      const maxX = this.originalSize.width - img.width;
      const maxY = this.originalSize.height - img.height;

      let offset_x = Math.round(position.x * this.originalSize.width - (img.width / 2));
      let offset_y = Math.round(position.y * this.originalSize.height - (img.height / 2));

      if (offset_x > maxX) {
        offset_x = maxX;
      } else if (offset_x < 0) {
        offset_x = 0;
      }

      if (offset_y > maxY) {
        offset_y = maxY;
      } else if (offset_y < 0) {
        offset_y = 0;
      }

      result = {
        ...result,
        ...position,
        scale_x: img.width / this.originalSize.width,
        scale_y: img.height / this.originalSize.height,
        offset_x,
        offset_y,
      };
    } else {
      result = {
        x: 0.5,
        y: 0.5,
        zoom: 0,
        scale_x: 1,
        scale_y: 1,
        offset_x: 0,
        offset_y: 0,
        ...value,
      };
    }

    if (zoom || zoom === 0) {
      result.zoom = zoom;
    }

    if (this.fileChanged) {
      result.file = this.state.file;
    }

    onChange(result);
  }

  loadSuccessHandler = (info) => {
    this.originalSize = {
      width: info.resource.width,
      height: info.resource.height,
    };

    this.positionChangeHandler();
  }

  render() {
    const { input: { value } } = this.props;
    const { photo } = this.state;

    if (!photo && value) {
      if (value.original_url) {
        setTimeout(() => {
          this.setState({
            photo: value.original_url,
          });
        }, 0);
      } else {
        this.getDataURL(value.file).then((data) => {
          this.setState({
            photo: data,
          });
        });
      }
    }

    return (
      <div className="photo-editor-wrapper photo-editor-wrapper--photo">
        <div className="photo-editor-cover-config">
          <div className="header">
            <div className="title">Edit your cover picture <Asterisk /></div>
          </div>
          <div className="content">
            <div className="cover-default">
              <div className="cover-preview">
                <div className="cover-background">
                  <div className="aspect-ratio">
                    <div className="content">
                      <div className="content-image">
                        <div
                          className="d-inline-block full"
                          ref={ref => { this.editorContainer = ref; }}>
                          <AvatarEditor
                            ref={ref => { this.editor = ref; }}
                            image={photo}
                            borderRadius={0}
                            width={this.editorContainer ? this.editorContainer.clientWidth : 200}
                            height={this.editorContainer ? this.editorContainer.clientHeight : 200}
                            border={0}
                            color={[255, 255, 255, 0.6]} // RGBA
                            scale={value && value.zoom ? +value.zoom + 1 : 1}
                            rotate={0}
                            onPositionChange={this.positionChangeHandler}
                            position={value && value.x ? { x: +value.x, y: +value.y } : { x: 0.5, y: 0.5 }}
                            onLoadSuccess={this.loadSuccessHandler} />
                        </div>
                      </div>
                      {/*<div className="cover-wrapper">*/}
                      {/*  <div className="cover-avatar"></div>*/}
                      {/*  <div className="cover-info"></div>*/}
                      {/*</div>*/}
                    </div>
                  </div>
                </div>
              </div>
              <div className="upload-control">
                <div className="zoom">
                  <SliderComponent
                    max={2}
                    min={1}
                    name="scale"
                    step={0.01}
                    value={value && value.zoom ? +value.zoom + 1 : 1}
                    onChange={this.zoomChangeHandler} />
                </div>
                <div className="upload-btn-wrapper">
                  <input id="upload_cover_photo" type="file" onChange={this.fileChangeHandler} />
                  <label htmlFor="upload_cover_photo">
                    <i className="fa fa-upload" aria-hidden="true"></i>
                    Upload Cover Photo
                  </label>
                </div>
                <div className="upload-hint">
                  <p>Only Upload a jpg or png, size maximum <strong>{MAX_FILE_SIZE_IN_MB} MB</strong></p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
