import React from 'react'
import { connect } from 'react-redux';
import { getActiveGigs } from '../../redux/modules/dashboard'
import { fontSizes, LINK_COLOR } from '../../utils/constants'
import { withRouter } from 'react-router'
import Button from '../common/Button/Button'
import Modal from '../../components/common/Modal/Modal'
import SelectField from '../common/SelectField/SelectField';
import SelectFieldItem from '../common/SelectField/SelectFieldItem';
import Loader from '../discover/Loader/Loader';
import GigCard from '../common/GigCard/GigCard';

@connect(
	({ dashboard, user }) => ({
        activeGigs: dashboard.activeGigs,
        loading: dashboard.isFetchingActiveGigs,
        currentUser: user.currentUser
	}),
	{
		getActiveGigs,
	}
)
/** 
 * Przycisk otwierajacy modal który umożliwia wybranie jednego ze swoich gigów / utworzenia nowego
 * bierze onSuccess(selectedGigId) który robi jak sie uda
 * bierze obowiazkowy props label
 * todo: nazwa moze byc mylaca, PropTypes
 */
class BookNowButton extends React.Component {
    constructor(props) {
        super(props);
        this.state = { 
            openModal: false,
            selectedGigIndex: null,
            selectedGigId: ''
        }
    }
    
    close = () => this.setState({ openModal: false })

    open = () => {
        const { currentUser, history } = this.props;

        if (!currentUser || !currentUser.id){
            history.push('/login');
        } else {
            this.setState({ openModal: true });        
            this.fetchActiveGigs();
        }
    }

    fetchActiveGigs = () => {
        const { getActiveGigs, currentUser, activeGigs, loading } = this.props;
        currentUser && currentUser.id && (!activeGigs || activeGigs.length === 0) && !loading && getActiveGigs(currentUser.id);
    }

    render () {
        const { openModal, selectedGigIndex, selectedGigId } = this.state;
        const { activeGigs = [], loading, style, label, onSuccess, insideModalButtonLabel } = this.props;

        const gigsList = [
            <SelectFieldItem 
                selected={0 === selectedGigIndex}
                value={'CREATE_NEW_GIG'}
                label={<span style={{ fontWeight: 600}}>+ Create new gig</span>}
                onChange={val => this.setState({ selectedGigIndex: 0, selectedGigId: val })}
            />,
            activeGigs.filter(item => !item.current_status).map((item, index) => <SelectFieldItemWithGigCard 
                selected={index + 1 === selectedGigIndex}
                item={item}
                onChange={val => this.setState({ selectedGigIndex: index + 1, selectedGigId: val })}
            />)
        ]

        return <div>
            <Button 
                label={label}
                style={style}
                onMouseEnter={this.fetchActiveGigs}
                onClick={this.open}
                success
            />

            <Modal isOpen={openModal} onClose={this.close} style={{ maxWidth: 600 }} dialogStyles={{ padding: '4rem 5%'}}>
                { loading ? <Loader /> : 
                    <div style={{ display: 'flex', flexDirection: 'column', textAlign: 'center', margin: '40px' }}>
                        <h3 style={{ fontSize: fontSizes.xx_large, fontWeight: 300, marginBottom: '2.5rem', color: '#000' }}>
                            { activeGigs.length ? `Select the gig` : `Create your gig` }
                        </h3>

                        <p style={{ color: '#000', fontSize: fontSizes.medium, margin: '0 1rem 2.5rem 1rem' }}>
                            { activeGigs.length ? 
                                `Please select the gig you would like to hire the vendor for` :
                                `You have no active gigs. In order to book an artist, you need create a gig first` }
                        </p>
                         
                        <div style={{ marginBottom: '2.5rem' }}>
                            {
                                activeGigs.length ?                                 
                                    <SelectField 
                                        value={this.state.selectedGigIndex}
                                        onChange={(idx, val) => this.setState({ selectedGigIndex: idx, val })}
                                        items={gigsList}
                                        style={{ maxHeight: 'calc(80px * 5)', overflowY: 'auto' }}
                                    /> :
                                    <img
                                        src={require('../../assets/book-now/book-now-image-2c.png')} 
                                        style={{margin: 'auto', width: '85%'}}
                                    />
                            }
                        </div>

                        <div>
                        {
                            (!activeGigs.length || selectedGigIndex === 0) &&
                                <Button 
                                    label="+ Create New Gig"
                                    primary
                                    onClick={() => this.props.history.push('/gig/new/start')}
                                /> || selectedGigIndex === null && 
                                <Button 
                                    label={ insideModalButtonLabel || "Proceed to Contract"}
                                    disabled
                                /> || <Button 
                                    label={ insideModalButtonLabel || "Proceed to Contract"}
                                    onClick={() => {
                                        onSuccess(selectedGigId);
                                        this.close();
                                    }}
                                    primary
                                />
                        }    
                        </div>                              
                    </div>
                }
            </Modal>
        </div>
    }
}

export default withRouter(BookNowButton)

class SelectFieldItemWithGigCard extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            showCard: false,
            pinCard: false
        }
    }

    showGigCard = () => this.setState({ showCard: true })

    hideGigCard = () => this.setState({ showCard: false })

    render() {
        const { item, onChange, selected } = this.props;
        const { showCard, pinCard } = this.state;

        return (
            <div onMouseLeave={this.hideGigCard}>
                <SelectFieldItem
                    selected={selected}
                    value={item.id}
                    label={<div
                        className="d-flex align-items-center"
                        style={{ __position: 'relative' }}>

                        {item.name}
                        <span 
                            style={{ marginLeft: 'auto', color: LINK_COLOR }}
                            onMouseOver={this.showGigCard}
                        >View</span>
                        { 
                            <GigCard
                                gig={item}
                                style={{ position: 'absolute', zIndex: 400, top: 200, display: showCard ? '' : 'none' }}
                                noButtons
                            />
                        }
                    </div>}
                    onChange={onChange}
                />
            </div>
        )
    }
} 