import React from 'react'
import { connect } from 'react-redux'
import {
  payForPlan,
  upgradeFreePlan,
  getPlansList,
  changePlan,
} from '../../../redux/modules/dashboard'
import { withRouter } from 'react-router-dom'
import * as _ from 'lodash'
import { Row, Col } from 'reactstrap'
import queryString from 'query-string'
import Button from '../../common/Button/Button'
import Modal from '../../common/Modal/Modal'
import PaymentMethodSelect
  from '../../common/PaymentMethodSelect/PaymentMethodSelect'
import { Asterisk } from '../../common/Asterisk/Asterisk'
import Checkbox from 'material-ui/Checkbox';
import {
  SmallHeading,
  MediumHeading,
  TextFieldComponent
} from '../../CommonComponent'
import { fontSizes, PAYMENT_CYCLES } from '../../../utils/constants'

import Loader from '../../discover/Loader/Loader'
import {
  createCard,
  createCardStep2,
  createBankAccount,
} from '../../../redux/modules/cards'
import { getCurrentUser } from '../../../redux/modules/user'
import classNames from 'classnames'

const closeIcon = require(
  '!!svg-inline-loader?classPrefix!../../../assets/icons/close.svg')
import SVGInline from 'react-svg-inline'
import './Payments.scss'
import PopoverWithList from '../../common/PopoverWithList'
import GigworksPopover from '../../common/GigworksPopover'
import { Text } from '../../DashBoard/Admin/Payments/SharedComponents'
import CouponField from '../../common/CouponField/CouponField'
import { Popover } from 'reactstrap'

import notification from 'antd/es/notification'
import 'antd/es/notification/style/css'
import Spinner from '../../common/Spinner/Spinner'
import {KycStatusType} from "../KYC/KycConstants";
import {TaxIdForm} from "../../DashBoard/Admin/TaxId/TaxId";

@withRouter
@connect(({ dashboard, user }) => ({
  currentUser: user.currentUser,
  plans: dashboard.plans,
  currentPlan: _.get(user, 'currentUser.plan', null),
  currentKYC: _.get(user, 'currentKYC', false),
  isChangingPlan: dashboard.isChangingPlan,
  isGettingUser:  user.isGettingUser,
  currentProfile: user.currentProfile,
}), {
  payForPlan,
  upgradeFreePlan,
  getPlansList,
  createCard,
  createCardStep2,
  createBankAccount,
  getCurrentUser,
  changePlan,
})
class Payments extends React.PureComponent {
  constructor (props) {
    super(props)
    this.state = {
      showPaymentDialog:     false,
      disableConfirmAndPayButton: false,
      disableBillingSection: false,
      selectedPaymentMethod: null,
      paymentInfo:           null,
      couponValue:           '',
      couponValid:           false,
      couponError:           null,
      paymentInfoTable:      null,
      period:                null,
      confirmCharging:       null,
      chargingConsent:       null,
      bankwireInfo:          false,
      isTaxIdModalOpen:      false,
    }
  }

  componentDidMount () {
    const { getPlansList } = this.props

    getPlansList().then(res => {
      const currentPlan = res.data.meta.price.find(plan => plan.current)

      if (currentPlan) {
        this.setState({
          paymentInfoTable: _.get(currentPlan, 'new_cycle.payment_data', null),
        })
      }
    })
  }

  UNSAFE_componentWillMount () {
    const { getPlansList, payForPlan, history, getCurrentUser } = this.props
    const queryStringParams                                     = queryString.parse(
      location.search)

    // when we create new bank account user is redirected to mangopay to create mandate
    // and then back us and now we can pay
    if (queryStringParams.account_id && queryStringParams.MandateId) {
      payForPlan({
        transferType:  'DirectDebitWeb',
        bankAccountId: queryStringParams.account_id,
        //planId: queryStringParams.plan_id,
      }).then(() => {
        console.log('robi sie to')
        getCurrentUser()
        getPlansList()
        history.push({ search: '' })
      })

      // when we create new bank account user is redirected to mangopay to create mandate
      // and then back us and now we can pay
      //if (queryStringParams.account_id && queryStringParams.MandateId) {
      //	this.setState({ activePlan: queryStringParams.plan_id }, () => {
      //		getCurrentUser();
      //		getPlansList();
      //		history.push({ search: '' })
      //	})
      //}
    }

    //const { plans, getPlansList, currentUser, changePlan, history, location } = this.props;
    //const queryStringParams = queryString.parse(location.search);

    // jak wraca z zapłacenia kartą
    //if (queryStringParams.transactionId) {
    //	this.setState({ activePlan: queryStringParams.plan_id }, () => {
    //		this.handleChangePlanAfterPaymentRedirect();
    //	});
    //}

  }

  handlePayment = ({
    cardId = null,
    bankAccountId = null,
    isCardDirect = false,
    isCard = false,
    isDirectDebitDirect = false,
    isDirectDebitWeb = false,
    isBankwire = false,
  }) => {
    const { couponValue, couponValid, disableConfirmAndPayButton } = this.state
    const { currentPlan, payForPlan, location, history, getCurrentUser, getPlansList } = this.props
    this.setState({ showPaymentDialogLoading: true })
    this.setState({ disableConfirmAndPayButton: true })

    if (disableConfirmAndPayButton) {
      return;
    }

    const getTransferType = () => {
      if (isCardDirect) return 'CardDirect'
      if (isCard) return 'Card'
      if (isBankwire) return 'Bankwire'
      if (isDirectDebitWeb) return 'DirectDebitWeb'
      if (isDirectDebitDirect) return 'DirectDebitDirect'
    }
    const selectedCycle   = _.get(currentPlan, 'new_cycle.new_cycle_id')

    payForPlan({
      planId:        currentPlan.plan_id,
      cardId:        isCardDirect ? cardId : undefined,
      bankAccountId: isDirectDebitDirect ? bankAccountId : undefined,
      transferType:  getTransferType(),
      coupon:        couponValid ? couponValue : '',
      cycle:         selectedCycle,
    }).then(res => {
      const transferStatus = _.get(res, 'data.transfer_status')

      if (_.get(res, 'data.executionDetails.SecureModeNeeded')) {
        const url3DRedirect = _.get(res,
          'data.executionDetails.SecureModeRedirectURL')
        window.location.replace(url3DRedirect)
      } else if (_.get(res, 'data.executionDetails.RedirectURL')) {
        window.location.replace(
          _.get(res, 'data.executionDetails.RedirectURL'))
      } else if (transferStatus === 'SUCCEEDED' || transferStatus ===
        'CREATED' || res.data === 'Success change') {
        this.closePaymentDialog()
        getCurrentUser()
        getPlansList()
        this.setState({disableBillingSection: true})

        if (transferStatus === 'CREATED') {
          this.setState({
            bankwireInfo: {
              amount: _.get(res, 'data.transfer_data.amount'),
              wire_reference: _.get(res, 'data.transfer_data.wire_reference'),
              ..._.get(res, 'data.transfer_data.bank_account')
            }
          })
        }

        notification.success({
          message:     'Success!',
          description: 'Congratulations on your new membership plan with Gigworks. Don\'t forget to change your ability to travel to let Clients know you\'re willing to accept gigs regionally or world wide.',
        })
      } else if (transferStatus === 'FAILED') {
        history.push(location.pathname)
        this.closePaymentDialog()
        notification.error({
          message:     'Error!',
          description: "Sorry, you're payment did not go through, please contact a Gigworks' representative to assist you.",
        })
      } else {
        console.error('UNHANDLED ERROR', res.data)
      }
    }).catch(err => {
      const errorStatus = err.response.status
      console.log('ERROR')
      console.log(err)

      if (errorStatus === 400) {
        notification.error({
          message:     'Error!',
          description: `Please, try again later.`,
        })
      } else if (errorStatus === 409) {
        const message        = err.response.data.split(
          'You can change the plan ')
        const currentDate    = moment({ hours: 0 })
        const nextChangeDate = moment(message[1], 'YYYY-DD-MM HH:mm:ss')

        notification.error({
          message:     'Error!',
          description: `You can change the plan in ${nextChangeDate.diff(
            currentDate, 'days')} days`,
        })
      }
    })
  }

  handleChangePlanAfterPaymentRedirect = () => {
    const { changePlan, history, location } = this.props
    const { activePlan }                    = this.state

    changePlan({
      planId: activePlan,
    }).then(_ => {
      this.closePaymentDialog()
      getCurrentUser()
      notification.success({
        message:     'Success!',
        description: 'You have new plan now!',
      })
    }).catch(err => {
      const errorStatus = err.response.status

      if (errorStatus === 400) {
        notification.error({
          message:     'Error!',
          description: `Please, try again later.`,
        })
      } else if (errorStatus === 409) {
        const message        = err.response.data.split(
          'You can change the plan ')
        const currentDate    = moment({ hours: 0 })
        const nextChangeDate = moment(message[1], 'YYYY-DD-MM HH:mm:ss')

        notification.error({
          message:     'Error!',
          description: `You can change the plan in ${nextChangeDate.diff(
            currentDate, 'days')} days`,
        })
      } else {
        notification.error({
          message:     'Error!',
          description: `Please, try again later.`,
        })
      }
    })
  }

  handlePayClick = () => {
    const { selectedPaymentMethod, paymentInfo } = this.state
    if (selectedPaymentMethod === 'CARD' && paymentInfo.Id === 'OTHER' &&
      paymentInfo.save === true) {
      const { cardNumber, cardExpirationDate, cardCvx } = paymentInfo

      this.props.createCard().then(res => {
        const { CardRegistrationURL, PreregistrationData, AccessKey } = res.data.card

        this.props.createCardStep2({
          CardRegistrationURL,
          PreregistrationData,
          AccessKey,
          cardNumber,
          cardExpirationDate,
          cardCvx,
        }).then(res2 => {
          this.handlePayment({
            isCardDirect: true,
            cardId:       res2.data.registeredCard.CardId,
          })
        })
      })
    } else if (selectedPaymentMethod === 'CARD' && paymentInfo.Id === 'OTHER') {
      this.handlePayment({ isCard: true })
    } else if (selectedPaymentMethod === 'CARD') {
      this.handlePayment({ isCardDirect: true, cardId: paymentInfo.Id })
    } else if (selectedPaymentMethod === 'DIRECT_DEBIT' && paymentInfo.Id ===
      'OTHER' && paymentInfo.save === true) {
      const { IBAN, BIC, street, city, zipcode, state, country } = paymentInfo
      this.props.createBankAccount({
        IBAN,
        BIC,
        adress:    {
          street,
          city,
          zipcode,
          state,
          country,
        },
        returnURL: `${window.location.protocol}//${window.location.host}/profile/edit/payments?plan_id=${this.state.activePlan}`,
      }).then(res => {
        const urlToRedirect = _.get(res, 'data.redirect_url')
        window.location.replace(urlToRedirect)
      }).catch(rej => {
        console.error(rej)
      })
    } else if (selectedPaymentMethod === 'DIRECT_DEBIT' && paymentInfo.Id ===
      'OTHER') {
      //this.handlePayment({ isDirectDebitWeb: true })
      const { IBAN, BIC, street, city, zipcode, state, country } = paymentInfo
      this.props.createBankAccount({
        IBAN,
        BIC,
        adress:    {
          street,
          city,
          zipcode,
          state,
          country,
        },
        returnURL: `${window.location.protocol}//${window.location.host}/profile/edit/payments?plan_id=${this.state.activePlan}&remove_after_payment=1`,
      }).then(res => {
        const urlToRedirect = _.get(res, 'data.redirect_url')
        window.location.replace(urlToRedirect)
      }).catch(rej => {
        console.error(rej)
      })
    } else if (selectedPaymentMethod === 'DIRECT_DEBIT') {
      this.handlePayment(
        { isDirectDebitDirect: true, bankAccountId: paymentInfo.Id })
    } else if (selectedPaymentMethod === 'BANK_TRANSFER') {
      this.handlePayment({ isBankwire: true });
    }
  };

  handleChange = event => {
    this.setState({
      confirmCharging: event.target.checked,
      chargingConsent: !this.state.chargingConsent,
    });
  };

  setPlan = ({
    cycle,
    planId,
    coupon = this.state.couponValid ? this.state.couponValue : '',
  }) => {
    const currentPlan = this.getActivePlan()

    const _planId       = _.defaultTo(planId,
      _.get(currentPlan, 'new_cycle.plan_id', _.get(currentPlan, 'id', null)))
    const selectedCycle = _.defaultTo(cycle,
      _.get(currentPlan, 'new_cycle.new_cycle_id',
        _.get(currentPlan, 'cycle', null)))

    this.props.changePlan({
      planId: _planId,
      cycle:  selectedCycle,
      coupon,
    }).then(res => {
      const paymentInfoTable = _.get(res.data, 'listAmount', null)
      paymentInfoTable && this.setState({ paymentInfoTable })

      this.setState({
        couponValid: !_.get(res.data, 'coupon_message.message', false),
        couponError: _.get(res.data, 'coupon_message.message', null),
        period:      _.get(res.data, 'period', null),
      })

      this.props.getCurrentUser()
      this.props.getPlansList()
    }, err => {
      const getErrorMsg = () => {
        return _.get(err, 'response.data.plan_id.0',
          _.get(err, 'response.data', 'Sorry, server error'))
      }
      notification.error({
        message: getErrorMsg(),
      })
    })
  }

  closePaymentDialog = () => this.setState({ showPaymentDialog: false })

  isValid = () => {
    const { selectedPaymentMethod, paymentInfo } = this.state

    return selectedPaymentMethod && paymentInfo &&
      ((paymentInfo.Id === 'OTHER' && paymentInfo.isValid) || paymentInfo.Id !==
        'OTHER')
  }

  UNSAFE_componentWillReceiveProps (nextProps) {
    console.log(
      '_____________________________ WILL RECEIVE _____________________________')
    console.log('W_ plans', nextProps.plans)
    console.log('W_ currentPlan', nextProps.currentPlan)
  }

  getActivePlan = () => {
    const _getActivePlanId = plansArr => {
      const defaultPlan = plansArr.find(plan => plan.current)
      return defaultPlan ? defaultPlan : null
    };

    const plansArr = _.get(this.props, 'plans.meta.price', null)
    if (!plansArr) return null

    return _getActivePlanId(plansArr)
  };

  getCurrentPlanPeriod = (plansArr, currentPlan) => {
    const currentPlanPeriod = plansArr.find(plan => plan.id === currentPlan.id);

    if (currentPlanPeriod) {
      return currentPlanPeriod.period
    }

    return '';
  };

  checkIfKYCIsVerified = (currentKYC) => {
    const { is_complete_kyc, status, is_complete_person_data } = currentKYC;

    return is_complete_kyc && status === KycStatusType.VALIDATED && is_complete_person_data;
  }

  changePaymentDialogShowStatus = (currentKYC, currentProfile, currentUser) => {
    const kycStatus = this.checkIfKYCIsVerified(currentKYC);

    if (!currentKYC && (currentProfile && !currentProfile.skip_kyc && currentUser && currentUser.profile_type !== 'client')) {
      notification.info({
        message: 'KYC not validated yet',
        description: 'You will be able to pay your selected plan after the KYC is validated.',
        duration: 5,
      })
    } else if ((currentProfile && !currentProfile.skip_kyc) && currentKYC && !currentKYC.company_number) {
      notification.info({
        message: 'KYC action required',
        description: 'Please either submit your KYC data or choose SKIP KYC temporarily before proceeding to upgrade your plan.',
        duration: 5,
      })
    } else {
      this.setState({ showPaymentDialog: true })
    }

  }

  tryExecute = (callback) => {
    const { currentUser } = this.props;
    const tax_id = _.get(currentUser, 'profile.tax_id')

    const hasTaxIdFilled = tax_id && tax_id.length > 3
    if (hasTaxIdFilled) {
      callback && callback()
    } else {
      this.openFillTaxIdModal(callback);
    }
  };

  openFillTaxIdModal = (callback) => {
    this.setState({ isTaxIdModalOpen: true, callback: callback })
  };

  closeFillTaxIdModal = () => {
    this.setState({ isTaxIdModalOpen: false })
  };

  render () {
    const { selectedPaymentMethod, paymentInfo, showPaymentDialog, couponError, period, confirmCharging, chargingConsent, disableBillingSection } = this.state;
    const { plans, isChangingPlan, isGettingUser, upgradeFreePlan, history, getCurrentUser, getPlansList, currentKYC, currentProfile, currentUser } = this.props;

    let paymentInfoTable = this.state.paymentInfoTable;
    if (!paymentInfoTable && this.props.currentPlan && this.props.currentPlan.hasOwnProperty('data')) {
      paymentInfoTable = JSON.parse(this.props.currentPlan['data']);
    }

    let totalAmount = 0;
    if(paymentInfoTable) {
      totalAmount = paymentInfoTable['total']['value'];
    }

    const getActivePlanId = plansArr => {
      const defaultPlan = plansArr.find(plan => plan.current);
      return defaultPlan ? defaultPlan : null;
    };

    const plansArr = _.get(plans, 'meta.price', null);
    if (!plansArr) return <Loader/>;

    const currentPlan = getActivePlanId(plansArr);
    const currentPlanPeriod = period || this.getCurrentPlanPeriod(plansArr, currentPlan);
    const currentPlanStatus = _.get(currentPlan, 'payment_status', null)
    const currentPlanIsPaid = currentPlanStatus === 'SUCCEEDED';

    const bankwireInfoToShow = _.get(currentPlan, 'bankwireInfo', {})
    let bankwireInfo = false;

    if (currentPlan.payment_status !== 'SUCCEEDED'
      && Object.keys(bankwireInfoToShow).length !== 0
      && bankwireInfoToShow.constructor === Object) {
      bankwireInfo = bankwireInfoToShow;
    }

    if (this.state.bankwireInfo) {
      bankwireInfo = this.state.bankwireInfo;
    }

    const selectedCycle = _.get(currentPlan, 'new_cycle.new_cycle_id',
      _.get(currentPlan, 'cycle', null))

    return <Row noGutters style={{ paddingLeft: 15, paddingRight: 15 }}>
      <Col xs={12} md={10} className="px-3 py-4 px-md-5">
        {isChangingPlan || isGettingUser && <Spinner/>}

        <Row noGutters className="PaymentsInfoRow my-2 my-md-0">
          <Col md={4} className="d-flex align-items-center">
            Current period
          </Col>

          <Col md={8}
               className="d-flex align-items-center justify-content-end justify-content-md-start">
					<span className="font-weight-bold">
						{currentPlanPeriod}
					</span>
          </Col>
        </Row>

        <Row noGutters className="PaymentsInfoRow my-2 my-md-0">
          <Col md={4} className="d-flex align-items-center">
            Your current plan
          </Col>

          <Col md={8}
               className="d-flex align-items-center justify-content-end justify-content-md-start">
					<span className="font-weight-bold mb-2 mb-md-0">
						{(() => {
              const selectedPlan = plansArr.find(plan => plan.id ===
                _.get(currentPlan, 'id', currentPlan.id));
              if (selectedPlan) {
                return selectedPlan.name.toUpperCase()
              }
              return 'NO'
            })()}
            {' '} PLAN
					</span>
          </Col>
        </Row>

        <Row noGutters className="PaymentsInfoRow my-2 my-md-0">
          <Col md={4}
               className="d-flex align-items-start align-items-md-center">
            Your selected plan
          </Col>

          <Col md={8} className="d-flex flex-column flex-md-row
					justify-content-start justify-content-md-between
					align-items-end align-items-md-center">
					<span className="font-weight-bold mb-2 mb-md-0">
						{(() => {
              const selectedPlan = plansArr.find(plan => plan.id ===
                _.get(currentPlan, 'new_cycle.plan_id', currentPlan.id));
              if (selectedPlan) {
                return selectedPlan.name.toUpperCase()
              }
              return 'NO'
            })()}
            {' '} PLAN
					</span>

            {
              currentPlan.payment_status !== 'SUCCEEDED' && !disableBillingSection && !bankwireInfo ?
                <PopoverWithList
                  target={<Button label="Change Plan" flat simpleBorder small
                                  rounded className="px-3 py-2"/>}
                  onSelect={planId => this.setPlan({planId})}
                  items={plansArr.map(item => ({
                    active: item.id === _.get(currentPlan, 'new_cycle.plan_id',
                      currentPlan.id),
                    value: item.id,
                    label: <div className="d-flex justify-content-between">
                      <span>{item.name}</span>
                      <span>{(+item.amount).toFixed(2)}€/month</span>
                    </div>,
                    description: <span>{item.description}</span>,
                  }))}
                /> :
                null
            }
          </Col>
        </Row>

        <Row noGutters className="PaymentsInfoRow my-2 my-md-0">
          <Col md={4} className="d-flex align-items-center">
            Amount
          </Col>

          <Col md={8}
               className="d-flex align-items-center justify-content-end justify-content-md-start">
					<span className="font-weight-bold">
						{(() => {
              const selectedPlan = plansArr.find(plan => plan.id ===
                _.get(currentPlan, 'id', currentPlan.id))
              if (selectedPlan) {
                return `${parseFloat(
                  Math.round(selectedPlan.amount * 100) / 100).toFixed(2)} €`
              }
              return 0
            })()}

					</span>
          </Col>
        </Row>

        <Row noGutters className="PaymentsInfoRow my-2 my-md-0">
          <Col md={4} className="d-flex align-items-center">
            Status
          </Col>

          <Col md={8}
               className="d-flex align-items-center justify-content-end justify-content-md-start">
					<span className="font-weight-bold">
						{(() => {
						  const isFreeUpgradePromoUsed = _.get(currentPlan, 'is_free_upgrade_promo_used', false);
						  if (isFreeUpgradePromoUsed) {
						    return 'FREE'
              }

              switch (_.get(currentPlan, 'payment_status', null)) {
                case 'SET_PLAN':
                  return 'UNPAID'
                case 'CREATED':
                  return 'UNPAID'
                case 'PENDING':
                  return 'PENDING'
                case 'SUCCEEDED':
                  return 'PAID'
                default:
                  return 'UNPAID'
              }
            })()}
					</span>
          </Col>
        </Row>

        <Row noGutters className="PaymentsInfoRow my-2 my-md-0">
          <Col md={4} className="d-flex align-items-start align-items-md-center">
            Billing cycle
          </Col>

          <Col md={8} className="d-flex flex-column flex-md-row
					justify-content-start justify-content-md-between
					align-items-end align-items-md-center">
					<span className="font-weight-bold mb-2 mb-md-0">
						{(() => {
              if (_.get(currentPlan, 'new_cycle.new_cycle_id', false)) {
                switch (_.get(currentPlan, 'new_cycle.new_cycle_id', null)) {
                  case PAYMENT_CYCLES['1_MONTH']:
                    return 'monthly'
                  case PAYMENT_CYCLES['3_MONTHS']:
                    return '3 months'
                  case PAYMENT_CYCLES['6_MONTHS']:
                    return '6 months'
                  case PAYMENT_CYCLES['12_MONTHS']:
                    return '12 months'
                  default:
                    return 'monthly'
                }
              } else {
                switch (_.get(currentPlan, 'cycle')) {
                  case PAYMENT_CYCLES['1_MONTH']:
                    return 'monthly'
                  case PAYMENT_CYCLES['3_MONTHS']:
                    return '3 months'
                  case PAYMENT_CYCLES['6_MONTHS']:
                    return '6 months'
                  case PAYMENT_CYCLES['12_MONTHS']:
                    return '12 months'
                  default:
                    return 'monthly'
                }
              }
            })()}
					</span>

            {
              currentPlan.payment_status !== 'SUCCEEDED' && !disableBillingSection && !bankwireInfo ?
                <PopoverWithList
                  target={<Button label="Change Cycle" flat simpleBorder small
                                  rounded className="px-3 py-2"/>}
                  onSelect={cycle => this.setPlan({ cycle })}
                  items={[
                    {
                      active: selectedCycle === PAYMENT_CYCLES['1_MONTH'],
                      value:  PAYMENT_CYCLES['1_MONTH'],
                      label:  <div className="d-flex justify-content-start">
                        <span>monthly</span>
                      </div>,
                    },
                    {
                      active: selectedCycle === PAYMENT_CYCLES['3_MONTHS'],
                      value:  PAYMENT_CYCLES['3_MONTHS'],
                      label:  <div className="d-flex justify-content-between">
                        <span>3 months</span>
                        <span>5% discount</span>
                      </div>,
                    },
                    {
                      active: selectedCycle === PAYMENT_CYCLES['6_MONTHS'],
                      value:  PAYMENT_CYCLES['6_MONTHS'],
                      label:  <div className="d-flex justify-content-between">
                        <span>6 months</span>
                        <span>7% discount</span>
                      </div>,
                    },
                    {
                      active: selectedCycle === PAYMENT_CYCLES['12_MONTHS'],
                      value:  PAYMENT_CYCLES['12_MONTHS'],
                      label:  <div className="d-flex justify-content-between">
                        <span>12 months</span>
                        <span>10% discount</span>
                      </div>,
                    },
                  ]}
                /> : null
            }
          </Col>
        </Row>

        {!currentPlanIsPaid && !disableBillingSection && !bankwireInfo &&
          <CouponField
            className="mt-5"
            value={this.state.couponValue}
            onChange={value => this.setState(
              {couponValue: value, couponError: false, couponValid: false})}
            onSubmit={coupon => this.setPlan({coupon})}
            error={couponError}
          />
        }

        {
          disableBillingSection && !bankwireInfo &&
          <div className="d-flex justify-content-center mt-5">
            Payment in progress. Please standby.
          </div>
        }

        {
          bankwireInfo &&
          <div>
            <SmallHeading>
              Please make the transfer to the {_.get(bankwireInfo, 'owner_name')} IBAN provided below:
            </SmallHeading>

            <div>
              <p>
                <b>Amount: </b>
                {(_.get(bankwireInfo, 'amount.Amount', 0) / 100).toFixed(2)} €
              </p>

              <p>
                <b>{_.get(bankwireInfo, 'owner_name')} IBAN:</b> {_.get(bankwireInfo, 'Details.IBAN')}
              </p>

              {_.get(bankwireInfo, 'Details.BIC') && <p>
                <b>BIC:</b> {_.get(bankwireInfo, 'Details.BIC')}
              </p>}

              <p>
                <b>Address:</b>  <br />
                {_.get(bankwireInfo, 'OwnerAddress.AddressLine1')} <br />
                {_.get(bankwireInfo, 'OwnerAddress.AddressLine2')} <br />
                {_.get(bankwireInfo, 'OwnerAddress.City')} -  {_.get(bankwireInfo, 'OwnerAddress.PostalCode')}
              </p>

              <p>
                <b>Reference:</b> {_.get(bankwireInfo, 'wire_reference')}
              </p>
            </div>
          </div>
        }

        {paymentInfoTable && !currentPlanIsPaid && !disableBillingSection && !bankwireInfo && <div className="mt-5">
          {Object.keys(paymentInfoTable).sort((a, b) => {
            let orderTab = [
              'promotion',
              'plan_amount',
              'discount',
              'subtotal',
              'tax',
              'total',
            ];

            const isFeeWaiverUsed = _.get(currentPlan, 'is_fee_waiver_used', false);
            if(isFeeWaiverUsed) {
              orderTab = [
                'promotion',
                'plan_amount',
                'tax',
                'subtotal',
                'discount',
                'total',
              ];
            }

            return orderTab.indexOf(a) - orderTab.indexOf(b);
          }).map(key => {
              const { label, value } = paymentInfoTable[key];
              return <>
                <hr className="m-0"/>
                <BillRow
                  name={<>
								<span className={classNames({
                  'BigText font-weight-bolder': [
                                                  'subtotal',
                                                  'total',
                                                ].includes(key),
                })} style={{
                  marginRight: '79px'
                }}>
									{label}
								</span>
                    {key === 'tax' && <span
                      className="flex-grow-1 text-center text-muted">Non-Refundable</span>}
                    {key === 'total' && <span
                      className="flex-grow-1 text-center text-muted">Non-Refundable</span>}
                  </>}
                  value={<span className={classNames({
                    'BigText font-weight-bolder': [
                                                    'subtotal',
                                                    'total',
                                                  ].includes(key),
                  })}>
								{value !== 'empty' ?
                  isNaN(value) ? value : `${(+value).toFixed(2)}€` : ''}
							</span>}
                />
              </>
            },
          )}

          {currentPlanPeriod &&
          <div className="d-flex justify-content-between mw-600px mt-3">
            <span>
              Service Period
            </span>
            <span className="font-weight-bolder">
              {currentPlanPeriod}
            </span>
          </div>}
        </div>}

        {!currentPlanIsPaid && !disableBillingSection && !bankwireInfo &&
          <div className="charging-consent">
            <Checkbox
              label={
                <>
                  <Asterisk/>{" "}
                  <span>I agree to allow Gigworks to continue charging the card on file for the service plan I have selected until I have downgraded to Free or canceled this account.</span>
                </>
              }
              labelStyle={{color: '#000'}}
              onCheck={this.handleChange}
              value={confirmCharging}
            />
          </div>
        }

        {!currentPlanIsPaid && !disableBillingSection && !bankwireInfo &&
          <div className="d-flex justify-content-center mt-5">
            {_.get(currentPlan, 'payment_status') === 'SET_PLAN' &&
            <Button
              className="w-100 mw-600px"
              success
              label={totalAmount === 0 ? "Upgrade" : "Pay Now"}
              disabled={!chargingConsent}
              onClick={() => this.tryExecute(() => {
                  totalAmount === 0 ? upgradeFreePlan().then(() => {
                    getCurrentUser()
                    getPlansList()
                    history.push({ search: '' })
                  })
                    :
                    this.changePaymentDialogShowStatus(currentKYC, currentProfile, currentUser)
                })
              }
            />}
          </div>
        }

        <Modal
          isOpen={showPaymentDialog}
          onClose={this.closePaymentDialog}
        >

          {/* <div style={{ position: 'fixed', top: 0, left: 50,
						backgroundColor: 'orange', maxWidth: 500, wordWrap: 'break-word'}}>
						<p>
							{JSON.stringify(selectedPaymentMethod)} / <br/>
							{JSON.stringify(paymentInfo)}
						</p>
					</div> */}

          {false && <Spinner/>}

          <div className="p-0 p-sm-4 text-center">
            <p className="text-xs-center">
              Please choose payment method.
              We will use the information provided to collect fee on monthly
              basis.
            </p>

            <PaymentMethodSelect
              // noBankTransfer
              alwaysSave={paymentInfo ? paymentInfo.saveCard : false}
              selectedPaymentMethod={selectedPaymentMethod}
              paymentInfo={paymentInfo}
              onChange={({ paymentInfo, selectedPaymentMethod }) => this.setState(
                {
                  paymentInfo,
                  selectedPaymentMethod,
                })}
            />
          </div>

          <div className="d-flex justify-content-center">
            <Button
              label="Confirm and Pay"
              success
              disabled={!this.isValid()}
              onClick={this.handlePayClick}
            />
          </div>
        </Modal>
        <Modal
          isOpen={this.state.isTaxIdModalOpen}
          onClose={this.closeFillTaxIdModal}
        >
          <div style={{ padding: '40px 0'}}>
            <p>
              Please fill your tax id before process
            </p>

            <TaxIdForm onSuccess={this.state.callback} onClose={this.closeFillTaxIdModal} />
          </div>
        </Modal>
      </Col>
    </Row>
  }
}

export default Payments

const BillRow = ({ name, value }) => <div
  className="d-flex justify-content-between align-items-center py-2">
  <span className="flex-grow-1 d-flex">{name}</span>
  <span className="">{value}</span>
</div>

