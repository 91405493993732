import React, { Component } from 'react';
import '../../../styles/components/band/BandContactInfo.scss';
import { Field, reduxForm } from 'redux-form';
import validate from '../../../helpers/validators/band/BandContactInfo';
import { connect } from 'react-redux';
import { getValues } from '../../../helpers/methods';
import apiClient from '../../../helpers/apiClient';
import {
	TextFieldComponent,
	GoogleMapsAutocomplete,
	PhoneComponent,
	BirthDatePickerComponent,
    CheckboxComponent,
    SelectFieldComponent
} from '../../CommonComponent';
import { Gender } from '../CommonComponent';
import { Asterisk } from '../../common/Asterisk/Asterisk';
import defaultAvatar from '../../../assets/images/default_avatar.jpg';
import classnames from 'classnames';
import { AddMemberPopup } from '../../profile/AddMemberPopup/AddMemberPopup';
import { Modal, ModalBody } from 'reactstrap';
import { IconButton, RaisedButton } from 'material-ui';
import SVGInline from 'react-svg-inline';
import Checkbox from 'material-ui/Checkbox';
import { registrationConfig } from '../../../config';
// import { nationalities } from '../../../utils/constants'
import { getCountriesTree } from '../../../redux/modules/dashboard';
import Loader from '../../discover/Loader/Loader'
import * as _ from "lodash";
import moment from "moment";
import store from "../../../redux/create";

const svg_more_icon = require('!!svg-inline-loader?classPrefix!../../../assets/icons/trash.svg');

class WizardBandContactInfoDetails extends Component {
	constructor(props) {
		super(props);
	}

	clearPopupCache = () => {
		this.props.initialValues.new_member_name ? this.props.initialValues.new_member_name = '' : null;
		this.props.initialValues.option_name ? this.props.initialValues.option_name = '' : null;
	};
  componentDidMount() {
    // const { getCountriesTree } = this.props
    this.props.getCountriesTree();
    let currentValue
    function handleChange() {
      let previousValue = currentValue
      if(store.getState().user.currentProfile.business_locations != undefined){
        currentValue = store.getState().user.currentProfile.business_locations.country
        if (previousValue !== currentValue && previousValue != undefined) {
          if(store.getState().dashboard.countriesTree != undefined){
            const arr = store.getState().dashboard.countriesTree
            const countryCode = store.getState().common.country_code
            for(let i = 0; i < arr.length; i++){
              if(arr[i].code == countryCode){
                store.getState().user.currentProfile.countries[0].temp = arr[i].id
              }
            }
          }
        }
      }
    }
    this.unsubscribe = store.subscribe(() => {
      handleChange()
    });
  }

  componentWillUnmount() {
    this.unsubscribe();
  }

	render() {
		const { initialValues, currentUser, valid } = this.props;
    const invitationInfo = {
      group_name: initialValues.name,
      manager_name: initialValues.manager_first_name + ' ' + initialValues.manager_last_name,
    };
    const isLocationEditable = _.get(currentUser, 'isLocationEditable', false);
    const addressLastUpdate = _.get(currentUser, 'profile.address_last_update', null);

		return (
			<form className="band-contact-info plans-and-venue-profile">
				<div className="band-contact-info__fields band-contact-info__container">
					<div className={classnames('band-contact-info__info-big-title', { filled: initialValues.name })}>
						Your band <Asterisk />
					</div>
					<Field
						name="name"
						type="text"
						style={{ margin: '10px 0 5px' }}
						fullWidth={true}
						component={TextFieldComponent}
						hintText="Band Name"
					/>
				</div>

				<div className="band-contact-info__fields band-contact-info__container">
					<div className={classnames('band-contact-info__info-big-title', { filled: initialValues.name })}>
						Manager or Representative Info <Asterisk />
					</div>

					<div className="">
						<div className="band-contact-info__info-title">
							Legal name <Asterisk />
						</div>
						<Field
							name="manager_first_name"
							type="text"
							style={{ margin: '10px 0 5px' }}
							fullWidth={true}
							component={TextFieldComponent}
							hintText="First Name"
						/>
						<Field
							name="manager_last_name"
							type="text"
							style={{ margin: '10px 0 5px' }}
							fullWidth={true}
							component={TextFieldComponent}
							hintText="Last Name"
						/>
						<Field
							name="manager_company_title"
							type="text"
							style={{ margin: '10px 0 5px' }}
							fullWidth={true}
							component={TextFieldComponent}
							hintText="What is the manager or representative's title within your band?"
						/>
					</div>

					<div className="">
						<div className="band-contact-info__info-title">
							<span
								className="fa-stack info-popup-window"
								data-title={registrationConfig.popupText.groupContactInformation}
							>
								<i className="fa fa-circle-thin fa-stack-2x"/>
								<i className="fa fa-info fa-stack-1x"/>
							</span>
							Manager or Representative's Address and Phone Number
						</div>
            {
              !isLocationEditable ?
                <div className="personal-info__address-additional-info">
                  Since you have updated your location in the past 3 months, this part of the form is now disabled.
                  You will be able to change it after {moment(addressLastUpdate).add(3, 'M').format('D MMM YYYY')}.
                </div> :
                null
            }
						<div style={{ width: '100%', margin: '10px 0 5px 0' }}>
							<Field
								fullWidth
								name="manager_location"
                isLocationEditable={isLocationEditable}
                component={GoogleMapsAutocomplete}
								hintText="Enter Address"
								hintStyle={{
									fontWeight: '300',
									color: '#a3a3a3',
								}}
								style={{
									textAlign: 'left',
									marginRight: '20px',
								}}
							/>
              {(this.props.phoneCodeList && this.props.phoneCodeList.length) ?
                <Field
                  name="manager_phone_number"
                  additional_name="manager_country_code"
                  style={{ margin: '10px 0 5px' }}
                  fullWidth
                  component={PhoneComponent}
                  countries={this.props.phoneCodeList}
                />
                :
                <div className="d-flex justify-content-center">
                  <Loader />
                </div>
              }
						</div>
					</div>

					<div className="">
						<div className="band-contact-info__info-title">
							Gender
						</div>
						<div className="text-left">
							<Field name="manager_gender" component={Gender} />
						</div>
					</div>

          <div className="band-contact-info__nationality">
            <div className={classnames('band-contact-info__info', { filled: initialValues.manager_nationality })}>
              Nationality
            </div>
            <div className="personal-info__nationality" style={{ width: '100%' }}>
              {(this.props.countriesList && this.props.countriesList.length) ?
                <Field
                  name="nationality"
                  type="string"
                  required
                  fullWidth
                  style={{ height: '3rem', textAlign: 'left' }}
                  options={this.props.countriesList}
                  hintText="Please select manager nationality"
                  component={SelectFieldComponent}
                />
                :
                <div className="d-flex justify-content-center">
                  <Loader />
                </div>
              }
            </div>
          </div>

					<div className="">
						<div className="band-contact-info__info-title">
							Date of birth <Asterisk />
						</div>
						<div className="band-contact-info__date-of-birth" style={{ width: '100%' }}>
							<Field
								name="manager_birth_date"
								hintText="Birth day"
								mode="landscape"
								component={BirthDatePickerComponent}
							/>
						</div>
						<div className="mt-3">
							<Field
                name="over18"
                label={<>I confirm that I'm over 18 years old <Asterisk /></>}
                labelStyle={{ width: 'initial'}}
                component={CheckboxComponent}
              />
						</div>
					</div>
				</div>

				<div className="band-contact-info__fields band-contact-info__container">
					<div className="band-contact-info__info-big-title">
						Members <Asterisk />
					</div>
					<MembersInfo
            isBand={true}
            clearPopupCache={this.clearPopupCache}
            valid={valid}
            invitationInfo={invitationInfo}
            phoneCodeList={this.props.phoneCodeList}
          />
				</div>
			</form>
		)
	}
}

WizardBandContactInfoDetails = reduxForm({
	form: 'BandContactInfo',
	destroyOnUnmount: false,
	forceUnregisterOnUnmount: false,
	validate,
})(WizardBandContactInfoDetails)

export default connect(({ user, form, dashboard }) => ({
  currentUser: user.currentUser,
  countriesList: dashboard.countriesList,
  phoneCodeList: dashboard.phoneCodeList,
  initialValues: getValues(
		form.BandContactInfo,
    user.currentUser && user.currentUser.profile ? {
				name: user.currentUser.profile.name,
				manager_first_name: user.currentUser.profile.manager_first_name,
				manager_last_name: user.currentUser.profile.manager_last_name,
				manager_company_title: user.currentUser.profile.manager_company_title,
				manager_location: user.currentUser.profile.manager_location || {},
				manager_phone_number: user.currentUser.profile.manager_phone_number,
        manager_country_code: user.currentUser.profile.manager_phone_number ? user.currentUser.profile.manager_phone_number.country_code : null,
        manager_gender: user.currentUser.profile.manager_gender,
        nationality: String(user.currentUser.profile.nationality),
				manager_birth_date: user.currentUser.profile.manager_birth_date,
				over18: !!user.currentUser.profile.over18,
			}
		:
			{}
	),
}), {
    getCountriesTree
})(WizardBandContactInfoDetails)

export class MembersInfo extends Component {
	state = {
		members: []
	};

	UNSAFE_componentWillMount() {
		this.getMembers();
	}

	getMembers = () => {
		apiClient.get('/api/users/me').then(res => {
			apiClient.get(`/api/users/${res.data.id}/profile`).then(res => {
				this.setState({
					members: res.data.members || []
				});
			})
		})
	};

	addNewMember = evt => {
    const { valid } = this.props;

    if (!valid) {
      return;
    }

		evt.preventDefault();
		this._closeAddMemberPopUp.show();
	};

	render() {
    const { isBand, valid, invitationInfo, phoneCodeList } = this.props;

		return (
			<div>
				<div className="mt-3 d-flex flex-wrap">
					{this.state.members.map((member, index) => (
						<MemberInfoComponent
							member={member}
							key={'memInf' + index}
							index={index}
							isBand={isBand}
							// onChange={(member) => {
							// 	const newMembers = this.state.members
							// 	newMembers[index] = member
							// 	this.setState({ members: newMembers }, () => {
							// 		apiClient.put('/api/users/me', { members: this.state.members })
							// 	})
							// }}
							reloadMembers={this.getMembers}
						/>
					))}
					<div className={`band-member-container new-member ${!valid ? 'disabled' : ''}`}>
						<div className="band-member-wrapper" onClick={this.addNewMember}>
							<div className="member-avatar-wrapper">
								<div className="member-avatar">
									<i className="fa fa-plus" aria-hidden="true"></i>
								</div>
							</div>
							<p style={{ marginTop: '1rem' }}>ADD NEW<br/>MEMBER</p>
						</div>
					</div>
				</div>
				<AddMemberPopup
					ref={(ref) => (this._closeAddMemberPopUp = ref)}
					isBand={isBand}
          invitationInfo={invitationInfo}
					onSuccesfullyAdded={this.getMembers}
					clearPopupCache={this.props.clearPopupCache}
          phoneCodeList={phoneCodeList}
				/>
			</div>
		)
	}
}

export class MemberInfoComponent extends Component {
	constructor(props) {
		super(props);
		this.state = {
			showMoreModal: false
		};

		this.onModalHide = this.onModalHide.bind(this)
	}

	onModalHide(needReload = false) {
		if (needReload && this.props.reloadMembers) {
			this.props.reloadMembers()
		}
		this.setState({ showMoreModal: false })
	}

	render() {
        const { member, isBand } = this.props;

		return (
			<div className={`band-member-container ${member.is_manager ? 'manager' : ''}`}>
				{	member.avatar_url ?
					<img className="cover-img" src={ member.avatar_url } />
					: null
				}
				<div className="buttons">
          <IconButton
            style={{ padding: 0, height: '16px', width: '16px' }}
            onClick={() => { this.setState({ showMoreModal: true })}}
          >
            <SVGInline svg={svg_more_icon} fill='#f0d' />
          </IconButton>
          <DeleteMemberModal
            member={JSON.parse(JSON.stringify(member))}
            show={this.state.showMoreModal}
            hide={this.onModalHide}
          />

				</div>
				<div className="band-member-wrapper">
					<div className="member-avatar-wrapper">
						<div className="member-avatar">
							<img src={ member.avatar ? (JSON.parse(member.avatar).url || defaultAvatar) : defaultAvatar } />
						</div>
					</div>
					<p className="member-name">
						{member.first_name || '\u00A0'}<br/>
						{member.last_name || '\u00A0'}
					</p>
					<p className="member-description">
						{member.talent || member.instrument || '\u00A0'}
					</p>
					<p className={classnames('member-status', { waiting: !member.is_verified })}>
						{
							!member.is_verified ?
                'Waiting for confirmation' :
                member.is_manager ? 'MANAGER' :
                  'GROUP MEMBER'
						}
					</p>
				</div>
			</div>
		)
	}
}

export class DeleteMemberModal extends Component {
	constructor(props) {
	  super(props);
	}

	onDelete(member) {
		apiClient.delete(`/api/member?user_id=${member.parent_user_id}&member_id=${member.user_id}`)
		this.props.hide(true);
	}

	onCancel() {
		this.props.hide();
	}


	render () {
		const { show, hide, member } = this.props;

		return (
			<Modal
				isOpen={show}
				backdrop={true}
				hide={hide}
				toggle={hide}
				className="modal-dialog-centered"
				style={{ maxWidth: '400px'}}
				size="lg"
			>
				<ModalBody>
					<div className="band-contact-info__delete-label">
						{'Are you sure to delete this member?'}
					</div>
					<div style={{ display: 'flex', justifyContent: 'space-between' }}>
						<RaisedButton
							primary
							label="Delete"
							style={{ height: '2.6rem' }}
							labelStyle={{ lineHeight: '2.6rem', width: '100%' }}
							buttonStyle={{ backgroundColor: 'red' }}
							onClick={() => {
								this.onDelete(member)
							}}
						/>
						<RaisedButton
							primary
							label="Cancel"
							style={{ height: '2.6rem' }}
							labelStyle={{ lineHeight: '2.6rem', width: '100%' }}
							onClick={() => {
								this.onCancel()
							}}
						/>
					</div>
				</ModalBody>
			</Modal>
		)
	}
}

export class MemberInfoMoreModal extends Component {
	constructor(props) {
		super(props);

		this.state = {
			member: props.member
		}
	}


	render () {
		const { isBand, show, hide, saveMembers } = this.props;
		const { member } = this.state;

		return (
			<Modal
				isOpen={show}
				backdrop={true}
				hide={hide}
				toggle={hide}
				className="modal-dialog-centered"
				style={{ maxWidth: '500px'}}
				size="lg"
			>
			<ModalBody>
				<div
          className="band-contact-info__fields band-contact-info__container add-member-popup-header"
          style={{ display: 'flex', flexDirection: 'row', marginLeft: '20px' }}
        >
          <div className="band-contact-info__info">
            <Checkbox
              id="id_is_manager"
              name="is_manager"
              style={{ width: 'auto' }}
              checked={member.is_manager}
              onCheck={(_, value) => this.setState({ member: {...member, is_manager: value } }) }
            />

            <label htmlFor="id_is_manager">
              Select as { isBand ? 'Band' : 'Group' } manager
            </label>

            <span className="info-popup-window pl-2" data-title={`Whether this member is a manager`}>
              <i className="fa fa-info-circle"></i>
            </span>
          </div>
        </div>
        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
				  <RaisedButton
						primary
						label="Save"
						style={{
							height: '2.6rem',
						}}
						labelStyle={{ lineHeight: '2.6rem', width: '100%' }}
        				onClick={() => {
							hide(member)
						}}
          />
        </div>
			</ModalBody>
		  </Modal>
		)
	}
}
