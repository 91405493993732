import React, { Component } from 'react';
import PriceListPopup from '../PriceListPopup';
import getSymbolCurrency from '../../helpers/getSymbolCurrency';

export default class ProfilePrices extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isPopupOpened: false
    };
  }

  switchPopup = () => this.setState(({ isPopupOpened }) => ({
      isPopupOpened: !isPopupOpened
  }));

  render() {
    const { profile, isCompare, close } = this.props;
    const fee_primary = profile.fee_primary || {};
    const fee_secondary = profile.fee_secondary || {};
    const { services, name, first_name, last_name } = profile;
    const fullName = first_name && last_name
      ? `${first_name} ${last_name}`
      : name;

    return (
      <div className="profile__prices">
        {
          !isCompare &&
          <PriceListPopup isOpened={this.state.isPopupOpened}
            onClose={this.switchPopup}
            priceList={services}
            name={fullName} />
        }
        <div>
          <p className="price-list">
            <a onClick={isCompare ? close : this.switchPopup}>
              <span style={{ fontWeight: '300' }}>View</span>
              <br/>
              <b>Price List</b>
            </a>
          </p>

          <p className="currency-text">
            <text>
              <b>{fee_primary.value || '-'}</b>{getSymbolCurrency(fee_primary.currency) || ''}
            </text>
            <br />per {fee_primary.period || 'period'}
          </p>

          <p className="currency-text">
            <text>
              <b>{fee_secondary.value || '-'}</b>{getSymbolCurrency(fee_secondary.currency) || ''}
            </text>
            <br />per {fee_secondary.period || 'period'}
          </p>
        </div>
        {this.state.showPriceList && <table className="table table-striped" style={{ marginBottom: '0' }}>
        </table>}
      </div>
    );
  }
}
