import React, { Component } from 'react';
import { Field, reduxForm } from 'redux-form';
import validate from '../../../helpers/validators/fan/FanInfo';
import { connect } from 'react-redux';
import { getValues } from '../../../helpers/methods';
import { Interests } from '../CommonComponent';
import { TextFieldComponent } from '../../CommonComponent';
import { Asterisk } from '../../common/Asterisk/Asterisk';
import { registrationConfig } from '../../../config';
import { MAX_ABOUT_INTRODUCTION_LENGTH, MAX_BRIEF_INTRODUCTION_LENGTH } from '../../../utils/constants';

class FanInfo extends Component {
  render() {
    const { handleSubmit } = this.props;

    const maxLength = max => value => {

      let v;
      let result = value.length > max;

      if(result === false) {
        v = value;
      }
      return v;
    };

    return (
      <form className="service-info-form" onSubmit={handleSubmit}>
        <div className="service-info-form__wrapper">
          <div className="service-info-form__item-header">
            <span className="fa-stack info-popup-window" data-title={registrationConfig.popupText.fanBriefIntroduction}>
              <i className="fa fa-circle-thin fa-stack-2x"></i>
              <i className="fa fa-info fa-stack-1x"></i>
            </span>
            <h4 className="service-info-form__title">
              Brief introduction <Asterisk/><span className="description">(Max. 155 characters)</span>
            </h4>
          </div>
          <Field
            hintText="Start writing here"
            name='brief_introduction'
            style={{ margin: '10px 0 5px' }}
            inputStyle={{ color: '#000' }}
            fullWidth={true}
            normalize={maxLength(MAX_BRIEF_INTRODUCTION_LENGTH)}
            component={TextFieldComponent}
          />
        </div>
        <div className="service-info-form__wrapper">
          <div className="service-info-form__item-header">
            <span className="fa-stack info-popup-window" data-title={registrationConfig.popupText.fanAboutSection}>
              <i className="fa fa-circle-thin fa-stack-2x"/>
              <i className="fa fa-info fa-stack-1x"/>
            </span>
            <h4 className="service-info-form__title">
              About section <span className="description">(Max. 1000 characters)</span>
            </h4>
          </div>
          <Field
            hintText="Start writing here"
            name='about'
            style={{ margin: '10px 0 5px' }}
            multiLine={true}
            fullWidth={true}
            component={TextFieldComponent}
          />
        </div>
        <div className="service-info-form__wrapper">
          <div className="service-info-form__item-header">
            <h4 className="service-info-form__title">
              Select your interests
            </h4>
          </div>
          <Field
            name='categories'
            component={Interests}
          />
        </div>
      </form>
    );
  }
}

FanInfo = reduxForm({
  form: 'FanInfo',
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: false,
  enableReinitialize: true,
  validate
})(FanInfo);

//TODO fields
export default connect(
  ({ user, form }) => (
    {
      currentUser: user.currentUser,
      initialValues: getValues(form.FanInfo, user.currentUser && user.currentUser.profile ? {
        brief_introduction: user.currentUser.profile.brief_introduction,
        about: user.currentUser.profile.about,
        categories: user.currentUser.categories || [],
      } : {})
    })
)(FanInfo);
