import React, { Component } from 'react';
import { block } from 'bem-cn';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { UserPic } from '../../../common/UserPic/UserPic';

import './CompareItem.scss';

const blk = block('CompareItem');

export class CompareItem extends Component {
  static propTypes = {
    item: PropTypes.object,
  };

  onRemove = () => {
    const { onRemove, item } = this.props;

    if (onRemove) {
      onRemove(item);
    }
  };

  render() {
    const { item } = this.props;

    return (
      <div className={classNames(blk(), 'col-12')}>
        <UserPic src={item.avatar}  profileCompleted ={true} />

        <div className={blk('info').mix('short-info')}>
          <div className={blk('info-title')}>
            {item.name}
          </div>

          <div className={blk('info-subtitle')}>
            {item.brief_introduction}
          </div>

          <div className={blk('info-location')}>
            {
              item.location ?
                `${item.location.city}, ${item.location.country}` :
                <span>&nbsp;</span>
            }
          </div>

          <button
            className={blk('remove').mix('close')}
            onClick={this.onRemove}
          >
            <span aria-hidden="true">×</span>
          </button>
        </div>
      </div>
    );
  }
}
