import './RegisterHeader.scss';
import React, { Component } from 'react';
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import LinearProgress from 'material-ui/LinearProgress';
import PropTypes from 'prop-types';
import { ConfirmPopUp } from '../../common/ConfirmPopUp/ConfirmPopUp';
import classNames from 'classnames';
import { block } from 'bem-cn';
import { AlertPopUp } from '../../common/AlertPopUp/AlertPopUp';

const b = block('RegisterHeader');

class RegisterHeader extends Component {
  static propTypes = {
    currentForm: PropTypes.shape({
      form: PropTypes.number,
      type: PropTypes.string
    })
  };

  constructor(props) {
    super(props);
  }

  _closeAlertPopUp = null;
  _closeConfirmPopUp = null;

  changeRoute = (url) => {
    this.props.history.push(url);
  };

  closeHandler = () => {
    const { currentForm } = this.props;
    if (currentForm.form <= -2 || currentForm.form === 1) {
      this.changeRoute('/');
    }
    this.props.currentForm.form <= 1 && this.props.currentForm.form !== -1
      ? this._closeAlertPopUp.show()
      : this._closeConfirmPopUp.show();
  };

  onAlertOkClick = () => {
    this._closeAlertPopUp.hide();
    const { onClose } = this.props;
    if (onClose) {
      onClose();
    } else {
      this.changeRoute('/');
    }
  };

  onConfirmCancelClick = () => {
    this._closeConfirmPopUp.hide();
  };

  onConfirmOkClick = () => {
    this._closeConfirmPopUp.hide();
    const { onClose } = this.props;
    if (onClose) {
      onClose();
    } else {
      this.changeRoute('/');
    }
  };

  getConfig = (currentForm) => {
    if (currentForm.type === 'profile') {
      switch (currentForm.form) {
        case -2: {
          return {
            formHeader: '',
            progressPercents: 0,
          };
        }
        case -1: {
          return {
            formHeader: 'Almost there!',
            progressPercents: 20,
          };
        }
        //accountInfo
        case 1 : {
          return {
            formHeader: 'Complete your profile for full access to all Gigworks has offer!',
            progressPercents: 20,
          };
        }
        case 2 : {
          return {
            formHeader: 'Tell us about yourself!',
            progressPercents: 0,
          };
        }
        default: {
          return {
            formHeader: 'Tell us about yourself',
            progressPercents: 20,
          };
        }
      }
    } else if (currentForm.type === 'media') {
      switch (currentForm.form) {
        default: {
          return {
            formHeader: 'More you fill your profile, more we let you navigate',
            progressPercents: 20,
          };
        }
      }
    }
  };

  render() {
    const { currentForm } = this.props;
    const { formHeader, progressPercents } = this.getConfig(currentForm);

    return (
      <div className={classNames(b(), b('register'), 'd-flex justify-content-between align-items-center')}>
        <Link to="/" className={classNames(b('logo'))}>
          <img
            src={require('../../../assets/login-logo.svg')}
            onClick={() => this.changeRoute('/')}
            alt="logo"
            className={'logo show-sm'}/>
        </Link>
        <div className={b('notifications')}>{formHeader}</div>
        <button
          className={classNames(b('close'), 'header--register-close', 'btn')}
          onClick={() => this.changeRoute('/') }>
          <img
            className="register-header-close" style={{ width: '24px', height: '24px', cursor: 'pointer' }}
            src={require('../../../assets/login-x-mark.svg')} alt=""/>
        </button>
        {/* <LinearProgress
          className={classNames(b('progress')())}
          style={{ position: 'absolute', bottom: '-2px', left: '0', backgroundColor: 'none' }}
          mode="determinate" value={progressPercents}/> */}
        <AlertPopUp
          ref={(ref) => (this._closeAlertPopUp = ref)}
          title={`POP UP`}
          text={'You can proceed with the registration, however, your profile will only be visible on the ' +
          'Gigworks platform once you confirm your email. Your profile Will NOT appear on ' +
          'clients\' searches. or Gigworks Discover or Search sections until you complete your ' +
          'registration and profile information and confirm your email address.'}
          okBtn={{
            label: `Ok`,
            onClick: this.onAlertOkClick
          }}
        />
        <ConfirmPopUp
          ref={(ref) => (this._closeConfirmPopUp = ref)}
          title={`POP UP`}
          text={'You can proceed with the registration, however, your profile will only be visible on the ' +
          'Gigworks platform once you confirm your email. Your profile Will NOT appear on ' +
          'clients\' searches. or Gigworks Discover or Search sections until you complete your ' +
          'registration and profile information and confirm your email address.'}
          okBtn={{
            label: `Leave and save`,
            onClick: this.onConfirmOkClick
          }}
          cancelBtn={{
            label: `Continue registration`,
            onClick: this.onConfirmCancelClick
          }}
        />
      </div>
    );
  }
}


export default withRouter(RegisterHeader);
