import * as _ from 'lodash';
import queryString from 'query-string';
import apiClient from '../../helpers/apiClient';
import { SEPA_COUNTRIES } from '../../utils/constants';

export const CREATE_CARD_START = 'CREATE_CARD_START'
export const CREATE_CARD_SUCCESS = 'CREATE_CARD_SUCCESS'
export const CREATE_CARD_FAIL = 'CREATE_CARD_FAIL'

export const CREATE_CARD_STEP2_START = 'CREATE_CARD_STEP2_START'
export const CREATE_CARD_STEP2_SUCCESS = 'CREATE_CARD_STEP2_SUCCESS'
export const CREATE_CARD_STEP2_FAIL = 'CREATE_CARD_STEP2_FAIL'

export const REMOVE_CARD_START = 'REMOVE_CARD_START'
export const REMOVE_CARD_SUCCESS = 'REMOVE_CARD_SUCCESS'
export const REMOVE_CARD_FAIL = 'REMOVE_CARD_FAIL'

export const FETCH_CARDS_LIST_START = 'FETCH_CARDS_LIST_START'
export const FETCH_CARDS_LIST_SUCCESS = 'FETCH_CARDS_LIST_SUCCESS'
export const FETCH_CARDS_LIST_FAIL = 'FETCH_CARDS_LIST_FAIL'

export const GET_CARD_START = 'GET_CARD_START'
export const GET_CARD_SUCCESS = 'GET_CARD_SUCCESS'
export const GET_CARD_FAIL = 'GET_CARD_FAIL'

export const FETCH_BANK_ACCOUNTS_LIST_START = 'FETCH_BANK_ACCOUNTS_LIST_START'
export const FETCH_BANK_ACCOUNTS_LIST_SUCCESS = 'FETCH_BANK_ACCOUNTS_LIST_SUCCESS'
export const FETCH_BANK_ACCOUNTS_LIST_FAIL = 'FETCH_BANK_ACCOUNTS_LIST_FAIL'

export const CREATE_BANK_ACCOUNT_START = 'CREATE_BANK_ACCOUNT_START'
export const CREATE_BANK_ACCOUNT_SUCCESS = 'CREATE_BANK_ACCOUNT_SUCCESS'
export const CREATE_BANK_ACCOUNT_FAIL = 'CREATE_BANK_ACCOUNT_FAIL'

export const REMOVE_BANK_ACCOUNT_START = 'REMOVE_BANK_ACCOUNT_START'
export const REMOVE_BANK_ACCOUNT_SUCCESS = 'REMOVE_BANK_ACCOUNT_SUCCESS'
export const REMOVE_BANK_ACCOUNT_FAIL = 'REMOVE_BANK_ACCOUNT_FAIL'

const initialState = {
	card: {},
	isCardCreating: false,
	isCardRemoving: false,
	cardsList: [],
	isCardsListFetching: false,

	isFetchingBankAccounts: false,
	bankAccountsList: [],
	isCreatingBankAccount: false,
	isRemovingBankAccount: false,
}

export default function cards(state = initialState, action) {
	switch (action.type) {
		case CREATE_CARD_START:
			return {
				...state,
				isCardCreating: true,
			}

		case CREATE_CARD_SUCCESS:
			return {
				...state,
				isCardCreating: false,
				card: action.result.data,
			}

		case CREATE_CARD_FAIL:
			return {
				...state,
				isCardCreating: false,
			}
		case CREATE_CARD_STEP2_START:
			return {
				...state,
				isCardCreating: true,
			}

		case CREATE_CARD_STEP2_SUCCESS:
			return {
				...state,
				isCardCreating: false,
			}

		case CREATE_CARD_STEP2_FAIL:
			return {
				...state,
				isCardCreating: false,
			}
		case REMOVE_CARD_START:
			return {
				...state,
				isCardRemoving: true,
			}

		case REMOVE_CARD_SUCCESS:
			return {
				...state,
				isCardRemoving: false,
				cardsList: JSON.parse(JSON.stringify(
					state.cardsList.filter(x => action.id !== x.Id)
				))
			}

		case REMOVE_CARD_FAIL:
			return {
				...state,
				isCardRemoving: false,
			}
		case GET_CARD_START:
			return {
				...state,
				isGettingCard: true,
			}
		case GET_CARD_SUCCESS:
			return {
				...state,
				isGettingCard: false,
			}
		case GET_CARD_FAIL:
			return {
				...state,
				isGettingCard: false,
			}
		case FETCH_CARDS_LIST_START:
			return {
				...state,
				isCardsListFetching: true,
			}

		case FETCH_CARDS_LIST_SUCCESS:
			return {
				...state,
				isCardsListFetching: false,
				//cardsList: action.result.data,				
				cardsList: _.values(action.result.data),
			}

		case FETCH_CARDS_LIST_FAIL:
			return {
				...state,
				isCardsListFetching: false,
			}

		case FETCH_BANK_ACCOUNTS_LIST_START:
			return {
				...state,
				isFetchingBankAccounts: true,
			}

		case FETCH_BANK_ACCOUNTS_LIST_SUCCESS:
			return {
				...state,
				isFetchingBankAccounts: false,
				bankAccountsList: _.values(action.result.data).map(item => {
					if (SEPA_COUNTRIES.includes(_.get(item, 'account.OwnerAddress.Country', false))) {
						item.sepa = true
					} else {
						item.sepa = false
					}
					return item					
				}),
			}

		case FETCH_BANK_ACCOUNTS_LIST_FAIL:
			return {
				...state,
				isFetchingBankAccounts: false,
			}

		case CREATE_BANK_ACCOUNT_START:
			return {
				...state,
				isCreatingBankAccount: true,
			}

		case CREATE_BANK_ACCOUNT_SUCCESS:
			return {
				...state,
				isCreatingBankAccount: false,
			}

		case CREATE_BANK_ACCOUNT_FAIL:
			return {
				...state,
				isCreatingBankAccount: false,
			}
			
		case REMOVE_BANK_ACCOUNT_START:
			return {
				...state,
				isRemovingBankAccount: true,
			}

		case REMOVE_BANK_ACCOUNT_SUCCESS:
			return {
				...state,
				isRemovingBankAccount: false,
				bankAccountsList: JSON.parse(JSON.stringify(
					state.bankAccountsList.filter(x => action.id !== x.Id)
				))
			}

		case REMOVE_BANK_ACCOUNT_FAIL:
			return {
				...state,
				isRemovingBankAccount: false,
			}

		default:
			return state
	}
}

export function createCard() {
	return {
		types: [CREATE_CARD_START, CREATE_CARD_SUCCESS, CREATE_CARD_FAIL],
		promise: client => client.post(`/api/billing/create-card`),
	}
}

export function removeCard(card_id) {
	return {
		types: [REMOVE_CARD_START, REMOVE_CARD_SUCCESS, REMOVE_CARD_FAIL],
		promise: client => client.put(`/api/billing/remove-card`, { card_id }),
		id: card_id,
	}
}

export function getCard(id) {
	return {
		types: [GET_CARD_START, GET_CARD_SUCCESS, GET_CARD_FAIL],
		promise: client => client.get(`/api/billing/card/${id}`),
	}
}

export function getCardsList() {
	return {
		types: [FETCH_CARDS_LIST_START, FETCH_CARDS_LIST_SUCCESS, FETCH_CARDS_LIST_FAIL],
		promise: client => client.get(`/api/billing/cards`),
	}
}

export function createCardStep2({
	CardRegistrationURL,
	PreregistrationData,
	AccessKey,
	cardNumber,
	cardExpirationDate,
	cardCvx,
}) {
	return {
		types: [CREATE_CARD_STEP2_START, CREATE_CARD_STEP2_SUCCESS, CREATE_CARD_STEP2_FAIL],
		promise: () => new Promise((resolve, reject) => {
			const data = {
				data: PreregistrationData,
				accessKeyRef: AccessKey,
				cardType: 'CB_VISA_MASTERCARD',
				cardNumber: cardNumber.split(' ').join(''),
				cardExpirationDate: cardExpirationDate.split('/').join(''),
				cardCvx,
			}

			function createCORSRequest(method, url) {
				var XDomainRequest = undefined;
				var xhr = new XMLHttpRequest();
				if ("withCredentials" in xhr) {
					xhr.open(method, url, true);		  
				} else if (typeof XDomainRequest != "undefined") {		  
					xhr = new XDomainRequest();
					xhr.open(method, url);		  
				} else {
					xhr = null;		  
				}			
				return xhr;
			}
			
			var xhr = createCORSRequest('POST', CardRegistrationURL);

			xhr.setRequestHeader("Content-Type", "application/x-www-form-urlencoded");

			xhr.onload = () => {
				const text = xhr.responseText;

				apiClient.put(`/api/billing/regist-card`, {
					data: queryString.parse(text).data,
				})
				.then(res => {
					console.log('SUKCES STEP 3!', res)
					resolve(res)
				})
				.catch(rej => {
					console.log('REJECT STEP 3!', rej)
					reject(rej)
				})
			};

			if (!xhr) {
				throw new Error('CORS not supported');			
			}

			const dataToSend = queryString.stringify(data)
			xhr.send(dataToSend);
		})
	}
}

// todo make new module for it
export function getBankAccountsList() {
	return {
		types: [FETCH_BANK_ACCOUNTS_LIST_START, FETCH_BANK_ACCOUNTS_LIST_SUCCESS, FETCH_BANK_ACCOUNTS_LIST_FAIL],
		promise: client => client.get(`/api/billing/bank-accounts`),
	}
}

export function createBankAccount({ IBAN, BIC, adress, returnURL }) {
	return {
		types: [CREATE_BANK_ACCOUNT_START, CREATE_BANK_ACCOUNT_SUCCESS, CREATE_BANK_ACCOUNT_FAIL],
		promise: client => client.post(`/api/billing/create-account`, { 
			iban: IBAN.split(' ').join(''),
			bic: BIC.split(' ').join(''),
			returnURL,
			adress,
		}),
	}
}

export function removeBankAccount(bank_account_id) {
	return {
		types: [REMOVE_BANK_ACCOUNT_START, REMOVE_BANK_ACCOUNT_SUCCESS, REMOVE_BANK_ACCOUNT_FAIL],
		promise: client => client.put(`/api/billing/remove-bank-account`, { bank_account_id }),
		id: bank_account_id,
	}
}

export function setBankAccountAsDefault(bank_account_id) {
	return {
		types: [null, null, null],
		promise: client => client.put(`/api/default-bank-account`, { bank_account_id })
	}
}