import axios from 'axios'
import config from '../config'

axios.defaults.headers.common = {
    'X-Requested-With': 'XMLHttpRequest',
    'Accept': 'application/json'
};

export const baseURL = `${config.protocol}://${config.host}:${config.port}`

const apiClient = axios.create({
  baseURL,
})

if (localStorage.getItem('gigAuthHeader')) {
  apiClient.defaults.headers.Authorization = `Bearer ${localStorage.getItem('gigAuthHeader')}`
}

apiClient.interceptors.response.use(function (response) {
  if (response.headers.authorization) {
    setToken(response.headers.authorization.replace('Bearer ', ''))
  }

  return response
}, function (error) {
  return Promise.reject(error)
})

export function setToken (header) {
  apiClient.defaults.headers.Authorization = `Bearer ${header}`
  localStorage.setItem('gigAuthHeader', header)
}

export function removeToken () {
  apiClient.defaults.headers.Authorization = ''
  localStorage.removeItem('gigAuthHeader')
}

export default apiClient
