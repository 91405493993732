const environment = {
	isProduction: false,
	protocol: process.env.REACT_APP_API_BACKEND_PROTOCOL || 'http',
  host: process.env.REACT_APP_API_BACKEND_URL || 'localhost',
	port: process.env.REACT_APP_API_BACKEND_PORT || '80',
	OAuth: {
		facebook: {
			app_id: '2553822574842752',
			callbackURL: '/auth/facebook/callback',
		},
		linkedin: {
			callbackURL: '/auth/linkedin/callback',
		},
		google: {
			callbackURL: '/auth/google/callback',
		},
	},
}

export const socialLinks = {
	facebook: 'https://www.facebook.com/GigWorks.official/',
	instagram: 'https://www.instagram.com/gigworks/',
	linkedin: 'https://www.linkedin.com/company/gigworks/',
	youtube:  'https://www.youtube.com/channel/UCWFElDzZK2ZjRO_T5ed5TPw/featured/',
}

// host: 'gigworks-backend.app',
//   port: 8000,

export const registrationConfig = {
	forms: {
		default: {
			profile: ['AccountInfo'],
			media: [],
		},
		artist: {
			profile: [
				'AccountInfo',
				'ArtistPersonalInfo',
				['Talent', 'PlansAndTalents', 'TalentDetails','CancellationPolicy'],
        ['Plans & Payments', 'PlansArtist', 'Coupons'],
        ['Profile media', 'EditProfilePhotos', 'EditVideos', 'EditAudio', 'EditPictures']
			],
		},
		venue: {
			profile: [
				'AccountInfo',
				'VenueInfo',
        ['Talent', 'PlansAndVenueProfile', 'BookingInfo','CancellationPolicy'],
        ['Plans & Payments', 'PlansVenue', 'Coupons'],
        ['Profile media', 'EditProfilePhotos', 'EditVideos', 'EditAudio', 'EditPictures']
			],
			media: ['EditProfilePhotos', 'EditVideos', 'EditAudio', 'EditPictures'],
		},
		band: {
			profile: [
				'AccountInfo',
				'BandContactInfo',
        ['Talent', 'PlansAndBandGenre', 'BandDetails','CancellationPolicy'],
        ['Plans & Payments', 'PlansBand', 'Coupons'],
        ['Profile media', 'EditProfilePhotos', 'EditVideos', 'EditAudio', 'EditPictures']
			],
			media: ['EditProfilePhotos', 'EditVideos', 'EditAudio', 'EditPictures'],
		},
		group: {
			profile: [
				'AccountInfo',
				'GroupContactInfo',
        ['Group Talent', 'PlansAndGroupTalents', 'GroupDetails', 'CancellationPolicy'],
        ['Plans & Payments', 'PlansGroup', 'Coupons'],
        ['Profile media', 'EditProfilePhotos', 'EditVideos', 'EditAudio', 'EditPictures']
			],
			media: ['EditProfilePhotos', 'EditVideos', 'EditAudio', 'EditPictures'],
		},
		company: {
			profile: ['AccountInfo', 'CompanyContactInfo', 'CompanyInfo', 'Coupons',
        ['Profile media', 'EditProfilePhotos', 'EditVideos', 'EditAudio', 'EditPictures']],
			media: ['EditProfilePhotos', 'EditVideos', 'EditAudio', 'EditPictures'],
		},
		client: {
			profile: ['AccountInfo', 'ClientPersonalInfo', 'ClientInfo', 'KYC', 'Coupons',
        ['Profile media', 'EditProfilePhotos', 'EditVideos', 'EditAudio', 'EditPictures']],
			media: ['EditProfilePhotos', 'EditVideos', 'EditAudio', 'EditPictures'],
		},
		fan: {
			profile: ['AccountInfo', 'FanInfo', 'FanPersonalInfo', 'Coupons',
        ['Profile media', 'EditProfilePhotos', 'EditVideos', 'EditAudio', 'EditPictures']],
			media: ['EditProfilePhotos', 'EditVideos', 'EditAudio', 'EditPictures'],
		},
	},
	popupText: {
		uploadVideoText: 'Link a Video to your card (not upload)',
		editVideoUploadText: 'Link video (not upload).',
		//artist
		artistContactInformation:
			'This will serve as the primary address when potential clients search for talent in your area. It will not appear on your profile. It may appear on any contracts you enter into through the Gigworks platform.',
		artistBriefIntroduction:
			'Give us your essence in 155 characters! This description will be shown on your profile and  will also form a Meta Description to help your profile be found on Google. (SEO).',
		artistAboutSection:
			'This is the space to inspire! Tell us everything we need to know about you. What sets you apart from the crowd? Why should clients hire you? Are you looking for partners in artistic crime? Be honest but not modest - this is your place to shine!',
		artistBookingMethod:
			'With instant booking, clients simply book from your calendar and send you a contract automatically. In selecting instant booking, you commit to keeping your Gigworks calendar up-to-date, as booking is immediate, with no confirmation process. ',
		artistServiceFees:
			"How much do you charge for your services? Make sure you're considering any extra costs, including transportation costs and taxes you might have to declare. You can only display two fee structures per profile. To include additional services or fee structures, upload in the price list below.",
		artistCompareOur: `Registration on Gigworks platform is free of charge.\nThere is no minimum subscription period to both Pro and Premium plans, unless you receive an offer from a superior plan: in this case, if you decide to accept it, you need to subscribe for at least 3 months.`,
		//band
		emailAndLogin:
			'The email address you enter will be your username for log in. Passwords should be at least 8 characters long, including at least one capital letter, lowercase letter, special character and number.',
		contactInformation:
			'This will serve as the primary address to locate your band when potential clients search for talent in your area. It will not appear on your profile. It may appear on any contracts you enter into through the Gigworks platform.',
		menagerMemberInfo:
			'Insert your Gigworks handle here if you already have a profile. You can also create an individual artist profile and link it to your band profile at later time!',
		legalName: `If you don't have a Gigworks profile, you'll appear under this name under Group Members.`,
		aboutYou:
			'This is the space to inspire! Tell us everything we need to know about your group. What sets you apart from the crowd? Why should clients hire you?  Be honest but not modest - this is your place to shine!',
		briefIntroduction:
			"Give us the essence of your band in 155 characters. This description will be shown on your band's profile and will also form a Meta Description to help your band be found on Google. (SEO).",
		bookingMethod:
			'With instant booking, clients simply book from your calendar and send you a contract automatically. In selecting instant booking, you commit to keeping your Gigworks calendar up-to-date, as booking is immediate, with no confirmation process.',
		serviceFees:
			"How much do you charge for your band's performance? You can only display two fee structures per profile. To include additional services or fee structures, upload in the price list below.",
		abilityToTravel:
			'Your plan governs which gigs you can respond to by location. Free Plan - you may choose 1 country. Pro Plan - you may choose 1 region. Premium Plan - all regions are available.',
		priceList:
			"Here you can add prices for all other services you'd like to provide to clients. Get competitive and be creative, i.e. give the client better prices if the client books you for a consecutive two weeks.",
		//client
		clientContactInformation:
			'This address will not be shown on your profile, but it will be used to locate artists, venues and venues close to you.',
		clientBriefIntroduction: 'Who are you? Give us a short description of yourself!',
		clientAboutSection:
			"People like to know who they're working with. What should artists, venues, and others you'll be working with know about you? Have you hosted any previous gigs?",
		clientSelectInterrest:
			"Which topics are you interested in? Select the ones you like the most and we'll do our best to provide you matching artists, events and Gigworks news.",
		//company
		companyBriefIntroduction:
			"Please provide us a brief description of your company. This description will also be used for SEO purposes to increase your profile's searchability.",
		companyAboutYou:
			"People like to know who they're working for. What should artists, venues, and others you'll be working with know about your company? Have you hosted any previous gigs?",
		companySelectYourInterest:
			"Which topics are you interested in? Select the ones you like the most and we'll do our best to provide you matching artists, events and Gigworks news.",
		companyYourCompany: 'This name will be shown on your profile.',
		companyMenagerRepresentative: "This information will be shown on your company's profile.",
		companyContact:
			'The company address will not be shown on your profile, but it will be used to locate artists and venues close to you.',
		//fan
		fanBriefIntroduction:
			'Many artists love getting to know their fans! Introduce yourself to your favorite artists - and the Gigworks community - here.',
		fanAboutSection:
			'Tell us your story! Connect with likeminded members of the Gigworks community by sharing what makes you tick. ',
		fanContactInformation:
			"We'll use this address to find great artists and events near you. It will not appear on your profile.",
		//venue
		venueMenagerRepresentative:
			'Your listed manager or representative should be the person responsible for all booking procedures for your venue.',
		venueContactInformation:
			'This address will be used to determine your proximity to the client and to ensure you appear in client search results for your area.',
		venueBookingMethod:
			'With instant booking, clients simply book from your calendar and send you a contract automatically. In selecting instant booking, you commit to keeping your Gigworks calendar up-to-date, as booking is immediate, with no confirmation process.',
		venueServiceFees:
			"How much do you charge for venue rental and services? Make sure you're considering any extra costs, including equipment use and personnel costs.",
		venueMenuList: 'Offer your clients as much information about your services as possible by listing your menu here.',
		venuePriceList:
			"Here you can add prices for all other services you'd like to provide to clients. Get competitive and be creative, i.e. offering discounted prices if a client books you for three nights a week.",
		venueTags: 'For optimum searchability, tags should be single words or phrases with spaces removed.',
		venueBriefIntroduction:
			"Give us the essence of your venue in 155 characters. This description will be shown on your venue's profile and will also form a Meta Description to help your venue be found on Google. (SEO).",
		venueAboutYou:
			'This is the space to inspire! Tell us everything we need to know about your venue. What sets you apart from the crowd? Why should clients rent your space?  Be honest but not modest - this is your place to shine!',
		//group
		groupManagerOrRepresentativeMemberInfo:
			'Insert your Gigworks handle here if you already have a profile. You can also create an individual artist profile and link it to your group profile at a later time!',
		groupBookingMethod:
			'With instant booking, clients simply book from your calendar and send you a contract automatically. In selecting instant booking, you commit to keeping your Gigworks calendar up-to-date, as booking is immediate, with no confirmation process.',
		groupServiceFees:
			"How much do you charge for your group's performance? Make sure you're considering any extra costs, including transportation costs and taxes you might have to declare. You can only display two fee structures per profile. To include additional services or fee structures, upload in the price list below.",
		groupLegalName: "If you don't have a Gigworks profile, you'll appear under this name under Group Members.",
		groupBriefIntroduction:
			"Give us the essence of your group in 155 characters. This description will be shown on your group's profile and will also form a Meta Description to help your group be found on Google. (SEO).",
		groupAboutYou:
			'This is the space to inspire! Tell us everything we need to know about your group. What sets you apart from the crowd? Why should clients hire you?  Be honest but not modest - this is your place to shine!',
		groupNewMember:
			'Please add members to your group, provide some information about them if they are not yet on Gigworks. You may also invite them in next step',
		groupContactInformation:
			'This will serve as the primary address when potential clients search for talent in your area. It will not appear on your profile. It may appear on any contracts you enter into through the Gigworks platform.',
	},
}

export default environment
