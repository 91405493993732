import React, { Component } from 'react';

// const RenderField = ({input, label, classNames, event, placeholder, type, disabled, autoComplete, meta: {touched, error}}) => {
export default class RenderField extends Component {
    constructor(props) {
        super(props);

        this.state = {
			readonly: true
		}
    }
    
    render() {
        const { input, label, classNames, event, placeholder, type, disabled, autoComplete, meta: {touched, error}} = this.props;

        return (
            <div onClick={event}>
                { type === 'file' && 
                    <div>
                        <div style={{display: 'none'}}>
                            <input {...input} className={error ? classNames : classNames + ' active'} placeholder={placeholder}
                                type={type} disabled={disabled}/>
                            {touched && error && <span className="error">{error}</span>}
                        </div>
                    </div>
                }
                { type === 'password' && 
                    <div>
                        <label>{label}</label>
                        <div>
                            <input
                                {...input}
                                className={error ? classNames : classNames + ' active'}
                                placeholder={placeholder}
                                type={type}
                                autoComplete={autoComplete}
                                onBlur={() => this.setState({ readonly: true })}
                                onFocus={() => this.setState({ readonly: false })}
                                readOnly={this.state.readonly}
                                disabled={disabled}
                            />
                            {touched && error && <span className="error">{error}</span>}
                        </div>
                    </div>
                }
                { type !== 'file' && type !== 'password' &&
                    <div>
                        <label>{label}</label>
                        <div>
                            <input {...input} className={error ? classNames : classNames + ' active'} placeholder={placeholder}
                                type={type} autoComplete={autoComplete} disabled={disabled}/>
                            {touched && error && <span className="error">{error}</span>}
                        </div>
                    </div>
                }
    
            </div>
        )
    }
}
