import React from 'react';
import * as _ from 'lodash';
import axios from 'axios';
import {connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Input, Card } from 'antd';
import 'antd/es/input/style/css'
import Button from '../../../common/Button/Button';
import { createCard, createCardStep2, getCardsList } from '../../../../redux/modules/cards';

import Spinner from '../../../common/Spinner/Spinner';
import Checkbox from 'material-ui/Checkbox';

import SimpleFormattedInput from './SimpleFormattedInput/SimpleFormattedInput';


const CARD_EXPIRATION_REGEX = /^((0[1-9])|(1[0-2]))(\d{2})$/
const CARD_NUMBER_REGEX = /^(?:4[0-9]{12}(?:[0-9]{3})?|(?:5[1-5][0-9]{2}|222[1-9]|22[3-9][0-9]|2[3-6][0-9]{2}|27[01][0-9]|2720)[0-9]{12}3[47][0-9]{13}|3(?:0[0-5]|[68][0-9])[0-9]{11}6(?:011|5[0-9]{2})[0-9]{12}|(?:2131|1800|35\d{3})\d{11})$/
const CARD_CVN_REGEX = /^[0-9]{3,4}$/
const CARD_AMEX_REGEX = /\b(\d{4})(\d{6})(\d{5})\b/


@withRouter
@connect(({ cards }) => ({
	isCardsListFetching: cards.isCardsListFetching
}), {
	createCard,
	createCardStep2,
	getCardsList
})
class NewCardForm extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      cardNumber: '',//'4706750000000033',
      cardExpirationDate:'', // '1122',
      cardCvx: '', //'566',

      clientName: '',
      clientAddress: '',

      isCardNumberValid: null,
      isCardExpirationDateValid: null,
      isCardCvxValid: null,

      isClientNameValid: null,
      isClientAddressValid: null,

      saveCard: false,

      CardRegistrationURL: null,
      PreregistrationData: null,
      AccessKey: null,
      Id: null,
    }
  }

  componentDidMount() {
    const { noSubmit } = this.props;

    if (!noSubmit) {
      this.fetchCreateData()
        .then(() => {})
        .catch(() => {})
    }
  }

  fetchCreateData = () => {
    const { createCard } = this.props;

    return createCard().then(res => {
      const {
        CardRegistrationURL,
        PreregistrationData,
        AccessKey,
        Id,
      } = res.data.card;

				this.setState({
					CardRegistrationURL,
					PreregistrationData,
					AccessKey,
					Id,
				})
			}).catch(_ => {})
  };

	handleSubmit = () => {
		const {
			CardRegistrationURL,
			PreregistrationData,
			AccessKey,
			cardNumber,
			cardExpirationDate,
			cardCvx,
      clientName,
      clientAddress,
      saveCard
		} = this.state;

		const { afterSuccess, getCardsList, createCardStep2, noSubmit } = this.props;

		createCardStep2({
			CardRegistrationURL,
			PreregistrationData,
			AccessKey,
			cardNumber: cardNumber.split(' ').join(''),
			cardExpirationDate: cardExpirationDate.split('/').join(''),
			cardCvx,
      clientName,
      clientAddress,
      saveCard
		 }).then(res => {
			!noSubmit && getCardsList();
			afterSuccess && afterSuccess();
		})
	};

	handleChange = nextValue => {
		const { noSubmit, onChange } = this.props;

		this.setState(
			{ ...nextValue },
			() => {
			  if (noSubmit) {
			    const {cardNumber, cardExpirationDate, cardCvx, clientName, clientAddress, saveCard} = this.state;
			    onChange({
            cardNumber: cardNumber.split(' ').join(''),
            cardExpirationDate: cardExpirationDate.split('/').join(''),
            clientName,
            clientAddress,
            cardCvx,
            saveCard,
            isValid: this.isValid()
			    });
			  }
			});
	};

	handleCheck = event => {
    this.handleChange({ saveCard: event.target.checked });
  };

	isValid = () => {
		const { cardNumber, cardExpirationDate, cardCvx, clientName, clientAddress,
      isCardCvxValid, isCardExpirationDateValid, isCardNumberValid, isClientNameValid, isClientAddressValid } = this.state;
		return !!cardNumber && !!cardExpirationDate && !!cardCvx && !!clientName && !!clientAddress
      && isCardCvxValid && isCardExpirationDateValid && isCardNumberValid && isClientNameValid && isClientAddressValid;
	};

	render() {
	  const {
	    cardNumber,
      cardExpirationDate,
      cardCvx,
      AccessKey,
      clientName,
      clientAddress,
      saveCard
    } = this.state;

	  const { noSubmit } = this.props;

	  return (
	    <div className="d-flex flex-column">
        <SimpleFormattedInput
          onlyNumbers
          placeholder=""
          label="Card Number"
          value={cardNumber}
          options={{ creditCard: true }}
          validator={value => CARD_NUMBER_REGEX.test(value.split(' ').join('')) || CARD_AMEX_REGEX.test(value.split(' ').join(''))}
          validateError={`Please fill valid card number`}
          onChange={({ value, isValid }) => this.handleChange({
            cardNumber: value,
            isCardNumberValid: isValid
          })}
        />

        <SimpleFormattedInput
          onlyNumbers
          placeholder=""
          label="Expiration Date"
          value={cardExpirationDate}
          validator={value => CARD_EXPIRATION_REGEX.test(value.split('/').join(''))}
          validateError={`Please fill valid expiration date`}
          options={{
            date: true,
            datePattern: ['m', 'y'],
          }}
          onChange={({ value, isValid }) => this.handleChange({
            cardExpirationDate: value,
            isCardExpirationDateValid: isValid
          })}
        />

        <SimpleFormattedInput
          placeholder=""
          label="CVV/CVC"
          value={cardCvx}
          onlyNumbers
          options={{ blocks: [3] }}
          validator={value => CARD_CVN_REGEX.test(value)}
          validateError={`Please fill CVV/CVC number`}
          onChange={({ value, isValid }) => this.handleChange({
            cardCvx: value,
            isCardCvxValid: isValid
          })}
        />


        <SimpleFormattedInput
          basicInput
          placeholder=""
          label="Name on card"
          value={clientName}
          validator={ value => value.length }
          validateError={`Please fill your first and last name`}
          onChange={({ value, isValid }) => this.handleChange({
            clientName: value,
            isClientNameValid: isValid
          })}
        />

        <SimpleFormattedInput
          basicInput
          placeholder=""
          label="Address"
          value={clientAddress}
          validator={ value => value.length }
          validateError={`Please fill your address`}
          onChange={({ value, isValid }) => this.handleChange({
            clientAddress: value,
            isClientAddressValid: isValid
          })}
        />

        {!noSubmit && <Button
          label="Create Card"
          primary
          onClick={this.handleSubmit}
          disabled={!AccessKey || !this.isValid()}
        />}
	    </div>
    )
	}
}

export default NewCardForm



