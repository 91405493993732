import { createStore, applyMiddleware } from 'redux';
import clientMiddleware from './middleware/clientMiddleware';
import apiClient from '../helpers/apiClient';
import reducer from './modules/reducer';

const allowDebugging = process.env.NODE_ENV !== 'production';

const finalCreateStore = applyMiddleware(clientMiddleware(apiClient))(createStore);

export const store = finalCreateStore(
  reducer,
  allowDebugging && window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
);

if (allowDebugging)
  window.reduxStore = store;

export default store;
