import './HomeProfiles.scss'
import React, { Component } from 'react'

import { connect } from 'react-redux'
import { compose } from 'recompose'

import { HomeProfile } from './HomeProfile/HomeProfile'
import classNames from 'classnames'
import block from 'bem-cn'
import { get } from 'lodash'

const b = block('HomeProfiles')

import { getUsers } from '../../../redux/modules/discover'

class HomeProfiles extends Component {
	componentDidMount() {
		const { getUsers } = this.props

		getUsers()
	}

	render() {
		const { className, users } = this.props

		let firstRow = []
		let secondRow = []
		let thirdRow = []

		if (users.length) {
			firstRow = users.slice(0, 3)
			secondRow = users.slice(3, 5)
			thirdRow = users[5]
		}

		return (
			<div className={classNames(className, b(), 'mt-md-5')}>
				<div className="profilesWrapper">
					<div className="profilesWrapper__mobile">
						{users.length &&
							users.map(user => {
								return (
									<HomeProfile
										userId={get(user, 'id', 123456)}
										urlName={get(user, 'url_name', undefined)}
										key={get(user, 'id', 123456)}
										profile_type={get(user, 'profile_type', 'artist') === 'venue' ? 'venue' : 'artist'}
										image={get(
											user,
											'avatar.url',
                      '#'
										)}
										name={get(user, 'name', 'Jolie Loi')}
										location={get(user, 'location.city', '') + ", " + get(user, 'location.country', '')}
										description={get(user, 'categories[0].name', undefined)}
									/>
								)
							})}
					</div>
					<div className="profilesWrapper__first-row">
						{users.length &&
							users.slice(0, 3).map(user => {
								return (
									<HomeProfile
										userId={get(user, 'id', 123456)}
                    urlName={get(user, 'url_name', undefined)}
                    key={get(user, 'id', 123456)}
										profile_type={get(user, 'profile_type', 'artist') === 'venue' ? 'venue' : 'artist'}
										image={get(
											user,
											'avatar.url',
                      '#'
										)}
										name={get(user, 'name', 'Jolie Loi')}
                    location={get(user, 'location.city', '') + ", " + get(user, 'location.country', '')}
										description={get(user, 'categories[0].name', 'Pop Singer')}
									/>
								)
							})}
					</div>
					<div className="profilesWrapper__second-row">
						{users.length &&
							users.slice(3, 5).map(user => {
								return (
									<HomeProfile
										userId={get(user, 'id', 123456)}
                    urlName={get(user, 'url_name', undefined)}
                    key={get(user, 'id', 123456)}
										profile_type={get(user, 'profile_type', 'artist') === 'venue' ? 'venue' : 'artist'}
										image={get(
											user,
											'avatar.url',
											'#'
										)}
										name={get(user, 'name', 'Jolie Loi')}
                    location={get(user, 'location.city', '') + ", " + get(user, 'location.country', '')}
										description={get(user, 'categories[0].name', 'Pop Singer')}
									/>
								)
							})}
					</div>
					<div className="profilesWrapper__third-row">
						{users.length && users.length > 5 && (
							<HomeProfile
								userId={get(users[5], 'id', 123456)}
                urlName={get(users[5], 'url_name', undefined)}
                key={get(users[5], 'id', 123456)}
								profile_type={get(users[5], 'profile_type', 'artist') === 'venue' ? 'venue' : 'artist'}
								image={get(
									users[5],
									'avatar.url',
                  '#'
								)}
								name={get(users[5], 'name', undefined)}
                location={get(users[5], 'location.city', '') + ", " + get(users[5], 'location.country', '')}
								description={get(users[5], 'categories[0].name', undefined)}
							/>
						)}
					</div>
				</div>
			</div>
		)
	}
}

const mapStateToProps = state => {
	return {
		users: state.discover.homepageUsers,
	}
}

export default compose(
	connect(
		mapStateToProps,
		// null,
		{ getUsers }
	)
)(HomeProfiles)
