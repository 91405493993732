import React, { Component } from 'react'
import '../../../styles/components/band/BandContactInfo.scss'
import { Field, reduxForm } from 'redux-form'
import validate from '../../../helpers/validators/group/GroupContactInfo'
import { connect } from 'react-redux'
import { getValues } from '../../../helpers/methods'
import { MembersInfo } from '../band/BandContactInfo'
import {
	TextFieldComponent,
	GoogleMapsAutocomplete,
	PhoneComponent,
	BirthDatePickerComponent,
  CheckboxComponent,
  SelectFieldComponent
} from '../../CommonComponent'
import { Gender } from '../CommonComponent'
import { Asterisk } from '../../common/Asterisk/Asterisk'
import classnames from 'classnames'
import { registrationConfig } from '../../../config'
// import { nationalities } from '../../../utils/constants'
import { getCountriesTree } from '../../../redux/modules/dashboard'
import Loader from '../../discover/Loader/Loader'
import * as _ from "lodash";
import moment from "moment";
import store from "../../../redux/create";

class GroupContactInfo extends Component {
	constructor(props) {
		super(props)
	}

	clearPopupCache = () => {
		this.props.initialValues.new_member_name ? this.props.initialValues.new_member_name = '' : null;
		this.props.initialValues.option_name ? this.props.initialValues.option_name = '' : null;
    };

    componentDidMount() {
      // const { getCountriesTree } = this.props
      this.props.getCountriesTree();
      let currentValue
      function handleChange() {
        let previousValue = currentValue
        if(store.getState().user.currentProfile.business_locations != undefined){
          currentValue = store.getState().user.currentProfile.business_locations.country
          if (previousValue !== currentValue && previousValue != undefined) {
            if(store.getState().dashboard.countriesTree != undefined){
              const arr = store.getState().dashboard.countriesTree
              const countryCode = store.getState().common.country_code
              for(let i = 0; i < arr.length; i++){
                if(arr[i].code == countryCode){
                  store.getState().user.currentProfile.countries[0].temp = arr[i].id
                }
              }
            }
          }
        }
      }
      this.unsubscribe = store.subscribe(() => {
        handleChange()
      });
    }

    componentWillUnmount() {
      this.unsubscribe();
    }

  render() {
		const { initialValues, currentUser, touch, valid } = this.props;
    const isLocationEditable = _.get(currentUser, 'isLocationEditable', false);
    const addressLastUpdate = _.get(currentUser, 'profile.address_last_update', null);

    const invitationInfo = {
      group_name: initialValues.name,
      manager_name: initialValues.manager_first_name + ' ' + initialValues.manager_last_name,
    };

		return (
			<form className="band-contact-info plans-and-venue-profile">
				<div className="band-contact-info__fields band-contact-info__container">
					<div className={classnames('band-contact-info__info-big-title', { filled: initialValues.name })}>
						Your group <Asterisk />
					</div>
					<Field
						name="name"
						type="text"
						style={{ margin: '10px 0 5px' }}
						fullWidth={true}
						component={TextFieldComponent}
						hintText="Group Name"
					/>
				</div>

				<div className="band-contact-info__fields band-contact-info__container">
					<div className={classnames('band-contact-info__info-big-title', { filled: initialValues.name })}>
						Manager or Representative Info <Asterisk />
					</div>

					<div className="">
						<div className="band-contact-info__info-title">
							Legal name <Asterisk />
						</div>
						<Field
							name="manager_first_name"
							type="text"
							style={{ margin: '10px 0 5px' }}
							fullWidth={true}
							component={TextFieldComponent}
							hintText="First Name"
						/>
						<Field
							name="manager_last_name"
							type="text"
							style={{ margin: '10px 0 5px' }}
							fullWidth={true}
							component={TextFieldComponent}
							hintText="Last Name"
						/>
						<Field
							name="manager_company_title"
							type="text"
							style={{ margin: '10px 0 5px' }}
							fullWidth={true}
							component={TextFieldComponent}
							hintText="What is the manager or representative's title within your group?"
						/>
					</div>

					<div className="">
						<div className="band-contact-info__info-title">
							<span
								className="fa-stack info-popup-window"
								data-title={registrationConfig.popupText.groupContactInformation}
							>
								<i className="fa fa-circle-thin fa-stack-2x"/>
								<i className="fa fa-info fa-stack-1x"/>
							</span>
							Manager or Representative's Address and Phone Number
						</div>
            {
              !isLocationEditable ?
                <div className="personal-info__address-additional-info">
                  Since you have updated your location in the past 3 months, this part of the form is now disabled.
                  You will be able to change it after {moment(addressLastUpdate).add(3, 'M').format('D MMM YYYY')}.
                </div> :
                null
            }
						<div style={{ width: '100%', margin: '10px 0 5px 0' }}>
							<Field
                makeTouched={() => touch('manager_location')}
                onBlur={() => touch('manager_location')}
								fullWidth
                isLocationEditable={isLocationEditable}
                name="manager_location"
								component={GoogleMapsAutocomplete}
								hintText="Enter Address"
								hintStyle={{
									fontWeight: '300',
									color: '#a3a3a3',
								}}
								style={{
									textAlign: 'left',
									marginRight: '20px',
								}}
							/>
              {(this.props.phoneCodeList && this.props.phoneCodeList.length) ?
                <Field
                  name="manager_phone_number"
                  additional_name="manager_country_code"
                  style={{ margin: '10px 0 5px' }}
                  fullWidth
                  component={PhoneComponent}
                  countries={this.props.phoneCodeList}
                />
                :
                <div className="d-flex justify-content-center">
                  <Loader />
                </div>
              }
						</div>
					</div>

					<div className="">
						<div className="band-contact-info__info-title">
							Gender
						</div>
						<div className="text-left">
							<Field name="manager_gender" component={Gender} />
						</div>
					</div>

          <div className="band-contact-info__nationality">
            <div className={classnames('band-contact-info__info', { filled: initialValues.nationality })}>
              Nationality
            </div>
            <div className="band-contact-info__nationality" style={{ width: '100%' }}>
              {(this.props.countriesList && this.props.countriesList.length) ?
                <Field
                  name="nationality"
                  type="string"
                  required
                  fullWidth
                  style={{ height: '3rem', textAlign: 'left' }}
                  options={this.props.countriesList}
                  hintText="Please select manager nationality"
                  component={SelectFieldComponent}
                />
                :
                <div className="d-flex justify-content-center">
                  <Loader />
                </div>
              }
            </div>
          </div>

					<div className="">
						<div className="band-contact-info__info-title">
							Date of birth <Asterisk />
						</div>
						<div className="band-contact-info__date-of-birth" style={{ width: '100%' }}>
							<Field
								name="manager_birth_date"
								hintText="Birth day"
								mode="landscape"
								component={BirthDatePickerComponent}
							/>
						</div>
						<div className="mt-3">
              <Field
                name="over18"
                label={<>I confirm that I'm over 18 years old <Asterisk /></>}
                labelStyle={{ width: 'initial'}}
                component={CheckboxComponent}
              />
						</div>
					</div>
				</div>

				<div className="band-contact-info__fields band-contact-info__container">
					<div className="band-contact-info__info-big-title">
						Members <Asterisk />
					</div>
					<MembersInfo
            isBand={false}
            invitationInfo={invitationInfo}
            clearPopupCache={this.clearPopupCache}
            valid={valid}
            phoneCodeList={this.props.phoneCodeList}
          />
				</div>
			</form>
		)
	}
}

GroupContactInfo = reduxForm({
	form: 'GroupContactInfo',
	destroyOnUnmount: false,
	forceUnregisterOnUnmount: false,
	enableReinitialize: true,
	keepDirtyOnReinitialize: true,
	validate,
})(GroupContactInfo);

export default connect(({ user, form, dashboard }) => ({
  currentUser: user.currentUser,
  countriesList: dashboard.countriesList,
  phoneCodeList: dashboard.phoneCodeList,
  initialValues: getValues(
		form.GroupContactInfo,
		user.currentUser && user.currentUser.profile
			?
      {
        name: user.currentUser.profile.name,
        manager_first_name: user.currentUser.profile.manager_first_name,
        manager_last_name: user.currentUser.profile.manager_last_name,
        manager_company_title: user.currentUser.profile.manager_company_title,
        manager_location: user.currentUser.profile.manager_location || {},
        manager_phone_number: user.currentUser.profile.manager_phone_number,
        manager_country_code: user.currentUser.profile.manager_phone_number ? user.currentUser.profile.manager_phone_number.country_code : null,
        manager_gender: user.currentUser.profile.manager_gender,
        nationality: String(user.currentUser.profile.nationality),
        manager_birth_date: user.currentUser.profile.manager_birth_date,
        over18: !!user.currentUser.profile.over18,
      }
      : {}
	),
}), {
    getCountriesTree
})(GroupContactInfo)
