import React, { Component } from 'react'
import '../../../styles/components/band/BandContactInfo.scss'
import { Field, reduxForm } from 'redux-form'
import { RadioButton, RadioButtonGroup } from 'material-ui/RadioButton'
import { Categories } from '../Category'
import RaisedButton from 'material-ui/RaisedButton'
import validate from '../../../helpers/validators/band/PlansAndBandGenres'
import '../../../styles/components/band/PlansAndBandGenre.scss'
import table from '../../../assets/table.jpg'
import { connect } from 'react-redux'
import { getValues } from '../../../helpers/methods'
import classnames from 'classnames'
import { Asterisk } from '../../common/Asterisk/Asterisk'
import { registrationConfig } from '../../../config'

class WizardPlansAndBandGenre extends Component {
	constructor(props) {
		super(props)

		this.state = {
			isOpened: true,
			plans: 'free',
		}
	}

	onCheckPlan(value) {
		window.scrollTo(0, 0)

		this.setState({
			plans: value,
			isOpened: false,
		})
	}

	togglePlansMenu(isOpened) {
		window.scrollTo(0, 0)

		this.setState({
			isOpened,
		})
	}

	render() {
		const { handleSubmit, initialValues } = this.props

		return (
			<form onSubmit={handleSubmit} className="plans-and-venue-profile">
				<div>
					<div
						className={classnames('band-contact-info__info', {
							filled: initialValues.categories,
						})}
					>
						Band Genres <Asterisk className="text-danger ml-1" />
					</div>
					<div className="band-genre-form__wrapper">
						<Field name="categories" type="group.band" categoryTypeText={'band genre'} component={Categories} />
					</div>
				</div>
			</form>
		)
	}
}

WizardPlansAndBandGenre = reduxForm({
	form: 'PlansAndBandGenre',
	destroyOnUnmount: false,
	forceUnregisterOnUnmount: false,
	validate,
})(WizardPlansAndBandGenre)

export default connect(({ user, form }) => ({
	currentUser: user.currentUser,
	initialValues: getValues(
		form.PlansAndBandGenre,
		user.currentUser
			? {
					categories: user.currentUser.categories.length
						? getCategoriesFromUser(user.currentUser)
						: [
								{
									id: '',
									name: '',
									styles: [],
								},
						  ],
			  }
			: { categories: [{ id: '', name: '', styles: [] }] }
	),
}))(WizardPlansAndBandGenre)

//for now support only one category
function getCategoriesFromUser(user) {
	let userCat = [...user.categories]
	const userStyles = [...user.styles]

  userCat = userCat.filter(category => category.type === "music_genre");

	return userCat.map(cat => ({
		id: cat.id,
		name: cat.name,
		styles: userStyles.map(style => ({ name: style.name, type: style.type, top_position: style.top_position })),
	}))
}
