import './LangDropdown.scss';
import React, { Component } from 'react';
import Checkbox from 'material-ui/Checkbox';
import { setPageLanguage } from '../../../translator';

const languages = [
  {code: 'AR', name: 'Arabic'},
  {code: 'BG', name: 'Bulgarian'},
  {code: 'CH', name: 'Chinese'},
  {code: 'HR', name: 'Croatian'},
  {code: 'CZ', name: 'Czech'},
  {code: 'NL', name: 'Dutch'},
  {code: 'ENG', name: 'English'},
  {code: 'PHI', name: 'Filipino'},
  {code: 'FR', name: 'French'},
  {code: 'DE', name: 'German'},
  {code: 'EL', name: 'Greek'},
  {code: 'HEB', name: 'Hebrew'},
  {code: 'HI', name: 'Hindi'},
  {code: 'IT', name: 'Italian'},
  {code: 'JP', name: 'Japanese'},
  {code: 'KO', name: 'Korean'},
  {code: 'MSA', name: 'Malaysian'},
  {code: 'PL', name: 'Polish'},
  {code: 'PRT', name: 'Portuguese'},
  {code: 'RO', name: 'Romanian'},
  {code: 'RU', name: 'Russian'},
  {code: 'SR', name: 'Serbian'},
  {code: 'ES', name: 'Spanish'},
  {code: 'SWA', name: 'Swahili'},
  {code: 'SWE', name: 'Swedish'},
  {code: 'THA', name: 'Thai'},
  {code: 'TR', name: 'Turkish'},
  {code: 'VI', name: 'Vietnamese'},
];

export default class LangDropdown extends Component {
  constructor() {
    super();

    this.state = {
      showMenu: false,
      lang: localStorage.getItem('lang') ? localStorage.getItem('lang') : 'ENG'
    };

    this.showMenu = this.showMenu.bind(this);
    this.closeMenu = this.closeMenu.bind(this);
  }

  showMenu(event) {
    event.preventDefault();

    this.setState({ showMenu: true }, () => {
      document.addEventListener('click', this.closeMenu);
    });
  }

  closeMenu(event) {
    this.setState({ showMenu: false }, () => {
      document.removeEventListener('click', this.closeMenu);
    });
  }

  changeLanguage(val) {
    setPageLanguage(val);
    this.setState({ lang: val });
    this.setState({ showMenu: false });
    setTimeout(function() {
      window.location.reload();
    }.bind(this), 500);
  }

  render() {
    return (
      <div>
        <div className="lang-button" onClick={this.showMenu}>
          {this.state.lang} <i className="fa fa-caret-down" aria-hidden="true"></i>
        </div>

        {
          this.state.showMenu
            ? (
              <div>
                <div className="arrow-up"></div>
                <div
                  className="menu"
                  ref={(element) => {
                    this.dropdownMenu = element;
                  }}
                >
                  {languages.map(({code, name}, ind) =>
                    <Checkbox
                      key={ind}
                      id={ind}
                      style={{
                        width: 'auto',
                        paddingBottom: '4px'
                      }}
                      labelStyle={{
                        width: 'auto',
                        color: '#000'
                      }}
                      label={name}
                      checked={this.state.lang == code}
                      onCheck={() => {
                        this.changeLanguage(code);
                      }}
                    />
                  )}
                </div>
              </div>
            )
            : (
              null
            )
        }
      </div>
    );
  }
}
