import React, { Component } from 'react'
import CancellationPolicy from './../../CancellationPolicy/CancellationPolicy'
import get from 'lodash/get'
import { getRealTypeOfUser } from '../../../helpers/methods'

import './ProfileCancellationPolicy.scss'

export default class ProfileCancellationPolicy extends Component {
	constructor(props) {
		super(props)

		this.state = {
			isOpenCancellationPopUp: false,
		}
	}

	switchPopup() {
		this.setState({
			isOpenCancellationPopUp: !this.state.isOpenCancellationPopUp,
		})
	}

	render() {
		const { profile } = this.props
		const profile_type = getRealTypeOfUser(profile)
		const cancellation_policy = get(this.props, 'profile.cancellation_policy')

        return (
            <div>
                {profile_type !== 'client' && (
                    <div className="cancellation-policy-link d-flex">
                        <div className="cancellation-policy-link__title">Cancellation Policy</div>
                            <div
                                style={{
                                    textTransform: 'capitalize',
                                }}
                                className="cancellation-policy-link__type"
                                onClick={() => this.switchPopup()}
                            >
                        {cancellation_policy &&
                            cancellation_policy
                            .split('_')
                            .join(' ')
                            .toLowerCase()}
                        </div>
                        {this.state.isOpenCancellationPopUp && (
                            <div className="cancellation-policy-popup" onClick={() => this.switchPopup()}>
                                <div className="cancellation-policy-popup__window" onClick={e => e.stopPropagation()}>
                                    <span className="closeCss" onClick={() => this.switchPopup()} />
                                    <CancellationPolicy selectedPolicy={cancellation_policy} locked={true} />
                                </div>
                            </div>
                        )}
                    </div>
                )}
            </div>
        )
	}
}
