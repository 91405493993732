import React, { Component } from 'react'
import { Field, reduxForm } from 'redux-form'
import validate from '../../../helpers/validators/client/ClientPersonalInfo'
import { connect } from 'react-redux'
import { getValues } from '../../../helpers/methods'
import { Gender, FileInput } from '../CommonComponent'
import { TextFieldComponent, GoogleMapsAutocomplete, BirthDatePickerComponent, PhoneComponent, SelectFieldComponent, CheckboxComponent } from '../../CommonComponent'
import { Asterisk } from '../../common/Asterisk/Asterisk'
import { registrationConfig } from '../../../config'
import { getCountriesTree } from '../../../redux/modules/dashboard'
import Loader from '../../discover/Loader/Loader'

class ClientPersonalInfo extends Component {
    componentDidMount() {
        this.props.getCountriesTree()
    }

	render() {
		const { handleSubmit, touch } = this.props

		return (
			<form className="personal-info" onSubmit={handleSubmit}>
				<div className="personal-info__fields">
					<div className="personal-info__fields">
						<div className="personal-info__info">
							Legal name <Asterisk />
						</div>
						<Field
							name="first_name"
							type="text"
							style={{ margin: '10px 0 5px' }}
							fullWidth={true}
							component={TextFieldComponent}
							hintText="First Name"
						/>
						<Field
							name="last_name"
							type="text"
							style={{ margin: '10px 0 5px' }}
							fullWidth={true}
							component={TextFieldComponent}
							hintText="Last Name"
						/>
					</div>
					<div className="personal-info__fields">
						<div className="personal-info__info">Profession</div>
						<Field
							name="profession"
							type="text"
							style={{ margin: '10px 0 5px' }}
							fullWidth={true}
							component={TextFieldComponent}
							hintText="What do you do?"
						/>
					</div>
					<div className="personal-info__gender">
						<div className="personal-info__info d-flex align-items-center">
							<span>Gender</span>
							<span className="band-contact-info__optional" />
						</div>
						<div className="text-left">
							<Field name="gender" component={Gender} />
						</div>
					</div>

					<div className="personal-info__nationality">
			 			<div className="personal-info__info d-flex align-items-center">
			 				<span>Nationality <Asterisk /></span>
			 				<span className="personal-info__optional" />
			 			</div>
			 			<div className="py-3 text-left">
              {(this.props.countriesList && this.props.countriesList.length) ?
                <Field
                  name="nationality"
                  type="string"
                  required={true}
                  fullWidth
                  style={{ height: '3rem', textAlign: 'left' }}
                  labelStyle={{ fontSize: '1.5rem', color: '#000', padding: '0' }}
                  options={this.props.countriesList}
                  hintText="Please select your nationality"
                  component={SelectFieldComponent}
                />
                :
                <div className="d-flex justify-content-center">
                  <Loader />
                </div>
              }
			 			</div>
				 	</div>

					<div className="personal-info__date">
						<div className="personal-info__info">
							Date of birth <Asterisk />
						</div>
						<div className="personal-info__date-of-birth" style={{ width: '100%' }}>
							<Field name="birth_date" hintText="Birth day" component={BirthDatePickerComponent} />
						</div>
						<div className="mt-3">
							<Field
                name="over18"
                label={<>I confirm that I'm over 18 years old <Asterisk /></>}
                labelStyle={{ width: 'initial'}}
                component={CheckboxComponent}
              />
						</div>
					</div>
					<div className="personal-info__contact-information">
						<div
							className="personal-info__info info-popup-window"
							data-title={registrationConfig.popupText.clientContactInformation}
						>
							Contact Information <Asterisk />
						</div>
						<div className="contact-information__select-wrapper google-location-input" style={{ width: '100%' }}>
							<Field
								makeTouched={() => touch('location')}
								onBlur={() => touch('location')}
								fullWidth
								name="location"
								component={GoogleMapsAutocomplete}
								hintText="Enter Address"
								hintStyle={{
									fontWeight: '300',
									color: '#a3a3a3',
								}}
								style={{
									textAlign: 'left',
									marginRight: '20px',
								}}
							/>
              {(this.props.phoneCodeList && this.props.phoneCodeList.length) ?
                <Field
                  name="phone_number"
                  style={{ margin: '10px 0 5px' }}
                  fullWidth
                  component={PhoneComponent}
                  countries={this.props.phoneCodeList}
                />
                :
                <div className="d-flex justify-content-center">
                  <Loader />
                </div>
              }
						</div>
					</div>
					<div className="curriculum-vitae-form__item">
						<div className="curriculum-vitae-form__item-header">
							<h4 className="curriculum-vitae-form__title d-flex align-items-center">
								<span>Curriculum Vitae</span>
								<span className="band-contact-info__optional">(optional)</span>
							</h4>
						</div>
						<div className="curriculum-vitae-form__file-wrapper">
							<p className="curriculum-vitae-form__text">
								After you upload the file, use our edit tool to create a shape and hide your contact info. Make sure
								that your CV don’t have any contact information (address; email; phone number).
							</p>
							<Field
								style={{ display: 'none' }}
								name="cv"
								type="file"
								accept=".pdf"
								label="Upload CV"
								showValidationError={this.props.showValidationError}
								component={FileInput}
							/>
						</div>
					</div>
				</div>
			</form>
		)
	}
}

ClientPersonalInfo = reduxForm({
	form: 'ClientPersonalInfo',
	// destroyOnUnmount: false,
	// forceUnregisterOnUnmount: false,
	// enableReinitialize: true,
	destroyOnUnmount: false,
	forceUnregisterOnUnmount: true,
	validate,
})(ClientPersonalInfo)

//TODO fields
export default connect(({ user, form, dashboard }) => ({
  currentUser: user.currentUser,
  countriesList: dashboard.countriesList,
  phoneCodeList: dashboard.phoneCodeList,
  initialValues: getValues(
    form.ClientPersonalInfo,
		user.currentUser && user.currentUser.profile
			? {
				first_name: user.currentUser.profile.first_name,
				last_name: user.currentUser.profile.last_name,
				birth_date: user.currentUser.profile.birth_date,
				over18: !!user.currentUser.profile.over18,
				gender: user.currentUser.profile.gender,
				nationality: String(user.currentUser.profile.nationality),
				profession: user.currentUser.profile.profession,
				location: user.currentUser.profile.location || {},
        phone_number: user.currentUser.profile.phone_number,
        country_code: user.currentUser.profile.phone_number ? user.currentUser.profile.phone_number.country_code : null,
      }
      : {}
			),
}), {
    getCountriesTree
})(ClientPersonalInfo)
