export const PROFILE_GET_START = 'profile/GetStart'
export const PROFILE_GET_SUCCESS = 'profile/GetSuccess'
export const PROFILE_GET_FAIL = 'profile/GetFail'
export const CLEAN_PROFILE = 'profile/CleanProfile'
export const PROFILE_BULK_GET_START = 'profile/BulkGetStart'
export const PROFILE_BULK_GET_SUCCESS = 'profile/BulkGetSuccess'
export const PROFILE_BULK_GET_FAIL = 'profile/BulkGetFail'

export const PROFILE_BULK_REMOVE_START = 'profile/BulkRemoveStart'
export const PROFILE_BULK_REMOVE_SUCCESS = 'profile/BulkRemoveSuccess'
export const PROFILE_BULK_REMOVE_FAIL = 'profile/BulkRemoveFail'

export const POST_REVIEW_START = 'profile/PostReviewStart'
export const POST_REVIEW_SUCCESS = 'profile/PostReviewSuccess'
export const POST_REVIEW_FAIL = 'profile/PostReviewFail'

export const TAGS_AUTOCOMPLETE_START = 'profile/TagsAutocompleteStart'
export const TAGS_AUTOCOMPLETE_SUCCESS = 'profile/TagsAutocompleteSUCCESS'
export const TAGS_AUTOCOMPLETE_FAIL = 'profile/TagsAutocompleteFAIL'

export const STYLES_AUTOCOMPLETE_START = 'profile/STYLESAutocompleteStart'
export const STYLES_AUTOCOMPLETE_SUCCESS = 'profile/STYLESAutocompleteSUCCESS'
export const STYLES_AUTOCOMPLETE_FAIL = 'profile/STYLESAutocompleteFAIL'

export const FETCHING_REVIEWS_START = 'profile/FetchingReviewStart'
export const FETCHING_REVIEWS_SUCCESS = 'profile/FetchingReviewSuccess'
export const FETCHING_REVIEWS_FAIL = 'profile/FetchingReviewFail'

export const SETTING_CLAPPED_START = 'profile/SETTING_CLAPPED_START'
export const SETTING_CLAPPED_SUCCESS = 'profile/SETTING_CLAPPED_SUCCESS'
export const SETTING_CLAPPED_FAIL = 'profile/SETTING_CLAPPED_FAIL'

export const SETTING_FOLLOWED_START = 'profile/SETTING_FOLLOWED_START'
export const SETTING_FOLLOWED_SUCCESS = 'profile/SETTING_FOLLOWED_SUCCESS'
export const SETTING_FOLLOWED_FAIL = 'profile/SETTING_FOLLOWED_FAIL'

export const SETTING_FAVORITED_START = 'profile/SETTING_FAVORITED_START'
export const SETTING_FAVORITED_SUCCESS = 'profile/SETTING_FAVORITED_SUCCESS'
export const SETTING_FAVORITED_FAIL = 'profile/SETTING_FAVORITED_FAIL'

export const UPDATE_FAV_OF_STORED_PROFILES = 'profile/UPDATE_FAV_OF_STORED_PROFILES'

// export const FETCHING_GIGS_LIST_START = 'FETCHING_GIGS_LIST_START'
// export const FETCHING_GIGS_LIST_SUCCESS = 'FETCHING_GIGS_LIST_SUCCESS'
// export const FETCHING_GIGS_LIST_FAIL = 'FETCHING_GIGS_LIST_FAIL'

export const DROP_REVIEWS = 'profile/DropReviews'

const initialState = {
	profile: {
		tag_list: [],
		tags: [],
		rating_statistic: {},
		reviews: [],
		videos: [],
		audios: [],
		pictures: [],
		fee_primary: {},
		fee_secondary: {},
	},
	reviews: [],
	lastReviewPage: 1,
	tags: [],
	bulkProfiles: [],
	isSettingClapped: false,
	isSettingFollowed: false,
	isSettingFavorited: false,

	// nextGigs: [],
	// isFetchingNextGigs: false,
}

export default function profile(state = initialState, action = {}) {
	switch (action.type) {
		case PROFILE_GET_START:
			return {
				...state,
				isGettingProfile: true,
			}
		case PROFILE_GET_SUCCESS:
			action.result.data.videos = action.result.data.media_items
				.filter(item => item.type === 'video')
				.sort((a, b) => a.position_index - b.position_index)

			action.result.data.audios = action.result.data.media_items
				.filter(item => item.type === 'audio')
				.sort((a, b) => a.position_index - b.position_index)

			action.result.data.pictures = action.result.data.media_items
				.filter(item => item.type === 'picture')
				.sort((a, b) => a.position_index - b.position_index)

			const profile = action.result.data
			profile.is_favorite = profile.favorited || false;

			return {
				...state,
				isGettingProfile: false,
				profile,
			}
		case PROFILE_GET_FAIL:
			return {
				...state,
				isGettingProfile: false,
			}
		case CLEAN_PROFILE:
			return {
				...state,
				profile: JSON.parse(JSON.stringify(initialState.profile))
			}
		case PROFILE_BULK_GET_START:
			return {
				...state,
				isGettingBulkProfiles: true,
			}
		case PROFILE_BULK_GET_SUCCESS:
			// todo: we dont get is_favorite from backend so it need some tricks
			const bulkProfiles = action.result.data.map(profile => {
				const item = action.itemsToCompareForFillFavorite.find(item => item.id === profile.id)
				const is_favorite = item ? item.is_favorite : false;
				return {
					...profile,
					is_favorite,
				}
			})
			return {
				...state,
				isGettingBulkProfiles: false,
				bulkProfiles,
			}
		case PROFILE_BULK_GET_FAIL:
			return {
				...state,
				isGettingBulkProfiles: false,
			}
		case PROFILE_BULK_REMOVE_START:
			return {
				...state,
			}
		case PROFILE_BULK_REMOVE_SUCCESS:
			const newBulkProfiles = [...state.bulkProfiles]
			const { itemId } = action.result
			const itemIndex = newBulkProfiles.findIndex(item => item.id === itemId)

			if (itemIndex > -1) {
				newBulkProfiles.splice(itemIndex, 1)
				return {
					...state,
					bulkProfiles: newBulkProfiles,
				}
			}
		case PROFILE_BULK_REMOVE_FAIL:
			return {
				...state,
			}
		case FETCHING_REVIEWS_START:
			return {
				...state,
				isGettingReviews: true,
			}
		case FETCHING_REVIEWS_SUCCESS:
			return {
				...state,
				isGettingReviews: false,
				reviews: [...state.reviews, ...action.result.data.data],
				lastReviewPage: action.result.data.last_page,
			}
		case FETCHING_REVIEWS_FAIL:
			return {
				...state,
				isGettingReviews: false,
			}
		case TAGS_AUTOCOMPLETE_START:
			return {
				...state,
				isAutocompletingTags: true,
			}
		case TAGS_AUTOCOMPLETE_SUCCESS:
			return {
				...state,
				isAutocompletingTags: false,
				tags: action.result.data,
			}
		case TAGS_AUTOCOMPLETE_FAIL:
			return {
				...state,
				isAutocompletingTags: false,
			}
		case DROP_REVIEWS:
			return {
				...state,
				reviews: [],
				lastReviewPage: 1,
			}
		case SETTING_CLAPPED_START:
			return {
				...state,
				isSettingClapped: true,
			}
		case SETTING_CLAPPED_SUCCESS:
			return {
				...state,
				isSettingClapped: false,
				profile: {
					...state.profile,
					clapped: action.value,
				},
			}
		case SETTING_CLAPPED_FAIL:
			return {
				...state,
				isSettingClapped: false,
			}
		case SETTING_FOLLOWED_START:
			return {
				...state,
				isSettingFollowed: true,
			}
		case SETTING_FOLLOWED_SUCCESS:
			return {
				...state,
				isSettingFollowed: false,
				profile: {
					...state.profile,
					followed: action.value,
				},
			}
		case SETTING_FOLLOWED_FAIL:
			return {
				...state,
				isSettingFollowed: false,
			}
		case SETTING_FAVORITED_START:
			return {
				...state,
				isSettingFavorited: true,
			}
		case SETTING_FAVORITED_SUCCESS:
			return {
				...state,
				profile: {
					...state.profile,
					favorited: action.value,
				},
				isSettingFavorited: false,
			}
		case SETTING_FAVORITED_FAIL:
			return {
				...state,
				isSettingFavorited: false,
			}
		default:
			return state
	}
}

export function getProfile(id) {
	return {
		types: [PROFILE_GET_START, PROFILE_GET_SUCCESS, PROFILE_GET_FAIL],
		promise: client => client.get(`/api/users/${id}/profile`),
	}
}

export function getProfileByName(name) {
  return {
    types: [PROFILE_GET_START, PROFILE_GET_SUCCESS, PROFILE_GET_FAIL],
    promise: client => client.get(`/api/users/${name}/profileByName`),
  }
}

export function cleanProfile() {
	return {
		type: CLEAN_PROFILE,
	}
}

export function updateProfileInSearch() {
	return {
		types: [null, null, null],
		promise: client => client.put(`/api/users/check-profile`)
	}
}

export function getBulkProfiles(itemsToCompare) {
	const ids = itemsToCompare.map(x => x.id);
	return {
		types: [PROFILE_BULK_GET_START, PROFILE_BULK_GET_SUCCESS, PROFILE_BULK_GET_FAIL],
		promise: client => client.get(`/api/users/bulk?user_ids=${ids.join(',')}&include=profile,tags,styles,services,membersDetails,answers`),
		itemsToCompareForFillFavorite: itemsToCompare,
	}
}

export function removeFromBulkProfiles(id) {
	return {
		types: [PROFILE_BULK_REMOVE_START, PROFILE_BULK_REMOVE_SUCCESS, PROFILE_BULK_REMOVE_FAIL],
		promise: () => Promise.resolve({ itemId: id }),
	}
}

/**
 * @param {string} targetUserId user who is been reviewed
 * @param {object} review the reivew object
 * @returns {{types: [string,string,string], promise: (function(*))}} nothing
 */
export function postReview(targetUserId, review) {
	return {
		types: [POST_REVIEW_START, POST_REVIEW_SUCCESS, POST_REVIEW_FAIL],
		promise: client => client.post(`/api/users/${targetUserId}/reviews`, review),
	}
}

//TODO refactoring
export function autocompleteTags(query) {
	return {
		types: [TAGS_AUTOCOMPLETE_START, TAGS_AUTOCOMPLETE_SUCCESS, TAGS_AUTOCOMPLETE_FAIL],
		promise: client => client.get(`/api/autocomplete/tags?query=${query}`),
	}
}

export function getAutocompleteStyles(categoryId, params) {
	return {
		types: [STYLES_AUTOCOMPLETE_START, STYLES_AUTOCOMPLETE_SUCCESS, STYLES_AUTOCOMPLETE_FAIL],
		promise: client => client.get(`/api/autocomplete/styles`, { params: { category_id : categoryId, ...params } }),
	}
}

const numberOfLoadReviews = 10

export function getReviews(profileId, pageNumber, star, sortBy) {
	return {
		types: [FETCHING_REVIEWS_START, FETCHING_REVIEWS_SUCCESS, FETCHING_REVIEWS_FAIL],
		promise: client =>
			client.get(`/api/users/${profileId}/reviews?page=${pageNumber}&per_page=${numberOfLoadReviews}&star=${star}&sort_by=${sortBy}`),
	}
}

export function dropReviews() {
	return {
		types: [null, DROP_REVIEWS, null],
		promise: () => Promise.resolve(),
	}
}

export function setClapped(value, targetId, targetType = 'profile') {
	const method = value ? 'post' : 'delete'
	const payload = {
		target_type: targetType,
		target_id: targetId,
	}

	return {
		value,
		types: [SETTING_CLAPPED_START, SETTING_CLAPPED_SUCCESS, SETTING_CLAPPED_FAIL],
		promise: client =>
			client[method](
				'/api/claps',
				value
					? payload
					: {
							params: payload,
					  }
			),
	}
}

export function setFollowed(value, targetId) {
	return {
		value,
		types: [SETTING_FOLLOWED_START, SETTING_FOLLOWED_SUCCESS, SETTING_FOLLOWED_FAIL],
		promise: client => client[value ? 'post' : 'delete'](`/api/users/${targetId}/followers`),
	}
}

// it handle fav change only in user profile view
// todo remove and use addFavorite / renmoveFavorite from favorite module
export function setFavorited(value, targetId) {
	return {
		value,
		types: [SETTING_FAVORITED_START, SETTING_FAVORITED_SUCCESS, SETTING_FAVORITED_FAIL],
		promise: client => client[value ? 'post' : 'delete'](`/api/users/${targetId}/favorites`),
	}
}

// export function getNextGigs(profileId) {
// 	return {
// 		types: [FETCHING_GIGS_LIST_START, FETCHING_GIGS_LIST_SUCCESS, FETCHING_GIGS_LIST_FAIL],
// 		promise: client => client.get(`/api/gigs/next/${profileId}/apply`),
// 	}
// }


export function getSuggestedProfiles(profiles_type) {
	return {
		types: [null, null, null],
		promise: client => client.get(profiles_type === 'ARTISTS' ? '/api/discover/artists' : '/api/discover/venues')
			.then(res => res.data[0].users.slice(0, 3) )
	}
}
