import * as _ from 'lodash';

export const CONTACT_SUBMIT_START = 'contact/SUBMIT_START';
export const CONTACT_SUBMIT_SUCCESS = 'contact/SUBMIT_SUCCESS';
export const CONTACT_SUBMIT_FAIL = 'contact/SUBMIT_FAIL';

export const COUNTRIES_LIST_GET_START = 'countries/LIST_GET_START';
export const COUNTRIES_LIST_GET_SUCCESS = 'countries/LIST_GET_SUCCESS';
export const COUNTRIES_LIST_GET_FAIL = 'countries/LIST_GET_FAIL';

export const CATEGORIES_GET_START = 'categories/GET_START';
export const CATEGORIES_GET_SUCCESS = 'categories/GET_SUCCESS';
export const CATEGORIES_GET_FAIL = 'categories/GET_FAIL';

export const ARTISTS_CATEGORIES_GET_START = 'categories/ARTISTS_GET_START';
export const ARTISTS_CATEGORIES_GET_SUCCESS = 'categories/ARTISTS_GET_SUCCESS';
export const ARTISTS_CATEGORIES_GET_FAIL = 'categories/ARTISTS_GET_FAIL';

export const VENUES_CATEGORIES_GET_START = 'categories/VENUES_GET_START';
export const VENUES_CATEGORIES_GET_SUCCESS = 'categories/VENUES_GET_SUCCESS';
export const VENUES_CATEGORIES_GET_FAIL = 'categories/VENUES_GET_FAIL';

export const SEARCH_CATEGORIES_GET_START = 'search_categories/GET_START';
export const SEARCH_CATEGORIES_GET_SUCCESS = 'search_categories/GET_SUCCESS';
export const SEARCH_CATEGORIES_GET_FAIL = 'search_categories/GET_FAIL';

export const CATEGORIES_GET_PARENTS_START = 'categories/GET_PARENTS_START';
export const CATEGORIES_GET_PARENTS_SUCCESS = 'categories/GET_PARENTS_SUCCESS';
export const CATEGORIES_GET_PARENTS_FAIL = 'categories/GET_PARENTS_FAIL';

export const CATEGORIES_GET_CHILDREN_START = 'categories/GET_CHILDREN_START';
export const CATEGORIES_GET_CHILDREN_SUCCESS = 'categories/GET_CHILDREN_SUCCESS';
export const CATEGORIES_GET_CHILDREN_FAIL = 'categories/GET_CHILDREN_FAIL';

export const FAQ_QUESTIONS_START = 'faq/GET_FAQ_START';
export const FAQ_QUESTIONS_SUCCESS = 'faq/GET_FAQ_SUCCESS';
export const FAQ_QUESTIONS_FAIL = 'faq/GET_FAQ_FAIL';

export const CATEGORIES_GET_TREE_START = 'categories/GET_TREE_START';
export const CATEGORIES_GET_TREE_SUCCESS = 'categories/GET_TREE_SUCCESS';
export const CATEGORIES_GET_TREE_FAIL = 'categories/GET_TREE_FAIL';

export const SEARCH_STYLES_GET_START = 'styles/SEARCH_STYLES_GET_START';
export const SEARCH_STYLES_GET_SUCCESS = 'styles/SEARCH_STYLES_GET_SUCCESS';
export const SEARCH_STYLES_GET_FAIL = 'styles/SEARCH_STYLES_GET_FAIL';

export const SET_POPUP_VISIBLE= 'categories/SET_POPUP_VISIBLE';

let categoriesCache = proxyFactory();
let searchCategoriesCache = searchProxyFactory();
let artistsCategoriesCache = proxyFactory();
let venuesCategoriesCache = proxyFactory();

function proxyFactory(list = []) {
    return new Proxy({ list }, {
        get: (target, name) => {
            if (name === 'all') {
                return target.list;
            } else {
                return target.list.filter(cat => cat.assignable_by && cat.assignable_by.includes(name));
            }
        }
    });
}

function searchProxyFactory(list = []) {
    return new Proxy({ list }, {
        get: (target, name) => {
            if (name === 'all') {
                return target.list;
            }
        }
    });
}

const initialState = {
    countriesList: [],
    country_code: [],
    contactSubmitError: null,
    isSubmittingContact: false,
    countriesListGetError: null,
    isGettingCountriesList: false,
    parentCategories: [],
    childrenCategories: [],

    isGettingCategories: false,
    isCategoriesCached: false,
    categories: categoriesCache,
    categoriesMap: {},

    isGettingSearchCategories: false,
    isSearchCategoriesCached: false,
    searchCategories: searchCategoriesCache,
    searchCategoriesMap: {},

    isGettingArtistsCategories: false,
    isArtistsCategoriesCached: false,
    artistsCategories: artistsCategoriesCache,
    artistsCategoriesMap: {},

    isGettingVenuesCategories: false,
    isVenuesCategoriesCached: false,
    venuesCategories: venuesCategoriesCache,
    venuesCategoriesMap: {},

  faqList: [],
  isGettingFaqList: false,
  faqListGetError: false,

  categoryTree: [],
  isGettingCategoryTree: false,
  categoryTreeGetError: false,

  searchStyles: [],
  isGettingSearchStyles: false,
  searchStylesGetError: false,
  isSearchStylesFuncCalled: false,

  popupVisible: false,
  backendError: null
};

export default function common(state = initialState, action = {}) {
    switch (action.type) {
        case CONTACT_SUBMIT_START:
            return {
                ...state,
                isSubmittingContact: true,
            };
        case CONTACT_SUBMIT_SUCCESS:
            return {
                ...state,
                isSubmittingContact: false,
            };
        case CONTACT_SUBMIT_FAIL:
            return {
                ...state,
                isSubmittingContact: false,
                contactSubmitError: action.error,
            };
        case COUNTRIES_LIST_GET_START:
            return {
                ...state,
                isGettingCountriesList: true,
            };
        case COUNTRIES_LIST_GET_SUCCESS:
            return {
                ...state,
                isGettingCountriesList: false,
                countriesList: action.result.data,
            };
        case COUNTRIES_LIST_GET_FAIL:
            return {
                ...state,
                isGettingCountriesList: false,
                countriesListGetError: action.error,
            };


        case CATEGORIES_GET_START:
            return {
                ...state,
                isGettingCategories: true,
            };

        case CATEGORIES_GET_SUCCESS:
            categoriesCache = proxyFactory(action.result.data);
            return {
                ...state,
                categories: categoriesCache,
                categoriesMap: action.result.data.reduce((acc, item) =>
                    (acc[item.id] = item, acc),
                    {}),
                isGettingCategories: false,
                isCategoriesCached: true,
            };

        case CATEGORIES_GET_FAIL:
            return {
                ...state,
                isGettingCategories: false,
                categoriesGetError: action.error,
            };


        case SEARCH_CATEGORIES_GET_START:
            return {
                ...state,
                isGettingSearchCategories: true,
            };

        case SEARCH_CATEGORIES_GET_SUCCESS:
            searchCategoriesCache = searchProxyFactory(
                _.uniqWith(action.result.data, (a, b) => a.id === b.id && a.parent_category_id === b.parent_category_id)
            );
            return {
                ...state,
                searchCategories: searchCategoriesCache,
                searchCategoriesMap: action.result.data.reduce((acc, item) =>
                    (acc[item.id] = item, acc),
                    {}),
                isGettingSearchCategories: false,
                isSearchCategoriesCached: true,
            };

        case SEARCH_CATEGORIES_GET_FAIL:
            return {
                ...state,
                isGettingSearchCategories: false,
                categoriesGetError: action.error,
            };


        case CATEGORIES_GET_PARENTS_START:
            return {
                ...state,
                isGettingCategories: true,
            };

        case CATEGORIES_GET_PARENTS_SUCCESS:
            return {
                ...state,
                isGettingCategories: false,
                parentCategories: action.result.data
            }

        case CATEGORIES_GET_PARENTS_FAIL:
            return {
                ...state,
                isGettingCategories: false,
            };


        case ARTISTS_CATEGORIES_GET_START:
            return {
                ...state,
                isGettingArtistsCategories: true,
            };

        case ARTISTS_CATEGORIES_GET_SUCCESS:
            artistsCategoriesCache = proxyFactory(action.result.data);
            return {
                ...state,
                artistsCategories: artistsCategoriesCache,
                artistsCategoriesMap: action.result.data.reduce((acc, item) =>
                    (acc[item.id] = item, acc),
                    {}),
                isGettingArtistsCategories: false,
                isArtistsCategoriesCached: true,
            };

        case ARTISTS_CATEGORIES_GET_FAIL:
            return {
                ...state,
                isGettingArtistsCategories: false,
                artistsCategoriesGetError: action.error,
            };



        case VENUES_CATEGORIES_GET_START:
            return {
                ...state,
                isGettingVenuesCategories: true,
            };

        case VENUES_CATEGORIES_GET_SUCCESS:
            venuesCategoriesCache = proxyFactory(action.result.data);
            return {
                ...state,
                venuesCategories: venuesCategoriesCache,
                venuesCategoriesMap: action.result.data.reduce((acc, item) =>
                    (acc[item.id] = item, acc),
                    {}),
                isGettingVenuesCategories: false,
                isVenuesCategoriesCached: true,
            };

        case VENUES_CATEGORIES_GET_FAIL:
            return {
                ...state,
                isGettingVenuesCategories: false,
                venuesCategoriesGetError: action.error,
            };


        case CATEGORIES_GET_CHILDREN_START:
            return {
                ...state,
                isGettingCategories: true,
            };
        case CATEGORIES_GET_CHILDREN_SUCCESS:
            return {
                ...state,
                isGettingCategories: false,
                childrenCategories: [...state.childrenCategories, ...action.result.data]
            }
        case CATEGORIES_GET_CHILDREN_FAIL:
            return {
                ...state,
                isGettingCategories: false,
            };

      case SET_POPUP_VISIBLE:
            return {
                ...state,
                popupVisible: action.visibility,
                backendError: action.error
            }

      case FAQ_QUESTIONS_START:
        return {
          ...state,
          isGettingFaqList: true,
        };
      case FAQ_QUESTIONS_SUCCESS:
        return {
          ...state,
          isGettingFaqList: false,
          faqList: action.result.data,
        };
      case FAQ_QUESTIONS_FAIL:
        return {
          ...state,
          isGettingFaqList: false,
          faqListGetError: action.error,
        };

      case CATEGORIES_GET_TREE_START:
        return {
          ...state,
          isGettingCategoryTree: true,
        };
      case CATEGORIES_GET_TREE_SUCCESS:
        return {
          ...state,
          isGettingCategoryTree: false,
          categoryTree: action.result.data,
        };
      case CATEGORIES_GET_TREE_FAIL:
        return {
          ...state,
          isGettingCategoryTree: false,
          categoryTreeGetError: action.error,
        };

      case SEARCH_STYLES_GET_START:
        return {
          ...state,
          isGettingSearchStyles: true,
        };
      case SEARCH_STYLES_GET_SUCCESS:
        return {
          ...state,
          isGettingSearchStyles: false,
          searchStyles: action.result.data,
          isSearchStylesFuncCalled: true
        };
      case SEARCH_STYLES_GET_FAIL:
        return {
          ...state,
          isGettingSearchStyles: false,
          searchStylesGetError: action.error,
        };

        default:
            return state;
    }
}

export function submitContact(data) {
    return {
        types: [
            CONTACT_SUBMIT_START,
            CONTACT_SUBMIT_SUCCESS,
            CONTACT_SUBMIT_FAIL
        ],
        promise: (client) => client.post('/api/feedback', data),
    };
}

export function getCountriesList() {
    return {
        types: [
            COUNTRIES_LIST_GET_START,
            COUNTRIES_LIST_GET_SUCCESS,
            COUNTRIES_LIST_GET_FAIL
        ],
        promise: (client) => client.get('/api/locations'),
    };
}

export function getFaqList(faqSectionName, faqCategory = null) {
  let url = faqSectionName ? `/api/faq/${faqSectionName}` : '/api/faq';

  if (faqCategory) {
    url = `${url}?categoryName=${faqCategory}`
  }

  return {
    types: [
      FAQ_QUESTIONS_START,
      FAQ_QUESTIONS_SUCCESS,
      FAQ_QUESTIONS_FAIL
    ],
    promise: (client) => client.get(url),
  };
}

export function getCategoryTree() {
  return {
    types: [
      CATEGORIES_GET_TREE_START,
      CATEGORIES_GET_TREE_SUCCESS,
      CATEGORIES_GET_TREE_FAIL
    ],
    promise: (client) => client.get('/api/category-tree'),
  };
}


export function getSearchStyles(categoryId) {
  return {
    types: [
      SEARCH_STYLES_GET_START,
      SEARCH_STYLES_GET_SUCCESS,
      SEARCH_STYLES_GET_FAIL
    ],
    promise: (client) => client.get(`/api/search-styles?categoryId=${categoryId}`),
  };
}

export function getCategories() {
    return (dispatch, getState) => {
        const { common: { isCategoriesCached, isGettingCategories } } = getState();

        if (isCategoriesCached || isGettingCategories) {
            return dispatch({
                types: [null, null, null],
                promise: () => Promise.resolve({ data: categoriesCache }),
            });
        }
        else {
            return dispatch({
                types: [
                    CATEGORIES_GET_START,
                    CATEGORIES_GET_SUCCESS,
                    CATEGORIES_GET_FAIL
                ],
                promise: client => client.get('/api/categories'),
            });
        }
    };
}

export function getArtistsCategories() {
    return (dispatch, getState) => {
        const { common: { isArtistsCategoriesCached, isGettingArtistsCategories } } = getState();

        if (isArtistsCategoriesCached || isGettingArtistsCategories) {
            return dispatch({
                types: [null, null, null],
                promise: () => Promise.resolve({ data: artistsCategoriesCache }),
            });
        }
        else {
            return dispatch({
                types: [
                    ARTISTS_CATEGORIES_GET_START,
                    ARTISTS_CATEGORIES_GET_SUCCESS,
                    ARTISTS_CATEGORIES_GET_FAIL
                ],
                promise: client => client.get('/api/categories?profile_type=artists'),
            });
        }
    }
}

export function getVenuesCategories() {
    return (dispatch, getState) => {
        const { common: { isVenuesCategoriesCached, isGettingVenuesCategories } } = getState();

        if (isVenuesCategoriesCached || isGettingVenuesCategories) {
            return dispatch({
                types: [null, null, null],
                promise: () => Promise.resolve({ data: venuesCategoriesCache }),
            });
        }
        else {
            return dispatch({
                types: [
                    VENUES_CATEGORIES_GET_START,
                    VENUES_CATEGORIES_GET_SUCCESS,
                    VENUES_CATEGORIES_GET_FAIL
                ],
                promise: client => client.get('/api/categories?profile_type=venues'),
            });
        }
    }
}

export function getSearchCategories() {
    return (dispatch, getState) => {
        const { common: { isSearchCategoriesCached, isGettingSearchCategories } } = getState();

        if (isSearchCategoriesCached || isGettingSearchCategories) {
            return dispatch({
                types: [null, null, null],
                promise: () => Promise.resolve({ data: searchCategoriesCache }),
            });
        }
        else {
            return dispatch({
                types: [
                    SEARCH_CATEGORIES_GET_START,
                    SEARCH_CATEGORIES_GET_SUCCESS,
                    SEARCH_CATEGORIES_GET_FAIL
                ],
                promise: client => client.get('/api/search-categories'),
            });
        }
    }
}

export function getParentCategories() {

    return {
        types: [
            CATEGORIES_GET_PARENTS_START,
            CATEGORIES_GET_PARENTS_SUCCESS,
            CATEGORIES_GET_PARENTS_FAIL
        ],
        promise: client => client.get('/api/main-categories'),
    };
}

export function getChildrenCategories(parent_Id) {

    return {
        types: [
            CATEGORIES_GET_CHILDREN_START,
            CATEGORIES_GET_CHILDREN_SUCCESS,
            CATEGORIES_GET_CHILDREN_FAIL
        ],
        promise: client => client.get(`/api/categories/${parent_Id}`),
    };
}

export function setPopupVisible(visibility, error = null){
	return {
		type: SET_POPUP_VISIBLE,
		visibility,
    error
	}
}
