import React from 'react';

import * as _ from 'lodash'
import { connect } from 'react-redux'
import Echo from 'laravel-echo'
import Pusher from 'pusher-js'
import config from '../../config'
import { addNotification, setPusherStatus, getNotifications } from '../../redux/modules/notifications';
import Button from '../common/Button/Button';
import { NOTIFICATIONS_STATUSES, VENDOR_ACTIONS, OWNER_ACTIONS, DASHBOARD_BOOKING_ACTIONS, PLAN_UPGRADE_ACTIONS, PROFILE_ACTIONS, HIDDEN_ACTIONS } from '../../utils/constants';
import { getNewMessages, getChatsList, refreshUnseenMessagesCount } from '../../redux/modules/inbox';
import { Switch, Route } from 'react-router-dom';
const PUSHER_APP_KEY = '13edf1ae05c452e6615f'

@connect(({ user, notifications }) => ({
    currentUser: user.currentUser,
    notifications: notifications.notifications,
}))
class InboxStuff extends React.PureComponent {
    //shouldComponentUpdate(nextProps) {
    //    if (_.isEmpty(this.props.currentUser) && !_.isEmpty(nextProps.currentUser) ||
    //        !_.isEmpty(this.props.currentUser) && _.isEmpty(nextProps.currentUser) ||
    //        
    //    ) return true
    //    return false
    //}

    render() {
        // console.log('new render !', this.props)
        if (this.props.currentUser && !_.isEmpty(this.props.currentUser)) return <Switch>
                <Route path="/dashboard/inbox/:id" render={props => <ChatObserver notifications={this.props.notifications} {...props} />} />
                <Route path="/*" render={props => <MessagesObserver notifications={this.props.notifications} {...props} />} />
            </Switch>
        return null
    }
}

export default InboxStuff

const REFRESH_MESSAGES_INTERVAL = 50000

@connect(() => ({
}), {
    refreshUnseenMessagesCount
})
class MessagesObserver extends React.PureComponent {
    constructor(props) {
        super(props);
        // console.log('Messages ObserVER !! ')
        this.throttledFetchNewMessages = _.throttle(this.props.refreshUnseenMessagesCount, 500)
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        // console.log('dostaje nowa wiadomosc XD', nextProps)
        if (nextProps.notifications.length > this.props.notifications.length) {
            const lastNotification = nextProps.notifications[nextProps.notifications.length -1 ]
            if ([NOTIFICATIONS_STATUSES.NEW_CHAT_MESSAGE].includes(lastNotification.action) && !lastNotification.read) {
                this.throttledFetchNewMessages()
            }
        }
    }

    shouldComponentUpdate() {
        return false
    }
    

    render() {
        return <div />
    }
}

@connect(() => ({
}), {
    getNewMessages,
    refreshUnseenMessagesCount   
})
class ChatObserver extends React.PureComponent {
    constructor(props) {
        super(props);
        // console.log('chat ObserVER /// ')
        //this.throttledFetchNewMessages = _.throttle(this.props.refreshUnseenMessagesCount, 500)
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.notifications.length > this.props.notifications.length) {
            const lastNotification = nextProps.notifications[nextProps.notifications.length -1]
            if ([NOTIFICATIONS_STATUSES.NEW_CHAT_MESSAGE].includes(lastNotification.action) && !lastNotification.read) {
                const chatId = _.get(this.props, 'location.pathname').split('/')[3]          
                if (chatId) {
                    this.props.getNewMessages(chatId)
                }
                this.props.refreshUnseenMessagesCount()
            }
        }
    }

    shouldComponentUpdate() {
        return false
    }
    

    render() {
        return <div />
    }
}