import { MEDIA_WIDTH, LAPTOP_WIDTH, TABLET_WIDTH, SMALL_BREAKPOINT_MAX } from '../../utils/constants'
const HANDLE_RESIZE = 'globalConfig/HANDLE_RESIZE'

const initialState = {}

export default function globalConfig(state = initialState, action = {}) {
	switch (action.type) {
		case HANDLE_RESIZE:
			return {
				...state,
				resize_width: action.width,
				isExtraSmall: action.width <= SMALL_BREAKPOINT_MAX,
				isMobile: action.width <= MEDIA_WIDTH,
				isTablet: action.width <= TABLET_WIDTH,
				isLaptop: action.width <= LAPTOP_WIDTH,				
			}
		default:
			return state
	}
}

export function handleResize(width) {
	return {
		type: HANDLE_RESIZE,
		width: width,
	}
}
