import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { reduxForm, Field } from 'redux-form';
import Rating from 'react-rating';
import '../../styles/components/ReviewForm.scss';
import validate from '../../helpers/validators/reviewFormValidator';


@reduxForm({
  form: 'reviewForm',
  validate,
  initialValues: {rating: 5},
  fields: ['content', 'title', 'rating']
})

export default class ReviewForm extends Component {
  static propTypes = {
    fields: PropTypes.array.isRequired,
    submitting: PropTypes.bool.isRequired,
    pristine: PropTypes.bool.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired
  };

  constructor (props) {
    super(props);
  }

  handleSubmitButton = (values)=>{
    const { onSubmit, onClose, } = this.props;
    onSubmit(values);
    onClose();
  };

  render () {
    const {
      onClose,
      handleSubmit
    } = this.props;
    
    return (
      <form className="review-form"
        onSubmit={handleSubmit(data=>this.handleSubmitButton(data))}>
        <div className="review-form__header">
          <Field name="title"
            type="text"
            component={renderFieldInput}
            className="title-field"
            placeholder="Give your review a title"/>
          <div className="review-form__stars">
            <Field name="rating"
              component={RatingField}
              className="review-text-field"/>
          </div>
        </div>
        <span onClick={onClose}
          className="close">
          <img src={require('../../assets/login-x-mark.svg')}
            alt="back"/>
        </span>
        <Field name="content"
          component={renderFieldTextArea}
          className="review-text-field"
          placeholder="Write your review" />
        <button className="btn review-submit-btn"
          type="submit">
          <b>Post review</b>
        </button>
      </form>
    );
  }
}

const RatingField = class ReviewForm extends Component {
  render() {
    const { input: { value, onChange }, meta: { touched, error, warning } } = this.props;
    const initialRating = value || 5;
    
    return (
      <div>
        <Rating
          initialRate={initialRating}
          onChange={onChange}
          empty="fa fa-star-o fa-fw"
          full="fa fa-star fa-fw" />
        {touched &&
        ((error &&
          <span>
            {error}
          </span>) ||
          (warning &&
            <span>
              {warning}
            </span>))}
      </div>
    );
  }
};


const renderFieldInput = ({
  input,
  placeholder,
  type,
  meta: { touched, error, warning }
}) =>
  <div className="input-wrapper">
    <input {...input} placeholder={placeholder} type={type} />
    {touched &&
      ((error &&
        <span>
          {error}
        </span>) ||
        (warning &&
          <span>
            {warning}
          </span>))}
  </div>;

const renderFieldTextArea = ({
  input,
  placeholder,
  type,
  meta: { touched, error, warning }
}) =>
  <div className="textarea-wrapper">
    <textarea {...input} placeholder={placeholder} type={type} />
    {touched &&
      ((error &&
        <span>
          {error}
        </span>) ||
        (warning &&
          <span>
            {warning}
          </span>))}
  </div>;
