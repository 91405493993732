import React, { Component } from 'react';
import { Popover } from 'material-ui';

export default class FilterPopOver extends Component {
  render() {
    const { children, isOpen, anchor, onClose, ...restProps } = this.props;

    return (
      <Popover
        open={isOpen}
        anchorEl={anchor}
        onRequestClose={onClose}
        useLayerForClickAway={false}
        style={{ zIndex: 1040 }}
        className="PopoverMain"
        canAutoPosition={false}
        targetOrigin={{ horizontal: 'left', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
        {...restProps}
      >
        {children}
      </Popover>
    );
  }
}
