import { InputStyles, PlaceholderStyles } from '../CommonComponent';
import React, { Component } from 'react';
import { currency, period } from '../../../utils/constants';
import { MenuItem, SelectField, TextField } from 'material-ui';
import { registrationConfig } from '../../../config';
import { connect } from 'react-redux'


@connect(({ profile }) => ({
      profile: profile.profile
}))
export class PriceList extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    const { input: { value, onChange }, profile } = this.props;

    if (!value || !value.length) {
      onChange([{
        name: '',
        price: '',
        currency: '',
        measurement: '',
        showOther: false
      }]);
    } else {
      const newPeriod = profile.profile_type === 'venue' ? period.venue : period.common
      onChange(value.map(val => ({
        ...val,
        showOther: val.measurement ? !newPeriod.find((per) => per.value === val.measurement) : false
      })));
    }
    this.addPriceItem();
  }


  onChangePriceListCurrency = (newValue, index) => {
    const { input: { value, onChange } } = this.props;
    const _list = [...value];
    _list[index].currency = newValue;
    onChange(_list);
    this.forceUpdate();
  };

  onChangePriceListValue = (newValue, index) => {
    const { input: { value, onChange } } = this.props;
    if (!isNaN(newValue)) {
      const _list = [...value];
      _list[index].price = newValue;
      onChange(_list);
    }
    this.forceUpdate();
  };

  onChangePriceListPeriodSelect = (newValue, index) => {
    const { input: { value, onChange } } = this.props;
    const _list = [...value];
    _list[index].measurement = newValue;
    _list[index].showOther = newValue === 'other';
    onChange(_list);
    this.forceUpdate();
  };

  onChangePriceListPeriodOther = (newValue, index) => {
    const { input: { value, onChange } } = this.props;
    const _list = [...value];
    _list[index].measurement = newValue;
    onChange(_list);
    this.forceUpdate();
  };

  onChangePriceListName = (newValue, index) => {
    const { input: { value, onChange } } = this.props;
    const _list = [...value];
    _list[index].name = newValue;
    onChange(_list);
    this.forceUpdate();
  };

  addPriceItem = (event) => {
    //event.preventDefault();
    const { input: { value, onChange } } = this.props;
    const _list = [...value];
    _list[_list.length] = { name: '', price: '', currency: '', measurement: '' };
    onChange(_list);
  };

  removePriceItem = index => {
		const {
			input: { value, onChange },
		} = this.props

		const _list = [...value]
		_list.splice(index, 1)
		onChange(_list)
	}

  render() {
    const { input: { value }, profile } = this.props;
    const newPeriod = profile.profile_type === 'venue' ? period.venue : period.common

    return (
      <div className="PriceList service-detail-form__item">
        <div className="service-detail-form__item-header">
          <span className="fa-stack info-popup-window" data-title={registrationConfig.popupText.priceList}>
            <i className="fa fa-circle-thin fa-stack-2x"/>
            <i className="fa fa-info fa-stack-1x"/>
          </span>
          <h4 className="service-detail-form__title d-flex align-items-center">
            <sapn>Price list</sapn>
            <span className="band-contact-info__optional">(optional)</span>
          </h4>
        </div>
        <div className="service-detail-form__price-list">
          {value && value.map((item, index) => <div key={index} className="price-list__item">
            <div>
              <div className="service-detail-form__price-wrapper price-list">
                <div className='d-flex first-line'>
                  <div className="number-input price-name">
                    <TextField
                      value={item.name}
                      inputStyle={InputStyles}
                      hintStyle={PlaceholderStyles}
                      onChange={(event, val) => this.onChangePriceListName(val, index)}
                      hintText="Describe Service"
                      fullWidth={true}/>
                  </div>
                  <div className="d-block flex-shrink-1 price-container">
										<div className="d-flex justify-content-between">
                      <div className="number-input price-count">
                        <TextField
                          inputStyle={InputStyles}
                          hintStyle={PlaceholderStyles}
                          value={item.price}
                          onChange={(event, val) => this.onChangePriceListValue(val, index)}
                          hintText="Fee"
                          fullWidth={true}/>
                      </div>
                      <div className={`price-currency at-price-currency-${index}`}>
                        <SelectField
                          hintStyle={PlaceholderStyles}
                          labelStyle={InputStyles}
                          fullWidth={true}
                          value={item.currency}
                          onChange={(event, indexLocal, value) => this.onChangePriceListCurrency(value, index)}>
                          {currency.map((cur, index) => <MenuItem
                            key={'curr' + index} value={cur.value}
                            innerDivStyle={{
                              display: 'flex',
                              flexDirection: 'row',
                              justifyContent: 'center',
                              padding: '0'
                            }}
                            primaryText={cur.label}/>)}
                        </SelectField>
                      </div>
                      <div className={`price-period at-price-period-${index}`}>
                        <SelectField
                          hintStyle={PlaceholderStyles}
                          labelStyle={InputStyles}
                          fullWidth={true}
                          value={item.showOther ? 'other' : item.measurement}
                          onChange={(event, indexLocal, value) => this.onChangePriceListPeriodSelect(value, index)}>
                          {newPeriod.map((per, index) => <MenuItem
                            key={'period' + index} value={per.value}
                            primaryText={per.label}/>)}
                        </SelectField>
                      </div>
                      <div>
												<i className="fa fa-minus" onClick={() => this.removePriceItem(index)} />
											</div>
                    </div>
                    { item.showOther
                    ?
                    <div className="price__price-description">
                      <TextField
                        inputStyle={InputStyles}
                        hintStyle={PlaceholderStyles}
                        style={{ lineHeight: '31px' }}
                        hintText='Please describe your "other" selection here.'
                        value={item.measurement === 'other' ? null : item.measurement}
                        onChange={(event, val) => this.onChangePriceListPeriodOther(val, index)}
                        fullWidth={true}
                        multiLine={true}
                      />
                    </div>
                    :
                    ''}
                  </div>
                </div>
              </div>
            </div>
          </div>)}
          <div className="price__control-wrappper">
            <button className="price__more-button" onClick={this.addPriceItem}>
              <i className="fa fa-plus"/> <span>Add more</span>
            </button>
          </div>
        </div>
      </div>
    );
  }
}
