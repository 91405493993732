import React from 'react'
import { connect } from 'react-redux'
import { SortableContainer, SortableElement } from 'react-sortable-hoc'
import './SortableList.scss'

const SortableList = ({ items }) => (
    <div className="SortableList">
    	{
            items.map((listElement, index) => (                    
                <SortableListItem
                    index={index}
                    {...listElement.wrapperProps}>
                    {listElement.children}
                </SortableListItem>
            ))
        }
    </div>
)


export default SortableContainer(SortableList);

const SortableListItem = SortableElement(({ children, style }) => (
		<div style={{ display: 'flex', maxWidth: '100%', ...style }}>
            {children}
        </div>
	)
)
