import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import { getBulkProfiles, removeFromBulkProfiles, getSuggestedProfiles } from '../../../redux/modules/profile';
import { getRandomAdsBy, clearAds } from '../../../redux/modules/discover'
import { SuggestedProfile, SelectedProfile } from '../../index';

import './CompareModal.scss';
import Loader from '../../../components/discover/Loader/Loader';
import SearchBlockAdvertising from "../../discover/SearchBlockAdvertising/SearchBlockAdvertising";

const compareYellowIcon = require('../../../assets/icons/compare_yellow.png');

@connect(({ profile, discover }) => ({
  profiles: profile.bulkProfiles,
  isFetchingAds: discover.isFetchingAds,
  randomAds: discover.randomAds,
}), {
  getBulkProfiles,
  removeFromBulkProfiles,
  getSuggestedProfiles,
  getRandomAdsBy,
  clearAds,
})
export default class CompareModal extends Component {
  static propTypes = {
    itemsToCompare: PropTypes.array,
    className: PropTypes.string,
    open: PropTypes.bool,
    onModalClose: PropTypes.func,
    onRemoveItem: PropTypes.func,
  };

  constructor(props) {
    super(props)

    this.state = {
      showFullAboutSection: false,
      suggestedProfiles: [],
      profilesType: ''
    }
  }

  componentDidMount() {
    if (this.props.randomAds.length === 0 && this.props.rootCategoryName) {
      this.props.getRandomAdsBy(this.props.rootCategoryName)
    }
  }

  componentWillUnmount() {
    this.props.clearAds();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    let { getBulkProfiles, itemsToCompare, getSuggestedProfiles, profiles, onModalClose } = this.props;

    nextProps.profiles.length === 0 && profiles.length > 0 && onModalClose();

    if (itemsToCompare && itemsToCompare.length > 0 && nextProps.itemsToCompare.some(x => profiles.map(o => o.id).indexOf(x.id) === -1 )) {
      // todo jak sie bedzie czesto renderwac to za kazdym razem sie bd pytac
      // todo jak bedziemy dostawac is_favorite z backendu (narazie musimy je sobie brac z itemsToCompare) to mozna wysylac same id do getBulkProfiles
      getBulkProfiles(itemsToCompare);

      const profilesType = this.getProfilesType();
      profilesType && this.setState({ profilesType });
      this.state.suggestedProfiles.length === 0 &&
        profilesType &&
        getSuggestedProfiles(profilesType.toUpperCase()).then(users => this.setState({ suggestedProfiles: users }));
    }
  }

  getProfilesType = () => {
    const { itemsToCompare } = this.props;

    switch (itemsToCompare.length && itemsToCompare[0].profile_type) {
      case 'venue':
        return 'Venues'
      case 'artist':
      case 'group':
        return 'Artists'
    }
    return false
  }

  handleRemove = ({ id }) => {
    const { onRemoveItem, removeFromBulkProfiles, itemsToCompare, profiles, onModalClose } = this.props;
    onRemoveItem(itemsToCompare.find((item) => item.id === id ))
    removeFromBulkProfiles(id).then(_ => profiles.length === 0 && onModalClose())

  }

  hasUnloadedItems = () => {
    const { itemsToCompare, profiles } = this.props;
    const profilesId = profiles.map(o => o.id);
    return itemsToCompare.some(x => profilesId.indexOf(x.id) === -1 );
  }

  render() {
    const { profiles, onModalClose, open, className, itemsToCompare, isFetchingAds, randomAds } = this.props;
    const { showFullAboutSection, suggestedProfiles, profilesType } = this.state;

    return (
      itemsToCompare ? <Modal
        isOpen={open}
        className={[className, 'CompareModal', (itemsToCompare.length > 2 ? 'three' : 'two'), 'suggested-off'].join(' ')}
        toggle={onModalClose}
        onClosed={onModalClose}>
        <ModalHeader toggle={onModalClose}>
          <div className="title">
            <img src={compareYellowIcon}/>
            <h1>Your Selected {profilesType}</h1>
          </div>
        </ModalHeader>
        <ModalBody>
          <div className='compare-content'>
            <div className='compare-content-wrapper d-flex flex-row'>
              <div className='selected-profiles-block'>
                {
                  profiles.map((profile, index) => {
                    return <SelectedProfile
                        profile={{
                          ...profile
                        }}
                        key={index}
                        showFullAboutSection={showFullAboutSection}
                        onRemoveItem={this.handleRemove}
                        onToggleMore={() => {this.setState({ showFullAboutSection: !showFullAboutSection })}}
                      />
                    }
                  )
                }

                {
                  this.hasUnloadedItems() && <div className="SelectedProfile" style={{ display: 'flex', alignItems: 'center' }}><Loader /></div>
                }
                <div className="suggestedProfilesList">
                  <SearchBlockAdvertising
                    isCompareModal={true}
                    isFetchingAds={isFetchingAds}
                    adsByCategory={randomAds}
                  />
                  {
                    suggestedProfiles.map(item =>
                      <SuggestedProfile
                        profile={item}
                        onRemove={this.handleRemove}
                      />)
                  }
                </div>
              </div>
            </div>
          </div>
        </ModalBody>
      </Modal> : null
    );
  }
}
