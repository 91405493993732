// 'use strict'
import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';

import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom'
import { Provider } from 'react-redux'
import ReactGA from 'react-ga';
import * as serviceWorker from './serviceWorker';
import { Cookies } from 'react-cookie-consent'

import '../node_modules/bootstrap/dist/css/bootstrap.css'
import './styles/main.scss'
// --- Material UI ---
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider'
import getMuiTheme from 'material-ui/styles/getMuiTheme'
//import injectTapEventPlugin from 'react-tap-event-plugin'
// --- end ---
import WrapperComponent from 'containers/WrapperComponent';

const HomePage = React.lazy(() => import('containers/HomePage/HomePage'));
const Login = React.lazy(() => import('containers/Login'));
const About = React.lazy(() => import('containers/About'));
const Code = React.lazy(() => import('containers/Code'));
const Contact = React.lazy(() => import('containers/Contact'));
const Investors = React.lazy(() => import('containers/Investors'));
const Profile = React.lazy(() => import('containers/Profile'));
const SocialLoader = React.lazy(() => import('containers/SocialLoader'));
const Register = React.lazy(() => import('containers/RegisterPage/Register'));
const RegisterWizard = React.lazy(() => import('containers/RegisterWizard/RegisterWizard'));
const RegisterEmail = React.lazy(() => import('containers/RegisterEmail/RegisterEmail'));
const EmailVerify = React.lazy(() => import('containers/EmailVerify/EmailVerify'));
const ResetPassword = React.lazy(() => import('containers/ResetPassword/ResetPassword'));
const ConfirmCancelAccount = React.lazy(() => import('containers/ConfirmCancelAccount/ConfirmCancelAccount'));
const Gig = React.lazy(() => import('containers/Gig'));
const CreateGig = React.lazy(() => import('containers/CreateGig'));
const Discover = React.lazy(() => import('containers/DiscoverPage/Discover'));
const Mission = React.lazy(() => import('containers/Mission'));
const Legal = React.lazy(() => import('containers/Legal'));
const Impressum = React.lazy(() => import('containers/Impressum'));
const Questions = React.lazy(() => import('containers/Questions'));
const Team = React.lazy(() => import('containers/Team'));
const Search = React.lazy(() => import('containers/SearchPage/Search'));
const AuthRoute = React.lazy(() => import('containers/AuthRoute'));
const DashBoard = React.lazy(() => import('containers/DashBoard/DashBoard'));
const Memberships = React.lazy(() => import('containers/Memberships'));
const VendorCancellationPolicy = React.lazy(() => import('containers/VendorCancellationPolicy'));
const Press = React.lazy(() => import('containers/Press'));
const MainProfile = React.lazy(() => import('containers/MainProfile'));
const ProfileSettings = React.lazy(() => import('containers/ProfileSettings/ProfileSettings'));
const DisputePreview = React.lazy(() => import('containers/DisputePreview/DisputePreview'));

// import HomePage from 'containers/HomePage/HomePage';
// import Login from 'containers/Login';
// import About from 'containers/About';
// import Code from 'containers/Code';
// import Contact from 'containers/Contact';
// import Investors from 'containers/Investors';
// import Profile from 'containers/Profile';
// import SocialLoader from 'containers/SocialLoader';
// import Register from 'containers/RegisterPage/Register';
// import RegisterWizard from 'containers/RegisterWizard/RegisterWizard';
// import RegisterEmail from 'containers/RegisterEmail/RegisterEmail';
// import EmailVerify from 'containers/EmailVerify/EmailVerify';
// import Gig from 'containers/Gig';
// import CreateGig from 'containers/CreateGig';
// import Discover from 'containers/DiscoverPage/Discover';
// import Mission from 'containers/Mission';
// import Legal from 'containers/Legal';
// import Impressum from 'containers/Impressum';
// import Questions from 'containers/Questions';
// import Team from 'containers/Team';
// import Search from 'containers/SearchPage/Search';
// import AuthRoute from 'containers/AuthRoute';
// import DashBoard from 'containers/DashBoard/DashBoard';
// import Memberships from 'containers/Memberships';
// import Press from 'containers/Press';
// import MainProfile from 'containers/MainProfile';
// import DisputePreview from 'containers/DisputePreview/DisputePreview';

import { NavBar, EditProfile, ContractPreview } from './components'
import store from './redux/create'
import onInit from './helpers/onInit'
import notification from 'antd/es/notification'
import RealContractPreview from './components/DashBoard/Contracts/RealContractPreview/RealContractPreview';
import PusherStaff from './components/PusherStaff/PusherStaff';
import InboxStuff from './components/InboxStuff/InboxStuff';

import './styles/containers/App.scss'

import moment from 'moment'
import MiddlewareErrorHandler from './components/MiddlewareErrorHandler/MiddlewareErrorHandler';
// --- Initialize React Google Analytics ---

if (Cookies.get('gigworks-cookie-accept') == 'true') {
  ReactGA.initialize('UA-152569493-1');
  ReactGA.pageview('/homepage');
}

// --- Material UI ---
//injectTapEventPlugin()
// --- end ---

const muiTheme = getMuiTheme({
	fontFamily: 'Montserrat, sans-serif',
	palette: {
		primary1Color: '#ffd210',
		primary2Color: '#ffd210',
		primary3Color: '#ffd210',
		textColor: '#a3a3a3',
		alternateTextColor: '#fff',
		canvasColor: '#fff',
		borderColor: '#c4c4c4',
	},
})

notification.config({
	style: {
		zIndex: 1000000000
	}
  });


moment.updateLocale('en', {
	relativeTime: {
	  future: 'in %s',
	  past: '%s',
	  s:  'now',
	  ss: '%ss',
	  m:  '1m',
	  mm: '%dm',
	  h:  '1h',
	  hh: '%dh',
	  d:  '1d',
	  dd: '%dd',
	  M:  'a month',
	  MM: '%dM',
	  y:  'a year',
	  yy: '%dY'
	}
});

ReactDOM.render(<Router>
		<Provider store={store}>

			<MuiThemeProvider fontFamily="Montserrat, serif" muiTheme={muiTheme}>
				<div>
					<PusherStaff />

					<InboxStuff />

                    <MiddlewareErrorHandler />

					<WrapperComponent>
						<div className="wrapper">
							<Switch>
								<Route exact path="/login" />
								<Route exact path="/register" />
								<Route exact path="/register-wizard" />
								<Route exact path="/register-email" />
								<Route exact path="/login/social" />
								<Route exact path="/gig/*" />
								<Route exact path="/verify-email" />
                <Route exact path="/password/reset/:token" />
								<Route exact path="*">
									<NavBar />
								</Route>
							</Switch>
							<Suspense fallback={<div></div>}>
								<Route exact path="/" render={() => <HomePage />} />
								<AuthRoute exact path="/login" component={Login} unauthorized />
								<AuthRoute exact path="/register" component={Register} unauthorized />
								<Route exact path="/register-wizard" component={RegisterWizard} />
								<Route exact path="/register-email" component={RegisterEmail} />
								<AuthRoute exact path="/login/social" component={SocialLoader} unauthorized />
								<Route exact path="/verify-email" component={EmailVerify} />
                <Route exact path="/password/reset/:token" component={ResetPassword} unauthorized />
								<Route exact path="/confirm-cancellation" component={ConfirmCancelAccount} />
								<Route exact path="/about" component={About} />
								<Route exact path="/contact" component={Contact} />
								<Route exact path="/investors" component={Investors} />
								<Route exact path="/code" component={Code} />
								<Route exact path="/memberships" component={Memberships} />
								<Route exact path="/VendorCancellationPolicy" component={VendorCancellationPolicy} />
								<Route exact path="/press" component={Press} />
								<Switch>
									<Route exact path="/discover" component={Discover} />
									<Route exact path="/discover/GigFeed/:superCategoryId/:categoryId" component={Discover} />
									<Route exact path="/discover/GigFeed/:superCategoryId" component={Discover} />
									<Route exact path="/discover/:tab/:superCatId" component={Discover} />
									<Route exact path="/discover/:tab/:superCatId/:categoryName" component={Discover} />
								</Switch>
								<Route exact path="/discover/:tab" component={Discover} />
								<Route exact path="/mission" component={Mission} />
								<Route exact path="/legal" component={Legal} />
								<Route exact path="/impressum" component={Impressum} />
								<Route exact path="/questions" component={Questions} />
								<Route exact path="/team" component={Team} />
								<Route path="/search/:paramA?/:paramB?/:paramC?/:paramD?" component={Search} />
								<Switch>
									<AuthRoute path="/dashboard/inbox/:id" component={DashBoard} authorized />
									<AuthRoute path="/dashboard/inbox/" component={DashBoard} authorized />
									<AuthRoute path="/dashboard/calendar/" component={DashBoard} authorized />
									<AuthRoute path="/dashboard/admin/" component={DashBoard} authorized />
									<AuthRoute path="/dashboard/:tab" component={DashBoard} authorized />
									<AuthRoute path="/dashboard/mygigs/:category/:id/" component={DashBoard} authorized />
									<AuthRoute path="/dashboard/mygigs/:category/" component={DashBoard} authorized />
									<AuthRoute path="/dashboard/" component={DashBoard} authorized />
								</Switch>
								<Switch>
									<AuthRoute exact path="/profile" component={MainProfile} authorized />
									<AuthRoute exact path="/profile/edit/:currentForm" component={EditProfile} authorized />
									<AuthRoute exact path="/profile/edit/" component={EditProfile} authorized />
									<AuthRoute exact path="/profile/settings/" component={ProfileSettings} authorized />
									<Route exact path="/profile/:id" component={Profile} />
								</Switch>
								<Route exact path="/gig/:id" component={Gig} /> {/* to jest nieuzywane */}
								<AuthRoute path="/gig/new/:step" component={CreateGig} authorized />
								<AuthRoute exact path="/contract/:hash" component={ContractPreview} authorized />
								<AuthRoute exact path="/contract-preview/:hash" component={RealContractPreview} authorized />
								<AuthRoute exact path="/dispute/:id" component={DisputePreview} authorized />
							</Suspense>
						</div>
					</WrapperComponent>
				</div>
			</MuiThemeProvider>
		</Provider>
	</Router>,
	document.getElementById('root')
)

//onInit()


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.register();
serviceWorker.registerServiceWorker();
