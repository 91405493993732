import { MAX_ABOUT_INTRODUCTION_LENGTH, MAX_BRIEF_INTRODUCTION_LENGTH } from '../../../utils/constants';

const validate = values => {
  const errors = {};

  if (!values.brief_introduction) {
    errors.brief_introduction = 'Required';
  }
  if (values.brief_introduction && values.brief_introduction.length > MAX_BRIEF_INTRODUCTION_LENGTH) {
    errors.brief_introduction = `The Brief Introduction may not be greater than ${MAX_BRIEF_INTRODUCTION_LENGTH} characters`;
  }
  if (values.about && values.about.length > MAX_ABOUT_INTRODUCTION_LENGTH) {
    errors.about = `The About may not be greater than ${MAX_ABOUT_INTRODUCTION_LENGTH} characters`;
  }

  return errors;
};

export default validate;
