import { personTypes, entityTypes } from '../../utils/constants';

const isSupportedType = (files) => {
    const supportedTypes = ['image/png', 'image/jpeg', 'image/gif', 'application/pdf', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document', 'application/vnd.oasis.opendocument.text']

    return files
            .filter(file => file instanceof File)
            .every(file => supportedTypes.includes(file.type))
}

const validate = values => {
	const errors = {}

    if(values.kyc_person_type === personTypes.Individual) {

        if( !values.kyc_first_name || !values.kyc_last_name || !values.kyc_nationality || !values.kyc_birth_date || !values.kyc_over18 ) {
            errors.kyc_individual_data = 'Required'
        }
        if( !values.kyc_email || !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.kyc_email) ) {
            errors.kyc_email = 'Required'
        }
        if( Object.keys(values.kyc_location).length === 0 || Object.keys(values.kyc_location).some(prop => prop == 'country_code' ? false : !values.kyc_location[prop]) ) {
            errors.kyc_location = 'Required'
        }
        if (!values.kyc_ind_identity_proof || !values.kyc_ind_identity_proof.length || !isSupportedType(values.kyc_ind_identity_proof) ) {
            errors.kyc_ind_identity_proof = 'Required'
        }

    }
    else if(values.kyc_person_type === personTypes.Entity) {

        if( values.kyc_entity_type ) {
//!values.kyc_business_name ||
            if( !values.kyc_representative_first_name || !values.kyc_representative_last_name || !values.kyc_representative_nationality || !values.kyc_representative_birth_date || !values.kyc_representative_over18 ) {
                errors.kyc_entity_data = 'Required'
            }

            if( !values.kyc_business_name ) {
              errors.kyc_business_name = 'Required'
            }

            if( !values.kyc_business_email || !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.kyc_business_email) ) {
                errors.kyc_business_email = 'Required'
            }
            if( !values.kyc_representative_email || !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.kyc_representative_email) ) {
                errors.kyc_representative_email = 'Required'
            }

          delete values.kyc_business_location.country_code
          delete values.kyc_business_location.formatted_address
          delete values.kyc_business_location.place_id

          delete values.kyc_representative_location.country_code
          delete values.kyc_representative_location.formatted_address
          delete values.kyc_representative_location.place_id

            if( values.kyc_business_location && (Object.keys(values.kyc_business_location).length === 0 || Object.keys(values.kyc_business_location).some(prop => !values.kyc_business_location[prop])) ) {
                errors.kyc_business_location = 'Required'
            }
            if( values.kyc_representative_location && (Object.keys(values.kyc_representative_location).length === 0 || Object.keys(values.kyc_representative_location).some(prop => !values.kyc_representative_location[prop])) ) {
                errors.kyc_representative_location = 'Required'
            }

            if (!values.kyc_ent_identity_proof || !values.kyc_ent_identity_proof.length || !isSupportedType(values.kyc_ent_identity_proof) ) {
                errors.kyc_ent_identity_proof = 'Required'
            }
            if (!values.kyc_registration_proof || !values.kyc_registration_proof.length || !isSupportedType(values.kyc_registration_proof) ) {
                errors.kyc_registration_proof = 'Required'
            }

            if ( values.kyc_entity_type !== entityTypes.Soletrader && ( !values.kyc_articles_association || !values.kyc_articles_association.length || !isSupportedType(values.kyc_articles_association) )) {
                errors.kyc_articles_association = 'Required'
            }

            if( values.kyc_entity_type === entityTypes.Business ) {
                if( !values.kyc_company_number ) {
                    errors.kyc_company_number = 'Required'
                }
                if (!values.kyc_ubo_declaration || !values.kyc_ubo_declaration.length || !isSupportedType(values.kyc_ubo_declaration) ) {
                    errors.kyc_ubo_declaration = 'Required'
                }
            }
        }
        else {
            errors.kyc_entity_type = 'Required'
        }
    }
    else {
        errors.kyc_person_type = 'Required'
    }

    return errors
}

export default validate
