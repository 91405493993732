import React from 'react'
import GigworksPopover from '../GigworksPopover';
import classNames from 'classnames';

const PopoverWithList = ({ target, items, onSelect }) => {
    return <GigworksPopover className="PopoverWithList PopoverWithList" target={target}>
        {({ togglePopover }) => items.map(({ value, label, description, active }) => <div
            className={classNames("PopoverWithList__Element d-flex justify-content-center flex-column px-4 py-3", {
                "active": active,
                "cursor-pointer": !!onSelect,
                "cursor-default": !onSelect,
            })}
            onClick={() => {
                if (onSelect) {
                    onSelect(value);
                    togglePopover();
            }}}>
                <span className="PopoverWithList__MediumText font-weight-bolder">
                    {label}
                </span>

                {description && <span className="PopoverWithList__SmallText mt-1">
                    {description}
                </span>}
            </div>)
        }
    </GigworksPopover>
}

export default PopoverWithList