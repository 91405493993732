import { PAYMENT_CYCLES } from "../../utils/constants"

export const ACTIVE_GIGS_FETCH_START = 'ACTIVE_GIGS_FETCH_START'
export const ACTIVE_GIGS_FETCH_SUCCESS = 'ACTIVE_GIGS_FETCH_SUCCESS'
export const ACTIVE_GIGS_FETCH_FAIL = 'ACTIVE_GIGS_FETCH_FAIL'

export const CONFIRMED_GIGS_FETCH_START = 'CONFIRMED_GIGS_FETCH_START'
export const CONFIRMED_GIGS_FETCH_SUCCESS = 'CONFIRMED_GIGS_FETCH_SUCCESS'
export const CONFIRMED_GIGS_FETCH_FAIL = 'CONFIRMED_GIGS_FETCH_FAIL'

export const PAST_GIGS_FETCH_START = 'PAST_GIGS_FETCH_START'
export const PAST_GIGS_FETCH_SUCCESS = 'PAST_GIGS_FETCH_SUCCESS'
export const PAST_GIGS_FETCH_FAIL = 'PAST_GIGS_FETCH_FAIL'

export const GET_GIG_FETCH_START = 'GET_GIG_FETCH_START'
export const GET_GIG_FETCH_SUCCESS = 'GET_GIG_FETCH_SUCCESS'
export const GET_GIG_FETCH_FAIL = 'GET_GIG_FETCH_FAIL'

export const UPDATE_GIG_START = 'UPDATE_GIG_START'
export const UPDATE_GIG_SUCCESS = 'UPDATE_GIG_SUCCESS'
export const UPDATE_GIG_FAIL = 'UPDATE_GIG_FAIL'

export const CANCEL_GIG_START = 'CANCEL_GIG_START'
export const CANCEL_GIG_SUCCESS = 'CANCEL_GIG_SUCCESS'
export const CANCEL_GIG_FAIL = 'CANCEL_GIG_FAIL'

export const APPLIED_GIGS_FETCH_START = 'APPLIED_GIGS_FETCH_START'
export const APPLIED_GIGS_FETCH_SUCCESS = 'APPLIED_GIGS_FETCH_SUCCESS'
export const APPLIED_GIGS_FETCH_FAIL = 'APPLIED_GIGS_FETCH_FAIL'

export const SAVED_GIGS_FETCH_START = 'SAVED_GIGS_FETCH_START'
export const SAVED_GIGS_FETCH_SUCCESS = 'SAVED_GIGS_FETCH_SUCCESS'
export const SAVED_GIGS_FETCH_FAIL = 'SAVED_GIGS_FETCH_FAIL'

//client side
export const ALL_CANDIDATES_FETCH_START = 'ALL_CANDIDATES_FETCH_START'
export const ALL_CANDIDATES_FETCH_SUCCESS = 'ALL_CANDIDATES_FETCH_SUCCESS'
export const ALL_CANDIDATES_FETCH_FAIL = 'ALL_CANDIDATES_FETCH_FAIL'

export const APPLIED_CANDIDATES_FETCH_START = 'APPLIED_CANDIDATES_FETCH_START'
export const APPLIED_CANDIDATES_FETCH_SUCCESS = 'APPLIED_CANDIDATES_FETCH_SUCCESS'
export const APPLIED_CANDIDATES_FETCH_FAIL = 'APPLIED_CANDIDATES_FETCH_FAIL'

export const FAVORITE_CANDIDATES_FETCH_START = 'FAVORITE_CANDIDATES_FETCH_START'
export const FAVORITE_CANDIDATES_FETCH_SUCCESS = 'FAVORITE_CANDIDATES_FETCH_SUCCESS'
export const FAVORITE_CANDIDATES_FETCH_FAIL = 'FAVORITE_CANDIDATES_FETCH_FAIL'

export const RECOMMENDED_CANDIDATES_FETCH_START = 'RECOMMENDED_CANDIDATES_FETCH_START'
export const RECOMMENDED_CANDIDATES_FETCH_SUCCESS = 'RECOMMENDED_CANDIDATES_FETCH_SUCCESS'
export const RECOMMENDED_CANDIDATES_FETCH_FAIL = 'RECOMMENDED_CANDIDATES_FETCH_FAIL'

export const SHORTLIST_CANDIDATES_FETCH_START = 'SHORTLIST_CANDIDATES_FETCH_START'
export const SHORTLIST_CANDIDATES_FETCH_SUCCESS = 'SHORTLIST_CANDIDATES_FETCH_SUCCESS'
export const SHORTLIST_CANDIDATES_FETCH_FAIL = 'SHORTLIST_CANDIDATES_FETCH_FAIL'

export const GET_PLANS_LIST_START = 'GET_PLANS_LIST_START'
export const GET_PLANS_LIST_SUCCESS = 'GET_PLANS_LIST_SUCCESS'
export const GET_PLANS_LIST_FAIL = 'GET_PLANS_LIST_FAIL'

export const GET_COUNTRIES_TREE_START = 'GET_COUNTRIES_TREE_START'
export const GET_COUNTRIES_TREE_SUCCESS = 'GET_COUNTRIES_TREE_SUCCESS'
export const GET_COUNTRIES_TREE_FAIL = 'GET_COUNTRIES_TREE_FAIL'

export const CHANGE_PLAN_START = 'CHANGE_PLAN_START'
export const CHANGE_PLAN_SUCCESS = 'CHANGE_PLAN_SUCCESS'
export const CHANGE_PLAN_FAIL = 'CHANGE_PLAN_FAIL'

export const FETCHING_GIGS_LIST_START = 'FETCHING_GIGS_LIST_START'
export const FETCHING_GIGS_LIST_SUCCESS = 'FETCHING_GIGS_LIST_SUCCESS'
export const FETCHING_GIGS_LIST_FAIL = 'FETCHING_GIGS_LIST_FAIL'

export const FETCHING_BOOKINGS_LIST_START = 'FETCHING_BOOKINGS_LIST_START';
export const FETCHING_BOOKINGS_LIST_SUCCESS = 'FETCHING_BOOKINGS_LIST_SUCCESS';
export const FETCHING_BOOKINGS_LIST_FAIL = 'FETCHING_BOOKINGS_LIST_FAIL';

export const FETCHING_INVITED_GIGS_LIST_START = 'FETCHING_INVITED_GIGS_LIST_START';
export const FETCHING_INVITED_GIGS_LIST_SUCCESS = 'FETCHING_INVITED_GIGS_LIST_SUCCESS';
export const FETCHING_INVITED_GIGS_LIST_FAIL = 'FETCHING_INVITED_GIGS_LIST_START';

export const FETCHING_MEMBERSHIP_PLAN_PAYMENTS_START = 'FETCHING_MEMBERSHIP_PLAN_PAYMENTS_START';
export const FETCHING_MEMBERSHIP_PLAN_PAYMENTS_SUCCESS = 'FETCHING_MEMBERSHIP_PLAN_PAYMENTS_SUCCESS';
export const FETCHING_MEMBERSHIP_PLAN_PAYMENTS_FAIL = 'FETCHING_MEMBERSHIP_PLAN_PAYMENTS_START';

export const PAY_FOR_PLAN_START = 'PAY_FOR_PLAN_START'
export const PAY_FOR_PLAN_SUCCESS = 'PAY_FOR_PLAN_SUCCESS'
export const PAY_FOR_PLAN_FAIL = 'PAY_FOR_PLAN_FAIL'

export const EMPTY_GIGS = 'EMPTY_GIGS'

const initialState = {
	appliedGigs: [],
	invitedGigs: [],
	savedGigs: [],
	activeGigs: [],
	confirmedGigs: [],
	pastGigs: [],
	gig: {},

	allCandidates: [],
	appliedCandidates: [],
	favoriteCandidates: [],
	recommendedCandidates: [],
	shortlistCandidates: [],

	bookingsAwaitingConfirmation: [],

	isFetchingGig: false,

	isFetchingActiveGigs: false,
	isFetchingAppliedGigs: false,
	isFetchingSavedGigs: false,
	isFetchingInvitedGigs: false,
	isFetchingPastGigs: false,

	isFetchingBookings: false,

	isFetchingAllCandidates: false,
	isFetchingAppliedCandidates: false,
	isFetchingFavoriteCandidates: false,
	isFetchingRecommendedCandidates: false,
	isFetchingShortlistCandidates: false,
	isFetchingConfirmedGigs: false,

	isChangingPlan: false,

	isGetingCountriesTree: false,
	countriesTree: [],
  nestedCountriesTree: [],
  countriesList: [],
  phoneCodeList: [],

	isGettingPlans: false,
	plans: {},

	isPlanChanging: false,
	plansChangingError: '',

	isPayingForPlan: false,

	nextGigs: [],
	isFetchingNextGigs: false,

  membershipPlanPayments: [],
  isFetchingMembershipPlanPayments: false,
}

export default function dashboard(state = initialState, action = {}) {
	switch (action.type) {
		case ACTIVE_GIGS_FETCH_START:
			return {
				...state,
				isFetchingActiveGigs: true,
			}
		case ACTIVE_GIGS_FETCH_SUCCESS:
			return {
				...state,
				isFetchingActiveGigs: false,
				activeGigs: action.result.data.map(x => {
					if (Array.isArray(x)) {
						console.error('Usuwam giga który był tablica', x)
						x = {
							now: false,
							id: 144141414141141414,
							name: 'Byłem tablica',
							list: [{ label: 'tAblcia 1', value: '10%',}, {label: 'dsafasd', value: '100%'}],
							contract: {}
						}
					}

					// fix someteimes gig.now was false
					x.now = x.now === false ? x.now = { value: '10%', name: 'Start'} : x.now
					// x.now = { value: '65%'}
					// x.current_status = undefined
					return x
				}),
			}
		case ACTIVE_GIGS_FETCH_FAIL:
			return {
				...state,
				isFetchingActiveGigs: false,
			}

		case CONFIRMED_GIGS_FETCH_START:
			return {
				...state,
				isFetchingConfirmedGigs: true,
			}
		case CONFIRMED_GIGS_FETCH_SUCCESS:
			return {
				...state,
				isFetchingConfirmedGigs: false,
				confirmedGigs: action.result.data,
			}
		case CONFIRMED_GIGS_FETCH_FAIL:
			return {
				...state,
				isFetchingConfirmedGigs: false,
			}


		case PAST_GIGS_FETCH_START:
			return {
				...state,
				isFetchingPastGigs: true,
			}
		case PAST_GIGS_FETCH_SUCCESS:
			return {
				...state,
				isFetchingPastGigs: false,
				pastGigs: action.result.data.filter(gig => !Array.isArray(gig)),
			}
		case PAST_GIGS_FETCH_FAIL:
			return {
				...state,
				isFetchingPastGigs: false,
			}

		case GET_GIG_FETCH_START:
			return {
				...state,
				isFetchingGig: true,
			}
		case GET_GIG_FETCH_SUCCESS:
			return {
				...state,
				isFetchingGig: false,
				gig: action.result.data,
			}
		case GET_GIG_FETCH_FAIL:
			return {
				...state,
				isFetchingGig: false,
			}

		case UPDATE_GIG_START:
			return {
				...state,
				isUpdatingGig: true,
			}
		case UPDATE_GIG_SUCCESS:
			return {
				...state,
				isUpdatingGig: false,
				//gig: action.result.data,
			}
		case UPDATE_GIG_FAIL:
			return {
				...state,
				isUpdatingGig: false,
			}
		case CANCEL_GIG_START:
			return {
				...state,
				isCancelingGig: true,
		}
		case CANCEL_GIG_SUCCESS:
			return {
				...state,
				isCancelingGig: false,
		}
		case CANCEL_GIG_FAIL:
			return {
				...state,
				isCancelingGig: false,
		}
		case APPLIED_GIGS_FETCH_START:
			return {
				...state,
				isFetchingAppliedGigs: true,
			}
		case APPLIED_GIGS_FETCH_SUCCESS:
			return {
				...state,
				isFetchingAppliedGigs: false,
				appliedGigs: action.result.data,
			}
		case APPLIED_GIGS_FETCH_FAIL:
			return {
				...state,
				isFetchingAppliedGigs: false,
			}
		case FETCHING_INVITED_GIGS_LIST_START:
			return {
				...state,
				isFetchingInvitedGigs: true
			}
		case FETCHING_INVITED_GIGS_LIST_SUCCESS:
			return {
				...state,
				isFetchingInvitedGigs: false,
				invitedGigs: action.result.data
			}
		case FETCHING_INVITED_GIGS_LIST_FAIL:
			return {
				...state,
				isFetchingInvitedGigs: false
			}

		case SAVED_GIGS_FETCH_START:
			return {
				...state,
				isFetchingSavedGigs: true,
			}
		case SAVED_GIGS_FETCH_SUCCESS:
			return {
				...state,
				isFetchingSavedGigs: false,
				savedGigs: action.result.data,
			}
		case SAVED_GIGS_FETCH_FAIL:
			return {
				...state,
				isFetchingSavedGigs: false,
			}

		case ALL_CANDIDATES_FETCH_START:
			return {
				...state,
				isFetchingAllCandidates: true,
			}
		case ALL_CANDIDATES_FETCH_SUCCESS:
			return {
				...state,
				isFetchingAllCandidates: false,
				allCandidates: action.result.data,
			}
		case ALL_CANDIDATES_FETCH_FAIL:
			return {
				...state,
				isFetchingAllCandidates: false,
			}

		case APPLIED_CANDIDATES_FETCH_START:
			return {
				...state,
				isFetchingAppliedCandidates: true,
			}
		case APPLIED_CANDIDATES_FETCH_SUCCESS:
			return {
				...state,
				isFetchingAppliedCandidates: false,
				appliedCandidates: action.result.data,
			}
		case APPLIED_CANDIDATES_FETCH_FAIL:
			return {
				...state,
				isFetchingAppliedCandidates: false,
			}

		case FAVORITE_CANDIDATES_FETCH_START:
			return {
				...state,
				isFetchingFavoriteCandidates: true,
			}
		case FAVORITE_CANDIDATES_FETCH_SUCCESS:
			return {
				...state,
				isFetchingFavoriteCandidates: false,
				favoriteCandidates: action.result.data,
			}
		case FAVORITE_CANDIDATES_FETCH_FAIL:
			return {
				...state,
				isFetchingFavoriteCandidates: false,
			}

		case RECOMMENDED_CANDIDATES_FETCH_START:
			return {
				...state,
				isFetchingRecommendedCandidates: true,
			}
		case RECOMMENDED_CANDIDATES_FETCH_SUCCESS:
			return {
				...state,
				isFetchingRecommendedCandidates: false,
				recommendedCandidates: action.result.data,
			}
		case RECOMMENDED_CANDIDATES_FETCH_FAIL:
			return {
				...state,
				isFetchingRecommendedCandidates: false,
			}

		case SHORTLIST_CANDIDATES_FETCH_START:
			return {
				...state,
				isFetchingShortlistCandidates: true,
			}
		case SHORTLIST_CANDIDATES_FETCH_SUCCESS:
			return {
				...state,
				isFetchingShortlistCandidates: false,
				shortlistCandidates: action.result.data,
			}
		case SHORTLIST_CANDIDATES_FETCH_FAIL:
			return {
				...state,
				isFetchingShortlistCandidates: false,
			}

		case GET_PLANS_LIST_START:
			return {
				...state,
				isGettingPlans: true,
			}
		case GET_PLANS_LIST_SUCCESS:
			return {
				...state,
				isGettingPlans: false,
				plans: action.result.data,
			}
		case GET_PLANS_LIST_FAIL:
			return {
				...state,
				isGettingPlans: false,
			}

		case GET_COUNTRIES_TREE_START:
			return {
				...state,
				isGetingCountriesTree: true,
			}
		case GET_COUNTRIES_TREE_SUCCESS:
			return {
				...state,
				isGetingCountriesTree: false,
        nestedCountriesTree: getNestedChildren(action.result.data, null),
        countriesList: getCountriesList(action.result.data),
        phoneCodeList: getCountriesPhoneCodesList(action.result.data),
				countriesTree: action.result.data
			}
		case GET_COUNTRIES_TREE_FAIL:
			return {
				...state,
				isGetingCountriesTree: false,
			}

		case CHANGE_PLAN_START:
			return {
				isChangingPlan: true,
				...state,
			}
		case CHANGE_PLAN_SUCCESS:
			const newPrices = state.plans.meta.price.map(plan => {
				plan.current = plan.id === action.planId
				return plan
			})
			state.plans.meta.price = newPrices
			return {
				isChangingPlan: false,
				...state
			}
		case CHANGE_PLAN_FAIL:
			return {
				isChangingPlan: false,
				...state,
			}

		case PAY_FOR_PLAN_START:
			return {
				...state,
				isPayingForPlan: true,
			}

		case PAY_FOR_PLAN_SUCCESS:
		case PAY_FOR_PLAN_FAIL:
			return {
				...state,
				isPayingForPlan: false
			}

		case EMPTY_GIGS:
			return {
				...state,
				activeGigs: [],
				pastGigs: [],
			}

		case FETCHING_GIGS_LIST_START:
			return {
				...state,
				isFetchingNextGigs: true,
			}
		case FETCHING_GIGS_LIST_SUCCESS:
			return {
                ...state,
				nextGigs: action.result.data,
				isFetchingNextGigs: false,
			}
		case FETCHING_GIGS_LIST_FAIL:
			return {
				...state,
				isFetchingNextGigs: false,
			}

		case FETCHING_BOOKINGS_LIST_START:
			return {
				...state,
				isFetchingBookings: true
			}
		case FETCHING_BOOKINGS_LIST_SUCCESS:
			return {
				...state,
				bookingsAwaitingConfirmation: action.result.data,
				isFetchingBookings: false
			}
		case FETCHING_BOOKINGS_LIST_FAIL:
			return {
				...state,
				isFetchingBookings: false
			}

    case FETCHING_MEMBERSHIP_PLAN_PAYMENTS_START:
      return {
        ...state,
        isFetchingMembershipPlanPayments: true,
      }
    case FETCHING_MEMBERSHIP_PLAN_PAYMENTS_SUCCESS:
      return {
        ...state,
        membershipPlanPayments: action.result.data,
        isFetchingMembershipPlanPayments: false
      }
    case FETCHING_MEMBERSHIP_PLAN_PAYMENTS_FAIL:
      return {
        ...state,
        isFetchingMembershipPlanPayments: false
      }

		default:
			return {
				...state,
			}
	}
}

function getNestedChildren(arr, parent_id) {
	let out = []
	arr.forEach(item => {
		if(item.parent_id === parent_id) {
			let treeItem = {
				title: item.name,
				value: item.id,
				key: item.id,
				level: item.values
			}

			let children = getNestedChildren(arr, item.id)
			if(children.length) {
				treeItem.children = children
			}
			out.push(treeItem)
		}
	})
	return out
}

function getCountriesList(arr) {
    return arr
        .filter(country => country.parent_id !== null && arr.find(c => c.id === country.parent_id).parent_id !== null)
        .map(item => ({ value: item.id.toString(), label: item.name }))
        .sort((a, b) => a.label.toLowerCase() > b.label.toLowerCase() ? 1 : -1)
}

function getCountriesPhoneCodesList(arr) {
  return arr
    .filter(country => country.parent_id !== null && arr.find(c => c.id === country.parent_id).parent_id !== null)
    .map(item => ({ value: item.id.toString(), label: `+${item.phone_code.toString()}`, sublabel: item.name, }))
    .sort((a, b) => a.sublabel.toLowerCase() > b.sublabel.toLowerCase() ? 1 : -1)
}

export function getBookings() {
	return {
		types: [FETCHING_BOOKINGS_LIST_START, FETCHING_BOOKINGS_LIST_SUCCESS, FETCHING_BOOKINGS_LIST_FAIL],
		promise: client => client.get(`/api/users/gigs/bind/book`)
	}
}

export function getAppliedGigs() {
	return {
		types: [APPLIED_GIGS_FETCH_START, APPLIED_GIGS_FETCH_SUCCESS, APPLIED_GIGS_FETCH_FAIL],
		promise: client => client.get(`/api/users/gigs/bind/apply`),
	}
}

export function getSavedGigs() {
	return {
		types: [SAVED_GIGS_FETCH_START, SAVED_GIGS_FETCH_SUCCESS, SAVED_GIGS_FETCH_FAIL],
		promise: client => client.get(`/api/users/gigs/bind/save`),
	}
}

export function getConfirmedGigs() {
	return {
		types: [CONFIRMED_GIGS_FETCH_START, CONFIRMED_GIGS_FETCH_SUCCESS, CONFIRMED_GIGS_FETCH_FAIL],
		promise: client => client.get(`api/users/gigs/bind/book_confirm`),
	}
}

export function getInvitedGigs() {
	return {
		types: [FETCHING_INVITED_GIGS_LIST_START, FETCHING_INVITED_GIGS_LIST_SUCCESS, FETCHING_INVITED_GIGS_LIST_FAIL],
		promise: client => client.get(`/api/users/gigs/bind/invite`)
	}
}

export function getActiveGigs(userId) {
	return {
		types: [ACTIVE_GIGS_FETCH_START, ACTIVE_GIGS_FETCH_SUCCESS, ACTIVE_GIGS_FETCH_FAIL],
		promise: client => client.get(`/api/user/gigs/${userId}`),
	}
}

export function getPastGigs(userId) {
	return {
		types: [PAST_GIGS_FETCH_START, PAST_GIGS_FETCH_SUCCESS, PAST_GIGS_FETCH_FAIL],
		promise: client => client.get(`/api/user/gigs/${userId}?type=past`),
	}
}

export function getGig(gigId) {
	return {
		types: [GET_GIG_FETCH_START, GET_GIG_FETCH_SUCCESS, GET_GIG_FETCH_FAIL],
		promise: client => client.get(`/api/gigs/${gigId}`),
	}
}

export function updateGig(gigId, data) {
	return {
	  types: [UPDATE_GIG_START, UPDATE_GIG_SUCCESS, UPDATE_GIG_FAIL],
	  promise: (client) => client.put(`/api/gigs/${gigId}`, data),
	};
}

/**
 * @param {number} gigId
 *
 * @returns {{types: [*, *, *], promise: (function(*): *)}}
 */
export function cancelGig(gigId) {
	return {
		types: [CANCEL_GIG_START, CANCEL_GIG_SUCCESS, CANCEL_GIG_FAIL],
	  	promise: (client) => client.delete(`/api/gig/remove?gigId=${gigId}`),
	}
}

export function getAllCandidates(gigId) {
	return {
		types: [ALL_CANDIDATES_FETCH_START, ALL_CANDIDATES_FETCH_SUCCESS, ALL_CANDIDATES_FETCH_FAIL],
		promise: client => client.get(`/api/dashboard/gig/${gigId}/candidate?filters[default][]=all`),
	}
}

export function getAppliedCandidates(gigId) {
	return {
		types: [APPLIED_CANDIDATES_FETCH_START, APPLIED_CANDIDATES_FETCH_SUCCESS, APPLIED_CANDIDATES_FETCH_FAIL],
		promise: client => client.get(`/api/dashboard/gig/${gigId}/candidate?filters[default][]=applied`),
	}
}

export function getFavoriteCandidates(gigId) {
	return {
		types: [FAVORITE_CANDIDATES_FETCH_START, FAVORITE_CANDIDATES_FETCH_SUCCESS, FAVORITE_CANDIDATES_FETCH_FAIL],
		promise: client => client.get(`/api/dashboard/gig/${gigId}/candidate?filters[default][]=favorite`),
	}
}

export function getRecommendedCandidates(gigId) {
	return {
		types: [
			RECOMMENDED_CANDIDATES_FETCH_START,
			RECOMMENDED_CANDIDATES_FETCH_SUCCESS,
			RECOMMENDED_CANDIDATES_FETCH_FAIL,
		],
		promise: client => client.get(`/api/dashboard/gig/${gigId}/candidate?filters[default][]=recommended`),
	}
}

export function getShortlistCandidates(gigId) {
	return {
		types: [SHORTLIST_CANDIDATES_FETCH_START, SHORTLIST_CANDIDATES_FETCH_SUCCESS, SHORTLIST_CANDIDATES_FETCH_FAIL],
		promise: client => client.get(`/api/dashboard/gig/${gigId}/candidate?filters[default][]=shortlist`),
	}
}

export function getCountriesTree() {
	return {
		types: [GET_COUNTRIES_TREE_START, GET_COUNTRIES_TREE_SUCCESS, GET_COUNTRIES_TREE_FAIL],
		promise: client => client.get('api/autocomplete/countries'),
	}
}

export function getPlansList() {
	return {
		types: [GET_PLANS_LIST_START, GET_PLANS_LIST_SUCCESS, GET_PLANS_LIST_FAIL],
		promise: client => client.get('/api/plan'),
	}
}

export function changePlan({ planId, cycle = PAYMENT_CYCLES["1_MONTH"], coupon }) {
	return {
		types: [CHANGE_PLAN_START, CHANGE_PLAN_SUCCESS, CHANGE_PLAN_FAIL],
		promise: client => client.put(`/api/plan`, {
			plan_id: planId,
			coupon,
			cycle
		}),
		planId,
	}
}

export function upgradeFreePlan() {
  return {
    types: [PAY_FOR_PLAN_START, PAY_FOR_PLAN_SUCCESS, PAY_FOR_PLAN_FAIL],
    promise: client => client.put(`/api/plan/upgrade-plan`, {}),
  }
}

export function payForPlan({ planId, transferType, cardId, bankAccountId, cycle = PAYMENT_CYCLES["1_MONTH"], coupon }) {
	return {
		types: [PAY_FOR_PLAN_START, PAY_FOR_PLAN_SUCCESS, PAY_FOR_PLAN_FAIL],
		promise: client => client.put(`/api/plan/pay-plan`, {
			plan_id: planId,
			transfer_type: transferType,
			card_id: cardId,
			bank_account_id: bankAccountId,
			coupon,
			cycle
		}),
	}
}

export function getNextGigs(profileId) {
	return {
		types: [FETCHING_GIGS_LIST_START, FETCHING_GIGS_LIST_SUCCESS, FETCHING_GIGS_LIST_FAIL],
		promise: client => client.get(`/api/gigs/next/${profileId}/apply`),
	}
}

export function getCalendarGigs(userId) {
	return {
		type: ['GET_CALENDAR_TYPES_START'],
		promise: client => client.get(`/api/user/gigs-calendar/${userId}`)
	}
}

export function getMembershipPlanPayments() {
  return {
    types: [FETCHING_MEMBERSHIP_PLAN_PAYMENTS_START, FETCHING_MEMBERSHIP_PLAN_PAYMENTS_SUCCESS, FETCHING_MEMBERSHIP_PLAN_PAYMENTS_FAIL],
    promise: client => client.get(`/api/dashboard/membership-payments`)
  }
}
