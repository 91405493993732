const TEXT_FOR_PROFILE_TYPES = {
  registration: {
    topFiveItems: {
      artist: 'Styles',
      venue: 'Styles',
      client: 'Styles',
      fan: 'Styles',
      group: 'Styles',
      band: 'Genres',
      company: 'Styles'
    },
    plansTalentItems: {
      artist: 'style',
      venue: 'occasion',
      client: 'style',
      fan: 'style',
      group: 'talent',
      band: 'genre',
      company: 'style'
    },
    contactInformation: {
      artist: 'Instruments',
      venue: 'This will serve as the primary address to locate your venue when potential clients search for venues in your area. It will not appear on your profile. It may appear on any contracts you enter into through the Gigworks platform.',
      client: 'Optional. We just need 1 contact from the group.',
      fan: 'Optional. We just need 1 contact from the group.',
      group: 'This will serve as the primary address to locate your group when potential clients search for talent in your area. It will not appear on your profile. It may appear on any contracts you enter into through the Gigworks platform.',
      band: 'This will serve as the primary address to locate your group when potential clients search for talent in your area. It will not appear on your profile. It may appear on any contracts you enter into through the Gigworks platform.',
      company: 'This information will be shown on your company\'s profile.'
    },
    groupTalentAndStyles: {
      artist: 'You would primarily describe your talent as a ...',
      venue: 'Describe your other business type.',
      client: 'What\'s your group\'s primary talent?',
      fan: 'What\'s your group\'s primary talent?',
      group: 'What\'s your group\'s primary talent?',
      band: 'What\'s your group\'s primary genre?',
      company: 'You would primarily describe your business as a ...'
    },
  }
};

export const getTextForProfileType = (place, textName, typeProfile) =>
    TEXT_FOR_PROFILE_TYPES[place][textName][typeProfile];
