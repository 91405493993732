import React, { Component } from 'react'

import { ButtonDropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap'
import { DANGER_COLOR, fontSizes } from '../../../utils/constants'

class GWNavDropdown extends Component {

  constructor(props) {
    super(props);

    this.state = {
      isOpen: false
    }
  }

  toggle = () => {
    this.setState({ isOpen: !this.state.isOpen })
  }

  render() {
    return (
      <ButtonDropdown className='profile-edit__info_btnddl' isOpen={this.state.isOpen} toggle={this.toggle}>
        <DropdownToggle caret>
          { this.props.ddlTitle } { this.props.hasMissingFields&&
          <i className="fa fa-exclamation-circle"
             aria-hidden="true"
             style={{
               marginLeft: '16px',
               fontSize: fontSizes.medium,
               verticalAlign: 'middle',
               color: DANGER_COLOR,
             }}
          />
          }
        </DropdownToggle>
        <DropdownMenu>
          { this.props.ddlActions.map((entry) => {
            return <DropdownItem onClick={ () => this.props.itemClickCallBack(entry.id)}>{entry.text}{ entry.missing &&
            <i className="fa fa-exclamation-circle"
               aria-hidden="true"
               style={{
                 marginLeft: '16px',
                 fontSize: fontSizes.medium,
                 verticalAlign: 'middle',
                 color: DANGER_COLOR,
               }}
            />
            }</DropdownItem>
          })}
        </DropdownMenu>
      </ButtonDropdown>
    )
  }
};

export default GWNavDropdown;
