import './LandingButton.scss';
import React from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import { block } from 'bem-cn';

const b = block('LandingButton');

export const LandingButton = (props) => {
  const { to, title, transparent, large, className, children } = props;

  return <Link
    className={classNames(className, b(), { [b('transparent')]: transparent },{ [b('large')]: large })}
    to={to}>
    {title ? title : children}
  </Link>;

};
