import React, {Component} from 'react';

export class IconButtonWrapper extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return <div
      onClick={this.props.onClick}
    >
      {this.props.children}
    </div>;
  }
}
