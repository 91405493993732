import React, { Component } from 'react';
import {
  isIOSorAndroid
} from '../../helpers/methods';
import {
  ShareButton
} from '../index';
import PropTypes from 'prop-types';
import '../../styles/components/ShareBar.scss';
import {
  WhatsAppShare,
  TwitterShare,
  PinterestShare,
  LinkedinShare,
  EmailShare,
  FacebookMessengerShare,
  FacebookShare,
} from './config';

import SVGInline from 'react-svg-inline';

const svg_mail = require('!!svg-inline-loader?classPrefix!../../assets/share-icons/mail.svg');
const svg_facebook = require('!!svg-inline-loader?classPrefix!../../assets/share-icons/facebook.svg');
const svg_linkedin = require('!!svg-inline-loader?classPrefix!../../assets/share-icons/linkedin.svg');
const svg_messanger = require('!!svg-inline-loader?classPrefix!../../assets/share-icons/messanger.svg');
const svg_pinterest = require('!!svg-inline-loader?classPrefix!../../assets/share-icons/pinterest.svg');
const svg_twitter = require('!!svg-inline-loader?classPrefix!../../assets/share-icons/twitter.svg');
const svg_whatsapp = require('!!svg-inline-loader?classPrefix!../../assets/share-icons/whatsapp.svg');
const svg_share = require('!!svg-inline-loader?classPrefix!../../assets/share-icon.svg');

export default class ShareBar extends Component {
  static propTypes = {
    urlToShare: PropTypes.string.isRequired,
    themeOfShare: PropTypes.string.isRequired,
  };

  constructor(props){
    super(props);

    this.state = {
      selectedTypeOfShare: '',
      email: ''
    };
  }

  shareOnSocialMedia = ()=>{
    const { selectedTypeOfShare, email } = this.state;
    const { urlToShare } = this.props;
    if (selectedTypeOfShare){
      switch (selectedTypeOfShare){
        case 'EM' : {
          EmailShare(urlToShare, email);
          
          return;
        }
        case 'FB' : {
          FacebookShare(urlToShare);
          
          return;
        }
        case 'FBM' : {
          FacebookMessengerShare(urlToShare);
          
          return;
        }
        case 'LI' : {
          LinkedinShare(urlToShare);
          
          return;
        }
        case 'PI' : {
          PinterestShare(urlToShare);
          
          return;
        }
        case 'TW' : {
          TwitterShare(urlToShare);
          
          return;
        }
        case 'WU' : {
          WhatsAppShare(urlToShare);
          
          return;
        }
      }
    }
  };

  onSelectTypeOfShare = (type) => {
    this.setState({
      selectedTypeOfShare: type
    });
  };

  onEmailChange = (evt) => {
    this.setState({
      email: evt.target.value
    });
  };

  render() {
    const {
      selectedTypeOfShare,
      email
    } = this.state;
    const {
      themeOfShare
    } = this.props;
    
    return (
      <div className="share-bar"
        style={{background: `${(themeOfShare === 'black' ? '#000000': '#ffffff')}`,
          color: `${(themeOfShare === 'black' ? '#ffffff': '#000000')}`,
          border: `1px solid ${(themeOfShare === 'black' ? '#ffffff': '#000000')}`}}>
        <div>
          <div className="buttons-wrapper">
            <ShareButton selected={selectedTypeOfShare === 'EM'}
              onSelect={() => this.onSelectTypeOfShare('EM')}>
              <SVGInline svg={svg_mail}
                width="24px"
                height="24px"
                fill={selectedTypeOfShare === 'EM' ? '#ffd210' : (themeOfShare === 'black' ? '#ffffff': '#000000')}/>
            </ShareButton>
            <ShareButton selected={selectedTypeOfShare === 'FB'}
              onSelect={() => this.onSelectTypeOfShare('FB')}>
              <SVGInline svg={svg_facebook}
                width="24px"
                height="24px"
                fill={selectedTypeOfShare === 'FB' ? '#ffd210' : (themeOfShare === 'black' ? '#ffffff': '#000000')}/>
            </ShareButton>
            <ShareButton selected={selectedTypeOfShare === 'LI'}
              onSelect={() => this.onSelectTypeOfShare('LI')}>
              <SVGInline svg={svg_linkedin}
                width="24px"
                height="24px"
                fill={selectedTypeOfShare === 'LI' ? '#ffd210' : (themeOfShare === 'black' ? '#ffffff': '#000000')}/>
            </ShareButton>
            <ShareButton selected={selectedTypeOfShare === 'PI'}
              onSelect={() => this.onSelectTypeOfShare('PI')}>
              <SVGInline svg={svg_pinterest}
                width="24px"
                height="24px"
                fill={selectedTypeOfShare === 'PI' ? '#ffd210' : (themeOfShare === 'black' ? '#ffffff': '#000000')}/>
            </ShareButton>
            <ShareButton selected={selectedTypeOfShare === 'TW'}
              onSelect={() => this.onSelectTypeOfShare('TW')}>
              <SVGInline svg={svg_twitter}
                width="24px"
                height="24px"
                fill={selectedTypeOfShare === 'TW' ? '#ffd210' : (themeOfShare === 'black' ? '#ffffff': '#000000')}/>
            </ShareButton>
            <ShareButton selected={selectedTypeOfShare === 'WU'}
              onSelect={() => this.onSelectTypeOfShare('WU')}>
              <SVGInline svg={svg_whatsapp}
                width="24px"
                height="24px"
                fill={selectedTypeOfShare === 'WU' ? '#ffd210' : (themeOfShare === 'black' ? '#ffffff': '#000000')}/>
            </ShareButton>
            {
              isIOSorAndroid()
                ?
                <ShareButton selected={selectedTypeOfShare === 'FBM'}
                  onSelect={() => this.onSelectTypeOfShare('FBM')}>
                  <SVGInline svg={svg_messanger}
                    width="24px"
                    height="24px"
                    fill={selectedTypeOfShare === 'FBM' ? '#ffd210' : (themeOfShare === 'black' ? '#ffffff': '#000000')}/>
                </ShareButton>
                :
                ''
            }
            {
              selectedTypeOfShare === 'EM'
                ?
                <input className="publish-input"
                  placeholder="Enter email"
                  value={email}
                  onChange={this.onEmailChange}/>
                :
                ''
            }
            <button className="share-last__button"
              style={{background: `${(themeOfShare === 'black' ? '#000000': '#ffffff')}`,
                color: `${(themeOfShare === 'black' ? '#ffffff': '#000000')}`,
                border: `1px solid ${(themeOfShare === 'black' ? '#ffffff': '#000000')}`}}
              onClick={this.shareOnSocialMedia}>
              <span className="share-last__button-text">Share on Social Media</span>
              <SVGInline svg={svg_share}
                width={'20px'}
                height={'16px'}
                fill={(themeOfShare === 'black' ? '#ffffff': '#000000')}/>
            </button>
          </div>
        </div>
      </div>
    );
  }
}
