import React from 'react';

import {connect} from 'react-redux';
import * as _ from 'lodash';
import moment from 'moment';

import {Badge, Tooltip} from 'antd';
import 'antd/es/badge/style/css';
import 'antd/es/tooltip/style/css';

import block from 'bem-cn'

import IconButton from 'material-ui/IconButton';
import SVGInline from 'react-svg-inline';
import {Link} from 'react-router-dom';

import {ICON_BUTTON_STYLES} from './../NavBar';
import './MessageNotificationsPopover.scss'
import RichPopover from './../RichPopover/RichPopover';
import { UserPic } from '../../common/UserPic/UserPic'
import Loader from "../../discover/Loader/Loader";

const comment = require('!!svg-inline-loader?classPrefix!../../../assets/comment-icon.svg');
const b = block('NavBar')

@connect(
  ({ inbox }) => ({
    unseenInboxCount: inbox.unseenInboxCount,
    allChatsWithLastMessage: inbox.allChatsWithLastMessage,
    isGettingAllChatsWithLastMessage: inbox.isGettingAllChatsWithLastMessage,
  })
)
class MessageNotificationsPopover extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      popoverOpen: false,
    }
  }

  componentDidMount() {
    this.refreshNotificationsTimeInterval = setInterval(() => this.forceUpdate(), 60000);

  }

  componentWillUnmount() {
    clearInterval(this.refreshNotificationsTimeInterval)
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.hidenNotificationTrigger !== this.props.hidenNotificationTrigger) {
      this.handleOpen();
    }
  }

  handleOpen = () => {
    if (this.props.openFakeNotificationPopover) {
      this.props.openFakeNotificationPopover()
    } else {
      this.setState({
        popoverOpen: true,
      })
    }
  }

  handleClose = () => this.setState({
    popoverOpen: false,
  })

  handleVisibleChange = visible => {
    if (!this.props.openFakeNotificationPopover) {
      this.setState({popoverOpen: visible});
    }
  };

  renderContent = () => {
    const {nextTryTimestamp, pusherStatus} = this.props;

    return this.props.isGettingAllChatsWithLastMessage ?
      <>
        <Loader />
      </> :
      <div>
        { this.renderMessageNotifications() }
      </div>
  }

  renderMessageNotifications = () => {
    const { allChatsWithLastMessage } = this.props;

    return _.sortBy(allChatsWithLastMessage, o => new moment(o.time).format('YYYYMMDDHHmm', ['desc']))
      .reverse()
      .map(item => {
        const {conversation_id, lastMessage, receiver} = item;
        const chatInboxLink = `/dashboard/inbox/${conversation_id}`;

        if (lastMessage === null) {
          return;
        }

        return <Link
          key={conversation_id}
          className="MessageNotificationItem"
          to={chatInboxLink}
          onClick={this.handleClose}
        >
          <UserPic
            href="#!"
            src={receiver.avatar || null}
            className={b('avatar')}
            profileCompleted ={true}
          />

          <div className="px-2 py-1 w-100 d-flex flex-column">
            <div className="d-flex justify-content-between">
              <span className="MessageNotificationItem__Title">{receiver.name}</span>
              <Tooltip
                placement="left"
                title={moment(lastMessage.created_at, 'DD MMMM YYYY, hh:mm')}
              >
                <span className="MessageNotificationItem__Date">
                  {moment(lastMessage.created_at).local().fromNow()}
                </span>
              </Tooltip>
            </div>

            <span className="MessageNotificationItem__Message">
              {lastMessage.body}
            </span>
          </div>
        </Link>
      });
  }

  render() {
    const { unseenInboxCount } = this.props;
    const { popoverOpen } = this.state;

    return <RichPopover
      visible={popoverOpen}
      onVisibleChange={this.handleVisibleChange}
      title={<div className="d-flex align-items-center">
        Inbox { unseenInboxCount > 0 ? <span> ({unseenInboxCount}) </span> : '' }
      </div>}
      content={this.renderContent()}
      overlayClassName="NotificationsPopover"
    >
      <IconButton
        className="IconButton"
        style={ICON_BUTTON_STYLES}
        onClick={popoverOpen ? this.handleClose : this.handleOpen}
      >
        <Badge
          count={unseenInboxCount}
          style={{boxShadow: 'none'}}
        >
          <SVGInline
            svg={comment}
            className="IconButton__Icon"
            fill="#fff"
          />
        </Badge>
      </IconButton>

    </RichPopover>
  }
}

export default MessageNotificationsPopover
