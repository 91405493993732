import React, { Component } from 'react'
import { connect } from 'react-redux'
import { getAutocompleteStyles } from '../../redux/modules/profile'
import { getCategories } from '../../redux/modules/common'
import Chip from 'material-ui/Chip'
import IconButton from 'material-ui/IconButton'
import TextField from 'material-ui/TextField'
import { Button } from '../CommonComponent'
import Checkbox from 'material-ui/Checkbox'
import SVGInline from 'react-svg-inline'
import { difference, debounce, differenceBy, capitalize, get } from 'lodash'
import { AutoCompleteComponent } from './../CommonComponent'
import { ServiceInfoCheckBoxGroup } from './CommonComponent'
import { Field } from 'redux-form'
import { DropTarget, DragDropContext, DragSource } from 'react-dnd'
import HTML5Backend from 'react-dnd-html5-backend'
import '../../styles/components/Category.scss'
import * as _ from 'lodash'
import SelectField from 'material-ui/SelectField';
import MenuItem from 'material-ui/MenuItem';

import { cloneDeep } from 'lodash'
import { getTextForProfileType } from '../../helpers/textForProfileTypes'
import apiClient from '../../helpers/apiClient'
import { getRealTypeOfUser } from '../../helpers/methods';
import { defaultFontSize } from '../../utils/constants';
import Loader from '../discover/Loader/Loader';
import { topMusicStyles } from '../../utils/constants'
import {Asterisk} from "../common/Asterisk/Asterisk";

const squareSvg = require('!!svg-inline-loader?classPrefix!../../assets/square.svg')
const closeSvg = require('!!svg-inline-loader?classPrefix!../../assets/icons/close.svg')
const collapseStylesType = 'music_genre';

export const InputStyles = {
	...defaultFontSize,
	fontWeight: '300',
	minHeight: '26px',
	color: '#000',
	width: '100%',
}

export const PlaceholderStyles = {
	...defaultFontSize,
	whiteSpace: 'nowrap',
}

//Implement workaround for backend index of tag starting from 1
export class Categories extends Component {
	constructor(props) {
		super(props)

		this.state = {
			goodForOptions: null,
			expandedCategoryIdx: 0
		}
	}

	componentDidMount() {
		const { type } = this.props;

		if (isVenue(type)) {
			apiClient.get('/api/autocomplete/dictionary?name=Good for')
			.then(res => res.data.map(i => { i.weight = +i.weight; return i }))
			.then(options => _.sortBy(options, ['weight', 'id']))
			.then(options => _.partition(options, x => x.weight === 1)) // 1 is weight of group size items
			.then(goodForOptions => this.setState({ goodForOptions }))
		}
	}

	addNewCategory = () => {
		const {
			input: { value, onChange },
		} = this.props
		const newArray = cloneDeep(value)
		newArray.push({
			id: '',
			name: '',
			styles: [],
			good_for: [],
		})
		onChange(newArray)
	}

	removeCategory = (idx) => {
		const {
			input: { value, onChange },
		} = this.props
		const newArray = cloneDeep(value)
		newArray.splice(idx, 1);
		onChange(newArray);
		if (newArray.length === 0) this.addNewCategory()
	}

	changeCategory = (changedCategory, index) => {
		const {
			input: { value, onChange },
		} = this.props
		const newArray = cloneDeep(value)
		newArray[index] = changedCategory
		onChange(newArray)
	}

	showMore = (idx) => {
		this.setState({ expandedCategoryIdx: idx })
	}

	render() {
		const {
			input: { value },
			meta,
			type,
			categoryTypeText,
		} = this.props

		return (
			<div className="w-100">
				{value.map((category, idx) => (
					<Category
						category={category}
						type={type}
						key={idx}
						index={idx}
						goodForOptions={this.state.goodForOptions}
						categoryTypeText={categoryTypeText}
						onChange={this.changeCategory}
						onRemove={() => this.removeCategory(idx)}
						onShowMore={() => this.showMore(idx)}
						isExpanded={idx === this.state.expandedCategoryIdx}
					/>
				))}
				{/* {meta && meta.error && <div className="talent-error">{meta.error}</div>} */}
				{/* {isVenue(type) && (
					<button className='button' onClick={this.addNewCategory}>
						Add Additional Venue Type
					</button>
				)} */}
			</div>
		)
	}
}

const isVenue = str => str && str.includes('venue')
const isArtist = str => str && str.includes('artist')
const isGroup = str => str && str.includes('group')
const isBand = str => str && str.includes('band')

const enableOccasions =  str => str && (isVenue(str) || isArtist(str) || isBand(str) || isGroup(str))

@connect(
	({ user, common, globalConfig }) => ({
		currentUser: user.currentUser,
		categories: common.categories,
		isMobile: globalConfig.isMobile,
	}),
	{
		getCategories,
		getAutocompleteStyles,
	}
)
//TODO refactoring Venue occassions
class Category extends Component {
	constructor(props) {
		super(props)

		this.state = {
			dataSource: [],
			availableStyles: [],
      		occasions: []
		}

		this.materialStyle = {
			chip: {
				margin: 4,
			},
		}

		this.bouncedUpdateAutoComplete = debounce(this.updateDataSource, 500)
	}

	loadOccasions(categoryId) {
		if (!categoryId || this.state.occasions.length) return []

    const { currentUser } = this.props;

    const style_type = currentUser.profile_type === 'artist' || currentUser.profile_type === 'group' ? 'artist_occasion' : 'venue_occasion';

		apiClient
			.get(`/api/autocomplete/styles?category_id=${categoryId}&style_type=${style_type}`)
			.then(({ data }) => {
				this.setState({ occasions: data })
			})
	}

	componentDidMount() {
		const { category } = this.props

		this.props.getCategories()

		if (category && category.name) {
			this.bouncedUpdateAutoComplete(category.name)
		}

		if (category && enableOccasions(this.props.type)) this.loadOccasions(category.id)
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		const { category } = nextProps

		if (category && enableOccasions(this.props.type)) this.loadOccasions(category.id)
	}

	//TODO refactoring (redesign)
	getAdditionalField = () => {
		switch (this.props.type) {
			case 'venue': {
				return <Field name="tags" component={VenueOccasions} />
			}
			default: {
				return null
			}
		}
	}

	handleRequestDelete = key => {
		const { onChange, category, type, index } = this.props
		const newCat = cloneDeep(category)
		let tags = newCat.styles.filter(style => this.getTagType(type).includes(style.type))
		let other = difference(newCat.styles, tags)
		newCat.styles = tags.filter((tag, index) => index !== key).concat(other)
		newCat.styles.forEach((tag, index) => {
			tag.top_position = index + 1
			if (index > 4) {
				delete tag.top_position
			}
		})
		onChange(newCat, index)
	}

	//Return default type
	getTagType = type => {
		switch (type) {
			case 'artist.basic': {
				return ['talent_style', 'music_genre']
			}
			case 'group.band': {
				return ['band_genre', 'music_genre']
			}
			case 'group.basic': {
				return ['talent_style', 'music_genre']
			}
			case 'venue.basic': {
				return ['venue_style', 'venue_industry', 'venue_color']
			}
		}
	}

	//TODO refactoring
	addTag = val => {
		const { onChange, category, type, index } = this.props
		const newCat = cloneDeep(category)

		const tags = newCat.styles.filter(style => this.getTagType(type).includes(style.type))

		const { searchTxt } = this.state

		if (val && !_.find(tags, tag => tag.name === val.name)) {
			const valCopy = cloneDeep(val)
			if (this.getTagType(type).includes(valCopy.type)) {
				if (tags.length < 5) {
					valCopy.top_position = tags.length + 1
				}

				newCat.styles.push(valCopy)
				onChange(newCat, index)
			}
		} else if (searchTxt && !_.find(tags, tag => tag.name === searchTxt)) {
			const newTag = {
				name: searchTxt,
				type: this.getTagType(type)[0],
			}

			if (tags.length < 5) {
				newTag.top_position = tags.length + 1
			}

			this.setState({
				searchTxt: '',
			})

			newCat.styles.push(newTag)
			onChange(newCat, index)
		}
	}

	changeCategoryName = newName => {
		const { onChange, category, index } = this.props
    const occasions = category.styles.filter(x => ['venue_occasion', 'artist_occasion'].includes(x.type))
		const newCat = cloneDeep(category)

		newCat.id = null
		if (this.state.availableCategories) {
			const cat = this.state.availableCategories.find(cat => cat.name === newName)
			if (cat) {
				newCat.id = cat.id
			}
		}
		newCat.name = newName
		newCat.styles = occasions
		onChange(newCat, index)
		this.bouncedUpdateAutoComplete(newName)
	}

	moveStyle = ({ oldIndex, newIndex }) => {
		const { onChange, category, index } = this.props
		const newCat = cloneDeep(category)
		const tags = newCat.styles.filter(style => this.getTagType(this.props.type).includes(style.type))

		let other = difference(newCat.styles, tags)
		;[tags[oldIndex], tags[newIndex]] = [tags[newIndex], tags[oldIndex]]

		tags.forEach((tag, index) => {
			tag.top_position = index + 1
			if (index > 4) {
				delete tag.top_position
			}
		})
		newCat.styles = tags.concat(other)

		onChange(newCat, index)
	}

	autoCompleteUpdateHandler = searchTxt => {
		this.setState({
			searchTxt,
		})
	}

	onOccasionChange = (style, isChecked) => {
		const { category, index, onChange } = this.props;

		const styles = isChecked ? category.styles.concat(style) : category.styles.filter(x => x.name !== style.name)

		onChange({ ...category, styles }, index)
	}

	onGoodForChange = ({ id, isChecked, ...restValues }) => {
		const { category, index, onChange} = this.props;
		const good_for = JSON.parse(JSON.stringify(category.good_for || []));
		const itemIndex = good_for.findIndex(x => x.id === id)

		if (isChecked && itemIndex === -1) {
			good_for.push({ id, ...restValues });
		} else if (isChecked) {
			good_for[itemIndex] = { ...good_for[itemIndex], ...restValues};
		} else {
			good_for.splice(itemIndex, 1);
		}

		onChange({ ...category, good_for }, index)
	}

	addOccasion = (name) => {
		const { category, index, onChange } = this.props
		const { occasions } = this.state

		const newOccasion = {
			name,
			id: 'custom',
			type: 'venue_occasion'
		}

		this.setState({ occasions: [...occasions, newOccasion] })

		const styles = category.styles.concat(newOccasion)
		onChange({ ...category, styles }, index)
	}

	removeOccasion = (style) => {
	  	const { category, index, onChange } = this.props
		const { occasions } = this.state

		const styles = category.styles.filter(x => x.name !== style.name)
		const newOccasion = occasions.filter(x => x.name !== style.name)

		this.setState({ occasions: newOccasion})

		onChange({ ...category, styles }, index)
  }


 	 updateDataSource = value => {
        const { category, type, currentUser, getCategories } = this.props

		if (value) {
			const profileType = getRealTypeOfUser(currentUser)
			if (profileType === 'band') {
				getCategories()
				.then(res => {
					const allCategories = res.data['all']
					const MUSIC_ID = allCategories.find(category => category.name === 'Music').id

					const categoriesForType = allCategories.filter(category => category.type === 'music_genre')

					this.setState({
						dataSource: categoriesForType.map(cat => cat.name),
						availableCategories: categoriesForType,
					})
					return categoriesForType //.find(arrCategory => arrCategory.type === 'music_genre')
				})
				.then(categories => {
					if (categories) {
						return categories
					}
				})
				.then(res => {
					if (res) {
						let availableStyles = res
							.map(sTag => ({
								name: sTag.name,
								type: sTag.type,
							}))
							.filter(tag => this.getTagType(type).includes(tag.type))

						this.setState({
							availableStyles,
						})
					}
				})
			}
			else if (profileType === 'group') {
				getCategories()
				.then(res => {
					const allCategories = res.data['all']
					const majorCategoriesNames = ['Visual Arts', 'Performing Arts', 'Music', 'Production']

					let majorCategories = majorCategoriesNames.map((name) => {
						const id = allCategories.find(category => category.name === name).id || null
						return {
							id,
							name: name,
							children: allCategories.filter(category => category.parent_category_id === id)
						}
					})

					let categoriesForType = []
					majorCategories.forEach(category => {
						categoriesForType = [...categoriesForType, ...category.children]
					})

					let dataSource = []
					majorCategories.forEach(majorCategory => {
						dataSource = [
							...dataSource,
							{
								text: '.' + majorCategory.name,
								value: <MenuItem primaryText={majorCategory.name} style={{ color: '#474747', fontWeight: '600' }} disabled />,
							},
							...majorCategory.children.map(cat => ({ text: cat.name, value: <MenuItem primaryText={cat.name} /> }))
						]
					})

					this.setState({
						dataSource,
						availableCategories: categoriesForType,
					})
					return categoriesForType.find(arrCategory => category.name === arrCategory.name)
				})
				.then(category => {
					if (category) {
						return this.props.getAutocompleteStyles(category.id)
					}
				})
				.then(res => {
					if (res) {
						let availableStyles = res.data
							.map(sTag => ({
								name: sTag.name,
								type: sTag.type,
							}))
							.filter(tag => this.getTagType(type).includes(tag.type))

						this.setState({
							availableStyles,
						})
					}
				})

			}
			else {
				getCategories()
				.then(res => {
					const cats = res.data[type]
					const allCategories = res.data['all']
					const MUSIC_ID = allCategories.find(category => category.name === 'Music').id

					const rootName = currentUser.profile_type === 'artist' || currentUser.profile_subtype === 'group' ?
						'Artists' :
						`${capitalize(currentUser.profile_type)}s`

					const rootCategory = allCategories.find(x => x.name === rootName)

					const rootCategoryId = rootCategory.id

					const parentCategories = allCategories
						.filter(x => x.parent_category_id === rootCategoryId)

					const parentCategoriesIds = parentCategories.map(cat => cat.id)

					this.setState({
						dataSource: cats.filter(cat => parentCategoriesIds.includes(cat.parent_category_id) && cat.type !== 'venue_occasion').map(cat => cat.name),
						// dataSource: cats.map(cat => cat.name).filter(cat => !cat.is_parent),
						availableCategories: cats,
					})

					return cats.find(arrCategory => category.name === arrCategory.name)
				})
				.then(category => {
					if (category) {
						// const { categories } = this.props;
						// const MUSIC_ID = categories.all.find(category => category.name === 'Music').id
						return this.props.getAutocompleteStyles(category.id) // , category.tree_path[0] === MUSIC_ID ? { style_type: 'music_genre' } : {}
					}
				})
				.then(res => {
					if (res) {
						let availableStyles = res.data
							.map(sTag => ({
								name: sTag.name,
								type: sTag.type,
							}))
							.filter(tag => this.getTagType(type).includes(tag.type))

						this.setState({
							availableStyles,
						})
					}
				})
			}
		}


	}

	render() {
		const { category, type, categoryTypeText, currentUser, goodForOptions, isExpanded, isMobile } = this.props
		const tags = category.styles.filter(style => this.getTagType(type).includes(style.type))
		const { searchTxt, dataSource, availableStyles, occasions } = this.state
    const style_type = currentUser.profile_type === 'artist' || currentUser.profile_type === 'group' ? 'artist_occasion' : 'venue_occasion';

    const occasionNames =
			enableOccasions(type) &&
			category.styles.reduce((acc, style) => (style.type === style_type ? (acc.push(style.name), acc) : acc), [])

		const profile_type = (
				currentUser.profile_type === 'group' && currentUser.profile_subtype === 'band'
			) ?
			'band' :
			currentUser.profile_type

		return (
			<div className="band-genre-form__item">
				<div className="band-genre-form__item-content">
					<div className="talent-tags-controller">
						<div className="talent-tags-controller__control">
							<div className="band-genre-form__search band-genre-form__container">
								<h5>Primary {categoryTypeText}</h5>
								<div className="webkitScroll">
									<AutoCompleteComponent
										input={{ value: category.name, onChange: value => this.changeCategoryName(value) }}
										textFieldStyle={InputStyles}
										inputStyle={{ color: 'black' }}
										dataSource={dataSource}
										fullWidth={true}
										hintText={getTextForProfileType('registration', 'groupTalentAndStyles', profile_type)}
									/>
									<i className="fa fa-search" aria-hidden="true" />
									{/* <i className="fa fa-minus" onClick={this.props.onRemove}/> */}
								</div>
							</div>
							{isExpanded ? (
							<div className="w-100">
							<div style={{ width: '100%' }}>
								{!!availableStyles.length && (
									<CategoryDnDContainer
										axis="xy"
										tags={tags}
										pressDelay={50}
										onUpdateInput={this.autoCompleteUpdateHandler}
										onTagAdd={this.addTag}
										searchTxt={searchTxt}
										moveStyle={this.moveStyle}
										availableStyles={availableStyles}
										onTagDelete={this.handleRequestDelete}
										typeProfile={profile_type}
									/>
								)}
								{this.getAdditionalField(type)}
							</div>

							{enableOccasions(type) && (
								<div className="occasions" style={{ marginBottom: '3rem' }}>
									<h5 className="occasions-header">
										{_.capitalize(getRealTypeOfUser(currentUser))} occasions
                    {getRealTypeOfUser(currentUser) === 'venue' ? <Asterisk /> : ''}
									</h5>
									<div
										style={{
											display: 'flex',
											flexWrap: 'wrap',
										}}
									>
										{occasions.map((style, idx) => (
										  <div
												key={idx}
												style={{ display: 'flex',  width: isMobile ? '50%' : '33%' }}
											>
												<Checkbox
												labelStyle={{
													flexGrow: 1,
													color: '#000',
													fontSize: '.9rem',
													textAlign: 'left',
													marginTop: '.1rem',
												}}
												checkedIcon={<SVGInline width="1rem" svg={squareSvg} fill="#ffd210" />}
												uncheckedIcon={<SVGInline width="1rem" svg={squareSvg} fill="#ededed" />}
												checked={occasionNames.includes(style.name)}
												onCheck={(_, isChecked) => this.onOccasionChange(style, isChecked)}
												label={ style.name }
												/>

												{
												style.id === 'custom' && <IconButton
													style={{ flexBasis: '1rem', padding: 0, height: '100%' }}
													onClick={() => {this.removeOccasion(style) }}
												>
													<SVGInline
													width="1rem"
													svg={closeSvg}
													fill="#ffd210"
													/>
												</IconButton>
												}
											</div>
										))}
									</div>

									<VenueCustomOccasionsInput
										style={{ marginTop: '1rem' }}
										onInput={this.addOccasion}
									/>

									{isVenue(type) &&
										<div>
											<VenueGoodForSection
												value={category.good_for}
												options={goodForOptions}
												onChange={this.onGoodForChange}
											/>
										</div>
									}
								</div>
							)}
						</div>
							) : (
								<button className='button' onClick={this.props.onShowMore} style={{ marginBottom: '3rem' }}>
									Show more
								</button>
							)}
						</div>
					</div>
				</div>
			</div>
		)
	}
}

class VenueCustomOccasionsInput extends Component {
  constructor(props) {
    super(props)

    this.state = {
      value: props.initialValue || ''
    }
  }

  render() {
    const { onInput/*, style*/ } = this.props
    const { value } = this.state

    const style = {
      display: 'none'
    }

    return (<div style={style}>
      <h5>You can also add your custom occasion:</h5>

      <div style={{ display: 'flex', alignItems: 'center' }}>
        <TextField
           id="id_new_occasion"
           style={{ width: '50%', minWidth: '200px' }}
           value={value}
           margin="normal"
           onChange={(evt) => { this.setState({ value: evt.target.value }) }}
         />

        <Button
          variant="contained"
          type='button'
          onClick={() => {
            if (value.length > 1) {
              const capitalzedVal = value.charAt(0).toUpperCase() + value.slice(1)
              onInput(capitalzedVal)
              this.setState({ value: '' })
            }
          }}
        >
          Add
        </Button>
      </div>
    </div>)
  }
}

class VenueOccasions extends Component {
	changeOccasions = array => {
		const {
			input: { onChange },
		} = this.props
		onChange({ venue_occasion: array.map(venue_occasion => ({ content: venue_occasion })) })
	}

	render() {
		const {
            input: { value },
            currentUser,
		} = this.props

		const optionNames = [].categories.styles.map(style => style.name)

		return (
			<div>
				<ServiceInfoCheckBoxGroup
					groupName={isVenue(currentUser.profile_type) ? "Venue occasions" : "Artist occasions"}
					optionNames={optionNames}
					collapsible={false}
					input={{ value: value.venue_occasion.map(v_o => v_o.content), onChange: this.changeOccasions }}
				/>
			</div>
		)
	}
}

@connect(({ globalConfig }) => ({
	isMobile: globalConfig.isMobile,
}))
class VenueGoodForSection extends Component {
	render() {
		const { value, onChange, options, isMobile } = this.props;

		return (<div style={{ marginTop: '1rem', width: '100%'}}>
			<h5 style={{ fontSize: '1rem', fontWeight: 500 }}>
				Good for
			</h5>
			{
				options ?
					options.map(optionGroup => <div
						style={{
							marginTop: '24px',
							display: 'grid',
							gridTemplateColumns: isMobile ? '1fr' : '1fr 1fr 1fr'

						}}>
						{
							optionGroup.map(item => {
								const selectedItem = value.find(x => x.id === item.id)

								return <div style={{ display: 'flex' }}>
									<Checkbox
										checked={!!selectedItem}
										label={item.name}
										labelStyle={{ textAlign: 'left', color: '#000', width: 'auto' }}
										style={{ width: 'auto' }}
										onCheck={(_, isChecked)=> onChange({ id: item.id, isChecked})}
									/>
									{ item.name === 'Adults only' && <SelectField
										//todo zrobic to zeby nie uzywalo zhardcodowanych wartości (nazwy i opcji)
										value={selectedItem && selectedItem.value || null}
										onChange={(_, indexValue) => onChange({ id: item.id, isChecked: true, value: [18, 21][indexValue]})}
										style={{ maxWidth: '90px', marginLeft: '8px', marginTop: '-12px'}}
										disabled={!selectedItem}
										>
											<MenuItem value={18} primaryText="18+" label="18+" />
											<MenuItem value={21} primaryText="21+" label="21+" />
										</SelectField>
									}
								</div>
							})
						}
					</div>) : <Loader />
			}
		</div>)
	}
}

/**
 * DnD part
 */
// @connect(({ user }) => ({
// 	typeProfile: user.currentUser.profile_type,
// }))
//TODO
@DragDropContext(HTML5Backend)
class CategoryDnDContainer extends Component {
	getSortableItems = (arr, factor = 0, styleChip) => {
		const { onTagDelete, moveStyle } = this.props

		return arr.map((value, index) => (
			<CategoryDnDStyle
				key={`item-${index + factor}`}
				chipIndex={index + factor}
				styleChip={{ cursor: 'pointer', ...styleChip }}
				type={'both'}
				value={value}
				moveStyle={moveStyle}
				onTagDelete={onTagDelete}
			/>
		))
	}

	getEmptyChips = len => {
		const width = 77 //100 / len - 1

		return new Array(len).fill('').map((val, index) => (
			<EmptyChipDropTarget
				key={index}
				width={`${width}px`}
				style={{
					width: `100%`,
					height: '1.875rem',
					background: '#fff',
					border: '1px solid #a3a3a3',
				}}
			/>
		))
	}

	render() {
		//TODO
		const { tags, searchTxt, onUpdateInput, onTagAdd, availableStyles, onTagDelete, typeProfile } = this.props
		const topTags = tags
			.filter(tag => typeof tag.top_position === 'number')
			.sort((a, b) => a.top_position - b.top_position)
		const normalTags = tags.filter(tag => typeof tag.top_position !== 'number')
		const filteredAvailableStyles = differenceBy(availableStyles, tags, 'name')

		return (
			<div>
				<h5>
					{isVenue(typeProfile) ? 'Top 5 Styles of the Venue' : 'Additional top 5 ' + getTextForProfileType('registration', 'topFiveItems', typeProfile).toLowerCase()}
					{topTags.length > 0 && (
						<span className="band-genre-form__description" style={{ marginLeft: '0.625rem' }}>
							Select as many {getTextForProfileType('registration', 'topFiveItems', typeProfile).toLowerCase()} as you
							like. Drag {getTextForProfileType('registration', 'topFiveItems', typeProfile).toLowerCase()} into the top
							row to select your Top 5.
						</span>
					)}
				</h5>
				<div className="band-genre-form__container d-flex flex-wrap at-top5-chips">
					{topTags.length ? (
						this.getSortableItems(topTags, 0, { backgroundColor: '#ffd210', fontWeight: '500' })
					) : (
						<div className="d-flex justify-content-between align-items-center" style={{ width: '100%' }}>
							<div className="d-flex justify-content-between align-items-center" style={{ width: '55%' }}>
								{this.getEmptyChips(5)}
							</div>
							{topTags.length ? null : (
								<span className="band-genre-form__description" style={{ maxWidth: '45%' }}>
									Select as many {getTextForProfileType('registration', 'topFiveItems', typeProfile).toLowerCase()} as
									you like. Drag {getTextForProfileType('registration', 'topFiveItems', typeProfile).toLowerCase()} into
									the top row to select your Top 5
								</span>
							)}
						</div>
					)}
				</div>
				<h5>{isVenue(typeProfile) ? 'Additional Styles of the Venue' : 'Your additional selected ' + getTextForProfileType('registration', 'topFiveItems', typeProfile).toLowerCase()}</h5>
				<div className="band-genre-form__container d-flex flex-wrap at-chips">
					{normalTags.length ? (
						this.getSortableItems(normalTags, topTags.length, { backgroundColor: '#ffd210', fontWeight: '500' })
					) : (
						<div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
							{this.getEmptyChips(8)}
							<div style={{ height: '0.9375rem' }} />
						</div>
					)}
				</div>
				{/* <h6>Don't see your {getTextForProfileType('registration', 'plansTalentItems', typeProfile)} here?</h6>
				<div className="band-genre-form__add-block">
					<AutoComplete
						style={{
							maxWidth: '20rem',
						}}
						inputStyle={InputStyles}
						hintStyle={PlaceholderStyles}
						fullWidth={true}
						hintText={`Enter a new ${getTextForProfileType('registration', 'plansTalentItems', typeProfile)}`}
						searchText={searchTxt}
						dataSource={[]}
						onUpdateInput={onUpdateInput}
					/>
					<div className="talent-tags-controller__control-add">
						<button disabled={!searchTxt} type="button" onClick={() => onTagAdd()}>
							Click to create new {getTextForProfileType('registration', 'plansTalentItems', typeProfile)}
						</button>
					</div>
				</div> */}
				<CategoryDnDSource
					searchTxt={searchTxt}
					onTagDelete={onTagDelete}
					availableStyles={filteredAvailableStyles}
					onTagAdd={onTagAdd}
					startIndex={topTags.length + normalTags.length}
					typeProfile={typeProfile}
				/>
			</div>
		)
	}
}

const styleSource = {
	beginDrag(props) {
		return props
	},
}

//TODO
const styleTarget = {
	drop(props, monitor, component) {
		const drag = monitor.getItem()

		if (drag.copy) {
			drag.addTag(drag.value)
		} else {
			props.moveStyle({ oldIndex: drag.chipIndex, newIndex: props.chipIndex })
		}
	},
}

@DropTarget('style', styleTarget, connect => ({
	connectDropTarget: connect.dropTarget(),
}))
@DragSource('style', styleSource, (connect, monitor) => ({
	connectDragSource: connect.dragSource(),
	isDragging: monitor.isDragging(),
}))
class CategoryDnDStyle extends Component {
	render() {
		const {
			value,
			styleChip,
			chipIndex,
			onTagDelete,
			type,
			isDragging,
			addTag,
			connectDragSource,
			connectDropTarget,
		} = this.props

		if (type === 'both') {
			return connectDragSource(
				connectDropTarget(
					<div style={{ margin: '4px' }}>
						<Chip style={styleChip} key={chipIndex} labelColor="#fff">
							{value.name} <i className="fa fa-times" aria-hidden="true" onClick={() => onTagDelete(chipIndex)} />
						</Chip>
					</div>
				)
			)
		} else if (type === 'source') {
			return (
				<div style={{ margin: '4px' }}>
					<Chip
						style={styleChip}
						key={chipIndex}
						labelColor="#fff"
						onClick={() => {
							addTag(value)
						}}
					>
						{value.name}
					</Chip>
				</div>
			)
		} else {
			return null
		}
	}
}

const suggestedStyle = {
	beginDrag(props) {
		return {
			name: props.name,
		}
	},
}

//TODO
@DragSource('suggestedStyle', suggestedStyle, (connect, monitor) => ({
	connectDragSource: connect.dragSource(),
	isDragging: monitor.isDragging(),
}))
class CategoryDnDSource extends Component {
	getSortableItems = (arr, factor = 0, styleChip, type) => {
		const { onTagDelete, onTagAdd, searchTxt } = this.props;

    if (type === collapseStylesType) {
      arr.sort(function(x,y) {
        return topMusicStyles.includes(x.name) ? -1 : topMusicStyles.includes(y.name) ? 1 : 0;
      });
    }

		return arr
			.filter(item => !searchTxt || item.name.toLowerCase().includes(searchTxt.toLowerCase()))
			.map((value, index) => (
				<CategoryDnDStyle
					key={`item-${index + factor}`}
					index={index}
					chipIndex={index + factor}
					styleChip={styleChip}
					value={value}
					type={'source'}
					copy={true}
					addTag={onTagAdd}
					onTagDelete={onTagDelete}
				/>
			))
	}

	getEmptyChips = len => {
		const width = 100 / len - 1

		return new Array(len).fill('').map((val, index) => (
			<Chip
				key={index}
				style={{
					width: `${width}%`,
					height: '2rem',
					background: '#fff',
					border: '1px solid #a3a3a3',
				}}
			/>
		))
	}

	render() {
		//TODO connectDragSource
		const { availableStyles, startIndex, typeProfile } = this.props;

    const type = !!availableStyles.length ? _.get(availableStyles[0], 'type') : false;
		return (
			<div className="band-genre-form__container d-flex flex-wrap at-styles-block">
				{!!availableStyles.length && (
					<div>
						<h5>List of {getTextForProfileType('registration', 'topFiveItems', typeProfile).toLowerCase()}</h5>
						{this.getSortableItems(availableStyles, startIndex, { backgroundColor: '#a2a2a2', fontWeight: '500' }, type)}
					</div>
				)}
			</div>
		)
	}
}

@DropTarget('style', styleTarget, connect => ({
	connectDropTarget: connect.dropTarget(),
}))
class EmptyChipDropTarget extends Component {
	render() {
		const { index, style, width } = this.props

		return this.props.connectDropTarget(
			<div style={{ width: width, height: '1.875rem' }}>
				<Chip key={index} style={style} />
			</div>
		)
	}
}
