import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Paper from 'material-ui/Paper';
import Overlay from './ContentBlock/Overlay';
import LinearProgress from 'material-ui/LinearProgress';
import FlatButton from 'material-ui/FlatButton';

import '../styles/components/CustomAudioPlayer.scss';

export default class CustomAudioPlayer extends Component {
  ready = false;
  iframe = null;
  player = null;
  currentUrl = null;
  audioContext = null;

  constructor(props) {
    super(props);

    this.audioContext = new AudioContext;

    this.state = {
      iframeStyles: {
        opacity: 0,
      },
    };
  }

  componentDidMount() {
    console.log(SC)
    this.player = SC.Widget(this.iframe);

    this.player.bind(SC.Widget.Events.READY, this.playerReadyHandler);
    this.player.bind(SC.Widget.Events.PAUSE, this.playerPauseHandler);
    this.player.bind(SC.Widget.Events.PLAY_PROGRESS, this.playerPlayProgressHandler);
  }

  componentWillUnmount() {
    this.player.unbind(SC.Widget.Events.READY, this.playerReadyHandler);
    this.player.unbind(SC.Widget.Events.PAUSE, this.playerPauseHandler);
    this.player.unbind(SC.Widget.Events.PLAY_PROGRESS, this.playerPlayProgressHandler);
  }

  UNSAFE_componentWillReceiveProps(props) {
    if (this.props.media.content_url !== props.media.content_url) {
      if (this.state.isPlaying) {
        this.playPause();
      }

      this.ready = false;

      this.player.load(props.media.content_url, {
        callback: () => {
          this.ready = true;

          this.props.updateItem(props.media.index, null, null, 0);
        }
      });

    }
  }

  playerReadyHandler = () => {
    this.ready = true;

    const { index } = this.props.media;

    this.setState({
      iframeStyles: {
        top: '-9999px',
        left: '-9999px',
        position: 'absolute',
      },
    });

    this.player.getCurrentSound((song) => {
      if (this.props.updateItem) {
        this.props.updateItem(index, song.artwork_url.replace('-large.jp', '-crop.jp'));
      } else {
        this.setState({ artworkUrl: song.artwork_url.replace('-large.jp', '-crop.jp') });
      }
    });

    this.player.getDuration((duration) => {
      if (this.props.updateItem) {
        this.props.updateItem(index, null, duration);
      } else {
        this.setState({ duration });
      }
    });
  }

  playerPlayProgressHandler = () => {
    this.player.getPosition((position) => {
      position = position / this.state.duration * 100;

      if (this.props.updateItem) {
        this.props.updateItem(
          this.props.media.index,
          null,
          null,
          position
        );
      } else {
        this.setState({ position });
      }
    });
  }

  playPause = e => {
    //var AudioContext = window.AudioContext || window.webkitAudioContext;
    //var audioCtx = new AudioContext();

    // if (MIDI.WebAudio.getContext() && MIDI.WebAudio.getContext().state === 'suspended') {
    //     MIDI.WebAudio.getContext().resume().then(function() {
    //         console.log('audioContext was suspended, now resumed');
    //         playerControl();
    //     });
    // } else { // either not using webAudio or audioContext already running
    //     playerControl();
    // }

    if (this.ready) {
      const isPlaying = this.state.isPlaying;

      if (isPlaying) {
        this.player.pause();
      } else {
        console.log(this.player)
        this.player.play();
      }

      this.setState({
        isPlaying: !isPlaying,
      });
    }
  }

  playerPauseHandler = () => {
    this.setState({
      isPlaying: false,
    });
  }

  render() {
    const { media } = this.props;
    const { location, content_url } = media;

    let artworkUrl, position, duration;

    if (this.props.updateItem) {
      artworkUrl = media.artworkUrl;
      position = media.position;
      duration = media.duration;
    } else {
      artworkUrl = this.state.artworkUrl;
      position = this.state.position;
      duration = this.state.duration;
    }

    const {
      isPlaying,
      iframeStyles,
    } = this.state;

    duration = ((duration || 0) / 1000 / 60)
      .toFixed(2)
      .toString()
      .replace('.', ':');
    return (
      <Paper style={{ width: '100%', color: '#000' }} zDepth={1} className="custom-audio-player">
        <div className="custom-audio-player__wrapper">
          <div className="custom-audio-player__image-block">
            <img draggable="false" src={artworkUrl} />
          </div>
          <div className="custom-audio-player__content">
            <div>
              <div className="custom-audio-player__top">
                <button
                  onClick={e => {

                    if (!this.audioContext) {
                      this.audioContext = new AudioContext();
                    }
                    this.audioContext.resume().then(() => {
                      console.log('Playback resumed successfully');
                      const isPlaying = this.state.isPlaying;

                      if (this.state.isPlaying) {
                        this.player.pause();
                      } else {
                        console.log(this.player)
                        this.player.play();
                      }

                      this.setState({
                        isPlaying: !isPlaying,
                      });
                    });
                  }}
                  className="custom-audio-player__play-button">
                  {isPlaying ? <i className="fa fa-pause" aria-hidden="true"></i> :
                    <i className="fa fa-play" aria-hidden="true"></i>}
                </button>
                {media.title}
                <p className="custom-audio-player__title">{location}</p>
              </div>
              <div className="custom-audio-player__linear-wrapper">
                <LinearProgress
                  style={{
                    backgroundColor: '#e3e3e3',
                    margin: '0 1rem',
                    width: 'calc(100% - 2rem)'
                  }}
                  mode="determinate"
                  value={position} />
                <div className="custom-audio-player__linear-bottom">
                  <div className="custom-audio-player__linear-right">
                    <span>
                      {duration}
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className="custom-audio-player__overlay-wrapper">
              <Overlay media={media} hide={false} color={'#000'}></Overlay>
            </div>
            <iframe
              ref={ref => { this.iframe = ref; }}
              src={`//w.soundcloud.com/player/?url=${encodeURIComponent(content_url)}`}
              style={iframeStyles}
              allow="autoplay"
              className="sc-widget">
            </iframe>
          </div>
        </div>
      </Paper>
    );
  }
}

CustomAudioPlayer.propTypes = {
  media: PropTypes.object.isRequired,
};
