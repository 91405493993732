import './GroupFilter.scss';
import React, { Component } from 'react';
import { FilterInput } from '../common/FilterInput/FilterInput';
import { SelectWithStepsCategories } from '../common/SelectWithStepsCategories/SelectWithStepsCategories';

export class GroupFilter extends Component {
  render() {
    const { value, onChange } = this.props;

    return (
      <div className='GroupFilter'>
        <FilterInput onChange={(e) => onChange({ queryText: e.target.value })}/>
        <SelectWithStepsCategories
          value={value}
          onChange={onChange}
          categoryId={null}
        />
      </div>
    );
  }
}
