import React, { Component } from 'react'
import FlatButton from 'material-ui/FlatButton'
import Chip from 'material-ui/Chip'
import { dropdownQuestions } from '../../../utils/constants'

import './ProfileAbout.scss'

export default class ProfileAbout extends Component {
	constructor(props) {
		super(props)

		this.state = {
			isReadMore: false,
		}
	}

	viewFile = url => {
		window.open(url)
	}

	switchReadMore = () => this.setState({ isReadMore: !this.state.isReadMore })

	collapseString(str) {
		return (str.length <= 30 || this.state.isReadMore) ? str : str.substring(0, 30) + '...'
	}

	getAnswer(answer) {
		const questions = [ ...dropdownQuestions.artistQuestions, ...dropdownQuestions.venueQuestions, ...dropdownQuestions.bandQuestions, ...dropdownQuestions.groupQuestions ]

		const question = questions.find(item => item.value === answer.question)
		const collapsedAnswer = this.collapseString(answer.answer)

		if(question) {
			if(question.value % 100 !== 99) {
				return <p><span className="question">{`Q: ${question.label}`}</span> <br /> {collapsedAnswer}&nbsp;</p>
			}
			else {
				return <p>{collapsedAnswer}&nbsp;</p>
			}
		}
	}

	displayReadMore = () => {
		const { profile } = this.props

		if(profile.questions_answers && profile.questions_answers.length > 0) {
			return profile.questions_answers.some(item => item.answer.length > 30) ? null : 'none'
		}
		else if(profile.about) {
			return (profile.about.length > 300) ? null : 'none'
		}

		return 'none'
	}

	render() {
		const { profile } = this.props

		return (
			<div>
				<section className="about">
          <div className="about__header">
            <h1 className="about__title">Brief description</h1>
          </div>
          <div>
            <p className="about__copy">
              {profile.brief_introduction}
            </p>
          </div>
					<header className="about__header">
						<h1 className="about__title">About</h1>
						{profile.cv && (
							<a className="about__link" style={{ cursor: 'pointer' }} onClick={() => this.viewFile(profile.cv)}>
								View CV <i className="fa fa-arrow-down" aria-hidden="true" />
							</a>
						)}
					</header>
					<div>
						<div className="about__copy">
							{(profile.questions_answers && profile.questions_answers.length > 0) ?
								(profile.questions_answers.map(answer => this.getAnswer(answer))) :
								(profile.about
									? String(profile.about).length > 300
										? this.state.isReadMore
											? profile.about
											: profile.about.substring(0, 300) + '...'
										: profile.about
									: '---'
								)
							}
							<span>
								<FlatButton
									style={{
										lineHeight: 'auto',
										height: 'auto',
										padding: '0 2px',
										margin: '0 2px',
										minWidth: '0',
										display: this.displayReadMore(),
									}}
									labelStyle={{
										color: '#ffc107',
										fontWeight: '500',
										fontSize: '0.75rem',
										textTransform: 'normal',
										padding: '0',
									}}
									label={this.state.isReadMore ? 'Read less' : 'Read more'}
									onClick={this.switchReadMore}
								/>
							</span>
							<div className="d-flex flex-wrap about__copy_style">
								{profile.tags.map((tag, index) => (
									<Chip key={'tag' + index} style={{ margin: '4px' }}>
										{tag.content}
									</Chip>
								))}
							</div>
						</div>
					</div>
				</section>
			</div>
		)
	}
}
