import React, { Component } from 'react'
import { Field, reduxForm } from 'redux-form'
import validate from '../../../helpers/validators/company/CompanyContactInfo'
import { connect } from 'react-redux'
import { getValues } from '../../../helpers/methods'
import { Gender, FileInput } from '../CommonComponent'
import {
  TextFieldComponent,
  GoogleMapsAutocomplete,
  BirthDatePickerComponent,
  PhoneComponent,
  SelectFieldComponent,
  CheckboxComponent
} from '../../CommonComponent'
import { Asterisk } from '../../common/Asterisk/Asterisk'
import { registrationConfig } from '../../../config'
// import { nationalities } from '../../../utils/constants'
import { getCountriesTree } from '../../../redux/modules/dashboard'
import Loader from '../../discover/Loader/Loader'

//TODO field names
class CompanyContactInfo extends Component {
    componentDidMount() {
        this.props.getCountriesTree()
    }

	render() {
		const { handleSubmit, touch } = this.props

		return (
			<form className="personal-info" onSubmit={handleSubmit}>
				<div className="personal-info__fields">
					<div className="personal-info__fields">
						<div className="personal-info__info">
							<span className="fa-stack info-popup-window" data-title={registrationConfig.popupText.companyYourCompany}>
								<i className="fa fa-circle-thin fa-stack-2x" />
								<i className="fa fa-info fa-stack-1x" />
							</span>
							Your company <Asterisk />
						</div>
						<Field
							name="name"
							type="text"
							style={{ margin: '10px 0 5px' }}
							fullWidth={true}
							component={TextFieldComponent}
							hintText="Company Name"
						/>
						<Field
							name="company_type"
							type="text"
							style={{ margin: '10px 0 5px' }}
							fullWidth={true}
							component={TextFieldComponent}
							hintText="Company Type (LLC, Ltd, GmbH)"
						/>
					</div>
					<div className="personal-info__info-big-title">
						<span
							className="fa-stack info-popup-window"
							data-title={registrationConfig.popupText.companyMenagerRepresentative}
						>
							<i className="fa fa-circle-thin fa-stack-2x" />
							<i className="fa fa-info fa-stack-1x" />
						</span>
						Manager or Representative info
					</div>
					<div className="personal-info__fields">
						<div className="personal-info__info">
							Legal name <Asterisk />
						</div>
						<Field
							name="manager_first_name"
							type="text"
							style={{ margin: '10px 0 5px' }}
							fullWidth={true}
							component={TextFieldComponent}
							hintText="First Name"
						/>
						<Field
							name="manager_last_name"
							type="text"
							style={{ margin: '10px 0 5px' }}
							fullWidth={true}
							component={TextFieldComponent}
							hintText="Last Name"
						/>
					</div>
					<div className="personal-info__fields">
						<div className="personal-info__info">Title</div>
						<Field
							name="manager_company_title"
							type="text"
							style={{ margin: '10px 0 5px' }}
							fullWidth={true}
							component={TextFieldComponent}
							hintText="What is the manager or representative's title within your company?"
						/>
					</div>

					<div className="personal-info__gender">
						<div className="personal-info__info d-flex align-items-center">
							<span>Gender</span>
							<span className="band-contact-info__optional" />
						</div>
						<div className="text-left">
							<Field name="manager_gender" component={Gender} />
						</div>
					</div>
					<div className="personal-info__nationality">
						<div className="personal-info__info">
							Nationality <Asterisk />
						</div>
						<div className="personal-info__nationality" style={{ width: '100%' }}>
                            {(this.props.countriesList && this.props.countriesList.length) ?
                                <Field
                                    name="manager_nationality"
                                    type="string"
                                    required={true}
                                    fullWidth
                                    style={{ height: '3rem', textAlign: 'left' }}
                                    labelStyle={{ fontSize: '1.5rem', color: '#000', padding: '0' }}
                                    options={this.props.countriesList}
                                    hintText="Please select your nationality"
                                    component={SelectFieldComponent}
                                />
                                :
                                <div className="d-flex justify-content-center">
                                    <Loader />
                                </div>
                            }
						</div>
					</div>
					<div className="personal-info__date">
						<div className="personal-info__info">
							Date of birth <Asterisk />
						</div>
						<div className="personal-info__date-of-birth" style={{ width: '100%' }}>
							<Field name="manager_birth_date" hintText="Birth day" component={BirthDatePickerComponent} />
						</div>
            <div className="mt-3">
              <Field
                name="over18"
                label={<>I confirm that I'm over 18 years old <Asterisk /></>}
                labelStyle={{ width: 'initial'}}
                component={CheckboxComponent}
              />
            </div>
					</div>
					<div className="personal-info__contact-information">
						<div className="personal-info__info">
							<span className="fa-stack info-popup-window" data-title={registrationConfig.popupText.companyContact}>
								<i className="fa fa-circle-thin fa-stack-2x" />
								<i className="fa fa-info fa-stack-1x" />
							</span>
							Contact Information <Asterisk />
						</div>
						<div className="contact-information__select-wrapper google-location-input" style={{ width: '100%' }}>
							<Field
								makeTouched={() => touch('manager_location')}
								onBlur={() => touch('manager_location')}
								fullWidth
								name="manager_location"
								component={GoogleMapsAutocomplete}
								hintText="Enter Address"
								hintStyle={{
									fontWeight: '300',
									color: '#a3a3a3',
								}}
								style={{
									textAlign: 'left',
									marginRight: '20px',
								}}
							/>
              {(this.props.phoneCodeList && this.props.phoneCodeList.length) ?
                <Field
                  name="manager_phone_number"
                  additional_name="manager_country_code"
                  style={{ margin: '10px 0 5px' }}
                  fullWidth
                  component={PhoneComponent}
                  countries={this.props.phoneCodeList}
                />
                :
                <div className="d-flex justify-content-center">
                  <Loader />
                </div>
              }
						</div>
					</div>
					<div className="curriculum-vitae-form__item">
						<div className="curriculum-vitae-form__item-header">
							<h4 className="curriculum-vitae-form__title d-flex align-items-center">
								<span>Curriculum Vitae</span> <span className="band-contact-info__optional">(optional)</span>
							</h4>
						</div>
						<div className="curriculum-vitae-form__file-wrapper">
							<p className="curriculum-vitae-form__text">
								After you upload you file, you can make a shape with our edit tool to further hide your contact
								information. Make sure no contact information, including address, email or phone number, is visible on
								your CV.
							</p>
							<Field
								style={{ display: 'none' }}
								name="cv"
								type="file"
								accept=".pdf"
								label="Upload CV"
								showValidationError={this.props.showValidationError}
								component={FileInput}
							/>
						</div>
					</div>
				</div>
			</form>
		)
	}
}

CompanyContactInfo = reduxForm({
	form: 'CompanyContactInfo',
	destroyOnUnmount: false,
	forceUnregisterOnUnmount: false,
	enableReinitialize: true,
	validate,
})(CompanyContactInfo)

//TODO fields
export default connect(({ user, form, dashboard }) => ({
   currentUser: user.currentUser,
  countriesList: dashboard.countriesList,
  phoneCodeList: dashboard.phoneCodeList,
  initialValues: getValues(
		form.CompanyContactInfo,
		user.currentUser && user.currentUser.profile
			? {
		    name: user.currentUser.profile.name.trim(),
        company_type: user.currentUser.profile.company_type,
        manager_first_name: user.currentUser.profile.manager_first_name,
        manager_last_name: user.currentUser.profile.manager_last_name,
        manager_company_title: user.currentUser.profile.manager_company_title,
        manager_gender: user.currentUser.profile.manager_gender,
        manager_nationality: String(user.currentUser.profile.nationality),
        manager_location: user.currentUser.profile.manager_location || {},
        manager_birth_date: user.currentUser.profile.manager_birth_date,
        over18: !!user.currentUser.profile.over18,
        manager_phone_number: user.currentUser.profile.manager_phone_number,
        manager_country_code: user.currentUser.profile.manager_phone_number ? user.currentUser.profile.manager_phone_number.country_code : null,
      }
			: {}
	),
}), {
    getCountriesTree
})(CompanyContactInfo)
