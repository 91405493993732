/*global require */

import './ProfileCard.scss'
import React, { Component } from 'react'
import PropTypes from 'prop-types'

import { Link } from 'react-router-dom'
import Rating from 'react-rating'
import SVGInline from 'react-svg-inline'
import * as _ from 'lodash'
import { IconButton, Popover } from 'material-ui'
import classNames from 'classnames'
import { getCurrentProfile, getCurrentUser } from '../../../redux/modules/user'
import { addToFavorites, removeFromFavorites } from '../../../redux/modules/favorites'
import { addItemToCompare, removeItemFromCompare } from '../../../redux/modules/compare'
import { connect } from 'react-redux'
import { UserPic } from '../../common/UserPic/UserPic'
import MediaBlock from '../../ContentBlock/MediaBlock'
import block from 'bem-cn'
import getSymbolCurrency from '../../../helpers/getSymbolCurrency'
import SharedPopUpSocialNetworks from './SharedPopUpSocialNetworks/SharedPopUpSocialNetworks'
import 'antd/es/notification/style/css'
import LikeButton from '../LikeButton/LikeButton';

const svg_heart = require('!!svg-inline-loader?classPrefix!../../../assets/icon-1.svg');
const svg_heart_fill = require('!!svg-inline-loader?classPrefix!../../../assets/GW_Heart.svg');
// const svg_heart = require('!!svg-inline-loader?classPrefix!../../../assets/heart-icon-1.svg')
const svg_compare = require('!!svg-inline-loader?classPrefix!../../../assets/icon-2.svg');
const svg_message = require('!!svg-inline-loader?classPrefix!../../../assets/icon-3.svg');
const svg_share = require('!!svg-inline-loader?classPrefix!../../../assets/icon-4.svg');
const svg_plane = require('!!svg-inline-loader?classPrefix!../../../assets/plane.svg');
const svg_clap = require('!!svg-inline-loader?classPrefix!../../../assets/icons/clap.svg');
const svg_chair = require('!!svg-inline-loader?classPrefix!../../../assets/icons/chair.svg');

const b = block('ProfileCard');

@connect(
	state => ({
		currentUser: state.user.currentUser,
		countriesList: state.common.countriesList,
		itemsToCompare: state.compare.itemsToCompare,
		isAuthorized: state.auth.isAuthorized,
	}),
	{
		getCurrentUser,
		getCurrentProfile,
		addToFavorites,
		removeFromFavorites,
		addItemToCompare,
		removeItemFromCompare,
	}
)
/**
 * @param onLike optional param as callback when favorite change
 */
export default class ProfileCard extends Component {
	static propTypes = {
		// onLike: PropTypes.func,
		onReview: PropTypes.func,
		profile: PropTypes.object,
		theme: PropTypes.string,
		coverComponent: PropTypes.element,
		size: PropTypes.oneOf(['default', 'large', 'small']),
	};

	constructor(props) {
		super(props);
		this.state = {
			location: this._location,
			isPlaying: false,
			openedSharePopUP: false,
		}
	}

	componentDidMount() {
		const countries = _.get(this.props, 'profile.countries', false);

		if(countries) {
			let location = countries.map(country => country.name).join(", ");
			this.setState({ location })
		}
	}

	_card = null;
	_location = '---';

	compare = () => {
		const { itemsToCompare, addItemToCompare, removeItemFromCompare, profile } = this.props;

		itemsToCompare.find(x => profile.id === x.id) ?
			removeItemFromCompare(profile) :
			addItemToCompare(profile)
	};

	like = () => {
		const {
			profile: { id },
			addToFavorites,
			removeFromFavorites,
			isAuthorized,
			onLike,
		} = this.props;

		if (!isAuthorized) return;

		//todo move it to store
		if (this.isFavorite()) {
			removeFromFavorites(id);
			onLike && onLike({ nextValue: false })
		} else {
			addToFavorites(id);
			onLike && onLike({ nextValue: true })
		}
	};

	isFavorite = () => _.get(this.props, 'profile.is_favorite', false);

	review = () => {
		if (this.props.onReview) {
			this.props.onReview()
		}
	};

	share = () => {
		this.setState({
			openedSharePopUP: !this.state.openedSharePopUP,
		})
	};

	onVideoPlayClick = event => {
		event.preventDefault();
		this.setState({ anchorEl: this._card, isPlaying: true })
	};
	onStopPlaying = e => {
		this.setState({ isPlaying: false })
	};

	onClosePopUp = () => {
		this.setState({ isPlaying: false })
	};

	computedName() {
		const profile = this.props.profile;

		if (profile.profile_subtype === 'company') {
			return `${_.get(this.props, 'currentUser.profile.manager_first_name')} ${_.get(this.props, 'currentUser.profile.manager_last_name')}`
		} else if (profile.name) {
		  return profile.name
		}
		else if (profile.first_name || profile.last_name) {
		  return [profile.first_name, profile.last_name].join(" ")
		} else {
		  return ''
		}
	}

	isSelected = () => {
		const { profile, itemsToCompare } = this.props;
		return !!itemsToCompare.find(x => x.id === profile.id);
	};

	getCategories() {
	  const { profile } = this.props;
    const profile_type = _.get(profile, 'profile_type');
    const profile_subtype = _.get(profile, 'profile_subtype');

    if(profile_type) {
      if(profile_type === 'client') {
        return (profile_subtype === 'basic') ? profile.profession : profile.manager_company_title
      } else {
        return `${profile.categories &&
          profile.categories
            .filter(category => !['venue_occasion', 'artist_occasion'].includes(category.type))
            .filter((v, i, a) => a.findIndex(val => val.name == v.name) === i) // unique names
            .map(category => category.name).join(', ')}`
      }
    }
	}

	buildUsernameForUrl = (name) => {
	  return name.replace(/\s/g, '-');
  };

	render() {
		const { currentUser, profile, theme, size, coverComponent, style, isAuthorized, history, openInNewTab = true } = this.props;
    const selected = this.isSelected();
    const location = _.get(profile, 'location', _.get(profile, 'business_locations', null));
		const userId = _.get(profile, 'id');
		const isComplete = _.get(profile, 'is_complete');

		let username = '';
		if (_.get(profile, 'url_name')) {
		  username = this.buildUsernameForUrl(_.get(profile, 'url_name'));
    }

    const domain = process.env.REACT_APP_URL;
    const currentUrl = `${domain}/profile/${username}`

    return (
			<div style={style}
				className={classNames(b(), { selected }, theme || 'dark', size || 'default')}
				ref={r => { this._card = r }}
			>

				{/* <div style={{ backgroundColor: 'lightgreen', position: 'absolute', zIndex: 900, top: 0, height: '50px', width: '90px' }}>
					{profile.profile_type}, <br /> {profile.profile_subtype}
				</div> */}
				{coverComponent ? (
					<div className="preview-cover preview-cover-component">
						{coverComponent}
					</div>
				) : (
					<div className="preview-cover">
						<img src={_.get(profile, 'card_img.url')} alt=" " style={{width:'100%'}}/>
					</div>
				)}
				<div className="preview-card">
          {
            isComplete && username ?
              <Link
                to={`/profile/${username}`}
                {...(openInNewTab ? {target: '_blank'} : {})}
                className="profile-link-overlay"
                onClick={e => {
                  !userId && e.preventDefault()
                }}
              /> : null
          }
					<div className="button-group">
            <LikeButton
              targetId={userId}
              defaultValue={this.isFavorite()}
              white
            />
						{/* <IconButton className="like-btn" onClick={() => isAuthorized ? this.like : history.push('/login')}>
							{this.isFavorite() ? (
								<SVGInline svg={svg_heart_fill} fill={'#f00'} width={'1rem'} className={'in_favorite'} />
							) : (
								<SVGInline svg={svg_heart} fill={'#fff'} width={'1rem'} className={'not_favorite'} />
							)}
						</IconButton> */}
						<IconButton className="compare-btn" onClick={this.compare}>
							<SVGInline svg={svg_compare} fill={selected ? '#ffd210' : '#ffffff'} width={'1rem'} />
						</IconButton>
            <IconButton className="share-btn" onClick={this.share} urlToShare={currentUrl}>
              <SVGInline svg={svg_share} fill="#ffffff" />
            </IconButton>          </div>
					<div className="info">
						<div className="description">
							<div className="fee">
								{_.get(profile, 'fee_primary') ? (
									<span>
										<b>
											{profile.fee_primary.value} {getSymbolCurrency(profile.fee_primary.currency)}
										</b>
								{profile.fee_primary.value && <span>/</span>} {profile.fee_primary.period}
									</span>
								) : null}
							</div>
							<div className="name">
								<b>{this.computedName()}</b>
							</div>
							<div className="categories">
								{_.get(profile, 'profile_type') === 'client' ? _.get(profile, 'name', _.get(profile, 'profession')) : this.getCategories()}
							</div>
							<div className="location">
								{(() => {
									if (_.get(currentUser, 'profile_subtype') === 'company') {
										return `${_.get(currentUser, 'profile.manager_location.city', '')}, ${_.get(currentUser, 'profile.manager_location.country', '')}`
									} else if (_.get(currentUser, 'profile_type') === 'client') {
										return `${_.get(currentUser, 'profile.location.city', '')}, ${_.get(currentUser, 'profile.location.country', '')}`
									}

									return location ? `${_.get(location, 'city', '')}, ${_.get(location, 'country', '')}` : ''
								})()}
							</div>
							<div className="rating">
								<Rating
									empty="fa fa-star-o fa-fw"
									initialRate={+_.get(profile, 'average_rating') || 0}
									readonly
									full="fa fa-star fa-fw"
								/>
							</div>
							{
								(() => {
									if (_.get(profile, 'profile_type') === 'venue') {
										return <div className="venue-place">
											<span>
												{_.get(profile, 'capacity.standing', '---')}ppl
												<i className="fa fa-male" />
											</span>
											<span>
                                                {_.get(profile, 'capacity.seated', '---')}ppl
												<SVGInline svg={svg_chair} fill="#fff" width={'18px'} height={'18px'} />
											</span>
										</div>
									} else if (_.get(profile, 'profile_type') !== 'client') {
										return <div className="availability">
											<span>{this.state.location ? this.state.location : this._location}</span>
											<SVGInline svg={svg_plane} fill="#ffffff" width={'1.25rem'} />
										</div>
									}
								})()
							}
						</div>
						<UserPic src={_.get(profile, 'avatar')} theme={theme} primary={theme === 'light'} profileCompleted ={true}>
							<i className="fa fa-play" onClick={this.onVideoPlayClick} />
						</UserPic>
					</div>
				</div>
				{username && this.state.openedSharePopUP && (
					<SharedPopUpSocialNetworks
						share={this.share}
						urlToShare={`${window.location.origin}/profile/${username}`}
					/>
				)}
				<Popover
					style={{
						width: this.state.anchorEl && this.state.anchorEl.clientWidth * 2 + 24,
						height: this.state.anchorEl && this.state.anchorEl.clientHeight + 2,
					}}
					canAutoPosition
					autoCloseWhenOffScreen
					anchorEl={this.state.anchorEl}
					open={this.state.isPlaying}
					anchorOrigin={{
						vertical: 'top',
						horizontal: 'left',
					}}
					targetOrigin={{
						vertical: 'top',
						horizontal: 'left',
					}}
					className={b('popover')}
					onRequestClose={this.onStopPlaying}
				>
					<MediaBlock
						playing
						loop
						media={{
							type: 'video',
							src: _.get(profile, 'card_video_url'),
							content_url: _.get(profile, 'card_video_url'),
							clapQuantity: 0,
							commentQuantity: 0,
							shareQuantity: 0,
							tags: [],
							location: '',
						}}
					/>
					<button className={b('close-popup') + ' close'} onClick={this.onClosePopUp}>
						<span aria-hidden="true">×</span>
					</button>
					{/* <UserPic src={_.get(profile, 'avatar')} theme={theme} primary={theme === 'light'} /> */}
				</Popover>
			</div>
		)
	}
}

ProfileCard.propTypes = {
	countriesList: PropTypes.array.isRequired,
	addToFavorites: PropTypes.func.isRequired,
	removeFromFavorites: PropTypes.func.isRequired,
	isAuthorized: PropTypes.bool.isRequired,
};
