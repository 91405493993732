import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { reduxForm, Field } from 'redux-form';
import { ShowPassword } from '../components';
import SVGInline from 'react-svg-inline';
import {Link} from 'react-router-dom';
import { translate } from '../translator';
import { Checkbox } from './CommonComponent';

const doneMark = require('!!svg-inline-loader?classPrefix!./../assets/login-done-mark.svg');

@reduxForm({
  form: 'loginForm',
  fields: ['email', 'password']
})

export default class LoginForm extends Component {
  static propTypes = {
    fields: PropTypes.array.isRequired,
    submitting: PropTypes.bool.isRequired,
    pristine: PropTypes.bool.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    onSingUp: PropTypes.func.isRequired,
    onForgotPassword: PropTypes.func.isRequired,
  };

  constructor (props) {
    super(props);

    this.state = {
      showPassword: false,
      rememberMe: false,
    };
  }

  setPasswordVisibility = (showPassword) =>{
    this.setState({showPassword});
  };

  //TODO
  toggleRememberMe = ()=>{
    const { rememberMe } = this.state;

    this.setState({
      rememberMe: !rememberMe
    });
  };

  render () {
    const {
      handleSubmit,
      submitting,
      pristine,
      onSingUp,
      onForgotPassword,
      onFieldsUpdate,
    } = this.props;

    const {
      showPassword,
      rememberMe
    } = this.state;

    return (
      <form className="login-form"
        onSubmit={handleSubmit}>
        <div className="form-header">
          <p className="description">
          {translate('loginfrom.loginFrom')}
          </p>
          <div className="remember-me"
            onClick={this.toggleRememberMe}>
            <Checkbox
                style={{ width: '20px', marginTop: '-4px' }}
                checked={rememberMe}
                onChange={(_, value) => this.setState({ rememberMe: value })}
            />
            <span className={`toggle-text ${(rememberMe) ? 'selected' : ''}`}>
            {translate('loginfrom.rememberMe')}
            </span>
          </div>
        </div>
        <Field name="email"
          type="text"
          component="input"
          className="input-field"
          onChange={(event) => onFieldsUpdate(event.target.value.length > 0)}
          placeholder="Email" />
        <div className="password-field">
          <Field name="password"
            type={showPassword ? 'text' : 'password'}
            component="input"
            className="input-field"
            onChange={(event) => onFieldsUpdate(event.target.value.length > 0)}
            placeholder={translate('loginfrom.password')} />
          <ShowPassword isShown={showPassword}
            toggleView={this.setPasswordVisibility} />
        </div>
        <div className="form-footer">
          <p className="additional">
            <span className="sing-up">
            {translate('loginfrom.questions')} <Link to="/register"><b>{translate('loginfrom.singUp')}</b></Link>
            </span>
            <span>
              <a className="forgot-password"
                onClick={onForgotPassword}>
                {translate('loginfrom.forgetPassword')}
              </a>
            </span>
          </p>
          <p className="terms">
          {translate('loginfrom.terms')}
          </p>
        </div>
        <button className="btn btn-block login-btn"
          disabled={submitting || pristine}
          type="submit">
          <b>{translate('loginfrom.logIn')}</b>
        </button>
      </form>
    );
  }
}
