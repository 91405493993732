/*global require */

import React, { Component } from 'react'

import PropTypes from 'prop-types'
import * as _ from 'lodash'
import { connect } from 'react-redux'
import { compose } from 'recompose'

import moment from 'moment'

import { Field, reduxForm } from 'redux-form'
import isEmpty from 'lodash/isEmpty'
import get from 'lodash/get'

import TextField from 'material-ui/TextField'
import Checkbox from 'material-ui/Checkbox'

import SkyLight from 'react-skylight'

import './ContractPreview.scss'

const logo = require('../../../../assets/images/gigworks.png')

import {
	getContract,
	updateContractTerms,
	getFrame,
	sendContract,
	addGuestsList,
} from '../../../../redux/modules/contracts'
import { gigStageUp } from '../../../../redux/modules/gig'
import { getCategories } from '../../../../redux/modules/common'

import EditTerms from './EditTerms'

class ContractPreview extends Component {
	state = {
		isAddAdditionalTermsOpen: false,
		title: '',
		description: '',
		titleErrorText: '',
		descriptionErrorText: '',
		text: '',
	}

	componentDidMount() {
		const {
			getContract,
			categories,
			getCategories,
		} = this.props

		if (isEmpty(categories)) {
			getCategories()
		}

		const hash = _.get(this.props, 'match.params.hash', this.props.hash)
		getContract(hash)
	}
	UNSAFE_componentWillReceiveProps(nextProps) {
		if (!isEmpty(nextProps.contract)) {
			this.setState({
				text: nextProps.contract.data.guests,
			})
		}
	}

	handlers = []

	onSubmit = (values, event) => {}

	checkboxClickHandler = name => {
		if (!this.handlers[name]) {
			this.handlers[name] = event => {
				this.setState({ [name]: !this.state[name] })
			}
		}

		return this.handlers[name]
	}

	addTerm = () => {
		const {
			contract: {
				data: { terms },
				id,
			},
			updateContractTerms,
		} = this.props
		const { title, description } = this.state

		const number = terms ? Math.max(...Object.keys(terms)) : 0

		if (title === '') {
			this.setState({ titleErrorText: 'This field is required.' })
		}
		if (description === '') {
			this.setState({ descriptionErrorText: 'This field is required.' })
		}

		if (title !== '' && description !== '') {
			updateContractTerms(id, number + 1, title, description).then(() =>
				this.setState({ isAddAdditionalTermsOpen: false })
			)
		}
	}

	showModal = () => {
		const {
			contract: {
				hash,
				data: {
					gig: { id },
				},
			},
			history,
			sendContract,
		} = this.props;

		sendContract(hash)
			.then(res => {
				history.push(`/dashboard/mygigs/created/${id}/`)
			})
			.catch(err => {
				console.log(err)
			})
	};
	_executeAfterModalClose = () => {};

	_onBlur = event => {
		const {
			contract: { id },
			addGuestsList,
		} = this.props;

		let guestsList = event.target.value;

		if (guestsList > 9) {
			this.guestsNumber.value = 9;
			guestsList = 9
		}

		addGuestsList(id, guestsList)
	};

	_onGestsListChange = event => {
		const re = '/^[1-9]$/';

		if (
			event.target.value === '' ||
			(event.target.value.length < 2 && (+event.target.value >= 0 && +event.target.value <= 9))
		) {
			this.setState({ text: event.target.value })
		}
	};

	render() {
		const { handleSubmit, contract, isGettingContract, categories, frameUrl } = this.props;

		const { terms_agreement, contract_agreement } = this.state;

		const myBigGreenDialog = {
			width: '70%',
			height: '600px',
			marginTop: '-300px',
			marginLeft: '-35%',
		};

		if (isGettingContract || isEmpty(contract) || isEmpty(categories)) {
		  return <div>Loading...</div>
    }

		return (
			<div className="contract_preview">
				<div className="contract_main">
					<div className="contract_logo">
						<img src={logo} alt="" />
						<div className="site">www.Gigworks.com</div>
					</div>
					<div className="contract_number">
						<div className="contract_number_title">Contract #{contract.hash}</div>
						<div className="contract_number_description">{contract.data.gig.name}</div>
					</div>

					<div className="blank_row_48" />

					<div className="contract_parties">Client</div>
					<div className="contract_parties">
						{contract.data.vendor.profile_type === 'artist' ? 'Artist' : 'Venue'}
					</div>

					<div className="blank_row_16" />

					<form onSubmit={handleSubmit(this.onSubmit)} className="main_form">
						<div className="client_info">
							<Field name="data.client.legal_name" label="Name:" hint="Type the Name" component={EditTerms} disabled />
							<Field
								name="data.client.company_name"
								label="Company Name/Artistic Name:"
								hint="Type the Company Name"
								component={EditTerms}
								disabled
							/>
							<Field
								name="data.client.location.formatted_address"
								label="Address:"
								hint="Type the Address"
								component={EditTerms}
								disabled
							/>
							<Field
								// TO SIE SCIAGA Z GIGA I JAK SIE ZAAKTUALIZUJE UŻYTKOWNIKA TAX_ID TO SIE TU NIE AKTUALIZUJE
								name="data.client.tax_id"
								label="Tax ID / VAT # / EIN #:"
								hint="Type the Tax information"
								component={EditTerms}
								disabled
							/>
						</div>

						<div className="vendor_info">
							<Field name="data.vendor.legal_name" label="Name:" hint="Type the Name" component={EditTerms} disabled />
							<Field
								name="data.vendor.company_name"
								label="Company Name:"
								hint="Type the Company Name"
								component={EditTerms}
								disabled
							/>
							<Field
								name="data.vendor.location.formatted_address"
								label="Address:"
								hint="Type the Address"
								component={EditTerms}
								disabled
							/>
							<Field
								name="data.vendor.tax_id"
								label="Tax ID / VAT # / EIN #:"
								hint="Type the Tax information"
								component={EditTerms}
								disabled
							/>
						</div>

						<div className="blank_row_32" />

						<div className="contract_data">
							<div className="dates">
								<div className="dates_row">
									<div className="title">Today‘s Date:</div>
									<div className="value">{moment(new Date()).format('MMM DD, YYYY')}</div>
								</div>
								<div className="dates_row">
									<div className="title">Date of Gig Performance:</div>
									<div className="value">{moment(contract.data.gig.date).format('MMM DD, YYYY')}</div>
								</div>
                <div className="dates_row">
                  <div className="title">Start time:</div>
                  <div className="value">{contract.data.gig.start_date ? moment(contract.data.gig.start_date, moment.ISO_8601).utc().format('LT') : "-"}</div>
                </div>
                <div className="dates_row">
                  <div className="title">Location:</div>
                  <div className="value">{contract.data.gig.location.formatted_address}</div>
                </div>
							</div>
							<div className="names">
								<div className="names_row">
									<div className="title">Gig Name:</div>
									<div className="value">{contract.data.gig.name}</div>
								</div>
								<div className="names_row">
									<div className="title">Vendor Service:</div>
									<div className="value">{get(categories[contract.data.gig.category_id], 'name', null)}</div>
								</div>
                <div className="names_row">
                  <div className="title">Service Period:</div>
                  <div className="value">{contract.data.gig.event_length + " " + contract.data.gig.event_length_unit + "s"}</div>
                </div>
							</div>
						</div>

						<div className="blank_row_32" />

						<h4 className="gig_description_header">Description of Gig Performance:</h4>
						<div className="blank_row_16" />
            <div className="gig_description">{contract.data.gig.description}</div>

            <div className="blank_row_32" />

            <h4 className="gig_description_header">Description of Special Requests:</h4>
            <div className="blank_row_16" />
            <div className="gig_description">{contract.data.gig.note}</div>

						<div className="blank_row_32" />

						<div className="addon">
							<div className="addon_title">Budget</div>
							<div className="addon_price">{`${contract.data.gig.budget}€`}</div>
						</div>
						{!isEmpty(contract.data.options) &&
							Object.values(contract.data.options).map((opt, index) => {
								return (
									<div key={`opt-${index}`} className="addon">
										<div className="addon_title">{opt.title}</div>
										<div className="addon_price">{`${opt.budget}€`}</div>
									</div>
								)
							})}
						<div className="hr">
							<hr />
						</div>
						<div className="addon_subtotal">
							<div className="addon_subtotal_title">Subtotal</div>
							<div className="addon_subtotal_price">
								{!isEmpty(contract.data.options)
									? Object.values(contract.data.options).reduce(
											(acc, current) => {
												return { budget: +acc.budget + +current.budget }
											},
											{ budget: contract.data.gig.budget }
									  ).budget + '€'
									: `${contract.data.gig.budget}€`}
							</div>
						</div>

						<div className="hr">
							<hr />
						</div>
						{!isEmpty(contract.data.terms) &&
							Object.values(contract.data.terms).map((key, index) => {
								return (
									<div key={`key-${index}`} className="terms_row">
										<h4 className="term_title">{key.title}</h4>
										<div className="term_description">{key.description}</div>
									</div>
								)
							})}
						{!isEmpty(contract.data.terms) && (
							<div className="hr">
								<hr />
							</div>
						)}

						<div className="blank_row_16" />
						{this.state.isAddAdditionalTermsOpen && (
							<div className="additional_term">
								<div className="additional_term_title">
									<TextField
										className="textFieldRenderedMaterial"
										floatingLabelText="Title"
										fullWidth
										hintStyle={{
											fontSize: '1rem',
										}}
										textareaStyle={{
											fontSize: '0.8rem',
											color: '#000 !important',
										}}
										hintText="Start writing here"
										floatingLabelStyle={{ color: 'grey' }}
										floatingLabelFocusStyle={{ color: 'blagreyck' }}
										floatingLabelShrinkStyle={{ color: 'black' }}
										id="additional_term_title"
										onChange={(_, v) => this.setState({ title: v, titleErrorText: '' })}
										errorText={this.state.titleErrorText}
									/>
								</div>
								<div className="additional_term_description">
									<TextField
										className="textFieldRenderedMaterial"
										floatingLabelText="Description"
										multiLine
										fullWidth
										hintStyle={{
											fontSize: '1rem',
										}}
										textareaStyle={{
											fontSize: '0.8rem',
											color: '#000 !important',
										}}
										hintText="Start writing here"
										rows={1}
										rowsMax={5}
										floatingLabelStyle={{ color: 'grey' }}
										floatingLabelFocusStyle={{ color: 'blagreyck' }}
										floatingLabelShrinkStyle={{ color: 'black' }}
										id="additional_term_description"
										onChange={(_, v) => this.setState({ description: v, descriptionErrorText: '' })}
										errorText={this.state.descriptionErrorText}
									/>
								</div>
								<div className="add_term" onClick={() => this.addTerm()}>
									Add
								</div>
								<div className="cancel_term" onClick={() => this.setState({ isAddAdditionalTermsOpen: false })}>
									Cancel
								</div>
							</div>
						)}

						{!(terms_agreement && contract_agreement) && (
							<div className="addTermBtnLi">
								<div
									className="addTermBtn"
									onClick={() => this.setState({ isAddAdditionalTermsOpen: !this.state.isAddAdditionalTermsOpen })}
								>
									+ Add Additional Contract Terms
								</div>
							</div>
						)}

						<div className="blank_row_16" />

						<div className="guest_list">
							<div>
								Guest List:
								<input
									ref={ref => (this.guestsNumber = ref)}
									onBlur={this._onBlur}
									className="input_guest_list"
									value={this.state.text}
									onChange={e => this._onGestsListChange(e)}
								/>
								<span> (# of free tickets available to vendor)</span>
							</div>
							<TextField
								className="textFieldRenderedMaterial"
								fullWidth
								hintStyle={{
									fontSize: '1rem',
								}}
								inputStyle={{
									fontSize: '0.8rem',
									color: '#000 !important',
									fontWeight: '400',
								}}
								floatingLabelStyle={{ color: 'grey' }}
								floatingLabelFocusStyle={{ color: 'blagreyck' }}
								floatingLabelShrinkStyle={{ color: 'black' }}
								onBlur={this._onBlur}
								disabled
							/>
						</div>

						<div className="blank_row_16" />

						<div className="cancelation_policy">
							<div>
								Cancellation Policy: <a href={process.env.PUBLIC_URL + "/VendorCancellationPolicy"}>{ contract.data.cancellation_policy }</a>{' '}
								<span> (Please view on cancellation tab for details)</span>
							</div>
						</div>

						<div className="blank_row_24" />

						<div className="terms_agreement">
							<Checkbox style={{ width: 'fit-content' }} onCheck={this.checkboxClickHandler('terms_agreement')} />
							<div className="list_of_terms_agreement">
								I have read and agree with the <a href={process.env.PUBLIC_URL + '/gigworks-terms.pdf'}>Terms and conditions</a>, <a href={process.env.PUBLIC_URL + '/gigworks-privacy.pdf'}>Privacy Policy</a> and{' '}
								<a href={process.env.PUBLIC_URL + "/VendorCancellationPolicy"}>Cancellation Policies</a> of Gigworks and Vendor
							</div>
						</div>
						<div className="contract_agreement">
							<Checkbox style={{ width: 'fit-content' }} onCheck={this.checkboxClickHandler('contract_agreement')} />
							<div className="list_of_contract_agreement">
								I agree to the above contract and <a href={process.env.PUBLIC_URL + '/gigworks-terms.pdf'}>1. Gigworks processing fee</a> (applicable to Client), or{' '}
								<a href={process.env.PUBLIC_URL + '/gigworks-terms.pdf'}>2. Gigworks commission fee</a> (applicable to Vendor).
							</div>
						</div>

						<div className="blank_row_32" />

						{terms_agreement &&
							contract_agreement && (
								<div className="signature_row">
									<div className="client_signature">
										<div className="client_name">Name: {contract.data.client.legal_name}</div>
										<div className="date">Date:</div>
										<div className="blank_row_32" />
										<div className="signature">Client Signature _____________________________________</div>
									</div>
									<div className="vendor_signature">
										<div className="vendor_name">Name: {contract.data.vendor.legal_name}</div>
										<div className="date">Date:</div>
										<div className="blank_row_32" />
										<div className="signature">Vendor Signature _____________________________________</div>
									</div>
								</div>
							)}

						<div className="send_contract">
							<button
								className="sendContractBtn"
								disabled={!terms_agreement || !contract_agreement}
								onClick={() => this.showModal()}
							>
								Send Contract
							</button>
							<SkyLight
								ref={ref => (this.simpleDialog = ref)}
								afterClose={this._executeAfterModalClose}
								dialogStyles={myBigGreenDialog}
							>
								<iframe
									src={frameUrl}
									style={{ position: 'absolute', top: '0', left: '0', height: '100%', width: '100%' }}
								/>
							</SkyLight>
						</div>

						<div className="blank_row_32" />
					</form>
          <div className="close-button" onClick={() => this.props.history.push(`/dashboard/mygigs/created/${this.props.contract.data.gig.id}/`)}>
            <i className="fa fa-times" aria-hidden="true" />
          </div>
				</div>
			</div>
		)
	}
}

// export default ContractPreview;

const mapStateToProps = state => {
	return {
		contract: state.contracts.contract,
		frameUrl: state.contracts.frameUrl,
		isGettingContract: state.contracts.isGettingContract,
		initialValues: state.contracts.contract,
		categories: state.common.categoriesMap,
	}
}

ContractPreview.propTypes = {
	match: PropTypes.object.isRequired,
	getContract: PropTypes.func.isRequired,
	contract: PropTypes.object.isRequired,
	updateContractTerms: PropTypes.func.isRequired,
	handleSubmit: PropTypes.func.isRequired,
	isGettingContract: PropTypes.func.isRequired,
	categories: PropTypes.object.isRequired,
	getCategories: PropTypes.func.isRequired,
	history: PropTypes.object.isRequired,
	sendContract: PropTypes.func.isRequired,
	addGuestsList: PropTypes.func.isRequired,
	frameUrl: PropTypes.string.isRequired,
}

export default compose(
	connect(
		mapStateToProps,
		{ getContract, updateContractTerms, getCategories, getFrame, gigStageUp, sendContract, addGuestsList }
	),
	reduxForm({
		form: 'cantractPreview',
		enableReinitialize: true,
		overwriteOnInitialValuesChange: true,
	})
)(ContractPreview)
