import React, { Component } from 'react';
import PropTypes from 'prop-types';


export default class ShareButton extends Component {
  static propTypes = {
    selected: PropTypes.bool,
    onSelect: PropTypes.func,
  };

  fireSelect = ()=>{
    const { onSelect } = this.props;
    onSelect();
  };

  render() {
    return (
      <div className="share-button"
        onClick={this.fireSelect}>
        {this.props.children}
      </div>
    );
  }
}
