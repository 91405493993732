import './SortBy.scss';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { DropDownMenu, MenuItem } from 'material-ui';

export class SortBy extends Component {
  static propTypes = {
    items: PropTypes.array,
    value: PropTypes.number,
    defaultValue: PropTypes.any,
    onChange: PropTypes.func,
    labelKey: PropTypes.string,
    valueKey: PropTypes.string
  };

  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this)
  }

  handleChange(_, index) {
    const { onChange } = this.props;
    onChange(index);
  }

  render() {
    const { value, items, style, wrapperStyle, onChange, ...restProps } = this.props;

    return <div className="SortBy" style={wrapperStyle}>
      <div className='d-flex justify-content-end align-items-center'>
        <span className="sort-title ">Sort by: </span>
        <DropDownMenu
          value={value}
          labelStyle={{ height: '48px', lineHeight: '48px', color: '#000', fontWeight: 600 }}
          underlineStyle={{ display: 'none' }}
          anchorOrigin={{ vertical: 'top', horizontal: 'right',}}
          targetOrigin={{ vertical: 'top', horizontal: 'right',}}
          className='dropdown'
          onChange={this.handleChange}
          style={style}
          {...restProps}
          >
          {items.map((option, index) =>
            <MenuItem
              key={index}
              value={index}
              primaryText={option.label}
              label={option.label}
            />
          )}
        </DropDownMenu>
      </div>
    </div>;
  }
}
