import './HomeProfile.scss'
import React from 'react'
import block from 'bem-cn'
import classNames from 'classnames'
import lozad from 'lozad'

const b = block('HomeProfile')

export const HomeProfile = props => {
	const { userId, urlName, image, name, location, description, className, profile_type = 'artist' } = props
	const imageRef = React.useRef(null);

  let username = '';
	if (urlName) {
	  username = urlName.replace(/\s/g, '-');
  }

	React.useEffect(() => {
		if (imageRef.current) {
			const observer = lozad(imageRef.current);
    		observer.observe();
		}
	}, []);

	return (
		<div className={classNames(className, b(), 'd-inline-block')}>
			<div className={classNames("image-mask", profile_type === 'venue' ? 'venue' : 'artist')}>
				<a href={`/profile/${username}`}>
					<img ref={imageRef} width="160" data-src={image} alt={name} />
				</a>
			</div>
			<div className={classNames("pseudo-image-mask", profile_type === 'venue' ? 'venue' : 'artist')} />
			<div className="info-wrapper mt-5">
				<h5 className="text-uppercase mt-2">{name}</h5>
				<p className="m-0">{location}</p>
				<hr className="pseudo-after-line m-0 mb-2" />
				<p className="m-0">{description}</p>
				<hr className="pseudo-after-line m-0 mb-2" />
				<div className="d-flex justify-content-between">
					<div className="d-flex justify-content-start">
						<a href="" className="d-inline-block">
							<img src={require('../../../../assets/applaud-icon.svg')} />
						</a>
						<a href="" className="d-inline-block ml-2">
							<img src={require('../../../../assets/comment-icon.svg')} />
						</a>
					</div>
					<div className="d-flex justify-content-end">
						<a href="" className="ml-2 float-right">
							<img src={require('../../../../assets/like-icon.svg')} />
						</a>
						<a href="" className="float-right" style={{ marginLeft: '0.5rem' }}>
							<img src={require('../../../../assets/share-icon.svg')} />
						</a>
					</div>
				</div>
			</div>
		</div>
	)
}
