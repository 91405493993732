export const GET_TRANSACTIONS_LIST_START = 'GET_TRANSACTIONS_LIST_START'
export const GET_TRANSACTIONS_LIST_SUCCESS = 'GET_TRANSACTIONS_LIST_SUCCESS'
export const GET_TRANSACTIONS_LIST_FAIL = 'GET_TRANSACTIONS_LIST_FAIL'

const initialState = {
	allTransactions: [],
	isGettingTransactionsList: false,
}

export default function transactions(state = initialState, action) {
	switch (action.type) {
		case GET_TRANSACTIONS_LIST_START:
			return {
				...state,
				isGettingTransactionsList: true,
			}

		case GET_TRANSACTIONS_LIST_SUCCESS:
			return {
				...state,
				isGettingTransactionsList: false,
				allTransactions: action.result.data,
			}

		case GET_TRANSACTIONS_LIST_FAIL:
			return {
				...state,
				isGettingTransactionsList: false,
			}

		default:
			return state
	}
}

export function getTransactions() {
	return {
		types: [GET_TRANSACTIONS_LIST_START, GET_TRANSACTIONS_LIST_SUCCESS, GET_TRANSACTIONS_LIST_FAIL],
		promise: client => client.get(`/api/billing/transfer-list`),
	}
}
