import React from 'react'

import { Row, Col } from 'reactstrap';

import * as _ from 'lodash';
import { connect } from 'react-redux';

import 'antd/es/radio/style/css'

import 'antd/es/button/style/css'

import 'antd/es/form/style/css'
import 'antd/es/input/style/css'

import './SelectBankAccountForm.scss';
import { Button as AntdButton } from 'antd';

import { IconCircle, Checkbox } from '../../../CommonComponent';
import Modal from '../../../common/Modal/Modal';
import Button from '../../../common/Button/Button';
import { getBankAccountsList } from '../../../../redux/modules/cards';
import Loader from '../../../discover/Loader/Loader';
import NewBankAccountForm from '../../Admin/Cards/NewBankAccountForm';


const CARD_EXPIRATION_REGEX = /^((0[1-9])|(1[0-2]))\/(\d{2})$/
const CARD_NUMBER_REGEX = /^(?:4[0-9]{12}(?:[0-9]{3})?|(?:5[1-5][0-9]{2}|222[1-9]|22[3-9][0-9]|2[3-6][0-9]{2}|27[01][0-9]|2720)[0-9]{12}3[47][0-9]{13}|3(?:0[0-5]|[68][0-9])[0-9]{11}6(?:011|5[0-9]{2})[0-9]{12}|(?:2131|1800|35\d{3})\d{11})$/
const CARD_CVN_REGEX = /^[0-9]{3,4}$/

@connect(({ cards }) => ({
    bankAccountsList: cards.bankAccountsList,
    isFetchingBankAccounts: cards.isFetchingBankAccounts,
}), { getBankAccountsList })
class SelectBankAccountForm extends React.PureComponent {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        const { bankAccountsList, getBankAccountsList } = this.props;

        if (!bankAccountsList || bankAccountsList.length === 0) {
            getBankAccountsList();
        }
    }

    handleChange = value => {
        const { onChange } = this.props;
        onChange(value);
    }   
    
    render() {
        const { bankAccountsList, value, isFetchingBankAccounts, alwaysSave } = this.props;

        if (isFetchingBankAccounts) return <div className="SelectBankAccountForm">
            <Loader />
        </div>

        return <div className="SelectBankAccountForm">
            {[
                ...bankAccountsList.map(item => <Card
                    item={item.account}
                    valueId={_.get(value, 'Id', null)}
                    onChange={this.handleChange}
                />),
                <Card
                    item={{ Id: 'OTHER' }}
                    valueId={_.get(value, 'Id', null)}
                    label="New Bank Account"
                    style={{ position: 'relative', zIndex: 3 }}
                    onChange={this.handleChange}
                />
            ]}
            { value && value.Id === 'OTHER' && <div
                style={{
                    position: 'relative',
                    top: '-16px',
                    padding: '16px',
                    paddingTop: '32px',
                    marginLeft: alwaysSave ? '-16px' : '2rem',
                    marginRight: alwaysSave ? '-16px' : '2rem',
                    border: '1px solid #d9d9d9',
                    borderTopWidth: alwaysSave ? 1 : 0,
                    borderRadius: '5px'
                }}>

                <NewBankAccountForm
                    noSubmit                    
                    onChange={value => this.handleChange({ 
                        ...value,
                        Id: 'OTHER',
                        save: alwaysSave ? true : value.save,                        
                    })}
                />

                <div className="mt-3">                    
                    { alwaysSave ? <p>
                        We will save that payment method to
                        speed up checkouts in the future.</p>
                    : <Checkbox
                        label="Save this bank account for future payments"
                        checked={value.save}                        
                        onChange={(e, save) => this.handleChange({ ...value, save })}
                    />}
                </div>                
            </div>}
        </div>
    }
}

export default SelectBankAccountForm;

class Card extends React.PureComponent {
    render() {
        const { item, label, style, onChange, valueId } = this.props;

        return <AntdButton
            className="SelectBankAccountForm__Item"
            type={valueId === item.Id ? "primary" : null}
            size="large"
            style={style}
            onClick={() => onChange(item)}              
        >
            { label ? label : `${item.Details.IBAN}`}
        </AntdButton>
    }
}
