import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import validate from '../../../helpers/validators/validate';
import MenuItem from 'material-ui/MenuItem';
import TextField from 'material-ui/TextField';
import SelectField from 'material-ui/SelectField';
import {
  Tags,
  Experience,
} from '../CommonRegistrationComponent';
import {
  InputStyles,
  PlaceholderStyles,
} from '../CommonComponent';
import {
  BusinessLocations
} from '../../CommonRegistrationComponent';
import {
  currency,
  period
} from '../../../utils/constants';

import '../../../styles/components/band/BandContactInfo.scss';
import { PriceList } from '../PriceList/PriceList';
import { registrationConfig } from '../../../config';
import { Asterisk } from '../../common/Asterisk/Asterisk';
import { GroupInfo } from './GroupInfo'

class GroupDetails extends Component {
  render() {
    const { handleSubmit, initialValues } = this.props;

    return (
      <form className="service-detail-form" onSubmit={handleSubmit}>
        <div className="service-detail-form__wrapper">
          <Field
            name="questions_answers"
            component={GroupInfo}/>
        </div>
        <div className="service-detail-form__wrapper">
          <Field
            name="booking_method"
            component={BookingMethod}/>
        </div>
        <div className="service-detail-form__wrapper">
          <Field
            name="experience"
            component={Experience}/>
        </div>
        <div className="service-detail-form__wrapper">
          <div className="service-detail-form__item-header">
            <span className="fa-stack info-popup-window" data-title={registrationConfig.popupText.groupServiceFees}>
              <i className="fa fa-circle-thin fa-stack-2x"/>
              <i className="fa fa-info fa-stack-1x"/>
            </span>
            <h4 className="service-detail-form__title">
              Service fees
            </h4>
          </div>
          <Field
            name="fee_primary"
            feeLabel={'Primary'}
            component={Fee}
            required
            primary
          />
          <Field
            name="fee_secondary"
            feeLabel={'Secondary'}
            component={Fee}/>
        </div>
        <div className="service-detail-form__wrapper">
          <Field
            name="services"
            component={PriceList}/>
        </div>
        <div className="service-detail-form__wrapper">
          <Field
            name="tags"
            component={Tags}/>
        </div>
      </form>
    );
  }
}

GroupDetails = reduxForm({
  form: 'GroupDetails',
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: false,
  enableReinitialize: true,
  validate
})(GroupDetails);
//TODO refactoring

export default connect(
  ({ user, form }) => ({
    currentUser: user.currentUser,
    initialValues: getValues(form.GroupDetails, user.currentUser && user.currentUser.profile ? {
      booking_method: user.currentUser.profile.booking_method,
      experience: user.currentUser.profile.experience,
      fee_primary: user.currentUser.profile.fee_primary,
      fee_secondary: user.currentUser.profile.fee_secondary,
      business_locations: user.currentUser.profile.business_locations,
      services: (user.currentUser.services && user.currentUser.services.length) ? user.currentUser.services : undefined,
      tags: (user.currentUser.tags && user.currentUser.tags.length) ?
        {
          basic: user.currentUser.tags.filter(tag => tag.type === 'basic')
            .map(tag => ({ content: tag.content }))
        } :
        { basic: [] },
    } : {})
  }), {}
)(GroupDetails);

function getValues(form, values) {
  if (form && form.values) {
    return form.values;
  } else {
    return values;
  }
}

class BookingMethod extends Component {

  onTypeSelect(type) {
    const { input: { onChange } } = this.props;
    onChange(type);
  }

  //string
  render() {
    const { input: { value } } = this.props;

    return (
      <div className="service-detail-form__item">
        <div className="service-detail-form__item-header">
          <span className="fa-stack info-popup-window" data-title={registrationConfig.popupText.groupBookingMethod}>
            <i className="fa fa-circle-thin fa-stack-2x"/>
            <i className="fa fa-info fa-stack-1x"/>
          </span>
          <h4 className="service-detail-form__title">
            Booking method
          </h4>
        </div>
        <div className="radio-group-wrapper">
          <label className="radio-group radio-inline">
            <input
              onClick={() => this.onTypeSelect('normal')}
              {...{ checked: value === 'normal' }}
              name='booking'
              type="radio"
              className="d-none"/>
            <span>Normal Booking</span>
          </label>
          <label className="radio-group radio-inline">
            <input
              onClick={() => this.onTypeSelect('instant')}
              {...{ checked: value === 'instant' }}
              name='booking'
              type="radio"
              className="d-none"/>
            <span>Instant Booking</span>
          </label>
        </div>
      </div>
    );
  }
}

class Fee extends Component {

  constructor(props) {
    super(props);

    this.state = {};
  }

  onChangeValue = (evt, newVal, field = 'value') => {
    const { input: { value, onChange } } = this.props;

    onChange({
      ...value,
      [field]: newVal,
    });
  };

  render() {
    const { feeLabel, input: { value }, required, primary } = this.props;

    const options = /*primary ? [period.common[0]] :*/ period.common

    return (
      <div className="service-detail-form__item">
        <h4 className="service-detail-form__subtitle">
          {feeLabel} fee
					{required ? <Asterisk className="ml-1" /> : null}
        </h4>

        <div className="service-detail-form__price-wrapper">
          <div className="number-input price-count">
            <TextField
              value={value.value}
              inputStyle={InputStyles}
              hintStyle={PlaceholderStyles}
              onChange={(evt, val) => this.onChangeValue(evt, val)}
              hintText={feeLabel}
              fullWidth={true}/>
          </div>
          <div className="price-currency">
            <SelectField
              hintStyle={PlaceholderStyles}
              labelStyle={InputStyles}
              fullWidth={true}
              value={value.currency}
              onChange={(evt, index, val) => this.onChangeValue(evt, val, 'currency')}>
              {currency.map((cur, index) => <MenuItem key={index} value={cur.value} primaryText={cur.label}/>)}
            </SelectField>
          </div>
          <div className="price-period">
            <SelectField
              hintStyle={PlaceholderStyles}
              labelStyle={InputStyles}
              fullWidth={true}
              value={value.period}
              onChange={(evt, index, val) => this.onChangeValue(evt, val, 'period')}>
              {options.map((per, index) => <MenuItem key={index} value={per.value} primaryText={per.label}/>)}
            </SelectField>
          </div>
        </div>
      </div>
    );
  }
}
