import React from 'react'

import { connect } from 'react-redux'
import * as _ from 'lodash'
import moment from 'moment'

import { Badge, Tooltip } from 'antd';
import 'antd/es/badge/style/css'
import 'antd/es/tooltip/style/css'

import IconButton from 'material-ui/IconButton'
import SVGInline from 'react-svg-inline'
import { Link } from 'react-router-dom'
import { ICON_BUTTON_STYLES } from './NavBar';

const bell_icon = require('!!svg-inline-loader?classPrefix!../../assets/bell-icon.svg')

import './NotificationsPopover.scss'
import RichPopover from './RichPopover/RichPopover';
import { NOTIFICATIONS_STATUSES, OWNER_ACTIONS, DANGER_COLOR, SUCCESS_COLOR, HIDDEN_ACTIONS } from '../../utils/constants';
import SmolSpinner from '../common/SmolSpinner/SmolSpinner';
import { markAsRead } from '../../redux/modules/notifications';

@connect(
	({ notifications }) => ({
        notifications: notifications.notifications,
        pusherStatus: notifications.pusherStatus,
        nextTryTimestamp: notifications.nextTryTimestamp,
    }), { markAsRead }
)
class NotificationsPopover extends React.PureComponent {
    constructor(props) {
        super(props);
        console.log('notifications in construtor ', props.notifications)
        this.state = {
            popoverOpen: false,
        }
    }

    componentDidMount() {
        this.refreshNotificationsTimeInterval = setInterval(() => this.forceUpdate(), 60000);
    }

    componentWillUnmount() {
		clearInterval(this.refreshNotificationsTimeInterval)
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if(nextProps.hidenNotificationTrigger !== this.props.hidenNotificationTrigger) {
            this.handleOpen();
        }
    }

    handleOpen = () => {
        if(this.props.openFakeNotificationPopover) {
            this.props.openFakeNotificationPopover()
        }
        else {
            this.setState({
                popoverOpen: true,
            }, () => this.props.markAsRead())
        }
    }

    handleClose = () => this.setState({
        popoverOpen: false,
    })

    handleVisibleChange = visible => {
        if(!this.props.openFakeNotificationPopover) {
            this.setState({ popoverOpen: visible });
        }
    };

    renderContent = () => {
        const { nextTryTimestamp, pusherStatus } = this.props;

        return <div>
            {this.renderNotifications()}
        </div>
    }

    renderNotifications = () => {
        const { notifications } = this.props;
        return _.sortBy(notifications, o => new moment(o.time).format('YYYYMMDDHHmm', ['desc']))
            .reverse()
            .map(item => {
            const { id, message, picture, time, name, hidden, action } = item;

            if (HIDDEN_ACTIONS.includes(action) || hidden) return null

            return <Link
              key={id}
                    className="NotificationItem"
                    to={item.link}
                    onClick={this.handleClose}
                >
                    {
                        picture && picture ? <img
                            src={picture}
                            alt="brand"
                            className="NotificationItem__Image"
                        /> : <div
                            className="NotificationItem__Image"
                            style={{backgroundColor: 'lightorange'}}
                        />
                    }

                    <div className="px-2 py-1 w-100 d-flex flex-column">
                        <div className="d-flex justify-content-between">
                            <span className="NotificationItem__Title">{name}</span>

                            <Tooltip
                                placement="left"
                                title={moment(time ,'DD MMMM YYYY, hh:mm')}>
                                <span className="NotificationItem__Date">
                                    {moment(time).local().fromNow()}
                                </span>
                            </Tooltip>

                        </div>

                        <span className="NotificationItem__Message">
                            {message}
                         </span>

                    </div>
                </Link>
        });
    }

    render() {
        const { notifications } = this.props;
        const { popoverOpen } = this.state;
        const notReadCount = notifications.filter(x => !x.read && !HIDDEN_ACTIONS.includes(x.action)).length

        return <RichPopover
            visible={popoverOpen}
            onVisibleChange={this.handleVisibleChange}
            title={<div className="d-flex align-items-center">
                Notifications
                {
                    this.props.pusherStatus === 'connecting' ?
                        <SmolSpinner style={{ marginLeft: 8 }} /> :
                        <span style={{ color: this.props.pusherStatus === 'connected' ? SUCCESS_COLOR : DANGER_COLOR, marginLeft: 8 }}>•</span>
                }
            </div>}
            content={this.renderContent()}
            overlayClassName="NotificationsPopover">
                    <IconButton
                        className="IconButton"
                        style={ICON_BUTTON_STYLES}
                        onClick={popoverOpen ? this.handleClose : this.handleOpen}
                        >
                            <Badge
                                count={notReadCount}
                                style={{ boxShadow: 'none'}}
                                >
                                <SVGInline
                                    svg={bell_icon}
                                    className="IconButton__Icon"
                                    fill="#fff"
                                />
                            </Badge>
                    </IconButton>

        </RichPopover>
    }
}

export default NotificationsPopover
