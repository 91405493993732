import React from 'react';
import { SkyLightStateless } from 'react-skylight';
import { currency } from '../../../../utils/constants';
import './PopUpPriceList.scss';
import IconButton from 'material-ui/IconButton'
import SVGInline from 'react-svg-inline';
const svg_go_back = require('!!svg-inline-loader?classPrefix!../../../../assets/icons/go-back.svg');

const dialogStyles = {
  width: '80%',
  height: '75vh',
  marginTop: 'calc(75vh / -2)',
  marginLeft: '-40%'
};

const getCurrencyLabel = (items, searched) => {
  const result = items.find(x => x.value === searched);

  return result ? result.label : null;
};

export default props => {
  const { priceList, name, close } = props;

  return (
    <div className="PopUpPriceList">
      <h5 className="title">
        Service Price List
      </h5>
      <p className="name">{name || '---'}</p>
      <div className="price-list">
        {
          (priceList && priceList.length)
            ? priceList.map((item, inx) => (
              <div className="item-price" key={inx}>
                <div><b>{item.name}</b></div>
                <div className="val">
                  <div><b>{item.price}&nbsp;{getCurrencyLabel(currency, item.currency)}</b></div>
                  <small>per&nbsp;<b>{item.measurement}</b></small>
                </div>
              </div>
            ))
            : <p className="no-prices">No Prices Yet</p>
        }
      </div>
      <IconButton 
         style={{ position: 'absolute', color: '#ccc', right: '-8px', top: '-.7rem', padding: '0', fontSize: '2.4rem' }} 
         onClick={close}>
         <SVGInline svg={svg_go_back} fill={'#000'} width={'1.4rem'} style={{ padding: '0px' }} />
      </IconButton>
    </div>
  );
};
