import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import InfiniteScroll from 'react-infinite-scroll-component'
import Footer from '../../../Footer/Footer'
import { getGigFeed, getGigFeedByCategoryName, getGigs, emptyGigFeed } from '../../../../redux/modules/gig'
import FeedCategory from '../FeedCategory/FeedCategory'
import Loader from '../../Loader/Loader'
import GigDetailsPopup from '../GigDetailsPopup/GigDetailsPopup'
import isEmpty from 'lodash/isEmpty'
import sortBy from 'lodash/sortBy'
import get from 'lodash/get'
import { SortBar } from '../../../common/SortBar/SortBar'
import { getRealTypeOfUser } from '../../../../helpers/methods'
import './GigFeedTab.scss'
import { SORT_BY_OPTIONS } from '../../../../utils/constants'
import { applyGig, saveGig } from '../../../../redux/modules/actions'

import notification from 'antd/es/notification'
import 'antd/es/notification/style/css'

@withRouter
@connect(
	({ gig, common, user }) => ({
		gigFeed: gig.gigFeed,
		isFetching: gig.isFetchingGigFeed,
		categoriesMap: common.categoriesMap,
		currentUser: user.currentUser,
	}),
	{
		getGigFeed,
    getGigFeedByCategoryName,
		getGigs,
		emptyGigFeed,
		applyGig,
		saveGig
	}
)
export default class GigFeedTab extends Component {
	constructor(props) {
		super(props)

		this.state = {
			nextPage: 1,
			lastPage: null,
			isDetailsPopupOpened: false,
			gigIdToViewDetails: null,
			refreshTrigger: false,
		}
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		const superCategoryId = this.props.match.params.superCategoryId
		const nextSuperCategoryId = nextProps.match.params.superCategoryId

    if(!superCategoryId || superCategoryId !== nextSuperCategoryId) {
			this.props.emptyGigFeed()
			this.fetchMoreCategories(1, nextSuperCategoryId)
		}
	}

	componentDidMount() {
		// superCategoryId isnt always number (!) e.g it can be
		const superCategoryId = this.props.match.params.superCategoryId

		this.fetchMoreCategories(this.state.nextPage, superCategoryId)
	}

	componentWillUnmount() {
		this.props.emptyGigFeed()
	}

	//UNSAFE_componentWillReceiveProps(nextProps) {
	//	if (this.props.superCategoryId !== nextProps.superCategoryId) {
	//		this.fetchMoreGigs(1, nextProps.superCategoryId, true)
	//	}
	//}

	next = () => {
	  const {isFetching, match: { params: { superCategoryId } } } = this.props;

		if (!isFetching) {
		  this.fetchMoreCategories(this.state.nextPage, superCategoryId)
    }
	}

	fetchMoreCategories = (nextPage, id, withReplace) =>
		this.props.getGigFeedByCategoryName(nextPage, id, withReplace).then(({ data: { last_page: lastPage } }) =>
			this.setState(({ nextPage }) => ({
				nextPage: nextPage + 1,
				lastPage,
			}))
		)

	switchPopup = gigId =>
		this.setState(prevState => ({
			isDetailsPopupOpened: !prevState.isDetailsPopupOpened,
			gigIdToViewDetails: gigId || null,
		}))

	scrollTo = id => {
		setTimeout(() => {
			document.getElementById(`FeedCategory-${id}`).scrollIntoView()
			window.scrollBy(0, -100)
		})
	}

	render() {
		const { gigFeed, isFetching, categoriesMap, currentUser, superCategoryId } = this.props
		const { nextPage, lastPage, isDetailsPopupOpened, gigIdToViewDetails } = this.state

		return (
			<div className="GigFeedTab">
				{getRealTypeOfUser(currentUser) === 'artist' && (
					<SortBar legend={false} sortValue={SORT_BY_OPTIONS[0]} onChangeSort={console.log} refreshTrigger={this.state.refreshTrigger} />
				)}
				<GigDetailsPopup
					isOpened={isDetailsPopupOpened}
					gigId={gigIdToViewDetails}
					onClose={this.switchPopup}
					categoriesMap={categoriesMap}
					applyBtnHandler={this.applyBtnHandler}
					saveBtnHandler={this.saveBtnHandler}
				/>
				{/* {sortBar} */}
				{isFetching && !gigFeed.length ? (
					<Loader />
				) : (
					<InfiniteScroll
						next={this.next}
						hasMore={nextPage <= lastPage}
						loader={<Loader />}
						endMessage={
							<Footer
								type="home"
								media={{
									type: 'video',
									src: 'https://player.vimeo.com/video/230747958',
									content_url: 'https://youtu.be/l1u1_ws6TBg',
									clapQuantity: 42,
									commentQuantity: 2,
									shareQuantity: 12,
									tags: ['#best', '#ever'],
									location: 'Berlin',
								}}
							/>
						}
					>
						{gigFeed.length === 0 ? (
							<div className="nothing">Nothing to show</div>
						) : (
							gigFeed.map((gigCategory, idx) => (
								<FeedCategory
									scrollTo={this.scrollTo}
									history={this.props.history}
									imageUrl={gigCategory.category.img_url}
									key={idx}
									isOccasion={superCategoryId === 'occasions'}
									onViewMore={this.switchPopup}
									feedCategory={gigCategory}
									//getGigs={this.props.getGigs}
									//applyBtnHandler={this.applyBtnHandler}
									//saveBtnHandler={this.saveBtnHandler}
								/>
							))
						)}
					</InfiniteScroll>
				)}
			</div>
		)
	}
}
