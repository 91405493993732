import React from 'react'
import PropTypes from 'prop-types'
import client from '../../../../helpers/apiClient'
import moment from 'moment'
import { connect } from 'react-redux'
import { lifecycle } from 'recompose'
import GeminiScrollbar from 'react-gemini-scrollbar'
import { SkyLightStateless } from 'react-skylight'
import get from 'lodash/get'
import './GigDetailsPopup.scss'
import GigCard from '../../../common/GigCard/GigCard'
import 'gemini-scrollbar/gemini-scrollbar.css'
import SVGInline from 'react-svg-inline'
import Modal from '../../../common/Modal/Modal';

const styles = {
	dialogStyles: {
		width: 'auto',
		position: 'fixed',
		left: '50%',
		marginLeft: 0,
		marginTop: 0,
		zIndex: 999,
		borderRadius: 0,
		padding: 0,
		height: 'auto',
		minHeight: 0,
	},
	overlayStyles: {
		zIndex: 999,
	},
	closeButtonStyle: {
		right: '25px',
		top: '0'
	  }
}

const EMPTY = '---'

const chipStyle = {
	marginRight: '10px',
	backgroundColor: 'transparent',
	border: '1px solid #d5d5d5',
	borderRadius: '12px',
}

@connect(({ globalConfig }) => ({
	isMobile: globalConfig.isMobile,
}))
class GigDetailsPopup extends React.PureComponent {
	constructor(props) {
		super(props)
		this.state = {
			gig: null,
			isFetching: false,
		}
	}

	componentDidMount() {
		const gig = get(this.props, 'gig', null)
		if (gig) {
			console.log('ustawiam giga')
			this.setState({ gig })
		}
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		const currentId = this.props.gigId
        const nextId = get(nextProps, 'gigId')
		
		if (!this.isNullOrEmptyOrObject(nextId) && nextId !== currentId) this.fetchGig(nextProps.gigId)	
	}

	fetchGig(id) {
		this.setState({ isFetching: true })

		return client(`/api/gigs/${id}`)
			.then(({ data: gig }) => this.setState({ gig, isFetching: false }))
			.catch(err => {
				this.setState({ isFetching: false })
				console.error(err)
			})
    }
    
    isNullOrEmptyOrObject(val) {
        if (!val) { return true }
        return ((typeof val === 'function') || (typeof val === 'object'))
    }

	render() {
		const { onClose, isOpened, categoriesMap, applyBtnHandler, saveBtnHandler, viewMoreHandler, noButtons, isMobile } = this.props
		const { gig, isFetching } = this.state;

		const svg_m2 = require('!!svg-inline-loader?classPrefix!../../../../assets/icons/m2.svg')
		const svg_person = require('!!svg-inline-loader?classPrefix!../../../../assets/icons/person.svg')
		const svg_chair = require('!!svg-inline-loader?classPrefix!../../../../assets/icons/chair.svg')

		const styles = (gig && gig.styles) ? gig.styles.filter(x => x.type !== 'venue_occasion') : []
		const occasion = (gig && gig.styles) ? gig.styles.find(x => x.type === 'venue_occasion') : []
		const categoryName = (gig && categoriesMap) && get(categoriesMap[gig.category_id], 'name', null)

		const dialogStyles = {
			width: 'unset',
			width: '95%',
			maxWidth: 'calc(35rem + 293px)', 
		}

		const mobileDialogStyles = {
			maxWidth: `calc(293px)`,
			marginLeft: 'auto',
			marginRight: 'auto',
        }

		return (
			isOpened && (			
					<Modal					 
						{...styles }
						className="GigDetailsPopupModal"
						isOpen={isOpened}
						onClose={onClose}
						dialogStyles={isMobile ? mobileDialogStyles : dialogStyles}
						closeButtonStyle={isMobile ? { top: 0, right: 0 } : {}}
						innerStyle={ isMobile ? {
							padding: 0,
							paddingTop: 48,
						} : { 
							padding: 0,
							height: 378 // same as GigCard 
						}}
						>
						{isFetching ? (
							<div className="popup-loader-container">
								<span>Loading...</span>
							</div>
						) : (
							gig && (
								<div className="gig-details">
									<GigCard 
										isDetailsPopupOpened={isOpened}
										gig={gig}
										onViewMore={viewMoreHandler}
										categoryName={categoryName}
										//apply={applyBtnHandler}
										save={saveBtnHandler}
										noButtons={noButtons}
										noMargins
									/>
									<div className="GigCard-popup-description">
										<GeminiScrollbar>
										<div className="categories">
											<h4>{gig.is_private_event_access ? 'Private Access' : 'Public Access'}</h4>
											{gig.performer_type === 'artist.basic' && (
												<div>
													<h4>Gig Location</h4>
													<p>{get(gig, 'venue_name', EMPTY)}</p>
													<p>{get(gig, 'location.formatted_address', EMPTY)}</p>
												</div>
											)}

											<p>
												Client is open to receiving offers from outside their requested city & country? {' '}
												<b>{get(gig, 'open_for_offers', false) ? 'Yes' : 'No'}</b>
											</p>

											<div>
												<h4>Description</h4>
												{gig.performer_type !== 'artist.basic' && (
													<div className="d-flex flex-wrap capacity-request">
														<span className="capacity-item">
															<SVGInline svg={svg_m2} fill="#000" width={'1.5rem'} height={'1.5rem'} /> {get(gig, 'capacity.space', EMPTY)} sq/m2
														</span>
														<span className="capacity-item">
															<SVGInline svg={svg_person} fill="#000" width={'1.5rem'} height={'1.5rem'} /> {get(gig, 'capacity.standing', EMPTY)} ppl
														</span>
														<span className="capacity-item">
															<SVGInline svg={svg_chair} fill="#000" width={'1.5rem'} height={'1.5rem'} /> {get(gig, 'capacity.seated', EMPTY)} ppl
														</span>
													</div>
												)}
												<p>{gig.description || EMPTY}</p>
											</div>
											<div>
												<h4>Details or special requests</h4>
												<p>{gig.note || EMPTY}</p>
											</div>
											{(gig.styles && gig.styles.length) ? (
												<div className="stylesWrapper">
													
													{!!styles.length && (
														<div className="styles">
															<h4>Styles</h4>
															<p className="styles-list">
																{styles.map((style, idx) => (
																	<span key={idx} className="styles-item" dangerouslySetInnerHTML={{ __html:  style.name  }} />
																))}
															</p>
														</div>	
													)}

													{occasion && (
														<div className="occasion">
															<h4>Occasion</h4>
															<p className="styles-list">
																<span className="styles-item" dangerouslySetInnerHTML={{ __html:  occasion.name  }} />
															</p>
														</div>
													)}
												</div>
											) : <div /> }
											<p>Gig posted {gig.created_at && moment(gig.created_at.date).local().fromNow()}</p>
										</div>
										</GeminiScrollbar>
									</div>
								</div>
							)
						)}
					</Modal>
			)
		)
	}
}

GigDetailsPopup.propTypes = {
	isOpened: PropTypes.bool,
	onClose: PropTypes.func,
	gigId: PropTypes.number,
	gig: PropTypes.object,
}

export default GigDetailsPopup
