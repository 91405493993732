const validate = values => {
	const errors = {};

  if (values.capacity) {
    const requiredProperties = ['space', 'standing', 'seated'];
    const missedCapacityProperties = checkIfRequiredPropsAreSet(requiredProperties, values.capacity);

    if (missedCapacityProperties.length > 0) {
      errors.capacity = 'Required';
    }

    for (let key in values.capacity) {
      if (isNaN(values.capacity[key])) {
        errors.capacity = 'Value must be number';
      }
    }
  } else {
    errors.capacity = 'Required';
  }

	if (!values.booking_method) {
		errors.booking_method = 'Required';
	}

	if (!values.experience) {
		errors.experience = 'Required';
	}

	if (values.fee_primary) {
	  const requiredFeePrimaryProps = ['currency', 'period', 'value'];
	  const missedFeePrimaryProps = checkIfRequiredPropsAreSet(requiredFeePrimaryProps, values.fee_primary);

    if (missedFeePrimaryProps.length > 0) {
      errors.capacity = 'Required';
    }

    if (values.fee_primary.value && isNaN(values.fee_primary.value)) {
      errors.capacity = 'Required';
    }
	} else {
		errors.fee_primary = 'Required';
  }

	return errors;
};

const checkIfRequiredPropsAreSet = (requiredProperties, fieldObj) => {
  return requiredProperties.filter((item) => !fieldObj.hasOwnProperty(item) || fieldObj.hasOwnProperty(item) && fieldObj[item] === '' );
};

export default validate;
