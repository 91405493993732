import React, {Component} from 'react';
import PropTypes from 'prop-types';
import './UserPic.scss';

import defaultPicture from '../../../assets/images/default_picture.png';

export class UserPic extends Component {
  render() {
    const {size, src, theme, primary, children, href, noBg, className, style, profileCompleted = true} = this.props;
    let pic = (<img src={src ? src.url : defaultPicture} alt=" "/>);

    return <div className={[
      'UserPic',
      (primary ? 'primary' : null),
      (size || 'default'),
      (noBg && 'noBg'),
      (theme || 'light'),
      className].join(' ')}
      style={style}>
      {
      this.props.profileCompleted == true? '' :
      <a href="/profile/edit" title="Profile is incomplete. Click here to complete your profile."
      onClick={this.handleIncompleteProfileClick}>
        <i class="fa fa-exclamation"></i>
      </a>}
      {href ? <a href={href} style={{ zIndex: 3 }}>{pic}</a> : pic}
      {children}
    </div>;
  }

  handleIncompleteProfileClick(e) {
    e.stopPropagation();
  }
}


UserPic.propTypes = {
  size: PropTypes.oneOf(['default', 'large', 'small']),
  href: PropTypes.string,
  noBg: PropTypes.bool,
  src: PropTypes.shape({
    url: PropTypes.string,
  }),
  theme: PropTypes.oneOf(['light', 'dark']),
  primary: PropTypes.bool,
  children: PropTypes.element
};
