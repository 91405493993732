import React from 'react'
import {entityTypes, personTypes} from "../../../utils/constants";
import {translate} from "../../../translator";
import {Field} from "redux-form";
import RenderField from "../../RenderField";
import {registrationConfig} from "../../../config";
import {Asterisk} from "../../common/Asterisk/Asterisk";
import {
  BirthDatePickerComponent, CheckboxComponent,
  GoogleMapsAutocomplete,
  SelectFieldComponent,
  TextFieldComponent
} from "../../CommonComponent";
import {FileInput} from "./FileInput";
import Loader from "../../discover/Loader/Loader";

/**
 * Kyc form for legal entities, such as Businesses, Organizations and Soletraders
 */
export default class KycEntityForm extends React.Component {

  render() {

    const { showValidationError, touch, isPersonTypeSelected, selectPersonType, isEntityTypeSelected, selectEntityType, countriesList } = this.props;

    return (
      <div className="section divide">
        <div className="button_column">
          <span className={`switch_button ${isPersonTypeSelected(personTypes.Entity) ? 'selected' : ''}`} onClick={() => selectPersonType(personTypes.Entity)}>{translate('kyc.select')}</span>
        </div>
        <div className="content_column">
          <div className="person_type_title">
            <div className="big_header">{translate('kyc.legalEntity')}</div>
            <div>{translate('kyc.legalEntitySub')}</div>
          </div>
          {isPersonTypeSelected(personTypes.Entity) && (
            <div className="mobile-full-width">

              <div className="section">
                <div className="divide">
                  <div className="button_column entity">
                    <div className={`switch_button ${isEntityTypeSelected(entityTypes.Business) ? 'selected' : ''}`} onClick={() => selectEntityType(entityTypes.Business)}>{translate('kyc.business')}</div>
                  </div>
                  <div className="content_column entity">
                    <div>{translate('kyc.businessSub')}</div>
                  </div>

                  <div className="button_column entity">
                    <div className={`switch_button ${isEntityTypeSelected(entityTypes.Organization) ? 'selected' : ''}`} onClick={() => selectEntityType(entityTypes.Organization)}>{translate('kyc.organization')}</div>
                  </div>
                  <div className="content_column entity">
                    <div>{translate('kyc.organizationSub')}</div>
                  </div>

                  <div className="button_column entity">
                    <div className={`switch_button ${isEntityTypeSelected(entityTypes.Soletrader) ? 'selected' : ''}`} onClick={() => selectEntityType(entityTypes.Soletrader)}>{translate('kyc.soletrader')}</div>
                  </div>
                  <div className="content_column entity">
                    <div>{translate('kyc.soletraderSub')}</div>
                  </div>
                </div>
              </div>

              {(isEntityTypeSelected(entityTypes.Business) || isEntityTypeSelected(entityTypes.Organization) || isEntityTypeSelected(entityTypes.Soletrader)) &&
              <form className="form-wrapper">
                <div className="form-section pb-0">
                  <div className="section-header">
                    {translate('kyc.businessEmail')}
                  </div>
                  <Field
                    name="kyc_business_email"
                    type="text"
                    component={RenderField}
                    classNames="input-field"
                    placeholder="Email"
                  />
                </div>

                <div className="form-section">
                  <div className="section-header">
                                                    <span className="fa-stack info-popup-window" data-title={registrationConfig.popupText.artistContactInformation}>
                                                        <i className="fa fa-circle-thin fa-stack-2x" />
                                                        <i className="fa fa-info fa-stack-1x" />
                                                    </span>
                    {translate('kyc.businessAddress')} <Asterisk />
                  </div>
                  <div className="google-location-input" style={{ width: '100%' }}>
                    <Field
                      makeTouched={() => touch('location')}
                      onBlur={() => touch('location')}
                      fullWidth
                      name="kyc_business_location"
                      component={GoogleMapsAutocomplete}
                      hintText="Enter Address"
                      hintStyle={{
                        fontWeight: '300',
                        color: '#a3a3a3',
                      }}
                      style={{
                        textAlign: 'left',
                        marginRight: '20px',
                      }}
                    />
                  </div>
                </div>

                {(isEntityTypeSelected(entityTypes.Business) || isEntityTypeSelected(entityTypes.Organization) || isEntityTypeSelected(entityTypes.Soletrader)) &&
                <div className="form-section">
                  <div className="section-header">
                   Company name <Asterisk />
                  </div>
                  <Field
                    name="kyc_business_name"
                    type="text"
                    style={{ margin: '10px 0 5px' }}
                    fullWidth
                    component={TextFieldComponent}
                    hintText="Company Name"
                  />
                </div>
                }

                {isEntityTypeSelected(entityTypes.Business) &&
                <div className="form-section">
                  <div className="section-header">
                    {translate('kyc.companyNumber')} <Asterisk />
                  </div>
                  <div>
                    {translate('kyc.companyNumberSub')} <a className="link" href={'https://docs.mangopay.com/guide/company-number'} target='_blank'>MangoPay</a>
                  </div>
                  <Field
                    name="kyc_company_number"
                    type="text"
                    style={{ margin: '10px 0 5px' }}
                    fullWidth
                    component={TextFieldComponent}
                    hintText="Type number"
                  />
                </div>
                }

                {isEntityTypeSelected(entityTypes.Business) &&
                  <>
                  <div className="form-section">
                    <div className="section-header">
                      {translate('kyc.uboDeclaration')} <Asterisk />
                    </div>
                    <div className="doc-input">
                      <div>
                        {translate('kyc.uboDeclarationSub')}
                      </div>
                      <Field
                        name="kyc_ubo_declaration"
                        type="file"
                        showValidationError={showValidationError}
                        withStatus={false}
                        component={FileInput}
                      />
                    </div>
                    Template: If you need a <a href={process.env.REACT_APP_API_BACKEND_PROTOCOL + "://" + process.env.REACT_APP_API_BACKEND_URL + "/" + "storage/contracts/ubo_declaration_en.pdf"} target={'_blank'} style={{ color: '#40a9ff' }}>Shareholder Declaration Template</a>, you may use this file.
                  </div>
                    <div className="horizontal_line extension_left" />
                    <div className="section-header">
                      {translate('kyc.uboExplanation')} <Asterisk />
                    </div>

                    <div className="form-section">
                      <div className="section-header">
                        {translate('kyc.uboFirstName')} <Asterisk />
                      </div>
                      <Field
                        name="ubo_first_name"
                        type="text"
                        style={{ margin: '10px 0 5px' }}
                        fullWidth
                        component={TextFieldComponent}
                        hintText="Type name"
                      />
                    </div>

                    <div className="form-section">
                      <div className="section-header">
                        {translate('kyc.uboLastName')} <Asterisk />
                      </div>
                      <Field
                        name="ubo_last_name"
                        type="text"
                        style={{ margin: '10px 0 5px' }}
                        fullWidth
                        component={TextFieldComponent}
                        hintText="Type name"
                      />
                    </div>

                    <div className="form-section">
                      <div className="section-header">
                        {translate('kyc.uboNationality')} <Asterisk />
                      </div>
                      <div className="" style={{ width: '100%' }}>
                        {(countriesList && countriesList.length) ?
                          <Field
                            name="ubo_nationality"
                            type="string"
                            className="select-nationality"
                            required
                            fullWidth
                            style={{ height: '3rem', textAlign: 'left' }}
                            options={countriesList}
                            hintText="Please select the UBO nationality"
                            component={SelectFieldComponent}
                          />
                          :
                          <div className="d-flex justify-content-center">
                            <Loader />
                          </div>
                        }
                      </div>
                    </div>

                    <div className="form-section">
                      <div className="section-header">
                        {translate('kyc.uboBirthday')} <Asterisk />
                      </div>
                      <div className="birth-date" style={{ width: '100%' }}>
                        <Field name="ubo_birth_date" hintText="Birth day" component={BirthDatePickerComponent} />
                      </div>
                    </div>

                    <div className="form-section">
                      <div className="section-header">
                                                    <span className="fa-stack info-popup-window" data-title={registrationConfig.popupText.artistContactInformation}>
                                                        <i className="fa fa-circle-thin fa-stack-2x" />
                                                        <i className="fa fa-info fa-stack-1x" />
                                                    </span>
                        {translate('kyc.uboAddress')} <Asterisk />
                      </div>
                      <div className="google-location-input" style={{ width: '100%' }}>
                        <Field
                          makeTouched={() => touch('location')}
                          onBlur={() => touch('location')}
                          fullWidth
                          name="ubo_address"
                          component={GoogleMapsAutocomplete}
                          hintText="Enter Address"
                          hintStyle={{
                            fontWeight: '300',
                            color: '#a3a3a3',
                          }}
                          style={{
                            textAlign: 'left',
                            marginRight: '20px',
                          }}
                        />
                      </div>
                    </div>

                    <div className="form-section">
                      <div className="section-header">
                        {translate('kyc.uboCityOfBirth')} <Asterisk />
                      </div>
                      <Field
                        name="ubo_city_of_birth"
                        type="text"
                        style={{ margin: '10px 0 5px' }}
                        fullWidth
                        component={TextFieldComponent}
                        hintText="Type name"
                      />
                    </div>

                    <div className="form-section">
                      <div className="section-header">
                        {translate('kyc.uboCountryOfBirth')} <Asterisk />
                      </div>
                      <div className="" style={{ width: '100%' }}>
                        {(countriesList && countriesList.length) ?
                          <Field
                            name="ubo_country_of_birth"
                            type="string"
                            className="select-nationality"
                            required
                            fullWidth
                            style={{ height: '3rem', textAlign: 'left' }}
                            options={countriesList}
                            hintText="Please select the UBO Country of Birth"
                            component={SelectFieldComponent}
                          />
                          :
                          <div className="d-flex justify-content-center">
                            <Loader />
                          </div>
                        }
                      </div>
                    </div>
                </>
                }

                <div className="horizontal_line extension_left" />

                <div className="form-section">
                  <div className="section-header">
                    {translate('kyc.representativeFirstName')} <Asterisk />
                  </div>
                  <Field
                    name="kyc_representative_first_name"
                    type="text"
                    style={{ margin: '10px 0 5px' }}
                    fullWidth
                    component={TextFieldComponent}
                    hintText="Type name"
                  />
                </div>

                <div className="form-section">
                  <div className="section-header">
                    {translate('kyc.representativeLastName')} <Asterisk />
                  </div>
                  <Field
                    name="kyc_representative_last_name"
                    type="text"
                    style={{ margin: '10px 0 5px' }}
                    fullWidth
                    component={TextFieldComponent}
                    hintText="Type name"
                  />
                </div>

                <div className="form-section">
                  <div className="section-header">
                    {translate('kyc.representativeNationality')} <Asterisk />
                  </div>
                  <div className="" style={{ width: '100%' }}>
                    {(countriesList && countriesList.length) ?
                      <Field
                        name="kyc_representative_nationality"
                        type="string"
                        className="select-nationality"
                        // className={classnames('select-nationality', { filled: initialValues.kyc_representative_nationality })}
                        required
                        fullWidth
                        style={{ height: '3rem', textAlign: 'left' }}
                        options={countriesList}
                        hintText="Please select your nationality"
                        component={SelectFieldComponent}
                      />
                      :
                      <div className="d-flex justify-content-center">
                        <Loader />
                      </div>
                    }
                  </div>
                </div>

                <div className="form-section">
                  <div className="section-header">
                    {translate('kyc.representativeBirthdate')} <Asterisk />
                  </div>
                  <div className="birth-date" style={{ width: '100%' }}>
                    <Field name="kyc_representative_birth_date" hintText="Birth day" component={BirthDatePickerComponent} />
                  </div>
                  <div className="">
                    <Field
                      name="kyc_representative_over18"
                      label={translate('kyc.iConfirm')}
                      labelStyle={{ width: 'initial' }}
                      component={CheckboxComponent}
                    />
                  </div>
                </div>

                <div className="form-section">
                  <div className="section-header">
                    {translate('kyc.representativeEmail')} <Asterisk />
                  </div>
                  <Field
                    name="kyc_representative_email"
                    type="email"
                    style={{ margin: '10px 0 5px' }}
                    fullWidth
                    component={TextFieldComponent}
                    hintText="Type E-mail"
                  />
                </div>

                <div className="form-section">
                  <div className="section-header">
                                                    <span className="fa-stack info-popup-window" data-title={registrationConfig.popupText.artistContactInformation}>
                                                        <i className="fa fa-circle-thin fa-stack-2x" />
                                                        <i className="fa fa-info fa-stack-1x" />
                                                    </span>
                    {translate('kyc.representativeAddress')} <Asterisk />
                  </div>
                  <div className="google-location-input" style={{ width: '100%' }}>
                    <Field
                      makeTouched={() => touch('location')}
                      onBlur={() => touch('location')}
                      fullWidth
                      name="kyc_representative_location"
                      component={GoogleMapsAutocomplete}
                      hintText="Enter Address"
                      hintStyle={{
                        fontWeight: '300',
                        color: '#a3a3a3',
                      }}
                      style={{
                        textAlign: 'left',
                        marginRight: '20px',
                      }}
                    />
                  </div>
                </div>

                <div className="horizontal_line extension_left" />

                <div className="form-section">
                  <div className="section-header">
                    {translate('kyc.requiredDocs')} <Asterisk />
                  </div>

                  <div className="doc-input">
                    <div>
                      {translate('kyc.identityProof2')}
                    </div>
                    <Field
                      name="kyc_ent_identity_proof"
                      type="file"
                      showValidationError={showValidationError}
                      component={FileInput}
                    />
                  </div>

                  {!isEntityTypeSelected(entityTypes.Soletrader) &&
                  <div className="doc-input">
                    <div>
                      {translate('kyc.articlesOfAssociation')}
                    </div>
                    <Field
                      name="kyc_articles_association"
                      type="file"
                      showValidationError={showValidationError}
                      component={FileInput}
                    />
                  </div>
                  }

                  <div className="doc-input">
                    <div>
                      {translate('kyc.registrationProof')}
                      <a href={'https://docs.mangopay.com/guide/kyc-further-information'} target={'_blank'} style={{ color: '#40a9ff' }}>https://docs.mangopay.com/guide/kyc-further-information</a>
                    </div>
                    <Field
                      name="kyc_registration_proof"
                      type="file"
                      showValidationError={showValidationError}
                      component={FileInput}
                    />
                  </div>
                </div>

              </form>
              }
            </div>
          )}
        </div>
      </div>
    );
  }
}
