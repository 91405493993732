import './NavBar.scss'
import React, { Component } from 'react'
import classNames from 'classnames'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { logout } from '../../redux/modules/auth'
import { getActiveGigs, getPastGigs } from '../../redux/modules/dashboard'
import { refreshUnseenMessagesCount, getAllChatsWithLastMessages } from '../../redux/modules/inbox'
import { getCurrentKYC } from '../../redux/modules/user'
import { withRouter } from 'react-router'
import * as _ from 'lodash'
import FlatButton from 'material-ui/FlatButton'
import IconButton from 'material-ui/IconButton'
import RaisedButton from 'material-ui/RaisedButton'
import IconMenu from 'material-ui/IconMenu'
import SearchIcon from 'material-ui/svg-icons/action/search'
import SVGInline from 'react-svg-inline'
import block from 'bem-cn'
import { translate } from '../../translator';
import TextField from 'material-ui/TextField';
import 'antd/es/notification/style/css'
import { Badge } from 'antd';
import 'antd/es/badge/style/css'
import { IconButtonWrapper } from '../IconButtonWrapper'
import { UserPic } from '../common/UserPic/UserPic'
import { Container, ModalFooter } from 'reactstrap'
import { InlinePreLoader } from '../common/InlinePreLoader/InlinePreLoader'
import { LandingButton } from '../HomePage/LandingButton/LandingButton'

import missingFields from '../../helpers/missingFields'
import allowCreateGig from './permission'
import apiClient from '../../helpers/apiClient';
import CloseButton from '../common/CloseButton/CloseButton';
import Button from '../common/Button/Button';
import { PRIMARY_COLOR } from '../../utils/constants';
import MessageNotificationsPopover from './Message/MessageNotificationsPopover';
import NotificationsPopover from './NotificationsPopover';
import Modal from "../common/Modal/Modal";
import {TaxIdForm} from "../DashBoard/Admin/TaxId/TaxId";
import get from 'lodash/get'

const comment = require('!!svg-inline-loader?classPrefix!../../assets/comment-icon.svg')

const search_icon_svg = require('!!svg-inline-loader?classPrefix!../../assets/icons/Search.svg')
const whiteBrandLogo = require('../../assets/images/gigworks-white.png')
const whiteLogo = require('../../assets/images/gigworks-logo-white.png')

const b = block('NavBar')

export const ICON_BUTTON_STYLES = {
	padding: 8,
	width: 40,
	height: 40,
}

@connect(
	({ auth, user, dashboard, globalConfig, inbox }) => ({
		isAuthorized: auth.isAuthorized,
		loggingIn: auth.loggingIn,
    currentUser: user.currentUser,
    currentKYC: user.currentKYC,
    userGigs: [ ...dashboard.activeGigs, ...dashboard.pastGigs ],
    isExtraSmall: globalConfig.isExtraSmall,
		isMobile: globalConfig.isMobile,
		isTablet: globalConfig.isTablet,
		isLaptop: globalConfig.isLaptop,
		currentProfile: user.currentProfile,
	}),
	{
	  logout,
    getActiveGigs,
    getPastGigs,
    getCurrentKYC,
    refreshUnseenMessagesCount,
    getAllChatsWithLastMessages
	}
)
export class NavBar extends Component {
	constructor(props) {
		super(props)
		this.state = {
			//openDropDown: false,
			navbarNotification: null,
			navbarComment: null,
      notificationsOpen: true,
      hidenNotificationTrigger: false,
      isTaxIdModalOpen: false,
      showProfileIncomplete: true

			//navbarSearchOpen: false
		}
    }

    componentDidMount() {
      this.refreshNotificationsTimeInterval = setInterval(() => this.fetchNewMessages(), 30000);
    }

  componentWillUnmount() {
    clearInterval(this.refreshNotificationsTimeInterval);
  }

  fetchNewMessages() {
    const {currentUser} = this.props;

    if (currentUser && currentUser.id) {
      Promise.all([
        this.props.getActiveGigs(currentUser.id),
        this.props.getPastGigs(currentUser.id),
        this.props.getCurrentKYC(),
        this.props.refreshUnseenMessagesCount(),
        this.props.getAllChatsWithLastMessages(),
      ])
    }
  }

  componentDidUpdate(prevProps) {
        const { currentUser } = this.props;

        if(currentUser && currentUser.id && currentUser.id !== _.get(prevProps, 'currentUser.id')) {
            Promise.all([
                this.props.getActiveGigs(currentUser.id),
                this.props.getPastGigs(currentUser.id),
                this.props.getCurrentKYC(),
              this.props.refreshUnseenMessagesCount(),
              this.props.getAllChatsWithLastMessages(),
            ])
        }
    }

	redirect = url => {
		//this.handleOnRequestChange()
		this.props.history.push(url)
	}

	//handleOnRequestChange = () => {
	//	this.setState({
	//		openDropDown: false,
	//	})
	//}
//
	//handleToggleDropDown = () => {
	//	this.setState({
	//		openDropDown: !this.state.openDropDown,
	//	})
	//}

	logOut = () => {
		//this.handleOnRequestChange()
		this.props.logout()
		this.props.history.push('/')
	}

	_onTryToCreateGig = () => {
		const {
			currentUser,
			currentUser: {
				profile_type,
				account_info_completion: { has_email, has_name, has_profile_type },
				profile: { cover_img, avatar },
				media_items,
			},
			history,
		} = this.props

		allowCreateGig(this.props.currentUser, this.props.currentKYC, this.props.userGigs, this.props.history)
	}

	render() {
		const {
			isAuthorized,
			currentUser,
			location: { pathname },
			isMobile,
			isTablet,
			isLaptop,
		} = this.props

		const discoverUrl = currentUser && currentUser.profile_type === 'client'
			? '/discover/artists'
			: '/discover/GigFeed'


		return (
			<div className="Navbar__Wrapper">
				<div className="Navbar">
					<div className="Navbar__LogoWrapper">
						<Link
							to='/'
							className="link-logo"
						>
							<img src={whiteLogo} alt="Gigworks logo" style={{ height: '100%', maxHeight: 55, minWidth: 55, width: 'auto' }}/>
							{/* <img className={classNames(b('logo')(), 'hide-md')} src={whiteLogo} alt="Gigworks" /> */}
						</Link>
					</div>

					<div className="SearchStuffWrapper" style={{ width: 256 }}>
					{
						!isMobile && pathname !== '/search' &&
							<SearchField redirect={this.redirect} />
					}
					</div>

					<div className="ButtonLinkGroup">
						<ButtonLink
							to={discoverUrl}
							label={translate('navbar.discover')}
							active={this.isSelectedPage('discover')}
						/>

						{isAuthorized && (
							<ButtonLink
								to={'/dashboard'}
								label={translate('navbar.dashboard')}
								active={this.isSelectedPage('dashboard')}
							/>
						)}

						{/*<ButtonLink
							to={'/'}
							label={'Community'}
						/>*/}
					</div>

					<div className="d-flex align-items-center mr-0 ml-auto">
						{this.renderRightSideStuff(discoverUrl)}
					</div>
				</div>
        <Modal
          isOpen={this.state.isTaxIdModalOpen}
          onClose={this.closeFillTaxIdModal}
        >
          <div style={{ padding: '40px 0'}}>
            <p>
              Please fill your tax id before process
            </p>

            <TaxIdForm onSuccess={this.state.callback} onClose={this.closeFillTaxIdModal} />
          </div>
        </Modal>

        <Modal
          isOpen={this.state.showProfileIncomplete && this.props.currentUser &&
          !get(this.props.currentUser, 'is_complete') && get(this.props.currentUser, 'approved')}
          onClose={this.handleClick}
        >
          <div style={{ padding: '40px 0'}}>
            <p>
              Your profile is missing required information!
            </p>
            <div>
              <button className="btn btn-danger" style={{margin: '0 auto', display: 'block'}} onClick={this.handleClick}>Complete my profile</button>
            </div>
          </div>
        </Modal>
			</div>
		)
	}

  tryExecute = (callback) => {
    const { currentUser } = this.props;
    if (get(currentUser, 'profile_type') === 'client') {
      callback && callback()
      return
    }

    const tax_id = _.get(currentUser, 'profile.tax_id')

    const hasTaxIdFilled = tax_id && tax_id.length > 3
    if (hasTaxIdFilled) {
      callback && callback()
    } else {
      this.openFillTaxIdModal(callback);
    }
  };

  openFillTaxIdModal = (callback) => {
    this.setState({ isTaxIdModalOpen: true, callback: callback })
  };

  closeFillTaxIdModal = () => {
    this.setState({ isTaxIdModalOpen: false })
  };

  handleClick = () => {
    this.props.history.push("/profile/edit");
    this.setState({ showProfileIncomplete: false })
  }
	isSelectedPage(path) {
		return this.props.match.url.split('/').indexOf(path) !== -1
	}

	renderRightSideStuff = (discoverUrl) => {
		const {
			isAuthorized,
			currentUser,
      location: { pathname },
      isExtraSmall,
			isMobile,
			isTablet,
			isLaptop,
			unseenInboxCount,
			currentProfile,
			currentKYC
		} = this.props;

		const hidenStyle = { visibility: 'hidden', width: '0' }

		if (isAuthorized && currentUser) {
			return [
				...(!isTablet ? [
				  <Button
            key="CreateNewGigButton"
            className="CreateNewGigButton"
            label={translate('navbar.create')}
            onClick={() => this.tryExecute(this._onTryToCreateGig)}
            disabled={!currentKYC && !currentProfile.skip_kyc && currentUser.profile_type !== 'client'}
          />
          ] : []),

        ...(isMobile ? [<ButtonLink
          to={discoverUrl}
          label={translate('navbar.discover')}
          active={this.isSelectedPage('discover')}
        />] : []),

        <div key="notificationPopoverDiv" style={isExtraSmall ? hidenStyle : {}}>
          <NotificationsPopover hidenNotificationTrigger={this.state.hidenNotificationTrigger} />
        </div>,

        <div key="messageNotificationPopoverDiv" style={isExtraSmall ? hidenStyle : {}}>
          <MessageNotificationsPopover hidenNotificationTrigger={this.state.hidenNotificationTrigger} />
        </div>,

				...(isLaptop && !pathname !== '/search' ? [
				  <IconButton
            key="iconButtonSearch"
            className="IconButton"
            style={ICON_BUTTON_STYLES}
            onClick={() => {this.redirect(`/search`)}}
          >
            <SVGInline svg={search_icon_svg} fill="#fff" className="IconButton__Icon"  width={`32px`} />
				  </IconButton>] : []),

				<DropDownMenu
          key="dropDownMenu"
					handleCreateGigTry={this._onTryToCreateGig}
					handleLogout={this.logOut}
          isAuthorized={true}
          redirect={this.redirect}
          openFakeNotificationPopover={() => this.setState({ hidenNotificationTrigger: !this.state.hidenNotificationTrigger })}
					trigger={<IconButtonWrapper
						key="iconButtonNavBar"
						className="UserPicWithMenu"
						>
						<div className={classNames(b('menu'), 'd-flex justify-content-center align-items-center')}>
							<UserPic
								href="#!"
								src={(currentUser &&
									currentUser.profile &&
									currentUser.profile.avatar) || null}
								className={b('avatar')}
                profileCompleted = {(currentUser &&
                  currentUser.profile &&
                  currentUser.profile.is_complete) || null}
							/>
						</div>
					</IconButtonWrapper>}
				/>
			]
		} else if (isAuthorized && !currentUser) {
			return <InlinePreLoader />
		} else if (!isAuthorized && isMobile) {
			return <DropDownMenu
				trigger={<i
					className="fa fa-bars"
					aria-hidden="true"
					style={{ color: '#fff', fontSize: '1.8rem' }}
				/>}
				isAuthorized={false}
			/>
		} else {
			return [
				<LandingButton to="/register" key="navbarRegister">
					{translate('navbar.singUp')}
				</LandingButton>,
				<LandingButton transparent className={'ml-2'} to="/login" key="navbarLogin">
					{translate('navbar.logIn')}
				</LandingButton>
			]
		}
	}

	// renderMobileSearch = () => {
	// 	const { isMobile } = this.props;
	// 	const { navbarSearchOpen } = this.state;

	// 	if (!isMobile) return null;

	// 	return navbarSearchOpen ? <div className="d-flex">
	// 			<SearchField
	// 				redirect={this.redirect}
	// 				style={{
	// 					position: 'absolute',
	// 					left: '40px',
	// 					width: 'calc(100% - 110px)',
	// 					justifyContent: 'end'
	// 				}}
	// 			/>
	// 			<CloseButton
	// 				style={{ }}
	// 				iconProps={{
	// 					width: '24px',
	// 					fill: '#fff'
	// 				}}
	// 				onClick={() => this.setState({ navbarSearchOpen: false })}
	// 			/>
	// 		</div> : <IconButton
	// 			className={b('notification')}
	// 			onClick={() => this.setState({ navbarSearchOpen: true })}
	// 			style={{ marginRight: '11px' }}
	// 			>
	// 			<SVGInline svg={search_icon_svg} fill="#fff" width={`32px`} />
	// 		</IconButton>
	// }

	// renderButtonToolbar = () => {
	// 	const { isAuthorized, currentUser, loggingIn, location: { pathname } } = this.props
	// 	const { openDropDown } = this.state
	// 	if (isAuthorized && currentUser) {
	// 		return (
	// 			<div className={b('signed-in')}>
	// 				<div className="NavBar-right">
	// 					<div className={b('create-gig')}>
	// 						}
	// 						<div className="CreateNewGigButton" onClick={this._onTryToCreateGig}>
	// 							{translate('navbar.create')}
	// 						</div>
	// 					</div>

	// 					{ pathname !== '/search' && this.renderMobileSearch()}

	// 					<IconButton
	// 						className={b('notification')}
	// 						onClick={() => { console.log('SPRINT 3')}}
	// 						>
	// 						<SVGInline svg={bell} fill="#fff" />
	// 						{this.state.navbarNotification && (
	// 							<div className="message-bell">
	// 								<span>{this.state.navbarNotification}</span>
	// 							</div>
	// 						)}
	// 					</IconButton>

	// 					<IconButton
	// 						className={b('message')}
	// 						onClick={() => {this.redirect(`/dashboard/inbox`)}}
	// 						>
	// 						<SVGInline svg={comment} fill="#fff" />
	// 						{this.state.navbarComment && (
	// 							<div className="message-comment">
	// 								<span>{this.state.navbarComment}</span>
	// 							</div>
	// 						)}
	// 					</IconButton>

	// 					<IconMenu
	// 						popover={{
	// 							className: b('drop-menu-popover'),
	// 						}}
	// 						iconButtonElement={
	// 							<IconButtonWrapper key="iconButtonNavBar">
	// 								<div className={classNames(b('menu')(), 'd-flex justify-content-center align-items-center show-sm')}>
	// 									<UserPic
	// 										href="javascript://"
	// 										src={(currentUser && currentUser.profile && currentUser.profile.avatar) || null}
	// 										className={b('avatar')}
	// 									/>
	// 								</div>
	// 								<IconButton style={{ margin: '0 1rem' }} className={'hide-sm'}>
	// 									<i className="fa fa-bars" aria-hidden="true" />
	// 								</IconButton>
	// 							</IconButtonWrapper>
	// 						}
	// 						anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
	// 						targetOrigin={{ horizontal: 'right', vertical: 'top' }}
	// 						open={openDropDown}
	// 						listStyle={{
	// 							cursor: 'pointer',
	// 							background: '#000',
	// 						}}
	// 						menuStyle={{
	// 							backgroundColor: '#000',
	// 						}}
	// 						onRequestChange={this.handleToggleDropDown}
	// 					>
	// 						<div className={b('dropdown')}>
	// 							<div className={b('dropdown-item mobile-item')} onClick={() => this.redirect(`/discover/feed`)}>
	// 								{translate('navbar.discover')}
	// 							</div>
	// 							<div className={b('dropdown-item mobile-item')} onClick={() => this.redirect(`/dashboard`)}>
	// 								{translate('navbar.dashboard')}
	// 							</div>
	// 							<div className={b('dropdown-item mobile-item')} onClick={this._onTryToCreateGig}>
	// 								{translate('navbar.create')}
	// 							</div>
	// 							<div className={b('dropdown-item')} onClick={() => this.redirect(`/profile`)}>
	// 								{translate('navbar.myAccount')}
	// 							</div>
	// 							{/*<div className={b('dropdown-item')}>*/}
	// 							{/*Settings*/}
	// 							{/*</div>*/}
	// 							<div className={b('dropdown-item')} onClick={this.logOut}>
	// 								{translate('navbar.logOut')}
	// 							</div>
	// 						</div>
	// 					</IconMenu>
	// 				</div>
	// 			</div>
	// 		)
	// 	} else if (!loggingIn && !isAuthorized) {
	// 		return (
	// 			<div className="logUp">
	// 				{['gigworks.com', 'www.gigworks.com'].indexOf(window.location.hostname) === -1 && (
	// 					<div className={b('sign-up')}>
	// 						<LandingButton to="/register" className={'sign_up'}>
	// 							{translate('navbar.singUp')}
	// 						</LandingButton>
	// 						<LandingButton transparent className={'sign_in'} to="/login">
	// 							{translate('navbar.logIn')}
	// 						</LandingButton>
	// 					</div>
	// 				)}

	// 				{ pathname !== '/search' && this.renderMobileSearch()}
	// 				<IconMenu
	// 					popover={{
	// 						className: b('drop-menu-popover'),
	// 					}}
	// 					iconButtonElement={
	// 						<IconButtonWrapper key="iconButtonNavBar">
	// 							<div className={classNames(b('menu')(), 'd-flex justify-content-center align-items-center show-sm')} />
	// 							<IconButton style={{ margin: '0 1rem' }} className={'hide-sm'}>
	// 								<i className="fa fa-bars" aria-hidden="true" />
	// 							</IconButton>
	// 						</IconButtonWrapper>
	// 					}
	// 					anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
	// 					targetOrigin={{ horizontal: 'right', vertical: 'top' }}
	// 					open={openDropDown}
	// 					listStyle={{
	// 						cursor: 'pointer',
	// 						background: '#000',
	// 					}}
	// 					menuStyle={{
	// 						backgroundColor: '#000',
	// 					}}
	// 					onRequestChange={this.handleToggleDropDown}
	// 				>
	// 					<div className={b('dropdown')}>
	// 						<div className={b('dropdown-item mobile-item')} onClick={() => this.redirect(`/discover/feed`)}>
	// 							{translate('navbar.discover')}
	// 						</div>
	// 						<div className={b('dropdown-item mobile-item')} onClick={() => this.redirect(`/register`)}>
	// 							{translate('navbar.singUp')}
	// 						</div>
	// 						<div className={b('dropdown-item mobile-item')} onClick={() => this.redirect(`/login`)}>
	// 							{translate('navbar.logIn')}
	// 						</div>
	// 					</div>
	// 				</IconMenu>
	// 			</div>
	// 		)
	// 	} else {
	// 		return <InlinePreLoader />
	// 	}
	// }
}

export default withRouter(NavBar)


class SearchField extends Component {
	constructor(props) {
		super(props)

		this.inputRef = null
		this.setInputRef = element => {this.inputRef = element}

		this.handleKeyPress = this.handleKeyPress.bind(this);
		this.handleSearch = this.handleSearch.bind(this);
	}

	handleKeyPress(e) {
		if (e.key === 'Enter') this.handleSearch();
	  }

	handleSearch() {
		const value = this.inputRef.input.value;
		this.props.redirect({
            pathname: '/search',
            search: `?q=${value}`
		});
	}

	render() {
		return (
			<div style={{
				display: 'flex',
				alignItems: 'center',
				position: 'relative',
				...this.props.style
			}}>
				<TextField
					ref={this.setInputRef}
					hintText='Search'
					hintStyle={{ color: '#ccc' }}
					style={{ marginLeft: '24px' }}
					onKeyPress={this.handleKeyPress}
				/>

				<IconButton
					style={{ padding: 0, position: 'absolute', right: 0 }}
					onClick={this.handleSearch}
				>
					<SVGInline
						svg={search_icon_svg}
						height='28px'
						width='28px'
						fill={'#ccc'}
					/>
				</IconButton>
			</div>
		)
	}
}

const ButtonLink = ({ active, label, to }) => <Link
	to={to}
	className={`ButtonLink ${active && 'active'}`}
	>
		{label}
</Link>

class DropDownMenu extends React.PureComponent {
	constructor(props) {
		super(props);
		this.state = {
			openDropDown: false,
		}
	}

	handleToggleDropDown = () => {
		this.setState({ openDropDown: !this.state.openDropDown })
	}

	render() {
		const { openDropDown } = this.state;
		const { handleCreateGigTry, handleLogout, isAuthorized, trigger, redirect } = this.props;

		return (
        <IconMenu
			open={openDropDown}
			popover={{
				className: b('drop-menu-popover'),
			}}
			iconButtonElement={trigger}
			anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
			targetOrigin={{ horizontal: 'right', vertical: 'top' }}
			listStyle={{
				cursor: 'pointer',
                background: '#000',
                zIndex: '100'
			}}
			menuStyle={{
				backgroundColor: '#000',
			}}
			onRequestChange={this.handleToggleDropDown}
		>
			<div className={b('dropdown')}>
        <div className="icon-wrapper">
          <div onClick={() => this.handleToggleDropDown()}>
            <NotificationsPopover
              openFakeNotificationPopover={this.props.openFakeNotificationPopover}
            />
          </div>
          <div>
            <IconButton
              className="IconButton"
              style={ICON_BUTTON_STYLES}
              onClick={() => {
                redirect(`/dashboard/inbox`)
                this.handleToggleDropDown()
              }}
            >
              <SVGInline svg={comment} fill="#fff" className="IconButton__Icon" />
            </IconButton>
          </div>
        </div>
        <div className={b('dropdown-item mobile-item')}>
					<Link to="/discover/GigFeed" onClick={this.handleToggleDropDown}>
						{translate('navbar.discover')}
					</Link>
				</div>
				<div className={b('dropdown-item mobile-item')}>
					<Link to="/search" onClick={this.handleToggleDropDown}>
						Search
					</Link>
				</div>
				<div className={b('dropdown-item mobile-item')}>
					<Link to="/" onClick={this.handleToggleDropDown}>
						{'Community'}
					</Link>
				</div>
				{ isAuthorized ? [
				<div key="navbarDashboard" className={b('dropdown-item mobile-item')}>
					<Link to="/dashboard" onClick={this.handleToggleDropDown}>
						{translate('navbar.dashboard')}
					</Link>
				</div>,
				<div
          key="navbarCreate"
					className={b('dropdown-item mobile-item')}
					onClick={handleCreateGigTry}>
					{translate('navbar.create')}
				</div>,
        <div key="navbarSettings" className={b('dropdown-item')}>
          <Link to="/profile/settings" onClick={this.handleToggleDropDown}>
            {translate('navbar.settings')}
          </Link>
        </div>,
				<div key="navbarMyAccount" className={b('dropdown-item')}>
					<Link to="/profile" onClick={this.handleToggleDropDown}>
						{translate('navbar.myAccount')}
					</Link>
				</div>,
				<div
          key="navbarLogOut"
					className={b('dropdown-item')}
					onClick={handleLogout}>
					{translate('navbar.logOut')}
				</div>,
				] : [
					<div className={b('dropdown-item')}>
						<Link to="/register" onClick={this.handleToggleDropDown}>
							{translate('navbar.singUp')}
						</Link>
					</div>,
					<div className={b('dropdown-item')}>
						<Link to="/login" onClick={this.handleToggleDropDown}>
							{translate('navbar.logIn')}
						</Link>
					</div>,
				]
			}
			</div>
		</IconMenu>)
	}
}
