import typeToReducer from 'type-to-reducer';

const COMPARE_ADD_ITEM = 'COMPARE_ADD_ITEM';
const COMPARE_REMOVE_ITEM = 'COMPARE_REMOVE_ITEM';
const COMPARE_CLEAR = 'COMPARE_CLEAR';
const COMPARE_OPEN_MODAL = 'COMPARE_OPEN_MODAL';
const COMPARE_CLOSE_MODAL = 'COMPARE_CLOSE_MODAL';
const MAX_ITEMS_TO_COMPARE = 3;

const initialState = {
  itemsToCompare: [],
  isOpenPopUp: false,
  isOpenModal: false,
};

const compare = typeToReducer({
  COMPARE_ADD_ITEM: (state, action) => {
    if (action.item.selected || state.itemsToCompare.length === MAX_ITEMS_TO_COMPARE ) {
      return { ...state };
    }
    action.item.selected = true;
    const itemsToCompare = [...state.itemsToCompare, action.item];

    return { ...state, itemsToCompare, isOpenPopUp: itemsToCompare.length > 0 };
  },
  COMPARE_REMOVE_ITEM: (state, action) => {
    if (!action.item.selected) {
      return { ...state };
    }
    action.item.selected = false;
    const index = state.itemsToCompare.findIndex(x => x.id === action.item.id);

    if (index !== -1) {
      state.itemsToCompare[index].selected = false;
      state.itemsToCompare.splice(index, 1);
      const itemsToCompare = [...state.itemsToCompare];

      return { ...state, itemsToCompare, isOpenPopUp: itemsToCompare.length > 0 };
    } else {
      return state;
    }
  },
  COMPARE_OPEN_MODAL: (state, action) => {
    return { ...state, isOpenModal: true };
  },
  COMPARE_CLOSE_MODAL: (state, action) => {
    return { ...state, isOpenModal: false };
  },
  COMPARE_CLEAR: (state, action) => {
    for (let i = 0; i < state.itemsToCompare.length; i++) {
      state.itemsToCompare[i].selected = false;
    }

    return initialState;
  }
}, initialState);

/**
 * 
 * @param {object} item user profile 
 */
export function addItemToCompare(item) {
  return {
    type: COMPARE_ADD_ITEM,
    item
  };
}


/**
 * 
 * @param {object} item user profile object, but we use id only
 */
export function removeItemFromCompare(item) {
  return {
    type: COMPARE_REMOVE_ITEM,
    item
  };
}

export function clearCompare() {
  return {
    type: COMPARE_CLEAR,
  };
}

export function openModalCompare() {
  return {
    type: COMPARE_OPEN_MODAL,
  };
}

export function closeModalCompare() {
  return {
    type: COMPARE_CLOSE_MODAL,
  };
}

export default compare;
