import React from 'react'
import { connect } from 'react-redux'
import * as _ from 'lodash'
import { RadioButton, RadioButtonGroup } from 'material-ui/RadioButton'
import GeminiScrollbar from 'react-gemini-scrollbar'
import PropTypes from 'prop-types'
import SVGInline from 'react-svg-inline'
import get from 'lodash/get'
import sortBy from 'lodash/sortBy'
import './SearchBlock.scss'
import 'gemini-scrollbar/gemini-scrollbar.css'
import { createSubcategorySelector } from '../../../../redux/selectors/category'
import apiClient from '../../../../helpers/apiClient';
import Loader from '../../Loader/Loader';
import { RecomendationProfile } from '../../RecomendationProfile/RecomendationProfile';
import SearchBlockAdvertising from '../../SearchBlockAdvertising/SearchBlockAdvertising';

const coverImg = require('../../../../assets/categories/default.jpg')
const squareSvg = require('!!svg-inline-loader?classPrefix!../../../../assets/square.svg')

const svgProps = {
	style: {
		display: 'block',
		margin: '3px',
		width: '18px',
		height: '18px',
		overflow: 'hidden',
	},
	width: '18px',
	height: '18px',
	svg: squareSvg,
}

const SVG_ACTIVE_COLOR = '#ffd210'
const SVG_DISABLED_COLOR = '#ededed'

@connect(({ discover }) => ({
	usersByCategories: discover.usersByCategories,
	isFetchingUsersByCategories: discover.isFetchingUsersByCategories,
}))
class SearchBlock extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
		  activeItem: null,
		  recommendedProfiles: [],
		};
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		if (this.props.usersByCategories.length === 0 &&
			nextProps.usersByCategories.length > 0) {
			this.setState({
				recommendedProfiles: _.sampleSize(nextProps.usersByCategories, 3)
			})
		}

    if (this.props.categories.length === 0
      && nextProps.categories.length > 0
      && this.props.categoryName
    ) {
      const categoryName = this.props.categoryName.replace(/([A-Z])/g, ' $1').trim();
      const selectedCategory = nextProps.categories.filter(category => category.name === categoryName);

      if (Array.isArray(selectedCategory) && selectedCategory.length > 0) {
        const activeItem = selectedCategory[0];
        this.setState({
          activeItem: {id: activeItem.id, name: activeItem.name, isFinal: !activeItem.is_parent}
        })
      }
    }
	}

  isLoading = () => {
	  return this.state.loading || this.props.isFetchingUsersByCategories
	}

	render() {
		const { categories, rootCategory, onChange, isFetchingAds, adsByCategory } = this.props
		const { activeItem, recommendedProfiles, loading } = this.state
		const categoriesSorted = sortBy(categories, 'name')

		return (
			<div className="SearchBlock">
				<div className="col-sm-12 col-lg-6 col-xl-5 filter">
					<div className="col-6 d-flex flex-column">
						<div className="filter-header">
							<h3>{rootCategory && rootCategory.name}</h3>
							<div className="d-flex flex-wrap flex-row justify-content-between">
								<span className="title">Select what you want to see</span>
							</div>
						</div>
						<GeminiScrollbar className="categories">
							<RadioButtonGroup
								name="selectedCategory"
								onChange={(_, value) => {
									this.setState({ activeItem: value })
									onChange(value)
								}}
							>
								{categoriesSorted.map((category, idx) => (
									<RadioButton
										key={idx}
										className="categoryCheckBox"
										label={
											<div className="categoryCheckBox__label">
												{category.name}
												&nbsp;
												<span>({category.users_count})</span>
											</div>
										}
										value={{ id: category.id, name: category.name, isFinal: !category.is_parent }}
										checkedIcon={<SVGInline {...{ ...svgProps, fill: SVG_ACTIVE_COLOR }} />}
										uncheckedIcon={
											<SVGInline
												{...{
													...svgProps,
													fill: activeItem && activeItem.id === category.id ? SVG_ACTIVE_COLOR : SVG_DISABLED_COLOR,
												}}
											/>
										}
									/>
								))}
							</RadioButtonGroup>
						</GeminiScrollbar>
					</div>
					<div className="col-6">
					{ this.isLoading()
						? <Loader />
						: recommendedProfiles.map(artist =>
							<RecomendationProfile
                key={artist.id}
								id={artist.id}
								name={artist.name}
								avatar={artist.avatar}
								style={get(artist, 'categories.0.name')}
								rating={artist.average_rating}
							/>
					)}
					</div>
				</div>
        <SearchBlockAdvertising isFetchingAds={isFetchingAds} adsByCategory={adsByCategory} />
			</div>
		)
	}
}

SearchBlock.propTypes = {
	parentCategoryId: PropTypes.string,
	onChange: PropTypes.func,
}

export default connect(createSubcategorySelector('common.artistsCategories.all'))(SearchBlock)
