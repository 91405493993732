import React from 'react'
import {personTypes} from "../../../utils/constants";
import {translate} from "../../../translator";
import {Asterisk} from "../../common/Asterisk/Asterisk";
import {Field} from "redux-form";
import {
  BirthDatePickerComponent,
  CheckboxComponent,
  GoogleMapsAutocomplete,
  SelectFieldComponent,
  TextFieldComponent
} from "../../CommonComponent";
import Loader from "../../discover/Loader/Loader";
import RenderField from "../../RenderField";
import {registrationConfig} from "../../../config";
import {FileInput} from "./FileInput";

/**
 * Kyc form for clients.
 */
export default function KycClientForm(props) {

  const {showValidationError, touch, isPersonTypeSelected, selectPersonType, countriesList } = props;

  return (
    <>
      <div className="section divide">
        <div className="button_column">
                    <span
                      className={`switch_button ${isPersonTypeSelected(personTypes.Individual) ? 'selected' : ''}`}
                      onClick={() => selectPersonType(personTypes.Individual)}>{translate('kyc.select')}</span>
        </div>
        <div className="content_column">
          <div className="person_type_title">
            <div className="big_header">{translate('kyc.individual')}</div>
            <div dangerouslySetInnerHTML={{__html: translate('kyc.individualSub')}}></div>
          </div>

          {isPersonTypeSelected(personTypes.Individual) &&
          <form className="form-wrapper mobile-full-width">
            <div className="form-section">
              <div className="section-header">
                {translate('kyc.legalName')} <Asterisk/>
              </div>
              <Field
                name="kyc_first_name"
                type="text"
                fullWidth
                component={TextFieldComponent}
                hintText={translate('kyc.firstName')}
              />
              <Field
                name="kyc_last_name"
                type="text"
                fullWidth
                component={TextFieldComponent}
                hintText={translate('kyc.lastName')}
              />
            </div>
            <div className="form-section">
              <div className="section-header">
                {translate('kyc.nationality')} <Asterisk/>
              </div>
              <div className="" style={{width: '100%'}}>
                {(countriesList && countriesList.length) ?
                  <Field
                    name="kyc_nationality"
                    type="string"
                    className="select-nationality"
                    required
                    fullWidth
                    style={{height: '3rem', textAlign: 'left'}}
                    options={countriesList}
                    hintText="Please select your nationality"
                    component={SelectFieldComponent}
                  />
                  :
                  <div className="d-flex justify-content-center">
                    <Loader/>
                  </div>
                }
              </div>
            </div>
            <div className="form-section">
              <div className="section-header">
                {translate('kyc.dateOfBirth')} <Asterisk/>
              </div>
              <div className="birth-date" style={{width: '100%'}}>
                <Field name="kyc_birth_date" hintText="Birth day" component={BirthDatePickerComponent}/>
              </div>
              <div className="">
                <Field
                  name="kyc_over18"
                  label={translate('kyc.iConfirm')}
                  labelStyle={{width: 'initial'}}
                  component={CheckboxComponent}
                />
              </div>
            </div>
            <div className="form-section pb-0">
              <div className="section-header">
                {translate('kyc.emailAsUser')}
              </div>
              <Field
                name="kyc_email"
                type="text"
                component={RenderField}
                classNames="input-field"
                placeholder="Email"
              />
            </div>
            <div className="form-section">
              <div className="section-header">
                                          <span className="fa-stack info-popup-window"
                                                data-title={registrationConfig.popupText.artistContactInformation}>
                                              <i className="fa fa-circle-thin fa-stack-2x"/>
                                              <i className="fa fa-info fa-stack-1x"/>
                                          </span>
                {translate('kyc.contactInformation')} <Asterisk/>
              </div>
              <div className="google-location-input" style={{width: '100%'}}>
                <Field
                  makeTouched={() => touch('location')}
                  onBlur={() => touch('location')}
                  fullWidth
                  name="kyc_location"
                  component={GoogleMapsAutocomplete}
                  hintText="Enter Address"
                  hintStyle={{
                    fontWeight: '300',
                    color: '#a3a3a3',
                  }}
                  style={{
                    textAlign: 'left',
                    marginRight: '20px',
                  }}
                />
              </div>
            </div>

            <div className="horizontal_line extension_left"/>

            <div className="form-section">
              <div className="section-header">
                {translate('kyc.requiredDocs')} <Asterisk/>
              </div>
              <div className="doc-input">
                <div>
                  {translate('kyc.identityProof')}
                </div>
                <Field
                  name="kyc_ind_identity_proof"
                  type="file"
                  showValidationError={showValidationError}
                  component={FileInput}
                />
              </div>
            </div>

          </form>
          }
        </div>
      </div>
    </>
  );
}
