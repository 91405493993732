import React from 'react'
import Rating from 'react-rating'
import Paper from 'material-ui/Paper'
import RaisedButton from 'material-ui/RaisedButton'
import moment from 'moment'
import { withRouter } from 'react-router-dom'
import ReactGA from 'react-ga';
import { compose } from 'recompose'
import { UserPic } from '../UserPic/UserPic'
import SVGInline from 'react-svg-inline'
import './GigCard.scss'
import getSymbolCurrency from '../../../helpers/getSymbolCurrency'
import { get } from 'lodash'
import { connect } from 'react-redux'
import SharedPopUpSocialNetworks from './../../common/ProfileCard/SharedPopUpSocialNetworks/SharedPopUpSocialNetworks'
import notification from 'antd/es/notification'
import 'antd/es/notification/style/css'
const brand_default = require('../../../assets/brand.png')
import * as _ from 'lodash'
import { applyGig, saveGig } from '../../../redux/modules/actions'
import { getCurrentUser } from '../../../redux/modules/user'
import GigDetailsPopup from '../../discover/GigFeedTab/GigDetailsPopup/GigDetailsPopup';
const iconShareCard = require('!!svg-inline-loader?classPrefix!./../../../assets/share-icon.svg')


const CARD_RAISED_BUTTON_LABEL_STYLE = {
	color: 'black',
	fontWeight: 600,
	fontSize: '14px',
	margin: 0
}
const CARD_RAISED_BUTTON_LABEL_STYLE_DISABLE = {
  fontWeight: 600,
  fontSize: '14px',
  margin: 0,
  color: '#ffffff',
}
const CARD_RAISED_BUTTON_BUTTON_STYLE = {
	borderRadius: '0',
	borderTop: '1px solid #d3d3d3',
	height: '2.6rem',
	margin: 0
}
const CARD_RAISED_BUTTON_BUTTON_STYLE_DISABLE = {
  borderRadius: '0',
  borderTop: '1px solid #d3d3d3',
  height: '2.6rem',
  margin: 0,
  color: '#ffffff',
  cursor: 'not-allowed',
}


/**
* typical GigCard -
* apply prop is now not required (!) - it uses his own method - applyBtnHandler
* todo create createBtnHandler
* @param {bool} noMargin card have no margins
* @param {bool} noSaveButton only apply button
* @param {bool} noShadow hide shadow
* @param {bool} noButtons hide both buttons
* @param {node} customButtons buttons to show instead of default save and apply, it gives a buttons right class and styles also
* @param {function} callback after apply or save action resolved with ({ action,  success })
*/

class GigCard extends React.PureComponent {
	constructor(props) {
		super(props);
		this.state = {
			isDetailsPopupOpened: false,
			isShowSharePopup: false,
		}
	}

	applyGig = () => {
		const { currentUser, applyGig, gig: { id }, callback } = this.props;

		if (get(currentUser, 'profile_type') !== 'client' && !get(currentUser, 'is_complete')) {
			notification.error({
				message: 'Profile incomplete!',
				description: `In order to apply to gigs, please complete your profile first.`,
			})
		}
		else {
			applyGig(id)
				.then(_ => {
					notification.success({
						message: 'Congratulation!',
						description: 'You have successfully applied gig.',
					})
					callback && callback({ action: 'APPLY', success: true })
				})
				.catch(err => {
					notification.error({
						message: 'Error!',
						description: _.get(err.response, 'data.message', 'Sorry, error please try again later'),
					})
					callback && callback({ action: 'APPLY', success: false })
				})
		}
	}

  canApply = (gigType, profileType) => {
    if(!profileType || !gigType) return false;

    switch(profileType) {
      case 'client': return false;
      case 'venue': return gigType.includes('venue');
      default: return !gigType.includes('venue');
    }
  };

	applyBtnHandler = () => {
		const { gig, currentUser, history } = this.props;
		const gigId = gig.id;
		const gigType = gig.performer_type && gig.performer_type.includes("venue") ? 'venue' : 'artist';

    if (!currentUser || !currentUser.id){
      history.push('/login');
    } else if (!currentUser || !currentUser.profile_type || !currentUser.email || !currentUser.profile.name || !currentUser.is_complete) {
      notification.error({
          message: 'Profile incomplete!',
          description: `In order to apply to gigs, please complete your profile first.`,
      })
		} else if (this.canApply(gig.performer_type, currentUser.profile_type)) {
      this.applyGig()
		} else {
			notification.error({
				message: 'Error!',
				description: `You can\'t apply to ${gigType} gig.`,
			})
		}
	};

	saveBtnHandler = gigId => {
		const { currentUser, callback, history } = this.props;

		if (!currentUser || !currentUser.id){
      history.push('/login');
    } else if (!currentUser || !currentUser.profile_type || !currentUser.email || !currentUser.profile.name || !currentUser.is_complete) {
		  notification.error({
        message: 'Profile incomplete!',
        description: `In order to save the gig, please complete your profile first.`,
      })
		} else {
			this.props.saveGig(gigId)
				.then(res => {
					notification.success({
						message: 'Congratulation!',
						description: 'You have successfully saved gig.',
					})
					callback && callback({ action: 'SAVE', success: true })
				})
				.catch(err => {
					console.log(err.response)
					notification.error({
						message: 'Error!',
						description: err.response.data.message,
					})
					callback && callback({ action: 'SAVE', success: false })
				})
		}
	}


	onViewMore(gigId) {
		ReactGA.event({
			category: 'Gig Feed',
			action: 'View Gig'
		});
		this.props.onViewMore ?
			this.props.onViewMore(gigId) :
			this.setState({ isDetailsPopupOpened: true })
	}

	isVenue = type => {
		return type && type.includes('venue')
    }

    getEventLength() {
        const { gig } = this.props
        const unit = gig.event_length > 1 ? `${gig.event_length_unit}s` : `${gig.event_length_unit}`
        return `${gig.event_length} ${unit}`
    }

	setShowSharePopup = value => this.setState({ isShowSharePopup: value })

	getButtonsSection = () => {
		const {
			gig,
			apply = this.applyBtnHandler,
			save = this.saveBtnHandler,
			noButtons,
			customButtons,
			noSaveButton,
      currentProfile,
      currentKYC,
      currentUser
		} = this.props;

		if (customButtons) return <div className="GigCard__buttons-toolbar">
			{
				customButtons.map(x => {
					const { labelStyle, buttonStyle, ..._restProps } = x.props;

					return <x.type
						className="GigCard__buttons-toolbar_apply-button"
						buttonStyle={{ ...CARD_RAISED_BUTTON_BUTTON_STYLE, ...buttonStyle }}
						labelStyle={{ ...CARD_RAISED_BUTTON_LABEL_STYLE, ...labelStyle }}
						{..._restProps}
					/>
				})
			}
		</div>

		if (noButtons) return null

		return <div className="GigCard__buttons-toolbar">
			<RaisedButton
				className="GigCard__buttons-toolbar_apply-button"
				label="Apply"
        buttonStyle={(currentUser && !currentKYC && !currentProfile.skip_kyc && currentUser.profile_type !== 'client') ? CARD_RAISED_BUTTON_BUTTON_STYLE_DISABLE : CARD_RAISED_BUTTON_BUTTON_STYLE}
        labelStyle={(currentUser && !currentKYC && !currentProfile.skip_kyc && currentUser.profile_type !== 'client') ? CARD_RAISED_BUTTON_LABEL_STYLE_DISABLE : CARD_RAISED_BUTTON_LABEL_STYLE}
				onClick={() => apply(gig.id)}
        disabled={currentUser && !currentKYC && !currentProfile.skip_kyc && currentUser.profile_type !== 'client'}
			/>

			{!noSaveButton && <RaisedButton
				className="GigCard__buttons-toolbar_save-button"
				label="Save"
				buttonStyle={{
					...CARD_RAISED_BUTTON_BUTTON_STYLE,
					borderLeft: '1px solid #d3d3d3',
				}}
				labelStyle={CARD_RAISED_BUTTON_LABEL_STYLE}
				onClick={() => save(gig.id)}
			/>}
		</div>
	}

	render() {
		const {
			gig,
			isShowSharePopup,
			apply = this.applyBtnHandler,
			save = this.saveBtnHandler,
			style,
			noMoreInfoLink,
			noButtons,
			noShadow,
			noMargins,
			customButtons,
			categoriesMap,
      feedCategoryName = null,
			noSaveButton,
			getCurrentUser,
		} = this.props;

		//const city = this.isVenue(gig.performer_type) ? get(gig, 'user.location.city', '') : get(gig, 'location.city', '')
		//const country = this.isVenue(gig.performer_type) ? get(gig, 'user.location.country', '') : get(gig, 'location.country', '')
		const city = get(gig, 'location.city', '')
		const country = get(gig, 'location.country', '')
		const avatar = get(gig, 'user.avatar', null)
		const user = get(gig, 'user', null)
		const userName = get(gig, 'user.name', '---')
		const userProfesion = get(gig, 'user.profession')
		const categoryName = get(categoriesMap, `${gig.category_id}.name`, null)
		let styles = get(gig, 'styles', []) || []
		const occasion = styles.find(item => item.type === "venue_occasion" || item.type === "artist_occasion")
		const occasionName = occasion ? occasion.name : ''

		const buttonsSection = this.getButtonsSection();

		return (
			<Paper
				zDepth={noShadow ? 0 : 3}
				className={`
				GigCard
				${noButtons ? 'GigCard--no-buttons' : ''}
				${noMargins ? 'GigCard--no-margins' : ''}
				`}
				style={style}>
				{isShowSharePopup && (
					<SharedPopUpSocialNetworks
						share={() => this.setShowSharePopup(!isShowSharePopup)}
						urlToShare={`${window.location.origin}`}
					/>
				)}

				<div className="GigCard__paper-header">
					<div className="GigCard__paper-header_head">
						{gig.performer_type === 'artist.basic' ? (
							<div className="signature artist" />
						) : (
								<div className="signature venue">
									<div className="rectangle" />
									<div className="rectangle" />
								</div>
							)}
						<img src={get(gig, 'picture.url', brand_default)} alt="brand" className="brand-logo" />
						<div className="name-head">
							{/* <p className="GigCard-title">{gig.name.length > 20 ? gig.name.substring(0, 20) + '...' : gig.name}</p> */}
							<span className="info-popup-window" data-title={gig.name}>
								<p className="GigCard-title">{gig.name}</p>
							</span>
							<p className="GigCard-category-name">{categoryName}</p>
							{occasionName &&
								<p className="GigCard-category-name">{occasionName}</p>
							}
						</div>
						<SVGInline
							onClick={() => this.setShowSharePopup(!isShowSharePopup)}
							className="shared-button"
							svg={iconShareCard}
							width="22px"
						/>
						<div className="title-gradient" />
					</div>
					<div className="GigCard__paper-header_location">
						<span className="item-location">
							<city>{city ? `${city}, ` : ''}</city>
							{country ? country : ''}
						</span>
					</div>
				</div>
				<div className="GigCard__paper-content">
					<div className="GigCard__paper-content_content-wrapper ">

						<div className="d-flex justify-content-between">
							<span className="item-date">
								{moment(gig.date).format('MMM Do, YYYY')}
							</span>

							<span className="range-time">
								{gig.start_date &&
									gig.end_date &&
									moment(gig.start_date).format('HH:mm:ss') !== moment(gig.end_date).format('HH:mm:ss') &&
									`${moment.utc(gig.start_date).format('h:mm a')} - ${moment.utc(gig.end_date).format('h:mm a')}`}
							</span>
						</div>


						<p>
							{/* {gig.start_date && gig.end_date &&
								moment(gig.start_date).format('HH:mm:ss') !== moment(gig.end_date).format('HH:mm:ss') && (
									<span className="item-hours">
										{Math.abs(moment
											.duration(
												moment
													.utc(gig.end_date)
													.startOf('hour')
													.diff(moment.utc(gig.start_date).startOf('hour'))
											)
											.hours()) + ' hours'}
									</span>
								)
                            } */}
                            {gig.event_length && (
                                <span className="item-hours">{this.getEventLength()}</span>
                            )}



						</p>
					</div>
					<div className="GigCard__paper-content_budget d-flex justify-content-sm-between">
						<span className="flexibility" style={{ color: gig.is_flexible_budget ? '#db3236' : '#47b535' }}>
							{gig.is_flexible_budget ? 'Fixed budget' : 'Flexible budget'}
						</span>
						<span className="currency">{`${gig.budget}${getSymbolCurrency(gig.currency)}`}</span>
					</div>
					<div className="GigCard__paper-content_deadline d-flex justify-content-sm-between">
						<span className="application">Application deadline</span>
						<span className="date">{moment(gig.deadline).format('MMM Do, YYYY')}</span>
					</div>

					{(!this.props.isDetailsPopupOpened || !noMoreInfoLink) && ([
						<div className="GigCard__paper-content_more-info">
							<span className="view-info-button" onClick={() => this.onViewMore(gig.id)}>
								View more info
							</span>
						</div>,
						...(!this.props.onViewMore && this.state.isDetailsPopupOpened ? [<GigDetailsPopup
							isOpened={this.state.isDetailsPopupOpened}
							gig={gig}
							onClose={() => this.setState({ isDetailsPopupOpened: false })}
                            categoriesMap={categoriesMap}
                            noButtons={noButtons}
						/>] : [])
					]
					)}
					<div className="GigCard__paper-content_author d-flex">
						<UserPic size="small" src={avatar} noBg  profileCompleted ={true} />
						<div className="gig-user d-flex flex-column">
							<span className="name text-uppercase">
								{userName}
							</span>
							<span className="events-manager">
								{userProfesion}
							</span>
						</div>
						<div className="rating-stars d-flex align-items-center">
							{user && user.average_rating &&
								<Rating
									readonly
									initialRate={+user.average_rating}
									empty="fa fa-star-o fa-fw"
									full="fa fa-star fa-fw"
								/>
							}
						</div>
					</div>
					{(!this.props.isDetailsPopupOpened || gig.applied_count) && (
						<div className="GigCard__paper-content_people-applied d-flex">
							<span className="count">
								{gig.applied_count ?
									(gig.applied_count === 1 ?
										'1 person has applied' :
										gig.applied_count + ' people have applied'
									)
									: ' '
								}
							</span>
						</div>
					)}
				</div>
				{buttonsSection}
			</Paper>
		)
	}
}

export default compose(
	withRouter,
	connect(({ common, user }) => ({
			categoriesMap: common.categoriesMap,
			currentUser: user.currentUser,
      currentProfile: user.currentProfile,
      currentKYC: user.currentKYC,
		}),
		{ applyGig, saveGig, getCurrentUser }
	)
)(GigCard)
