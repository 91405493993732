import React, { Component } from 'react';
import { connect } from 'react-redux';
import { SliderComponent } from '../components/register/CommonComponent';
import AvatarEditor from 'react-avatar-editor';
import '../styles/components/AvatarEditor.scss';
import ProfileCard from './common/ProfileCard/ProfileCard';

const MAX_FILE_SIZE_IN_MB = 10;

@connect(
  ({ profile }) => ({
    profile: profile.profile
  })
)

export default class CardEditor extends Component {
  fileChanged = false;

  constructor(props) {
    super(props);

    this.state = {
      cardVideoUrl: props.cardVideoUrl || ''
    };
  }

  getDataURL(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();

      reader.onload = () => {
        resolve(reader.result);

        reader.onload = null;
      };

      reader.readAsDataURL(file);
    });
  }

  fileChangeHandler = (evt) => {
    const files = evt.target.files;
    const maxFileSize = MAX_FILE_SIZE_IN_MB * 1024 * 1024;

    if (files[0]) {
      if (files[0].size < maxFileSize) {
        this.getDataURL(files[0])
          .then((data) => {
            this.fileChanged = true;

            this.setState({
              file: files[0],
              photo: data,
            });

            this.positionChangeHandler();
          });
      } else {
        this.props.showValidationError(`${MAX_FILE_SIZE_IN_MB} MB file size exceeded`);
      }
    }
  };

  zoomChangeHandler = (evt, val) => {
    const { input: { onChange, value } } = this.props;

    if (this.state.photo) {
      this.positionChangeHandler({
        x: +value.x,
        y: +value.y,
      }, val - 1);
    }
  };

  positionChangeHandler = (position, zoom) => {
    const { input: { onChange, value } } = this.props;

    let result = {
      ...value,
    };

    if (position) {
      const img = this.editor.getImage();
      const absolutePosition = this.editor.calculatePosition();

      const maxX = this.originalSize.width - img.width;
      const maxY = this.originalSize.height - img.height;

      let offset_x = Math.round(position.x * this.originalSize.width - (img.width / 2));
      let offset_y = Math.round(position.y * this.originalSize.height - (img.height / 2));

      if (offset_x > maxX) {
        offset_x = maxX;
      } else if (offset_x < 0) {
        offset_x = 0;
      }

      if (offset_y > maxY) {
        offset_y = maxY;
      } else if (offset_y < 0) {
        offset_y = 0;
      }

      result = {
        ...result,
        ...position,
        scale_x: img.width / this.originalSize.width,
        scale_y: img.height / this.originalSize.height,
        offset_x,
        offset_y,
      };
    } else {
      result = {
        x: 0.5,
        y: 0.5,
        zoom: 0,
        scale_x: 1,
        scale_y: 1,
        offset_x: 0,
        offset_y: 0,
        ...value,
      };
    }

    if (zoom || zoom === 0) {
      result.zoom = zoom;
    }

    if (this.fileChanged) {
      result.file = this.state.file;
    }

    onChange(result);
  };

  loadSuccessHandler = (info) => {
    this.originalSize = {
      width: info.resource.width,
      height: info.resource.height,
    };

    this.positionChangeHandler();
  };

  getCropper() {
    return (
      <div className="d-inline-block full">
        <Cropper
          ref="cropper"
          src={this.state.photo}
          background={false}
          viewMode={2}
          dragMode="move"
          zoomOnTouch={false}
          zoomOnWheel={false}
          cropBoxResizable={false}
          guides={false}
          crop={this.crop}/>
      </div>
    );
  }

  render() {
    const { input: { value }, } = this.props;
    const { photo } = this.state;

    if (!photo && value) {
      if (value.original_url) {
        setTimeout(() => {
          this.setState({
            photo: value.original_url,
          });
        }, 0);
      } else {
        this.getDataURL(value.file)
          .then((data) => {
            this.setState({
              photo: data,
            });
          });
      }
    }

    return (
      <div>
        <div className="width-card">
          <ProfileCard
            profile={this.props.profile}
            openInNewTab={false}
            coverComponent={
              photo ?
                <div className="info-avatar" ref={ref => {
                  this.editorContainer = ref;
                }}
                >
                  <AvatarEditor
                    ref={ref => {
                      this.editor = ref;
                    }}
                    image={photo}
                    borderRadius={0}
                    width={this.editorContainer ? this.editorContainer.clientWidth : 332}
                    height={this.editorContainer ? this.editorContainer.clientHeight : 455}
                    border={0}
                    color={[255, 255, 255, 0.6]} // RGBA
                    scale={value && value.zoom ? +value.zoom + 1 : 1}
                    rotate={0}
                    onPositionChange={this.positionChangeHandler}
                    position={value && value.x ? { x: +value.x, y: +value.y } : { x: 0.5, y: 0.5 }}
                    onLoadSuccess={this.loadSuccessHandler}
                  />
                </div> : null
            }
          />
        </div>
        <div className="zoom">
          <SliderComponent
            max={2}
            min={1}
            name="scale"
            step={0.01}
            value={value && value.zoom ? +value.zoom + 1 : 1}
            onChange={this.zoomChangeHandler}/>
        </div>
        <div className="upload-btn-wrapper">
          <input id="upload_preview_avatar" type="file" onChange={this.fileChangeHandler}/>
          <label htmlFor="upload_preview_avatar">
            <i className="fa fa-upload" aria-hidden="true"/> Upload Card Photo
          </label>
        </div>
        <div className="upload-hint crop">
          <p>Photos must be in .jpg or .png format. Recommended size is 550x750 pixels and cannot exceed <strong>{ MAX_FILE_SIZE_IN_MB } Mb</strong></p>
        </div>
      </div>
    );
  }
}
