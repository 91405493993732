import React, { Component } from 'react'
import Checkbox from 'material-ui/Checkbox'
import getMuiTheme from 'material-ui/styles/getMuiTheme'
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider'
import SVGInline from 'react-svg-inline'
import { difference, debounce, range, capitalize, intersectionBy, cloneDeep } from 'lodash'
import IconButton from 'material-ui/IconButton'
import Chip from 'material-ui/Chip'
import Slider from 'material-ui/Slider'
import TextField from 'material-ui/TextField'
import { SortableContainer, SortableElement } from 'react-sortable-hoc'
import { registrationConfig } from '../../config'
import {
	registerProfileTabCodesConvert,
	registerMediaTabCodesConvert,
	registerMediaTabCodes,
	registerProfileTabCodes,
	genders,
	fontSizes,
	defaultFontSize,
	//abilityTree
} from '../../utils/constants'
import '../../styles/components/CommonComponent.scss'
const file_upload = require('../../assets/file_upload_white.svg')
const crossSymbol = require('!!svg-inline-loader?classPrefix!../../assets/icons/cross-symbol.svg')
import { connect } from 'react-redux'
import { destroy } from 'redux-form'
import { getCategories, getCountriesList } from '../../redux/modules/common'
import { AlertPopUp } from '../common/AlertPopUp/AlertPopUp'
import { ConfirmPopUp } from '../common/ConfirmPopUp/ConfirmPopUp'
import classnames from 'classnames'
import { Asterisk } from '../common/Asterisk/Asterisk'
import apiClient from '../../helpers/apiClient';
import { translate } from '../../translator'
import { getPlansList, getCountriesTree } from '../../redux/modules/dashboard'
import TreeSelect from 'antd/es/tree-select'
import 'antd/es/tree-select/style/css'
import notification from 'antd/es/notification'
import 'antd/es/notification/style/css'

import '../../styles/components/register/CommonComponent.scss'
import Loader from '../discover/Loader/Loader';
import store from "../../redux/create";

const DISABLED_BTN_COLOR = '#efefef'
const MAX_FILE_SIZE_IN_MB = 10


export const InputStyles = {
	...defaultFontSize,
	fontWeight: '300',
	minHeight: '26px',
	color: '#000',
}

export const PlaceholderStyles = {
	...defaultFontSize,
}

const SortableItem = SortableElement(({ value, onTagDelete, index, chipIndex }) => (
	<div style={{ margin: '4px' }}>
		<Chip
			key={chipIndex}
			onRequestDelete={() => onTagDelete(chipIndex)}
			labelColor="#ffffff"
			backgroundColor="#ffd210"
			deleteIconStyle={{
				opacity: 0,
				position: 'absolute',
				right: 0,
				zIndex: 100,
			}}
		>
			{value.content} <i className="fa fa-times" aria-hidden="true" />
		</Chip>
	</div>
))

export const getEmptyChips = len => {
	if (len) {
		const width = 100 / len - 1

		return new Array(len).fill('').map((val, index) => (
			<Chip
				key={index}
				style={{
					width: `${width}%`,
					height: '1.875rem',
					background: '#fff',
					border: '1px solid #a3a3a3',
				}}
			/>
		))
	}
}

export const SortableList = SortableContainer(({ tags, onTagDelete, empty }) => (
	<div className="talent-tags-controller__preview">
		{tags.length ? (
			tags.map((value, index) => (
				<SortableItem key={`item-${index}`} index={index} chipIndex={index} value={value} onTagDelete={onTagDelete} />
			))
		) : (
				<div className="d-flex" style={{ width: '100%' }}>
					<div className="d-flex justify-content-between align-items-center" style={{ width: '50%' }}>
						{getEmptyChips(5)}
					</div>
				</div>
			)}
	</div>
))

export class ServiceInfoCheckBoxGroup extends Component {
	constructor(props) {
		super(props)

		this.state = {
			showOptions: false,
		}
	}

	onValueChange = newValue => {
		console.log(newValue)
		const {
			input: { value, onChange },
		} = this.props
		if (value.indexOf(newValue) === -1) {
			onChange([...value, newValue])
		} else {
			value.splice(value.indexOf(newValue), 1)
			onChange(value)
			this.forceUpdate()
		}
	}

	toggleVisibility() {
		this.setState({ showOptions: !this.state.showOptions });
	}

	render() {
		const {
			groupName,
			optionNames,
			input: { value },
			required,
			collapsible = true,
		} = this.props
		let options = []
		let displayContent = collapsible ? this.state.showOptions : true;

		console.log(value)

		optionNames.forEach((option, index) => {
			options.push(<ServiceInfoCheckBox optionName={option} onChange={this.onValueChange} value={value} key={index} />)
		})

		return (
			<div className="business-info-form__content">
				<h5 className="business-info-form__checkbox-title" onClick={this.toggleVisibility.bind(this)}>
					{groupName} {required && <Asterisk />} {collapsible && (displayContent ? <i className="fa fa-minus-circle" style={{ color: '#bbb', marginLeft: '0.25rem' }} /> : <i className="fa fa-plus-circle" style={{ color: '#bbb', marginLeft: '0.25rem' }} />)}
				</h5>
				{displayContent && <div className="business-info-form__checkbox-wrapper">{options}</div>}
			</div>
		)
	}
}

export class ServiceInfoCheckBox extends Component {
	render() {
		const { optionName, onChange, value } = this.props

		return (
			<div className="business-info-form__container">
				<Checkbox
					className="business-info-form__container__checkbox"
					label={optionName}
					checked={value.indexOf(optionName) !== -1}
					onCheck={() => onChange(optionName)}
					id={optionName}
					type="checkbox"
					labelStyle={{
						fontWeight: value.includes(optionName) ? '500' : '300',
						color: '#000',
						textAlign: 'left',
					}}
				/>
			</div>
		)
	}
}

@connect(
	({ user, form }) => ({}),
	{
		destroy,
	}
)
export class SkipThisStep extends Component {
	switchForm = () => {
		const { changeForm, saveForms, preventUpdate, currentForm, currentType } = this.props

		if (saveForms) {
			saveForms(preventUpdate)
				.then(() => changeForm(this.getNextForm()))
				.catch(err => { })
		} else {
			if (currentType === 'media') {
				this.props.destroy(registerMediaTabCodesConvert[currentForm])
			} else if (currentType === 'profile') {
				this.props.destroy(registerProfileTabCodesConvert[currentForm])
			}
			changeForm(this.getNextForm())
		}
	}

	getNextForm = () => {
		const { currentForm, currentType, profile_type } = this.props
		let currentFormName = ''

		if (currentType === 'media') {
			currentFormName = registerMediaTabCodesConvert[currentForm]
		} else if (currentType === 'profile') {
			currentFormName = registerProfileTabCodesConvert[currentForm]
		}

		let currentFormIndex = registrationConfig.forms[profile_type][currentType].indexOf(currentFormName)

		let nextFrom = {
			form: 1,
			type: currentType,
		}

		if (typeof currentFormIndex !== 'undefined') {
			if (registrationConfig.forms[profile_type][currentType][currentFormIndex + 1]) {
				if (currentType === 'media') {
					nextFrom.form =
						registerMediaTabCodes[registrationConfig.forms[profile_type][currentType][currentFormIndex + 1]]
				} else if (currentType === 'profile') {
					nextFrom.form =
						registerProfileTabCodes[registrationConfig.forms[profile_type][currentType][currentFormIndex + 1]]
				}
			} else {
				if (currentType === 'profile') {
					nextFrom = {
						form: 1,
						type: 'media',
					}
				} else {
					nextFrom = {
						form: currentForm,
						type: currentType,
					}
				}
			}
		}

		return nextFrom
	}

	render() {
		const { style } = this.props
		const { text, disabled } = this.props

		return (
			<div className="skip-button">
				<button
					onClick={this.switchForm}
					style={
						!disabled
							? style
							: Object.assign({}, style, {
								background: DISABLED_BTN_COLOR,
								cursor: 'not-allowed',
							})
					}
					disabled={disabled}
				>
					{text}
				</button>
			</div>
		)
	}
}

@connect(
	({ common }) => ({
		categoriesMap: common.categoriesMap,
    categories: common.categories,
	}),
	{
		getCategories,
	}
)
export class Interests extends Component {
	constructor(props) {
		super(props)

		this.state = {
			selectedGenCat: [],
			categoriesFields: []
		}
	}

	componentDidMount() {
		this.props.getCategories()
			.then(res => {
				const categories = res.data
				const {
					input: { value },
				} = this.props

				const artistsCategory = categories.all.find(category => category.name === 'Artists');
				const artistCategoryId = artistsCategory ? artistsCategory.id : 1;

				const categoriesFields = categories.all.filter(
					cat => cat.parent_category_id === artistCategoryId && cat.name !== 'Artist Occasions')
					.map(genCat => ({
						...genCat,
						categories: categories.all.filter(cat => cat.parent_category_id === genCat.id),

					}))

				let selectedGenCat = categoriesFields.filter(catF => !!intersectionBy(value, catF.categories, 'name').length)

				this.setState({
					selectedGenCat: cloneDeep(selectedGenCat),
					categoriesFields: cloneDeep(categoriesFields),
				})
			})
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		const {
			input: { value },
		} = nextProps
		const { categories } = nextProps

		if (categories) {
      const artistsCategory = categories.all.find(category => category.name === 'Artists');
      const artistCategoryId = artistsCategory ? artistsCategory.id : 1;

      if (!this.props.categories || (this.props.categories.all.length !== categories.all.length)) {
				const categoriesFields = categories.all.filter(cat => cat.parent_category_id === artistCategoryId).map(genCat => ({
					...genCat,
					categories: this.getChildCategories(genCat.id, categories).filter(cat => !cat.is_parent),
				}))

				let selectedGenCat = categoriesFields.filter(catF => !!intersectionBy(value, catF.categories, 'name').length)

				this.setState({
					selectedGenCat: cloneDeep(selectedGenCat),
					categoriesFields: cloneDeep(categoriesFields),
				})
			}
		}
	}

	//tree structure
	getChildCategories = (id, categories) => {
		const children = categories.all.filter(cat => cat.parent_category_id === id)

		let lowerChildren = []
		children.forEach(cld => {
			lowerChildren = lowerChildren.concat(this.getChildCategories(cld.id, categories))
		})

		return children.concat(lowerChildren)
	}

	unselectChildCategories = id => {
		const {
			input: { value, onChange },
			categories,
		} = this.props
		const valueCopy = cloneDeep(value)
		const children = this.getChildCategories(id, categories)

		const filteredStyles = valueCopy.filter(selCat => !children.find(child => child.id === selCat.id))

		onChange(filteredStyles)
	}

	addCategory = cat => {
		const {
			input: { value, onChange },
		} = this.props
		const catCopy = cloneDeep(cat)
		const copy = cloneDeep(value)

		if (!value.find(vCat => catCopy.id === vCat.id)) {
			onChange([...copy, catCopy])
		} else {
			onChange([...copy.filter(vCat => catCopy.id !== vCat.id)])
		}
	}

	selectGeneralCategory = genCat => {
		let selectedGenCat = cloneDeep([...this.state.selectedGenCat])
		if (selectedGenCat.find(selGenCat => selGenCat.name === genCat.name)) {
			this.unselectChildCategories(genCat.id)
			selectedGenCat = selectedGenCat.filter(selGenCat => selGenCat.name !== genCat.name)
		} else {
			selectedGenCat.push(genCat)
		}

		this.setState({
			selectedGenCat: selectedGenCat,
		})
	}

	getCategories = (generalCategory, index) => {
		const categories = generalCategory.categories

		const style = {
			width: `100%`,
			height: '1.875rem',
			border: '1px solid #eeeeee',
			backgroundColor: '#eeeeee',
		}

		const checkedStyle = {
			width: `100%`,
			height: '1.875rem',
			border: '1px solid rgba(163, 163, 163, 0.87)',
			backgroundColor: 'rgba(163, 163, 163, 0.87)',
		}

		const labelStyle = {
			color: '#fff',
		}

		return (
			<div key={index}>
				<div className="gen-cat-name">{generalCategory.name}</div>
				<div className="categories-wrapper">
					{categories &&
						categories.map((cat, index) => (
							<CategoryInterests
								addCategory={this.addCategory}
								category={cat}
								labelStyle={this.isChecked(cat) ? labelStyle : null}
								key={'cat-int' + index}
								index={index}
								style={this.isChecked(cat) ? checkedStyle : style}
								name={cat.name}
							/>
						))}
				</div>
			</div>
		)
	}

	isChecked = cat => {
		const {
			input: { value },
		} = this.props

		return !!value.find(vCat => vCat.id === cat.id)
	}

	render() {
		const { selectedGenCat, categoriesFields } = this.state

		return (
			<div className={'interests'}>
				<div className="interest-gen-cat">
					{categoriesFields &&
						categoriesFields.map((genCat, index) => (
							<label className="radio-group radio-inline" key={'catFields' + index}>
								<input
									onChange={() => this.selectGeneralCategory(genCat)}
									checked={!!selectedGenCat.find(selGenCat => selGenCat.name === genCat.name)}
									type="checkbox"
									className="d-none"
								/>
								<span>{genCat.name}</span>
							</label>
						))}
				</div>
				<div>{selectedGenCat.map((genCat, index) => this.getCategories(genCat, index))}</div>
			</div>
		)
	}
}

class CategoryInterests extends Component {
	render() {
		const { style, index, name, category, addCategory, labelStyle } = this.props

		return (
			<div className="category">
				<Chip key={index} onClick={() => addCategory(category)} labelStyle={labelStyle} style={style}>
					{name}
				</Chip>
			</div>
		)
	}
}

@connect(
	({ dashboard }) => ({
		plans: dashboard.plans,
		countriesTree: dashboard.countriesTree,
	}),
	{
		getPlansList,
		getCountriesTree,
	}
)
export class AbilityToTravel extends Component {
	constructor(props) {
		super(props);

		this.state = {
			activePlan: this.props.activePlan || null,
			treeRootId: null,
			abilityTree: [],
			isDataLoaded: false,
		}
	}

	componentDidMount() {
		const { getPlansList, getCountriesTree } = this.props

		Promise.all([getPlansList(), getCountriesTree()])
			.then(res => {
				const {
					meta: { price }
				} = this.props.plans;

				const currentPlan = price.find(item => item.current)

				const tree = this.getNestedChildren(this.props.countriesTree, null, null)
				const root = this.props.countriesTree.find(item => item.parent_id === null)
				const rootId = root ? root.id : null

				this.setState({ activePlan: currentPlan, abilityTree: tree, treeRootId: rootId, isDataLoaded: true });
				if(store.getState().user.currentProfile.countries[0].temp != undefined){
          this.props.input.onChange(store.getState().user.currentProfile.countries[0].temp)
        }
			})
	}

	getNestedChildren(arr, parent_id) {
		let out = []
		if (Array.isArray(arr)) {
			arr.forEach(item => {
				if (item.parent_id === parent_id) {
					let treeItem = {
						title: item.name,
						value: item.id,
						key: item.id,
						level: item.values
					}

					let children = this.getNestedChildren(arr, item.id)
					if (children.length) {
						console.log('children', children.order)
						treeItem.children = children.sort(function (a, b) {
							var nameA = a.title.toLowerCase(), nameB = b.title.toLowerCase();
							if (nameA < nameB)
								return -1;
							if (nameA > nameB)
								return 1;
							return 0;
						});
					}
					out.push(treeItem)
				}
			})
		}
		return out
	}

	getSelectAbility() {
		const def = 'one country'
		if (this.state.activePlan) {
			switch (this.state.activePlan.name) {
				case 'Free': return def
				case 'Pro': return 'one continent'
				case 'Premium': return 'whole world'
				default: return def
			}
		}
		return def
	}

	searchTree(element, matchingValue) {
		if (element.value == matchingValue) {
			return element
		}
		else if (element.children != null) {
			let i
			let result = null
			for (i = 0; result == null && i < element.children.length; i++) {
				result = this.searchTree(element.children[i], matchingValue)
			}
			return result
		}
		return null
	}

	onChange = value => {
		const element = this.searchTree(this.state.abilityTree[0], value)

		if (element) {
			const allowChange = this.state.activePlan
				? element.level <= this.state.activePlan.id
				: !element.children

			if (allowChange) {
				this.props.input.onChange(value)
      }
			else {
				notification.info({
					message: 'Attention',
					description: 'To perform this action please upgrade your Plan.'
				})
			}
		}
	}

	render() {
		const {
			input: { value }
		} = this.props;

		const { abilityTree } = this.state

		const planName = 'Gigworks ' + (this.state.activePlan ? this.state.activePlan.name : 'Free');

		return (
			<div>
				<div className="service-detail-form__item-header">
					<span className="fa-stack info-popup-window" data-title={registrationConfig.popupText.abilityToTravel}>
						<i className="fa fa-circle-thin fa-stack-2x" />
						<i className="fa fa-info fa-stack-1x" />
					</span>
					<h4 className="service-detail-form__title">
						Ability to travel {this.props.required && <Asterisk />}
					</h4>
				</div>
				{this.state.isDataLoaded ?
					<TreeSelect className="ability-tree"
						dropdownClassName="ability-tree-dropdown"
						style={{ ...defaultFontSize, width: '100%' }}
						dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
						value={value}
						defaultValue={value}
						treeData={abilityTree}
						treeDefaultExpandedKeys={[this.state.treeRootId]} // only root
						placeholder="Please select"
						treeDefaultExpandAll={false}
						onChange={this.onChange}
					/>
					:
					<div
						className="d-flex justify-content-center">
						<Loader />
					</div>
					// <TreeSelect className="ability-tree"
					// 	dropdownClassName="ability-tree-dropdown"
					// 	style={{ ...defaultFontSize, width: '100%' }}
					// 	dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
					// 	value={null}
					// 	treeData={null}
					// 	placeholder="Please select"
					// />
				}
				<h6 className="service-detail-form__input-subtitle" style={{ display: 'inherit' }}>
					You've selected the&nbsp;<strong>{planName}</strong>&nbsp;Plan&copy;, you may select {this.getSelectAbility()} for your profile.
				</h6>
			</div>
		)
	}
}

export class Gender extends Component {
	render() {
		const {
			input: { value, onChange },
		} = this.props;

		return (
			<div className="personal-info__checkbox-wrapper">
				{genders.map((gender, index) => (
					<Checkbox
						key={index}
						id={gender.value}
						style={{ ...defaultFontSize, width: 'auto', textAlign: 'left' }}
						labelStyle={{ ...defaultFontSize, width: 'auto' }}
						label={gender.label}
						checked={value === gender.value}
						onCheck={() => {
							onChange(gender.value)
						}}
					/>
				))}
			</div>
		)
	}
}

export class RadioComponent extends Component {
	render() {
		const {
			input: { value, onChange },
			required,
			optionNames,
			groupName,
			meta,
		} = this.props

		return (
			<div>
				<h5>
					{groupName} {required && <span style={{ color: 'red' }}>* </span>}
					{meta && meta.error && meta.touched ? (
						<span style={{ color: 'red', fontSize: '12px', lineHeight: '12px' }}>Required</span>
					) : (
							''
						)}
				</h5>
				<div className="d-flex justify-content-between flex-wrap">
					{optionNames.map((item, index) => (
						<Checkbox
							key={index}
							id={item}
							style={{ width: '33%', marginBottom: '0.5rem' }}
							labelStyle={{ width: 'auto', color: '#000', fontWeight: '300' }}
							label={item}
							checked={value === item}
							onCheck={() => {
								onChange(item)
							}}
						/>
					))}
				</div>
			</div>
		)
	}
}

export class FileInput extends React.Component {
	input = null

	onCloseConfirm = () => {
		const {
			input: { onChange },
		} = this.props
		const { canDeleteCV } = this.state
		this.setState({ isOpenConfirmPopUp: false, canDeleteCV: false })
		canDeleteCV && this.setState({ isOpenConfirmPopUp: false }, onChange(null))
	}

	constructor(props) {
		super(props)

		this.state = {
			isOpenPopUp: false,
			isOpenConfirmPopUp: false,
			canDeleteCV: false,
		}
	}

	onChange = e => {
		const {
			input: { onChange },
			showValidationError,
		} = this.props
		const maxFileSize = MAX_FILE_SIZE_IN_MB * 1024 * 1024

		if (e.target.files[0].size < maxFileSize) {
			onChange(e.target.files[0])
		} else {
			showValidationError(`${MAX_FILE_SIZE_IN_MB}mb file size exceeded`)
		}
	}

	render() {
		const {
			input: { value },
			style,
			accept,
			className,
			label = 'Upload file'
		} = this.props
		const { isOpenPopUp, isOpenConfirmPopUp } = this.state

		if (!value) {
			return (
				<div className={classnames(className, 'row d-flex align-items-center')}>
					<div className="col-12">
						<label
							onClick={() => {
								this.setState({ isOpenPopUp: true })
							}}
						>
							<div className="input-file__button">
								<span>
									<img src={file_upload} />
								</span>{' '}
								{label}
							</div>
						</label>
						<input
							type="file"
							ref={r => {
								this.input = r
							}}
							style={style}
							onChange={this.onChange}
							accept={accept}
						/>
						<div className="upload-hint hover-description" dangerouslySetInnerHTML={{ __html: '' }}>

						</div>
						<AlertPopUp
							isOpen={isOpenPopUp}
							title={`ALERT MESSAGE`}
							onClose={() => {
								this.setState({ isOpenPopUp: false })
							}}
							text={
								`Please be sure you place the shape on any contact information shown on your CV (address, email,` +
								` phone number, social media channels, etc).`
							}
							okBtn={{
								label: `Ok`,
								onClick: () => {
									this.setState({ isOpenPopUp: false }, this.input.click())
								},
							}}
						/>
					</div>
				</div>
			)
		} else {
			return (
				<div style={{ fontWeight: 500 }} className="d-flex flex-nowrap">
					{value.name}
					&nbsp;
					<a
						href="javascript://"
						aria-hidden="true"
						onClick={() => {
							this.setState({ isOpenConfirmPopUp: true })
						}}
					>
						<SVGInline svg={crossSymbol} width="12px" fill="#a3a3a3" />
					</a>
					<ConfirmPopUp
						isOpen={isOpenConfirmPopUp}
						title={`CONFIRM MESSAGE`}
						text={`Are u sure you want to delete this file?`}
						onClose={this.onCloseConfirm}
						okBtn={{
							label: `Yes!`,
							onClick: () => {
								this.setState({ isOpenConfirmPopUp: false, canDeleteCV: true })
							},
						}}
						cancelBtn={{
							label: `No, please keep it!`,
							onClick: () => {
								this.setState({ isOpenConfirmPopUp: false, canDeleteCV: false })
							},
						}}
					/>
				</div>
			)
		}
	}
}

export class SliderComponent extends Component {
	constructor(props) {
		super(props)
	}

	render() {
		const { onChange, value, name, max, min, step } = this.props
		const SliderTheme = getMuiTheme({
			palette: {
				primary1Color: '#000',
				accent1Color: '#000',
			},
		})

		return (
			<MuiThemeProvider muiTheme={SliderTheme}>
				<div className="zoom-control d-flex align-items-center">
					<IconButton disableTouchRipple={true}>
						<div>
							<i style={{ fontSize: '1rem' }} className="fa fa-minus" aria-hidden="true" />
						</div>
					</IconButton>
					<Slider
						className="slider-component"
						name={name}
						style={{
							zIndex: 0,
							width: '100%',
							display: 'flex',
							alignItems: 'center',
						}}
						sliderStyle={{
							marginTop: '0',
							marginBottom: '0',
						}}
						value={value}
						onChange={onChange}
						max={max}
						min={min}
						step={step}
					/>
					<IconButton disableTouchRipple={true}>
						<div>
							<i style={{ fontSize: '1rem' }} className="fa fa-plus" aria-hidden="true" />
						</div>
					</IconButton>
				</div>
			</MuiThemeProvider>
		)
	}
}

export class Industries extends Component {
	constructor(props) {
		super(props)

		this.state = {
			limit: 5,
			industries: []
		}
	}

	UNSAFE_componentWillMount() {
		apiClient
			.get('/api/autocomplete/dictionary?name=Industries')
			.then((data) => {
				this.setState({
					industries: data.data
				})
			})
	}

	//_onClick = e => {
	//	const {
	//	 	input: { value, onChange },
	//	} = this.props
	//
	//	if (e.target.checked) {
	//		if (value.length < this.state.limit) {
	//			onChange([...value, e.target.value]);
	//		}
	//		else {
	//			e.target.checked = false
	//		}
	//	}
	//	else {
	//		onChange([...value.filter(ind => e.target.value !== ind)]);
	//	}
	//}

	//_isChecked = val => {
	//	const {
	//		input: { value },
	//	} = this.props
	//
	//	return !!value.find(v => val == v)
	//}

	handleChange = (checkboxValue, id) => {
		const { input: { value, onChange } } = this.props;


		console.log({ checkboxValue, id, })
		const newValue = JSON.parse(JSON.stringify(
			checkboxValue ? [...value, id] : value.filter(x => x !== id)
		))

		onChange(newValue)
	}

	render() {
		const { input: { value } } = this.props;
		const { industries } = this.state;

		if (!industries.length) return <div
			className="mt-4 d-flex justify-content-center">
			<Loader />
		</div>

		return (
			<div className="mt-4 d-flex flex-wrap">
				{
					industries.map(industry => <Checkbox
						key={industry.id}
						id={industry.id}
						style={{ width: '300px' }}
						labelStyle={{ width: 'auto' }}
						label={industry.name}
						checked={!!(value.indexOf(industry.id) > -1)}
						onClick={e => this.handleChange(e.target.checked, industry.id)}
					/>)
				}
			</div>
		)
	}
}
