import './SiteMap.scss';
import React from 'react';
import { block } from 'bem-cn';
import { RaisedButton } from 'material-ui';
import { socialLinks } from '../../../config';
import { Link } from 'react-router-dom';
import { translate } from '../../../translator';

const b = block('SiteMap');

export const SiteMap = (props) => {
  return <div className={b()}>
    <ul className={b('list')}>
      <li className={b('item list-gigworks')}>
        <span className={b('item_title')}>{`Gigworks`}</span>
        <ul className={b('list', { nested: true })}>
          <li className={b('item')}>
            <Link href="#" to='/about' className={b('link')} >{translate('sitemap.aboutUs')}</Link>
          </li>
          <li className={b('item')}>
            <Link href="#" to='/mission' className={b('link')}>{translate('sitemap.missionVision')}</Link>
          </li>
          <li className={b('item')}>
            <Link href="#" to='/code' className={b('link')}>{translate('sitemap.ourGenetic')}</Link>
          </li>
          <li className={b('item')}>
            <Link href="#" to='/memberships' className={b('link')}>{translate('sitemap.membershipsPricing')}</Link>
          </li>
          <li className={b('item')}>
            <Link href="#" to='/VendorCancellationPolicy' className={b('link')}>{translate('sitemap.vendorCancellationPolicy')}</Link>
          </li>
        </ul>
      </li>
      <li className={b('item list-company')}>
        <span className={b('item_title')}>{translate('sitemap.company')}</span>
        <ul className={b('list', { nested: true })}>
          <li className={b('item')}>
            <Link to='/contact' className={b('link')} href='#'>{translate('sitemap.contactUs')}</Link>
          </li>
          <li className={b('item')}>
            <Link to='/team' className={b('link')} href='#'>{translate('sitemap.theTeam')}</Link>
          </li>
        </ul>
      </li>
      <li className={b('item list-learn-more')}>
        <span className={b('item_title')}>{translate('sitemap.learnMore')}</span>
        <ul className={b('list', { nested: true })}>
          <li className={b('item')}>
            <Link href="#" to='/investors' className={b('link')}>{translate('sitemap.inventorsPartners')}</Link>
          </li>
          <li className={b('item')}>
            <Link href="#" to='/press' className={b('link')} href='#'>{translate('sitemap.press')}</Link>
          </li>
          <li className={b('item')}>
            <Link href="#" to='/questions' className={b('link')} href='#'>{translate('sitemap.faq')}</Link>
          </li>
        </ul>
      </li>
      <li className={b('item mobile-subscribe')}>
        <RaisedButton
          label="Join our Newsletter"
          onClick={props.switchSubscription}
          labelStyle={{
            textTransform: 'normal',
            fontSize: '14px',
            color: '#000',
            overflow: 'hidden',
          }}
          buttonStyle={{
            background: 'transparent',
            zIndex: 'unset'
          }}
          style={{
            borderRadius: '50px',
            backgroundColor: '#fff',
            color: '#000',
          }}
          className='subscription'
          overlayStyle={{ borderRadius: '1.5rem' }}/>
        <div className={b('options')}>
          <Link target="_blank" to={socialLinks.facebook}><i className="fa fa-facebook" aria-hidden="true"/></Link>
          <Link target="_blank" to={socialLinks.instagram}><i className="fa fa-instagram" aria-hidden="true"/></Link>
          <Link target="_blank" to={socialLinks.linkedin}><i className="fa fa-linkedin" aria-hidden="true"/></Link>
          <Link target="_blank" to={socialLinks.youtube}><i className="fa fa-youtube-play" aria-hidden="true"/></Link>
        </div>
      </li>
    </ul>
  </div>;
};
