import './HomeArticle.scss';
import React from 'react';
import { LandingButton } from '../LandingButton/LandingButton';
import { block } from 'bem-cn';
import classNames from 'classnames';

const b = block('HomeArticle');

export const HomeArticle = (props) => {
  const { image, title, subtitle, description, linkTitle, linkPath, className } = props;

  return (
    <article
      className={classNames(className, b(), 'all-item', 'all-item--artists', 'text-center', 'lozad' )}
      data-background-image={image}>
      <div className="all-item__content text-left">
        <header className="all-item__header">
          <img className="all-item__logo" src={require('../../../assets/home-white-logo.svg')} alt="Gigworks"/>
          <h1 className="all-item__title">{title}</h1>
        </header>
        <h2 className="all-item__subtitle">{subtitle}</h2>
        <p className="all-item__copy">{description}</p>
        <div className="text-center text-md-left">
          <LandingButton className={b('button')} transparent to={linkPath}>{linkTitle}</LandingButton>
        </div>
      </div>
    </article>
  );
};
