import './TimeFilter.scss';
import React, { Component } from 'react';
import { DateRangePicker } from '../../../common/DateRangePicker/DateRangePicker';
import moment from 'moment';

export class TimeFilter extends Component {
  constructor(props) {
    super(props);

    this.state = {
      startDay: null,
      endDay: null,
      dateIsRange: false,
      year: null,
      startMonth: null,
      endMonth: null,
    };
  }

  handleSelect = date => {
    this.setState(() => ({
      startDay: date.startDate._d.getDate(),
      endDay: date.endDate._d.getDate(),
      dateIsRange: date.startDate._d.getDate() !== date.endDate._d.getDate(),
      year: moment(date.startDate._d).get('year'),
      startMonth: date.startDate.month(),
      endMonth: date.endDate.month(),
    }));
  };

  render() {
    const { startDay, endDay, dateIsRange, year, startMonth, endMonth } = this.state;

    return (
      <DateRangePicker
        selectDate={this.handleSelect}
        startDay={startDay}
        endDay={endDay}
        dateIsRange={dateIsRange}
        year={year}
        startMonth={startMonth}
        endMonth={endMonth} />
    );
  }
}
