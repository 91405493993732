import React from 'react'
import PropTypes from 'prop-types';

/**
 * 
 */
class SelectFieldItem extends React.Component {
    static propTypes = {
        label: PropTypes.any,
        value: PropTypes.any,
        /** need for objects etc */
        selected: PropTypes.bool
      }
    constructor(props) {
        super(props);

        this.state = {

        }
    }

    render() {
        const { label, value, onChange, selected } = this.props;

        return <div
            className={`SelectFieldItem ${selected && 'selected'}`}
            onClick={() => onChange(value)}>
            {label}
        </div>
    }
}

export default SelectFieldItem;