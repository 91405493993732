import React from 'react'
import { connect } from 'react-redux'
import { setPopupVisible } from '../../redux/modules/common'
import { AlertPopUp } from '../common/AlertPopUp/AlertPopUp'
import { Offline, Online } from 'react-detect-offline'
import config from '../../config'

const baseURL = `${config.protocol}://${config.host}:${config.port}`;

@connect(({ common }) => ({
  popupVisible: common.popupVisible,
  backendError: common.backendError,
}), {
  setPopupVisible,
})
class MiddlewareErrorHandler extends React.PureComponent {
  constructor (props) {
    super(props)
  }

  closePopup = () => {
    const { setPopupVisible } = this.props

    setPopupVisible(false, null)
  };

  render () {
    const { popupVisible, backendError } = this.props

    const polling = {
      enabled:  true,
      interval: 10000,
      url:      `${baseURL}/api/ping`,
    };

    return (
      <div>
        <Online polling={polling}>
          <AlertPopUp
            isOpen={popupVisible}
            title={'Ooops!'}
            text={backendError ?? 'It seem that we had an error. Our team of highly trained monkeys are working on it. 🙈'}
            okBtn={{
              label:   `Ok, I understand.`,
              onClick: this.closePopup,
            }}
          />
        </Online>
      </div>
    )
  }
}

export default MiddlewareErrorHandler
