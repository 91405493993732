import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { registrationConfig } from '../../config';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { getRealTypeOfUser } from '../../helpers/methods';
import {
	ArtistPersonalInfo,
	AudioUploadWrapper,
	ClientInfo,
	ClientPersonalInfo,
	CompanyInfo,
	FanInfo,
	FanPersonalInfo,
	CompanyContactInfo,
	GroupContactInfo,
	GroupDetails,
	GroupInfo,
	PicturesUploadWrapper,
	PlansAndGroupTalents,
	SkipThisStep,
	VideoUploadWrapper,
	WizardAccountInfo,
	WizardBandContactInfo,
	WizardBandDetails,
	WizardBandInfoDetails,
	WizardBookingInfo,
	WizardBusinessInfo,
	WizardOverviewPhotosEditStep,
	WizardOverviewTalentDetailsForm,
  WizardOverviewPlansAndPayments,
	WizardOverviewTalentInfoForm,
	WizardPhotoHistory,
	WizardPlansAndBandGenre,
	WizardPlansAndTalentsForm,
	WizardPlansAndVenueProfile,
	WizardVenueInfo,
	CancellationPolicy
} from './index';
import { accountType, registerCall, requestVerifyEmailChange, updatePassword } from '../../redux/modules/register';
import { getCurrentUser } from '../../redux/modules/user';
import { RegisterForm } from '../../components';
import {
	registerMediaTabCodes,
    registerTabs,
	translateMediaForms,
    translateProfileForms,
    registerProfileTabCodesConvert,
	fontSizes,
	DANGER_COLOR,
} from '../../utils/constants';
import * as _ from 'lodash';
import moment from 'moment';
import { parse } from 'qs';

import Plans from '../../components/DashBoard/Plans/Plans';
import MissingFields from './MissingFields';

import '../../styles/components/WizardOverviewStep.scss';
import classNames from 'classnames/bind';
import { AlertPopUp } from '../common/AlertPopUp/AlertPopUp';
import { ModalPreloader } from '../common/ModalPreLoader/ModalPreLoader';
import { AutoAffix } from 'react-overlays';
import { translate } from '../../translator';
import Payments from './Payments/Payments';
import Coupons from './Coupons';
import KYC from './KYC/KYC';
import missingFields from '../../helpers/missingFields';
import SVGInline from 'react-svg-inline';
import {KycStatusType} from "./KYC/KycConstants";
import GWNavDropDown from "../common/GWNavDropdown/GWNavDropdown"
import reduce from 'lodash/reduce'

const white_shield = require('!!svg-inline-loader?classPrefix!../../assets/white_shield.svg');
const circle_shield = require('!!svg-inline-loader?classPrefix!../../assets/circle_shield.svg');

export const sharePopUpDialog = {
	backgroundColor: '#ffffff',
	color: '#ffffff',
	width: '40%',
	height: 'auto',
	padding: '0',
	left: '55%',
};

@connect(
	({ form, user, globalConfig }) => ({
		forms: form,
		currentUser: user.currentUser,
		currentKYC: _.get(user, 'currentKYC', false),
		profile: user.currentProfile,
		isMobile: globalConfig.isMobile,
		currentProfile: user.currentProfile,
	}),
	{
		registerCall,
		accountType,
		requestVerifyEmailChange,
		getCurrentUser,
		updatePassword,
	}
)
class WizardOverviewStep extends Component {
	static propTypes = {
		onSubmit: PropTypes.func.isRequired,
		registerType: PropTypes.string.isRequired,
		loading: PropTypes.bool.isRequired,
		initialValuesRegister: PropTypes.object.isRequired,
		initialPage: PropTypes.object.isRequired,
		setCurrentForm: PropTypes.func.isRequired,
		showValidationError: PropTypes.func.isRequired,
	};

	rightSide = null; // '100vh';
  registerProfileTabCodes = reduce(
    registerTabs,
    (res, item, index) => {
      res[item.name] = index + 1

      return res
    },
    {}
  )
	constructor(props) {
	  super(props);

		this.state = {
			media: {
				currentForm: props.initialPage.form || registerMediaTabCodes.EditProfilePhotos,
			},
			profile: {
				currentForm: this.registerProfileTabCodes.AccountInfo,
			},
			isMenuVisible: true,
			showMedia: true,
			currentType: props.initialPage.type || 'profile',
			isScrollMainContent: false,
			widthScroll: 0,
			isOpenErrorPopUp: false,
      renderPendingAccountApprovalNotice: false
		};

		this.props.setCurrentForm({
			form: this.state[this.state.currentType].currentForm,
			type: this.state.currentType,
		})
	}

	UNSAFE_componentWillMount() {
		const { getCurrentUser, match, registerType } = this.props;


		const redirectToTargetForm = () => {
			const { location } = this.props;
			const currentPath = _.get(location, 'pathname');
			const { form } = parse(this.props.location.search.substr(1));

			if (currentPath === '/profile/edit/plans') {
				const flatProfileType = _.get(this.props, 'currentUser.flat_profile_type')

				if (flatProfileType === 'artist.basic')
					this.changeForm(3)
				else if (flatProfileType === 'group.band')
					this.changeForm(4)
				else if (flatProfileType === 'group.basic')
					this.changeForm(5)
				else if (flatProfileType === 'venue.basic')
					this.changeForm(6)
			} else if (currentPath === '/profile/edit/payments' || currentPath === '/profile/edit/payments/') {
                this.changeForm(this.registerProfileTabCodes.Payments)
			} else if (currentPath === '/profile/edit/kyc' || currentPath === '/profile/edit/kyc/') {
                this.changeForm(this.registerProfileTabCodes.KYC)
			} if (form) {
				this.changeForm(+form);
			}
			//else if (currentPath === '/profile/edit/payments') {
			//	this.changeForm(this.registerProfileTabCodes.Payments)

		}

		if (registerType === 'edit-profile'){
			if (this.props.currentUser) {
				redirectToTargetForm()
			} else {
				getCurrentUser()
					.then(res => redirectToTargetForm())
					.catch(err => console.log('Please try again...', err))
			}
		}
	}

	changeForm = (formNumber, type = null) => {
	  const { setCurrentForm, isMobile } = this.props;
	  const currentType = (type === 'profile' || type === 'media') ? type : this.state.currentType;

    this.props.history.replace({
      pathname: this.props.location.pathname,
      search: `?form=${formNumber}`
    });

    this.setState({
      [currentType]: {
        currentForm: formNumber,
        ...(isMobile && {isMenuVisible: false})
      },
      currentType: currentType
	  });

		setCurrentForm({
			form: formNumber,
			type: currentType,
		})
	};

	toggleMenuState = isMenuVisible => {
		this.setState({ isMenuVisible })
	};

	saveForms = preventUpdate => {
		const { onSubmit } = this.props

		return onSubmit(preventUpdate)
	};

	isPage = (current, number) => {
		if (current === number) {
			return 'active'
		}
		return ''
	};

	changeType = type => {
		const { setCurrentForm } = this.props
		this.setState({
			currentType: type,
			isMenuVisible: true,
		});
		setCurrentForm({
			form: this.state[type].currentForm,
			type,
		})
	};

	registerHandler = data => {
		const { currentUser, registerType } = this.props

		if (!data.profile_type) {
			this.setState({
				...this.state,
				errorsModalContent: (
					<ul>
						<li>Select your account type please.</li>
					</ul>
				),
				isOpenErrorPopUp: true,
			})
		}
		const profileType = data.profile_subtype ? data.profile_type + '.' + data.profile_subtype : data.profile_type
		if (registerType === 'oauth') {
			let promises = []
			if (!currentUser.profile_type) {
				promises.push(this.props.accountType(profileType))
			}

			// promises.push(this.props.requestVerifyEmailChange(data.email))
			promises.push(this.props.updatePassword(data.password))

			Promise.all(promises)
				.then(() => this.props.getCurrentUser())
				// .then(() => this.props.history.push('/profile/edit'))
				.then(() => this.props.history.push('/verify-email'))
				.catch(err => {
					this.setState({
						...this.state,
						errorsModalContent: (
							<ul>
								{!_.isEmpty(err.response.data.errors) && Object.values(err.response.data.errors).map((val, index) => (
									<li key={index}>{val}</li>
								))}
							</ul>
						),
						isOpenErrorPopUp: true,
					})
				})
		} else if (registerType === 'email-password') {
			this.props
				.registerCall({
					...data,
					profile_type: profileType,
					profile_subtype: undefined,
				})
				.then(res => {
					if (res.status === 200) {
						return this.props.getCurrentUser()
					}
				})
				.then(() => this.props.history.push('/verify-email'))
				.catch(err => {
					this.setState({
						...this.state,
						errorsModalContent: (
							<ul>
								{!_.isEmpty(err.response.data.errors) && Object.values(err.response.data.errors).map((val, index) => (
									<li key={index}>{val}</li>
								))}
							</ul>
						),
						isOpenErrorPopUp: true,
					})
				})
		} else if (registerType === 'edit-profile') {
			//  ???

			// console.log('edit-profile')
		} else {
			// console.log('I have no idea about registerType')
		}
	};

	getContent = (currentForm, currentType) => {
		const { initialValuesRegister, registerType, currentUser } = this.props
		const isSuccessfullyRegistered = !!currentUser && !!currentUser.profile_type
		const profile_type = getRealTypeOfUser(currentUser)

		if (currentType === 'profile') {
			if (initialValuesRegister.redirectFormIndex && initialValuesRegister.redirectFormIndex !== -1) {
				this.changeForm(initialValuesRegister.redirectFormIndex);
				initialValuesRegister.redirectFormIndex = -1;
			}
			return (
				<div className="profile-edit__main">
					{/*COMMON*/}
					{currentForm === this.registerProfileTabCodes.AccountInfo &&
						registerType !== 'edit-profile' &&
								!isSuccessfullyRegistered && (
									<RegisterForm initialValues={initialValuesRegister} onSubmit={this.registerHandler} />
								)
							}
							{currentForm === this.registerProfileTabCodes.AccountInfo &&
								(registerType === 'edit-profile' || isSuccessfullyRegistered) && (
									<WizardAccountInfo initialValues={initialValuesRegister} onSubmit={this.registerHandler} />
								)}
							{/*ARTIST*/}
							{currentForm === this.registerProfileTabCodes.ArtistPersonalInfo && (
								<ArtistPersonalInfo showValidationError={this.props.showValidationError} />
							)}
							{currentForm === this.registerProfileTabCodes.PlansAndTalents && <WizardPlansAndTalentsForm />}
							{currentForm === this.registerProfileTabCodes.PlansArtist && <WizardOverviewPlansAndPayments
								goToPayments={() => this.changeForm(this.registerProfileTabCodes.Payments)}
							/>}
							{/*{currentForm === this.registerProfileTabCodes.TalentInfo && <WizardOverviewTalentInfoForm />}*/}
							{currentForm === this.registerProfileTabCodes.TalentDetails && <WizardOverviewTalentDetailsForm />}
							{/*VENUE*/}
							{currentForm === this.registerProfileTabCodes.VenueInfo && <WizardVenueInfo />}
							{currentForm === this.registerProfileTabCodes.PlansVenue && <WizardOverviewPlansAndPayments
								goToPayments={() => this.changeForm(this.registerProfileTabCodes.Payments)}
							/>}
							{currentForm === this.registerProfileTabCodes.PlansAndVenueProfile && <WizardPlansAndVenueProfile />}
							{/*{currentForm === this.registerProfileTabCodes.BusinessInfo && <WizardBusinessInfo />}*/}
							{currentForm === this.registerProfileTabCodes.BookingInfo && (
								<WizardBookingInfo showValidationError={this.props.showValidationError} />
							)}
							{/*BAND*/}
							{currentForm === this.registerProfileTabCodes.BandContactInfo && <WizardBandContactInfo />}
							{currentForm === this.registerProfileTabCodes.PlansBand && <WizardOverviewPlansAndPayments
								goToPayments={() => this.changeForm(this.registerProfileTabCodes.Payments)}
							/>}
							{currentForm === this.registerProfileTabCodes.PlansAndBandGenre && <WizardPlansAndBandGenre />}
							{currentForm === this.registerProfileTabCodes.BandInfo && <WizardBandInfoDetails />}
							{currentForm === this.registerProfileTabCodes.BandDetails && <WizardBandDetails />}
							{/*Group*/}
							{currentForm === this.registerProfileTabCodes.GroupContactInfo && <GroupContactInfo />}
							{currentForm === this.registerProfileTabCodes.PlansGroup && <WizardOverviewPlansAndPayments
								goToPayments={() => this.changeForm(this.registerProfileTabCodes.Payments)}
							/>}
							{currentForm === this.registerProfileTabCodes.PlansAndGroupTalents && <PlansAndGroupTalents />}
							{currentForm === this.registerProfileTabCodes.GroupInfo && <GroupInfo />}
							{currentForm === this.registerProfileTabCodes.GroupDetails && <GroupDetails />}
							{/*Client*/}
							{currentForm === this.registerProfileTabCodes.ClientInfo && <ClientInfo />}
							{currentForm === this.registerProfileTabCodes.ClientPersonalInfo && (
								<ClientPersonalInfo showValidationError={this.props.showValidationError} />
							)}
							{/*Company*/}
							{currentForm === this.registerProfileTabCodes.CompanyInfo && <CompanyInfo />}
							{currentForm === this.registerProfileTabCodes.CompanyContactInfo && (
								<CompanyContactInfo showValidationError={this.props.showValidationError} />
							)}
							{/*Fan*/}
							{currentForm === this.registerProfileTabCodes.FanInfo && <FanInfo />}
							{currentForm === this.registerProfileTabCodes.FanPersonalInfo && (
								<FanPersonalInfo showValidationError={this.props.showValidationError} />
							)}
							{currentForm === this.registerProfileTabCodes.CancellationPolicy && (
								<CancellationPolicy selectedPolicy={currentUser.profile.cancellation_policy} />
							)}
							{currentForm === this.registerProfileTabCodes.Payments && <Payments />}
							{
							  currentForm === this.registerProfileTabCodes.KYC
                && <KYC showValidationError={this.props.showValidationError}/>
							}
							{
							  currentForm === this.registerProfileTabCodes.Coupons
                && <Coupons />
							}
              {currentForm === registerMediaTabCodes.EditProfilePhotos && (
                <WizardOverviewPhotosEditStep showValidationError={this.props.showValidationError} />
              )}
              {currentForm === registerMediaTabCodes.EditVideos && <VideoUploadWrapper />}
              {currentForm === registerMediaTabCodes.EditAudio && <AudioUploadWrapper />}
              {currentForm === registerMediaTabCodes.EditPictures && (
                <PicturesUploadWrapper showValidationError={this.props.showValidationError} />
              )}

							{registerType !== 'edit-profile' &&
								currentForm !== this.registerProfileTabCodes.AccountInfo &&
								profile_type && (
									<SkipThisStep
										currentForm={currentForm}
										currentType={currentType}
										changeForm={this.setForm}
										text={'Skip this step'}
										disabled={false}
										style={{
											border: '1px solid #d3d3d3',
											background: 'none',
											borderRadius: '1.5rem',
											color: '#000',
											fontWeight: '300',
											padding: '0.3rem 1.5rem',
											margin: '1rem',
										}}
										profile_type={profile_type}
									/>
								)}
						</div>

			)
		} /*else if (currentType === 'media') {
			return (
				<div className="profile-edit__main">
					{currentForm === registerMediaTabCodes.EditProfilePhotos && (
						<WizardOverviewPhotosEditStep showValidationError={this.props.showValidationError} />
					)}
					{currentForm === registerMediaTabCodes.EditVideos && <VideoUploadWrapper />}
					{currentForm === registerMediaTabCodes.EditAudio && <AudioUploadWrapper />}
					{currentForm === registerMediaTabCodes.EditPictures && (
						<PicturesUploadWrapper showValidationError={this.props.showValidationError} />
					)}
					{registerType !== 'edit-profile' &&
						profile_type && (
							<SkipThisStep
								currentForm={currentForm}
								currentType={currentType}
								changeForm={this.setForm}
								text={'Skip this step'}
								style={{
									border: '1px solid #d3d3d3',
									background: 'none',
									borderRadius: '1.5rem',
									color: '#000',
									fontWeight: '300',
									padding: '0.3rem 1.5rem',
									margin: '1rem',
								}}
								disabled={false}
								profile_type={profile_type}
							/>
						)}
				</div>
			)
		}*/
	}

	setForm = nextForm => {
		const { setCurrentForm } = this.props
		this.setState({
			[nextForm.type]: {
				currentForm: nextForm.form,
			},
			currentType: nextForm.type,
		})
		setCurrentForm(nextForm)
	}

	getListWithDisableLeftMenuFields = (profile, missingFieldsIdList) => {
	  return profile.map((form, index, profileArr) => {
	    if (index > 0) {
	      return missingFieldsIdList.indexOf(this.registerProfileTabCodes[profileArr[index - 1]]) > -1;
      } else {
	      return false;
      }
    })
  };

	getLeftNavMenu = (currentForm, currentType) => {
		const { currentUser, container, isMobile, currentKYC, currentProfile } = this.props;
		let media;
		let profile;
		if (currentUser && currentUser.profile_type) {
			const profile_type = getRealTypeOfUser(currentUser);
			media = registrationConfig.forms[profile_type].media;
			profile = registrationConfig.forms[profile_type].profile;
		} else {
			media = registrationConfig.forms.default.media;
			profile = registrationConfig.forms.default.profile;
		}

		if (currentType === 'profile') {
			const missingFieldsIdList = missingFields({currentUser, currentKYC}, currentType).map(x => x.form);
			const isNotClientProfile = currentUser && currentUser.profile_type !== 'client'
      const hideLeftSideMenu = currentForm == this.registerProfileTabCodes.KYC && isNotClientProfile

			let isSkipKYC = currentProfile ? (currentProfile.profile_detail ? currentProfile.profile_detail.skip_kyc : false) : false;

			const menuComponent = isMobile ? <div /> : <AutoAffix
				viewportOffsetTop={document.getElementById('NavBar') ?
					document.getElementById('NavBar').offsetHeight : 75}
				/>;

      const kyc_shield = currentForm === this.registerProfileTabCodes.KYC ? white_shield : circle_shield;

      const listWithDisableLeftMenuFields = this.getListWithDisableLeftMenuFields(profile, missingFieldsIdList, this.registerProfileTabCodes);

      const accountIsBookable = currentUser && ['artist', 'group', 'venue'].includes(currentUser.profile_type)

      // Да ви еба майката полска!
      // No other way to detect what user data has been updated.
      const profileMissingContactInformation = currentProfile && !(currentProfile.name && currentProfile.name.trim().length > 0)

      this.state.renderPendingAccountApprovalNotice = (currentUser && !currentUser.approved &&
                                                      !profileMissingContactInformation && accountIsBookable)

			return ( ! hideLeftSideMenu && (
				<div>
					<div className={'clearfix hide-sm'}>
						<button
							style={{ width: 'auto', margin: 0 }}
							className={'pull-right btn btn-menu d-flex align-items-center'}
							onClick={() => this.toggleMenuState(!this.state.isMenuVisible)}
						>
							Menu
							<i className="fa fa-bars" aria-hidden="true" style={{ fontSize: '1.5rem', marginLeft: 8 }}/>
						</button>
					</div>
					<div className={classNames('profile-edit__info', { 'show-sm': this.state.isMenuVisible })}>
						<menuComponent.type
							{...menuComponent.props}
							children={<div>
								{profile.map((form, index) => {
								  let checkKycForTabCodexPlan = (
									(currentUser && currentUser.profile_type && currentUser.profile_type !== 'client') &&
									!currentKYC && !isSkipKYC && (
									(this.registerProfileTabCodes[form] == this.registerProfileTabCodes.PlansArtist) ||
									(this.registerProfileTabCodes[form] == this.registerProfileTabCodes.PlansBand) ||
									(this.registerProfileTabCodes[form] == this.registerProfileTabCodes.PlansGroup) ||
									(this.registerProfileTabCodes[form] == this.registerProfileTabCodes.PlansVenue)
								  )) ? true : false;

								  const isDropDownMenu = Array.isArray(form)
                  if(isDropDownMenu) {
                    let ddlActionsMap = []
                    let missingFieldsCount = 0

                    for(let index = 1; index < form.length; index++) {
                      const entry = form[index]
                      const missingField = missingFieldsIdList.indexOf(this.registerProfileTabCodes[entry]) > -1
                      const action = {
                        'id': this.registerProfileTabCodes[entry],
                        'text': translateProfileForms[this.registerProfileTabCodes[entry]],
                        'missing' : missingField
                      }

                      if(missingField)
                        missingFieldsCount++

                      ddlActionsMap.push(action)
                    }

                    return <GWNavDropDown
                      ddlTitle={form[0]}
                      ddlActions={ddlActionsMap}
                      hasMissingFields={missingFieldsCount > 0}
                      itemClickCallBack={this.changeForm} />
                  }

								  const hasErrors = missingFieldsIdList.indexOf(this.registerProfileTabCodes[form]) > -1;
									return <button
										key={index}
										className={[this.isPage(currentForm, this.registerProfileTabCodes[form])].join(' ')}
										style={ hasErrors && isMobile ? { color: DANGER_COLOR } :  null}
										onClick={() => {
											if (container === 'profile') {
												this.toggleMenuState(true);
												this.changeForm(this.registerProfileTabCodes[form]);
											}
										}}
									disabled={(checkKycForTabCodexPlan) || ((currentUser && currentUser.profile_type && currentUser.profile_type !== 'client') ? (listWithDisableLeftMenuFields[index] && !isSkipKYC) : listWithDisableLeftMenuFields[index])}
									>
										{translateProfileForms[this.registerProfileTabCodes[form]]}

										{ hasErrors &&
                      <i className="fa fa-exclamation-circle"
												aria-hidden="true"
												style={{
													marginLeft: '16px',
													fontSize: fontSizes.medium,
													verticalAlign: 'middle',
													color: DANGER_COLOR,
												}}
                      />
										}
                    { !isMobile && form === 'KYC' && <SVGInline svg={kyc_shield} width={'2rem'} height={'2rem'} style={{ position: 'absolute', top: '9px', right: '9px' }} /> }
									</button>
								})}
							</div>}
						/>
					</div>
				</div>
			))
		} else if (currentType === 'media') {
			const missingFieldsIdList = missingFields({currentUser, currentKYC}, currentType).map(x => x.form)

			return (
				<div>
					<div className={'clearfix hide-sm'}>
						<button
							style={{ width: 'auto', margin: 0 }}
							className={'pull-right btn btn-menu d-flex align-items-center'}
							onClick={() => this.toggleMenuState(!this.state.isMenuVisible)}
						>
							Menu
							<i className="fa fa-bars"
								aria-hidden="true"
                            	style={{ fontSize: '1.5rem', marginLeft: 8 }}/>

						</button>
					</div>
					<div className={classNames('profile-edit__info', { 'show-sm': this.state.isMenuVisible })}>
						{media.map((form, index) => {
							const hasErrors = missingFieldsIdList.indexOf(this.registerProfileTabCodes[form]) > -1;

							return <button
								key={index}
								className={this.isPage(currentForm, registerMediaTabCodes[form])}
								onClick={() => {
									if (container === 'profile') {
										this.toggleMenuState(true)
										this.changeForm(registerMediaTabCodes[form]);
									}
								}}
							>
								{translateMediaForms[registerMediaTabCodes[form]]}

								{ isMobile && hasErrors && <i className="fa fa-exclamation"
												aria-hidden="true"
												style={{
													marginLeft: '16px',
													fontSize: fontSizes.xx_large,
													verticalAlign: 'middle',
													color: DANGER_COLOR,
												}}
										/>}
							</button>
						})}
					</div>
				</div>
			)
		}
	}

	getGroupedPhotoHistory(list, type = 'year') {
		const items = _.groupBy(list, item => moment(item.created_at)[type]())

		return _.chain(items)
			.keys()
			.thru(res => res.sort((a, b) => b - a))
			.map(key => {
				if (moment().year() === +key) {
					return this.getGroupedPhotoHistory(items[key], 'month')
				} else {
					return {
						title: type === 'month' ? moment(items[key][0].created_at).format('MMMM') : key,
						items: items[key],
					}
				}
			})
			.flatten()
			.value()
	}

	getTypeSwitcher = () => {
		const { currentUser, container } = this.props
		const { currentType, showMedia } = this.state

		if (!currentUser || !currentUser.profile_type) {
			return (
				<div className="type-switcher">
					<button
						className={`btn ${currentType === 'profile' ? 'selected' : ''}`}
						onClick={container === 'profile' ? () => this.changeType('profile') : null}
					>
						{translate('wizardoverviewstep.button1')}
					</button>
					{showMedia && (
						<button
							className={`btn ${currentType === 'media' ? 'selected' : ''}`}
							onClick={container === 'profile' ? () => this.changeType('media') : null}
						>
							{translate('wizardoverviewstep.button2')}
						</button>
					)}
				</div>
			)
		} else {
			const profile_type = getRealTypeOfUser(currentUser)
			const { media, profile } = registrationConfig.forms[profile_type]

      return (<div className="type-switcher"></div>)
			return (
				<div className="type-switcher">
					{profile.length > 0 && (
						<button
							className={`btn ${currentType === 'profile' ? 'selected' : ''}`}
							onClick={container === 'profile' ? () => this.changeType('profile') : null}
						>
							{translate('wizardoverviewstep.button1')}
						</button>
					)}
					{media.length > 0 && (
						<button
							className={`btn ${currentType === 'media' ? 'selected' : ''}`}
							onClick={container === 'profile' ? () => this.changeType('media') : null}
						>
							{translate('wizardoverviewstep.button2')}
						</button>
					)}
				</div>
			)
		}
	}

	//TODO refactoring
	getRightSide(type) {
		const { loading, registerType, currentUser, isMobile } = this.props;

		const profile_type = getRealTypeOfUser(currentUser);
		const { currentType } = this.state;
		const currentForm = this.state[currentType].currentForm;

		if (type === 'profile') {
			return (
				<div className="profile-edit__missing -if-type-profile">
					{registerType !== 'edit-profile' &&
						profile_type && (
							<SkipThisStep
								currentForm={currentForm}
								currentType={currentType}
								changeForm={this.setForm}
								text={'Save Profile Info'}
								saveForms={this.saveForms}
								disabled={
									!_.get(this.props, 'forms.ArtistPersonalInfo.anyTouched', true) ||
									!!Object.keys(_.get(this.props, 'forms.ArtistPersonalInfo.syncErrors', {})).length
								}
								preventUpdate={true}
								style={{
									width: '100%',
									background: '#ffd210',
									border: 'none',
									color: '#fff',
									fontWeight: '600',
									height: '3.28125rem',
									fontSize: '1.125rem',
									borderBottom: '1px solid #fff',
								}}
								profile_type={profile_type}
							/>
						)}
					{/*TODO refactoring*/}
					{!loading &&
						this.state[type].currentForm !== this.registerProfileTabCodes.AccountInfo &&
						registerType === 'edit-profile' &&
						profile_type && (
							<button className="missing__save" disabled={!this.isValidForm(registerProfileTabCodesConvert[this.state[type].currentForm])} onClick={this.saveForms}>
								Save
							</button>
						)
					}
          {loading && <ModalPreloader />}
					<MissingFields
						currentKYC={this.props.currentKYC}
						currentUser={this.props.currentUser}
						currentType={type}
            currentForm={currentForm}
						onClickFn={this.changeForm}

					/>
				</div>
			)
		}
		const photoHistoryCollection = this.getGroupedPhotoHistory(currentUser ? currentUser.uploaded_pictures : []);

		return (
			<div className="profile-edit__missing -else" id="i-rightSide">
				{/*TODO refactoring*/}
				{!loading &&
					((this.state[type].currentForm === registerMediaTabCodes.EditPictures && registerType !== 'edit-profile') ||
						registerType === 'edit-profile') &&
					profile_type && (
						<button className="missing__save" disabled={false} onClick={() => this.saveForms()}>
							Save
						</button>
					)}
				{loading && <ModalPreloader />}

				<MissingFields currentUser={this.props.currentUser} currentType={type} currentKYC={this.props.currentKYC} onClickFn={this.changeForm} />

				<h2
					className="missing__title missing__title--photo-history"
					style={{ display: photoHistoryCollection.length ? 'inline-block' : 'none' }}
				>
					Your Photos History
				</h2>
				<div
					style={{
						maxHeight: $(window).height() - $('.NavBar').height() * 2,
						overflowY: 'auto',
					}}
				>
					{photoHistoryCollection.map((collection, index) => (
						<WizardPhotoHistory title={collection.title} photos={collection.items} key={index} />
					))}
				</div>
				{registerType !== 'edit-profile' &&
					this.state[type].currentForm !== registerMediaTabCodes.EditPictures &&
					profile_type && (
						<SkipThisStep
							currentForm={currentForm}
							currentType={currentType}
							changeForm={this.setForm}
							text={'Save Profile Info'}
							saveForms={this.saveForms}
							preventUpdate={true}
							style={{
								width: '100%',
								background: '#ffd210',
								border: 'none',
								color: '#fff',
								fontWeight: '600',
								height: '3.28125rem',
								fontSize: '1.125rem',
								borderBottom: '1px solid #fff',
							}}
							profile_type={profile_type}
						/>
					)}
			</div>
		)
	}

	isValidForm = (currentForm) => {
    const { forms } = this.props

		for (const prop in forms) {
			if (prop === currentForm && forms.hasOwnProperty(prop)) {
			  const form = forms[prop];

        if (currentForm == 'KYC') {
          if(form.hasOwnProperty('values')) {
            if (form.values.hasOwnProperty('skip_kyc')) {
              if (form.values.skip_kyc === true) {
                //TODO: validate
                return true;
              }
            }
          }
        }

			  if(form.hasOwnProperty('syncErrors')) {
          return false
        }

			  // fix bug old
        if(form.hasOwnProperty('values')) {
          if (form.values.hasOwnProperty('kyc_status')) {
            if(form.values.kyc_status === KycStatusType.VALIDATION_ASKED) {
              return false;
            }
          }
        }
			}
		}
		return true
	}

	// scrollHandler = (headerHeight) => {
	//   if ($('#root-wrapper')
	//       .scrollTop() >= headerHeight) {
	//     if (!this.state.isScrollMainContent) {
	//       this.setState({
	//         isScrollMainContent: true
	//       });
	//     }
	//   } else {
	//     if (this.state.isScrollMainContent) {
	//       this.setState({
	//         isScrollMainContent: false
	//       });
	//     }
	//   }
	// };

	componentDidMount() {
        window.scrollTo(null, 0)
		// const app = document.querySelector('#root-wrapper');
		// this.setState({
		//   widthScroll: app.offsetWidth - app.clientWidth
		// });
		// let headerHeight = $('.type-switcher')
		//   .innerHeight();
		// $('#root-wrapper')
		//   .scroll(this.scrollHandler.bind(this, headerHeight));
		//
		// $('#wizard-content .content-scrolled')
		//   .css({ minHeight: this.rightSide.clientHeight });
	}

	componentWillUnmount() {
		// $('#root-wrapper')
		//   .off('scroll', this.scrollHandler.bind(this, 0));
	}

	onCloseErrorPopUp = () => {
		this.setState({ isOpenErrorPopUp: false })
	}

	render() {
		const { isMobile } = this.props;
		const { currentType, isOpenErrorPopUp } = this.state

		const currentForm = this.state[currentType].currentForm

		const menuComponent = isMobile ? <div /> : <AutoAffix
				viewportOffsetTop={document.getElementById('NavBar') ?
					document.getElementById('NavBar').offsetHeight : 75}
				/>

		return (

			<div className="profile-edit">
        {(
          this.state.renderPendingAccountApprovalNotice && (
            <div className="container">
              <div className="row">
                <div className="col-md-12 pt-5">
                  <div className="alert alert-danger mb-0 text-center" role="alert">
                    Gigworks team is currently reviewing your account information. Please be patient, we will get back to you soon!
                  </div>
                </div>
              </div>
            </div>
          )
        )}
				{/*type navigation*/}
				{ _.get(this.props, 'registerType') !== 'email-password' && this.getTypeSwitcher()}


        {(
          !this.state.renderPendingAccountApprovalNotice && (
				<div id="content-wrapper" className="content-wrapper row" style={{ minHeight: '100vh' }}>
					{/*leftblock*/}
					<div className={'nav-menu'}>
						<menuComponent.type
							{...menuComponent.props}
							children={<div>{this.getLeftNavMenu(currentForm, currentType)}</div>}
						/>
					</div>
					{/*content*/}
					<div id="wizard-content" className={'content-main'}>
						<div className="content-scrolled">{this.getContent(currentForm, currentType)}</div>
					</div>
					{/*rightblock*/}
					<div
						className={'side-panel'}
						ref={r => {
							this.rightSide = r
						}}
					>
						<menuComponent.type
							{...menuComponent.props}
							children={<div>{this.getRightSide(currentType)}</div>}
						/>
					</div>
					<div className="errors-popup">
						<AlertPopUp
							isOpen={isOpenErrorPopUp}
							title={`ALERT MESSAGE`}
							text={this.state.errorsModalContent}
							okBtn={{
								label: `Ok, I understand.`,
								onClick: this.onCloseErrorPopUp,
							}}
						/>
					</div>
				</div>))}
			</div>
		)
	}
}

export default withRouter(WizardOverviewStep)
