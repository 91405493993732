import moment from 'moment'
import momentify from '../../momentify'

const validate = values => {
	const errors = {}

	if (!values.performer_type) {
		errors.performer_type = 'Required'
	}

	if (!values.budget) {
		errors.budget = 'Required'
	}

	if (values.performer_type === 'venue' && +values.budget < 1) {
		errors.budget = 'Minimum of 50'
	}
	if (values.performer_type === 'artist' && +values.budget < 1) {
		errors.budget = 'Minimum of 30'
	}

	if (!values.currency) {
		errors.currency = 'Required'
	}

	if (values.is_flexible_budget === undefined) {
    errors.currency = 'Required'
  }

	if (!values.noTime) {
		const timeGetter = createGetter(momentify(values.start_date))
		const dateGetter = createGetter(momentify(values.date))

		const currentStartDate = moment({
			year: dateGetter('year'),
			month: dateGetter('month'),
			date: dateGetter('date'),
			hours: timeGetter('hours'),
			minutes: timeGetter('minutes'),
			seconds: timeGetter('seconds'),
		})

		if (currentStartDate < moment()) {
			errors.start_date = 'Start date must be greater than current date'
		}

		if (!values.eventLength) {
			errors.eventLength = 'Required'
		}
		else if(values.eventLength < 0) {
			errors.eventLength = 'Minimum of 0'
		}

		if (!values.eventLengthUnit) {
			errors.eventLengthUnit = 'Required'
		}
	}

	if (!values.date) {
		errors.date = 'Required'
	}

	return errors
}

const createGetter = obj => prop => obj[prop]()

export default validate
