import React from 'react';

import { Row, Col } from 'reactstrap';

import 'antd/es/form/style/css'
import 'antd/es/radio/style/css'
import 'antd/es/input/style/css'

import {
    Form, Icon, Input, Button, Card, Radio
  } from 'antd';

const RadioGroup = Radio.Group;

export default class DirectDebitForm extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {            
            bankRoutingNumber: '',
            checkingAccNumber: '',
            checkCheckingAccNumber: '',

            userIdentifierType: 'DRIVER_LICENSE',
            driverLicenseNumber: '',
            passportNumber: ''
        }
    }

    handleChange = value => {
        const { onChange } =  this.props;
        this.setState({ ...value }, () => onChange({ ...this.state }))
    }

    render() {
        const {
            bankRoutingNumber,
            checkingAccNumber,
            checkCheckingAccNumber,            
            passportNumber,
            driverLicenseNumber,
            userIdentifierType
        } = this.state;
        
        return <Row>
            <Col xs={12} className="my-2">
                <Input placeholder="Bank routing number" size="large"
                    value={bankRoutingNumber}
                    onChange={e => this.handleChange({ bankRoutingNumber: e.target.value })}
                />
            </Col>

            <Col xs={12} className="my-2">
                <Input placeholder="Checking Account Number" size="large"
                    value={checkingAccNumber}
                    onChange={e => this.handleChange({ checkingAccNumber: e.target.value })}
                />
            </Col>

            <Col xs={12} className="my-2">
                <Input placeholder="Repeat Checking Account Number" size="large"
                    value={checkCheckingAccNumber}
                    onChange={e => this.handleChange({ checkCheckingAccNumber: e.target.value })}
                />
            </Col>

            <Col xs={12} className="mt-2">
                <RadioGroup 
                    onChange={e => this.handleChange({ userIdentifierType: e.target.value })}
                    value={userIdentifierType}>                                 
                    <Radio value={'DRIVER_LICENSE'} className="mb-2">
                        <Input placeholder="Driver license" size="large" 
                            value={driverLicenseNumber}
                            onChange={e => this.handleChange({ driverLicenseNumber: e.target.value })}
                            disabled={userIdentifierType !== 'DRIVER_LICENSE'}
                        />
                    </Radio>

                    <Radio value={'PASSPORT_NUMBER'}>
                        <Input placeholder="Passport number" size="large" 
                            value={passportNumber}
                            onChange={e => this.handleChange({ passportNumber: e.target.value })}
                            disabled={userIdentifierType !== 'PASSPORT_NUMBER'}
                        />
                    </Radio>
                </RadioGroup>
            </Col>
        </Row>
    }
}