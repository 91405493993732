import React from 'react'
import * as _ from 'lodash'
import { Popover } from 'reactstrap';
import './GigworksPopover.scss'
import classNames from 'classnames'

class GigworksPopover extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            uniqueId: _.get(props, 'target.props.id', _.uniqueId('popover_')),
            popoverOpen: false
        }
    }
    
    togglePopover = () => this.setState({ popoverOpen: !this.state.popoverOpen })

    render() {
        const { target, children, className } = this.props
        const { uniqueId, popoverOpen } = this.state;

        return <div>
            <target.type {...target.props} id={uniqueId} onClick={this.togglePopover} />
        
            <Popover
                placement="bottom-start"
                isOpen={popoverOpen}
                target={uniqueId}
                className={classNames("GigworksPopover", className)}
                innerClassName="GigworksPopover__Inner"
                toggle={this.togglePopover}
                modifiers={{ preventOverflow: { enabled: false } }}
                offset={'-16px'}
                >
                {children({ togglePopover: this.togglePopover })}
            </Popover>
        </div>
    }
} 


export default GigworksPopover