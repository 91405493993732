import * as _ from 'lodash'

export const FAVORITES_TREE_FETCH_START = 'FAVORITES_TREE_FETCH_START'
export const FAVORITES_TREE_FETCH_SUCCESS = 'FAVORITES_TREE_FETCH_SUCCESS'
export const FAVORITES_TREE_FETCH_FAIL = 'FAVORITES_TREE_FETCH_FAIL'

export const FAVORITES_FETCH_START = 'FAVORITES_FETCH_START'
export const FAVORITES_FETCH_SUCCESS = 'FAVORITES_FETCH_SUCCESS'
export const FAVORITES_FETCH_FAIL = 'FAVORITES_FETCH_FAIL'

export const CLEAR_FAVORITES_LIST = 'CLEAR_FAVORITES_LIST'

export const ADD_TO_FAVORITES_START = 'ADD_TO_FAVORITES_START'
export const ADD_TO_FAVORITES_SUCCESS = 'ADD_TO_FAVORITES_SUCCESS'
export const ADD_TO_FAVORITES_FAIL = 'ADD_TO_FAVORITES_FAIL'

export const REMOVE_FROM_FAVORITES_START = 'REMOVE_FROM_FAVORITES_START'
export const REMOVE_FROM_FAVORITES_SUCCESS = 'REMOVE_FROM_FAVORITES_SUCCESS'
export const REMOVE_FROM_FAVORITES_FAIL = 'REMOVE_FROM_FAVORITES_FAIL'


const initialState = {
	favoritesTree: {},
	isFavoritesTreeFetching: false,
	favoritesList: [],
	isFavoritesFetching: false,
	isAddingToFavorites: false,
	isRemovingFromFavorite: false,
}

export default function actions(state = initialState, action = {}) {
	switch (action.type) {
		case FAVORITES_TREE_FETCH_START:
			return {
				...state,
				isFavoritesTreeFetching: true,
			}
		case FAVORITES_TREE_FETCH_SUCCESS:
			return {
				...state,
				favoritesTree: action.result.data,
				isFavoritesTreeFetching: false,
			}
		case FAVORITES_TREE_FETCH_FAIL:
			return {
				...state,
				isFavoritesTreeFetching: false,
			}

		case FAVORITES_FETCH_START:
			return {
				...state,
				isFavoritesFetching: true,
			}
		case FAVORITES_FETCH_SUCCESS:
			return {
				...state,
				favoritesList: action.result.data,
				isFavoritesFetching: false,
			}
		case FAVORITES_FETCH_FAIL:
			return {
				...state,
				isFavoritesFetching: false,
			}

		case CLEAR_FAVORITES_LIST:
			return {
				...state,
				favoritesList: [],
			}

		case ADD_TO_FAVORITES_START:
			return {
				...state,
				isAddingToFavorites: true,
			}
		case ADD_TO_FAVORITES_SUCCESS:
			return {
				...state,
				isAddingToFavorites: false,
			}
		case ADD_TO_FAVORITES_FAIL:
			return {
				...state,
				isAddingToFavorites: false,
			}

		case REMOVE_FROM_FAVORITES_START:
			console.log('Remove from favorties start')
			console.log({state, action})
			const targetId = action.targetId
			const favoritesTree = JSON.parse(JSON.stringify(state.favoritesTree))
			

			_.forOwn(favoritesTree, (mainCategory, mainCategoryId) => {
				_.forOwn(mainCategory.child, (superCategory, superCategoryId) => {
					const newChildren = {}
					_.forOwn(superCategory.child, (profile, profileId) => {
						if (targetId !== profileId) {
							newChildren[profileId] = profile
						}
						console.log({ targetId, profileId })
					})
					console.log({ newChildren,  })
					favoritesTree[mainCategoryId].child[superCategoryId].child = newChildren
				})
			})

			return {
				...state,
				isRemovingFromFavorite: true,
			}
		case REMOVE_FROM_FAVORITES_SUCCESS:
			return {
				...state,
				isRemovingFromFavorite: false,
			}
		case REMOVE_FROM_FAVORITES_FAIL:
			return {
				...state,
				isRemovingFromFavorite: false,
			}

		default:
			return {
				...state,
			}
	}
}

export function getFavoritesTree() {
	return {
		types: [FAVORITES_TREE_FETCH_START, FAVORITES_TREE_FETCH_SUCCESS, FAVORITES_TREE_FETCH_FAIL],
		promise: client => client.get(`/api/favorite/categories`),
	}
}

export function getFavorites(usersIds) {
	return {
		types: [FAVORITES_FETCH_START, FAVORITES_FETCH_SUCCESS, FAVORITES_FETCH_FAIL],
		promise: client => client.get(`/api/users/lists?user_ids=${usersIds.join()}`),
	}
}

export function clearFavoritesList() {
	return {
		type: CLEAR_FAVORITES_LIST,
	}
}

export function addToFavorites(id) {
	return {
		types: [ADD_TO_FAVORITES_START, ADD_TO_FAVORITES_SUCCESS, ADD_TO_FAVORITES_FAIL],
		targetId: id,
		promise: client => client.post(`/api/favorite/${id}`),
	}
}

export function removeFromFavorites(id) {
	return {
		types: [REMOVE_FROM_FAVORITES_START, REMOVE_FROM_FAVORITES_SUCCESS, REMOVE_FROM_FAVORITES_FAIL],
		targetId: id,
		promise: client => client.delete(`/api/favorite/${id}`),
	}
}
