import React, { Component } from 'react';

import '../../../styles/components/WizardPhotoHistory.scss';

export default class WizardPhotoHistory extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    const {title, photos} = this.props;
    const photoItem = photos.map((photo, index) => (
      <li key={index} className="photo-history__photo-wrapper">
        <div className="photo-history__aspect-ratio">
          <div className="photo-history__aspect-ratio-content">
            <div
              className="photo-history__photo"
              style={{backgroundImage: `url(${photo.url})`, backgroundSize: 'cover'}}
              alt="image"/>
          </div>
        </div>
      </li>
    ));

    return (
      <div className="photo-history">
        <p className="photo-history__title">{title}</p>
        <ul className="list-unstyled photo-history__photos">
          {photoItem}
        </ul>
      </div>
    );
  }
}
