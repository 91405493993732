import React, { Component } from 'react'
import { Field, reduxForm } from 'redux-form'
import validate from '../../../helpers/validators/artist/PlansAndTalents'
// import validate from '../../../helpers/validators/validate'
import '../../../styles/components/WizardPlansAndTalents.scss'
// import table from '../../../assets/table.png'
import { connect } from 'react-redux'
import { getValues } from '../../../helpers/methods'
import { Categories } from './../Category'
// import { RadioButton, RadioButtonGroup } from 'material-ui/RadioButton'

// import RaisedButton from 'material-ui/RaisedButton'
import classnames from 'classnames'
// import { getInitialValues } from '../media/AudioUploadWrapper'
import { Asterisk } from '../../common/Asterisk/Asterisk'
// import { registrationConfig } from '../../../config'

// import PlansList from '../../DashBoard/Plans/List/PlansList'

class WizardPlansAndTalents extends Component {
	constructor(props) {
		super(props)

		this.state = {
			isOpened: false,
			plans: '',
		}
	}

	onCheckPlan(value) {
		window.scrollTo(0, 0)
		this.setState({
			plans: value,
			isOpened: false,
		})
	}

	togglePlansMenu(isOpened) {
		window.scrollTo(0, 0)
		this.setState({
			isOpened,
		})
	}

	render() {
		const { handleSubmit, initialValues } = this.props

		return (
			<form onSubmit={handleSubmit} className="plans-and-venue-profile">
				{!this.state.isOpened && (
					<div>
						<div
							className={classnames('band-contact-info__info', {
								filled: initialValues.categories,
							})}
						>
							Talent and Styles <Asterisk className="text-danger ml-1" />
						</div>
						<div className="band-genre-form__wrapper">
							<Field name="categories" type="artist.basic" categoryTypeText={'talent'} component={Categories} />
						</div>
					</div>
				)}
			</form>
		)
	}
}

WizardPlansAndTalents = reduxForm({
	form: 'PlansAndTalents',
	destroyOnUnmount: false,
	forceUnregisterOnUnmount: false,
	validate,
})(WizardPlansAndTalents)

export default connect(({ user, form }) => ({
	currentUser: user.currentUser,
	initialValues: getValues(
		form.PlansAndTalents,
		user.currentUser
			? {
					categories: user.currentUser.categories.length
						? [getCategoriesFromUser(user.currentUser)[0]] //Some changes, only one Primary Talent
						: [
								{
									id: '',
									name: '',
									styles: [],
								},
						  ],
			  }
			: { categories: [{ id: '', name: '', styles: [] }] }
	),
}))(WizardPlansAndTalents)

//for now support only one category
function getCategoriesFromUser(user) {
	let userCat = [...user.categories]
	const userStyles = [...user.styles]

  userCat = userCat.filter(category => category.type === "talent");

	return userCat.map(cat => ({
		id: cat.id,
		name: cat.name,
		styles: userStyles.map(style => style)
	}))
}
