const validate = values => {
  const errors = {};

  if (!values.first_name) {
    errors.first_name = 'Required';
  } else if (values.first_name.length < 2){
    errors.first_name = 'First Name should be at least 2 characters';
  }

  if (!values.last_name) {
    errors.last_name = 'Required';
  } else if (values.last_name.length < 2){
    errors.last_name = 'Last Name should be at least 2 characters';
  }

  if (!values.interested_as) {
    errors.interested_as = 'Required';
  }

  if (values.phone_number && !/^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im.test(values.phone_number)){
    errors.phone_number = 'The phone number is invalid';
  }

  if (!values.email) {
    errors.email = 'Required';
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
    errors.email = 'Invalid email address';
  }

  return errors;
};

export default validate;
