import './InlinePreLoader.scss';
import React, { Component } from 'react';


export class InlinePreLoader extends Component {

  render() {
    return (
      <div className='InlinePreLoader'>
        <div className="dotAnimation">
          <div className="dot1"/>
          <div className="dot2"/>
          <div className="dot3"/>
        </div>
      </div>
    );
  }
}
