import React, { Component } from 'react'
import { Field, reduxForm } from 'redux-form'

import { Categories } from '../Category'
import validate from '../../../helpers/validators/venue/VenueProfile'
import '../../../styles/components/venue/WizardPlansAndVenueProfile.scss'
import { connect } from 'react-redux'
import { getValues } from '../../../helpers/methods'
import classnames from 'classnames'
import {Asterisk} from "../../common/Asterisk/Asterisk";

class WizardPlansAndVenueProfile extends Component {
	constructor(props) {
		super(props)

		this.state = {
			isOpened: true,
			plans: '',
		}
	}

	onCheckPlan(value) {
		window.scrollTo(0, 0)

		this.setState({
			plans: value,
			isOpened: false,
		})
	}

	togglePlansMenu(isOpened) {
		window.scrollTo(0, 0)

		this.setState({
			isOpened,
		})
	}

	render() {
		const { handleSubmit, initialValues } = this.props

		return (
			<form onSubmit={handleSubmit} className="plans-and-venue-profile">
				<div>
					<div
						className={classnames('band-contact-info__info', {
							filled: initialValues.categories,
						})}
					>
						Venue Type & Styles <Asterisk />
					</div>
					<div className="band-genre-form__wrapper">
						<Field name="categories" type="venue.basic" categoryTypeText={'business type'} component={Categories} />
					</div>
				</div>
			</form>
		)
	}
}

//TODO refactoring
WizardPlansAndVenueProfile = reduxForm({
	form: 'PlansAndVenueProfile',
	destroyOnUnmount: false,
	forceUnregisterOnUnmount: false,
	enableReinitialize: true,
	keepDirtyOnReinitialize: true,
	validate,
})(WizardPlansAndVenueProfile)

export default connect(({ profile, user, form }) => ({
	currentUser: user.currentUser,
	initialValues: getValues(
		form.PlansAndVenueProfile,
		user.currentUser
			? {
					tags: user.currentUser.tags
						? {
								venue_occasion: user.currentUser.tags
									.filter(tag => tag.type === 'venue_occasion')
									.map(tag => ({ content: tag.content })),
						  }
						: { venue_occasion: [] },
					categories: user.currentUser.categories.length
						? getCategoriesFromUser(user.currentUser, profile)
						: [
								{
									id: '',
									name: '',
									styles: [],
									good_for: []
								},
						  ],
			  }
			: {
					categories: [{ id: '', name: '', styles: [], good_for: [] }],
			  }
	),
}))(WizardPlansAndVenueProfile)

//for now support only one category
function getCategoriesFromUser(user, profile) {
	return user.categories.map(category => ({
		id: category.id,
		name: category.name,
		styles: user.styles ? user.styles.filter(style => style.category_id === category.id) : [],
		good_for: profile.profile.category_details ? profile.profile.category_details.filter(detail => detail.category_id === category.id).map(x => ({ id: x.dictionary_id })) : [],
	}))
}
