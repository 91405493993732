import messagesAr from './translations/ar.json';
import messagesBg from './translations/bg.json';
import messagesCh from './translations/ch.json';
import messagesCz from './translations/cz.json';
import messagesEl from './translations/el.json';
import messagesEs from './translations/es.json';
import messagesFr from './translations/fr.json';
import messagesHeb from './translations/heb.json';
import messagesHi from './translations/hi.json';
import messagesHr from './translations/hr.json';
import messagesIt from './translations/it.json';
import messagesJp from './translations/jp.json';
import messagesKo from './translations/ko.json';
import messagesMsa from './translations/msa.json';
import messagesPhi from './translations/phi.json';
import messagesPrt from './translations/prt.json';
import messagesRo from './translations/ro.json';
import messagesRu from './translations/ru.json';
import messagesSr from './translations/sr.json';
import messagesSwa from './translations/swa.json';
import messagesSwe from './translations/swe.json';
import messagesTha from './translations/tha.json';
import messagesTr from './translations/tr.json';
import messagesVi from './translations/vi.json';
import messagesEng from './translations/en.json';
import messagesDe from './translations/de.json';
import messagesPl from './translations/pl.json';
import messagesNl from './translations/nl.json';

const lang = localStorage.getItem('lang') ? localStorage.getItem('lang') : 'ENG';
const messages = {
	'ENG': messagesEng,
  'DE': messagesDe,
	'PL': messagesPl,
	'NL': messagesNl,
  'AR': messagesAr,
  'BG': messagesBg,
  'CH': messagesCh,
  'CZ': messagesCz,
  'EL': messagesEl,
  'ES': messagesEs,
  'FR': messagesFr,
  'HEB': messagesHeb,
  'HI': messagesHi,
  'HR': messagesHr,
  'IT': messagesIt,
  'JP': messagesJp,
  'KO': messagesKo,
  'MSA': messagesMsa,
  'PHI': messagesPhi,
  'PRT': messagesPrt,
  'RO': messagesRo,
  'RU': messagesRu,
  'SR': messagesSr,
  'SWA': messagesSwa,
  'SWE': messagesSwe,
  'THA': messagesTha,
  'TR': messagesTr,
  'VI': messagesVi
};

export const setPageLanguage = (lang) => {
	localStorage.setItem('lang', lang);
}

export const translate = (translation) => {
	const engMessage = messages['ENG'][translation];
	return messages[lang] ?
			messages[lang][translation] ?
				messages[lang][translation]
				: engMessage
		   : engMessage;
}
