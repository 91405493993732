import React, { Component } from 'react';
import Rating from 'react-rating';
import * as _ from 'lodash'
import PropTypes from 'prop-types';
import '../../styles/components/ReviewStars.scss';

export default class ReviewStars extends Component {
  static propTypes = {
    rating: PropTypes.object.isRequired,
    readonly: PropTypes.bool.isRequired
  };

  render() {
    const { rating, readonly, onlyStars } = this.props;
    const averageRating = Math.round(rating.average);
    
    return (
      <div className="review_stars">
        <Rating
          initialRate={_.defaultTo(averageRating, 0)}
          readonly={readonly}
          empty="fa fa-star-o fa-fw"
          full="fa fa-star fa-fw"
        />
        {!onlyStars ? <p className="average-rating">
          {averageRating ? `${averageRating} out 5 stars` : `No stars yet`} 
        </p> : null}
      </div>
    );
  }
}
