import React from 'react'
import * as _ from 'lodash'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { Input } from 'antd'
import 'antd/es/input/style/css'
import Button from '../../../common/Button/Button'
import { createBankAccount } from '../../../../redux/modules/cards'
import { Checkbox } from '../../../CommonComponent'
import SimpleFormattedInput from './SimpleFormattedInput/SimpleFormattedInput'
import { Select } from 'antd'
import { getCountriesList } from '../../../../redux/modules/common'

const { Option, OptGroup } = Select

import './NewBankAccountForm.scss'
import Spinner from '../../../common/Spinner/Spinner'
import { SEPA_COUNTRIES } from '../../../../utils/constants'

@withRouter
@connect(({ cards, common }) => ({
  countriesList:          common.countriesList,
  isGettingCountriesList: common.isGettingCountriesList,
  isFetchingBankAccounts: cards.isFetchingBankAccounts,
}), {
  createBankAccount, getCountriesList,
})
class NewBankAccountForm extends React.PureComponent {
  constructor (props) {
    super(props)
    this.state = {
      IBAN:    '', // 'FR7618829754160173622224154',
      BIC:     '', //'CMBRFR2BCME',
      street:  '',
      city:    '',
      zipcode: '',
      state:   '',
      country: '',
      error:   null,

      isIBANValid: null,
      isIBICValid: null,

      isStreetValid:  null,
      isCityValid:    null,
      isZipCodeValid: null,
      isStateValid:   null,
      isCountryValid: null,
    }
  }

  componentDidMount () {
    const { countriesList, isGettingCountriesList, getCountriesList } = this.props

    if (!countriesList.length || !isGettingCountriesList) {
      getCountriesList()
    }
  }

  handleCreate = () => {
    const { IBAN, BIC, street, city, zipcode, state, country } = this.state
    const { createBankAccount, returnURL, afterSuccess }       = this.props

    createBankAccount({
      IBAN,
      BIC,
      adress: {
        street,
        city,
        zipcode,
        state,
        country,
      },
      returnURL,
    }).then(res => {
      window.location.replace(res.data.redirect_url)
    }).catch(rej => {
      this.setState({ error: 'Please insert correct IBAN and BIC' })
    })
  }

  handleChange = nextValue => {
    const { noSubmit, onChange } = this.props

    this.setState(
      { error: null, ...nextValue },
      () => {
        if (noSubmit) {
          const { IBAN, BIC, street, city, zipcode, state, country } = this.state
          onChange({
            IBAN,
            BIC,
            street,
            city,
            zipcode,
            state,
            country,
            isValid: this.isValid(),
          })
        }
      })
  }

  isValid = () => {
    const { IBAN, BIC, isIBANValid, isIBICValid } = this.state
    return !!IBAN && !!BIC && isIBANValid && isIBICValid &&
      !!this.state.street && this.state.isStreetValid &&
      !!this.state.city && this.state.isCityValid &&
      !!this.state.zipcode && this.state.isZipCodeValid &&
      !!this.state.state && this.state.isStateValid &&
      !!this.state.country && this.state.isCountryValid
  }

  render () {
    const {
            noSubmit,
            isGettingCountriesList,
            isFetchingBankAccounts,
            countriesList = [],
          } = this.props

    console.log()
    const {
            IBAN,
            BIC,
            street,
            city,
            zipcode,
            state,
            country,
            error,
          } = this.state

    const noSpecialChars =
            value => !(/[~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]+/.test(
              ('' + value)))

    const getCustomNumberFields = countryCode => {
      if (countryCode.toUpperCase() == 'US') {
        return {
          firstNumber:  {
            label:     'Account number',
            validator: value => noSpecialChars(value) &&
              (6 < ('' + value).length),
            maxLength: 14,
          },
          secondNumber: {
            label:     'ABA/Routing Number',
            validator: value => noSpecialChars(value) &&
              (('' + value).length === 9),
            maxLength: 9,
          },
        }
      }

      if (countryCode.toUpperCase() == 'GB') {
        return {
          firstNumber:  {
            label:     'Account number',
            validator: value => noSpecialChars(value) &&
              (('' + value).length === 8),
            maxLength: 8,
          },
          secondNumber: {
            label:     'Sort code',
            validator: value => noSpecialChars(value) &&
              (('' + value).length === 6),
            maxLength: 6,
          },
        }
      }

      if (countryCode.toUpperCase() == 'CA') {
        return {
          firstNumber:  {
            label:     'Account number',
            validator: value => noSpecialChars(value) &&
              (('' + value).length > 3),
            maxLength: 20,
          },
          secondNumber: {
            label:     'Institution number',
            validator: value => noSpecialChars(value) &&
              (('' + value).length > 3),
            maxLength: 4,
          },
        }
      }

      return {
        firstNumber:  {
          label:     'IBAN',
          //blocks: [2, 2, 4, 4, 4, 4, 4, 4],
          validator: value => noSpecialChars(value) && ('' + value.length > 15),
          maxLength: 28,
        },
        secondNumber: {
          label:     'BIC',
          //blocks: [4, 2, 2, 3],
          validator: value => noSpecialChars(value) && !!value,
          maxLength: 11,
        },
      }
    }

    const customFields = getCustomNumberFields(country)

    const onlyCountriesList = countriesList.filter(x => x.type === 'country')

    const countryIsSepa = (country) => {
      return _.find(SEPA_COUNTRIES, (countryCode) => countryCode == country.id)
        ? true
        : false
    }

    const countryIsNotSepa = (country) => {
      return !countryIsSepa(country);
    }

    let sepaCountries = onlyCountriesList.filter(
      (country) => countryIsSepa(country))

    let nonSepaCountries = onlyCountriesList.filter(
      (country) => countryIsNotSepa(country))

    console.log(sepaCountries)
    console.log(nonSepaCountries)

    sepaCountries = _.orderBy(
      sepaCountries, ['name'], ['asc'])

    nonSepaCountries = _.orderBy(
      nonSepaCountries, ['name'], ['asc'])

    const optGroupStyle = {
      fontSize: '16px'
    }

    return (
      <div className="NewBankAccountForm d-flex flex-column">
        {(isGettingCountriesList || isFetchingBankAccounts) && <Spinner/>}

        <span style={{ marginLeft: 12, fontWeight: 600 }}>Country</span>

        <Select
          showSearch
          placeholder="Select a country"
          optionFilterProp="children"
          filterOption={(input, option) => {
              const children = option.props.children;
              if(typeof children !== "string") return;
              return children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          }
          onChange={value => this.handleChange({
            country:        value,
            isCountryValid: true,
            IBAN:           '',
            BIC:            '',
          })}
          className="Select"
        >
          <OptGroup style={optGroupStyle} key={'sepa'} label="SEPA Countries">
            {sepaCountries
              .map(({ id, name }) => <Option key={id} value={id}>
                {name}
              </Option>)
            }
          </OptGroup>
          <OptGroup style={optGroupStyle} key={'nonsepa'} label="Non-SEPA Countries">
            {nonSepaCountries
              .map(({ id, name }) => <Option key={id} value={id}>
                {name}
              </Option>)
            }
          </OptGroup>
        </Select>

        <span className="d-inline-block text-muted mt-1 mb-2" style={{
          fontSize:   '.8rem',
          marginLeft: 12,
        }}>
					Please note that if your Bank is located outside of
          {' '}
          <a className="text-info" target="_blank"
             href={`https://en.wikipedia.org/wiki/Single_Euro_Payments_Area`}>SEPA</a> zone
					MangoPay will automatically charge 3 EUR for each transfer to that account
				</span>

        <SimpleFormattedInput
          placeholder=""
          label={customFields.firstNumber.label}
          value={IBAN}
          maxLength={customFields.firstNumber.maxLength}
          noSpecialChars
          validator={customFields.firstNumber.validator}
          validateError={`Please fill valid ${customFields.firstNumber.label} number`}
          options={{ blocks: customFields.firstNumber.blocks }}
          onChange={({ value, isValid }) => this.handleChange({
            IBAN:        value,
            isIBANValid: isValid,
          })}
        />

        <SimpleFormattedInput
          placeholder=""
          noSpecialChars
          label={customFields.secondNumber.label}
          value={BIC}
          maxLength={customFields.secondNumber.maxLength}
          validator={customFields.secondNumber.validator}
          validateError={`Please fill valid ${customFields.secondNumber.label}`}
          options={{ blocks: customFields.secondNumber.blocks }}
          onChange={({ value, isValid }) => this.handleChange({
            BIC:         value,
            isIBICValid: isValid,
          })}
        />

        <SimpleFormattedInput
          placeholder=""
          label="Street"
          basicInput
          value={street}
          validator={value => !!value}
          validateError={`Please fill your address`}
          options={{}}
          onChange={({ value, isValid }) => this.handleChange({
            street:        value,
            isStreetValid: isValid,
          })}
        />

        <SimpleFormattedInput
          placeholder=""
          label="City"
          value={city}
          basicInput
          validator={value => !!value}
          validateError={`Please fill your address`}
          options={{}}
          onChange={({ value, isValid }) => this.handleChange({
            city:        value,
            isCityValid: isValid,
          })}
        />

        <SimpleFormattedInput
          placeholder=""
          label="Zip code"
          value={zipcode}
          basicInput
          validator={value => !!value}
          validateError={`Please fill your address`}
          options={{}}
          onChange={({ value, isValid }) => this.handleChange({
            zipcode:        value,
            isZipCodeValid: isValid,
          })}
        />

        <SimpleFormattedInput
          placeholder=""
          label="State"
          value={state}
          basicInput
          validator={value => !!value}
          validateError={`Please fill your address`}
          options={{}}
          onChange={({ value, isValid }) => this.handleChange({
            state:        value,
            isStateValid: isValid,
          })}
        />

        {error && <span className="text-danger pl-3">{error}</span>}

        {!noSubmit && <Button
          className="mt-3"
          label="Save Bank Account"
          primary
          disabled={!this.isValid()}
          onClick={this.handleCreate}
          type="submit"
        />}
      </div>
    )
  }
}

export default NewBankAccountForm
