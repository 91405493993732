/**
 * should be imported
 */
import './../assets/favicon.png';
/**
 */

function setIsTouchSupported() {
  if (!(('ontouchstart' in window) || window.DocumentTouch && document instanceof window.DocumentTouch)) {
    document.getElementById('app').classList.add('hasHover');
  }
}

export default ()=>{
  //setIsTouchSupported();
};
