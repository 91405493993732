import React, { Component } from 'react'
import { Field, reduxForm } from 'redux-form'
import { connect } from 'react-redux'

import validate from '../../helpers/validators/plansAndPayments/PlansAndPayments'
import {getRealTypeOfUser, getValues} from '../../helpers/methods'

import { AbilityToTravel } from './CommonComponent'
import Plans from "../DashBoard/Plans/Plans";
import Payments from "./Payments/Payments";

import '../../styles/components/WizardOverviewPlansAndPayments.scss'

class WizardOverviewPlansAndPayments extends Component {
  sectionTypes = {
    plans: 'Plans',
    abilityToTravel: 'Ability To Travel',
    payments: 'Payments'
  };

  constructor(props) {
    super(props);

    this.state = {
      selectedSectionType: null,
      activePlan: null,
    };
  }

  selectSectionType = type => {
    this.setState({
      selectedSectionType: type,
    });
  };

  onPlanChange = (plan) => {
    this.setState({
      activePlan: plan,
    });
  }

  render() {
		const { handleSubmit, currentUser } = this.props;
		const { selectedSectionType } = this.state;

    const profileType = getRealTypeOfUser(currentUser);

    return (
      <div>
        <div>
          <div
            className="wizard-plans-payments--item"
            onClick={
              () => this.selectSectionType(selectedSectionType === 'plans' ? null : 'plans')
            }
          >
            <p>{this.sectionTypes.plans}</p>
            <div className="wizard-plans-payments--item-icon">
              <i className={selectedSectionType === 'plans' ? "fa fa-minus" : "fa fa-plus"} aria-hidden="true" />
            </div>
          </div>
          {
            selectedSectionType === 'plans' &&
            <div className="wizard-plans-payments--table">
              <div className="wizard-plans-payments__content flex-column">
                <div className="height-250 d-flex">
                  <div>
                    <Plans onPlanChange={this.onPlanChange}/>
                  </div>
                </div>
              </div>
            </div>
          }
        </div>
        <div>
          <div
            className="wizard-plans-payments--item"
            onClick={
              () => this.selectSectionType(selectedSectionType === 'payments' ? null : 'payments')
            }
          >
            <p>{this.sectionTypes.payments}</p>
            <div className="wizard-plans-payments--item-icon">
              <i className={selectedSectionType === 'payments' ? "fa fa-minus" : "fa fa-plus"} aria-hidden="true" />
            </div>
          </div>
          {
            selectedSectionType === 'payments' &&
            <div className="wizard-plans-payments--table">
              <div className="wizard-plans-payments__content flex-column">
                <div>
                  <Payments />
                </div>
              </div>
            </div>
          }
        </div>
        {
          profileType === 'venue' ?
            null :
            <div>
              <div
                className="wizard-plans-payments--item"
                onClick={
                  () => this.selectSectionType(selectedSectionType === 'abilityToTravel' ? null : 'abilityToTravel')
                }
              >
                <p>{this.sectionTypes.abilityToTravel}</p>
                <div className="wizard-plans-payments--item-icon">
                  <i className={selectedSectionType === 'abilityToTravel' ? "fa fa-minus" : "fa fa-plus"} aria-hidden="true" />
                </div>
              </div>
              {
                selectedSectionType === 'abilityToTravel' &&
                <div className="wizard-plans-payments--table">
                  <div className="wizard-plans-payments__content flex-column">
                    <div className="height-250 d-flex">
                      <div style={{width: '100%'}}>
                        <form className="service-payments-form" onSubmit={handleSubmit}>
                          <div className="service-payments-form__wrapper">
                            <Field
                              name="ability_to_travel"
                              required={true}
                              component={AbilityToTravel}
                              activePlan={this.state.activePlan}
                            />
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              }
            </div>
        }
      </div>
		)
	}
}

WizardOverviewPlansAndPayments = reduxForm({
	form: 'PlansArtist',
	destroyOnUnmount: false,
	forceUnregisterOnUnmount: false,
	enableReinitialize: true,
	validate,
})(WizardOverviewPlansAndPayments)
export default connect(
	({ user, form }) => ({
		currentUser: user.currentUser,
		initialValues: getValues(
			form.PlansArtist,
      user.currentUser && user.currentUser.profile ? {
			  ability_to_travel: user.currentUser.countries[0] ? user.currentUser.countries[0].id : null,
        } : {}
		),
	}),
	{}
)(WizardOverviewPlansAndPayments)
