const validate = values => {
	const errors = {}

	// if (!values.categories[0].name) {
	// 	errors.categories = 'Required'
	// }

	return errors
}

export default validate
