
const KycStatusType = {
  VALIDATED : 'VALIDATED',
  REFUSED : 'REFUSED',
  CREATED : 'CREATED',
  VALIDATION_ASKED : 'VALIDATION_ASKED'
};

const KycDocumentType = {
  IDENTITY_PROOF : 'IDENTITY_PROOF',
  REGISTRATION_PROOF : 'REGISTRATION_PROOF',
  ARTICLES_OF_ASSOCIATION : 'ARTICLES_OF_ASSOCIATION'
};

export { KycDocumentType, KycStatusType };
