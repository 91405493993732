import React from 'react';
import { SkyLightStateless } from 'react-skylight';
import { currency } from '../utils/constants';
import '../styles/components/PriceListPopup.scss';

const defaultDialogStyles = {
  width: '60%',
  height: '60vh',
  marginTop: 'calc(60vh / -2)',
  marginLeft: '-30%',
  borderRadius: '16px'
};

const getCurrencyLabel = (items, searched) => {
  const result = items.find(x => x.value === searched);

  return result ? result.label : null;
};

export default props => {
  const { isOpened, onClose, priceList, name, dialogStyles } = props;

  return (
    <SkyLightStateless
      closeOnEsc
      hideOnOverlayClicked
      dialogStyles={dialogStyles || defaultDialogStyles}
      isVisible={isOpened}
      onCloseClicked={onClose}>
      <div className="price-list-popup">
        <h1 className="title">
          Service<br/>
          Price List
        </h1>
        <span className="name">{name || '---'}</span>
        <ul className="price-items">
          {
            (priceList && priceList.length)
              ? priceList.map((item, inx) => (
                <li key={inx}>
                  <span><b>{item.name}</b></span>
                  <span>
                    <b>{item.price}&nbsp;{getCurrencyLabel(currency, item.currency)}</b>
                    &nbsp;per&nbsp;
                    <b>{item.measurement}</b>
                  </span>
                </li>
              ))
              : <li className="no-prices">No Prices Yet</li>
          }
        </ul>
      </div>
    </SkyLightStateless>
  );
}
