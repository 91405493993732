import './SubscriptionWindow.scss';
import React, { Component } from 'react';
import RaisedButton from 'material-ui/RaisedButton';
import { TextField } from 'material-ui';
import SVGInline from 'react-svg-inline';

const closeSVG = require('!!svg-inline-loader?classPrefix!../../../assets/icons/close.svg');

const SubscriptionWindow = ({ close }) => (
  <div className="subscription-window">
    {/* {this.props.showMe && (
      <div className="container d-flex">
        <p style={{
          color: '#000',
          fontWeight: 'bold'
        }}>
          Your email address has been sucessfully added to our database.
        </p>
      </div>
    )} */}
    <div className="container d-flex">
      <TextField
        className="subscription-window__fields"
        hintText="First name" />
      <TextField
        className="subscription-window__fields"
        hintText="Last name" />
      <TextField
        className="subscription-window__fields"
        hintText="E-mail" />
      <RaisedButton
        label="Subscribe"
        //onClick={}
        className="subscribe-button"
        labelStyle={{
          textTransform: 'normal',
          fontSize: '14px',
          color: '#a2a2a2',
          overflow: 'hidden',
        }}
        buttonStyle={{
          background: 'transparent'
        }}
        style={{
          borderRadius: '50px',
          backgroundColor: '#fff',
          color: '#000',
          boxShadow: 'none',
          border: '1px solid #a2a2a2',
          padding: '0 20px',

        }}
        overlayStyle={{ borderRadius: '1.5rem' }} />
      <div
        className="close"
        onClick={close}>
        <SVGInline
          height='25px'
          fill={'#000000'}
          svg={closeSVG} />
      </div>
    </div>
  </div>
);

export default SubscriptionWindow;
