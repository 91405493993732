import React, { Component } from 'react'

import classNames from 'classnames'

import './PlansFooter.scss'

export default class PlansFooter extends Component {
	render() {
		const { prices, onClick, active, noButtons } = this.props;

		return (
			<div className="plans_footer">
				<div className="title" />
				<div className="label" />
				{prices.map((price, index) => {
					return (
						<div className="plan" key={`plan-${index}`}>
              {!noButtons &&
              <div
                className={classNames('select_button', {
                  active: price.id === active,
                })}
                onClick={() => {
                  if (!price.current) {
                    onClick(price.id)
                  }
                }}
              >
                Select
              </div>
              }
							<div className="name">{price.name}</div>
							{ price.amount &&
								<div className={classNames('amount', { underlined: price.amount })}>€{parseFloat(price.amount).toFixed(2)}/month</div>
							}
							<div className="description">{price.description && `${price.description}`}</div>
						</div>
					)
				})}
			</div>
		)
	}
}
