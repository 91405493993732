import React, { Component } from 'react'
import MySortableList from './SortableList'
import MediaItemWrapper from './MediaItemWrapper'
import { PRIMARY_COLOR } from '../../../utils/constants';
import { arrayMove } from 'react-sortable-hoc'
import { IconButton } from 'material-ui'
import * as _ from 'lodash'
import { connect } from 'react-redux'
import SVGInline from 'react-svg-inline'
const file_plus_icon = require('!!svg-inline-loader?classPrefix!../../../assets/icons/info-plus.svg')


const MINIMUM_ITEMS_COUNT = 3;

@connect(({ globalConfig }) => ({
	isMobile: globalConfig.isMobile,
}))
class MediaFieldWrapper extends Component {
	constructor(props) {
		super(props)

		this.state = {
			showUpload: false,
			listElements: [],
			isDragging: false,
		}
	}

	UNSAFE_componentWillMount() {
		this.setState({ listElements: this.getListElements() })
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		this.setState({ listElements: this.getListElements(nextProps) })
	}

	getListElements = (props = this.props) => {
        const items = props.input.value;
        const { media_type } = props;
        const _result = items.map((item, index) => this.generateListElement({ innerProps: { item }, index }));

		if (items && items.length < MINIMUM_ITEMS_COUNT) { // uzupełnianie zeby były conajmniej 3 zawszes
			[...Array(MINIMUM_ITEMS_COUNT - items.length).keys()].forEach(i=> {
				_result.push(this.generateListElement({
					innerProps: {
                        isEditing: true,
                        item: {
                            type: media_type,
                            temporaryId: '_' + Math.random().toString(12).substr(2, 9)
                        }
                    },
					wrapperProps: {
						disabled: true,
					},
					index: items.length + i,
				}))
			})
		}
		return _result
    }

	generateListElement = ({ index, wrapperProps = {}, innerProps }) => {
        const { getInnerContent, validator, media_type, customUrlInput } = this.props;

		if (this.props.isMobile) {
			wrapperProps.disabled = true
		}

		return {
			wrapperProps,
			children: (<MediaItemWrapper
				key={_.get(innerProps, 'item.id') || _.get(innerProps, 'item.temporaryId')}
                style={{minHeight: '220px', display: 'flex'}}
                media_type={media_type}
				index={index}
				validator={validator}
				customUrlInput={customUrlInput}
				children={getInnerContent && getInnerContent({ innerProps })}
				innerContentHandler={getInnerContent}
				onChange={this.handleSave}
				onRemove={this.handleRemove}
				openCrop={this.openCrop}
				{...innerProps}
			/>)
		}
    }

	handleSave = (item) => {
		const { input: { value, onChange } } = this.props
		const newValue = value;

		console.log('handleSave', {item,newValue,value})

		const targetIndex = value.findIndex(x =>
			(!!item.id && (x.id === item.id)) ||
			(!!item.temporaryId && (x.temporaryId === item.temporaryId))
		);

		if (targetIndex > -1) {
			newValue[targetIndex] = { ...newValue[targetIndex], ...item };
		} else {
			newValue.push({	...item, location: 'TODO REMOVE LCOATION' })
        }

		console.log('ostatnie newValue', _.cloneDeep(newValue))
        onChange(newValue);
		this.setState({
			listElements: this.getListElements()
		});
	}

	handleRemove = ({ id, temporaryId }) => {
		console.log('handleRemove')
		const { input: { value, onChange } } = this.props;
		const newValue = value

		const targetIndex = newValue.findIndex(x =>
			(!!id && (x.id === id)) ||
			(!!temporaryId && (x.temporaryId === temporaryId))
		);

		if (targetIndex > -1) {
			newValue.splice(targetIndex, 1);
			console.log('handleRemove newValue', newValue)
			onChange(newValue);
		}

        //this.setState({ listElements: this.getListElements() });
        //this.forceUpdate();
	}

	handleSortEnd = ({ oldIndex, newIndex }) => {
		const { input: { value, onChange } } = this.props;

		onChange(arrayMove(value, oldIndex, newIndex));
		this.setState({
			isDragging: false,
			listElements: this.getListElements()
		});
    }

	generateAddMoreButton = () => {
        const { media_type } = this.props;
		const { listElements } = this.state;
		return {
			wrapperProps: { disabled: true  },
			children: <div
				style={{
					display: 'flex',
					justifyContent: 'center',
					flexDirection: 'column',
					padding: '24px 12px'
				}}>
				<div style={{ display: 'flex', alignItems: 'center' }}>
					<b>Add more {media_type}</b>
					<IconButton
						style={{ padding: 0}}
						onClick={() => this.setState({
							listElements: [
								...listElements,
								this.generateListElement({
									wrapperProps: {
										disabled: true,
									},
									innerProps: {
										isEditing: true,
										item: {
                      temporaryId: '_' + Math.random().toString(12).substr(2, 9),
                      type: media_type,
										}
									},
									index: listElements.length,
								})
							]
						})}
						>
						<SVGInline
							svg={file_plus_icon}
							fill={PRIMARY_COLOR}
							width="1.5rem"
							height="1.5rem"
						/>
					</IconButton>
				</div>
				{/* <p style={{ fontSize: '.75rem'}}>
					Take advantage of being on <strong>GWPremiumPlan</strong>&reg;
				</p> */}
			</div>
		}
	}

	render() {
		const { listElements, isDragging } = this.state;

		const items = this.props.input.value
		const itemsToRender = [...listElements];

		if (items.length === listElements.length && !isDragging) {
			itemsToRender.push(this.generateAddMoreButton())
		}

		return (
			<div>
				<MySortableList
					onSortStart={() => this.setState({ isDragging: true })}
					onSortEnd={this.handleSortEnd}
					axis='xy'
					items={itemsToRender}
				/>
			</div>
		)
	}
}

export default MediaFieldWrapper
