import React from 'react'
import PropTypes from 'prop-types'

import PlansListCell from './PlansListCell'

const PlansRow = ({ row }) => {
	function needHigerRow () {
		if (row.label.length > 80) return true
		if (row.value[0].length > 80) return true
		if (row.value[1].length > 80) return true
		if (row.value[2].length > 80) return true
		return false
	}	

	return (
		<div className={`plans_row ${ needHigerRow() ? 'higher' : null}`}>
			<div className="label">
				<PlansListCell value={row.label} />
			</div>
			<div className="free">
				<PlansListCell value={row.value[0]} />
			</div>
			<div className="pro">
				<PlansListCell value={row.value[1]} />
			</div>
			<div className="premium">
				<PlansListCell value={row.value[2]} />
			</div>
		</div>
	)
}

PlansRow.propTypes = {
	row: PropTypes.object.isRequired,
}

export default PlansRow
