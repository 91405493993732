import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import {connect} from 'react-redux';
import default_picture from '../../assets/images/default_picture.png';

import avatarLinesWhite from '../../assets/profile-bg.png' // images/circles-abstract-white.png' //profile-bg.png'

@connect(({user}) => ({
  currentUser: user.currentUser,
}))

export default class ProfilePhoto extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const {profile, currentUser, match} = this.props;
    
    return (
      <header className={'profile__header' + (this.props.isMobile ? ' mobile' : '') }>
        {/*TODO: REVERT*/}
        {/*<img*/}
        {/*className="profile__ribbon"*/}
        {/*src={require('../../assets/ribbon-founding-member.png')}*/}
        {/*alt="Founding Member"/>*/}
        <div className="profile__pic-wrapper">
          <div className={"profile__pic-circle-wrapper" + (this.props.isMobile ? ' mobile' : '') }>
            <div className={
              `profile__pic-circle ${currentUser
              && currentUser.id
              && currentUser.id === Number(match)
              && 'profile__pic-hover'}`
            }>
              {
                currentUser
                && currentUser.id
                && currentUser.id === Number(match)
                && (
                  <Link className="profile__edit" to={
                    {
                      pathname: '/profile/edit',
                      state: [
                        'edit-profile',
                        {},
                        {
                          form: 1,
                          type: 'media'
                        }
                      ]
                    }
                  }>
                    <i className="fa fa-pencil-square-o" aria-hidden="true"/>
                    <br />
                    <span className={'show-md'}>Edit <br /> Profile Picture</span>
                  </Link>
                )
              }

            </div>
            <figure className="profile__pic">
              <img className="profile__pic-decorator" src={avatarLinesWhite} />

              <img
                className="profile-avatar"
                src={`${(profile && profile.avatar ? profile.avatar.url : null) || default_picture}`}/>
            </figure>
          </div>
        </div>
      </header>
    );
  }
}
