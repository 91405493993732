import React, { Component } from 'react'
import moment from 'moment'
import { DateRangePicker } from '../../common/DateRangePicker/DateRangePicker'
import { withRouter } from 'react-router-dom'

import { connect } from 'react-redux'
import { compose } from 'recompose'

import { getNextGigs, getActiveGigs, getAppliedGigs, getConfirmedGigs } from '../../../redux/modules/dashboard'

import NextGigs from './NextGigs'

import './ProfileEvents.scss'
import { defaultTo } from 'lodash'
import { Promise } from 'q';

class ProfileEvents extends Component {
	constructor(props) {
		super(props)

		this.state = {
			startDay: null,
			endDay: null,
			dateIsRange: false,
			year: null,
			startMonth: null,
			endMonth: null,
			isOpenedFullEvents: false,
			events: [],
			id: props.id,
		}
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		if(nextProps.id && (nextProps.id !== this.state.id)) {
			this.props.getNextGigs(nextProps.id)
				.then(res => {
					this.setState({ id: nextProps.id})
				})
				.catch(err => this.console.log(err))
		}
	}

	handleSelect = date => {
		this.setState(() => ({
			startDay: date.startDate._d.getDate(),
			endDay: date.endDate._d.getDate(),
			dateIsRange: date.startDate._d.getDate() !== date.endDate._d.getDate(),
			year: moment(date.startDate._d).get('year'),
			startMonth: date.startDate.month(),
			endMonth: date.endDate.month(),
		}))
	}

	render() {
		const { startDay, endDay, dateIsRange, year, startMonth, endMonth, isOpenedFullEvents } = this.state

		const { nextGigs } = this.props

		return (
			<section className="events">
				{nextGigs.length !== 0 && (
					<div>
						<h5 className="events__title">Next Gigs</h5>
						<NextGigs nextGigs={nextGigs} isOpenedFullEvents={isOpenedFullEvents} />

						{nextGigs.length > 4 && (
							<div className="d-flex justify-content-end events__end-block">
								<span
									onClick={() => this.setState({ isOpenedFullEvents: !isOpenedFullEvents })}
									className="events__end-block_view-button"
								>
									View {isOpenedFullEvents ? 'less' : 'more'}
								</span>
							</div>
						)}
					</div>
				)}
				<div className="events__calendar">
					<DateRangePicker						
						startDay={startDay}
						endDay={endDay}
						dateIsRange={dateIsRange}
						year={year}
						startMonth={startMonth}
						endMonth={endMonth}
						readonly
					/>
				</div>
			</section>
		)
	}
}

const mapStateToProps = ({ dashboard, auth }) => {
	return {
		appliedGigs: dashboard.appliedGigs,
		isAuthorized: auth.isAuthorized,
		nextGigs: dashboard.nextGigs,
		activeGigs: dashboard.activeGigs,
		nextGigs: dashboard.nextGigs
	}
}

export default compose(
	withRouter,
	connect(
		mapStateToProps,
		{ getAppliedGigs, getNextGigs, getActiveGigs, getConfirmedGigs }
	)
)(ProfileEvents)
