import './ManagePrivacyPreferencesWindow.scss';
import React, { useState } from 'react';
import RaisedButton from 'material-ui/RaisedButton';
import SVGInline from 'react-svg-inline';
import Checkbox from "material-ui/Checkbox";
import {Asterisk} from "../../common/Asterisk/Asterisk";
import { Cookies } from 'react-cookie-consent'
import ReactGA from "react-ga";

const closeSVG = require('!!svg-inline-loader?classPrefix!../../../assets/icons/close.svg');

const ManagePrivacyPreferencesWindow = ({ close }) => {
  const gigworksCookieConsent = Cookies.get('gigworks-cookie-accept') == 'true';
  const [isConsentAccepted, setCookieConsent] = useState(gigworksCookieConsent);

  const handleOnSave = () => {
    if (isConsentAccepted) {
      window['ga-disable-UA-152569493-1'] = false;
      ReactGA.initialize('UA-152569493-1');
      ReactGA.pageview('/homepage');
    } else {
      Cookies.remove('_gat');
      Cookies.remove('_gid');
      Cookies.remove('_ga');
      Cookies.set('gigworks-cookie-accept', false);
      window['ga-disable-UA-152569493-1'] = true;
    }
  }

  return (
    <div className="manage-pricacy-preferences-window">
      <div className="container d-flex">
        <Checkbox className="manage-pricacy-preferences-window__fields" label={<>Necessary <Asterisk /></>} checked={true} disabled />
        <Checkbox className="manage-pricacy-preferences-window__fields" label={'Analytics'} checked={isConsentAccepted} onCheck={() => setCookieConsent(!isConsentAccepted)} />
        <RaisedButton
          label="Save"
          onClick={handleOnSave}
          className="subscribe-button"
          labelStyle={{
            textTransform: 'normal',
            fontSize: '14px',
            color: '#a2a2a2',
            overflow: 'hidden',
          }}
          buttonStyle={{
            background: 'transparent'
          }}
          style={{
            borderRadius: '50px',
            backgroundColor: '#fff',
            color: '#000',
            boxShadow: 'none',
            border: '1px solid #a2a2a2',
            padding: '0 20px',

          }}
          overlayStyle={{ borderRadius: '1.5rem' }} />
        <div
          className="close"
          onClick={close}>
          <SVGInline
            height='25px'
            fill={'#000000'}
            svg={closeSVG} />
        </div>
      </div>
    </div>
)};

export default ManagePrivacyPreferencesWindow;
