import React from 'react'
import PropTypes from 'prop-types';

import SelectFieldItem from './SelectFieldItem'

import './SelectField.scss'

/**
 * todo: mylaca nazwa (SelectSection lepsza)
 */
class SelectField extends React.PureComponent {
    static propTypes = {
        /** List of <SelectFieldItem> to put inside SelectField. Use either items or options */
        items: PropTypes.any,
        /** List of { label, value }. Use either items or options */
        options: PropTypes.any,
        /** Index (!) in options/items array of selected item */
        value: PropTypes.any
    }

    render() {
        const { items, options, value, onChange, style } = this.props;

        return <div className="SelectField" style={style}>
            {
                items ?
                items :
                options.map((option, index) => <SelectFieldItem
                    selected={index === value}
                    value={option.value}
                    label={option.label}                    
                    onChange={val => onChange(index, val)}
                />)
            }
        </div>
    }
}

export default SelectField;