export const GET_WALLET_BALANCE_START = 'GET_WALLET_BALANCE_START'
export const GET_WALLET_BALANCE_SUCCESS = 'GET_WALLET_BALANCE_SUCCESS'
export const GET_WALLET_BALANCE_FAIL = 'GET_WALLET_BALANCE_FAIL'

export const GET_NOTIFICATIONS_START = 'GET_NOTIFICATIONS_START'
export const GET_NOTIFICATIONS_SUCCESS = 'GET_NOTIFICATIONS_SUCCESS'
export const GET_NOTIFICATIONS_FAIL = 'GET_NOTIFICATIONS_FAIL'

export const SET_PUSHER_STATUS = 'SET_PUSHER_STATUS'

export const MARK_AS_READ_START = 'MARK_AS_READ_START'
export const MARK_AS_READ_SUCCESS = 'MARK_AS_READ_SUCCESS'
export const MARK_AS_READ_FAIL = 'MARK_AS_READ_FAIL'

export const ADD_DASHBOARD_NOTIFICATION = 'ADD_DASHBOARD_NOTIFICATION'

const initialState = {
    isGettingNotifications: false,
	notifications: [],
	pusherStatus: null,
	nextTryTimestamp: null,
}

export default function notifications(state = initialState, action = {}) {
	switch (action.type) {
		case SET_PUSHER_STATUS:
			return {
				...state,
				pusherStatus: action.status,
			}
		// case SET_PUSHER_DISCONNECTED: {
		// 	const nextTryTimestamp = new Date().getTime();
		// 	console.log('nextTryTimestamp', nextTryTimestamp)

		// 	return {
		// 		...state,
		// 		pusherStatus: 'DISCONNECTED',
		// 		...(action.shouldTryConnect ? { nextTryTimestamp: nextTryTimestamp + 3000 } : {})
		// 	}
		// }

		case GET_NOTIFICATIONS_START: {
			return state
		}

		case GET_NOTIFICATIONS_SUCCESS: {
			console.log('Dostana', action.result.data.data)
			return state
		}

		case GET_NOTIFICATIONS_FAIL: {
			return state
		}
	
        case ADD_DASHBOARD_NOTIFICATION:
            return {
                ...state,
                notifications: JSON.parse(JSON.stringify([...state.notifications, ...action.item])),
			}
			
		case MARK_AS_READ_START:
            return state;

        case MARK_AS_READ_SUCCESS:
			return {
				...state,
				notifications: JSON.parse(JSON.stringify(state.notifications.map(i => ({ ...i, read: true }))))
            }
        
        case MARK_AS_READ_FAIL:
            return state;

		default:
			return state
	}
}

export function getBalance() {
	return {
		types: [GET_WALLET_BALANCE_START, GET_WALLET_BALANCE_SUCCESS, GET_WALLET_BALANCE_FAIL],
		promise: client => client.get(`/api/billing/wallet`),
	}
}

export function getNotifications() {
	return {
		types: [GET_NOTIFICATIONS_START, GET_NOTIFICATIONS_SUCCESS, GET_NOTIFICATIONS_FAIL],
		promise: client => client.get(`/api/notification/get`)
	}
}


export function addNotification(item = []) {
    const itemArray = Array.isArray(item) ? [ ...item ] : [ item ]
    return {
        type: ADD_DASHBOARD_NOTIFICATION,
        item: itemArray
    }
}

export function setPusherStatus(status){
	return {
		type: SET_PUSHER_STATUS,
		status
	}
}

export function markAsRead() {
	return {
        types: [MARK_AS_READ_START, MARK_AS_READ_SUCCESS, MARK_AS_READ_FAIL],
        promise: client => client.put(`/api/notification/mark-read`)
    }
}