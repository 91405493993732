import React, { Component } from 'react'
import SVGInline from 'react-svg-inline'
import { TextField, IconButton } from 'material-ui'
import * as _ from 'lodash'
import { notification } from 'antd';
import CropImage from './CropImage';
import Modal from '../../common/Modal/Modal';
const file_upload_icon = require('!!svg-inline-loader?classPrefix!../../../assets/file_upload.svg')
const file_trash_icon = require('!!svg-inline-loader?classPrefix!../../../assets/icons/trash.svg')

import './MediaItemWrapper.scss'

const TEXT_FIELD_HEIGHT = 48
const PADDING = 12

class MediaItemWrapper extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isEditing: props.isEditing || false,
      input: null,
      src: null,
			showCropImage: false,
      value: {
          title: _.get(props, 'item.title', ''),
          hashtagsAsString: _.get(props, 'item.hashtags', []).map(x => x.content).join(','),
          hashtags: _.get(props, 'item.hashtags', []),
          content_url: _.get(props, 'item.content_url', ''),
          thumbnail_url: _.get(props, 'item.thumbnail_url', ''),
          input: null // todo refactor, input is used only for pictures
      },
      errors: {},
      isValidating: false,
      children: props.children
    }
  }

  defaultValidator({ title, hashtags, url }) {
      return Promise.resolve()
  }

    onSelectFile = e => {
        if (e.target.files && e.target.files.length > 0) {
            const reader = new FileReader();
            const input = e.target;

            console.log("onSELECT - IN")

            reader.addEventListener("load", () =>
                this.setState({
                    showCropImage: true,
                    src: reader.result,
                    input
                })
            );
            reader.readAsDataURL(e.target.files[0]);
        }
    };

	openCrop = () => {
        this.inputElement.click()

		// this.setState({
		// 	editingElement: _component
		// })
	}

	onDone = (url, input) => {
		// const { editingElement } = this.state
        const { innerContentHandler } = this.props

		this.setState(state => {
			const children = innerContentHandler && innerContentHandler({ innerProps: { item: { content_url: url } } })
			return {
				children: children,
				value: {
					...state.value,
					preview: url,
					content_url: url,
					input: input,
				}
			}
		}, () => {
            this.handleSaveTry()
            this.onClose()
        })
	}

	onClose = () => {
		this.setState({
			showCropImage: false,
			// editingElement: null,
		})
	}

    handleRemove = () => {
        const { item, onRemove } = this.props;

        this.setState({
            value: {
                title: '',
                hashtagsAsString: '',
                hashtags: [],
                content_url: '',
                thumbnail_url: '',
                preview: null
            }
        });
        onRemove({ id: item.id, temporaryId: item.temporaryId })
    };

    handleSaveTry = () => {
        const {
            validator = this.defaultValidator,
            item,
            item: { id, temporaryId },
            onChange,
        } = this.props;

        const {
            title,
            content_url: url,
            hashtags,
            preview,
            input,
        } = this.state.value;

        console.log("Handle save try", this.state.value)

        this.setState({ isValidating: true });
        validator({ title, hashtags, url, item, input }).then(
            fieldsFromValidator => {
                this.setState({ isEditing: false, isValidating: false })

                onChange({
                    id,
                    temporaryId,
                    title,
                    hashtags,
                    preview,
                    input: (this.props.media_type === 'picture') ? input : url,
                    ...fieldsFromValidator
                })
        }, (_errors) => {
            const _renderErrors = [];
            _.forOwn(_errors, value => _renderErrors.push(<li>{value}</li>));

            notification.error({
                message: 'Errors!',
			    description: <div>
                    Please, correct the following errors
                    <ul style={{ paddingLeft: '15px' }}>
                        { _renderErrors }
                    </ul>
                </div>,
            });
            this.setState({ errors: {..._errors}, isValidating: false })
        })
    };

    UNSAFE_componentWillReceiveProps(nextProps) {
        const next_thmbnail_url = _.get(nextProps, 'item.thumbnail_url', this.state.thumbnail_url)
        const next_content_url = _.get(nextProps, 'item.content_url', this.state.content_url)

        if(next_thmbnail_url !== this.state.thumbnail_url || next_content_url !== this.state.content_url) {
            this.setState(prevState => ({
                value: {
                    ...prevState.value,
                    thumbnail_url: next_thmbnail_url,
                    content_url: next_content_url,
                },
                children: nextProps.children
            }))
        }
    }

    render() {
        const { isEditing, value, errors, isValidating, children } = this.state;
        const { item, index, media_type } = this.props;

        return (
            <div
                className="MediaItemWrapper"
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    position: 'relative', //for spinner
                    width: '100%',
                    boxShadow: '2px 4px 15px -4px rgba(0,0,0,0.08)',
                    backgroundColor: '#f3f3f3',
                    userSelect: 'none',
                    // marginBottom: '12px',
                }}
            >
                <div className="d-none">
                    <input
                        ref={input => this.inputElement = input}
                        className="file-input"
                        type="file"
                        accept="image/*"
                        id="input"
                        onChange={this.onSelectFile}
                    />
                </div>
				<Modal
					isOpen={this.state.showCropImage}
					onClose={this.onClose}
					noAccidentallyClose={true}
				>
					<CropImage onDone={this.onDone} onCancel={this.onClose} src={this.state.src} input={this.state.input}/>
				</Modal>

                { isValidating && <div className="spinner" />}

                {
                    isEditing ? <div
                        style={{
                            paddingLeft: `${PADDING}px`,
                            paddingRight: `${PADDING}px`,
                            position: 'relative'
                        }}>
                        <TextField
                            value={value.title}
                            onChange={(_, title) => {
                                this.setState({
                                    value: { ...value, title }
                                })
                            }}
                            hintText={'Description'}
                            fullWidth
                            errorText={errors.title}
                        />
                        <TextField
                            value={value.hashtagsAsString}
                            onChange={(_, hashtagsAsString) => {
                                this.setState({
                                    value: {
                                        ...value,
                                        hashtagsAsString,
                                        hashtags:
                                            hashtagsAsString ?
                                            hashtagsAsString.split(',').map(x => ({ content: x.trim()}) ) : []
                                    }
                                })
                            }}
                            hintText={'Hashtags'}
                            fullWidth
                            errorText={errors.hashtags}
                        />
                        {!errors.hashtags && <span style={{
                            color: '#ccc',
                            fontSize: '12px',
                            position: 'relative',
                            top: '-.5rem'
                        }}>max 5 hashtags</span>}

                        <IconButton
                            style={{ position: 'absolute', right: 0, top: 0}}
                            onClick={this.handleRemove}
                            >
                            <SVGInline
                                svg={file_trash_icon}
                                fill="#000"
                                width="1rem"
                                height="1rem"
                            />
                        </IconButton>
                    </div> : <div style={{ padding: `${PADDING}px` }}>
                        { item.title && <h5 style={{ overflowWrap: 'break-word' }}>{item.title}</h5>}
                        { item.hashtags && item.hashtags.length ?
                            <span style={{ overflowWrap: 'break-word' }}>
                                {item.hashtags.map(x => x.content).join(', ')}
                            </span>
                            :
                            <br/>
                        }
                    </div>
                }

                <div>
                    {children}

                    {
                        !isEditing && <div style={{
                            borderRadius: '50%',
                            backgroundColor: '#141726',
                            color: '#ffffff',
                            width: '1.5rem',
                            height: '1.5rem',
                            textAlign: 'center',
                            lineHeight: '1.5rem',
                            position: 'absolute',
                            right: `12px`,
                            bottom: `12px`,
                        }}>{index + 1}</div>
                    }
                </div>


                <div style={{
                    padding: `${PADDING}px`,
                    height: '100%',
                    minHeight: '3rem',
                    display: 'flex',
                    alignItems: 'end',
                }}>
                    <IconButton
                        style={{ padding: 0, width: 'auto', height: 'auto', marginRight:`${PADDING}px`, position: 'absolute', bottom: '12px', left: '12px' }}
                        onClick={isEditing ? this.handleSaveTry : () => this.setState({ isEditing: true })}
                        >
                        <SVGInline
                            svg={file_upload_icon}
                            fill="#000"
                            width="1rem"
                            height="1rem"
                        />
                    </IconButton>

                    { isEditing ? (
                        this.props.media_type === 'picture' ?
                            <button onClick={() => this.openCrop()}
                                style={{
                                    cursor: 'pointer',
                                    color: 'black',
                                    textDecoration: 'none !important',
                                    overflow: 'hidden',
                                    boxSizing: 'border-box',
                                    padding: '0px 10px',
                                    fontSize: '1rem',
                                    fontWeight: '400',
                                    border: 'none',
                                    borderRadius: '20px',
                                    borderColor: '#ffd210',
                                    'background-color': '#ffd210',
                                    position: 'absolute',
                                    bottom: '12px',
                                    left: '40px',
                                }}
                            >
                                <span>Select picture</span>
                            </button>
                            :
                            <TextField
                                value={value.content_url}
                                onChange={(_, content_url) => {
                                    this.setState({
                                        value: { ...value, content_url }
                                    })
                                }}
                                style={{
                                    position: 'absolute',
                                    bottom: '0',
                                    left: '40px',
                                    right: '12px',
                                    width: 'unset'
                                }}
                                // onBlur={() => {
                                //     const url = this.state.value.content_url
                                //     console.log("----> onBlur", this.state.value)

                                //     if(this.props.innerContentHandler && url) {
                                //         console.log("---> innerContentHandler")
                                //         this.setState({
                                //             children: this.props.innerContentHandler({ innerProps: { item: { content_url: url } } })
                                //         })
                                //     }
                                // }}
                                hintText={`Url of ${media_type}`}
                                fullWidth
                                errorText={errors.content_url}
                            />
                        ) :
                            <button
                                role="button"
                                style={{ color: '#fff', backgroundColor: '#ffd210', borderRadius: '20px', border: 'none', padding: '0px 10px', position: 'absolute', bottom: '12px', left: '40px' }}
                                onClick={() => this.setState({ isEditing: true })}>
                                Change {media_type}
                            </button>
                    }
                </div>
            </div>
        )
    }
}
export default MediaItemWrapper
