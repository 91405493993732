import React, {Component} from 'react'

import classNames from 'classnames'
import './PlansHeader.scss'

export default class PlansHeader extends Component {
	render() {
		const { prices, onClick, active, noButtons } = this.props;

		return (
			<div className="plans_header">
				<div className="title" />
				<div className="label" />
				{prices.map((price, index) => {
          return (
            <div className="plan" key={`plan-${index}`}>
              <div className="name">{price.name}</div>

              {price.amount && (
                <div
                  className={classNames('amount', {underlined: price.amount})}>€{parseFloat(price.amount).toFixed(2)}/month</div>
              )}
              {price.description && <div className="description">{price.description}</div>}
              {!noButtons &&
              <div
                className={classNames('select_button', {
                  active: price.id === active,
                })}
                onClick={() => {
                  if (price.id !== active) {
                    onClick(price.id)
                  }
                }}
              >
                Select
              </div>
              }
            </div>
          )
				})}
			</div>
		)
	}
}
