import React, { Component } from 'react'
import { connect } from 'react-redux'
import { ShowPassword } from '../../components'
import { Field, reduxForm } from 'redux-form'
import validate from '../../helpers/validators/validate'
import RenderField from './../RenderField'
import { getRealTypeOfUser } from '../../helpers/methods'
import { getCategories } from '../../redux/modules/common'
import { resendEmail, getCurrentUser, changeEmail } from '../../redux/modules/user'
import { changePlan } from '../../redux/modules/dashboard'

import queryString from 'query-string'
import '../../styles/components/RegisterForm.scss'
import '../../styles/components/WizardAccountInfo.scss'
import PasswordChange from './PasswordChange/PasswordChange'

import { AlertPopUp } from '../../components/common/AlertPopUp/AlertPopUp'
import Button from '../common/Button/Button'
import { artistRegistrationProfileInfoSteps, venueRegistrationProfileInfoSteps, clientRegistrationProfileInfoSteps } from "../../utils/tour-guide-steps";
import Joyride from "react-joyride";
import Loader from '../discover/Loader/Loader'
import Modal from '../common/Modal/Modal'
import notification from 'antd/es/notification'

@connect(
	({ user }) => ({
		currentUser: user.currentUser,
		isResendingActivationEmail: user.isResendingActivationEmail,
		initialValues: user.currentUser || {},
	}),
	{
		getCategories,
		resendEmail,
    changePlan,
    getCurrentUser,
    changeEmail
	}
)
@reduxForm({
	form: 'AccountInfo',
	destroyOnUnmount: false,
	forceUnregisterOnUnmount: false,
	enableReinitialize: true,
	validate,
})
export default class WizardAccountInfo extends Component {
	constructor(props) {
		super(props)

    const profileSteps = this.getProfileSteps();

		this.state = {
			showPassword: false,
      showNewEmailModal: false,
      showChangeEmailLoading: false,
      newEmail: '',
			showAlert: false,
      run: localStorage.getItem('registration-profile-tour') ? localStorage.getItem('registration-profile-tour') == 'true' : true,
      steps: [...[{
        target: '#root-wrapper > div > div.RegisterWizard > div.profile-edit',
        content: (
          <>
            <h3>Hello. Welcome to Gigworks, would you like a quick tour of the registration area to get you started?</h3>
            <button
              style={{
                backgroundColor: "#e61e26",
                border: '0px',
                borderRadius: '4px',
                cursor: 'pointer',
                fontSize: '16px',
                lineHeight: 1,
                padding: '8px',
                appearance: 'none',
                color: "#fff"
              }}
              onClick={this.handleDisablingTourGuide}
            >
              Don't show me again this message
            </button>
          </>
        ),
        placement: "center",
        locale: { skip: "Skip" },
      },], ...profileSteps
      ]
		}
    this.changeEmailAddress = this.changeEmailAddress.bind(this)
		this.handleResendActivationLinkClick = this.handleResendActivationLinkClick.bind(this);
	}

	getProfileSteps = () => {
    const { currentUser } = this.props;
    const profile = currentUser && currentUser.profile_type && getRealTypeOfUser(currentUser);

    switch (profile) {
      case 'artist':
      case 'group':
        return artistRegistrationProfileInfoSteps;
      case 'venue':
        return venueRegistrationProfileInfoSteps;
      case 'company':
      case 'client':
        return clientRegistrationProfileInfoSteps;
      default:
          return [];
    }
  }

  handleDisablingTourGuide = e => {
    e.preventDefault();

    localStorage.setItem('registration-profile-tour', 'false');

    this.setState({
      run: false
    });
  };

	componentDidMount = () => {
        const { changePlan, getCategories } = this.props;

        Promise.all([getCurrentUser(), getCategories()])

		const queryStringParams = queryString.parse(location.search)

		if (queryStringParams.planId) {
			changePlan(planId);
		}
    }

	setPasswordVisibility = showPassword => this.setState({ showPassword })

	handleResendActivationLinkClick () {
		const { currentUser, resendEmail } = this.props;

		resendEmail(currentUser.id)
		.then(res => {
			this.setState({
				showAlert: true,
				alertTitle: 'Success',
				alertMessage: 'New activation link has been sent to your mailbox'
			})
		}, rej => {
			this.setState({
				showAlert: true,
				alertTitle: 'Error',
				alertMessage: 'Request failed, refresh the page or try again in a few minutes'
			})
		})

	}

  onUserEmailChanged = (event) => { this.setState( {newEmail: event.target.value} ) }
  onShowChangeEmailModal = () => { this.setState({ showNewEmailModal: true } ) }
  onCloseChangeEmailModal = () => { if( ! this.state.showChangeEmailLoading ) this.setState({ showNewEmailModal: false } ) }
  changeEmailAddress = () => {

    this.setState({ showChangeEmailLoading: true })
    this.props.changeEmail({'email': this.state.newEmail})
      .then(res => {
        if (res && res.data) {

          this.setState( {newEmail: '', showChangeEmailLoading: false})
          this.onCloseChangeEmailModal()

          notification.info({
            message: 'Success',
            description: res.data.message
          })
        }
      })
      .catch(err => {
        this.setState( {newEmail: '', showChangeEmailLoading: false})
        this.onCloseChangeEmailModal()
        notification.error({
          message: 'Error!',
          description: err.response.data.error
        })
      })
  }

	render() {
		const { handleSubmit, currentUser, isResendingActivationEmail } = this.props
    const { showAlert, alertMessage, alertTitle, run, steps } = this.state

    if (!currentUser) return null

		return (
			<div className="register-page">
        <Joyride
          showProgress
          showSkipButton
          continuous
          run={run}
          steps={steps}
          styles={{
            options: {
              textColor: '#000',
              width: 450,
              zIndex: 1000,
              spotlightShadow: '0 0 15px rgba(0, 0, 0, 0.5)',
            }
          }}
        />
				<form onSubmit={handleSubmit}>
					<div className="account-info-form__item-header">
						<h4 className="account-info-form__title">Account type</h4>
					</div>
					<div className="type-of-user">
						<p>{currentUser.profile_type && getRealTypeOfUser(currentUser)}</p>
					</div>
					<div className="account-info-form__item-header">
						<h4 className="account-info-form__title">Your email as username</h4>
					</div>
					{currentUser.email ? (
            <div className={"row"}>
              <div className={'col-md-6 d-flex align-items-center'}>
                <span>{currentUser.email}</span>
              </div>
							<div className={"btn-container col-md-6"}>
                <Button
                  label="Change email address"
                  style={{ marginLeft: '30px', position: 'relative',
                    top: '50%', transform: 'translateY(-50%)' }}
                  onClick={this.onShowChangeEmailModal}
                />
							</div>
              <Modal
                isOpen={this.state.showNewEmailModal}
                onClose={this.onCloseChangeEmailModal}
              >
                <div style={{ padding: '40px 0'}}>
                  <p>
                    <input type="text" className="input-field" placeholder="Enter your email"
                           hidden={this.state.showChangeEmailLoading}
                           value={this.state.newEmail} onChange={this.onUserEmailChanged} />
                  </p>
                  <div>
                    { this.state.showChangeEmailLoading ? (
                      <Loader/>
                    ) : (
                      <button className="btn btn-danger" style={{ margin: '0 auto', display: 'block' }}
                              onClick={this.changeEmailAddress}>Send verification email</button>
                    )}
                  </div>
                </div>
              </Modal>
            </div>
					) : (
						<div style={{ display: 'flex' }}>
							<span style={{ color: 'lightgray' }}>You need to confirm your email</span>
							<Button
								rounded small
								label="Send activation link again"
								style={{ marginLeft: 'auto' }}
								disabled={isResendingActivationEmail && 'disabled' }
								className="px-3"
								onClick={this.handleResendActivationLinkClick}
							/>
						</div>
					)}
					<PasswordChange style={{ marginTop: '30px' }} />
					{!currentUser ||
						(!currentUser.id && (
							<div>
								<div className="password-field">
									<Field
										name="password"
										type={this.state.showPassword ? 'text' : 'password'}
										component={RenderField}
										classNames="input-field"
										placeholder="Password"
									/>
									<ShowPassword isShown={this.state.showPassword} toggleView={this.setPasswordVisibility} />
								</div>
								<div className="password-field">
									<Field
										name="confirmPassword"
										type={this.state.showPassword ? 'text' : 'password'}
										component={RenderField}
										classNames="input-field"
										placeholder="Confirm Password"
									/>
									<ShowPassword isShown={this.state.showPassword} toggleView={this.setPasswordVisibility} />
								</div>
							</div>
						))}
				</form>

				<div className="errors-popup">
					<AlertPopUp
						isOpen={showAlert}
						title={alertTitle}
						text={alertMessage}
						okBtn={{
							label: `OK`,
							onClick: () => { this.setState({ showAlert: false }) },
						}}
						onClose={() => {
							this.setState({ showAlert: false })
						}}
					/>
				</div>
			</div>
		)
	}
}
