/**
 * This page is base for artist tab in discover
 */

import React, { Component } from 'react'
import { connect } from 'react-redux'
import { getArtistsByCategories, getProductionByCategories } from '../../../redux/modules/discover'
import GeneralCategories from './GeneralCategories/GeneralCategories'
import SpecificCategories from './SpecificCategories/SpecificCategories'
import { withRouter } from 'react-router'

import './ArtistsTab.scss'

@connect(
	({ discover }) => ({
		artistsByCategories: discover.artistsByCategories,
		productionByCategories: discover.productionByCategories,
	}),
	{
		getArtistsByCategories,
		getProductionByCategories,
	}
)
class ArtistsTab extends Component {
	constructor(props) {
		super(props)
		this.state = {
			artistGeneralCategoryId: this.props.match.params.superCatId,
		}
	}

	loadItemsByCategoryIfNeeded = rootCategoryName => {
		if (rootCategoryName !== 'artists') return

		if (!this.props[`artistsByCategories`].length) {
			this.props.getArtistsByCategories()
		}
		if (!this.props[`productionByCategories`].length) {
			this.props.getProductionByCategories()
		}
	}

	UNSAFE_componentWillMount() {
		this.loadItemsByCategoryIfNeeded(this.props.rootCategoryName)
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		if (nextProps.rootCategoryName !== this.props.rootCategoryName) {
			this.loadItemsByCategoryIfNeeded(nextProps.rootCategoryName)
		}
	}

	render() {
		const { sortBar, categoryName, history } = this.props
		const { artistGeneralCategoryId } = this.state

		return (
			<div className="ArtistsTab">
				{sortBar}
				{artistGeneralCategoryId ? (
					<SpecificCategories categoryId={artistGeneralCategoryId} categoryName={categoryName} rootCategoryName={this.props.rootCategoryName} history={history} />
				) : (
					<GeneralCategories {...this.props} />
				)}
			</div>
		)
	}
}

export default withRouter(ArtistsTab)
