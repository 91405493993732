import React, { Component } from 'react';
import { connect } from 'react-redux'
import PropTypes from 'prop-types';
import './SortBar.scss';
import SVGInline from 'react-svg-inline';
import { IconButton } from 'material-ui';
import { Link } from 'react-router-dom'
import { SortBy } from './SortBy/SortBy';
import { SORT_BY_OPTIONS } from '../../../utils/constants';
import { translate } from '../../../translator';
import { getAppliedGigs, getSavedGigs } from '../../../redux/modules/dashboard'

const svg_heart = require('!!svg-inline-loader?classPrefix!../../../assets/icon-1.svg');
const svg_compare = require('!!svg-inline-loader?classPrefix!../../../assets/icon-2.svg');
const svg_message = require('!!svg-inline-loader?classPrefix!../../../assets/icon-3.svg');
const svg_share = require('!!svg-inline-loader?classPrefix!../../../assets/icon-4.svg');

@connect(
	({ user }) => ({
		currentUser: user.currentUser,
	}),
	{
    getAppliedGigs,
    getSavedGigs,
	}
)

export class SortBar extends Component {
  constructor(props) {
    super(props);
    const { sortValue } = this.props;
    this.state = {
      savedGigs: 0,
      appliedGigs: 0,
      sortValue
    };
  }
  _sortValues = SORT_BY_OPTIONS;

  componentDidMount() {
    this.getSaveApply()
  }

  componentDidUpdate(prevProps, prevState) {
    if(this.props.refreshTrigger !== prevProps.refreshTrigger)
    this.getSaveApply();
  }

  getSaveApply() {
    const { getAppliedGigs, getSavedGigs, currentUser, legend } = this.props;

    if (currentUser && !legend) {
      // todo it fetches all gigs every time xD
      Promise.all([getAppliedGigs(), getSavedGigs()])
      .then(res => {
        this.setState({
          appliedGigs: res[0].data.length,
          savedGigs: res[1].data.length
        })
      })
    }
  }

  render() {
    const { savedGigs, appliedGigs, sortValue } = this.state;
    const { legend, currentUser } = this.props;

    return (
      <div className="SortBar d-flex justify-content-center align-items-center">
        <div className="container d-flex justify-content-between align-items-center">
          {
            legend && <div className='legend'>
                <div className="d-flex justify-content-between align-items-center">
                  <div className="item" style={{ "margin-left": "-14px" }}>
                    <IconButton>
                      <SVGInline
                        svg={svg_heart}
                        fill="#000"
                        width='16px'
                      />
                    </IconButton>
                    <span className='title'>{translate('sortbar.button1')}</span>
                  </div>
                  <div className="item">
                    <IconButton>
                      <SVGInline
                        svg={svg_compare}
                        fill="#000"
                        width='16px'
                      />
                    </IconButton>
                    <span className='title'>{translate('sortbar.button2')}</span>
                  </div>
                  <div className="item">
                    <IconButton>
                      <SVGInline
                        svg={svg_message}
                        fill="#000"
                        width='16px'
                      />
                    </IconButton>
                    <span className='title'>{translate('sortbar.button3')}</span>
                  </div>
                  <div className="item">
                    <IconButton>
                      <SVGInline
                        svg={svg_share}
                        fill="#000"
                        width='16px'
                      />
                    </IconButton>
                    <span className='title'>{translate('sortbar.button4')}</span>
                  </div>
                </div>
              </div>
          }

          {
            currentUser &&
            (currentUser.profile_type !== 'client' && currentUser.profile_type !== 'fan') ? (<div
                className="information -discover-content__top-information d-flex justify-content-between align-items-center"
                >
                <span className="item -discover-content__top-information-item">
                  <Link to={`/dashboard/mygigs/saved/`}>
                    {savedGigs} {translate('sortbar.button5')}
                  </Link>
                </span>
                <span className="item -discover-content__top-information-item" style={{ paddingLeft: '.5rem'}}>
                  <Link to={`/dashboard/mygigs/applied/`}>
                    {appliedGigs} {translate('sortbar.button6')}
                  </Link>
                </span>
              </div>) : <div />
          }
          <SortBy items={this._sortValues} value={sortValue} onChange={this.onChangeSortType}/>
        </div>
      </div>
    );
  }

  onChangeSortType = (v) => {
    const { onChangeSort } = this.props;
    const sortValue = this._sortValues.indexOf(v);
    this.setState({ sortValue });
    if (onChangeSort) {
      onChangeSort(sortValue);
    }
  };
}

SortBar.propTypes = {
  legend: PropTypes.bool,
  onChangeSort: PropTypes.func
};

