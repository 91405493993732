const validate = values => {
	const errors = {}

	const cats = values.categories

  if (cats && cats.length) {

    if (!cats[0].name) {
      errors.categories = 'Required'
    }
  }

	return errors
};

export default validate
