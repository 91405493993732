import React from 'react'
import PropTypes from 'prop-types'
import './OperationHours.scss'

import { MediumHeading } from '../../CommonComponent'

import moment from 'moment'

function getTimeFormat(timeStr) {
  if (timeStr.length === 4 && ''+timeStr[0] === '0') return 'HHmm' // 0600
  if (timeStr.length === 4) return 'HHmm' // 1200
  if (timeStr.length === 3) return 'Hmm' //500
  return 'HH:mm' // 11:00
}

function formatDay(dayName, dayObject) {
  const timeFrom = moment(dayObject.from, getTimeFormat(dayObject.from))
  const timeTo = moment(dayObject.to, getTimeFormat(dayObject.to))

  return (
    <div className="OperationHours__item">
      <div className="day">{dayName}</div>
      <div className="value">{timeFrom.format('HH:mm')}</div>
      <div className="value">{timeTo.format('HH:mm')}</div>
    </div>
  )
}

const orderedDays = [
    'monday',
    'tuesday',
    'wednesday',
    'thursday',
    'friday',
    'saturday',
    'sunday'
]

const OperationHours = ({ value }) => (
  <div className="OperationHours">
    <MediumHeading style={{ textAlign: 'center' }}>Hours of operation</MediumHeading>
    <div className="OperationHours__head">
      <div>From</div>
      <div>To</div>
    </div>
    {
      orderedDays.map(key => {
        const day = Object.keys(value).find(property => property === key)
        if(day) {
          return formatDay(day, value[day])
        }        
      })
    }
  </div>
)

OperationHours.propTypes = {
  value: PropTypes.object,
}

export default OperationHours
