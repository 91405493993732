import React from 'react'
import PropTypes from 'prop-types'

import classNames from 'classnames'

import PlansRow from './PlansRow'

import './PlansList.scss'

const PlansList = ({ plans }) => {
	return (
		<div className="plans_list">
			{plans.map((group, index) => {
				return (
					<div className="group" key={`group-${index}`}>
						<div className={classNames('title', `title-${group.group}`)}>
							<span>{group.label}</span>
						</div>
						<div className="values">
							{group.value.map((val, index) => {
								return <PlansRow row={val} key={`plan-row-${index}`} />
							})}
						</div>
					</div>
				)
			})}
		</div>
	)
}

PlansList.propTypes = {
	plans: PropTypes.array.isRequired,
}

export default PlansList
