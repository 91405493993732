import React, { Component } from 'react'
import classNames from 'classnames'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { find, capitalize, get, debounce, memoize, isArray } from 'lodash'
import SearchBlock from '../SearchBlock/SearchBlock'
import SearchBlockMobile from '../SearchBlockMobile/SearchBlockMobile'
import InfiniteScroll from 'react-infinite-scroll-component'
import ProfileCard from '../../../common/ProfileCard/ProfileCard'
import Loader from '../../Loader/Loader'
import Footer from '../../../Footer/Footer'
import {
	getSpotlightInfo,
	getUsersByCategories,
	clearUsersByCategories,
	getAllUsersByMaincategory,
  getAdsBy,
  clearAds,
} from '../../../../redux/modules/discover'
import { getFaqList } from "../../../../redux/modules/common";
import { getStyles } from '../../../../redux/modules/gig'
import SVGInline from 'react-svg-inline'
import { Container } from 'reactstrap'
import FAQAnswers from '../../../FAQ/Answers'

import { urlList } from '../../../../config';

import './SpecificCategories.scss'

const buttonBack = require('!!svg-inline-loader?classPrefix!../../../../assets/icons/left-arrow.svg')

const pluck = fieldName => obj => obj[fieldName]

const faqSectionName = 'discover_artist';

@connect(
	({ discover, compare, common, globalConfig, gig }) => ({
		usersByCategories: discover.usersByCategories,
		isFetchingUsersByCategoriesWhenReplace: discover.isFetchingUsersByCategoriesWhenReplace,
		isFetchingArtists: discover.isFetchingArtists,
		spotlightInfo: discover.spotlightInfo,
		itemsToCompare: compare.itemsToCompare,
		categories: common.artistsCategories.all,
		categoriesMap: common.artistsCategoriesMap,
		isMobile: globalConfig.isMobile,
		styles: gig.styles,
    faqList: common.faqList,
    isFetchingAds: discover.isFetchingAds,
    adsByCategory: discover.adsByCategory,
  }),
	{
		getSpotlightInfo,
		getUsersByCategories,
		clearUsersByCategories,
		getAllUsersByMaincategory,
		getStyles,
    getFaqList,
    getAdsBy,
    clearAds,
	}
)
export default class SpecificCategories extends Component {
	constructor(props) {
		super(props)
		this.state = {
			selectedCategory: null,
			subcategoryIds: [],
			nextPage: 1,
			lastPage: null,
			itemsToCompare: [],
			compareIds: [],
			isComparePopUpOpen: false,
			isCompareModalOpen: false,
			isOpenedMobileModalSearch: false,
      isShowAllUsers: true,
      isShowAllSubcategories: false,
      itemsToShow: 10,
      activateFromLimit: 15, //must be greater than itemsToShow
    }
	}

	componentDidMount() {
		this.props.getSpotlightInfo()
		this.getNextUsers(this.props.categoryId)

    if (this.props.faqList.length === 0) {
      const categoryName = this.props.categoryName || this.props.categoryId;
      this.props.getFaqList(faqSectionName, categoryName);
    }

    if (this.props.adsByCategory.length === 0) {
      this.props.getAdsBy(this.props.rootCategoryName, this.props.categoryId)
    }
	}

  componentDidUpdate(prevProps) {
    if ( this.props.usersByCategories.length !== prevProps.usersByCategories.length) {
      const categoryName = this.props.categoryName || this.props.categoryId;
      this.props.getFaqList(faqSectionName, categoryName);
    }
  }

	componentWillUnmount() {
		this.props.clearUsersByCategories();
		this.props.clearAds();
	}

	// getSubcategoryIds = memoize(
	// 	(categoryId, isFinal) =>
	// 		isFinal
	// 			? [categoryId]
	// 			: this.props.categories.filter(category => category.parent_category_id === categoryId).map(({ id }) => id)
	// )

	getNextUsers = (ids, page, withReplace) => {
	  (this.state.isShowAllUsers
			? this.props.getAllUsersByMaincategory(this.props.categoryId, page, withReplace, 'artist')
			: this.props.getUsersByCategories(this.state.selectedCategory.id, [...ids], page, withReplace, 'artist')
		).then(({ data }) => {

			const lastPage = get(data, 'meta.last_page', null)

			this.setState(prevState => ({
				lastPage,
				nextPage: prevState.nextPage + 1,
			}))
		})
	}

	getNextDebounced = debounce(() => {
		this.getNextUsers([], 1, true)
	})

	onCategoryChange = categoryObj => {
		const { categoriesMap } = this.props

		const category = categoriesMap[categoryObj.id]
		const parent = category ? categoriesMap[category.parent_category_id] : undefined

		let styleName = category ? category.type : undefined

		if(parent && parent.name === 'Music') {
			styleName = 'music_genre'
		} else if (styleName === 'talent') {
			styleName = 'talent_style'
		}

    let categoryName = categoryObj.name.replace(/\s/g, '');
    const urlAttributes = this.props.history.location.pathname.split('/');

    if (this.props.categoryName || urlAttributes.length > 4) {
      urlAttributes.pop();
    }
    const url = urlAttributes.join('/');
    this.props.history.push(url + '/' + categoryName);

		this.props.getStyles(categoryObj.id, styleName)
			.then(res => {
				const styles = this.props.styles || []
				this.setState(
					{
						isShowAllUsers: false,
						selectedCategory: categoryObj,
						nextPage: 1,
						subcategoryIds: styles.map(style => ({
							style,
							isSelected: false,
						})),
					},
					this.getNextDebounced
				)
			})
	}

	onScrollNext = () =>{
		this.getNextUsers(this.state.subcategoryIds.filter(pluck('isSelected')).map(pluck('id')), this.state.nextPage)
	}

	switchSubcategory = id => {
		this.setState(
			prevState => ({
				nextPage: 1,
				subcategoryIds: prevState.subcategoryIds.map(
					categoryObj =>
						categoryObj.style.id === id ? { ...categoryObj, isSelected: !categoryObj.isSelected } : { ...categoryObj }
				),
			}),
			this.getNextDebouncedSubCategory
		)
	}

	//writing crutch for selecting subcategory from tags: start
	getNextDebouncedSubCategory = debounce(() => {
		this.getNextUsers(this.state.subcategoryIds.filter(pluck('isSelected')).map(obj => obj.style.id), 1, true)
    })

    reduceSubcategories(subcategoryIds) {
        const { itemsToShow, activateFromLimit, isShowAllSubcategories } = this.state

        if(!subcategoryIds) {
            return []
        }
        else if(isShowAllSubcategories || subcategoryIds.length < activateFromLimit) {
            return subcategoryIds
        }
        else {
            const selected = subcategoryIds.filter(({ style, isSelected }) => isSelected )
            const unselected = subcategoryIds.filter(({ style, isSelected }) => !isSelected )

            if(selected.length >= itemsToShow) {
                return selected
            }
            else {
                return [...selected, ...unselected].slice(0, itemsToShow).sort((a, b) => a.style.name.localeCompare(b.style.name))
            }
        }
    }

	render() {
		const { nextPage, lastPage, subcategoryIds, selectedCategory } = this.state

		const {
			categoriesMap,
			rootCategoryName,
			isFetchingUsersByCategoriesWhenReplace,
			isFetchingArtists,
			isMobile,
      faqList,
      isFetchingAds,
      adsByCategory,
		} = this.props

    let { usersByCategories, categoryId } = this.props;
    let categoryName = categoryId;

    const spotlightItems = rootCategoryName
			? get(this.props, `spotlightInfo.${rootCategoryName.slice(0, rootCategoryName.length - 1)}`, [])
			: []

    return (
			<div className="SpecificCategories">
				<div className="search">
					<div className="container d-flex justify-content-between align-items-center">
						{isMobile ? (
							<SearchBlockMobile
								categoriesMap={categoriesMap}
								selectedCategory={selectedCategory}
                                subcategoryIds={subcategoryIds}
                                switchSubcategory={this.switchSubcategory}
								rootCategoryName={rootCategoryName}
								parentCategoryId={categoryId}
                                onChangeMainCategory={this.onCategoryChange}
							/>
						) : (
							<SearchBlock
                parentCategoryId={categoryId}
                categoryName={categoryName}
                isFetchingAds={isFetchingAds}
                adsByCategory={adsByCategory}
                onChange={this.onCategoryChange}
              />
						)}
					</div>
				</div>
				{subcategoryIds.length > 1 &&
					!isMobile && (
						<div className="subcategories-block container">
							<div className="header">
								<h3>{this.props.categoriesMap[selectedCategory.id].name}</h3>
								<span>Please select the {categoryId === 'Music' ? 'Genres' : 'Styles'} you would like to see. </span>
							</div>
							{this.reduceSubcategories(subcategoryIds).map(({ style, isSelected }) => {
								return (
									<span
										className={'subcategory-item' + (isSelected ? ' selected' : '')}
										onClick={() => this.switchSubcategory(style.id)}
									>
										{style.name}
										&nbsp;
										{/* <span className="users-count">({category.users_count})</span> */}
									</span>
								)
							})}
							{subcategoryIds && subcategoryIds.length >= this.state.activateFromLimit &&
              <span
                className="subcategory-item"
                style={{ color: '#ffd210', fontWeight: '500', whiteSpace: 'nowrap' }}
                onClick={() => this.setState({ isShowAllSubcategories: !this.state.isShowAllSubcategories })}
              >
                {this.state.isShowAllSubcategories ? 'Show less...' : 'Show more...'}
              </span>
							}
						</div>
					)}
				<div className={classNames('top-categories')}>
					{!isMobile && (
						<div className="top-title container">
							<h3>{capitalize(rootCategoryName)}</h3>
							{rootCategoryName === 'artists' && (
								<Link to={`/discover/artists`} activeClassName="active">
									<SVGInline svg={buttonBack} />
									<span className="button-back">Back to artists</span>
								</Link>
							)}
						</div>
					)}
				</div>
				<div className="infinite-scroll-main">
					{isFetchingUsersByCategoriesWhenReplace || isFetchingArtists ? (
						<Loader />
					) : !usersByCategories.length || usersByCategories[0] === undefined ? (
            <div>
              <div className="nothing">Results Rendering</div>
              {!isMobile &&
                <FAQAnswers isMobile={isMobile} faqList={faqList} />}
            </div>
					) : (
						<InfiniteScroll
							next={this.onScrollNext}
							hasMore={nextPage <= lastPage}
							loader={<Loader />}
							endMessage={
                <>
                  {!isMobile && <FAQAnswers isMobile={isMobile} faqList={faqList} />}
                  <Footer
                    type="home"
                    media={{
                      type: 'video',
                      src: 'https://player.vimeo.com/video/230747958',
                      content_url: 'https://youtu.be/l1u1_ws6TBg',
                      clapQuantity: 42,
                      commentQuantity: 2,
                      shareQuantity: 12,
                      tags: ['#best', '#ever'],
                      location: 'Berlin',
                    }}
                  />
                </>
							}
						>
							<Container className="infinite-map">
								<div className="width-items">
									{usersByCategories.map((user, idx) => (
										<ProfileCard
											key={idx}
											profile={user}
										/>
									))}
								</div>
							</Container>
						</InfiniteScroll>
					)}
				</div>
			</div>
		)
	}
}

// <div className="infinite-scroll-main">
// 					{isFetchingUsersByCategoriesWhenReplace || isFetchingArtists ? (
// 						<Loader />
// 					) : usersByCategories.length &&
// 					subcategoryIds.length > 1 &&
// 					!subcategoryIds.filter(pluck('isSelected')).length ? (
// 						<div className="nothing">Nothing to show</div>
// 					) : (
// 						<InfiniteScroll
// 							next={this.onScrollNext}
// 							hasMore={nextPage <= lastPage}
// 							loader={<Loader />}
// 							endMessage={
// 								<Footer
// 									type="home"
// 									media={{
// 										type: 'video',
// 										src: 'https://player.vimeo.com/video/230747958',
// 										content_url: 'https://youtu.be/l1u1_ws6TBg',
// 										clapQuantity: 42,
// 										commentQuantity: 2,
// 										shareQuantity: 12,
// 										tags: ['#best', '#ever'],
// 										location: 'Berlin',
// 									}}
// 								/>
// 							}
// 						>
// 							<Container className="infinite-map">
// 								<div className="width-items">
// 									{usersByCategories.map((user, idx) => (
// 										<ProfileCard
// 											key={idx}
// 											profile={user}
// 											selected={user.selected}
// 											onCompare={user.selected ? removeItemFromCompare : addItemToCompare}
// 										/>
// 									))}
// 								</div>
// 							</Container>
// 						</InfiniteScroll>
// 					)}
// 				</div>
