import React from 'react';
import { Table as AntdTable } from 'antd';
import moment from 'moment';

import {DANGER_COLOR, SUCCESS_COLOR, WARNING_COLOR} from '../../../../utils/constants';
import 'antd/es/table/style/css'
import 'antd/es/pagination/style/css'

export class Table extends React.PureComponent {
    render() {
        const { data, columns, noHorizontalBorders } = this.props;

        const styles = {
            border: '1px solid #ccc',
            borderBottom: 0
        }

        if (noHorizontalBorders) {
            styles.borderLeft = 0;
            styles.borderRight = 0;
            styles.borderBottom = 0;
        }

        return <div>
            <AntdTable
                columns={columns}
                dataSource={data}
                pagination={false}
                style={styles}
            />
        </div>
    }
}

export const Text = ({ children, style, success, danger, warning, ...restProps}) => {
    const _style = {}
    if (danger) {
      _style.color = DANGER_COLOR
    }
    else if(warning) {
      _style.color = WARNING_COLOR
    }
    else if(success) {
      _style.color = SUCCESS_COLOR
    }

    return <span style={{ ..._style, ...style }} {...restProps}>{children}</span>
}

export const cmpObj = (a, b, field) => a[field].toLowerCase() > b[field].toLowerCase() ? 1 : -1
