export const GET_WALLET_BALANCE_START = 'GET_WALLET_BALANCE_START'
export const GET_WALLET_BALANCE_SUCCESS = 'GET_WALLET_BALANCE_SUCCESS'
export const GET_WALLET_BALANCE_FAIL = 'GET_WALLET_BALANCE_FAIL'

export const TRANSFER_CASH_START = 'TRANSFER_CASH_START'
export const TRANSFER_CASH_SUCCESS = 'TRANSFER_CASH_SUCCESS'
export const TRANSFER_CASH_FAIL = 'TRANSFER_CASH_FAIL'

const initialState = {
	wallet: null,
	credits: null,
	isGettingBalance: false,
	isTransferingCash: false
}

export default function wallet(state = initialState, action = {}) {
	switch (action.type) {
		case GET_WALLET_BALANCE_START:
			return {
				...state,
				isGettingBalance: true,
			}
		case GET_WALLET_BALANCE_SUCCESS: {
			return {
				...state,
				isGettingBalance: false,
				wallet: action.result.data.wallet,
				credits: action.result.data.emoney
			}
		}
		case GET_WALLET_BALANCE_FAIL:
			return {
				...state,
				isGettingBalance: false,
			}
		default:
			return state
	}
}

export function getBalance() {
	return {
		types: [GET_WALLET_BALANCE_START, GET_WALLET_BALANCE_SUCCESS, GET_WALLET_BALANCE_FAIL],
		promise: client => client.get(`/api/billing/wallet`),
	}
}


export function transferCashToBankAccount(balance, sepa = false) {
	return {
		types: [TRANSFER_CASH_START, TRANSFER_CASH_SUCCESS, TRANSFER_CASH_FAIL],
		promise: client => client.post(`/api/payment/pay-out`, {
			money: balance,
			sepa
		})
	}
}
