import React, { Component } from 'react'
import Paper from 'material-ui/Paper'
import Overlay from './ContentBlock/Overlay'
import apiClient from '../helpers/apiClient'
import ReactPlayer from 'react-player'
import LinearProgress from 'material-ui/LinearProgress'

import '../styles/components/CustomAudioPlayer.scss'
// https://open.spotify.com/track/2xmrfQpmS2iJExTlklLoAL
// https://open.spotify.com/track/3AzvNvE3xpgelqmE5MRnbI

export default class SpotifyAudioPlayer extends Component {
	constructor(props) {
		super(props)

		this.state = {}
	}

	UNSAFE_componentWillMount() {
		const { media, updateItem } = this.props
		const { index, content_url } = media

		if (!content_url || !/mp3-preview/.test(content_url)) {
			apiClient.get(`/api/media/audio/validate-url?url=${content_url}`).then(res => {
				const { thumbnail_url } = res.data

				updateItem(index, thumbnail_url, null, null, res.data.content_url)
			})
		}
	}

	playPause = () => {
		this.setState({
			isPlaying: !this.state.isPlaying,
		})
	}

	durationHandler = duration => {
		const { updateItem, media } = this.props

		if (updateItem) {
			updateItem(media.index, null, duration)
		} else {
			this.setState({
				duration,
			})
		}
	}

	progressHandler = progress => {
		const { updateItem, media } = this.props

		if (updateItem) {
			updateItem(media.index, null, null, Math.round((progress.playedSeconds / this.state.duration) * 100))
		} else {
			this.setState({
				position: Math.round((progress.playedSeconds / this.state.duration) * 100),
			})
		}
	}

	render() {
		const { media, updateItem } = this.props
		const { thumbnail_url, location, content_url } = media
		const { isPlaying } = this.state

		let position, duration

		if (updateItem) {
			position = media.position
			duration = media.duration
		} else {
			position = this.state.position
			duration = this.state.duration
		}

		return (
			<Paper style={{ width: '100%', color: '#000' }} zDepth={1} className="custom-audio-player">
				<div className="custom-audio-player__wrapper">
					<div className="custom-audio-player__image-block">
						<img draggable="false" src={thumbnail_url} />
					</div>
					<div className="custom-audio-player__content">
						<div>
							<div className="custom-audio-player__top">
								<button onClick={this.playPause} className="custom-audio-player__play-button">
									{isPlaying ? (
										<i className="fa fa-pause" aria-hidden="true" />
									) : (
										<i className="fa fa-play" aria-hidden="true" />
									)}
								</button>
								{media.title}
								<p className="custom-audio-player__title">{location}</p>
							</div>
							<div className="custom-audio-player__linear-wrapper">
								<LinearProgress
									style={{
										backgroundColor: '#e3e3e3',
										margin: '0 1rem',
										width: 'calc(100% - 2rem)',
									}}
									mode="determinate"
									value={Math.round(position) || 0}
								/>
								<div className="custom-audio-player__linear-bottom">
									<div className="custom-audio-player__linear-right">
										<span>{`00:${Math.round(duration) || '00'}`}</span>
									</div>
								</div>
							</div>
						</div>
						<div className="custom-audio-player__overlay-wrapper">
							<Overlay media={media} hide={false} color={'#000'} />
						</div>
						<div className="d-none">
							<ReactPlayer
								url={content_url}
								playing={isPlaying}
								onProgress={this.progressHandler}
								onDuration={this.durationHandler}
								playsinline
							/>
						</div>
					</div>
				</div>
			</Paper>
		)
	}
}
