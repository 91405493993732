import React from 'react'
import './Button.scss'
import classNames from 'classnames';

/**
 * Typical button
 * todo: add large size
 * @param {string, node} label button label/text
 * @param {bool} success button is green
 * @param {bool} primary button habe giggworks primary color
 * @param {bool} flat only text
 * @param {bool} danger button have danger color
 * @param {bool} disabled button is disabled
 * @param {bool} dark button is dark - thers some outlined and dark buttons in dashboard/admin
 * @param {bool} fullWidth
 * @param {bool} outlined - only border and text has color
 * @param {bool} small 
 * @param {bool} rounded 
 * @param {} ...other props are passed to root element 
 */
class Button extends React.PureComponent {
    render() {
        const {
            id,
            className,
            label,
            success,
            outlined,
            flat,
            primary,
            disabled,
            danger,
            fullWidth,
            small,
            dark,
            type,
            grey,
            black,
            rounded,
            simpleBorder,
            ...restProps
        } = this.props;
        
        return <button
            id={id}
            type={type ? type : 'button'}
            className={classNames(
                'g-Button', 
                { success, primary, dark, danger, flat, simpleBorder, gRounded: rounded, disabled, outlined, fullWidth, small, grey, black },
                `${className}`
            )}
            disabled={disabled}
            {...restProps}
        >
            {label}
        </button>
    }
}
export default Button;