const validate = values => {
	const errors = {}
	if (!values.first_name) {
		errors.first_name = 'Required'
	}
	if (!values.last_name) {
		errors.last_name = 'Required'
	}
	if (!values.birth_date) {
		errors.birth_date = 'Required'
	}
  if (!values.over18) {
    errors.over18 = 'Required'
  }
	if (!values.location) {
		errors.location = 'Required'
	}
	else if (!values.location.street) {
		errors.location = 'Street required'
	}
	else if (!values.location.city) {
		errors.location = 'City required'
	}
	else if (!values.location.state) {
		errors.location = 'State required'
	}
	else if (!values.location.postal_code) {
		errors.location = 'Zip code required'
	}
	else if (!values.location.country) {
		errors.location = 'Country required'
	}

	if (values.location && values.location.formatted_address && !values.location.place_id) {
		errors.location = 'You must select a city from the list'
	}

	// if (!values.phone_number || !values.phone_number.phone_number || values.phone_number.phone_number === '' || !values.phone_number.country_code || values.phone_number.country_code === '') {
	// 	errors.phone_number = 'Required';
	// }

	if (!values.phone_number || !values.phone_number.phone_number) {
		errors.phone_number = 'Required';
	}

	if (!values.phone_number || !values.phone_number.country_code) {
		errors.country_code = 'Required';
	}

	// if (values.phone_number && values.phone_number.country_code.toString().length < 2) {
	// 	errors.country_code = 'Country code too short';
	// }

	// if (values.phone_number && values.phone_number.country_code.toString().length > 3) {
	// 	errors.phone_number = 'Country code too long'
	// }

	// if (values.phone_number && values.phone_number.phone_number.toString().length < 7) {
	// 	errors.phone_number = 'Phone number too short'
	// }

	return errors
}

export default validate
