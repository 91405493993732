import React, { Component } from 'react';
import { reduxForm, Field } from 'redux-form';
import { connect } from 'react-redux';
import { getInitialValues } from './AudioUploadWrapper';
import * as _ from 'lodash';
import ReactPlayer from 'react-player';

import { MAX_MEDIA_DESCRIPTION_LENGTH, MAX_MEDIA_HASHTAGS_COUNT } from '../../../utils/constants'

import '../../../styles/components/EditVideo.scss';
import MediaFieldWrapper from './MediaFieldWrapper';

export const MINIMUM_ITEMS_ELEMENTS = 3;

class VideoUploadWrapper extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isActiveYouTubeInstructions: false,
      isActiveVimeoInstructions: false
    };
  }

  validator = ({ url, title, hashtags, ...restProsp }) => {
    const errors = {}

    const youtube = /(?:https?:\/\/)?(?:(www|m)\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-)+)(?:\S+)?/;
    const vimeo = /(http|https)?:\/\/(www\.)?vimeo.com\/(?:channels\/(?:\w+\/)?|groups\/([^\/]*)\/videos\/|)(\d+)(?:|\/\?)/;

		if (!url) {
			errors.content_url = 'Url is required'
		} else if (!(youtube.test(url) || vimeo.test(url))) {
			errors.content_url = 'Url is invalid'
    }

    if (title && title.length > MAX_MEDIA_DESCRIPTION_LENGTH){
      errors.title = 'Description is too long'
    } /*else if (!title || title.length === 0) {
			errors.title = 'Description is required'
    }*/

    if (hashtags.length > MAX_MEDIA_HASHTAGS_COUNT) {
			errors.hashtags = 'max 5 hashtags'
    }

		if (errors.content_url || errors.title || errors.hashtags){
			return Promise.reject(errors)
		}

		return Promise.resolve({ content_url: url })
	}

  switchInstructions = type => {
    switch (type) {
      case 'youtube':
        this.setState({
          isActiveYouTubeInstructions: !this.state.isActiveYouTubeInstructions,
          isActiveVimeoInstructions: false
        });
        break;
      case 'vimeo':
        this.setState({
          isActiveVimeoInstructions: !this.state.isActiveVimeoInstructions,
          isActiveYouTubeInstructions: false
        });
        break;
    }
  };

  render() {
    const { isActiveYouTubeInstructions, isActiveVimeoInstructions } = this.state;

    return (
      <div className='social-audio-list-wrapper'>
            <div className="content-reposition">
              <button className="reposition-btn">Click to reposition content</button>
            </div>

            <Field name="videos"
              component={(props) => <MediaFieldWrapper
                media_type={'video'}
                validator={this.validator}
                getInnerContent={({ innerProps }) => <div className={'embed-responsive embed-responsive-4by3'}>
                    <ReactPlayer
                      url={innerProps.item.content_url}
                      onPlay={() => {}}
                      onPause={() => {}}
                      playing={false}
                      loop={() => {}}
                      className={'embed-responsive-item'}
                    />
                  </div>
                }
                {...props}
              />}
            />

            <div className="spotify">
                <p>Upload Instructions
                  <i
                    style={{
                      color: isActiveYouTubeInstructions ? '#FA0202' : '#000'
                    }}
                    onClick={() => this.switchInstructions('youtube')}
                    className="fa fa-youtube" />
                  <i
                    style={{
                      color: isActiveVimeoInstructions ? '#3cb8ee' : '#000'
                    }}
                    onClick={() => this.switchInstructions('vimeo')}
                    className="fa fa-vimeo" />
                </p>

                <div className="steps">
                  {
                    isActiveYouTubeInstructions &&
                    <img src={require('./../../../assets/images/instructions/instructions-youtube.jpg')} alt=""/>
                  }
                  {
                    isActiveVimeoInstructions &&
                    <img src={require('./../../../assets/images/instructions/instructions-vimeo.jpg')} alt=""/>
                  }
                </div>
            </div>
      </div>
    );
  }
}

VideoUploadWrapper = reduxForm({
  form: 'EditVideos',
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: false,
  enableReinitialize: true,
})(VideoUploadWrapper);

export default connect(
  ({ user, profile, form }) => ({
    currentUser: user.currentUser,
    profile: user.currentProfile,
    initialValues: JSON.parse(JSON.stringify(getInitialValues(form.EditVideos, user.currentProfile, 'videos'))),
  })
)(VideoUploadWrapper);
