const validate = values => {
	const errors = {}

	const cats = values.categories

  if (cats && cats.length) {

    if (!cats[0].name) {
      errors.categories = 'Required'
    }

    if (cats[0].styles && cats[0].styles.length) {
      const venueOccasions = cats[0].styles.filter((item) => item.type === 'venue_occasion');

      if (!venueOccasions.length) {
        errors.categories = 'Required';
      }
    } else {
      errors.categories = 'Required';
    }
  }

	return errors
};

export default validate
