import config from '../config';

export function loginOAuth(type) {
  return new Promise((resolve, reject)=> {
    window.onCloseSocialLoginWindow = function onCloseSocialLoginWindow (result){
      console.log('oauth.js', result)
      if (result.status === 200){
        resolve(result.data);
      } else {
        reject(result);
      }
    };

    let popupWindow = window.open(`${config.protocol}://${config.host}:${config.port}/auth/${type}`, 'popUpWindow',
      'menubar=no,toolbar=no,resizable=yes,scrollbars=yes,height=500,width=900,centerscreen=true');
  }, err => {
			console.table('oauth', { ...err })

  });
}
