export const CREATE_CONTRACT_START = 'CREATE_CONTRACT_START'
export const CREATE_CONTRACT_SUCCESS = 'CREATE_CONTRACT_SUCCESS'
export const CREATE_CONTRACT_FAIL = 'CREATE_CONTRACT_FAIL'

export const REJECT_CONTRACT_START = 'REJECT_CONTRACT_START'
export const REJECT_CONTRACT_SUCCESS = 'REJECT_CONTRACT_SUCCESS'
export const REJECT_CONTRACT_FAIL = 'REJECT_CONTRACT_FAIL'

export const GET_CONTRACT_START = 'GET_ALL_CHATS_START'
export const GET_CONTRACT_SUCCESS = 'GET_CONTRACT_SUCCESS'
export const GET_CONTRACT_FAIL = 'GET_CONTRACT_FAIL'

export const UPDATE_CONTRACT_TERMS_START = 'UPDATE_CONTRACT_TERMS_START'
export const UPDATE_CONTRACT_TERMS_SUCCESS = 'UPDATE_CONTRACT_TERMS_SUCCESS'
export const UPDATE_CONTRACT_TERMS_FAIL = 'UPDATE_CONTRACT_TERMS_FAIL'

export const UPDATE_GIG_FOR_CONTRACT_START = 'UPDATE_GIG_FOR_CONTRACT_START'
export const UPDATE_GIG_FOR_CONTRACT_SUCCESS = 'UPDATE_GIG_FOR_CONTRACT_SUCCESS'
export const UPDATE_GIG_FOR_CONTRACT_FAIL = 'UPDATE_GIG_FOR_CONTRACT_FAIL'

export const UPDATE_OPTIONS_FOR_CONTRACT_START = 'UPDATE_OPTIONS_FOR_CONTRACT_START'
export const UPDATE_OPTIONS_FOR_CONTRACT_SUCCESS = 'UPDATE_OPTIONS_FOR_CONTRACT_SUCCESS'
export const UPDATE_OPTIONS_FOR_CONTRACT_FAIL = 'UPDATE_OPTIONS_FOR_CONTRACT_FAIL'

export const DELETE_CONTRACT_OPTION_START = 'DELETE_CONTRACT_OPTION_START'
export const DELETE_CONTRACT_OPTION_SUCCESS = 'DELETE_CONTRACT_OPTION_SUCCESS'
export const DELETE_CONTRACT_OPTION_FAIL = 'DELETE_CONTRACT_OPTION_FAIL'

export const GET_CONTRACT_FRAME_START = 'GET_CONTRACT_FRAME_START'
export const GET_CONTRACT_FRAME_SUCCESS = 'GET_CONTRACT_FRAME_SUCCESS'
export const GET_CONTRACT_FRAME_FAIL = 'GET_CONTRACT_FRAME_FAIL'

export const SEND_CONTRACT_START = 'SEND_CONTRACT_START'
export const SEND_CONTRACT_SUCCESS = 'SEND_CONTRACT_SUCCESS'
export const SEND_CONTRACT_FAIL = 'SEND_CONTRACT_FAIL'

export const DOWNLOAD_CONTRACT_START = 'DOWNLOAD_CONTRACT_START'
export const DOWNLOAD_CONTRACT_SUCCESS = 'DOWNLOAD_CONTRACT_SUCCESS'
export const DOWNLOAD_CONTRACT_FAIL = 'DOWNLOAD_CONTRACT_FAIL'

export const ADD_GUESTS_LIST_START = 'ADD_GUESTS_LIST_START'
export const ADD_GUESTS_LIST_SUCCESS = 'ADD_GUESTS_LIST_SUCCESS'
export const ADD_GUESTS_LIST_FAIL = 'ADD_GUESTS_LIST_FAIL'

export const UNBOOK_VENDOR_START = 'UNBOOK_VENDOR_START'
export const UNBOOK_VENDOR_SUCCESS = 'UNBOOK_VENDOR_SUCCESS'
export const UNBOOK_VENDOR_FAIL = 'UNBOOK_VENDOR_FAIL'

const initialState = {
	contract: {},
	isCreatingContract: false,
	isGettingContract: false,
	isUpdateContract: false,
	isGettingContractFrame: false,
	isRejectingContract: false,
	frameUrl: '',
	isSendingContract: true,
	isDownloadingContract: false,
}

export default function contracts(state = initialState, action = {}) {
	switch (action.type) {
		case CREATE_CONTRACT_START:
			return {
				...state,
				isCreatingContract: true,
			}
		case CREATE_CONTRACT_SUCCESS:
			return {
				...state,
				isCreatingContract: false,
				// contract: action.result.data,
			}
		case CREATE_CONTRACT_FAIL:
			return {
				...state,
				isCreatingContract: false,
			}
		case REJECT_CONTRACT_START:
			return {
				...state,
				isRejectingContract: true,
			}
		case REJECT_CONTRACT_SUCCESS:
			return {
				...state,
				isRejectingContract: false,
			}
		case REJECT_CONTRACT_FAIL:
			return {
				...state,
				isRejectingContract: false,
			}

		case GET_CONTRACT_START:
			return {
				...state,
				isGettingContract: true,
			}
		case GET_CONTRACT_SUCCESS:
			return {
				...state,
				isGettingContract: false,
				contract: action.result.data,
			}
		case GET_CONTRACT_FAIL:
			return {
				...state,
				isGettingContract: false,
			}

		case UPDATE_CONTRACT_TERMS_START:
			return {
				...state,
				isUpdateContract: true,
			}
		case UPDATE_CONTRACT_TERMS_SUCCESS:
			return {
				...state,
				isUpdateContract: false,
				contract: {
					...state.contract,
					data: {
						...state.contract.data,
						terms: action.result.data.data.terms,
					},
				},
			}
		case UPDATE_CONTRACT_TERMS_FAIL:
			return {
				...state,
				isUpdateContract: false,
			}

		case UPDATE_GIG_FOR_CONTRACT_START:
			return {
				...state,
				isUpdateContract: true,
			}
		case UPDATE_GIG_FOR_CONTRACT_SUCCESS:
			return {
				...state,
				isUpdateContract: false,
				contract: {
					...state.contract,
					data: {
						...state.contract.data,
						gig: action.result.data.data.gig,
					},
				},
			}
		case UPDATE_GIG_FOR_CONTRACT_FAIL:
			return {
				...state,
				isUpdateContract: false,
			}

		case UPDATE_OPTIONS_FOR_CONTRACT_START:
			return {
				...state,
				isUpdateContract: true,
			}
		case UPDATE_OPTIONS_FOR_CONTRACT_SUCCESS:
			return {
				...state,
				isUpdateContract: false,
				contract: {
					...state.contract,
					data: {
						...state.contract.data,
						options: action.result.data.data.options,
					},
				},
			}
		case UPDATE_OPTIONS_FOR_CONTRACT_FAIL:
			return {
				...state,
				isUpdateContract: false,
			}

		case DELETE_CONTRACT_OPTION_START:
			return {
				...state,
				isUpdateContract: true,
			}
		case DELETE_CONTRACT_OPTION_SUCCESS:
			return {
				...state,
				isUpdateContract: false,
				contract: {
					...state.contract,
					data: {
						...state.contract.data,
						options: action.result.data.data.options,
					},
				},
			}
		case DELETE_CONTRACT_OPTION_FAIL:
			return {
				...state,
				isUpdateContract: false,
			}

		case GET_CONTRACT_FRAME_START:
			return {
				...state,
				isGettingContractFrame: true,
			}
		case GET_CONTRACT_FRAME_SUCCESS:
			return {
				...state,
				isGettingContractFrame: false,
				frameUrl: action.result.data,
			}
		case GET_CONTRACT_FRAME_FAIL:
			return {
				...state,
				isGettingContractFrame: false,
			}

		case SEND_CONTRACT_START:
			return {
				...state,
				isSendingContract: true,
			}
		case SEND_CONTRACT_SUCCESS:
			return {
				...state,
				isSendingContract: false,
			}
		case SEND_CONTRACT_FAIL:
			return {
				...state,
				isSendingContract: false,
			}

		case DOWNLOAD_CONTRACT_START:
			return {
				...state,
				isDownloadingContract: true,
			}
		case DOWNLOAD_CONTRACT_SUCCESS:
			return {
				...state,
				isDownloadingContract: false,
				contract: action.result.data,
			}
		case DOWNLOAD_CONTRACT_FAIL:
			return {
				...state,
				isDownloadingContract: false,
			}

		case ADD_GUESTS_LIST_START:
			return {
				...state,
				isUpdateContract: true,
			}
		case ADD_GUESTS_LIST_SUCCESS:
			return {
				...state,
				isUpdateContract: false,
				contract: {
					...state.contract,
					data: {
						...state.contract.data,
						guests: action.result.data.data.guests,
					},
				},
			}
		case ADD_GUESTS_LIST_FAIL:
			return {
				...state,
				isUpdateContract: false,
			}

		default:
			return {
				...state,
			}
	}
}

export function createContract(gigId, vendorId) {
	return {
		types: [CREATE_CONTRACT_START, CREATE_CONTRACT_SUCCESS, CREATE_CONTRACT_FAIL],
		promise: client => client.post(`/api/contract?gigId=${gigId}&vendorId=${vendorId}`),
	}
}

export function rejectContract(contractId) {
	return {
		types: [REJECT_CONTRACT_START, REJECT_CONTRACT_SUCCESS, REJECT_CONTRACT_FAIL],
		promise: client => client.put(`/api/contract/reject?contractId=${contractId}`)
	}
}

export function getContract(hash) {
	return {
		types: [GET_CONTRACT_START, GET_CONTRACT_SUCCESS, GET_CONTRACT_FAIL],
		promise: client => client.get(`/api/contract?hash=${hash}`),
	}
}

export function updateContractTerms(contractId, number, title, description) {
	return {
		types: [UPDATE_CONTRACT_TERMS_START, UPDATE_CONTRACT_TERMS_SUCCESS, UPDATE_CONTRACT_TERMS_FAIL],
		promise: client =>
			client.put(
				`/api/contract/edit?id=${contractId}&add[terms][${number}][title]=${title}&add[terms][${number}][description]=${description}`
			),
	}
}

export function addGuestsList(contractId, list) {
	return {
		types: [ADD_GUESTS_LIST_START, ADD_GUESTS_LIST_SUCCESS, ADD_GUESTS_LIST_FAIL],
		promise: client => client.put(`/api/contract/edit?id=${contractId}&add[guests]=${list}`),
	}
}

export function updateGigParamsForContract(contractId, paramString) {
	return {
		types: [UPDATE_GIG_FOR_CONTRACT_START, UPDATE_GIG_FOR_CONTRACT_SUCCESS, UPDATE_GIG_FOR_CONTRACT_FAIL],
		promise: client => client.put(`/api/contract/edit?id=${contractId}&${paramString}`),
	}
}

export function updateOptionsForContract(contractId, paramString) {
	return {
		types: [UPDATE_OPTIONS_FOR_CONTRACT_START, UPDATE_OPTIONS_FOR_CONTRACT_SUCCESS, UPDATE_OPTIONS_FOR_CONTRACT_FAIL],
		promise: client => client.put(`/api/contract/edit?id=${contractId}&${paramString}`),
	}
}

export function deleteOption(contractId, optionId) {
	return {
		types: [DELETE_CONTRACT_OPTION_START, DELETE_CONTRACT_OPTION_SUCCESS, DELETE_CONTRACT_OPTION_FAIL],
		promise: client => client.put(`/api/contract/edit?id=${contractId}&delete[options][${optionId}]`),
	}
}

export function getFrame(hash) {
	return {
		types: [GET_CONTRACT_FRAME_START, GET_CONTRACT_FRAME_SUCCESS, GET_CONTRACT_FRAME_FAIL],
		promise: client => client.get(`/api/contract/fetch?hash=${hash}&type=sign`),
	}
}

export function sendContract(hash) {
	return {
		types: [SEND_CONTRACT_START, SEND_CONTRACT_SUCCESS, SEND_CONTRACT_FAIL],
		promise: client => client.post(`/api/gigs/send-contract/${hash}`),
	}
}

export function downloadContract(hash) {
	return {
		types: [DOWNLOAD_CONTRACT_START, DOWNLOAD_CONTRACT_SUCCESS, DOWNLOAD_CONTRACT_FAIL],
		promise: client => client.get(`/api/contract/fetch?hash=${hash}&type=download`),
	}
}

export function unbookVendor(gigId, vendorId) {
	return {
		types: [UNBOOK_VENDOR_START, UNBOOK_VENDOR_SUCCESS, UNBOOK_VENDOR_FAIL],
		promise: client => client.delete(`/api/contract/unbook?gigId=${gigId}&vendorId=${vendorId}`),
	}
}
