import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactPlayer from 'react-player';

export default class AudioBlock extends Component {
  static propTypes = {
    media: PropTypes.object.isRequired,
    onPlay: PropTypes.func,
    onPause: PropTypes.func,
  };

  constructor (props) {
    super(props);
  }

  render () {
    const { media, onPlay, onPause } = this.props;

    //TODO
    return (
      <div>
        <div className="media">
          <div className="media-player">
            <ReactPlayer width="100%" height="24.5vw" url={media.content_url} onPlay={onPlay} onPause={onPause} playsinline/>
          </div>
          <div className="media-controls">
          </div>
        </div>
      </div>
    );
  }
}
