import React, { Component } from 'react'
import { SortableContainer, SortableElement, arrayMove } from 'react-sortable-hoc'
import EditOverlay from '../../ContentBlock/EditOverlay'
import { reduxForm, Field } from 'redux-form'
import { connect } from 'react-redux'
import * as _ from 'lodash'
import { AddNewBlock } from '../../index'
import MediaFieldWrapper from './MediaFieldWrapper';
import '../../../styles/components/EditPicture.scss'
import '../../../styles/components/register/media/PicturesUploadWrapper.scss'
import { MAX_MEDIA_DESCRIPTION_LENGTH, MAX_MEDIA_HASHTAGS_COUNT } from '../../../utils/constants'
import { Asterisk } from '../../common/Asterisk/Asterisk'

const MAX_FILE_SIZE_IN_MB = 10

// class SortableItem extends Component {
// 	render() {
// 		const { data, isDraggble, submitHandler, validate } = this.props

// 		return (
// 			<div className="edit-picture-item-wrapper">
// 				<div className="aspect-ratio-container">
// 					<div className="aspect-ratio-content">
// 						<EditOverlay
// 							validate={validate}
// 							media={data}
// 							typeContent="picture"
// 							isDraggble={isDraggble}
// 							submitHandler={submitHandler}
// 						>
// 							<div
// 								className="picture-item__image"
// 								style={{ backgroundImage: `url(${data.preview || data.content_url})` }}
// 							/>
// 							<div className="position-number">{data.index + 1}</div>
// 						</EditOverlay>
// 					</div>
// 				</div>
// 			</div>
// 		)
// 	}
// }

// SortableItem = SortableElement(SortableItem)

// const SortableList = SortableContainer(({ items, isDraggble, submitHandler, changeDraggble, hint, validate }) => {
// 	const openedBlocks = items.length < 3
// 	let position = 0

// 	return (
// 		<div className="edit-picture-list-container">
// 			{items.map((item, index) => (
// 				<SortableItem
// 					key={`item-${index}`}
// 					data={{ ...item, index }}
// 					index={index}
// 					disabled={isDraggble}
// 					isDraggble={changeDraggble}
// 					validate={validate}
// 					submitHandler={submitHandler}
// 				/>
// 			))}

// 			{_.range(openedBlocks ? 3 - items.length : 1).map((item, key) => (
// 				<div key={key + Math.random()} className="edit-picture-item-wrapper">
// 					<div className="aspect-ratio-container">
// 						<div className="aspect-ratio-content">
// 							<AddNewBlock
// 								uploadTxt="Upload Picture File"
// 								showItem={openedBlocks}
// 								opened={openedBlocks}
// 								submitHandler={submitHandler}
// 								validate={validate}
// 								label="Add more pictures"
// 								type="file"
// 								placeholder="Description"
// 								typeContent="picture"
// 								positionNumber={items.length + (openedBlocks ? key + 1 : 1)}
// 								hint={hint}
// 							/>
// 						</div>
// 					</div>
// 				</div>
// 			))}
// 		</div>
// 	)
// })

// class Wrapper extends Component {
// 	constructor(props) {
// 		super(props)

// 		this.state = {
// 			isDraggble: false,
// 		}
// 	}

// 	ChangeDraggbleState = bool => {
// 		this.setState(() => ({ isDraggble: bool }))
// 	}

// 	onSortEnd = ({ oldIndex, newIndex }) => {
// 		const {
// 			input: { value, onChange },
// 		} = this.props

// 		onChange(arrayMove(value, oldIndex, newIndex))
// 	}

// 	validate = ({ input, title, location }) => {
// 		const errors = {}
// 		console.log('validate')
// 		console.log({ input, title, location })
// 		//return Promise.resolve()
// 		// todo data is undefined
// 		if (!input.files[0]) {
// 			errors.url = 'File is required.'
// 		}

// 		if (!location) {
// 		  errors.location = 'Description is required'
// 		}

// 		const maxFileSize = MAX_FILE_SIZE_IN_MB * 1024 * 1024
// 		if (input.files[0] && input.files[0].size > maxFileSize) {
// 			errors.url = `${MAX_FILE_SIZE_IN_MB} MB file size exceeded`
// 		}

// 		if (Object.keys(errors).length) {
// 			return Promise.reject(errors)
// 		}

// 		return Promise.resolve()
// 	}

// 	submitHandler = (input, data, index) => {
// 		const {
// 			input: { value, onChange },
// 		} = this.props

// 		if (typeof input === 'number') {
// 			value.splice(input, 1)
// 			return onChange(value)
// 		}

// 		console.log('submitHandler input', input)
// 		console.log('submitHandler data', data)

// 		const reader = new FileReader()
// 		reader.onload = () => {
// 			const newItem = {
// 				...data,
// 				title: data.location || '',
// 				file: input.files[0],
// 				preview: reader.result,
// 			}

// 			const newItems = [...value]

// 			if (!index && index !== 0) {
// 				newItems.push(newItem)
// 			} else {
// 				newItems[index] = newItem
// 			}

// 			console.log('nexwITems', newItems)

// 			onChange(newItems)

// 			reader.onload = null
// 		}
// 		if (input.files[0]) {
//   		reader.readAsDataURL(input.files[0])
//     } else {
// 		  console.log('else route', input)
// 		  console.log('else index', index)
// 		  const newItem = {
// 				...data
//         //file: input.files[0],
// 				//preview: reader.result,
//       }

//       console.log('newItem', newItem)

// 			const newItems = [...value]

// 			if (!index && index !== 0) {
// 				newItems.push(newItem)
// 			} else {
// 				newItems[index] = newItem
// 			}

// 			onChange(newItems)
//     }
// 	}

// 	render() {
// 		const items = [...new Set([...this.props.input.value, ...this.props.meta.initial])]

// 		let hint = (
// 			<div className="upload-hint hover-description">
// 				<div className="col-12">
// 					<p>
// 						Only Upload a jpg or png and size format <strong>5 x 7</strong> or <strong>3 x 4</strong>
// 					</p>
// 				</div>
// 			</div>
// 		)

// 		return (
// 			<div className="social-picture-list-wrapper" style={{ margin: 0, marginTop: '1.3rem' }}>
// 				<SortableList
// 					isDraggble={this.state.isDraggble}
// 					changeDraggble={this.ChangeDraggbleState}
// 					axis="xy"
// 					items={items}
// 					submitHandler={this.submitHandler}
// 					onSortEnd={this.onSortEnd}
// 					hint={hint}
// 					validate={this.validate}
// 				/>
// 			</div>
// 		)
// 	}
// }

class PicturesUploadWrapper extends Component {
	constructor(props){
		super(props);
	}

	createDataUrlFromFile = (file) => {
		const reader = new FileReader();

		reader.onload = () => {
			const imageAsDataUri = reader.result;
			reader.onload = null;
			return imageAsDataUri;
		}
		reader.readAsDataURL(file)
	}

	validator = ({ input, title, hashtags, item }) => {
		const errors = {}
		let edited = true;

		// console.log("from validator - input", input)

		if ((!input || !input.files[0]) && !_.get(item, 'content_url', false)) {
			errors.content_url = 'File is required.'
		} else if (!input || !input.files[0]) {
			//edited = false;
		}

		if (title && title.length > MAX_MEDIA_DESCRIPTION_LENGTH){
			errors.title = 'Description is too long'
		} /*else if (!title || title.length === 0) {
			errors.title = 'Description is required'
		}*/

		// const maxFileSize = MAX_FILE_SIZE_IN_MB * 1024 * 1024
		// if (input && input.files[0] && input.files[0].size > maxFileSize) {
		// 	errors.content_url = `${MAX_FILE_SIZE_IN_MB} MB file size exceeded`
		// }

		if (hashtags.length > MAX_MEDIA_HASHTAGS_COUNT) {
			errors.hashtags = 'max 5 hashtags'
    }

		if (Object.keys(errors).length) {
			return Promise.reject(errors)
		}

		const file = input ?
			new File([input.files[0]], input.files[0].name, { type: input.files[0].type }) :
			undefined

		return Promise.resolve({
			file,
			edited
		})
	}

	render() {
		return <div className='social-audio-list-wrapper'>
			<div className="content-reposition">
        <div className="header">
          <div className="title">You need 2 media of any combination <Asterisk /> <br /> <br /> </div>
        </div>
				<button className="reposition-btn">Click to reposition content</button>
			</div>


				<Field name="pictures"
					component={(props) => <MediaFieldWrapper
						media_type={'picture'}
						validator={this.validator}
						getInnerContent={({ innerProps }) =>
							<div className="square">
								<div className="square-content">
									{(innerProps.item.preview || innerProps.item.content_url) &&
										<img src={innerProps.item.preview || innerProps.item.content_url} style={{ objectFit: 'contain', width: '100%', height: '100%', background: '#ddd'}} />
									}
								</div>
							</div>
						}
      	    {...props}
      	  />}
				/>
		</div>
	}
}

PicturesUploadWrapper = reduxForm({
	form: 'EditPictures',
	destroyOnUnmount: false,
	forceUnregisterOnUnmount: false,
	enableReinitialize: true,
})(PicturesUploadWrapper)

export default connect(({ user, profile, form }) => ({
	currentUser: user.currentUser,
	profile: user.currentProfile,
	initialValues: getInitialValues(form.EditPictures, user.currentProfile, 'pictures')
}))(PicturesUploadWrapper)

function getInitialValues(form, profile, type) {
    return {
		[type]: _.get(profile, 'media_items', [])
			.filter(item => item.type === type.replace(/s$/, ''))
            .sort((a, b) => a.position_index > b.position_index),
    }
}
