import config from '../../config';
const windowConfig = 'menubar=no,toolbar=no,resizable=yes,scrollbars=yes,height=500,width=900,centerscreen=true';

export function WhatsAppShare(url) {
  window.open(`https://web.whatsapp.com/send?text=${url}`, 'popUpWindow', windowConfig);
}

export function FacebookMessengerShare(url) {
  window.open(`fb-messenger://share?link='${url}&app_id=${config.OAuth.facebook.app_id}`, 'popUpWindow', windowConfig);
}

export function LinkedinShare(url) {
  window.open(`https://www.linkedin.com/shareArticle?url=${url}`, 'popUpWindow', windowConfig);
}

export function FacebookShare(url) {
  window.open(`https://www.facebook.com/sharer/sharer.php?u=${url}`, 'popUpWindow', windowConfig);
}

export function TwitterShare(url) {
  window.open(`http://www.twitter.com/share?url=${url}`, 'popUpWindow', windowConfig);
}

export function PinterestShare(url, media, description) {
  window.open(`https://pinterest.com/pin/create/button/?url=${url}&media=${media}&description=${description}`, 'popUpWindow', windowConfig);
}

export function EmailShare(url, email) {
  window.open(`mailto:${email}?subject=${url}`, 'popUpWindow', windowConfig);
}
