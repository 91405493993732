import './ComparePopUp.scss';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Paper, RaisedButton } from 'material-ui';
import { Row } from 'reactstrap';
import { CompareItem } from './CompareItem/CompareItem';
import { block } from 'bem-cn';
import classNames from 'classnames';
import { some, get } from 'lodash';

const b = block('ComparePopUp');
const compareWhiteIcon = require('../../../assets/icons/compare_white.png');
const compareIcon = require('../../../assets/icons/compare_black.png');

export default class ComparePopUp extends Component {

  static propTypes = {
    itemsToCompare: PropTypes.array,
    show: PropTypes.bool,
    onRemoveItem: PropTypes.func,
    onClose: PropTypes.func,
  };

  constructor(props) {
    super(props);

    this.state = {
      invalidTypeProfileCard: false
    };
  }

  render() {
    const { itemsToCompare, show } = this.props;

    return show
      ? <div className={'ComparePopUp'}>
        <Row className={classNames('justify-content-center', 'ComparePopUp__wrapper')}>
          <div>
            <Paper
              zDepth={1}
              className={classNames('ComparePopUp__paper', 'row')}
              style={{
                margin: '0',
                width: '100%',
                padding: '1rem 2rem 1rem 0rem',
              }} >
              <div className={classNames('ComparePopUp__compare-list', 'col-12')}>
                <Row>
                  {itemsToCompare.map((item, key) =>
                    <CompareItem item={item} key={key} onRemove={() => this.onRemoveItem(item)}/>
                  )}
                  <div className="compareBtn-wrapper d-flex justify-content-end align-items-center">
                    <RaisedButton
                      label="Compare artists"
                      disabled={(itemsToCompare.length < 2 || itemsToCompare.length > 3)
                        || this.state.invalidTypeProfileCard}
                      labelPosition="before"
                      onClick={this.openCompareModal}
                      labelStyle={{ display: 'flex', flexWrap: 'nowrap', whiteSpace: 'nowrap', textTransform: 'none' }}
                      style={{ display: 'flex' }}
                      className={classNames('compareBtn', { disabled: itemsToCompare.length < 2
                          || itemsToCompare.length > 3 })}
                      icon={<img
                        src={(itemsToCompare.length < 2 || itemsToCompare.length > 3) ? compareWhiteIcon : compareIcon}
                        style={{ width: '1rem', height: '1rem' }} />} />
                    {
                      this.state.invalidTypeProfileCard &&
                        <span className="error">Choose only artists or venues to compare</span>
                    }
                    <button className={classNames('ComparePopUp__close-popup', 'close')} onClick={this.onClose}>
                      <span aria-hidden="true">×</span>
                    </button>
                  </div>
                </Row>
              </div>
            </Paper>
          </div>
        </Row>
      </div>
      : null;
  }

  validationCompare = itemsToCompare => {
    this.setState({ invalidTypeProfileCard: false });

    const currentType = get(itemsToCompare, '0.profile_type');
    const isInvalid = currentType === 'venue' && itemsToCompare.some(x => x.profile_type !== 'venue') ||
      ['artist', 'group'].indexOf(currentType) > -1 && itemsToCompare.some(x => ['artist', 'group'].indexOf(x.profile_type) === -1)

    if (currentType && isInvalid)
      this.setState({ invalidTypeProfileCard: true });
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { itemsToCompare } = nextProps;

    this.validationCompare(itemsToCompare);
  }

  onRemoveItem = (item) => {
    const { onRemoveItem } = this.props;
    if (onRemoveItem) {
      onRemoveItem(item);
    }
  };

  openCompareModal = () => {
    const { onClickCompare, itemsToCompare } = this.props;
    if (onClickCompare) {
      onClickCompare(itemsToCompare.map(item => item.id));
    }
  };

  onClose = () => {
    const { onClose } = this.props;
    if (onClose) {
      onClose();
    }
  };
}
