export const GIG_POST_START = 'gig/PostStart'
export const GIG_POST_SUCCESS = 'gig/PostSuccess'
export const GIG_POST_FAIL = 'gig/PostFail'

export const STYLES_GET_START = 'styles/GetStart'
export const STYLES_GET_SUCCESS = 'styles/GetSuccess'
export const STYLES_GET_FAIL = 'styles/GetFail'

export const FETCHING_GIGS_START = 'FETCHING_GIGS_START'
export const FETCHING_GIGS_SUCCESS = 'FETCHING_GIGS_SUCCESS'
export const FETCHING_GIGS_FAIL = 'FETCHING_GIGS_FAIL'

export const GIG_FEED_FETCH_START = 'GIG_FEED_FETCH_START'
export const GIG_FEED_FETCH_SUCCESS = 'GIG_FEED_FETCH_SUCCESS'
export const GIG_FEED_FETCH_FAIL = 'GIG_FEED_FETCH_FAIL'

export const GIG_PROGRESS_UP_START = 'GIG_PROGRESS_UP_START'
export const GIG_PROGRESS_UP_SUCCESS = 'GIG_PROGRESS_UP_SUCCESS'
export const GIG_PROGRESS_UP_FAIL = 'GIG_PROGRESS_UP_FAIL'

export const EMPTY_GIG_FEED = 'EMPTY_GIG_FEED'

const initialState = {
	gigFeed: [],
	styles: [],
	isFetchingGigFeed: false,
}

export default function gig(state = initialState, action = {}) {
	switch (action.type) {
		case GIG_POST_START:
			return {
				...state,
				isPostingGig: true,
			}
		case GIG_POST_SUCCESS:
			return {
				...state,
				isPostingGig: false,
				gig: action.result.data,
			}
		case GIG_POST_FAIL:
			return {
				...state,
				isPostingGig: false,
			}
		case STYLES_GET_START:
			return {
				...state,
				isGettingStyles: true,
			}
		case STYLES_GET_SUCCESS:
			return {
				...state,
				isGettingStyles: false,
				styles: action.result.data,
			}
		case STYLES_GET_FAIL:
			return {
				...state,
				isFetchingGigs: false,
			}
		case FETCHING_GIGS_START:
			return {
				...state,
				isFetchingGigs: true,
			}
		case FETCHING_GIGS_SUCCESS:
			return {
				...state,
				isFetchingGigs: false,
			}
		case FETCHING_GIGS_FAIL:
			return {
				...state,
				isGettingStyles: false,
			}
		case GIG_FEED_FETCH_START:
			return {
				...state,
				isFetchingGigFeed: true,
			}
		case GIG_FEED_FETCH_SUCCESS:
			if (action.result.status === 204) {
				return {
					...state,
					isFetchingGigFeed: false,
					gigFeed: [],
				}
			}
			return {
				...state,
				isFetchingGigFeed: false,
				gigFeed: action.withReplace ? action.result.data.data : state.gigFeed.concat(action.result.data.data),
			}
		case GIG_FEED_FETCH_FAIL:
			return {
				...state,
				isFetchingGigFeed: false,
			}
		case EMPTY_GIG_FEED:
			return {
				...state,
				gigFeed: [],
			}
		default:
			return state
	}
}

export function postGig(data) {
	return {
		types: [GIG_POST_START, GIG_POST_SUCCESS, GIG_POST_FAIL],
		promise: client => client.post(`/api/gigs`, data),
	}
}

const PAGES_TO_LOAD = 9

export function getGigs({ page, categoryId, isOccasion, styleName, params }) {
	const _params = []
	params && _params.push(...params)
	_params.push(isOccasion ? `style_name=${styleName}` : `category_id=${categoryId}`)


	return {
		types: [FETCHING_GIGS_START, FETCHING_GIGS_SUCCESS, FETCHING_GIGS_FAIL],
		promise: client =>
			client.get(`/api/gigs?page=${page}&per_page=${PAGES_TO_LOAD}&${_params.join('&')}`),
	}
}

export function getGigsByCategoryName({ page, categoryName, isOccasion, styleName, params }) {
  const _params = []
  params && _params.push(...params)
  _params.push(isOccasion ? `style_name=${styleName}` : `category_name=${categoryName}`)

  return {
    types: [FETCHING_GIGS_START, FETCHING_GIGS_SUCCESS, FETCHING_GIGS_FAIL],
    promise: client =>
      client.get(`/api/gigsByCategoryName?page=${page}&per_page=${PAGES_TO_LOAD}&${_params.join('&')}`),
  }
}

export function getGigFeed(page, super_category_id, withReplace = false) {
	const isOccasions = super_category_id === 'occasions'

	return {
		types: [GIG_FEED_FETCH_START, GIG_FEED_FETCH_SUCCESS, GIG_FEED_FETCH_FAIL],
		promise: client =>
			client.get(
				`/api/gigs/feed${isOccasions ? '/occasions' : ''}?page=${page}&super_category_id=${super_category_id}`
			),
		withReplace,
	}
}

export function getGigFeedByCategoryName(page, super_category_id, withReplace = false) {
  let url = '/api/gigs';

  if (super_category_id === 'occasions') {
    url = `${url}/feed/occasions?page=${page}&parent_category=${super_category_id}`;
  } else {
    url = `${url}/feedByCategoryName?page=${page}&super_category_name=${super_category_id}`;
  }

  return {
    types: [GIG_FEED_FETCH_START, GIG_FEED_FETCH_SUCCESS, GIG_FEED_FETCH_FAIL],
    promise: client =>
      client.get(url),
    withReplace,
  }
}

export function getStyles(categoryId, styleName) {
	const styleType = styleName ? `&style_type=${styleName}` : ''

	return {
		types: [STYLES_GET_START, STYLES_GET_SUCCESS, STYLES_GET_FAIL],
		promise: client => client.get(`/api/autocomplete/styles?category_id=${categoryId}${styleType}`),
	}
}

export function getStylesByName(categoryName, styleName) {
  const styleType = styleName ? `&style_type=${styleName}` : '';
  console.log(categoryName);

  return {
    types: [STYLES_GET_START, STYLES_GET_SUCCESS, STYLES_GET_FAIL],
    promise: client => client.get(`/api/autocomplete/getStylesByName?category_name=${categoryName}${styleType}`),
  }
}

export function gigStageUp(gigId) {
	return {
		types: [GIG_PROGRESS_UP_START, GIG_PROGRESS_UP_SUCCESS, GIG_PROGRESS_UP_FAIL],
		promise: client => client.post(`/api/gigs/progress/${gigId}/up`),
	}
}

export function emptyGigFeed() {
	return { type: EMPTY_GIG_FEED }
}
