import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactPlayer from 'react-player';
// const { PlayPause, MuteUnmute, } = controls;

export default class VideoBlock extends Component {
  static propTypes = {
    media: PropTypes.object.isRequired,
    onPlay: PropTypes.func,
    onPause: PropTypes.func,
    playing: PropTypes.bool,
    loop: PropTypes.bool
  };

  constructor(props) {
    super(props);
  }

  render() {
    const { media, onPlay, onPause, playing, loop } = this.props;

    return (
      <div className="videoBlock">
        <div className="media">
          <div className="media-player embed-responsive embed-responsive-4by3">
            <ReactPlayer
              url={media.content_url}
              onPlay={onPlay}
              onPause={onPause}
              playing={playing}
              loop={loop}
              className="embed-responsive-item"/>
          </div>
        </div>
      </div>
    );
  }
}
