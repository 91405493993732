import React from 'react'
const closeIcon = require('!!svg-inline-loader?classPrefix!../../../assets/icons/close.svg');
import SVGInline from 'react-svg-inline'

import classNames from 'classnames';
import { TextFieldComponent } from '../../CommonComponent';
import Button from '../Button/Button';

export default ({ value, onChange, onSubmit, className, error }) => <div className={classNames('service-info-form__item', className)}>
	<div className="service-info-form__item-header not-pulled">		
		<span className="fa-stack info-popup-window" style={{ width: '1.6em', textAlign: 'left'}} data-title={"nie ma tekstu"}>
			<i className="fa fa-circle-thin fa-stack-2x"/>
			<i className="fa fa-info fa-stack-1x"/>
		</span>

		<h4 className="service-info-form__title">							
			Promotion Code
		</h4>
	</div>						

	<div className="d-flex flex-column flex-md-row w-100">					
		<div className="flex-grow-1 position-relative">
			<TextFieldComponent
				placeholder="Type Promotion Code"
				inputStyle={{ border: '1px solid #ccc', paddingLeft: `16px`, paddingRight: `16px`, color: '#000'}}
				underlineShow={false}
				className="w-100"							
				input={{
					value,
					onChange
			}}/>

			{value && <SVGInline
				className="position-absolute cursor-pointer"
				style={{ right: 16, top: '50%', transform: 'translateY(-50%)' }}
				svg={closeIcon}
				fill={'#ccc'}
				width={'1.5rem'}
				onClick={() => onChange('')}
			/>}
		</div>

		<div className="pt-2 pt-md-0 pl-0 pl-md-3">
			<Button label="Apply" className="px-5 w-100 w-md-auto" style={{ height: 48 }} onClick={() => onSubmit(value)} />
		</div>
	</div>

	{error && <div>
		<span className="service-info-form__title ml-2 text-danger">
			{error}
		</span>
	</div>}
</div>