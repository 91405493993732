import { LOG_OUT_SUCCESS } from './auth'
import {routes} from "../../utils/constants";
import {PERSONAL_INFO_FAIL, PERSONAL_INFO_START, PERSONAL_INFO_SUCCESS} from "./register";

export const CURRENT_USER_GET_START = 'user/CurrentUserGetStart'
export const CURRENT_USER_SUCCESS = 'user/CurrentUserGetSuccess'
export const CURRENT_USER_GET_FAIL = 'user/CurrentUserGetFail'

export const EMAIL_VERIFY_START = 'user/EmailVerifyStart'
export const EMAIL_VERIFY_SUCCESS = 'user/EmailVerifySuccess'
export const EMAIL_VERIFY_FAIL = 'user/EmailVerifyFail'

export const EMAIL_RESEND_START = 'user/EmailResendStart'
export const EMAIL_RESEND_SUCCESS = 'user/EmailResendSuccess'
export const EMAIL_RESEND_FAIL = 'user/EmailResendFail'

export const EMAIL_CHANGE_START = 'user/EmailChangeStart'
export const EMAIL_CHANGE_SUCCESS = 'user/EmailChangeSuccess'
export const EMAIL_CHANGE_FAIL = 'user/EmailChangeFail'

export const CANCEL_ACCOUNT_START = 'user/CancelAccountStart'
export const CANCEL_ACCOUNT_SUCCESS = 'user/CancelAccountSuccess'
export const CANCEL_ACCOUNT_FAIL = 'user/CancelAccountFail'

export const CONFIRM_CANCEL_ACCOUNT_START = 'user/ConfirmCancelAccountStart'
export const CONFIRM_CANCEL_ACCOUNT_SUCCESS = 'user/ConfirmCancelAccountSuccess'
export const CONFIRM_CANCEL_ACCOUNT_FAIL = 'user/ConfirmCancelAccountFail'

export const CURRENT_PROFILE_GET_START = 'user/CurrentProfileGetStart'
export const CURRENT_PROFILE_SUCCESS = 'user/CurrentProfileGetSuccess'
export const CURRENT_PROFILE_GET_FAIL = 'user/CurrentProfileGetFail'

export const SAVE_SETTINGS_START = 'user/SaveSettingsStart'
export const SAVE_SETTINGS_SUCCESS = 'user/SaveSettingsSuccess'
export const SAVE_SETTINGS_FAIL = 'user/SaveSettingsFail'

export const KYC_START = 'user/StartKYC'
export const KYC_SUCCESS = 'user/SuccessKYC'
export const KYC_FAIL = 'user/FailKYC'


const initialState = {
	isGettingUser: false,
  isResendingActivationEmail: false,
  isCancelingAccount: false,
  isConfirmingCancelAccount: false,
  isSavingCookiesSettings: false,
  currentKYC: false,
	currentProfile: {
		id: null,
		tag_list: [],
		rating_statistic: {},
		reviews: [],
		videos: [],
		audios: [],
		pictures: [],
		fee_primary: {},
		fee_secondary: {},
	},
}

export default function user(state = initialState, action = {}) {
	switch (action.type) {
		case CURRENT_USER_GET_START:
			return {
				...state,
				isGettingUser: true,
			}
		case CURRENT_USER_SUCCESS:
			return {
				...state,
				isGettingUser: false,
				currentUser: action.result.data,
			}
		case CURRENT_USER_GET_FAIL:
			return {
				...state,
				isGettingUser: false,
			}
		case EMAIL_VERIFY_START:
			return {
				...state,
				isVerifyingEmail: true,
			}
		case EMAIL_VERIFY_SUCCESS:
			return {
				...state,
				isVerifyingEmail: false,
			}
		case EMAIL_VERIFY_FAIL:
			return {
				...state,
				isVerifyingEmail: false,
			}
		case EMAIL_RESEND_START:
			return {
				...state,
				isResendingActivationEmail: true,
			}
		case EMAIL_RESEND_SUCCESS:
			return {
				...state,
				isResendingActivationEmail: false
			}
		case EMAIL_RESEND_FAIL:
			return {
				...state,
				isResendingActivationEmail: false
			}

		case CANCEL_ACCOUNT_START:
			return {
				...state,
        isCancelingAccount: true,
			}
		case CANCEL_ACCOUNT_SUCCESS:
			return {
				...state,
        isCancelingAccount: false
			}
		case CANCEL_ACCOUNT_FAIL:
			return {
				...state,
        isCancelingAccount: false
			}

    case SAVE_SETTINGS_START:
      return {
        ...state,
        isSavingCookiesSettings: true,
      }
    case SAVE_SETTINGS_SUCCESS:
      return {
        ...state,
        isSavingCookiesSettings: false
      }
    case SAVE_SETTINGS_FAIL:
      return {
        ...state,
        isSavingCookiesSettings: false
      }

		case CONFIRM_CANCEL_ACCOUNT_START:
			return {
				...state,
        isConfirmingCancelAccount: true,
			}
		case CONFIRM_CANCEL_ACCOUNT_SUCCESS:
			return {
				...state,
        isConfirmingCancelAccount: false
			}
		case CONFIRM_CANCEL_ACCOUNT_FAIL:
			return {
				...state,
        isConfirmingCancelAccount: false
			}

		case CURRENT_PROFILE_GET_START:
			return {
				...state,
				isGettingProfile: true,
			}
		case CURRENT_PROFILE_SUCCESS:
			return {
				...state,
				isGettingProfile: false,
				currentProfile: action.result.data,
			}
		case CURRENT_PROFILE_GET_FAIL:
			return {
				...state,
				isGettingProfile: false,
            }
        case KYC_START:
            return {
                ...state,
            }
        case KYC_SUCCESS:
            return {
                ...state,
                currentKYC: action.result.data,
            }
        case KYC_FAIL:
            return {
                ...state,
            }
		case LOG_OUT_SUCCESS:
			return {
				...state,
				currentUser: null,
				currentProfile: {
					tag_list: [],
					rating_statistic: {},
					reviews: [],
					videos: [],
					audios: [],
					pictures: [],
					fee_primary: {},
					fee_secondary: {},
				},
			}
		default:
			return state
	}
}

export function verifyEmail(token) {
	return {
		types: [EMAIL_VERIFY_START, EMAIL_VERIFY_SUCCESS, EMAIL_VERIFY_FAIL],
		promise: client => client.post(`/api/verify-email?verification_token=${token}`),
	}
}

export function resendEmail(userId) {
	return {
		types: [EMAIL_RESEND_START, EMAIL_RESEND_SUCCESS, EMAIL_RESEND_FAIL],
		promise: client => client.post(`/api/resend-email?id=${userId}`),
	}
}

export function changeEmail(data) {
  return {
    types: [EMAIL_CHANGE_START, EMAIL_CHANGE_SUCCESS, EMAIL_CHANGE_FAIL],
    promise: client => client.post(`/api/change-account-email`, data),
  }
}

export function sendCancellationAccountRequest(userId) {
  return {
    types: [CANCEL_ACCOUNT_START, CANCEL_ACCOUNT_SUCCESS, CANCEL_ACCOUNT_FAIL],
    promise: client => client.post(`/api/cancel-account?id=${userId}`),
  }
}

export function confirmAccountCancellation(token, password) {
  return {
    types: [CONFIRM_CANCEL_ACCOUNT_START, CONFIRM_CANCEL_ACCOUNT_SUCCESS, CONFIRM_CANCEL_ACCOUNT_FAIL],
    promise: client => client.delete(`/api/confirm-cancel-account?confirmation_token=${token}`,
      {
        data: {
          password: password
        }
      }),
  }
}

export function getCurrentUser() {
	return {
		types: [CURRENT_USER_GET_START, CURRENT_USER_SUCCESS, CURRENT_USER_GET_FAIL],
		promise: client => client.get(`/api/users/me?include=uploadedPictures,tags,services,styles,categories,mediaItems,countries,plan,cookiesSettings`),
		// promise: client => client.get(`/api/users/1239/profile`),
	}
}

export function getCurrentProfile(id) {
	return {
		types: [CURRENT_PROFILE_GET_START, CURRENT_PROFILE_SUCCESS, CURRENT_PROFILE_GET_FAIL],
		promise: client => client.get(`/api/users/${id}/profile`),
	}
}

export function getCurrentKYC() {
    return {
		types: [KYC_START, KYC_SUCCESS, KYC_FAIL],
		promise: client => client.get('/api/kyc'),
	}
}

export function saveCookiesSettings(data) {
  return {
    types: [SAVE_SETTINGS_START, SAVE_SETTINGS_SUCCESS, SAVE_SETTINGS_FAIL],
    promise: (client) => client.post(`/api/save-cookies-settings`, data),
  };
}
