import React, { Component } from 'react';
import { withGoogleMap, GoogleMap, Marker } from 'react-google-maps';
import { compose, withProps } from 'recompose';
import { getSearchParamsFromLocation } from '../../helpers/geocoder';

class ProfileMap extends Component{
  constructor(props){
    super(props);
    this.state = {};
  }
  componentDidMount(){
    const { profile } = this.props;
    const location = profile.location ? `${profile.location.country} ${profile.location.city} ${profile.location.street}` : undefined;
    getSearchParamsFromLocation(location)
      .then((result)=>{
        this.setState({
          location: result.geometry.location
        });
      })
      .catch(console.log);
  }

  render(){
    const { location } = this.state;
    const zoom = location ? 16 : 1;
    const center = location ? location : { lat: 1, lng: 1 };
    const options = {
      zoomControl: false,
      mapTypeControl: false,
      scaleControl: false,
      streetViewControl: false,
      rotateControl: false,
      fullscreenControl: false
    };

    return (
      <div>
        <GoogleMap zoom={zoom}
          options={options}
          center={center}>
          {location && <Marker position={location} />}
        </GoogleMap>
      </div>
    );
  }
}

export default compose(
  withProps({
    loadingElement: <div style={{ height: `100%` }} />,
    containerElement: <div style={{ height: `200px` }} />,
    mapElement: <div style={{ height: `100%` }} />,
  }),
  withGoogleMap
)(ProfileMap);
