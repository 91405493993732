import { combineReducers } from 'redux'
import { reducer as formReducer } from 'redux-form'
import * as _ from 'lodash'

import gig from './gig'
import auth, { LOG_OUT_SUCCESS } from './auth'
import user from './user'
import media from './media'
import search from './search'
import common from './common'
import profile from './profile'
import register from './register'
import discover from './discover'
import globalConfig from './globalConfig'
import compare from './compare'
import dashboard from './dashboard'
import inbox from './inbox'
import payments from './payments'
import contracts from './contracts'
import favorites, { ADD_TO_FAVORITES_START, REMOVE_FROM_FAVORITES_START } from './favorites'
import billing from './billing'
import transactions from './transactions'
import wallet from './wallet'
import actions from './actions'
import coupons from './coupons'
import cards from './cards'
import notifications from './notifications'

const appReducer = combineReducers({
	gig,
	auth,
	user,
	media,
	common,
	search,
	compare,
	profile,
	register,
	discover,
	payments,
	globalConfig,
	dashboard,
	inbox,
	contracts,
	coupons,
	favorites,
	billing,
	transactions,
	wallet,
	actions,
	cards,
	notifications,
	form: formReducer,
  })

  const rootReducer = (state, action) => {
	if (action.type === LOG_OUT_SUCCESS) {
		// all reducers should return initialState as default
		state = undefined;
	}

	if ([REMOVE_FROM_FAVORITES_START, ADD_TO_FAVORITES_START].indexOf(action.type) > -1) {
		state = updateItemInAllModules(state, {
			targetId: action.targetId,
			is_favorite: action.type === ADD_TO_FAVORITES_START,
		})
	}

	return appReducer(state, action)
  }


export default rootReducer



// todo we dont update bulk profiles band members  now
function updateItemInAllModules(state, { targetId, ...nextProps }) {
	const profile = JSON.parse(JSON.stringify(state.profile));
	if (_.get(profile, 'bulkProfiles', []).length) {
		const itemIndex = profile.bulkProfiles.findIndex(x => x.id === targetId)
		profile.bulkProfiles[itemIndex] = { ...profile.bulkProfiles[itemIndex], ...nextProps }
	}

	if (profile.profile && profile.profile.id === targetId) {
		profile.profile = { ...profile.profile, ...nextProps }
	}

	const search = JSON.parse(JSON.stringify(state.search));
	if (_.get(search, 'usersSearchResults', []).length) {
		const targetIndex = search.usersSearchResults.findIndex(x => x.id === targetId)
		search.usersSearchResults[targetIndex] = { ...search.usersSearchResults[targetIndex], ...nextProps }
	}

	const discover = JSON.parse(JSON.stringify(state.discover));
	if (_.get(discover, 'usersByCategories', []).length) {
		const targetIndex = discover.usersByCategories.findIndex(x => x.id === targetId)
		discover.usersByCategories[targetIndex] = { ...discover.usersByCategories[targetIndex], ...nextProps }
	}

	for (const prop of ['artists', 'venues', 'production'].map(x => x+'ByCategories')) {
		if (_.get(discover[prop], 'users', []).length){
			const targetIndex = discover[prop].users.findIndex(x => x.id === targetId)
			discover[prop].users[targetIndex] = { ...discover[prop].users[targetIndex], ...nextProps }
		}
	}

	// _.forOwn(discover.spotlightInfo, (_, key) => {
	// 	const targetIndex = discover.spotlightInfo[key].findIndex(x => x.id === targetId)
	// 	discover.spotlightInfo[key][targetIndex] = { ...discover.spotlightInfo[key][targetIndex], ...nextProps }
  //   })

	//for (const prop of ['artist', 'venue', 'group']) {
	//	if (discover.spotlightInfo[prop]) {
	//		const targetIndex = discover.spotlightInfo[prop].findIndex(x => x.id === targetId)
	//		discover.spotlightInfo[prop][targetIndex] = { ...discover.spotlightInfo[prop][targetIndex], ...nextProps }
	//	}
	//}

	const favorites = JSON.parse(JSON.stringify(state.favorites))
	// console.log('favorites', favorites)

	// if (_.get(favorites, 'favoritesList', []).length) {
	// 	const targetIndex = favorites.favoritesList.findIndex(x => x.id === targetId)
	// 	favorites.favoritesList[targetIndex] = { ...favorites.favoritesList[targetIndex], ...nextProps }
	// }

	return {
		...state,
		profile,
		search,
		discover,
		favorites
	}
}
