import React, { Component } from 'react';

export default class VideoUploadBlock extends Component {
  fileChangeHandler(evt) {
    if (evt.target.files[0]) {
      console.log(evt.target.files[0]);
    } else {
      console.log('No files chosen.');
    }
  }

  render() {
    const { data } = this.props;

    return (
      <div className="VideoUploadBlock">
        <p>{data.name}</p>
        <input type="file" onChange={this.fileChangeHandler} />
      </div>
    );
  }
}
