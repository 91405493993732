/**
 * path /discover
 * shows artist base categories
 */

import React, { Component } from 'react'
import ArtistGeneralCategoryBlock from '../ArtistGeneralCategoryBlock/ArtistGeneralCategoryBlock'
import { connect } from 'react-redux'
import { get, capitalize } from 'lodash'
import Loader from '../../Loader/Loader'

import './GeneralCategories.scss'

@connect(({ discover: { artistsByCategories, productionByCategories, isFetchingArtists, isFetchingVenues } }) => ({
	artistsByCategories,
	productionByCategories,
	isFetchingArtists,
	isFetchingVenues,
}))
export default class GeneralCategories extends Component {
	constructor(props) {
		super(props)

		this.state = {
			rootCategoryName: this.props.rootCategoryName,
		}
	}

	changeCategory(categoryName) {
		this.setState({ rootCategoryName: categoryName })
	}

	render() {
		const itemsToRender = get(this.props, `${this.state.rootCategoryName}ByCategories`, [])
		const isFetching = get(this.props, `isFetching${capitalize(this.state.rootCategoryName)}`, false)

		return (
			<div className="GeneralCategories">
				{isFetching ? (
					<Loader />
				) : (
					itemsToRender.map((items, idx) => (
						<ArtistGeneralCategoryBlock
							rootCategoryName={this.state.rootCategoryName}
                            key={idx}
                            fullWidth={itemsToRender.length-1 === idx}
							onAddToCompare={this.props.addItemToCompare}
							artisticCategory={items}
							changeCategory={this.changeCategory.bind(this)}
						/>
					))
				)}
			</div>
		)
	}
}
