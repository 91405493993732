import React from 'react'
import moment from 'moment'
import {Row, Col} from 'reactstrap'
import Loader from '../../discover/Loader/Loader'
import { compose } from 'recompose'
import { connect } from 'react-redux'

import { getCategories } from '../../../redux/modules/common'
import { getCoupons } from '../../../redux/modules/coupons'
import Coupon from 'components/common/Coupon/Coupon'


@connect(
	({ common, coupons }) => ({
        categoriesMap: common.categoriesMap,
        allCoupons: coupons.allCoupons,
        isGettingCoupons: coupons.isGettingCoupons
	}),
	{
		getCategories, getCoupons
	}
)
class Coupons extends React.Component {
    componentDidMount() {
        this.props.getCoupons()
    }


    render() {
        const { allCoupons, isGettingCoupons } = this.props;

        return <Row>
            <Col
                xs={12}
                style={{ padding: '2.625rem 4.1875rem' }}
                className="d-flex justify-content-center direction-row flex-wrap">
                {
                   isGettingCoupons ?
                   <Loader /> :
                   allCoupons.map(item => <div className="p-2">
                       <Coupon key={item.id} {...item} />
                   </div>)
                }
            </Col>
        </Row>
    }
}

export default Coupons
