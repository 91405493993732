import React, { Component } from 'react'
import '../../styles/components/overlay.scss'
import SVGInline from 'react-svg-inline'
import { Promise } from 'q';
const file_upload = require('!!svg-inline-loader?classPrefix!../../assets/file_upload.svg')

export default class EditOverlay extends Component {
	constructor(props) {
		super(props)

		this.state = {
			isOpenedEditor: false,
			fileName: '',
			errorMessage: {
				url: '',
				location: '',
				title: '',
			},
			isValidating: false,
		}
	}

	getOpenEditor = () => {
		this.setState(() => ({
			isOpenedEditor: true,
		}))

		this.props.isDraggble(true)
	}

	getUploadFile = evt => {
		console.log('getUploadFile Edidt', evt.target)
		this.setState({
			fileName: evt.target.files[0].name,
		})
	}

	deleteMedia = () => {
		const {
			isDraggble,
			submitHandler,
			media: { index },
		} = this.props
		this.setState({
			isOpenedEditor: false,
		})

		submitHandler(index)

		isDraggble(false)
	}

	defaultValidator = (data) => {
		return Promise.resolve(data)
	} 

	editMedia = () => {
		const { media, isDraggble, typeContent, submitHandler, validate = this.defaultValidator } = this.props

		const data = {
			url: this.refs.urlInput.value,
			location: this.refs.titleInput.value.replace(/[@#]+/g, ''),
			hashtags: this.refs.hashtagsInput.value
						.split(/\s*,\s*|\s+/g)
						.filter(tag => !!tag)
						.map(tag => ({ content: tag.replace(/[@#]+/g, '') }))
						.filter(tag => !!tag.content)
						.slice(0, 5)
		}

		console.log('editMedia', data)

		this.setState({ isValidating: true })
		validate({ ...data, input: this.refs.urlInput }).then(
			res => {
				this.setState({ isValidating: false })

				submitHandler(
					this.refs.urlInput,
					{
						...media,
						edited: true,
						location: data.location,
						hashtags: data.hashtags
					},
					media.index
				)

				setTimeout(() => {
					this.setState(() => ({
						isOpenedEditor: false,
					}))
		
					isDraggble(false)
				})

			},
			errors => {
				this.setState({ isValidating: false })
				if (errors && Object.keys(errors).length) {
					this.setState({ errorMessage: errors, });
				}
			}
		)
	}

	render() {
		const { children, media, typeContent, placeholder } = this.props
		const { isOpenedEditor } = this.state
		const type = typeContent || media.type
		const hashtags = []
		return (
			<div className="edit-overlay">
				{isOpenedEditor ? (
					<div className="edit-add-new">
						<div className="wrapper open d-flex flex-column justify-content-between">
							<div>
								<div className="edit-add-new__close" onClick={this.deleteMedia}>
									<i className="fa fa-times" aria-hidden="true" />
								</div>
								<div>
									<input
										className="name-input"
										ref="titleInput"
										type="text"
										placeholder={type === 'video' ? '@ Video description' : 'Description'}
										defaultValue={media.title || media.location}
									/>
									{!!this.state.errorMessage.location && (
										<p className="required-error">{this.state.errorMessage.location}</p>
									)}
								</div>
								<div>
									<input
										type="text"
										ref="hashtagsInput"
										className="hashtags-input"
										placeholder="Hashtags"
										defaultValue={(() => media.hashtags.map(item => item.content).join(', '))()}
									/>
									{!!this.state.errorMessage.hashtags && (
										<p className="required-error">{this.state.errorMessage.hashtags}</p>
									)}
								</div>
								<p className="subtitle">max 5 hashtags</p>
							</div>

							{type === 'video' && <div style={{ marginBottom: '12px' }}>{children}</div>}

							<div className="media-video-link">
								<div className="wrapper-url">
									{type === 'video' && (
										<button style={{ border: 'none' }} onClick={this.editMedia}>
											<SVGInline svg={file_upload} fill="#000" width="1rem" height="1rem" />
										</button>
									)}

									{type === 'picture' ? (
										<label>
											{this.state.fileName ? (
												<span className="full-url">{this.state.fileName}</span>
											) : (
												<span className="unfull-url">Upload file</span>
											)}
											<input
												defaultValue={media.content_url}
												className={`${type}-url`}
												onChange={this.getUploadFile}
												ref="urlInput"
												type={type === 'picture' ? 'file' : 'text'}
											/>
										</label>
									) : (
										<label style={{ border: `${type === 'picture' ? '1px' : 'none'}` }}>
											<input
												ref="urlInput"
												className={`${type}-url`}
												defaultValue={media.content_url}
												placeholder={`Add here link to ${type}`}
												type="text"
											/>
										</label>
									)}
									{type !== 'video' && <button className="add-btn" onClick={this.editMedia} />}
								</div>
								<p className="required-error">{this.state.errorMessage.url}</p>
							</div>
						</div>
					</div>
				) : (
					<div style={{ position: 'relative', height: '100%' }}>
						{type !== 'video' && children}
						<div
							className={`edit-overlay__wrapper d-flex flex-column justify-content-between
							align-items-start noselect ${type === 'video' ? 'video-overlay' : ''}`}
						>
							<div style={{ width: '100%', zIndex: 400 }}>
								<h3 className="d-flex">
									<strong
										style={{
											overflow: 'hidden',
											textOverflow: 'ellipsis',
											wordBreak: 'break-all',
											overflowWrap: 'break-word;',
										}}
									>
										{media.title || media.location}
									</strong>
								</h3>

								<p className="d-flex flex-wrap tags-list">
									{media.hashtags.map((item, key) => (
										<span key={key} style={{ marginRight: '0.5rem', color: '#fff' }}>
											{item.content},
										</span>
									))}
								</p>
							</div>
							{type === 'video' && <div style={{ width: '100%', margin: '0' }}>{children}</div>}
							<button className="upload-button" onClick={this.getOpenEditor}>
								{type === 'video' ? (
									<SVGInline
										svg={file_upload}
										fill="#000"
										width="1rem"
										height="1rem"
										style={{ marginRight: '.5rem' }}
									/>
								) : (
									<SVGInline svg={file_upload} fill="#fff" width="1rem" height="1rem" style={{ marginRight: '1rem' }} />
								)}
								Change {type}
							</button>
						</div>
					</div>
				)}
			</div>
		)
	}
}
