import React from "react";

const Answers = ({ isMobile, faqList, customStyles = {} }) =>
  (
    <div className='d-flex' style={{ marginTop: '24px' }}>
      <div className="faq-section" style={{ flexGrow: 1 }}>
        <div className="mb-4 faq-components">
          {
            faqList.sort((a, b) => a.order - b.order).map((k, i) => (
              <div key={i} className="faq-component">
                <span style={{ 'fontWeight': 'bold', ...customStyles }}>{k.question}</span>
                <div>
                  <p style={customStyles}>{k.answer}</p>
                </div>
              </div>
              )
            )
          }
        </div>
      </div>
    </div>
  )

export default Answers
