import React, { Component } from 'react'
import PropTypes from 'prop-types'

import isEmpty from 'lodash/isEmpty'
import {connect} from 'react-redux'
import notification from 'antd/es/notification'
import 'antd/es/notification/style/css'
import { withRouter } from 'react-router'
import { getPlansList, changePlan } from '../../../redux/modules/dashboard'
import PlansList from './List/PlansList'
import PlansHeader from './Header/PlansHeader'
import PlansFooter from './Footer/PlansFooter'
import PlansMobile from './Mobile/PlansMobile'
import queryString from 'query-string';
import './Plans.scss'
import Modal from '../../common/Modal/Modal';

import Button from '../../common/Button/Button';
import Spinner from '../../common/Spinner/Spinner';

import { createCard, createCardStep2, createBankAccount } from '../../../redux/modules/cards';
import { getCurrentUser } from '../../../redux/modules/user';

@withRouter
@connect(({ dashboard, user, globalConfig }) => ({
	plans: dashboard.plans,
	isGettingPlans: dashboard.isGettingPlans,
    currentUser: user.currentUser,
    isMobile: globalConfig.isMobile,
}),
{ getPlansList, changePlan, createCard, createCardStep2, createBankAccount, getCurrentUser })
class Plans extends Component {
	constructor(props) {
		super(props);

		this.state = {
			plans: null,
			oldPlan: null,
			activePlan: null,
      allPlans: null,
			showConfirmationDialog: false,

			showPaymentDialog: false,
			showPaymentDialogLoading: false,

			selectedPaymentMethod: null,
			paymentInfo: null,
		}

	}

	componentDidMount() {
		const { plans, getPlansList, currentUser, changePlan, history, location } = this.props;
		console.log(this.props)
		const queryStringParams = queryString.parse(location.search);

		const getActivePlanId = (plansArr) => {
			if (queryStringParams.data) {
				return +queryStringParams.plan_id
			}

			const defaultPlan = plansArr.find(plan => plan.current)
			return defaultPlan ? defaultPlan.id : null
		};

		//when user returns from paying by card
		if (queryStringParams.transactionId) {
			this.setState({ activePlan: queryStringParams.plan_id }, () => {
				this.handleChangePlanAfterPaymentRedirect();
			});
		}

		// when we create new bank account user is redirected to mangopay to create mandate
		// and then back us and now we can pay
		if (queryStringParams.account_id && queryStringParams.MandateId) {
			this.setState({ activePlan: queryStringParams.plan_id }, () => {
				this.handleChangePlanAfterPaymentRedirect();
			})
		}

		if (isEmpty(plans)) {
			getPlansList()
				.then(res => {
					this.setState({
						plans: res.data,
						activePlan: getActivePlanId(res.data.meta.price),
            allPlans: res.data.meta.price,
					})
				})
				.catch(err => console.log(err))
		} else {
			this.setState({
				plans,
				activePlan: getActivePlanId(plans.meta.price),
        allPlans: plans.meta.price,
      });
		}
	}

	handleChangePlanAfterPaymentRedirect = (success = true, payAgain = false) => {
		const { getCurrentUser, changePlan, history } = this.props;
		const { activePlan } = this.state;

		if (success) {
			notification.success({
				message: 'Success!',
				description: 'You have new plan now!',
			});
			history.push({ search: ''  })
		} else {
			notification.error({
				message: 'Error!',
				description: `Please, try again later.`,
			});
			history.push({ search: ''  })
		}


		//changePlan({
		//	planId: activePlan,
		//}).then(_ => {
		//	getCurrentUser();
		//	notification.success({
		//		message: 'Success!',
		//		description: 'You have new plan now!',
		//	});
		//}).catch(err => {
		//	const errorStatus = err.response.status;
//
		//	if (errorStatus === 400) {
		//		notification.error({
		//			message: 'Error!',
		//			description: `Please, try again later.`,
		//		});
		//	} else if (errorStatus === 409) {
		//		const message = err.response.data.split('You can change the plan ');
		//		const currentDate = moment({ hours: 0 });
		//		const nextChangeDate = moment(message[1], 'YYYY-DD-MM HH:mm:ss');
//
		//		notification.error({
		//			message: 'Error!',
		//			description: `You can change the plan in ${nextChangeDate.diff(currentDate, 'days')} days`,
		//		});
		//	} else {
		//		notification.error({
		//			message: 'Error!',
		//			description: `Please, try again later.`,
		//		});
		//	}
		//})
	};

	closeAllDialogs = () => {
		this.setState({
			showConfirmationDialog: false,
			showPaymentDialog: false,
			showPaymentDialogLoading: false,
			showSaveCardModal: false,
			showAskForSaveDialog: false,
		})
	};

	_btnClick = (id) => {
		this.setState({
			oldPlan: this.state.activePlan,
			activePlan: id
		}, () =>
      this.handleChangePlan()
    )
	};

	handleChangePlan = () => {
		const { activePlan } = this.state;
		const { changePlan } = this.props;

		changePlan({ planId: activePlan }).then(() => {
      const newPlan = this.state.allPlans.find(plan => plan.current);

      this.props.onPlanChange(newPlan);
		});
	};

	resetSelectedPlan = () => {
		this.setState({
			activePlan: this.state.oldPlan,
			oldPlan: null,
		})
	};

	closeConfirmationDialog = () => {
		this.setState({
			showConfirmationDialog: false,
		})
	};

  handleCancelPlanChange = () => {
    this.setState({
      showConfirmationDialog: false,
      activePlan: this.state.oldPlan,
      oldPlan: null,
    })
  };

	closePaymentDialog = () => {
		this.setState({
			showPaymentDialog: false,
			activePlan: this.state.oldPlan,
			oldPlan: null,
		})
	};

	closeAskForSaveDialog = () => {
		this.setState({
			showAskForSaveDialog: false,
			activePlan: this.state.oldPlan,
			oldPlan: null,
		})
	};

	closeSaveCardModal = () => {
		this.setState({
			showSaveCardModal: false,
			activePlan: this.state.oldPlan,
			oldPlan: null,
		})
	}

	closeSaveBankAccountModal = () => {
		this.setState({
			showSaveBankAccountModal: false,
			activePlan: this.state.oldPlan,
			oldPlan: null,
		})
	};

	render() {
		const {
			showPaymentDialog,
			showConfirmationDialog,
			showPaymentDialogLoading,
			selectedPaymentMethod,
			showAskForSaveDialog,
			showSaveCardModal,
			showSaveBankAccountModal,
			paymentInfo,
      activePlan,
		} = this.state;

		const askForSave = true;

		const { plans, isMobile } = this.props;
		const cachedPlans = isEmpty(plans) ? this.state.plans : plans;

		if (isEmpty(cachedPlans)) return null

		const plansBody = cachedPlans.body
        const prices = JSON.parse(JSON.stringify(cachedPlans.meta.price))

		const indexOfCurrentPlan = prices.findIndex(price => price.current)

		prices.forEach((price, index) => {
			price.disabled = indexOfCurrentPlan > index
		});

		return (
			<div className="plans">
        {isMobile ? (
          <div>
            <PlansMobile prices={prices} plans={plansBody} onClick={this._btnClick} active={activePlan} />
          </div>
        ) : (
          <div>
            <PlansHeader prices={prices} onClick={this._btnClick} active={activePlan} />
            <PlansList plans={plansBody} />
            <PlansFooter prices={prices} onClick={this._btnClick} active={activePlan} />
          </div>
        )}
				<Modal
					isOpen={showConfirmationDialog}
					onClose={this.closeConfirmationDialog}
				>
					<div style={{ padding: isMobile ? '0' : '24px', textAlign: 'center' }}>
						<p>You have selected new plan.</p>
						<p>Do you want to proceed with the change?</p>
					</div>

					<div className="d-flex justify-content-center flex-wrap flex-sm-nowrap">
						<Button
							label="Cancel"
							flat
							onClick={() => {
								this.handleCancelPlanChange();
							}}
						/>
						<Button
							label="OK"
							success
							onClick={() => {
								this.handleChangePlan({ straightToPay: true });
								this.closeConfirmationDialog();
								this.props.getCurrentUser();
							}}
						/>
					</div>
				</Modal>
			</div>
		)
	}
}

Plans.propTypes = {
	getPlansList: PropTypes.func.isRequired,
	plans: PropTypes.object.isRequired,
	isGettingPlans: PropTypes.bool.isRequired,
};

export default Plans
