import './FilterRadio.scss';
import React, { Component } from 'react';
import SVGInline from 'react-svg-inline';
import { Checkbox } from 'material-ui';

const square = require('!!svg-inline-loader?classPrefix!../../../../../assets/square.svg');

export class FilterRadio extends Component {
  selectType = () => {
    const { value, onChange } = this.props;
    onChange(value);
  };

  render() {
    const { label, value, groupName, selected } = this.props;
    const checkedIcon = <SVGInline style={{
      display: 'block',
      // border: '2px solid #ffd210',
      margin: '3px',
      width: '18px',
      height: '18px',
      overflow: 'hidden'
    }} width={'18px'} height={'18px'} svg={square} fill={'#ffd210'}/>;
    const unCheckedIcon = <SVGInline style={{
      display: 'block',
      // border: '2px solid #ededed',
      margin: '3px',
      width: '18px',
      height: '18px',
      overflow: 'hidden'
    }} width={'18px'} height={'18px'} svg={square} fill={'#ededed'}/>;

    return (
      <div className='FilterRadio'>
        <Checkbox
          label={label}
          checkedIcon={checkedIcon}
          uncheckedIcon={unCheckedIcon}
          checked={selected === value}
          name={groupName}
          onCheck={this.selectType}
        />
      </div>
    );
  }
}
