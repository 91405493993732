import React from 'react';
import { connect } from 'react-redux';
import { get } from 'lodash';
import SVGInline from 'react-svg-inline';
import {
  setClapped,
  setFollowed,
} from '../../redux/modules/profile';
import Button from "../common/Button/Button";

const svg_clap_black = require('!!svg-inline-loader?classPrefix!../../assets/profile/Clap-black.svg');
const svg_follow_black = require('!!svg-inline-loader?classPrefix!../../assets/profile/Follow-black.svg');
const svg_clap_yellow = require('!!svg-inline-loader?classPrefix!../../assets/profile/Clap-yellow.svg');
const svg_follow_yellow = require('!!svg-inline-loader?classPrefix!../../assets/profile/Follow-yellow.svg');
const svg_square = require('!!svg-inline-loader?classPrefix!../../assets/square.svg')


@connect(
	({profile}) => ({
    userId: get(profile, 'profile.id', null),
    clapped: get(profile, 'profile.clapped', false),
    followed: get(profile, 'profile.followed', false),
    isSettingClapped: get(profile, 'isSettingClapped'),
    isSettingFollowed: get(profile, 'isSettingFollowed')
  }),
	{
    setClapped,
    setFollowed,
	}
)
class MobileProfileActions extends React.Component {
  constructor(props) {
    super(props);

    const {
      clapped = false,
      followed = false,
      clappers_count,
      followers_count
    } = props;

    this.state = {
      clapped, followed, clappers_count, followers_count
    }
  }
  UNSAFE_componentWillReceiveProps(nextProps) {
    const { clapped, followed, clappers_count, followers_count } = nextProps;

    if (typeof this.state.clappers_count === 'undefined' &&
        typeof this.state.followers_count === 'undefined') {
      this.setState({ clapped, followed, clappers_count, followers_count })
    }
  }

  handleClapClick = () => {
    const { history, currentUser, userId, isSettingClapped, setClapped } = this.props;
    const { clapped } = this.state;

    if (currentUser && userId && !isSettingClapped) {
        this.setState(
          clapped ?
            { clapped: false, clappers_count: this.state.clappers_count - 1 }:
            { clapped: true, clappers_count: this.state.clappers_count + 1 },
            () => setClapped(!clapped, userId)
        )
    } else if (!currentUser) {
      history.push('/login');
    }
  }

  handleFollowClick = () => {
    const { history, currentUser, userId, isSettingFollowed, setFollowed } = this.props;
    const { followed } = this.state;

    if (currentUser && userId && !isSettingFollowed) {
      this.setState(
        followed ?
          { followed: false, followers_count: this.state.followers_count - 1 }:
          { followed: true, followers_count: this.state.followers_count + 1 },
          () => setFollowed(!followed, userId)
      )
    } else if (!currentUser) {
      history.push('/login');
    }
  }

  render() {

    const {
      clappers_count,
      followers_count,
      clapped,
      followed
    } = this.state;

    const { isKindOfArtist, onButtonToggle, openAboutSection } = this.props;

    const yellowSquareIcon = <SVGInline svg={svg_square} width="16px" fill="#ffd210" />
    const greySquareIcon = <SVGInline svg={svg_square} width="16px" fill="#989898" />

    const clapLabel = (
      <div className="action_button_label">
        <SVGInline svg={clapped ? svg_clap_yellow : svg_clap_black} width="20px" />
        Clap
      </div>);
    const followLabel = (
      <div className="action_button_label">
        { followed ? yellowSquareIcon : greySquareIcon }
        Follow
      </div>
    );
    const infoLabel = (
      <div className="action_button_label">
        { openAboutSection ? yellowSquareIcon : greySquareIcon }
        <span>Info</span>
      </div>
    );

    const mainButtonStyles = {
      cursor: 'pointer',
      width: `${100}%`,
      margin: `${1}rem ${5}px`,
      padding: '7px 10px',
      borderRadius: '5px',
      color: 'black',
      backgroundColor: 'white',
      border: '2px solid rgb(255, 210, 16)',
    };

    return (
      <div className="profile__mobile_actions">
        {/*{!isKindOfArtist && <div className="underline" />}*/}
        <Button
          className="action_button"
          label={clapLabel}
          onClick={this.handleClapClick}
          style={{...mainButtonStyles, ...{backgroundColor: clapped ? '#ffd210' : 'white'}}}
        />
        <Button
          className="action_button"
          label={followLabel}
          onClick={this.handleFollowClick}
          style={{...mainButtonStyles, ...{backgroundColor: followed ? '#ffd210' : 'white'}}}
        />
        <Button
          className="action_button"
          label={infoLabel}
          style={mainButtonStyles}
          onClick={onButtonToggle}
        />
        {/*{isKindOfArtist && <div className="underline" />}*/}
      </div>
    )
  }
}

export default MobileProfileActions;
