
const PromotionDiscountTypes = {
  Percent : 1,
  Waiver : 2,
  Cash : 3,
  CashContractMin : 4,
  PercentContractMin : 5,
  TaxWaiver : 6,
  FreeUpgrade: 7,
};

export default PromotionDiscountTypes;
