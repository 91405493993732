export function getSearchParamsFromLocation(searchLocation) {
  return ensureGoogleLoaded()
    .then(()=> new Promise((resolve, reject) => {
      const geocoder = new google.maps.Geocoder();
      geocoder.geocode({address: searchLocation}, (results, status) => {
        if (status !== 'OK') return reject(status);
        const place = results[0];
        resolve({
          geometry: place.geometry,
          searchLocation: place.formatted_address
        });
      });
    }));
}


function ensureGoogleLoaded(){
  return new Promise((resolve, reject)=>{
    if (window.google){
      resolve();
    } else {
      reject('google isnt initialized');
    }
  });
}
