import React from 'react';
import './SmolSpinner.scss';

const Spinner = ({ children, style, show }) => {
    if (show === undefined || show) {
        return <div className="SmolSpinner" style={style}>
            {children}
        </div> 
    }

    return null
}

export default Spinner;