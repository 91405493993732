import { isArray } from 'lodash'

export const FETCHING_SPOTLIGHT_INFO_START = 'FETCHING_SPOTLIGHT_INFO_START'
export const FETCHING_SPOTLIGHT_INFO_SUCCESS = 'FETCHING_SPOTLIGHT_INFO_SUCCESS'
export const FETCHING_SPOTLIGHT_INFO_FAIL = 'FETCHING_SPOTLIGHT_INFO_FAIL'

export const FETCHING_ARTISTS_CATEGORY_SERVICE_INFO_START = 'FETCHING_ARTISTS_CATEGORY_SERVICE_INFO_START'
export const FETCHING_ARTISTS_CATEGORY_SERVICE_INFO_SUCCESS = 'FETCHING_ARTISTS_CATEGORY_SERVICE_INFO_SUCCESS'
export const FETCHING_ARTISTS_CATEGORY_SERVICE_INFO_FAIL = 'FETCHING_ARTISTS_CATEGORY_SERVICE_INFO_FAIL'

export const FETCHING_VENUES_CATEGORY_SERVICE_INFO_START = 'FETCHING_VENUES_CATEGORY_SERVICE_INFO_START'
export const FETCHING_VENUES_CATEGORY_SERVICE_INFO_SUCCESS = 'FETCHING_VENUES_CATEGORY_SERVICE_INFO_SUCCESS'
export const FETCHING_VENUES_CATEGORY_SERVICE_INFO_FAIL = 'FETCHING_VENUES_CATEGORY_SERVICE_INFO_FAIL'

export const FETCHING_PRODUCTION_CATEGORY_SERVICE_INFO_START = 'FETCHING_PRODUCTION_CATEGORY_SERVICE_INFO_START'
export const FETCHING_PRODUCTION_CATEGORY_SERVICE_INFO_SUCCESS = 'FETCHING_PRODUCTION_CATEGORY_SERVICE_INFO_SUCCESS'
export const FETCHING_PRODUCTION_CATEGORY_SERVICE_INFO_FAIL = 'FETCHING_PRODUCTION_CATEGORY_SERVICE_INFO_FAIL'

export const FETCHING_USERS_BY_CATEGORIES_START = 'FETCHING_USERS_BY_CATEGORIES_START'
export const FETCHING_USERS_BY_CATEGORIES_SUCCESS = 'FETCHING_USERS_BY_CATEGORIES_SUCCESS'
export const FETCHING_USERS_BY_CATEGORIES_FAIL = 'FETCHING_USERS_BY_CATEGORIES_FAIL'

export const FETCHING_USERS_FOR_HOMEPAGE_START = 'FETCHING_USERS_FOR_HOMEPAGE_START'
export const FETCHING_USERS_FOR_HOMEPAGE_SUCCESS = 'FETCHING_USERS_FOR_HOMEPAGE_SUCCESS'
export const FETCHING_USERS_FOR_HOMEPAGE_FAIL = 'FETCHING_USERS_FOR_HOMEPAGE_FAIL'

export const FETCHING_ADS_FOR_DISCOVERY_START = 'FETCHING_ADS_FOR_DISCOVERY_START'
export const FETCHING_ADS_FOR_DISCOVERY_SUCCESS = 'FETCHING_ADS_FOR_DISCOVERY_SUCCESS'
export const FETCHING_ADS_FOR_DISCOVERY_FAIL = 'FETCHING_ADS_FOR_DISCOVERY_FAIL'

export const FETCHING_RANDOM_ADS_START = 'FETCHING_RANDOM_ADS_START'
export const FETCHING_RANDOM_ADS_SUCCESS = 'FETCHING_RANDOM_ADS_SUCCESS'
export const FETCHING_RANDOM_ADS_FAIL = 'FETCHING_RANDOM_ADS_FAIL'

export const UPDATE_FAV_OF_STORED_DISCOVER_PROFILES = 'UPDATE_FAV_OF_STORED_DISCOVER_PROFILES'

export const CLEAR_USERS_BY_CATEGORIES = 'CLEAR_USERS_BY_CATEGORIES'
export const CLEAR_ADS = 'CLEAR_ADS'

const ITEMS_PER_PAGE = 30

const initialState = {
	spotlightInfo: {},
	artistsByCategories: [],
	isFetchingArtists: false,
	venuesByCategories: [],
	isFetchingVenues: false,
	productionByCategories: [],
	isFetchingProduction: false,
	usersByCategories: [],
	isFetchingUsersByCategories: false,
	isFetchingUsersByCategoriesWhenReplace: false,

	isFetchingHomepageUsers: false,
	homepageUsers: [],
	homepageVenues: [],

  isFetchingAds: false,
  adsByCategory: [],
  randomAds: [],
}

const getNameForFetchingUsersByCategories = withReplace =>
	`isFetchingUsersByCategories${withReplace ? 'WhenReplace' : ''}`

export default function discover(state = initialState, action = {}) {
	switch (action.type) {
		case FETCHING_SPOTLIGHT_INFO_SUCCESS:
			return {
				...state,
				spotlightInfo: action.result.data,
			}

		case FETCHING_ARTISTS_CATEGORY_SERVICE_INFO_START:
			return {
				...state,
				isFetchingArtists: true,
			}

		case FETCHING_ARTISTS_CATEGORY_SERVICE_INFO_SUCCESS:
			return {
				...state,
				artistsByCategories: action.result.data,
				isFetchingArtists: false,
			}

		case FETCHING_ARTISTS_CATEGORY_SERVICE_INFO_FAIL:
			return {
				...state,
				isFetchingArtists: false,
			}

		case FETCHING_VENUES_CATEGORY_SERVICE_INFO_START:
			return {
				...state,
				isFetchingVenues: true,
			}

		case FETCHING_VENUES_CATEGORY_SERVICE_INFO_SUCCESS:
			return {
				...state,
				venuesByCategories: action.result.data,
				isFetchingVenues: false,
			}

		case FETCHING_VENUES_CATEGORY_SERVICE_INFO_FAIL:
			return {
				...state,
				isFetchingVenues: false,
			}

		case FETCHING_PRODUCTION_CATEGORY_SERVICE_INFO_START:
			return {
				...state,
				isFetchingProduction: true,
			}

		case FETCHING_PRODUCTION_CATEGORY_SERVICE_INFO_SUCCESS:
			return {
				...state,
				productionByCategories: action.result.data,
				isFetchingProduction: false,
			}

		case FETCHING_PRODUCTION_CATEGORY_SERVICE_INFO_FAIL:
			return {
				...state,
				isFetchingProduction: false,
			}

		case FETCHING_USERS_BY_CATEGORIES_START:
			return {
				...state,
				[getNameForFetchingUsersByCategories(action.withReplace)]: true,
			}
		case FETCHING_USERS_BY_CATEGORIES_SUCCESS:
			var returnArr = []

			if (action.result.status === 204) {
				return {
					...state,
					isFetchingUsersByCategories: false,
					usersByCategories: [],
				}
			}

			if (!isArray(action.result.data)) returnArr = action.result.data.data

			return {
				...state,
				[getNameForFetchingUsersByCategories(action.withReplace)]: false,
				usersByCategories: action.withReplace ? returnArr : state.usersByCategories.concat(returnArr),
			}

		case FETCHING_USERS_BY_CATEGORIES_FAIL:
			return {
				...state,
				[getNameForFetchingUsersByCategories(action.withReplace)]: false,
			}

		case FETCHING_USERS_FOR_HOMEPAGE_START:
			return {
				...state,
				isFetchingHomepageUsers: true,
			}

		case FETCHING_USERS_FOR_HOMEPAGE_SUCCESS:
			return {
				...state,
				homepageUsers: action.result.data.profiles,
				homepageVenues: action.result.data.venues,
				isFetchingHomepageUsers: false,
			}

		case FETCHING_USERS_FOR_HOMEPAGE_FAIL:
			return {
				...state,
				isFetchingHomepageUsers: false,
			}

    case FETCHING_ADS_FOR_DISCOVERY_START:
      return {
        ...state,
        isFetchingAds: true,
      }

    case FETCHING_ADS_FOR_DISCOVERY_SUCCESS:
      return {
        ...state,
        adsByCategory: action.result.data,
        isFetchingAds: false,
      }

    case FETCHING_ADS_FOR_DISCOVERY_FAIL:
      return {
        ...state,
        isFetchingAds: false,
      }

    case FETCHING_RANDOM_ADS_START:
      return {
        ...state,
        isFetchingAds: true,
      }

    case FETCHING_RANDOM_ADS_SUCCESS:
      return {
        ...state,
        randomAds: action.result.data,
        isFetchingAds: false,
      }

    case FETCHING_RANDOM_ADS_FAIL:
      return {
        ...state,
        isFetchingAds: false,
      }

		case CLEAR_USERS_BY_CATEGORIES:
			return {
				...state,
				usersByCategories: [],
			}

    case CLEAR_ADS:
      return {
        ...state,
        adsByCategory: [],
        randomAds: [],
      }

		case UPDATE_FAV_OF_STORED_DISCOVER_PROFILES:
			return {
				...state,
			}

		default:
			return state
	}
}

//TODO change route name
export function getSpotlightInfo() {
	return {
		types: [FETCHING_SPOTLIGHT_INFO_START, FETCHING_SPOTLIGHT_INFO_SUCCESS, FETCHING_SPOTLIGHT_INFO_FAIL],
		promise: client => client.get('/api/discover/spotlight'),
	}
}

export function getArtistsByCategories() {
	return {
		types: [
			FETCHING_ARTISTS_CATEGORY_SERVICE_INFO_START,
			FETCHING_ARTISTS_CATEGORY_SERVICE_INFO_SUCCESS,
			FETCHING_ARTISTS_CATEGORY_SERVICE_INFO_FAIL,
		],
		promise: client => client.get('/api/discover/artists'),
	}
}

export function getVenuesByCategories() {
	return {
		types: [
			FETCHING_VENUES_CATEGORY_SERVICE_INFO_START,
			FETCHING_VENUES_CATEGORY_SERVICE_INFO_SUCCESS,
			FETCHING_VENUES_CATEGORY_SERVICE_INFO_FAIL,
		],
		promise: client => client.get('/api/discover/venues'),
	}
}

export function getProductionByCategories() {
	return {
		types: [
			FETCHING_PRODUCTION_CATEGORY_SERVICE_INFO_START,
			FETCHING_PRODUCTION_CATEGORY_SERVICE_INFO_SUCCESS,
			FETCHING_PRODUCTION_CATEGORY_SERVICE_INFO_FAIL,
		],
		promise: client => client.get('/api/discover/production'),
	}
}

export function getUsersByCategories(categoryIds, stylesIds, page = 1, withReplace = false, fromView = '') {
	const category_ids = isArray(categoryIds) ? categoryIds.join() : categoryIds
	const styles_ids = isArray(stylesIds) ? stylesIds.join() : stylesIds
	const styles_param = styles_ids ? `&styles_ids=${styles_ids}` : ''

	return {
		withReplace,
		types: [
			FETCHING_USERS_BY_CATEGORIES_START,
			FETCHING_USERS_BY_CATEGORIES_SUCCESS,
			FETCHING_USERS_BY_CATEGORIES_FAIL,
		],
		promise: client =>
			client.get(`/api/discover/users?category_ids=${category_ids}` + styles_param + `&page=${page}&per_page=${ITEMS_PER_PAGE}&profile_type=${fromView}`),
	}
}

export function getAllUsersByMaincategory(categoryId, page = 1, withReplace = false, fromView = '') {
	return {
		withReplace,
		types: [
			FETCHING_USERS_BY_CATEGORIES_START,
			FETCHING_USERS_BY_CATEGORIES_SUCCESS,
			FETCHING_USERS_BY_CATEGORIES_FAIL,
		],
		promise: client => client.get(`/api/discover/users/${categoryId}?page=${page}&per_page=${ITEMS_PER_PAGE}&profile_type=${fromView}`),
	}
}

export function clearUsersByCategories() {
	return {
		type: CLEAR_USERS_BY_CATEGORIES,
	}
}

export function getUsers() {
	return {
		types: [FETCHING_USERS_FOR_HOMEPAGE_START, FETCHING_USERS_FOR_HOMEPAGE_SUCCESS, FETCHING_USERS_FOR_HOMEPAGE_FAIL],
		promise: client => client.get(`/api/users/home`),
	}
}

export function getAdsBy(section, categoryName) {
  return {
    types: [FETCHING_ADS_FOR_DISCOVERY_START, FETCHING_ADS_FOR_DISCOVERY_SUCCESS, FETCHING_ADS_FOR_DISCOVERY_FAIL],
    promise: client => client.get(`/api/advertising/${section}?categoryName=${categoryName}`),
  }
}

export function getRandomAdsBy(section) {
  return {
    types: [FETCHING_RANDOM_ADS_START, FETCHING_RANDOM_ADS_SUCCESS, FETCHING_RANDOM_ADS_FAIL],
    promise: client => client.get(`/api/advertising/random/${section}`),
  }
}

export function clearAds() {
  return {
    type: CLEAR_ADS,
  }
}
