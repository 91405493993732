import React, { Component } from 'react'
import TextField from 'material-ui/TextField'
import { SSL_OP_MICROSOFT_BIG_SSLV3_BUFFER } from 'constants'

const styles = {
	errorStyle: {
		color: 'orange',
	},
	floatingLabelStyle: {
		color: 'grey',
		top: '20px',
	},
	floatingLabelFocusStyle: {
		color: 'grey',
		top: '20px',
	},
	floatingLabelShrinkStyle: {
		color: 'black',
	},
	underlineFocusStyle: {
		bottom: '8px',
	},
	underlineStyle: {
		bottom: '8px',
	},
	inputStyle: {
		marginTop: '0px',
		color: 'black',
	},
	hintStyle: {
		bottom: '10px',
	},
	style: {
		height: '50px',
	},
}

const EditTerms = ({ input, label, hint, disabled, onBlur }) => (
	<TextField
		className="textFieldRenderedMaterial"
		fullWidth
		hintStyle={{
			fontSize: '1rem',
		}}
		hintText="Start writing here"
		value={input.value}
		onChange={(_, v) => input.onChange(v)}
		hintText={hint}
		floatingLabelText={label}
		floatingLabelStyle={styles.floatingLabelStyle}
		floatingLabelFocusStyle={styles.floatingLabelFocusStyle}
		floatingLabelShrinkStyle={styles.floatingLabelShrinkStyle}
		underlineFocusStyle={styles.underlineFocusStyle}
		underlineStyle={styles.underlineStyle}
		inputStyle={styles.inputStyle}
		hintStyle={styles.hintStyle}
		style={styles.style}
		disabled={disabled}
		onBlur={onBlur}
	/>
)

export default EditTerms
