import React, { Component } from 'react'
import PropTypes from 'prop-types'
import SVGInline from 'react-svg-inline'

import { compose } from 'recompose'
import { connect } from 'react-redux'
import moment from 'moment'
const applaud_icon = require('!!svg-inline-loader?classPrefix!./../../assets/applaud-icon.svg')

import SharePopUp from '../share/SharePopUp'
import { getMediaComments, addCommentToMedia } from '../../redux/modules/media'
import { setClapped } from '../../redux/modules/profile';
import { PRIMARY_COLOR } from '../../utils/constants';

class Overlay extends Component {
	constructor(props) {
		super(props);
		this.state = {
			addComment: false,
			comments: [],
			commentContent: '',
			claps_count: _.get(props, 'media.claps_count', 0),
			clapped: _.get(props, 'media.clapped', false)
		}
	}

	_openComments = () => {
		const {
			getMediaComments,
			media: { id },
		} = this.props

		this.setState({
			addComment: true,
		})

		getMediaComments(id).then(res => this.setState({ comments: res.data.data }))
	}

	_closeComments = () => this.setState({ addComment: false })

	_onSendComment = ev => {
		const {
			addCommentToMedia,
			media: { id },
		} = this.props

		const { commentContent } = this.state

		if (ev.which !== 13) return

		addCommentToMedia(id, commentContent)
			.then(({ data }) =>
				this.setState(prevState => ({
					commentContent: '',
					comments: [data.comment].concat(prevState.comments),
				}))
			)
			.catch(err => console.log(err))
	}

	clap = () => {
		const { media: { id } } = this.props;
		this.props.setClapped(true, id, 'media').then(res => {
			this.setState({
				claps_count: this.state.claps_count + 1,
				clapped: true
			})
		}, () => {
			
		});
	}

	unClap = () => {
		const { media: { id } } = this.props;
		this.props.setClapped(false, id, 'media').then(res => {
			this.setState({
				claps_count: this.state.claps_count - 1,
				clapped: false
			})
		}, () => {
			
		});
	}

	render() {
		const { media, isCommentsFetching } = this.props
		const { addComment, comments, commentContent, claps_count, clapped } = this.state

		const hashTags = media.hashtags ? media.hashtags.map(tag => `#${tag.content || tag}`).join(' ') : ''

		const comments_list = comments.length ? (
			comments.map(comment => {
				return (
					<div className="comment" key={`comment-${comment.id}`}>
						<img src={comment.commentator.profile.avatar.url} alt="avatar" />
						<div>
							<div>
								<span className="name">
									{comment.commentator.profile.first_name
										? `${comment.commentator.profile.first_name} ${comment.commentator.profile.last_name}`
										: comment.commentator.profile.name}
								</span>
								<span className="date">{moment.utc(comment.created_at).calendar()}</span>
							</div>
							<div className="content">{comment.content}</div>
						</div>
					</div>
				)
			})
		) : (
			<div className="no_comments">No comments yet</div>
		)

		return (
			<div className="overlay">
				{addComment ? (
					<div className="comments_wraper" style={addComment && { backgroundColor: 'rgba(0, 0, 0, .7)' }}>
						<div>
							<a onClick={this._closeComments}>
								<i className="media-description-close fa fa-times" aria-hidden="true" />
							</a>
							<span className="description-text">
								@<span className="semi-bold">{media.title}</span>
								<br />
								{hashTags}
								<br />
							</span>
						</div>
						<div className="add_comment">
							<div className="input-comment-container">
								<i className="fa fa-comment-o" aria-hidden="true" />
								<input
									className="description-add-comment-input"
									type="text"
									onKeyUp={this._onSendComment}
									placeholder="Write a comment"
									value={commentContent}
									onChange={ev =>
										this.setState({
											commentContent: ev.target.value,
										})
									}
								/>
							</div>
						</div>
						<div className="comments_list">{isCommentsFetching ? 'Loading...' : comments_list}</div>
					</div>
				) : (
					<div>
						<div className="activity-wrapper justify-content-between align-items-center">
							<div className="d-flex justify-content-between align-items-center">
								<div className="activity apploude" onClick={e => {									
									e.stopPropagation()
									clapped ? this.unClap() : this.clap()}
								}>
									<span style={{ fontSize: '12px' }}>
										{claps_count}
									</span>
									<SVGInline svg={applaud_icon} fill={clapped ? PRIMARY_COLOR : '#000'} width="20px" height="20px" />
								</div>
								<a className="activity comment" onClick={this._openComments}>
									<span style={{ fontSize: '12px' }}>{comments.length}</span>
									<SVGInline
										svg={require('!!svg-inline-loader?classPrefix!./../../assets/comment-icon.svg')}
										fill={'#000'}
										width="20px"
										height="20px" />
								</a>
								{true && (
									<div className="activity add-comment">
										<a style={{ fontSize: '12px' }} onClick={this._openComments}>Write a comment</a>
									</div>
								)}
							</div>
							<div>
								<a>
									<SharePopUp urlToShare={media.content_url} colorShareIcon={'#000'}/>
								</a>
							</div>
						</div>
					</div>
				)}

				{this.props.children}
			</div>
		)
	}
}

Overlay.propTypes = {
	media: PropTypes.object.isRequired,
	isCommentsFetching: PropTypes.bool.isRequired,
	getMediaComments: PropTypes.func.isRequired,
	children: PropTypes.object.isRequired,
}

const mapStateToProps = ({ media }) => {
	return {
		isCommentsFetching: media.isCommentsFetching,
	}
}

export default compose(
	connect(
		mapStateToProps,
		{ getMediaComments, addCommentToMedia, setClapped }
	)
)(Overlay)
