import React, { Component } from 'react'
import { reduxForm, Field } from 'redux-form'
import { connect } from 'react-redux'
import * as _ from 'lodash'

import apiClient from '../../../helpers/apiClient'
import '../../../styles/components/EditAudio.scss'
import { MAX_MEDIA_DESCRIPTION_LENGTH, MAX_MEDIA_HASHTAGS_COUNT } from '../../../utils/constants'
import MediaFieldWrapper from './MediaFieldWrapper'

class AudioUploadWrapper extends Component {
	constructor(props) {
		super(props);

		this.state = {
			isOpenSpotifyInstruction: false,
			isOpenSoundCloudInstruction: false,
		}
	}

	switchInstructions = type => {
		switch (type) {
			case 'soundcloud':
				this.setState({
					isOpenSoundCloudInstruction: !this.state.isOpenSoundCloudInstruction,
					isOpenSpotifyInstruction: false,
				})
				break
			case 'spotify':
				this.setState({
					isOpenSpotifyInstruction: !this.state.isOpenSpotifyInstruction,
					isOpenSoundCloudInstruction: false,
				})
				break
		}
	}

	validator = ({ title, url, hashtags, item }) => {
		const errors = {}

		const soundcloudRegex = /(https?:\/\/)?(www.)?(m\.)?soundcloud\.com\/[\w\-\.]+(\/)+[\w\-\.]+\/?/
		const spotifyRegex = /https?:\/\/(?:embed\.|open\.)(?:spotify\.com\/)(?:track\/|\?uri=spotify:track:)((\w|-){22})/
		const spotifyTrackRegex = /spotify:track:([a-zA-Z0-9]{22})/

		const urlWithoutAlbum = url.indexOf('?si=') > 0 ? url.split('?si=')[0] : url

		if (!url) {
			errors.content_url = 'Url is required'
		} else if (url && item.content_url === url) {
			// we ommit url validation because its not changed
		} else if (!(soundcloudRegex.test(url) || spotifyTrackRegex.test(url) || spotifyRegex.test(url))) {
			errors.content_url = 'Url is invalid'
		}

		if (title && title.length > MAX_MEDIA_DESCRIPTION_LENGTH){
			errors.title = 'Description is too long'
		} /*else if (!title || title.length === 0) {
			errors.title = 'Description is required'
		}*/

		if (hashtags.length > MAX_MEDIA_HASHTAGS_COUNT) {
			errors.hashtags = 'max 5 hashtags'
		}

		console.log('validator', {title, url, hashtags, item})

		if (errors.content_url || errors.hashtags){
			return Promise.reject(errors)
		}

		// we ommit url validation because its not changed
		if (url && item.content_url === url) {
			return Promise.resolve({
				thumbnail_url: item.thumbnail_url,
				content_url: item.content_url,
				artworkUrl: item.thumbnail_url,
			})
		}

		if (url) {
			return apiClient.get(`/api/media/audio/validate-url?url=${urlWithoutAlbum}`).then(
				({ data: { thumbnail_url, content_url } }) => ({ thumbnail_url, content_url }),
				err => {
					throw { content_url: err.response.data.error ?? 'This url doesn\'t contain song'}
				}
			)
		}
	}


	render() {
		return (
			<div className="social-audio-list-wrapper">
				<div className="content-reposition">
					<button className="reposition-btn">Click to reposition content</button>
				</div>

				<Field
					name="audios"
					component={(props) => <MediaFieldWrapper
						media_type={'audio'}
						validator={this.validator}
						getInnerContent={({ innerProps }) => {
							return _.get(innerProps, 'item.thumbnail_url') ?
								  <img src={innerProps.item.thumbnail_url} /> :
								  <div style={{ minHeight: '170px'}} />
						}}
						{...props}
					  />}
				/>

				<div className="spotify">
					<p>
						Upload instructions
						<i
							onClick={() => this.switchInstructions('soundcloud')}
							style={{
								color: this.state.isOpenSoundCloudInstruction ? '#f7650f' : '#000',
							}}
							className="fa fa-soundcloud d-none"
						/>
						<i
							onClick={() => this.switchInstructions('spotify')}
							style={{
								color: this.state.isOpenSpotifyInstruction ? '#81b542' : '#000',
							}}
							className="fa fa-spotify"
						/>
					</p>
					{this.state.isOpenSoundCloudInstruction && (
						<img src={require('../../../assets/images/instructions/InstructionsSoundClowd.jpg')} alt="" />
					)}
					{this.state.isOpenSpotifyInstruction && (
						<img src={require('../../../assets/images/instructions/InstructionsSpotify.jpg')} alt="" />
					)}
				</div>
			</div>
		)
	}
}

AudioUploadWrapper = reduxForm({
	form: 'EditAudio',
	destroyOnUnmount: false,
	forceUnregisterOnUnmount: false,
	enableReinitialize: true,
})(AudioUploadWrapper)

export default connect(({ user, form }) => ({
	currentUser: user.currentUser,
	profile: user.currentProfile,
	initialValues: JSON.parse(JSON.stringify(getInitialValues(form.EditAudio, user.currentProfile, 'audios'))),
}))(AudioUploadWrapper)

export function getInitialValues(form, profile, type) {
	if (form && !_.isEmpty(form.values) && form.values[type].length !== 0) {
		return form.values
	} else if (profile && profile.media_items) {
		return {
			[type]: profile.media_items
				.filter(item => item.type === type.replace(/s$/, ''))
				.sort((a, b) => a.position_index > b.position_index),
		}
	}

	return {
		[type]: [],
	}
}
