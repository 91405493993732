import { createSelector } from 'reselect';
import { get } from 'lodash';

export const createSubcategorySelector = (from = 'common.categories.all') => {
  const getCategories = state =>
    get(state, from, []);

  const getParentCategoryId = (_, owmProps) =>
    owmProps.parentCategoryId || null;

  return createSelector(
    [getCategories, getParentCategoryId],
    (categories, parentCategoryId) => {
      const categoryName = parentCategoryId.replace(/([A-Z])/g, ' $1').trim();

      const parentCategory = categories.filter(category => category.name === categoryName);
      if (Array.isArray(parentCategory) && parentCategory.length) {
        parentCategoryId = parentCategory[0].id ?? parentCategoryId;
      }

      const getPredicate = fieldName => category =>
        +category[fieldName] === +parentCategoryId;

      return {
        rootCategory: parentCategoryId
          ? categories.find(getPredicate('id'))
          : null,
        categories: parentCategoryId
          ? categories.filter(getPredicate('parent_category_id'))
          : []
      };
    }
  );
};
