export const CREATE_TRANSFER_START = 'CREATE_TRANSFER_START'
export const CREATE_TRANSFER_SUCCESS = 'CREATE_TRANSFER_SUCCESS'
export const CREATE_TRANSFER_FAIL = 'CREATE_TRANSFER_FAIL'

export const GET_TRANSFER_STATUS_START = 'GET_TRANSFER_STATUS_START'
export const GET_TRANSFER_STATUS_SUCCESS = 'GET_TRANSFER_STATUS_SUCCESS'
export const GET_TRANSFER_STATUS_FAIL = 'GET_TRANSFER_STATUS_FAIL'

export const GET_PROMOTION_INFO = 'GET_PROMOTION_INFO'

const initialState = {
	transfer: {},
	isTransferCreating: false,
	transferStatus: {},
	isGettingTransferStatus: false,
}

export default function search(state = initialState, action) {
	switch (action.type) {
		case CREATE_TRANSFER_START:
			return {
				...state,
				isTransferCreating: true,
			}

		case CREATE_TRANSFER_SUCCESS:
			return {
				...state,
				isTransferCreating: false,
				transfer: action.result.data,
			}

		case CREATE_TRANSFER_FAIL:
			return {
				...state,
				isTransferCreating: false,
			}

		case GET_TRANSFER_STATUS_START:
			return {
				...state,
				isGettingTransferStatus: true,
			}

		case GET_TRANSFER_STATUS_SUCCESS:
			return {
				...state,
				isGettingTransferStatus: false,
				transferStatus: action.result.data,
			}

		case GET_TRANSFER_STATUS_FAIL:
			return {
				...state,
				isGettingTransferStatus: false,
			}

    case GET_PROMOTION_INFO:

		default:
			return state
	}
}

export function getPromoInfo(hash, promoCode) {
  return {
    types: [GET_PROMOTION_INFO, GET_PROMOTION_INFO, GET_PROMOTION_INFO],
    promise: client => client.get(`/api/promotions/get-promo-info?hash=${hash}&code=${promoCode}`),
  }
}

export function getTransferInfo(hash, coupon = '') {
	return {
		types: [CREATE_TRANSFER_START, CREATE_TRANSFER_SUCCESS, CREATE_TRANSFER_FAIL],
		promise: client => client.get(`/api/billing/transfer?hash=${hash}&coupon=${coupon}`),
	}
}

