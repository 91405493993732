const validate = values => {
    const errors = {};

    if (!values.manager_location) {
            errors.manager_location = 'Required'
    }
    else if (!values.manager_location.street) {
        errors.manager_location = 'Street required'
    }  
    else if (!values.manager_location.city) {
        errors.manager_location = 'City required'
    }
    else if (!values.manager_location.state) {
        errors.manager_location = 'State required'
    }
    else if (!values.manager_location.postal_code) {
        errors.manager_location = 'Zip code required'
    }
    else if (!values.manager_location.country) {
        errors.manager_location = 'Country required'
    }	

    if (values.location && values.location.formatted_address && !values.location.place_id) {
		errors.location = 'You must select a city from the list'
	}
    
    return errors;
};

export default validate;
