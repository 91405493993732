import React, { Component } from 'react'
import PropTypes from 'prop-types'

import '../../styles/components/MediaBlock.scss'

import Overlay from './Overlay'
import EditOverlay from './EditOverlay'
import AudioBlock from './AudioBlock'
import VideoBlock from './VideoBlock'
import PictureBlock from './PictureBlock'

export default class MediaBlock extends Component {
	static propTypes = {
		media: PropTypes.object.isRequired,
	}

	constructor(props) {
		super(props)

		this.state = {
			isPlaying: false,
		}
	}

	onPlay = () => {
		this.setState({
			isPlaying: true,
		})
	}

	onPause = () => {
		this.setState({
			isPlaying: false,
		})
	}

	getBlock(media) {
		const { playing, loop } = this.props
		switch (media.type) {
			case 'video': {
				return <VideoBlock media={media} onPlay={this.onPlay} onPause={this.onPause} playing={playing} loop={loop} />
			}
			case 'audio': {
				return <AudioBlock media={media} onPlay={this.onPlay} onPause={this.onPause} />
			}
			case 'picture': {
				return <PictureBlock media={media} />
			}
			default: {
				return <div />
			}
		}
	}

	render() {
		const { media, hide, comments, items, isMedia, isDraggble, submitHandler, validate } = this.props
		const { isPlaying } = this.state

		return (
			<div className="media-block">
				{isMedia ? (
					<EditOverlay media={media} items={items} isDraggble={isDraggble} submitHandler={submitHandler} validate={validate}>
						{this.getBlock(media)}
					</EditOverlay>
				) : (
                    <div>
						{this.getBlock(media)}
                    </div>
                    // <Overlay media={media} hide={hide || isPlaying} comments={comments}>
					// </Overlay>
				)}
			</div>
		)
	}
}
