//Client actions

export const TRY_TO_BOOK_ARTIST_START = 'TRY_TO_BOOK_ARTIST_START'
export const TRY_TO_BOOK_ARTIST_SUCCESS = 'TRY_TO_BOOK_ARTIST_SUCCESS'
export const TRY_TO_BOOK_ARTIST_FAIL = 'TRY_TO_BOOK_ARTIST_FAIL'

export const ADD_ARTIST_TO_FAVORITE_START = 'ADD_ARTIST_TO_FAVORITE_START'
export const ADD_ARTIST_TO_FAVORITE_SUCCESS = 'ADD_ARTIST_TO_FAVORITE_SUCCESS'
export const ADD_ARTIST_TO_FAVORITE_FAIL = 'ADD_ARTIST_TO_FAVORITE_FAIL'

export const REMOVE_ARTIST_FROM_FAVORITE_START = 'REMOVE_ARTIST_FROM_FAVORITE_START'
export const REMOVE_ARTIST_FROM_FAVORITE_SUCCESS = 'REMOVE_ARTIST_FROM_FAVORITE_SUCCESS'
export const REMOVE_ARTIST_FROM_FAVORITE_FAIL = 'REMOVE_ARTIST_FROM_FAVORITE_FAIL'

export const ADD_ARTIST_TO_SHORTLIST_START = 'ADD_ARTIST_TO_SHORTLIST_START'
export const ADD_ARTIST_TO_SHORTLIST_SUCCESS = 'ADD_ARTIST_TO_SHORTLIST_SUCCESS'
export const ADD_ARTIST_TO_SHORTLIST_FAIL = 'ADD_ARTIST_TO_SHORTLIST_FAIL'

export const REMOVE_ARTIST_FROM_SHORTLIST_START = 'REMOVE_ARTIST_FROM_SHORTLIST_START'
export const REMOVE_ARTIST_FROM_SHORTLIST_SUCCESS = 'REMOVE_ARTIST_FROM_SHORTLIST_SUCCESS'
export const REMOVE_ARTIST_FROM_SHORTLIST_FAIL = 'REMOVE_ARTIST_FROM_SHORTLIST_FAIL'

export const TRY_TO_INVITE_TO_GIG_START = 'TRY_TO_INVITE_TO_GIG_START'
export const TRY_TO_INVITE_TO_GIG_SUCCESS = 'TRY_TO_INVITE_TO_GIG_SUCCESS'
export const TRY_TO_INVITE_TO_GIG_FAIL = 'TRY_TO_INVITE_TO_GIG_FAIL'

//vendors actions
export const TRY_TO_APPLY_GIG_START = 'TRY_TO_APPLY_GIG_START'
export const TRY_TO_APPLY_GIG_SUCCESS = 'TRY_TO_APPLY_GIG_SUCCESS'
export const TRY_TO_APPLY_GIG_FAIL = 'TRY_TO_APPLY_GIG_FAIL'

export const CONFIRM_APPLY_FOR_GIG_START = 'CONFIRM_APPLY_FOR_GIG_START';
export const CONFIRM_APPLY_FOR_GIG_SUCCESS = 'CONFIRM_APPLY_FOR_GIG_SUCCESS';
export const CONFIRM_APPLY_FOR_GIG_FAIL = 'CONFIRM_APPLY_FOR_GIG_FAIL';

export const DECLINE_APPLY_FOR_GIG_START = 'DECLINE_APPLY_FOR_GIG_START';
export const DECLINE_APPLY_FOR_GIG_SUCCESS = 'DECLINE_APPLY_FOR_GIG_SUCCESS';
export const DECLINE_APPLY_FOR_GIG_FAIL = 'DECLINE_APPLY_FOR_GIG_FAIL';


export const TRY_TO_BOOK_FOR_GIG_START = 'TRY_TO_BOOK_FOR_GIG_START'
export const TRY_TO_BOOK_FOR_GIG_SUCCESS = 'TRY_TO_BOOK_FOR_GIG_SUCCESS'
export const TRY_TO_BOOK_FOR_GIG_FAIL = 'TRY_TO_BOOK_FOR_GIG_FAIL'

export const CONFIRM_BOOKING_FOR_GIG_START = 'CONFIRM_BOOKING_FOR_GIG_START';
export const CONFIRM_BOOKING_FOR_GIG_SUCCESS = 'CONFIRM_BOOKING_FOR_GIG_SUCCESS';
export const CONFIRM_BOOKING_FOR_GIG_FAIL = 'CONFIRM_BOOKING_FOR_GIG_FAIL';

export const DECLINE_BOOKING_FOR_GIG_START = 'DECLINE_BOOKING_FOR_GIG_START';
export const DECLINE_BOOKING_FOR_GIG_SUCCESS = 'DECLINE_BOOKING_FOR_GIG_START';
export const DECLINE_BOOKING_FOR_GIG_FAIL = 'DECLINE_BOOKING_FOR_GIG_FAIL';

export const TRY_TO_SAVE_GIG_START = 'TRY_TO_SAVE_GIG_START'
export const TRY_TO_SAVE_GIG_SUCCESS = 'TRY_TO_SAVE_GIG_SUCCESS'
export const TRY_TO_SAVE_GIG_FAIL = 'TRY_TO_SAVE_GIG_FAIL'

const initialState = {
	isTryToApply: false,
	isTryToSave: false,
	isTryToBook: false,
	isConfirmApply: false,
	isDeclineApply: false,
	isConfirmBook: false,
	isDeclineBook: false
}

export default function actions(state = initialState, action = {}) {
	switch (action.type) {
		case TRY_TO_APPLY_GIG_START:
			return {
				...state,
				isTryToApply: true,
			}
		case TRY_TO_APPLY_GIG_SUCCESS:
			return {
				...state,
				isTryToApply: false,
			}
		case TRY_TO_APPLY_GIG_FAIL:
			return {
				...state,
				isTryToApply: false,
			}
		case CONFIRM_APPLY_FOR_GIG_START:
			return {
				...state,
				isConfirmApply: true
			}
		case CONFIRM_APPLY_FOR_GIG_SUCCESS:
			return {
				...state,
				isConfirmApply: false
			}
		case CONFIRM_APPLY_FOR_GIG_FAIL:
			return {
				...state,
				isConfirmApply: false
			}
		case DECLINE_APPLY_FOR_GIG_START:
			return {
				...state,
				isDeclineApply: true
			}
		case DECLINE_APPLY_FOR_GIG_SUCCESS:
			return {
				...state,
				isDeclineApply: false
			}
		case DECLINE_APPLY_FOR_GIG_FAIL:
			return {
				...state,
				isDeclineApply: false
			}
		case TRY_TO_INVITE_TO_GIG_START:
			return {
				...state,
				isTryToInvite: true,
			}
		case TRY_TO_INVITE_TO_GIG_SUCCESS:
			return {
				...state,
				isTryToInvite: false,
			}
		case TRY_TO_INVITE_TO_GIG_FAIL:
			return {
				...state,
				isTryToInvite: false,
			}	
		case TRY_TO_BOOK_FOR_GIG_START:
			return {
				...state,
				isTryToBook: true,
			}
		case TRY_TO_BOOK_FOR_GIG_SUCCESS:
			return {
				...state,
				isTryToBook: false,
			}
		case TRY_TO_BOOK_FOR_GIG_FAIL:
			return {
				...state,
				isTryToBook: false,
			}
		case CONFIRM_BOOKING_FOR_GIG_START:
			return {
				...state,
				isConfirmBook: true
			}
		case CONFIRM_BOOKING_FOR_GIG_SUCCESS:
			return {
				...state,
				isConfirmBook: false
			}
		case CONFIRM_BOOKING_FOR_GIG_FAIL:
			return {
				...state,
				isConfirmBook: false
			}
		case DECLINE_BOOKING_FOR_GIG_START:
			return {
				...state,
				isDeclineBook: true
			}
		case DECLINE_BOOKING_FOR_GIG_SUCCESS:
			return {
				...state,
				isDeclineBook: false
			}
		case DECLINE_BOOKING_FOR_GIG_FAIL:
			return {
				...state,
				isDeclineBook: false
			}

		case TRY_TO_SAVE_GIG_START:
			return {
				...state,
				isTryToSave: true,
			}
		case TRY_TO_SAVE_GIG_SUCCESS:
			return {
				...state,
				isTryToSave: false,
			}
		case TRY_TO_SAVE_GIG_FAIL:
			return {
				...state,
				isTryToSave: false,
			}

		default:
			return {
				...state,
			}
	}
}

export function saveGig(gigId) {
	return {
		types: [TRY_TO_SAVE_GIG_START, TRY_TO_SAVE_GIG_SUCCESS, TRY_TO_SAVE_GIG_FAIL],
		promise: client => client.post(`/api/users/gigs/${gigId}/save`),
	}
}

export function addToShortlist(userId, gigId) {
	return {
		types: [ADD_ARTIST_TO_SHORTLIST_START, ADD_ARTIST_TO_SHORTLIST_SUCCESS, ADD_ARTIST_TO_SHORTLIST_FAIL],
		promise: client => client.post(`/api/user/${userId}/gigs/${gigId}/shortlist`),
	}
}

export function removeFromShortlist(userId, gigId) {
	return {
		types: [
			REMOVE_ARTIST_FROM_SHORTLIST_START,
			REMOVE_ARTIST_FROM_SHORTLIST_SUCCESS,
			REMOVE_ARTIST_FROM_SHORTLIST_FAIL,
		],
		promise: client => client.delete(`/api/user/${userId}/gigs/${gigId}/shortlist`),
	}
}


export function applyGig(gigId) {
	return {
		types: [
			TRY_TO_APPLY_GIG_START, 
			TRY_TO_APPLY_GIG_SUCCESS,
			TRY_TO_APPLY_GIG_FAIL
		],
		promise: client => client.post(`/api/users/gigs/${gigId}/apply`),
	}
}

export function confirmApply(gigId) {
	return {
		types: [
			CONFIRM_APPLY_FOR_GIG_START,
			CONFIRM_APPLY_FOR_GIG_SUCCESS,
			CONFIRM_APPLY_FOR_GIG_FAIL
		],
		promise: client => client.post(`/api/users/gigs/${gigId}/apply`)
	}
}

export function declineApply(gigId) {
	return {
		types: [
			DECLINE_APPLY_FOR_GIG_START,
			DECLINE_APPLY_FOR_GIG_SUCCESS,
			DECLINE_APPLY_FOR_GIG_FAIL
		],
		promise: client => client.post(`/api/users/gigs/${gigId}/apply_decline`)
	}
}

export function bookForGig(gigId, userId) {
	return {
		types: [
			TRY_TO_BOOK_FOR_GIG_START,
			TRY_TO_BOOK_FOR_GIG_SUCCESS,
			TRY_TO_BOOK_FOR_GIG_FAIL,
		],
		promise: client => client.post(`/api/users/gigs/${gigId}/book?user_id=${userId}`),
	}
}

export function confirmBooking(gigId) {
	return {
		types: [
			CONFIRM_BOOKING_FOR_GIG_START,
			CONFIRM_BOOKING_FOR_GIG_SUCCESS,
			CONFIRM_BOOKING_FOR_GIG_FAIL
		],
		promise: client => client.post(`/api/users/gigs/${gigId}/book_confirm`)
	}
}

export function declineBooking(gigId) {
	return {
		types: [
			DECLINE_BOOKING_FOR_GIG_START,
			DECLINE_BOOKING_FOR_GIG_SUCCESS,
			DECLINE_BOOKING_FOR_GIG_FAIL
		],
		promise: client => client.post(`/api/users/gigs/${gigId}/book_decline`)
	}
}


/**
 * 
 * @param {number} gigId id of gig
 * @param {number} userId id of user we want to invite
 */
export function inviteToGig(gigId, userId) {
	return {
		types: [
			TRY_TO_INVITE_TO_GIG_START, 
			TRY_TO_INVITE_TO_GIG_SUCCESS,
			TRY_TO_INVITE_TO_GIG_FAIL
		],
		promise: client => client.post(`/api/users/gigs/${gigId}/invite?user_id=${userId}`),
	}
}
