import React, { Component } from 'react';
import * as _ from 'lodash'
import { reduxForm, Field } from 'redux-form';
import { connect } from 'react-redux';
import {
  CardEditor,
  PhotoEditor,
  AvatarEditorComp,
} from '../../index';
import _default from 'antd/lib/drawer';
import VideoUrlField from '../../VideoUrlField';

import {Row, Col} from 'reactstrap'
import {
  artistRegistrationProfileMediaSteps,
  venueRegistrationProfileMediaSteps,
  clientRegistrationProfileMediaSteps
} from "../../../utils/tour-guide-steps";
import Joyride from "react-joyride";
import {getRealTypeOfUser} from "../../../helpers/methods";

class WizardPhotosEditStep extends Component {
  files = {};

  constructor(props) {
    super(props);

    const profileSteps = this.getProfileSteps();

    this.state = {
      run: localStorage.getItem('registration-profile-media-tour') ? localStorage.getItem('registration-profile-media-tour') == 'true' : true,
      steps: [...[{
        target: '#root-wrapper > div > div.RegisterWizard > div.profile-edit',
        content: (
          <>
            <h3>Hello. Would you like a quick tour of the registration media area?</h3>
            <button
              style={{
                backgroundColor: "#e61e26",
                border: '0px',
                borderRadius: '4px',
                cursor: 'pointer',
                fontSize: '16px',
                lineHeight: 1,
                padding: '8px',
                appearance: 'none',
                color: "#fff"
              }}
              onClick={this.handleDisablingTourGuide}
            >
              Don't show me again this message
            </button>
          </>
        ),
        placement: "center",
        locale: { skip: "Skip" },
      },], ...profileSteps
      ]
    }
  }

  getProfileSteps = () => {
    const { currentUser } = this.props;
    const profile = currentUser && currentUser.profile_type && getRealTypeOfUser(currentUser);

    switch (profile) {
      case 'artist':
      case 'group':
        return artistRegistrationProfileMediaSteps;
      case 'venue':
        return venueRegistrationProfileMediaSteps;
      case 'client':
      case 'company':
        return clientRegistrationProfileMediaSteps;
      default:
        return [];
    }
  }

  handleDisablingTourGuide = e => {
    e.preventDefault();

    localStorage.setItem('registration-profile-media-tour', 'false');

    this.setState({
      run: false
    });
  };

  changeHandler(name, file) {
    this.files[name] = file;
  }

  render() {
    const { run, steps } = this.state;
    return (
      <div className="profile-photos-wrapper">
        <Joyride
          showProgress
          showSkipButton
          continuous
          run={run}
          steps={steps}
          styles={{
            options: {
              textColor: '#000',
              width: 450,
              zIndex: 1000,
              spotlightShadow: '0 0 15px rgba(0, 0, 0, 0.5)',
            }
          }}
        />
        <Field type="file"
          name="avatar_img"
          showValidationError={this.props.showValidationError}
          component={AvatarEditorComp}
        />

        <Field type="file"
          name="cover_img"
          showValidationError={this.props.showValidationError}
          component={PhotoEditor}
        />

          <div className="photo-editor-wrapper photo-editor-wrapper--preview">
            <div className="photo-editor-card-config">
              <div className="header">
                <div className="title">Edit your card</div>
              </div>

              <div className="content">
                <Row className="profile-picture">
                  <Col sm={6} xs={12} className="profile-picture-preview">
                    <Field type="file"
                      name="card_img"
                      showValidationError={this.props.showValidationError}
                      component={CardEditor}
                    />
                  </Col>

                  <Col sm={6} xs={12} className="profile-picture-upload">
                    <Field
                      name="card_video_url"
                      showValidationError={this.props.showValidationError}
                      component={VideoUrlField}
                    />
                  </Col>
                </Row>
              </div>
          </div>
        </div>
      </div>
    );
  }
}

WizardPhotosEditStep = reduxForm({
  form: 'EditProfilePhotos',
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: false,
  enableReinitialize: true,
})(WizardPhotosEditStep);

const getAvatarImg = (user, form) => {
  if (form && form.values && form.values.avatar_img && form.values.avatar_img.file) {
    return form.values.avatar_img;
  } else if (user && user.profile && user.profile.avatar) {
    return user.profile.avatar;
  }

  return null;
};

const getCoverImg = (user, form) => {
  if (form && form.values && form.values.cover_img && form.values.cover_img.file) {
    return form.values.cover_img;
  } else if (user && user.profile.cover_img) {
    return user.profile.cover_img;
  }

  return null;
};

const getImg = (user, form, which) => {
  if (form && form.values && form.values[which] && form.values[which].file) {
    return form.values[which];
  } else if (user && user.profile[which]) {
    return user.profile[which];
  }

  return null;
};

export default connect(
  ({ user, profile, form }) => ({
    currentUser: user.currentUser,
    initialValues: {
      avatar_img: getAvatarImg(user.currentUser, form.EditProfilePhotos),
      cover_img: getImg(user.currentUser, form.EditProfilePhotos, 'cover_img'),
      card_img: getImg(user.currentUser, form.EditProfilePhotos, 'card_img'),
      card_video_url: _.get(user, 'currentProfile.card_video_url', '')
    }
  })
)(WizardPhotosEditStep);
