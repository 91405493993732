import {
  setToken,
  removeToken,
} from '../../helpers/apiClient';
import { routes } from './../../utils/constants';

export const TALENT_INFO_START = 'register/TALENT_INFO_START';
export const TALENT_INFO_SUCCESS = 'register/TALENT_INFO_SUCCESS';
export const TALENT_INFO_FAIL = 'register/TALENT_INFO_FAIL';

export const PROFILE_INFO_START = 'register/PROFILE_INFO_START';
export const PROFILE_INFO_SUCCESS = 'register/PROFILE_INFO_SUCCESS';
export const PROFILE_INFO_FAIL = 'register/PROFILE_INFO_FAIL';

export const REGISTER_START = 'register/REGISTER_START';
export const REGISTER_SUCCESS = 'register/REGISTER_SUCCESS';
export const REGISTER_FAIL = 'register/REGISTER_FAIL';

export const TALENT_DETAILS_START = 'register/TALENT_DETAILS_START';
export const TALENT_DETAILS_SUCCESS = 'register/TALENT_DETAILS_SUCCESS';
export const TALENT_DETAILS_FAIL = 'register/TALENT_DETAILS_FAIL';

export const PERSONAL_INFO_START = 'register/PERSONAL_INFO_START';
export const PERSONAL_INFO_SUCCESS = 'register/PERSONAL_INFO_SUCCESS';
export const PERSONAL_INFO_FAIL = 'register/PERSONAL_INFO_FAIL';

export const PHOTOS_UPLOAD_START = 'register/PHOTOS_UPLOAD_START';
export const PHOTOS_UPLOAD_SUCCESS = 'register/PHOTOS_UPLOAD_SUCCESS';
export const PHOTOS_UPLOAD_FAIL = 'register/PHOTOS_UPLOAD_FAIL';

export const VIDEO_SAVE_START = 'register/VIDEO_SAVE_START';
export const VIDEO_SAVE_SUCCESS = 'register/VIDEO_SAVE_SUCCESS';
export const VIDEO_SAVE_FAIL = 'register/VIDEO_SAVE_FAIL';

export const AUDIO_SAVE_START = 'register/AUDIO_SAVE_START';
export const AUDIO_SAVE_SUCCESS = 'register/AUDIO_SAVE_SUCCESS';
export const AUDIO_SAVE_FAIL = 'register/AUDIO_SAVE_FAIL';

export const PICTURE_SAVE_START = 'register/PICTURE_SAVE_START';
export const PICTURE_SAVE_SUCCESS = 'register/PICTURE_SAVE_SUCCESS';
export const PICTURE_SAVE_FAIL = 'register/PICTURE_SAVE_FAIL';

export const REQUEST_EMAIL_VERIFICATION = 'register/REQUEST_EMAIL_VERIFICATION';
export const VERIFY_EMAIL = 'register/VERIFY_EMAIL';
export const UPDATE_PASSWORD = 'register/UPDATE_PASSWORD';

export const ACCOUNT_TYPE_START = 'register/ACCOUNT_TYPE_START';
export const ACCOUNT_TYPE_SUCCESS = 'register/ACCOUNT_TYPE_SUCCESS';
export const ACCOUNT_TYPE_FAIL = 'register/ACCOUNT_TYPE_FAIL';


const initialState = {};

export default function register(state = initialState, action = {}) {
  switch (action.type){
    case ACCOUNT_TYPE_START:
      return {
        ...state,
        isUpdatingAccountType: true,
      };
    case ACCOUNT_TYPE_FAIL:
      return {
        ...state,
        isUpdatingAccountType: false,
        accountTypeError: action.error,
      };
    case ACCOUNT_TYPE_SUCCESS:
      return {
        ...state,
        isUpdatingAccountType: false,
      };
    case REGISTER_START:
      return {
        ...state,
        isRegistering: true,
      };
    case REGISTER_FAIL:
      return {
        ...state,
        isRegistering: false,
        registerError: action.error,
      };
    case REGISTER_SUCCESS:
      setToken(action.result.data.token);

      return {
        ...state,
        isRegistering: false,
      };
    case PERSONAL_INFO_START:
      return {
        ...state,
        isUpdatingPersonalInfo: true,
      };
    case PERSONAL_INFO_FAIL:
      return {
        ...state,
        isUpdatingPersonalInfo: false,
        personalInfoError: action.error,
      };
    case PERSONAL_INFO_SUCCESS:
      return {
        ...state,
        isAuthorized: true,
        isUpdatingPersonalInfo: false,
      };
    default:
      return state;
  }
}

export function saveTalentInfo(talentInfo) {
  return {
    types: [TALENT_INFO_START, TALENT_INFO_SUCCESS, TALENT_INFO_FAIL],
    promise: (client) => client.put(`/api/users/me`, talentInfo)
  };
}

export function saveProfileInfo(data) {
  return {
    types: [PROFILE_INFO_START, PROFILE_INFO_SUCCESS, PROFILE_INFO_FAIL],
    promise: (client) => client.put(`/api/users/me`, data)
  };
}

export function saveProfileInfoPost(data) {
  return {
    types: [PROFILE_INFO_START, PROFILE_INFO_SUCCESS, PROFILE_INFO_FAIL],
    promise: (client) => client.post(`/api/users/me`, data)
  };
}

export function saveTalentDetails(talentDetails) {
  return {
    types: [TALENT_DETAILS_START, TALENT_DETAILS_SUCCESS, TALENT_DETAILS_FAIL],
    promise: (client) => client.put(`/api/users/me`, talentDetails)
  };
}

export function accountType(profileType) {
  return {
    types: [ACCOUNT_TYPE_START, ACCOUNT_TYPE_SUCCESS, ACCOUNT_TYPE_FAIL],
    promise: (client) => client.post(`/api/users/me/profile-type`, { profile_type: profileType }),
  };
}


export function verifyEmail(token) {
  return {
    types: [null, VERIFY_EMAIL, null],
    promise: (client) => client.post(`/api/users/me/verify-email`, { token })
  };
}

export function requestVerifyEmailChange(email){
  return {
    types: [null, REQUEST_EMAIL_VERIFICATION, null],
    promise: (client) => client.post(`/api/users/me/email`, { email })
  };
}

export function updatePassword(password) {
  return {
    types: [null, UPDATE_PASSWORD, null],
    promise: (client) => client.post(`/api/users/me/password`, { password })
  };
}

export function registerCall(data) {
  return {
    types: [REGISTER_START, REGISTER_SUCCESS, REGISTER_FAIL],
    promise: (client) => client.post(`/${routes.register}`, data),
  };
}

export function personalInfo(data) {
  return {
    types: [PERSONAL_INFO_START, PERSONAL_INFO_SUCCESS, PERSONAL_INFO_FAIL],
    promise: (client) => client.post(`/${routes.users}/me`, data),
  };
}

export function uploadPhotos(data) {
  return {
    types: [PHOTOS_UPLOAD_START, PHOTOS_UPLOAD_SUCCESS, PHOTOS_UPLOAD_FAIL],
    promise: (client) => client.post(`/${routes.users}/me/look`, data),
  };
}

export function saveVideos(data) {
  return {
    types: [VIDEO_SAVE_START, VIDEO_SAVE_SUCCESS, VIDEO_SAVE_FAIL],
    promise: (client) => client.post(`/${routes.users}/me/videos`, data),
  };
}

export function savePictures(data) {
  return {
    types: [PICTURE_SAVE_START, PICTURE_SAVE_SUCCESS, PICTURE_SAVE_FAIL],
    promise: (client) => client.post(`/${routes.users}/me/pictures`, data),
  };
}

export function saveAudios(data) {
  return {
    types: [AUDIO_SAVE_START, AUDIO_SAVE_SUCCESS, AUDIO_SAVE_FAIL],
    promise: (client) => client.post(`/${routes.users}/me/audios`, data),
  };
}
