import React, { Component } from 'react'

import moment from 'moment'
import isEmpty from 'lodash/isEmpty'

export default class NextGigs extends Component {
	render() {
		const { nextGigs, isOpenedFullEvents } = this.props

		if (!nextGigs.length) return null

		return (
			<div className="events__list-next-gigs">
				{nextGigs.slice(0, isOpenedFullEvents ? nextGigs.length : 3).map((item, index) => (
					<div className="events__list-next-gigs_item" key={`gig-event-${index}`}>
						<span>{moment(item.date).format('MMMM Do')}</span>
						<span>{isEmpty(item.location) ? '' : `${item.location.city}, ${item.location.country}`}</span>
					</div>
				))}
			</div>
		)
	}
}
