import { reduce, chain } from 'lodash';

export function isIOSorAndroid() {
  var userAgent = navigator.userAgent || navigator.vendor || window.opera;

  if (/android/i.test(userAgent)) {
    return true;
  }
  if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
    return true;
  }

  return false;
}

export function getValues(form, values) {
  if (form && form.values && Object.keys(form.values).length) {
    return form.values;
  } else {
    return values;
  }
}

export function cleanServices(services) {
  const toRemove = [];

  return chain(services)
    .each((service, index) => {
      let isEmpty = true;

      Object.keys(service).forEach(key => {
        if (service[key]) {
          isEmpty = false;
        }
      });

      if (isEmpty) {
        toRemove.push(index);
      }
    })
    .remove((item, index) => !toRemove.includes(index))
    .value();
}

export function filterMediaArr(arr) {
  arr.forEach((item, index) => { item.position_index = index; });

  return arr;
}

export function objectToFormData (obj, form, namespace) {
  var fd = form || new FormData();
  var formKey;

  for (var property in obj) {
    if (obj.hasOwnProperty(property) && obj[property] != null) {
      if (namespace) {
        formKey = namespace + '[' + property + ']';
      } else {
        formKey = property;
      }

      // if the property is an object, but not a File,
      // use recursivity.
      if (typeof obj[property] === 'object' && !(obj[property] instanceof File)) {

        if (Object.keys(obj[property]).length){
          objectToFormData(obj[property], fd, formKey);
        } else {
          fd.append(formKey, obj[property]);
        }

      } else {
        // if it's a string or a File object
        if (obj[property] != null){
          fd.append(formKey, obj[property]);
        }
      }
    }
  }

  return fd;
}

/**
 * workaround
 * @param user
 */
export function getRealTypeOfUser(user) {
  if (!user){
    return;
  }
  switch (user.profile_type){
    case 'artist' : {
      return 'artist';
    }
    case 'venue' : {
      return 'venue';
    }
    case 'group' : {
      return user.profile_subtype === 'basic' ? 'group' : user.profile_subtype;
    }
    case 'client': {
      return user.profile_subtype === 'basic' ? 'client' : user.profile_subtype;
    }
    case 'fan' : {
      return 'fan';
    }
  }
}

export function adjustQuestionsAnswersResponse(arr) {
  if (arr) {
    const newArr = [];
    for (let i = 0; i < arr.length; i++) {
      let item = arr[i];
      let q = {
        name: '',
        question: item.question_id,
        questions: [],
        answer: item.answer,
        isEntered: true
      }
      newArr.push(q);
    }
    arr = newArr;
  }
  return arr;
}

export function reduceObjectsToIds(array) {
  const newArr = [];
  array.forEach(v => {
    newArr.push(v.id);
  })

  return newArr;
}