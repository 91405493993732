import React, { Component } from 'react';
import Slider from 'material-ui/Slider';
import AvatarEditor from 'react-avatar-editor';
import { SliderComponent } from '../components/register/CommonComponent';

import '../styles/components/AvatarEditor.scss';
import { Asterisk } from './common/Asterisk/Asterisk'

const MAX_FILE_SIZE_IN_MB = 10;
export default class AvatarEditorComp extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  getDataURL(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => {
        resolve(reader.result);

        reader.onload = null;
      };
      reader.readAsDataURL(file);
    });
  }

  fileChangeHandler = (evt) => {
    const files = evt.target.files;
    const maxFileSize = MAX_FILE_SIZE_IN_MB * 1024 * 1024;

    if (files[0]) {
      if (files[0].size < maxFileSize){
        this.getDataURL(files[0]).then((data) => {
          this.fileChanged = true;

          this.setState({
            file: files[0],
            photo: data,
          });
        });
      } else {
        this.props.showValidationError(`${MAX_FILE_SIZE_IN_MB} MB file size exceeded`);
      }
    }
  };

  zoomChangeHandler = (evt, val) => {
    const { input: { onChange, value } } = this.props;

    if (this.state.photo) {
      this.positionChangeHandler({
        x: +value.x,
        y: +value.y,
      }, val - 1);
    }
  };

  positionChangeHandler = (position, zoom) => {
    const { input: { onChange, value } } = this.props;

    let result = {
      ...value,
    };

    if (position) {
      const img = this.editor.getImage();
      const absolutePosition = this.editor.calculatePosition();

      const maxX = this.originalSize.width - img.width;
      const maxY = this.originalSize.height - img.height;

      let offset_x = Math.round(position.x * this.originalSize.width - (img.width / 2));
      let offset_y = Math.round(position.y * this.originalSize.height - (img.height / 2));

      if (offset_x > maxX) {
        offset_x = maxX;
      } else if (offset_x < 0) {
        offset_x = 0;
      }

      if (offset_y > maxY) {
        offset_y = maxY;
      } else if (offset_y < 0) {
        offset_y = 0;
      }

      result = {
        ...result,
        ...position,
        scale: img.width / Math.min(this.originalSize.width, this.originalSize.height),
        offset_x,
        offset_y,
      };
    } else {
      result = {
        x: 0.5,
        y: 0.5,
        zoom: 0,
        scale: 1,
        offset_x: 0,
        offset_y: 0,
        ...value,
      };
    }

    if (zoom || zoom === 0) {
      result.zoom = zoom;
    }

    if (this.fileChanged) {
      result.file = this.state.file;
    }

    onChange(result);
  };

  loadSuccessHandler = (info) => {
    this.originalSize = {
      width: info.resource.width,
      height: info.resource.height,
    };

    this.positionChangeHandler();

    setTimeout(() => this.forceUpdate());
  };

  getCropper = (isMain, radius) => {
    const { photo } = this.state;
    const { input: { value } } = this.props;
    let scale = (value && +value.zoom) ? +value.zoom + 1 : 1.25;
    scale = (scale < 1.25) ? 1.25 : scale;

    return (
      <div className='canvas-wrapper'>
        <AvatarEditor
          ref={ref => {
            if (isMain) {
              this.editor = ref;
            }
          }}
          image={photo}
          width={this.editorContainer ? this.editorContainer.clientWidth - 100 : 225}
          height={this.editorContainer ? this.editorContainer.clientHeight - 100 : 225}
          borderRadius={radius}
          border={50}
          color={[255, 255, 255, 0.95]} // RGBA
          scale={scale/*value && value.zoom ? +value.zoom + 1 : 1.25*/}
          rotate={0}
          onPositionChange={this.positionChangeHandler}
          position={value && value.x ? { x: +value.x, y: +value.y } : { x: 0.5, y: 0.5 }}
          onLoadSuccess={isMain ? this.loadSuccessHandler : () => {}} />
        <div className="avatarBorder"/>
      </div>
    );
  };

  render() {
    const { photo } = this.state;
    const { input: { value } } = this.props;

    if (!photo && value) {
      if (value.original_url) {
        setTimeout(() => {
          this.setState({
            photo: value.original_url,
          });
        }, 0);
      }
      else {
        this.getDataURL(value.file).then((data) => {
          this.setState({
            photo: data,
          });
        });
      }
    }

    return (
      <div className="photo-editor-wrapper photo-editor-wrapper--avatar">
        <div className="photo-editor-profile-picture">
          <div className="header">
            <div className="title">Edit your profile pictures <Asterisk /></div>
            <div className="reposition-control">
              <button className="reposition-btn">
                <i className="fa fa-arrows" aria-hidden="true"/> Drag to reposition your photo
              </button>
            </div>
          </div>
          <div className="control">
            {this.state.photo ?
              <div className="advanced-control">
                <div
                  style={{ /*width: '50%',*/ height: `${this.editorContainer ? this.editorContainer.clientWidth : 200}px`}}
                  ref={ref => { this.editorContainer = ref; }}
                  className="advanced-control-item uploaded-config">
                  {this.getCropper(true, this.editorContainer ? this.editorContainer.clientWidth - 100 : 100)}
                  <div className="zoom">
                    <SliderComponent
                      max={2}
                      min={1}
                      name="scale"
                      step={0.01}
                      value={(value && +value.zoom) ? +value.zoom + 1 : 1}
                      onChange={this.zoomChangeHandler} />
                  </div>
                  <div className="upload-btn-wrapper">
                    <input id="upload_profile_picture" type="file" onChange={this.fileChangeHandler} />
                    <label htmlFor="upload_profile_picture">
                      <i className="fa fa-upload" aria-hidden="true"/> Upload Profile Pictures
                    </label>
                  </div>
                  <div className="upload-hint crop">
                    <p>Photos must be in .jpg or .png format. Recommended size is 2878x1448 pixels and cannot exceed <strong>{ MAX_FILE_SIZE_IN_MB } MB</strong></p>
                  </div>
                </div>
                <div className="advanced-control-item home-picture-preview" style={{ maxWidth: '50%' }}>
                  {this.getCropper(false, 'diamond')}
                  <p className="picture-title">Home Picture</p>
                  <p className="picture-desc">
                    You are only able to be shown on our Home Page if you subscribe the
                    <strong>GWPremium</strong> Plan&reg;
                  </p>
                </div>
              </div>
              : <div className="default-preview">
                <div className={'picture-preview-wrapper'}>
                  <div className="upload-image-preview"/>
                  <div className="upload-btn-wrapper" style={{marginTop: '55px'}}>
                    <input
                      id="upload_profile_picture"
                      type="file"
                      onChange={this.fileChangeHandler} />
                    <label htmlFor="upload_profile_picture">
                      <i className="fa fa-upload" aria-hidden="true"/> Upload Profile Pictures
                    </label>
                  </div>
                  <div className="upload-hint">
                    <p>Photos must be in .jpg or .png format. Recommended size is 548x548 pixels and cannot exceed <strong>{ MAX_FILE_SIZE_IN_MB } MB}</strong></p>
                  </div>
                </div>
                <div className={'picture-preview-wrapper'}>
                  <div className="home-picture-preview"/>
                  <p className="home-picture-preview-title">Home Picture</p>
                  <p className="home-picture-preview-desc">
                    Display in our home Page is a perk of the
                    <strong> GWPremium </strong> Plan.
                  </p>
                </div>
              </div>}
          </div>
        </div>
      </div>
    );
  }
}
