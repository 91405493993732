import notification from 'antd/es/notification'
import 'antd/es/notification/style/css'

import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'
import forIn from 'lodash/forIn'
import pick from 'lodash/pick'
import forEach from 'lodash/forEach'

const mustHave = {
	// everyone: {
	// 	has_profile_type: true,
	// 	has_profile_type_error: 'Please choose your profile type to create a gig.',
	// 	has_email: true,
	// 	has_email_error: 'Please specify your email to create a gig.',
	// 	has_name: true,
	// 	has_name_error: 'Please specify your name to create a gig',
	// 	cover_img_error: 'Please add cover image to your profile.',
	// 	avatar_error: 'Please add avatar to your profile.',
	// },

	// artist: {},
	account_info_completion: {
        default: {
            has_profile_type: {
                value: true,
                error: 'Please choose your profile type to create a gig.',
            },
            has_email: {
                value: true,
                error: 'Please confirm your email to create a gig.',
            },
            has_name: {
                value: true,
                error: 'Please specify your name to create a gig',
            },
        }
	},
	profile: {
        client: {},
        default: {
            cover_img: {
                value: true,
                error: 'Please add cover image to your profile.',
            },
            avatar: {
                value: true,
                error: 'Please add avatar to your profile.',
            },
        }
	},

	media_items: {
		artist: {
			length: 2,
			error: 'Please add atleast 2 mediafile.',
		},
		fan: {
			length: 2,
			error: 'Please add atleast 2 mediafile.',
		},
		venue: {
			length: 2,
			error: 'Please add atleast 2 mediafile.',
		},
	},

	// profile_type: {
	//   artist: {
	//     media_items: {
	//       length: 3,
	//       error: 'Please add avatar to your profile.',
	//     }
	//   }
	// }
}

const allowCreateGig = (currentUser, currentKYC, userGigs, history, currentProfile) => {
	// const { profile_type, account_info_completion, profile, media_items } = currentUser
	const { profile_type } = currentUser;
  let finish = false;

	if (get(currentUser, 'profile_type') !== 'client' && !get(currentUser, 'is_complete')) {
		notification.error({
			message: 'Profile incomplete!',
			description: `Please, complete your profile first`,
		});

		return false;
	}

  let isSkipKYC = (currentProfile && currentProfile.skip_kyc) ? currentProfile.skip_kyc : false;
	if (get(currentUser, 'profile_type') === 'client' && userGigs.length > 0 && userGigs.length === 1 && get(currentKYC, 'status', 'INCOMPLETE') !== 'VALIDATED' && !isSkipKYC) {
		notification.error({
			message: 'Profile incomplete!',
			description: `Please, complete your KYC first`,
		});

		return false;
	}


	//if (get(currentUser, 'plan.payment_status') === 'SET_PLAN') {
	//	notification.error({
	//		message: 'Need payment!',
	//		description: `Please, pay for your plan first`,
	//	})
	//	return false
	//}

	forEach(pick(currentUser, Object.keys(mustHave)), (value, key) => {
		const currentKey = key;

    if (value instanceof Array) {
        if (value.length < get(mustHave, `${currentKey}.${profile_type}.length`)) {
            notification.error({
                message: 'Error!',
                description: get(mustHave, `${currentKey}.${profile_type}.error`),
            })
            finish = true
            return false
        }
    } else if (value instanceof Object) {
      const mustHaveProp = get(mustHave, `[${key}][${profile_type}]`, get(mustHave, `[${key}][default]`))
			forEach(pick(value, Object.keys(mustHaveProp)), (value, key) => {
				if (!!value !== get(mustHaveProp, `${key}.value`)) {
					notification.error({
						message: 'Error!',
						description: get(mustHaveProp, `${key}.error`),
					});

					finish = true;
					return false;
				}
			})
    }

		if (finish) return false;
	});

	if (!finish) {
		history.push('/gig/new/start');
	}


	// currentUser.keys().

	// forIn(currentUser, (value, key) => {
	//   if(value instanceof Object) {

	//   }
	// })

	// console.log(has_email)
	// forIn(account_info_completion, (value, key) => {
	// 	if (value === mustHave.everyone[key]) {
	// 		notification.error({
	// 			message: 'Error!',
	// 			description: get(mustHave, `everyone.${key}_error`, ''),
	// 		})
	// 		return false
	// 	}
	// })

	// forIn(profile, (value, key) => {
	// 	console.log(key)
	// 	if (value) {
	// 		notification.error({
	// 			message: 'Error!',
	// 			description: get(mustHave, `everyone.${key}_error`, ''),
	// 		})
	// 		return false
	// 	}
	// })
}

export default allowCreateGig

// const {
//   currentUser: {
//     profile_type,
//     account_info_completion: { has_email, has_name, has_profile_type },
//     profile: { cover_img, avatar },
//     media_items,
//   },
//   history,
// } = this.props

// //It's awful
// if (!has_profile_type || !has_email || !has_name) {
//   notification.error({
//     message: 'Error!',
//     description: 'Please complete your profile to create a gig.',
//   })
// } else if (!cover_img || !avatar) {
//   notification.error({
//     message: 'Error!',
//     description: 'Please add avatar and cover image to your profile.',
//   })
// } else if (profile_type === 'artist') {
//   if (media_items.length < 3) {
//     notification.error({
//       message: 'Error!',
//       description: 'Please add atleast 3 mediafile.',
//     })
//   } else {
//     history.push('/gig/new/start')
//   }
// } else if (profile_type === 'venue' || profile_type === 'fan') {
//   if (media_items.length < 2) {
//     notification.error({
//       message: 'Error!',
//       description: 'Please add atleast 2 mediafile.',
//     })
//   } else {
//     history.push('/gig/new/start')
//   }
// } else {
//   history.push('/gig/new/start')
// }
// }
