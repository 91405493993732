import './SelectWithStepsCategories.scss';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { FilterRadio } from '../FilterRadio/FilterRadio';
import orderBy from 'lodash/orderBy';

@connect(({ common }) => ({
  categories: common.categories,
}))
export class SelectWithStepsCategories extends Component {

  parentScroll = null;
  scrollOffset = 0;

  constructor(props) {
    super(props);

    this.state = {};
    this._filteredCategories = this.getFilteredCategories();
  }

  _filteredCategories = null;

  getIndex() {
    return this.props.index || 0;
  }

  getFilteredCategories() {
    const { index, value, categories } = this.props;

    const filterIndex = index ? index - 1 : null;

    const comparator = value[filterIndex] || null;

    return orderBy(categories.all.filter(cat =>
      cat.parent_category_id === comparator && cat.name !== 'Events' && cat.name !== 'Services'), item => item.name);
  }

  componentWillUpdate() {
    this.scrollOffset = this.parentScroll.scrollTop;
  }

  componentDidUpdate() {
    this.parentScroll.scrollTo(null, this.scrollOffset);
  }

  render() {
    const { value, onChange, categories } = this.props;

    let calculatedValue = value[this.getIndex()];
    if (!this._filteredCategories.length) {
      return null;
    }

    return (
      <div className="SelectWithStepsCategories" key={`cat-${calculatedValue}`}>
        <div className='parent' ref={(r) => {
          this.parentScroll = r;
        }}>
          {
            this._filteredCategories.map((cat, index) =>
              <FilterRadio
                key={`radio-${calculatedValue}-${index}`}
                value={cat.id}
                label={cat.name}
                selected={calculatedValue}
                groupName={`step-cat-${calculatedValue}`}
                onChange={val => onChange(this.getIndex(), val)}
              />
            )}
        </div>
        <div>
          {calculatedValue && <SelectWithStepsCategories
            index={this.getIndex() + 1}
            value={value}
            onChange={onChange}
            categories={categories}
          />}
        </div>
      </div>
    );
  }
}
