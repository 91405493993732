import React, { Component } from 'react';
import _ from 'lodash';
import SVGInline from 'react-svg-inline';
import { BandMembers } from './../../../profile/ProfileSideBar';
import IconButton from 'material-ui/IconButton'
import {
  ProfilePrices,
  ProfileEvents,
  ProfileTags,
} from '../../../index';
import PopUpPriceList from '../PopUpPriceList/PopUpPriceList';
import { UserPic } from '../../../common/UserPic/UserPic';
import { CANCELLATION_POLICY_ARTIST_TYPES } from './../../../../components/CancellationPolicy/CancellationPolicy';
import './SelectedProfile.scss';
import notification from 'antd/es/notification'
import defaultPicture from '../../../../assets/images/default_picture.png';
import { connect } from 'react-redux';
import { getCountriesList } from '../../../../redux/modules/common';
import { getCurrentProfile, getCurrentUser } from '../../../../redux/modules/user'
import { addToFavorites, removeFromFavorites } from '../../../../redux/modules/favorites'
import OperationHours from '../../../common/OperationHours/OperationHours';
import { dropdownQuestions } from '../../../../utils/constants'

import './SelectedProfile.scss'

const svg_heart = require('!!svg-inline-loader?classPrefix!../../../../assets/icon-1.svg');
const svg_heart_fill = require('!!svg-inline-loader?classPrefix!../../../../assets/GW_Heart.svg');


@connect(
  ({ auth, profile }) => ({
    isAuthorized: auth.isAuthorized,
    targetId: profile.profile.id,
    isSetting: profile.isSettingFavorited
  }), {
    getCurrentUser,
		getCurrentProfile,
    getCountriesList,
    addToFavorites,
    removeFromFavorites,
  }
)
export default class SelectedProfile extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isOpenedPriceInfo: false,
      isOpenedAmenitiesInfo: false,
      isOpenedCancellationPolicyInfo: false
    };
  }

  floorNumber = (num) => {
    num = +num || 0;

    if (num >= 1000) {
      return `${Math.floor(num / 1000)}k`;
    }

    return num;
  };

  isFavorite = () => _.get(this, 'props.profile.is_favorite', false)

  like = () => {
      const {
        profile: { id },
        addToFavorites,
        removeFromFavorites,
        isAuthorized,
      } = this.props

      if (!isAuthorized) {
        notification.warning({
					message: 'Login required',
          description: 'You must be logged in to perform this action',
				})
        return
      }

      if (this.isFavorite()) {
        removeFromFavorites(id).catch(_ => console.error('Favorite set fail'))
      } else {
        addToFavorites(id).catch(_ => console.error('Favorite set fail'))
      }
  };

  expandReadMore = (evt) => {
    evt.preventDefault();
    this.props.onToggleMore()
  };

  switchPopupInCompare = () => {
    this.setState({ isOpenedPriceInfo: !this.state.isOpenedPriceInfo });
  };

  computedName() {
    const { profile: { profile: { name, first_name, last_name } } } = this.props;

     if (name && name.length > 1) {
      return name
    }
    else {
      return `${first_name} ${last_name}`
    }
  }

  collapseString(str) {
    return (str.length <= 30 || this.props.showFullAboutSection) ? str : str.substring(0, 30) + '...'
  }

  getAnswer(answer) {
    const questions = [ ...dropdownQuestions.artistQuestions, ...dropdownQuestions.venueQuestions, ...dropdownQuestions.bandQuestions, ...dropdownQuestions.groupQuestions ]

    const question = questions.find(item => item.value === answer.question_id)
    const collapsedAnswer = this.collapseString(answer.answer)

    if(question) {
      if(question.value % 100 !== 99) {
        return <p>{question.label} <br /> {collapsedAnswer}&nbsp;</p>
      }
      else {
        return <p>{collapsedAnswer}&nbsp;</p>
      }
    }
  }


  render() {
    const {
      profile,
      profile: { services, profile_type },
      showFullAboutSection,
      onRemoveItem
    } = this.props;
    const prof = profile.profile;
    const { isOpenedAmenitiesInfo, isOpenedPriceInfo } = this.state;
    const result = profile.styles ? profile.styles.reduce((acc, style) => {
      (style.type === 'venue_occasion')
        ? acc.occasions.push(style)
        : acc.styles.push(style);

      return acc;
    }, { styles: [], occasions: [] }) : [];

    const operation_hours = _.get(profile, 'profile.operation_hours')
    const ability = _.get(profile, 'countries', []).map(country => country.name).join(', ')

    const amenitiesArray = [
      { name: 'Type of Food', value: prof.food_types },
      { name: 'Attributes', value: prof.attributes },
      { name: 'Drinks', value: prof.drinks },
      { name: 'Payment Options', value: prof.payment_options },
      { name: 'Smoking', value: prof.smoking },
      { name: 'Suggested Attire / Dress Code', value: prof.fngfidghfuighsfuidhgsfuidgh },
      { name: 'Facilities', value: prof.facilities },
      { name: 'Recreational Services', value: prof.recreational_services },
      { name: 'Business services', value: prof.business_services  },
      { name: 'Setting', value: prof.Setting },
      { name: 'Serves', value: prof.serves },
      { name: 'Accessible by', value: prof.accessible_by },
      { name: 'Equipment', value: prof.equipment },
      { name: 'Parking', value: prof.parking_types },
      { name: 'Features and Guest Services', value: prof.features_and_guest_services }
    ];

    return (

      <div className="SelectedProfile">
        {
          isOpenedPriceInfo &&
            <PopUpPriceList
              priceList={services}
              close={this.switchPopupInCompare}
              name={prof.name || `${prof.first_name} ${prof.last_name}`}
            />
        }

        <div className="header">
          <UserPic src={prof.avatar}  profileCompleted ={true} />
          <div className='name'>
            <p>
              { this.computedName() }
            </p>
            {
              !isOpenedPriceInfo && <IconButton className="like-btn" onClick={this.like}>
                {this.isFavorite() ? (
                  <SVGInline svg={svg_heart_fill} fill={'#f00'} width={'1.4rem'} style={{ padding: '0px' }} className={'in_favorite'} />
                ) : (
                  <SVGInline svg={svg_heart} fill={'#000'} width={'1.4rem'} style={{ padding: '0px' }} className={'not_favorite'} />
                )}
              </IconButton>

            }
          </div>

          {
            !isOpenedPriceInfo &&
            <IconButton
              style={{ position: 'absolute', color: '#ccc', right: '-8px', top: '-.7rem', padding: '0', fontSize: '2.4rem' }}
              onClick={() => { onRemoveItem(profile) }}>
              ×
            </IconButton>
          }
        </div>

        <div className='cover'>
          <img src={_.get(prof, 'cover_img.url', defaultPicture)}/>
        </div>

        <ProfilePrices close={this.switchPopupInCompare} profile={prof} isCompare={true} />

        {
          profile.profile_type !== 'venue'
            && <div className='business-locations'>
              <p>
                <span className="circle">
                  <i className="fa fa-plane" aria-hidden="true"/>
                </span>
                {ability ? ability : '---'}
              </p>
            </div>
        }

        <p className="short-info">
          {result.styles.map(style => <span className="style-item-no-select" key={style.id}>{style.name}</span>)}
        </p>

        <ProfileTags profile={profile} />

        <div className='claps'>
          <p>
            <b>{this.floorNumber(profile.successful_gigs || 0)}</b>
            <br/>
            Successful gigs
          </p>

          <p>
            <b>{this.floorNumber(profile.clappers_count) || 0}</b>
            <br/>
            Claps
          </p>

          <p>
            <b>{this.floorNumber(profile.followers_count) || 0}</b>
            <br/>
            Followers
          </p>
        </div>

        {operation_hours && <OperationHours value={operation_hours} />}

        <ProfileEvents id={profile.id} />

        {(profile.answers && profile.answers.length > 0) ?
          (
            <div className="about">
              <h5>
                <b>About:</b>
              </h5>
              {profile.answers.map(answer =>
                <div>
                  {this.getAnswer(answer)}
                </div>
              )}
              <a href="" onClick={this.expandReadMore}>{showFullAboutSection ? 'Read less' : 'Read more'}</a>
              <div className="line" />
            </div>
          ) :
          (prof.about && (
            <div className="about">
              <h5>
                <b>About:</b>
              </h5>
              <p>{this.collapseString(prof.about)}&nbsp;
              </p>
              <a href="" onClick={this.expandReadMore}>{showFullAboutSection ? 'Read less' : 'Read more'}</a>
              <div className="line" />
            </div>
          ))
        }
        {
          (profile_type === 'band' ||
            profile_type === 'group') &&
              <BandMembers profile={profile}/>
        }

        {
          profile.profile_type === 'venue'
          && (
            <div>
              <div className="occasions">
                <h5>Occasions</h5>
                {
                  result.occasions.map(item => <span style={{ display: 'inline-block', fontSize: '1rem' }}>{item.name}</span>)
                }
                <div className="line" />
              </div>


              <div className="amenities">
                <h5 onClick={() => this.setState({ isOpenedAmenitiesInfo: !isOpenedAmenitiesInfo })}>
                        Amenities <span className="fa fa-plus-circle" /></h5>
                {
                  isOpenedAmenitiesInfo && amenitiesArray.map(item =>
                    !!item.value && <div className="amenities-item">
                      <p className="name">{item.name}</p>
                      <p className="value">{item.value.join(', ')}</p>
                    </div>)
                }
                <div className="line" />
              </div>
            </div>
          )
        }
        <div className="cancellation-policy">
          <h5 className="d-flex justify-content-between">
            <span>Cancellation Policy</span><span onClick={() =>
              this.setState({ isOpenedCancellationPolicyInfo: !this.state.isOpenedCancellationPolicyInfo })}>
            Easy Going</span>
          </h5>
          {
            this.state.isOpenedCancellationPolicyInfo
              && <p className="description" dangerouslySetInnerHTML={{ __html: CANCELLATION_POLICY_TYPE[0].content }} />
          }
        </div>
      </div>
    );
  }
}
