import React from 'react';
import { withState } from 'recompose';
import { connect } from 'react-redux'
import './FreeQuote.scss';
import { TextField, RaisedButton } from 'material-ui';
import apiClient from '../../../helpers/apiClient';
import { createMessage } from '../../../redux/modules/inbox';
import notification from 'antd/es/notification'
import 'antd/es/notification/style/css'

import Spinner from '../../common/Spinner/Spinner';

const materialStyles = {
  TextField: {
    style: {
      width: '100%'
    },
    hintStyle: {
      height: '100%',
      display: 'flex',
      alignItems: 'center',
      fontSize: '1rem',
      top: 0
    }
  },
  RaisedButton: {
    style: {
      width: '100%',
      borderRadius: 0
    },
    labelStyle: {
      textTransform: 'none'
    }
  }
};

@connect(({}) => ({}), {createMessage})
class FreeQuote extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      text: '',   
      loading: false,   
    };
  }

  handleSubmit = () => {
    this.setState({ loading: true })

    apiClient.post('/api/chat/create-ask', {
      profileId: this.props.profileId,
    }).then(res => {
      const roomId = res.data.id;

      this.props.createMessage(roomId, this.state.text)
        .then(res => {
          this.setState({ text: '' });
          this.props.switchPopup(false);
          this.setState({ loading: false })
          notification.success({
						message: 'Congratulation!',
						description: 'You have successfully send quote request.',
					})
        })
        .catch(err => {
          console.log(err)
          this.setState({ loading: false })
        })
    })
  }

  render() {
    const { isShowPopup, switchPopup, currentUser, history } = this.props;

    return (
      <div className="FreeQuote">
        {this.state.loading && <Spinner />}
        <h3
          onClick={() => currentUser ? switchPopup(!isShowPopup) : history.push('/login')}
          className="FreeQuote__title">
          <i className="fa fa-question" /> Ask for a free quote
        </h3>
        {
          isShowPopup
          && <div className="FreeQuote__popup">
            <TextField
              value={this.state.text}
              onChange={(evt, text) => this.setState({ text })}
              style={materialStyles.TextField.style}
              hintStyle={materialStyles.TextField.hintStyle}
              hintText="Write a quote under 100 characters."
              underlineShow={false}
              multiLine={true}
              rows={4}
              rowsMax={4} />
            <RaisedButton
              style={materialStyles.RaisedButton.style}
              labelStyle={materialStyles.RaisedButton.labelStyle}
              onClick={this.handleSubmit}
              label="Send" />
          </div>
        }
      </div>
    );
  }
}


const enhance = withState('isShowPopup', 'switchPopup', false);

export default enhance(FreeQuote);
