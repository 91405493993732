import React, {Component} from 'react';
import { registrationConfig } from '../config';
import validate from '../helpers/validators/gig/serviceType';
import SVGInline from 'react-svg-inline'
//const file_upload = require('../assets/file_upload.svg');
const file_upload = require('!!svg-inline-loader?classPrefix!../assets/file_upload.svg');

export default class AddNewBlock extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showItem: this.props.showItem,
      showInput: this.props.opened,
      showUpload: false,
      isValidating: false,
      fileName: '',
      errorMessage: {
        url: '',
        location: '',
        title: ''
      }
    };
  }

  buttonClickHandler = () => {
    const {
      submitHandler,
      typeContent,
      showItem,
      validate
    } = this.props;

    const {
      showInput,
      showUpload,
    } = this.state;

    if (this.state.showInput) {
      let title;

      if (this.refs.titleInput) {
        title = this.refs.titleInput.value.replace(/[@#]+/g, '');
      }

      const hashtagsArr = this.refs.hashtagsInput
        .value
        .split(/,(\s+)?|\s+/g)
        .filter(tag => !!tag)
        .map(tag => ({content: tag.replace(/[@#]+/g, '')}))
        .filter(tag => !!tag.content)
        .slice(0, 5)

      // TODO: redo this in the right way
      const data = {
        url: this.refs.urlInput.value,
        title: title,
        location: title,
        hashtags: hashtagsArr,
        input: this.refs.urlInput
      }

      this.setState({ isValidating: true })
      validate(data).then(
        res => {
          this.setState({ isValidating: false })

          submitHandler(this.refs.urlInput, data)

          if (!showItem) {
            this.setState({ showUpload: false, showInput: false,  errorMessage: {}, fileName: '' });
          } else {
            this.setState({ showUpload: false, errorMessage: {}, fileName: '' });

            this.refs.titleInput.value = '';
            this.refs.hashtagsInput.value = '';
          }

        },
        errors => {
          this.setState({ isValidating: false })
          if (errors && Object.keys(errors).length) {
            this.setState({ errorMessage: errors, });
          }
        }
      )

    } else {
      if (!showItem) {
        this.setState({
          showInput: !this.state.showInput,
        });
      }
    }
  }

  getShowUpload = () => {
    this.setState({
      showUpload: !this.state.showUpload,
    });
  }

  getUploadFile = () => {
    console.log('getUploadFile Add', this.refs.urlInput)
    this.setState({
      fileName: this.refs.urlInput.files[0].name  //value
    });
  }

  render() {

    const { showInput, showUpload, isValidating } = this.state;
    const {
      label,
      typeContent,
      placeholder,
      hint,
      uploadTxt,
    } = this.props;

    const type = this.props.type || 'text';
    const positionNumber = this.props.positionNumber || null;
    let addButton = !showInput
      ? <button className="add-btn" onClick={this.buttonClickHandler}/>
      : null;

    return (
      <div className="edit-add-new">
        { isValidating && <div className="spinner" /> }

        <div className={`wrapper ${showInput ? 'open' : null}`}>
          <div className="control">
            {showInput ?
              <div className={`wrapper-input d-flex flex-column justify-content-between at-block-${positionNumber}`}>
                <div>
                  <div>
                    <input className="name-input" placeholder={placeholder} ref="titleInput" type="text"/>
                    {!!this.state.errorMessage.title &&
                    <p className="required-error">{this.state.errorMessage.title}</p>}
                    {!!this.state.errorMessage.location &&
                    <p className="required-error">{this.state.errorMessage.location}</p>}
                  </div>
                  <div>
                    <input className="hashtags-input" type="text" ref="hashtagsInput" placeholder="Hashtags"/>
                    {!!this.state.errorMessage.hashtags &&
                    <p className="required-error">{this.state.errorMessage.hashtags}</p>}
                  </div>
                  <span className="subtitle">max 5 hashtags</span>
                </div>
                {!showUpload ?
                  <button
                    className="upload-button"
                    onClick={this.getShowUpload}
                  >
                     <SVGInline
                       svg={file_upload}
                       style={{ marginRight: '.5rem' }}
                       fill="#000"
                       width="1rem"
                       height="1rem"
                     />
                    {uploadTxt}</button> :
                  <div>
                    <div className="wrapper-url">
                      {
                        typeContent === 'video' &&
                          <button
                            style={{ border: 'none', padding: 0, marginRight: '.5rem' }}
                            onClick={this.buttonClickHandler}
                          >
                            <SVGInline
                              svg={file_upload}
                              fill="#000"
                              width="1rem"
                              height="1rem"
                            />
                          </button>
                      }

                      {typeContent === 'picture' ?
                        <label>
                          {this.state.fileName ? <span className="full-url">{this.state.fileName}</span> :
                            <span className="unfull-url">Upload file</span>}
                          <input
                            className={`${typeContent}-url`} ref="urlInput"
                            onChange={this.getUploadFile}
                            type={typeContent === 'picture' ? 'file' : 'text'}/>
                        </label>
                        :
                        <label style={{border: `${typeContent === 'picture' ? '1px' : 'none'}`}}>
                          <input
                            className={`${typeContent}-url`} ref="urlInput"
                            placeholder={`Add here link to ${typeContent}`} type="text"/>
                        </label>}
                        {
                        typeContent !== 'video' &&
                        <button className="add-btn" onClick={this.buttonClickHandler} />
                      }
                    </div>
                    <p className="required-error">{this.state.errorMessage.url}</p>

                  </div>
                }
              </div>
              : null}
          </div>
          {!addButton && !showUpload ? <div className="position-number">{positionNumber}</div> : null}
          <div className='add-new-video'>
            {!showInput ?
              <div className="description">
                {addButton}
                <p className="description-title"><b>{showInput ? 'Save' : label}</b></p>
                <p className="description-content">Take advantage of being on <strong>GWPremiumPlan</strong>&reg;</p>
              </div>
              : null}
          </div>
          {showInput ? hint : null}
        </div>
      </div>
    );
  }
}
