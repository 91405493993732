import './Footer.scss'
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { MediaBlock } from '../index'
import { socialLinks } from '../../config'
import { Container } from 'reactstrap'
import { SiteMap } from './SiteMap/SiteMap'
import classNames from 'classnames'
import { block } from 'bem-cn'
import RaisedButton from 'material-ui/RaisedButton'
import SubscriptionWindow from './SubscriptionWindow/SubscriptionWindow'
import { translate } from '../../translator'
import CookieConsent, { Cookies } from 'react-cookie-consent'
import LangDropdown from './LangDropdown/LangDropdown'
import VisibilitySensor from 'react-visibility-sensor'
import ReactGA from "react-ga";
import ManagePrivacyPreferencesWindow from "./ManagePrivacyPreferencesWindow/ManagePrivacyPreferencesWindow";

const b = block('Footer')

export default class Footer extends Component {
  state = {
    isOpenedSubscription: false,
    isOpenedManagePrivacyPreferences: false
  }

  constructor (props) {
    super(props)
  }

  static propTypes = {
    media: PropTypes.object.isRequired,
    type:  PropTypes.string,
  }

  switchSubscriptionModal = () =>
    this.setState(prevState => ({
      isOpenedSubscription: !prevState.isOpenedSubscription,
    }))

  switchManagePrivacyPreferencesModal = () =>
    this.setState(prevState => ({
      isOpenedManagePrivacyPreferences: !prevState.isOpenedManagePrivacyPreferences,
    }))

  render () {
    const { media, type }   = this.props
    const subscriptionStyle = {
      borderRadius:    '50px',
      backgroundColor: '#fff',
      color:           '#000',
    }

    const wrapperStyle = {
      margin:       '10% 25%',
      background:   'none #fff',
      color:        '#333',
      opacity:      1,
      padding:      '20px',
      boxShadow:    '0px 0px 15px rgb(43, 55, 59)',
      borderRadius: '15px',
    }

    return (
      <footer className={classNames(b(), { [b('home')]: (type === 'home') })}>

        <CookieConsent
          enableDeclineButton
          location="none"
          containerClasses="d-flex flex-column justify-content-start"
          contentStyle={{ flex: '1 0 auto' }}
          onAccept={() => {
            ReactGA.initialize('UA-152569493-1');
            ReactGA.pageview('/homepage');
          }}
          onDecline={() => {
            window['ga-disable-UA-152569493-1'] = true;
          }}
          declineButtonText={'Decline'}
          buttonText={'Accept'}
          cookieName="gigworks-cookie-accept"
          style={{
            background: 'none rgba(255,255,255,0.9)',
            fontSize:   '13px',
            opacity:    '1',
            height:     '100%',
            position:   'fixed',
          }}

          buttonStyle={{
            background:   '#fff',
            fontSize:     '13px',
            borderRadius: '1.5rem',
            color:        '#000',
            fontWeight:   'bold',
            alignSelf:    'center',
            position:     'fixed',
            padding:      '15px 55px',
            top:          '0',
            margin:       '355px 75px auto 75px',
            boxShadow:    'rgba(43, 55, 59, 0.8) 0px 4px 10px',
          }}
          declineButtonStyle={{
            background:   '#fff',
            fontSize:     '13px',
            borderRadius: '1.5rem',
            color:        '#000',
            fontWeight:   'bold',
            alignSelf:    'center',
            position:     'fixed',
            padding:      '15px 55px',
            top:          '0',
            margin:       '355px -100px auto -100px',
            boxShadow:    'rgba(43, 55, 59, 0.8) 0px 4px 10px',
          }}
          expires={365}>
          <div style={{
            position: 'fixed',
            left:     0,
            bottom:   '0',
            height:   '100%',
            width:    '100%',
            background: 'none rgba(255,255,255,0.9)',

          }}>
            <div style={wrapperStyle} className="d-flex flex-column">
              <p>
                <h4>We value your privacy</h4>
                <br/>
                When you browse on this site, cookies and other technologies
                collect data to enhance your experience and personalize the
                content and advertising you see. Visit our Privacy Policy to
                learn more and make choices about the data used by us and our
                partners on the site. By clicking "Accept" or by continuing to
                use the site, you agree to this use of cookies and data.
              </p>
              <div className="d-flex flex-column">
                <a className="static-pages-content--link mb-2"
                   href={process.env.PUBLIC_URL + '/gigworks-privacy.pdf'}
                   target="blank">{translate('footer.privacyTextLink')}</a>
                <a className="static-pages-content--link"
                   href={process.env.PUBLIC_URL + '/gigworks-terms.pdf'}
                   target="blank">{translate('footer.termsLink')}</a>
              </div>

            </div>
          </div>
        </CookieConsent>
        {/* <CookieConsent
                location="none"
                buttonText={translate('footer.textGotIt')}
                cookieName="gigworks-privacy-policy-accept"
                style={{ background: "#2B373B", fontSize: "13px", opacity: "0.8", position: "relative" }}
                buttonStyle={{
                    background: "#fff",
                    fontSize: "13px",
                    borderRadius: "1.5rem",
                    color: "#000",
                    fontWeight: "bold",
                    alignSelf: "center"
                }}
                expires={365}>{translate('footer.privacyText')}<br/>
                    {translate('footer.privacyText2')} <Link to="" className="static-pages-content--link" href='#'>{translate('footer.privacyTextLink')}</Link>
            </CookieConsent> */}


        <div className="mobile-video">
          <VisibilitySensor partialVisibility>{({ isVisible }) => {
            if (isVisible) return <MediaBlock media={media}/>
            return <div
              style={{ height: '40px', backgroundColor: 'transparent' }}/>
          }}</VisibilitySensor>
        </div>
        {
          this.state.isOpenedSubscription
          && <SubscriptionWindow close={this.switchSubscriptionModal}/>
        }
        <Container>
          <div className={b('top')}>
            <div className={b('logo')}>
              <picture>
                <source media="(min-width: 48em)" srcSet={require(
                  '../../assets/images/gigworks-white.png')}/>
                <img
                  src={require('../../assets/images/gigworks-logo-white.png')}
                  alt="Gigworks"/>
              </picture>
            </div>
            <div className="Footer__top_right">
              <div className="mr-3">
                <b>{translate('footer.language')}</b>
              </div>
              <div className="mr-4">
                <LangDropdown/>
              </div>
              <div className={b('subscription')}>
                <RaisedButton
                  label={translate('footer.joinOurNewsletter')}
                  onClick={this.switchSubscriptionModal}
                  labelStyle={{
                    textTransform: 'normal',
                    fontSize:      '.9rem',
                    color:         '#000',
                    overflow:      'hidden',
                    whiteSpace:    'normal',
                    padding:       0,
                    width:         '100%',
                    fontWeight:    'bold',
                  }}
                  buttonStyle={{
                    background: 'transparent',
                    zIndex:     'unset',
                  }}
                  style={subscriptionStyle}
                  className='subscription'
                  overlayStyle={{ borderRadius: '1.5rem' }}/>
              </div>
              <div className={b('options')}>
                <div>
                  <a target="_blank" href={socialLinks.facebook}>
                    <i className="fa fa-facebook" aria-hidden="true"/></a>
                </div>
                <div>
                  <a target="_blank" href={socialLinks.instagram}>
                    <i className="fa fa-instagram" aria-hidden="true"/></a>
                </div>
                <div>
                  <a target="_blank" href={socialLinks.linkedin}>
                    <i className="fa fa-linkedin" aria-hidden="true"/></a>
                </div>
                <div>
                  <a target="_blank" href={socialLinks.youtube}>
                    <i className="fa fa-youtube-play" aria-hidden="true"/></a>
                </div>
              </div>
            </div>
          </div>
          <div className={b('middle')}>
            <SiteMap switchSubscription={this.switchSubscriptionModal}/>
            <div className={b('video')}>
              <VisibilitySensor partialVisibility>{({ isVisible }) => {
                if (isVisible) return <MediaBlock media={media}/>
                return <div
                  style={{ height: '40px', backgroundColor: 'transparent' }}/>
              }}</VisibilitySensor>
            </div>
          </div>
          <div className={b('bottom')}>
            <div className={b('links')}>
              <div>
                <div
                  className='manage_privacy_preferences'
                  onClick={this.switchManagePrivacyPreferencesModal}
                  // style={{...{backgroundColor: '#ffd210'}}}
                >
                  #Manage Privacy Preferences
                </div>
                {
                  this.state.isOpenedManagePrivacyPreferences
                  && <ManagePrivacyPreferencesWindow close={this.switchManagePrivacyPreferencesModal}/>
                }
              </div>
              <a
                href={process.env.PUBLIC_URL + '/gigworks-terms.pdf'}
                target="blank"
              >
                {translate('footer.termsAndConditions')}
              </a>
              <a
                href={process.env.PUBLIC_URL + '/gigworks-privacy.pdf'}
                target="blank"
              >
                {translate('footer.privacyAndCookies')}
              </a>
              <a href="/legal">{translate('footer.legal')}</a>
              <span className='andSpan'>{translate('footer.and')}</span>
              <a href="/impressum">{translate('footer.impressum')}</a>
            </div>
            <p className={b('copyright')}>© 2019 Gigworks</p>
          </div>
        </Container>
      </footer>
    )
  }
}
