import './LocationFilter.scss';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getCountriesList } from '../../../../redux/modules/common'
import { SelectWithStepsLocation } from '../common/SelectWithStepsLocation/SelectWithStepsLocation';
import Loader from '../../../discover/Loader/Loader';



@connect(({ common }) => ({
  countriesList: common.countriesList
}), {
  getCountriesList
})
export class LocationFilter extends Component {
  constructor(props) {
    super(props);
  }

  UNSAFE_componentWillMount() {
    this.props.countriesList.length === 0 && this.props.getCountriesList();
  }

  render() {
    return (
      <div className='LocationFilter'>
        {
          this.props.countriesList.length === 0 ? <Loader /> : <SelectWithStepsLocation
            value={this.props.value}
            locationType={'continent'}
            onChange={this.props.onChange}
            locationId={null}
          />
        }        
      </div>
    );
  }
}
