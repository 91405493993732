import React, { Component } from 'react'
import _ from 'lodash'
import MdDone from 'react-icons/lib/md/done'

import './PlansMobile.scss'
import Button from '../../../common/Button/Button';

export default class PlansMobile extends Component {
    constructor(props) {
        super(props)

        this.state = {
            selected: this.getActivePlan(),
            buttonDisabled: false,
            buttonLabel: '',
            prices: JSON.parse(JSON.stringify(props.prices))
        }
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        const prices = JSON.parse(JSON.stringify(nextProps.prices))
        if(prices !== this.state.prices) {
            this.setState({ prices: prices }, () => this.prepareButton())
        }
    }

    getActivePlan() {
        const { prices } = this.props
        let index = -1
        if(prices && Array.isArray(prices)) {
            index = prices.findIndex(plan => plan.current)
        }

        return index >= 0 ? index : 0
    }

    prepareButton = () => {
        const { prices } = this.state
        const selectedPlan = prices[this.state.selected]
        const currentPlan = prices.find(plan => plan.id === this.props.active)

        if(!currentPlan || selectedPlan.id > currentPlan.id) {
            this.setState({ buttonDisabled: false, buttonLabel: `Select ${selectedPlan.name} Plan` })
        }
        else {
            this.setState({ buttonDisabled: true, buttonLabel: `You already have ${currentPlan.name} Plan` })
        }
    };

    onClickSelect = () => {
        const { prices, onClick } = this.props
        const selectedPlan = prices[this.state.selected]

        if(selectedPlan && !selectedPlan.current && !selectedPlan.disabled) {
            onClick(selectedPlan.id)
        }
    };

    getInfo(val) {
        if (_.isBoolean(val)) {
		    return val ? <MdDone size={16} color="#17C317" /> : '-'
        }
        else {
            return val || '-'
        }
    }

    render() {
        const { prices, plans, noButtons } = this.props;
        const { selected } = this.state;

        return(
            <div className="plans-mobile">
                <div className="plans-switcher">
                    {prices.map((price, idx) =>
                        <div className={'plan-header' + (selected === idx ? ' selected' : '')} onClick={() => this.setState({ selected: idx }, () => this.prepareButton())}>{_.get(price, 'name')}</div>
                    )}
                </div>
                <div className="plans-content">
                    <div className="content-header">Pricing</div>
                    <div className="content-row">
                        <div className="row-header">
                            Monthly price:
                        </div>
                        <div className="row-content font-size-biggest">
                            {prices[selected].amount ? `${prices[selected].amount.toFixed(2)}€` : 'Free'}
                        </div>
                    </div>

                    {plans.map(group => {
                        return (
                            <div>
                                <div className="content-header">{group.label}</div>
                                {group.value.map(item =>
                                    <div className="content-row">
                                        <div className="row-header">
                                            <div className="header-label">{item.label}</div>
                                            {item.description && <div className="header-description">{item.description}</div>}
                                        </div>
                                        <div className="row-content">
                                            {this.getInfo(item.value[selected])}
                                        </div>
                                    </div>
                                )}
                            </div>
                        )
                    })}
                    {!noButtons &&
                        <Button
                            primary
                            fullWidth
                            style={{ padding: 'inherit 0' }}
                            disabled={this.state.buttonDisabled}
                            label={this.state.buttonLabel}
                            onClick={this.onClickSelect} />
                    }
                </div>
            </div>
        )
    }
}
