const validate = values => {
  const errors = {};

  if (!values.first_name) {
    errors.first_name = 'Required'
  }
  if (!values.last_name) {
    errors.last_name = 'Required'
  }
  if (!values.nationality) {
    errors.nationality = 'Required'
  }
  if (!values.birth_date) {
    errors.birth_date = 'Required'
  }
  if (!values.over18) {
    errors.over18 = 'Required'
  }
  if (!values.location) {
		errors.location = 'Required'
  }
  else if (!values.location.street) {
    errors.location = 'Street required'
  }
  else if (!values.location.city) {
    errors.location = 'City required'
  }
  else if (!values.location.state) {
    errors.location = 'State required'
  }
  else if (!values.location.postal_code) {
    errors.location = 'Zip code required'
  }
  else if (!values.location.country) {
    errors.location = 'Country required'
  }

  if (values.location && values.location.formatted_address && !values.location.place_id) {
    errors.location = 'You must select a city from the list'
  }


  return errors;
};

export default validate;
