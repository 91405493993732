import './SearchFilterBar.scss'
import 'rc-slider/assets/index.css'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { getCategories, getCountriesList } from '../../../redux/modules/common'
import map from 'lodash/map'
import each from 'lodash/each'
import isArray from 'lodash/isArray'
import reduce from 'lodash/reduce'
import startCase from 'lodash/startCase'
import { SORT_BY_OPTIONS } from '../../../utils/constants'
import { SortBy } from '../../common/SortBar/SortBy/SortBy'
import { Container } from 'reactstrap'
import FilterPopOver from './common/FilterPopOver/FilterPopOver'
import { GroupFilter } from './GroupFilter/GroupFilter'
import { LocationFilter } from './LocationFilter/LocationFilter'
import { BudgetFilter } from './BudgetFilter/BudgetFilter'
import classNames from 'classnames'
import { TimeFilter } from './TimeFilter/TimeFilter'

@connect(
	({ common }) => ({
		countriesList: common.countriesList,
	}),
	{
		getCategories,
		getCountriesList,
	}
)
export default class SearchFilterBar extends Component {
	filters = {
		group: {
			component: GroupFilter,
		},
		location: {
			component: LocationFilter,
			props: {},
		},
		time: {
			component: TimeFilter,
		},
		budget: {
			component: BudgetFilter,
		},
	}

	constructor(props) {
		super(props)

		this.state = {
			isOpened: false,
			sortValue: 0,
			groupFilterValue: [],
			locationFilterValue: [],
			timeFilterValue: {},
			selectedType: null,
			budgetFilterValue: {
				currency: 'EUR',
				min: 0,
				max: 50000,
			},
		}

		this.props.getCategories()

		console.log(this.props.countriesList)
		console.log(this.props.countriesList.length)

		if (this.props.countriesList.length === 0) {
			console.log('Try to get countries for Search Filter')
			this.props.getCountriesList()
		}

		this.filters.location.props.itemClickHandler = this.forceOpenUpdate
	}

	forceOpenUpdate = () => {
		this.keepPopover = true
	}

	groupFilterChangeHandler = (index, val) => {
		const values = [...this.state.groupFilterValue]

		values[index] = val
		values.splice(index + 1)

		this.setState({ groupFilterValue: values }, this.filtersChangeHandler)
	}

	timeFilterChangeHandler = timeFilterValue => {
		this.setState({ timeFilterValue }, this.filtersChangeHandler)
	}

	budgetFilterChangeHandler = budgetFilterValue => {
		this.setState({ budgetFilterValue }, this.filtersChangeHandler)
	}

	locationFilterChangeHandler = (index, val) => {
		const values = [...this.state.locationFilterValue]

		values[index] = val
		values.splice(index + 1)

		this.setState(
			{
				locationFilterValue: values,
			},
			this.filtersChangeHandler
		)
	}

	filtersChangeHandler = () => {
		const final = reduce(
			this.filters,
			(res, val, key) => {
				val = this.state[`${key}FilterValue`]

				if (val && ((isArray(val) && val.length) || !isArray(val))) {
					switch (key) {
						case 'group':
							res.push(`category_id=${val[val.length - 1]}`)
							break
						case 'budget':
							each(val, (budgetVal, budgetKey) => {
								res.push(`budget[${budgetKey}]=${budgetVal}`)
							})
							break
						case 'location':
							val.forEach((location, index) => {
								if (location) {
									res.push(`locations[${index}][id]=${location.id}&locations[${index}][type]=${location.type}`)
								}
							})
							break
					}
				}

				return res
			},
			[]
		)

		this.props.onChange(final.join('&'))
	}

	filterClickHandler = evt => {
		evt.preventDefault()

		const anchorEl = evt.target

		this.setState({
			anchorEl,
			isOpened: true,
			selectedType: anchorEl.id,
		})
	}

	getPopOverContent = () => {
		const { selectedType } = this.state

		const filter = this.filters[selectedType]

		const ComponentRef = filter.component

		return (
			<ComponentRef
				value={this.state[`${selectedType}FilterValue`]}
				onChange={this[`${selectedType}FilterChangeHandler`]}
				{...filter.props || {}}
			/>
		)
	}

	filterPopOverCloseHandler = () => {
		if (!this.keepPopover) {
			this.setState({
				isOpened: false,
				selectedType: null,
			})
		}

		this.keepPopover = false
	}

	sortByChangeHandler = v => {
		const sortValue = SORT_BY_OPTIONS.indexOf(v)
		this.setState({ sortValue })
	}

	render() {
		const { isOpened, anchorEl, selectedType } = this.state

		return (
			<div className="SearchFilterBar">
				<Container className="d-flex row">
					<div className="search-type">
						{map(this.filters, (filter, key) => (
							<button
								id={key}
								key={key}
								className={classNames('search-type__button', { selected: isOpened && selectedType === key })}
								onClick={this.filterClickHandler}
							>
								{startCase(key)}
							</button>
						))}
					</div>

					{isOpened && (
						<FilterPopOver anchor={anchorEl} isOpened={isOpened} onClose={this.filterPopOverCloseHandler}>
							{this.getPopOverContent()}
						</FilterPopOver>
					)}
					<SortBy items={SORT_BY_OPTIONS} value={this.state.sortValue} onChange={this.sortByChangeHandler} />
				</Container>
			</div>
		)
	}
}
