import React from 'react'
import { defaultFontSize, entityTypes, personTypes, skipKYC } from '../../../utils/constants'
import {translate} from "../../../translator";
import {Field} from "redux-form";
import RenderField from "../../RenderField";
import {registrationConfig} from "../../../config";
import {Asterisk} from "../../common/Asterisk/Asterisk";
import {
  BirthDatePickerComponent, CheckboxComponent,
  GoogleMapsAutocomplete,
  SelectFieldComponent,
  TextFieldComponent
} from "../../CommonComponent";
import {FileInput} from "./FileInput";
import Loader from "../../discover/Loader/Loader";
import Checkbox, { Gender } from '../CommonComponent'


/**
 * Kyc form for legal entities, such as Businesses, Organizations and Soletraders
 */
export default class KycSkipForm extends React.Component {

  render() {

    const { showValidationError, touch, isSkipKYCSelected, selectSkipKYC, isAcceptCheckboxSkipKYC, acceptCheckboxSkipKYC, currentProfile } = this.props;
    console.log('currentProfile', currentProfile.profile_detail)
    return (
      <div className="section divide">
        {!currentProfile.skip_kyc &&(
        <div className="button_column skip_kyc">
          <span className={`switch_button ${isSkipKYCSelected(skipKYC.skip) ? 'selected' : ''}`} onClick={() => selectSkipKYC(skipKYC.skip)}>{translate('kyc.skip_kyc')}</span>
        </div>
        )}
        {currentProfile.skip_kyc &&(
          <div className="button_column skip_kyc">
            <span className={`switch_button disable`}>{translate('kyc.skip_kyc')}</span>
          </div>
        )}
        <div className="content_column_skip_kyc">
          <div className="person_type_title">
            <p className="skip-kyc-notice">
              Select this button if you wish to upload your KYC documents at a later time.<br/>
              This will alow you to continue using the site until you are to receive funds from Gigworks.
            </p>
          </div>

          {isSkipKYCSelected(skipKYC.skip) && !currentProfile.skip_kyc &&(
          <div className="mobile-full-width">
            <form className="form-wrapper-kyc">
              <div className="form-section pb-0">
                <Field
                  name="skip_kyc"
                  label={<>I acknowledge the KYC requirements and I understand these documents must be provided prior to receiving funds from the Gigworks platform. <Asterisk /></>}
                  labelStyle={{ width: 'initial', color: '#000000'}}
                  onChange={acceptCheckboxSkipKYC}
                  component={CheckboxComponent}
                />
              </div>
            </form>
          </div>
          )}
        </div>
      </div>
    );
  }
}
