import React, { Component } from 'react'
import { connect } from 'react-redux'
import { change, destroy } from 'redux-form'
import * as _ from 'lodash'
import { filterMediaArr, objectToFormData, cleanServices } from '../helpers/methods'
import { WizardOverviewStep } from '../components/register'
import { sharePopUpDialog } from '../components/register/WizardOverviewStep'
import {
	saveVideos,
	saveAudios,
	savePictures,
	personalInfo,
	uploadPhotos,
	saveTalentDetails,
	saveTalentInfo,
	saveProfileInfo,
	saveProfileInfoPost,
} from '../redux/modules/register'
import { getCurrentProfile, getCurrentUser, getCurrentKYC } from '../redux/modules/user'
import { getProfile, updateProfileInSearch } from '../redux/modules/profile'
// import '../styles/containers/RegisterWizard.scss';
import '../containers/RegisterWizard/RegisterWizard.scss'

import { mediaFormNames, profileFormNames, personTypes } from '../utils/constants'
import { getRealTypeOfUser } from '../helpers/methods'
import { registrationConfig } from '../config'
import { AlertPopUp } from './common/AlertPopUp/AlertPopUp'
import { store as globalStore } from "../redux/create"

@connect(
	({ form, user, auth }) => ({
		forms: form,
		currentUser: user.currentUser,
		isAuthorized: auth.isAuthorized,
	}),
	{
		saveVideos,
		saveAudios,
		savePictures,
		uploadPhotos,
		personalInfo,
		getCurrentUser,
		getProfile,
    getCurrentProfile,
    getCurrentKYC,
		saveTalentDetails,
		saveTalentInfo,
		saveProfileInfo,
		saveProfileInfoPost,
		change,
		destroy,
		updateProfileInSearch
	}
)
export default class EditProfile extends Component {
	constructor(props) {
		super(props)

		this.state = {
			registerType:
				this.props.location.state && this.props.location.state[0] ? this.props.location.state[0] : 'edit-profile',
			initialValues: this.props.location.state && this.props.location.state[1] ? this.props.location.state[1] : {},
			initialPage: this.props.location.state && this.props.location.state[2] ? this.props.location.state[2] : {},
			loading: false,
			currentForm: { form: -1, type: 'profile' },
		}
	}

	UNSAFE_componentWillMount() {
		if (this.props.isAuthorized) {
			this.props
				.getCurrentUser()
				.then(res => this.props.getProfile(res.data.id))
                .then(res => this.props.getCurrentProfile(res.data.id))
                .then(res => this.props.getCurrentKYC())
		}
	}

	componentWillUnmount() {
		this.props.destroy(...Object.keys(this.props.forms))
	}

	setCurrentForm = value => {
		this.setState({
			currentForm: value,
		})
	}

	showValidationError = error => {
		this.setState({
			errorsModalContent: error,
			isOpenErrorPopUp: true,
		})
	}

	render() {
		const { initialValues, loading, initialPage, isOpenErrorPopUp } = this.state

		return (
			<div className="RegisterWizard">
				<WizardOverviewStep
					container="profile"
					registerType={this.state.registerType}
					initialValuesRegister={initialValues}
					loading={loading}
					initialPage={initialPage}
					isValidForms={this.isValidForms}
					isValidForm={this.isValidForm}
					setCurrentForm={this.setCurrentForm}
					showValidationError={this.showValidationError}
					onSubmit={this.onSaveForm}
				/>

				<div className="errors-popup">
					<AlertPopUp
						isOpen={isOpenErrorPopUp}
						title={`ALERT MESSAGE`}
						text={this.state.errorsModalContent}
						okBtn={{
							label: `Ok, I understand.`,
							onClick: this.onCloseErrorPopUp,
						}}
					/>
				</div>
			</div>
		)
	}
	onCloseErrorPopUp = () => {
		this.setState({ isOpenErrorPopUp: false, errorModalContent: null })
	}
	/**
	 * TODO refactoring move save changes logic to forms itself
	 */
	updateAndRedirect = () =>
		this.props
			.updateProfileInSearch()
			.then(_ => this.props.getCurrentUser())
			.then(res => this.props.getProfile(res.data.id))
            .then(res => this.props.getCurrentProfile(res.data.id))
            .then(_ => this.props.getCurrentKYC())

	onSaveForm = () => {
		const savePromises = this.getSaveChangesPromisesByFormStore(this.props.forms)

		this.setState({
			loading: true,
		})

		Promise.all(savePromises)
			.then(() => {
        this.updateAndRedirect().then(() => {
          this.setState({
            loading: false,
          })
        })
			})
			.catch(err => {
				let errorsModalContent = {}
					if(err && err.response && err.response.data && err.response.data.errors){
						errorsModalContent = (<ul>
							{Object.values(err.response.data.errors).map((val, index) => (
								<li key={index}>{val}</li>
							))}
						</ul>
					)} else if(err && err.response && err.response.data ){
            errorsModalContent = (<ul>
                {Object.values(err.response.data).map((val, index) => (
                  <li key={index}>{val}</li>
                ))}
              </ul>
            )} else {
              errorsModalContent = (
              <ul>{<li>{err.toString()}</li>}</ul>
            )
					}
				this.setState({
					loading: false,
					errorsModalContent: errorsModalContent,
					isOpenErrorPopUp: true,
				})
			})
	}

	isValidForms = forms => {
		const { currentUser } = this.props
		const profile_type = getRealTypeOfUser(currentUser)

		let isValid = true
		const store = forms

		//TODO profile for now
		const formsToFill = profile_type ? registrationConfig.forms[profile_type].profile : []

		formsToFill.forEach(formName => {
			let form = store[formName]
			if (form) {
				if (form.syncErrors) {
					isValid = false
				}
			} else {
			}
		})

		return isValid
	}

	isValidForm = formName => {
		let isValid = true
		const store = this.props.forms

		let form = store[formName]

		if (form) {
			if (form.syncErrors) isValid = false
		} else {
			console.log(formName, 'isnt initialized')
		}

		return isValid
	}

	/**
	 * @param store
	 * store with forms
	 *
	 * return value Promise list
	 */
	getSaveChangesPromisesByFormStore = store => {
		let savePromisses = Object.keys(store).map(key => {
			let form = store[key]

      if (key === 'KYC') {
        if (form.values.skip_kyc && form.values.skip_kyc === true) {
          return this.getSkipKYCAction(key, form.values.skip_kyc)
        }
      }

			if (this.isValidForm(key) && Object.keys(_.omitBy(form.values, _.isNil)).length)
				return this.getSaveAction(key, form)
			else return undefined
		})

		return savePromisses.filter(promise => !!promise)
    }

    renameProp = (oldProp, newProp, { [oldProp]: old, ...others }) => {
        return {
            [newProp]: old,
            ...others
        }
    }

	//TODO validation
	getSaveAction = (storeName, store) => {
		if (
			mediaFormNames.indexOf(storeName) === -1 &&
			profileFormNames.indexOf(storeName) === -1 &&
			storeName !== 'wizard'
		) {
			console.log('incorrect form name')

			return
		}

		if (storeName === 'AccountInfo') {
			console.log('ignore AccountInfo')

			return
		}

		// _.each(store.values, (val, key) => {
		// 	console.log(key)
		// 	console.log(val)
		// 	// if (val && typeof val === 'object' && !Object.keys(val).length && !(val instanceof File)) {
		// 	// 	delete store.values[key]
		// 	// }
		// })

		switch (storeName) {
			case 'EditProfilePhotos': {
				const formData = new FormData()
				let shouldUpload = false

				const avatarImg = store.values.avatar_img

				if (avatarImg) {
					formData.append('avatar_img[zoom]', avatarImg.zoom)
					formData.append('avatar_img[scale]', avatarImg.scale)
					formData.append('avatar_img[offset_x]', avatarImg.offset_x)
					formData.append('avatar_img[offset_y]', avatarImg.offset_y)
					formData.append('avatar_img[x]', avatarImg.x)
					formData.append('avatar_img[y]', avatarImg.y)

					if (avatarImg.file) {
						formData.append('avatar_img[file]', avatarImg.file)
					}

					shouldUpload = true
				}

				const cardImg = store.values.card_img

				if (cardImg && typeof cardImg === 'object') {
					formData.append('card_img[zoom]', cardImg.zoom)
					formData.append('card_img[scale_x]', cardImg.scale_x)
					formData.append('card_img[scale_y]', cardImg.scale_y)
					formData.append('card_img[offset_x]', cardImg.offset_x)
					formData.append('card_img[offset_y]', cardImg.offset_y)
					formData.append('card_img[x]', cardImg.x)
					formData.append('card_img[y]', cardImg.y)

					if (cardImg.file) {
						formData.append('card_img[file]', cardImg.file)
					}

					shouldUpload = true
				}

				const coverImg = store.values.cover_img

				if (coverImg && typeof coverImg === 'object') {
					formData.append('cover_img[zoom]', coverImg.zoom)
					formData.append('cover_img[scale_x]', coverImg.scale_x)
					formData.append('cover_img[scale_y]', coverImg.scale_y)
					formData.append('cover_img[offset_x]', coverImg.offset_x)
					formData.append('cover_img[offset_y]', coverImg.offset_y)
					formData.append('cover_img[x]', coverImg.x)
					formData.append('cover_img[y]', coverImg.y)

					if (coverImg.file) {
						formData.append('cover_img[file]', coverImg.file)
					}

					shouldUpload = true
				}

				const videoUrl = store.values.card_video_url

				if (videoUrl) {
					formData.append('card_video_url', videoUrl)
				}

				if (shouldUpload) {
					return this.props.uploadPhotos(formData).then(() => {
						this.props.change('EditProfilePhotos', 'card_img', null)
						this.props.change('EditProfilePhotos', 'avatar_img', null)
						this.props.change('EditProfilePhotos', 'cover_img', null)
					})
				}
				break
			}
			case 'EditVideos': {
				const videos = store.values.videos || []

				return this.props.saveVideos({
					videos: filterMediaArr(videos),
				})
			}
			case 'EditAudio': {
				const audios = store.values.audios || []

				return this.props.saveAudios({
					audios: filterMediaArr(audios),
				})
			}
			case 'EditPictures': {
				let pictures = store.values.pictures || []

				pictures = filterMediaArr(pictures)

				if (!pictures.length) {
					return this.props.savePictures({ pictures })
				}

				const picturesFormData = new FormData()
				function base64ImageToBlob(str) {
					// extract content type and base64 payload from original string
					var pos = str.indexOf(';base64,');
					var type = str.substring(5, pos);
					var b64 = str.substr(pos + 8);

					// decode base64
					var imageContent = atob(b64);

					// create an ArrayBuffer and a view (as unsigned 8-bit)
					var buffer = new ArrayBuffer(imageContent.length);
					var view = new Uint8Array(buffer);

					// fill the view, using the decoded base64
					for(var n = 0; n < imageContent.length; n++) {
					  view[n] = imageContent.charCodeAt(n);
					}

					// convert ArrayBuffer to Blob
					var blob = new Blob([buffer], { type: type });

					return blob;
				}

				pictures.forEach((picture, index) => {
					picturesFormData.append(`pictures[${index}][location]`, picture.location)
					picturesFormData.append(`pictures[${index}][title]`, picture.title)
					picturesFormData.append(`pictures[${index}][position_index]`, index)

					if (!picture.id || (picture.edited && picture.preview)) {
						picturesFormData.append(`pictures[${index}][file]`, base64ImageToBlob(picture.preview))
					}

					if (picture.id) {
						picturesFormData.append(`pictures[${index}][id]`, picture.id)
					}

					picture.hashtags.forEach((tag, indexTag) => {
						picturesFormData.append(
							`pictures[${index}][hashtags][${indexTag}][content]`,
							tag.content ? tag.content : tag
						)
					})
				})

				return this.props.savePictures(picturesFormData)

				// return this.props.savePictures(picturesFormData).then(res => {
				// 	this.props.getCurrentUser().then(res => {
				// 		Promise.all([this.props.getProfile(res.data.id), this.props.getCurrentProfile(res.data.id)])
				// 	})
				// })
			}

			/**
			 * PROFILE
			 */

      case 'VenueInfo':
      case 'ArtistPersonalInfo':
      case 'BandContactInfo':
      case 'GroupContactInfo':{
        const data = _.cloneDeep(store.values)
        const arr = globalStore.getState().dashboard.countriesTree
        const countryCode = globalStore.getState().common.country_code

        for (let i = 0; i < arr.length; i++) {
          if (arr[i].code === countryCode) {
            data.ability_to_travel = arr[i].id
          }
        }
        if (data.gender === 'n') {
          data.gender = undefined
        }

        const formData = objectToFormData(data)

        //workaround for backend
        formData.append('_method', 'PUT')
        formData.append('profile_action', 'update_contact_info')

        return this.props.personalInfo(formData)
      }
      case 'TalentInfo': {
        return this.props.saveTalentInfo(store.values)
      }
      case 'TalentDetails': {
        const TalentDetails = _.cloneDeep(store.values)

				TalentDetails.services = cleanServices(TalentDetails.services)

				return this.props.saveTalentDetails(_.omitBy(TalentDetails, _.isNil))
			}
			case 'BookingInfo': {
				const bookingInfo = _.cloneDeep(store.values)

				bookingInfo.services = cleanServices(bookingInfo.services)

				const formData = objectToFormData(bookingInfo)

				//workaround for backend
				formData.append('_method', 'PUT')

				return this.props.saveProfileInfoPost(formData)
			}
			case 'ClientPersonalInfo': {
				const clientPersonalInfo = _.cloneDeep(store.values)

				const formData = objectToFormData(clientPersonalInfo)

				//workaround for backend
				formData.append('_method', 'PUT')

				return this.props.saveProfileInfoPost(formData)
			}
			case 'CompanyContactInfo': {
				const CompanyContactInfo = _.cloneDeep(store.values)

				const formData = objectToFormData(CompanyContactInfo)

				//workaround for backend
				formData.append('_method', 'PUT')

				return this.props.saveProfileInfoPost(formData)
			}
			case 'FanPersonalInfo': {
				const FanPersonalInfo = _.cloneDeep(store.values)

				const formData = objectToFormData(FanPersonalInfo)

				//workaround for backend
				formData.append('_method', 'PUT')

				return this.props.saveProfileInfoPost(formData)
            }
            case 'KYC': {
                let KYC = _.cloneDeep(store.values)
                console.log('KYC values', KYC)

                if(KYC.kyc_person_type === personTypes.Individual) {
                    KYC = this.renameProp('kyc_ind_identity_proof', 'kyc_identity_proof', KYC)
                    KYC = { ...KYC, kyc_ent_identity_proof: null }
                }
                else if(KYC.kyc_person_type === personTypes.Entity) {
                    KYC = this.renameProp('kyc_ent_identity_proof', 'kyc_identity_proof', KYC)
                    KYC = { ...KYC, kyc_ind_identity_proof: null }
                }

                if(KYC.kyc_location == null && Object.keys(KYC.kyc_location).length === 0 || Object.keys(KYC.kyc_location).every(prop => !KYC.kyc_location[prop] )) {
                  KYC = {...KYC, kyc_location: null}
                }
                if(KYC.kyc_business_location == null || Object.keys(KYC.kyc_business_location).length === 0 || Object.keys(KYC.kyc_business_location).every(prop => !KYC.kyc_business_location[prop] )) {
                  KYC = {...KYC, kyc_business_location: null}
                }
                if(KYC.kyc_representative_location == null || Object.keys(KYC.kyc_representative_location).length === 0 || Object.keys(KYC.kyc_representative_location).every(prop => !KYC.kyc_representative_location[prop])) {
                  KYC = {...KYC, kyc_representative_location: null}
                }

                console.log("KYC AFTER CHANGE", KYC)
                const formData = objectToFormData(KYC, null, 'kyc')

                //workaround for backend
				formData.append('_method', 'PUT')

				return this.props.saveProfileInfoPost(formData)
            }
			default: {
				console.log('Store capacity too', store.values)
				const values = _.cloneDeep(store.values)

				if (values.capacity) {
					console.log(values.capacity)
					_.forEach(values.capacity, (value, key) => {
						// console.log(key)
						// console.log(value)
						if (value === '') {
							delete values.capacity[key]
						}
					})
					console.log(values.capacity)
				}

				if (values.services) {
					values.services = cleanServices(values.services)
				}

				if (values.categories) {
					values.categories = values.categories.filter(cat => !!cat.name)
				}

				// _.each(values, (val, key) => {
				// 	if (
				// 		val &&
				// 		typeof val === 'object' &&
				// 		(val.formatted_address || val.formatted_address === '') &&
				// 		!val.place_id
				// 	) {
				// 		delete values[key]
				// 	}
				// })

				return this.props.saveProfileInfo(_.omitBy(values, _.isNil))
			}
		}
	}

  //TODO validation
  getSkipKYCAction = (key, skipKyc) => {

    const formData = new FormData()
    formData.append('skip_kyc', skipKyc)

    //workaround for backend
    formData.append('_method', 'PUT')

    return this.props.personalInfo(formData)
  }
}
