import React, { Component } from 'react';
import { connect } from 'react-redux'
import { Field, reduxForm } from 'redux-form'
import classNames from 'classnames'
import Chip from 'material-ui/Chip';
import Toggle from 'material-ui/Toggle';
import Popover from 'material-ui/Popover/Popover';
import MenuItem from 'material-ui/MenuItem';
import MaterialCheckbox from 'material-ui/Checkbox';
import TextField from 'material-ui/TextField';
import DatePicker from 'material-ui/DatePicker';
import TimePicker from 'material-ui/TimePicker';
import FlatButton from 'material-ui/FlatButton';
import SelectField from 'material-ui/SelectField';
import RaisedButton from 'material-ui/RaisedButton';
import AutoComplete from 'material-ui/AutoComplete';
import { Menu } from 'material-ui/Menu';
import { RadioButton, RadioButtonGroup } from 'material-ui/RadioButton';

import * as _ from 'lodash';
import moment from 'moment';
import { DateRange } from 'react-date-range';
import { SortableContainer, SortableElement, arrayMove } from 'react-sortable-hoc';
import SVGInline from 'react-svg-inline'

import {
  months,
  defaultFontSize,
  GREY_COLOR_DARKER
} from '../utils/constants';

import file_upload from '../assets/file_upload.svg';
import file_upload_white from '../assets/file_upload_white.svg';

const svg_checkbox = require('!!svg-inline-loader?classPrefix!../assets/icons/checkbox.svg');
const svg_checkbox_empty = require('!!svg-inline-loader?classPrefix!../assets/icons/checkbox_empty.svg');

import '../styles/components/CommonComponent.scss'
import { translate } from '../translator'
import store from "../redux/create";
import { getCountriesTree } from '../redux/modules/dashboard';
import Loader from './discover/Loader/Loader'

export const InputStyles = {
	...defaultFontSize,
  fontWeight: '300',
  minHeight: '26px',
	color: '#000',
};

export const PlaceholderStyles = {
	...defaultFontSize,
};

const mobileDefaultFontSize = { fontSize: '0.8rem' };

const square = require('!!svg-inline-loader?classPrefix!../assets/square.svg');
const PRIMARY_COLOR = '#ffd210';

export class DatePickerComponent extends Component {
  render() {
    const {
      meta,
      input: { value, onChange },
      ...props
    } = this.props;

    return (
      <DatePicker
        autoOk
        locale="en-US"
        mode="landscape"
        container="inline"
        textFieldStyle={{ fontSize: 'calc(50rem / 32)' }}
        value={value ? moment(value)
          .toDate() : undefined}
        onChange={(evt, val) => onChange(moment(val)
          .format('YYYY-MM-DD 00:00:00'))}
        {...props} />
    );
  }
}

export class BirthDatePickerComponent extends Component {
  constructor(props) {
    super(props);

    const { input: { value } } = this.props;

    if (value) {
      const date = moment(value);

      const year = date.year();
      const month = date.month() + 1;

      this.state = {
        year,
        month,
        day: date.date(),
        days: this.getDays(year, this.getZeroed(month)),
        isValidAge: true,
      };
    } else {
      const day = new Date();
      const dayWrapper = moment(day);

      const year = dayWrapper.year();
      const month = dayWrapper.month();

      this.state = {
        days: this.getDays(year, this.getZeroed(month)),
        isValidAge: true
      };
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { input: { value } } = nextProps;

    if (value) {
      const date = moment(value);
      const year = date.year();
      const month = date.month() + 1;

      this.setState({
        year,
        month,
        day: date.date(),
        days: this.getDays(year, this.getZeroed(month)),
        isValidAge: true
      });
    }
  }

  getDays(year, month) {
    return _.range(1, year && parseInt(month, 10) ? moment(`${year}-${month}`)
      .daysInMonth() + 1 : 32);
  }

  getZeroed(val) {
    return parseInt(val, 10) < 10 ? `0${val}` : val;
  }

  validateAge = () => {
    const { year, month, day } = this.state;

    let today = moment();
    let yearDiff = today.year() - year;
    let monthDiff = today.month() + 1 - month;
    let dayDiff = today.date() - day;

    const hadBirthDayThisYear = ( monthDiff > 0 ) || (monthDiff === 0 && dayDiff >= 0)
    const boolAge = ( yearDiff > 18 ) || ( hadBirthDayThisYear && yearDiff === 18 )

    this.setState(() => ({
      isValidAge: boolAge
    }));
    !boolAge && this.setState(() => ({
      year: undefined
    }));
  };

  changeHandler(which, value) {
    let date = {};
    date[which] = value;
    date = Object.assign(this.state, date);
    let { year, month, day } = date;
    const { withTime, input: { onChange } } = this.props;
    const format = `YYYY-MM-DD${withTime ? ' HH:MM:SS' : ''}`;
    day = this.getZeroed(day);
    month = this.getZeroed(month);
    this.setState({
      days: this.getDays(year, month),
      year: parseInt(year, 10),
      month: parseInt(month, 10),
      day: parseInt(day, 10)
    }, () => {
      const { year, month, day } = this.state;
      const isoDate = moment(`${year || '2000'}-${this.getZeroed(month) || '01'}-${this.getZeroed(day) || '01'}`)
        .format(format);
      if (isoDate !== 'Invalid date') {
        onChange(isoDate);
      }
    });
    year && month && day && this.validateAge();
  }

  getSelectField(which, items, textGetter) {
    return <SelectField
      id={which}
      style={{ width: '100%' }}
      value={this.state[which]}
      hintStyle={ defaultFontSize }
      labelStyle={{ ...defaultFontSize, color: '#000' }}
      hintText={_.capitalize(which)}
      onChange={(evt, index, val) => this.changeHandler(which, val)}>
      {items.map(item =>
        <MenuItem
          key={item}
          value={item}
          checked={item === this.state[which]}
          primaryText={textGetter ? textGetter(item) : item} />
      )}
    </SelectField>;
  }

  render() {
    const { days, isValidAge, over18 } = this.state;
    const { isFuture } = this.props;

    return (
      <div className="birth-date-picker d-flex justify-content-end" style={{ width: '100%' }}>
        <div className="birth-date-picker__item year">
          {this.getSelectField('year', _.range(moment()
            .year(), isFuture ? 2100 : 1930), year => year)}
        </div>
        <div className="birth-date-picker__item day">
          {this.getSelectField('day', days)}
        </div>
        <div className="birth-date-picker__item month">
          {this.getSelectField('month', _.range(1, 13), val => months[val - 1])}
        </div>
        {!isValidAge && <span className="birth-date-picker__error">The age must be 18+</span>}
      </div>
    );
  }
}

export class VenueBirthDatePickerComponent extends Component {
  constructor(props) {
    super(props);

    const { input: { value } } = this.props;

    if (value) {
      const date = moment(value);

      const year = date.year();
      const month = date.month() + 1;

      this.state = {
        year,
        month,
        day: date.date(),
        days: this.getDays(year, this.getZeroed(month)),
        isValidAge: true
      };
    } else {
      const day = new Date();
      const dayWrapper = moment(day);

      const year = dayWrapper.year();
      const month = dayWrapper.month();

      this.state = {
        days: this.getDays(year, this.getZeroed(month)),
        isValidAge: true
      };
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { input: { value } } = nextProps;

    if (value) {
      const date = moment(value);
      const year = date.year();
      const month = date.month() + 1;

      this.setState({
        year,
        month,
        day: date.date(),
        days: this.getDays(year, this.getZeroed(month)),
        isValidAge: true
      });
    }
  }

  getDays(year, month) {
    return _.range(1, year && parseInt(month, 10) ? moment(`${year}-${month}`)
      .daysInMonth() + 1 : 32);
  }

  getZeroed(val) {
    return parseInt(val, 10) < 10 ? `0${val}` : val;
  }

  validateAge = () => {
    const { year, month, day } = this.state;
    const boolAge = moment().diff(`${year}-${month}-${day}`, 'years') > 1;
    this.setState(() => ({
      isValidAge: boolAge
    }));
    !boolAge && this.setState(() => ({
      year: undefined
    }));
  };

  changeHandler(which, value) {
    let date = {};
    date[which] = value;
    date = Object.assign(this.state, date);
    let { year, month, day } = date;
    const { withTime, input: { onChange } } = this.props;
    const format = `YYYY-MM-DD${withTime ? ' HH:MM:SS' : ''}`;
    day = this.getZeroed(day);
    month = this.getZeroed(month);
    this.setState({
      days: this.getDays(year, month),
      year: parseInt(year, 10),
      month: parseInt(month, 10),
      day: parseInt(day, 10)
    }, () => {
      const { year, month, day } = this.state;
      const isoDate = moment(`${year || '2000'}-${this.getZeroed(month) || '01'}-${this.getZeroed(day) || '01'}`)
        .format(format);
      if (isoDate !== 'Invalid date') {
        onChange(isoDate);
      }
    });
    year && month && day && this.validateAge();
  }

  getSelectField(which, items, textGetter) {
    return <SelectField
      id={which}
      style={{ width: '100%' }}
      value={this.state[which]}
      hintStyle={ defaultFontSize }
      labelStyle={{ ...defaultFontSize, color: '#000' }}
      hintText={_.capitalize(which)}
      onChange={(evt, index, val) => this.changeHandler(which, val)}>
      {items.map(item =>
        <MenuItem
          key={item}
          value={item}
          checked={item === this.state[which]}
          primaryText={textGetter ? textGetter(item) : item} />
      )}
    </SelectField>;
  }

  render() {
    const { days, isValidAge } = this.state;
    const { isFuture } = this.props;

    return (
      <div className="birth-date-picker d-flex justify-content-end" style={{ width: '100%' }}>
        <div className="birth-date-picker__item year">
          {this.getSelectField('year', _.range(moment()
            .year(), isFuture ? 2100 : 1930), year => year)}
        </div>
        <div className="birth-date-picker__item day">
          {this.getSelectField('day', days)}
        </div>
        <div className="birth-date-picker__item month">
          {this.getSelectField('month', _.range(1, 13), val => months[val - 1])}
        </div>
      </div>
    );
  }
}

@connect(
	({ globalConfig }) => ({
		isMobile: globalConfig.isMobile,
	})
)
export class TextFieldComponent extends Component {
  render() {
    const {
      meta,
      input: { value, onChange, onBlur, onFocus, name },
      textareaStyle,
      component,
      style,
      isMobile,
      ...props
    } = this.props;

    return (
      <TextField
        name={name}
        value={value}
        onBlur={onBlur}
        style={{ ...defaultFontSize, ...style }}
        hintStyle={ isMobile ? mobileDefaultFontSize : defaultFontSize }
        textareaStyle={{ color: '#000', ...textareaStyle }}
        onFocus={onFocus}
        onChange={(evt, val) => onChange(val)}
        errorText={meta && meta.error && meta.touched ? meta.error : ''}
        {...props}
        />
    );
  }
}

export class PhoneComponent extends Component {

  changeHandler(which, val) {
    const { input: { value, onChange } } = this.props;

    onChange({
      ...value,
      [which]: val,
    });
  }

  onBlur = (control_name) => {
    this.props.makeTouched && this.props.makeTouched(control_name)
  };

  render() {
    const {
      meta,
      style,
      input: { value, onChange },
      countries
    } = this.props;

    const countryCodeName = _.get(this.props, 'additional_name', 'country_code');

    return (
      <div className="field-container">
        <Field
          className='short-field'
          name={countryCodeName}
          style={{ ...defaultFontSize, ...style }}
          options={countries}
          hintText={translate('commoncomponent.cI8')}
          value={value.country_code}
          onChange={(e, val) => this.changeHandler('country_code', val)}
          onBlur={this.onBlur('country_code')}
          component={SelectFieldComponent}
        />
        <TextField
          errorText={meta.error && meta.touched ? meta.error: ''}
					hintText={translate('commoncomponent.cI9')}
          name='phone_number'
          className='long-field'
					style={{ ...defaultFontSize, ...style }}
          value={value.phone_number}
          onChange={(e, val) => this.changeHandler('phone_number', val)}
          onBlur={this.onBlur('phone_number')}
				/>
      </div>
    );
  }
}

@connect(
	({ globalConfig, dashboard }) => ({
		isMobile: globalConfig.isMobile,
    countriesList: dashboard.countriesList,
	}), {
    getCountriesTree
  }
)
export class GoogleMapsAutocomplete extends Component {
  googleAutocomplete = null;

  constructor(props) {
    super(props);

    this.state = {
      countryCode: ''
    }
    const { input: { value, onChange } } = this.props;

    if (!value) {
      onChange({
        formatted_address: '',
      });
    }
  }

  componentDidMount() {
    this.props.getCountriesTree().then(res =>{

      const country = this.props.countriesList.find((country, index) => {
        return country.label == this.props.input.value.country
      })


      this.setState({
        countryCode: country ? country.value : ''
      })
    })
    // this.googleAutocomplete = new google.maps.places.Autocomplete(this.inputRef.input, {
    //   types: ['address'],
    // });
    //
    // this.googleAutocomplete.addListener('place_changed', this.googleAutocompletePlaceChangeHandler);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { input: { value, onChange } } = nextProps;

    const country = this.props.countriesList.find((country, index) => {
      return country.label == nextProps.input.value.country
    })


    this.setState({
      countryCode: country ? country.value : ''
    })

    onChange({
      place_id: value.place_id || '',
      formatted_address: value.formatted_address || '',
      street: value.street || '',
      street_number: value.street_number || '',
      city: value.city || '',
      state: value.state || '',
      country: value.country || '',
      postal_code: value.postal_code || '',
      country_code: ''
    })
  }

  googleAutocompletePlaceChangeHandler = () => {
    const place = this.googleAutocomplete.getPlace();

    const { input, input: { value, onChange } } = this.props;

    function findOrUndefined(arg_type, key){
      // console.log("SG2", place)
      let ret;
      try {
        const item = place.address_components.find(item => item.types.find(type => type == arg_type))
        // console.log("SG", item, arg_type)
        return item[key];
      } catch (e) {}
      return ret || '';
    }

    if (store.getState().common.country_code) {
      store.getState().common.country_code = convertCountryCode(findOrUndefined('country', 'short_name'))
    }

    onChange({
      ...value,
      place_id: place.place_id,
      formatted_address: place.formatted_address,
      street: findOrUndefined('route', 'long_name'),
      street_number: findOrUndefined('street_number', 'long_name'),
      city: findOrUndefined('locality', 'long_name') || findOrUndefined('administrative_area_level_2', 'long_name'),
      state: findOrUndefined('administrative_area_level_1', 'long_name'),
      country: findOrUndefined('country', 'long_name'),
      postal_code: findOrUndefined('postal_code', 'long_name'),
    });

  };

  changeHandler(which, val) {
    const { input: { value, onChange } } = this.props;
    const isAutoCompleteProperty = which === 'formatted_address';

    if(which === 'country') {
      val = this.props.countriesList.find(country => country.value == val).label
    }

    const streetAndNumber = [
      which ==='street_number' ? val : value.street_number,
      which ==='street' ? val : value.street,
    ];

    const addressParts = [
      streetAndNumber.join(' '),
      which ==='city' ? val : value.city,
      which ==='state' ? val : value.state,
      which ==='postal_code' ? val : value.postal_code,
      which ==='country' ? val : value.country
    ];

    onChange({
      ...value,
      [which]: val,
      place_id: 'pid_' + Date.now(),
      formatted_address: addressParts.join(', ')
    });
  }

  render() {
    const { isMobile, input: { name, value }, isLocationEditable = true, meta, ...props } = this.props;

    const fields = ['<street>', '<city>', '<state>', '<zip>', '<country>', '<street_number>']
    let specificError = false
    if(meta.error) {
      specificError = meta.touched && fields.some(field => meta.error.toLowerCase().includes(field))
    }

    return (
      <div>
        {/*<TextField*/}
        {/*  disabled={!isLocationEditable}*/}
        {/*  errorText={meta.touched && !specificError && meta.error}*/}
        {/*  id={name}*/}
        {/*  fullWidth*/}
        {/*  style={{...defaultFontSize, ...props.inputStyle}}*/}
        {/*  inputStyle={{ color: '#000' }}*/}
        {/*  hintStyle={ isMobile ? mobileDefaultFontSize : defaultFontSize }*/}
        {/*  value={value.formatted_address}*/}
        {/*  onChange={(evt, val) => {*/}
        {/*    this.changeHandler('formatted_address', val)*/}
        {/*  }}*/}
        {/*  onBlur={props.makeTouched}*/}
        {/*  ref={ref => {*/}
        {/*    this.inputRef = ref;*/}
        {/*  }}*/}
        {/*/>*/}
        <div className="field-container">
          <TextField
            disabled={!isLocationEditable}
            errorText={specificError && meta.error.toLowerCase().includes(fields[0]) && 'Required'}
            className='long-field'
            id={`${name}-street`}
            value={value.street}
            style={{...defaultFontSize, ...props.inputStyle}}
            inputStyle={{
              color: '#000'
            }}
            hintStyle={ isMobile ? mobileDefaultFontSize : defaultFontSize }
            hintText={translate('commoncomponent.cI3')}
            onBlur={props.makeTouched}
            onChange={(evt, val) => this.changeHandler('street', val)} />
          <TextField
            disabled={!isLocationEditable}
            className='short-field'
            id={`${name}-street_number`}
            hintText={translate('commoncomponent.cI2')}
            style={{...defaultFontSize, ...props.inputStyle}}
            value={value.street_number}
            inputStyle={{
              color: '#000'
            }}
            hintStyle={ isMobile ? mobileDefaultFontSize : defaultFontSize }
            errorText={specificError && meta.error.toLowerCase().includes(fields[5]) && 'Required'}
            onChange={(evt, val) => this.changeHandler('street_number', val)} />
          <TextField
            disabled={!isLocationEditable}
            errorText={specificError && meta.error.toLowerCase().includes(fields[1]) && 'Required'}
            className='long-field'
            id={`${name}-city`}
            value={value.city}
            style={{...defaultFontSize, ...props.inputStyle}}
            inputStyle={{
              color: '#000'
            }}
            hintStyle={ isMobile ? mobileDefaultFontSize : defaultFontSize }
            hintText={translate('commoncomponent.cI4')}
            onBlur={props.makeTouched}
            onChange={(evt, val) => this.changeHandler('city', val)} />
          <TextField
            disabled={!isLocationEditable}
            errorText={specificError && meta.error.toLowerCase().includes(fields[2]) && 'Required'}
            className='short-field'
            id={`${name}-state`}
            value={value.state}
            style={{...defaultFontSize, ...props.inputStyle}}
            inputStyle={{
              color: '#000'
            }}
            hintStyle={ isMobile ? mobileDefaultFontSize : defaultFontSize }
            hintText={translate('commoncomponent.cI5')}
            onBlur={props.makeTouched}
            onChange={(evt, val) => this.changeHandler('state', val)} />
          <TextField
            disabled={!isLocationEditable}
            errorText={specificError && meta.error.toLowerCase().includes(fields[3]) && 'Required'}
            className='short-field'
            id={`${name}-postal_code`}
            value={value.postal_code}
            style={{...defaultFontSize, ...props.inputStyle}}
            inputStyle={{
              color: '#000'
            }}
            hintStyle={ isMobile ? mobileDefaultFontSize : defaultFontSize }
            hintText={translate('commoncomponent.cI6')}
            onBlur={props.makeTouched}
            onChange={(evt, val) => this.changeHandler('postal_code', val)} />
          {/*<TextField*/}
          {/*  disabled={!isLocationEditable}*/}
          {/*  errorText={specificError && meta.error.toLowerCase().includes(fields[4]) && 'Required'}*/}
          {/*  className='long-field'*/}
          {/*  id={`${name}-country`}*/}
          {/*  value={value.country}*/}
          {/*  style={{...defaultFontSize, ...props.inputStyle}}*/}
          {/*  inputStyle={{*/}
          {/*    color: '#000'*/}
          {/*  }}*/}
          {/*  hintStyle={ isMobile ? mobileDefaultFontSize : defaultFontSize }*/}
          {/*  hintText={translate('commoncomponent.cI7')}*/}
          {/*  onBlur={props.makeTouched}*/}
          {/*  onChange={(evt, val) => this.changeHandler('country', val)} />*/}
          {(this.props.countriesList && this.props.countriesList.length) ?
          <Field
            id={`${name}-country`}
            type="string"
            name={`${name}-country`}
            disabled={!isLocationEditable}
            className='long-field'
            required
            style={{...defaultFontSize, ...props.inputStyle}}
            inputStyle={{
              color: '#000'
            }}
            hintStyle={ isMobile ? mobileDefaultFontSize : defaultFontSize }
            type="string"
            options={this.props.countriesList}
            defaultValue={this.state.countryCode}
            hintText={translate('commoncomponent.cI7')}
            onBlur={props.makeTouched}
            errorText={specificError && meta.error.toLowerCase().includes(fields[4]) && 'Required'}
            onChange={(evt, val) => this.changeHandler('country', val)}
            component={SelectFieldComponent}
          />
            :
            <div className="d-flex justify-content-center">
            <Loader />
            </div>
          }
        </div>
      </div>
    );
  }
}
export class SelectFieldComponent extends Component {
  constructor(props) {
    super(props);

    const { input: { value, onChange }, defaultValue } = this.props;

    if (!value && defaultValue) {
      onChange(defaultValue);
    }
  }

  buildPrimaryText (option, isChecked) {
    if (option.hasOwnProperty('sublabel') && option.label !== '' && !isChecked) {
      return `${option.label} - ${option.sublabel}`
    } else {
      return option.label
    }
  }

  render() {
    const {
      style,
      options,
      hintText,
      maxHeight,
      labelStyle,
      input: { value, onChange },
      defaultValue,
      ...rest
    } = this.props;

    return (
      <SelectField
        {...rest}
        hintText={hintText}
        value={value || defaultValue}
        labelStyle={labelStyle}
        onChange={(evt, key, val) => onChange(val)}
        maxHeight={maxHeight}
        menuStyle={{ width: 'auto' }}
        style={{ ...defaultFontSize, ...style }}
        iconStyle={{
          fontSize: '1.2rem',
          padding: '6px',
          width: '28px'
        }}
        dropDownMenuProps={{
          iconButton: <i
            className="fa fa-angle-down"
            aria-hidden="true"
          />
        }}>

        {_.map(options, (option, key) => {
          const isObj = _.isObjectLike(option);
          const isChecked =(isObj ? option.value : option ) == (value ? value : defaultValue);

          return <MenuItem
            style={{ maxWidth: '100vw', whiteSpace: 'unset' }}
            key={key}
            value={isObj ? option.value : option}
            checked={isChecked}
            primaryText={isObj ? this.buildPrimaryText(option, isChecked) : option} />;
        })}
      </SelectField>
    );
  }
}

export class AutoCompleteComponent extends Component {
  filter = (txt, key) => {
    const pattern = new RegExp(`(.+)?${txt}(.+)?`, 'i');

    // hack for categories to stay always
    if (key[0] === '.'){
      return true
    }
    return pattern.test(key);
  };

  render() {
    const {
      input: { name, value, onChange },
      ...props
    } = this.props;

    return (
      <AutoComplete
        {...props}
        className="test"
        listStyle={{ maxHeight: 250, overflow: 'auto' }}
        name={name}
        filter={this.filter}
        searchText={value}
        openOnFocus={false}
        onUpdateInput={val => onChange(val)} />
    );
  }
}

const SortableItem = SortableElement(({ value, onTagDelete, index }) =>
  <div style={{ margin: '4px' }}>
    <Chip
      key={index}
      onRequestDelete={() => onTagDelete(index)}
    >
      {value}
    </Chip>
  </div>
);

export const SortableList = SortableContainer(({ tags, onTagDelete }) => (
  <div className="talent-tags-controller__preview">
    {tags.map((value, index) => (
      <SortableItem key={`item-${index}`}
        index={index}
        value={value}
        onTagDelete={onTagDelete} />
    ))}
  </div>
));

function convertCountryCode(countryCode){
  var countryISOMapping = {
    AF:"AFG",
    AX:"ALA",
    AL:"ALB",
    DZ:"DZA",
    AS:"ASM",
    AD:"AND",
    AO:"AGO",
    AI:"AIA",
    AQ:"ATA",
    AG:"ATG",
    AR:"ARG",
    AM:"ARM",
    AW:"ABW",
    AU:"AUS",
    AT:"AUT",
    AZ:"AZE",
    BS:"BHS",
    BH:"BHR",
    BD:"BGD",
    BB:"BRB",
    BY:"BLR",
    BE:"BEL",
    BZ:"BLZ",
    BJ:"BEN",
    BM:"BMU",
    BT:"BTN",
    BO:"BOL",
    BA:"BIH",
    BW:"BWA",
    BV:"BVT",
    BR:"BRA",
    VG:"VGB",
    IO:"IOT",
    BN:"BRN",
    BG:"BGR",
    BF:"BFA",
    BI:"BDI",
    KH:"KHM",
    CM:"CMR",
    CA:"CAN",
    CV:"CPV",
    KY:"CYM",
    CF:"CAF",
    TD:"TCD",
    CL:"CHL",
    CN:"CHN",
    HK:"HKG",
    MO:"MAC",
    CX:"CXR",
    CC:"CCK",
    CO:"COL",
    KM:"COM",
    CG:"COG",
    CD:"COD",
    CK:"COK",
    CR:"CRI",
    CI:"CIV",
    HR:"HRV",
    CU:"CUB",
    CY:"CYP",
    CZ:"CZE",
    DK:"DNK",
    DJ:"DJI",
    DM:"DMA",
    DO:"DOM",
    EC:"ECU",
    EG:"EGY",
    SV:"SLV",
    GQ:"GNQ",
    ER:"ERI",
    EE:"EST",
    ET:"ETH",
    FK:"FLK",
    FO:"FRO",
    FJ:"FJI",
    FI:"FIN",
    FR:"FRA",
    GF:"GUF",
    PF:"PYF",
    TF:"ATF",
    GA:"GAB",
    GM:"GMB",
    GE:"GEO",
    DE:"DEU",
    GH:"GHA",
    GI:"GIB",
    GR:"GRC",
    GL:"GRL",
    GD:"GRD",
    GP:"GLP",
    GU:"GUM",
    GT:"GTM",
    GG:"GGY",
    GN:"GIN",
    GW:"GNB",
    GY:"GUY",
    HT:"HTI",
    HM:"HMD",
    VA:"VAT",
    HN:"HND",
    HU:"HUN",
    IS:"ISL",
    IN:"IND",
    ID:"IDN",
    IR:"IRN",
    IQ:"IRQ",
    IE:"IRL",
    IM:"IMN",
    IL:"ISR",
    IT:"ITA",
    JM:"JAM",
    JP:"JPN",
    JE:"JEY",
    JO:"JOR",
    KZ:"KAZ",
    KE:"KEN",
    KI:"KIR",
    KP:"PRK",
    KR:"KOR",
    KW:"KWT",
    KG:"KGZ",
    LA:"LAO",
    LV:"LVA",
    LB:"LBN",
    LS:"LSO",
    LR:"LBR",
    LY:"LBY",
    LI:"LIE",
    LT:"LTU",
    LU:"LUX",
    MK:"MKD",
    MG:"MDG",
    MW:"MWI",
    MY:"MYS",
    MV:"MDV",
    ML:"MLI",
    MT:"MLT",
    MH:"MHL",
    MQ:"MTQ",
    MR:"MRT",
    MU:"MUS",
    YT:"MYT",
    MX:"MEX",
    FM:"FSM",
    MD:"MDA",
    MC:"MCO",
    MN:"MNG",
    ME:"MNE",
    MS:"MSR",
    MA:"MAR",
    MZ:"MOZ",
    MM:"MMR",
    NA:"NAM",
    NR:"NRU",
    NP:"NPL",
    NL:"NLD",
    AN:"ANT",
    NC:"NCL",
    NZ:"NZL",
    NI:"NIC",
    NE:"NER",
    NG:"NGA",
    NU:"NIU",
    NF:"NFK",
    MP:"MNP",
    NO:"NOR",
    OM:"OMN",
    PK:"PAK",
    PW:"PLW",
    PS:"PSE",
    PA:"PAN",
    PG:"PNG",
    PY:"PRY",
    PE:"PER",
    PH:"PHL",
    PN:"PCN",
    PL:"POL",
    PT:"PRT",
    PR:"PRI",
    QA:"QAT",
    RE:"REU",
    RO:"ROU",
    RU:"RUS",
    RW:"RWA",
    BL:"BLM",
    SH:"SHN",
    KN:"KNA",
    LC:"LCA",
    MF:"MAF",
    PM:"SPM",
    VC:"VCT",
    WS:"WSM",
    SM:"SMR",
    ST:"STP",
    SA:"SAU",
    SN:"SEN",
    RS:"SRB",
    SC:"SYC",
    SL:"SLE",
    SG:"SGP",
    SK:"SVK",
    SI:"SVN",
    SB:"SLB",
    SO:"SOM",
    ZA:"ZAF",
    GS:"SGS",
    SS:"SSD",
    ES:"ESP",
    LK:"LKA",
    SD:"SDN",
    SR:"SUR",
    SJ:"SJM",
    SZ:"SWZ",
    SE:"SWE",
    CH:"CHE",
    SY:"SYR",
    TW:"TWN",
    TJ:"TJK",
    TZ:"TZA",
    TH:"THA",
    TL:"TLS",
    TG:"TGO",
    TK:"TKL",
    TO:"TON",
    TT:"TTO",
    TN:"TUN",
    TR:"TUR",
    TM:"TKM",
    TC:"TCA",
    TV:"TUV",
    UG:"UGA",
    UA:"UKR",
    AE:"ARE",
    GB:"GBR",
    US:"USA",
    UM:"UMI",
    UY:"URY",
    UZ:"UZB",
    VU:"VUT",
    VE:"VEN",
    VN:"VNM",
    VI:"VIR",
    WF:"WLF",
    EH:"ESH",
    YE:"YEM",
    ZM:"ZMB",
    ZW:"ZWE",
    XK:"XKX"
  }
  return countryISOMapping[countryCode]
}

export class MainRadioButtonGroup extends Component {
  constructor(props) {
    super(props);

    this.props.input.onChange(this.props.defaultSelected);
  }

  changeHandler = (evt, value) => {
    this.props.input.onChange(value);
  };

  render() {
    const { buttons, defaultSelected, input: { name, value, onChange } } = this.props;

    return (
      <div className="main-radio-button">
          {buttons.map((option, index) => (<RaisedButton
              key={index}
              htmlFor={option.value}
              label={option.label}
              labelPosition="before"
              containerElement={option.disabled ? 'div' : 'label'}
              className="main-radio-button__item"
              disabled={!!option.disabled}
              primary={value === option.value}
              overlayStyle={{ borderRadius: '3rem', padding: '0.5rem 0', height: 'auto' }}
              rippleStyle={{ borderRadius: '3rem', height: 'auto' }}
              buttonStyle={{ borderRadius: '3rem', height: 'auto' }}
              labelStyle={{
                textTransform: 'normal',
                fontSize: '1rem',
                height: 'auto',
                fontWeight: (value === option.value ? '500' : '300'),
                color: (value === option.value ? '#fff' : '#000')
              }}
              icon={option.icon ? <img src={option.icon} /> : null}
              style={{ borderRadius: '3rem', overflow: 'hidden' }}
              onClick={e => onChange(e, option.value)}
            />
        ))}


        {/* <RadioButtonGroup
          name={name}
          onChange={this.changeHandler}
          defaultSelected={defaultSelected}
          style={{ display: 'none' }}>
          {buttons.map((button, index) =>
            <RadioButton
              id={button.value}
              key={index}
              name={button.value}
              value={button.value}
              disabled={button.disabled} />
          )}
        </RadioButtonGroup> */}
        {/* {buttons.map((button, index) =>
          <RaisedButton
            key={index}
            htmlFor={button.value}
            label={button.label}
            labelPosition="before"
            containerElement={button.disabled ? 'div' : 'label'}
            className="main-radio-button__item"
            disabled={!!button.disabled}
            primary={value === button.value}
            overlayStyle={{ borderRadius: '3rem', padding: '0.5rem 0', height: 'auto' }}
            rippleStyle={{ borderRadius: '3rem', height: 'auto' }}
            buttonStyle={{ borderRadius: '3rem', height: 'auto' }}
            labelStyle={{
              textTransform: 'normal',
              fontSize: '1rem',
              height: 'auto',
              fontWeight: (value === button.value ? '500' : '300'),
              color: (value === button.value ? '#fff' : '#000')
            }}
            icon={button.icon ? <img src={button.icon} /> : null}
            style={{ borderRadius: '3rem', overflow: 'hidden' }}
          />
        )} */}
      </div>
    );
  }
}

export class ToggleComponent extends Component {
  constructor(props) {
    super(props);
  }

  toggle = (evt, value) => {
    const { callback, input: { onChange } } = this.props;

    onChange(value);

    if (callback) {
      callback(value);
    }
  };

  render() {
    const { offTitle, onTitle, callback, input: { name, value } } = this.props;

    return (
      <div className="toggle__wrapper" style={{
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center'
      }}>
        <span
          className="start-create-gig__toggle-label"
          style={{ fontWeight: !value ? '500' : '300', fontSize: '1.1rem' }}>
          {offTitle}
        </span>
        <Toggle
          trackStyle={{
            height: '1.7rem',
            width: '3.56rem',
            backgroundColor: '#fff',
            border: '1px solid #c4c4c4'
          }}
          trackSwitchedStyle={{ width: '3.56rem', backgroundColor: '#fff' }}
          iconStyle={{ width: '3.56rem', margin: '0' }}
          thumbStyle={{
            backgroundColor: '#ffd210',
            top: '0.5rem',
            left: '0.4rem',
            width: '1.125rem',
            height: '1.125rem'
          }}
          thumbSwitchedStyle={{ left: '65%' }}
          name={name}
          toggled={!!value}
          style={{ width: 'auto', margin: '0 1rem' }}
          onToggle={this.toggle} />
        <span
          className="start-create-gig__toggle-label"
          style={{ fontWeight: value ? '500' : '300', fontSize: '1.1rem' }}>
          {onTitle}
        </span>
      </div>
    );
  }
}

export class MultiCheckbox extends Component {
  constructor(props) {
    super(props);

    const { input: { value, onChange } } = this.props;

    if (!value) {
      onChange([]);
    }
  }

  getChipStyle(item) {
    return this.props.input.value.includes(item) ? {
      backgroundColor: '#FFD210',
      color: '#fff',
      margin: '0.2rem',
    } : {
        margin: '0.2rem',
      };
  }

  onCheckBox(toAddRemove) {
    const { input: { value, onChange } } = this.props;

    if (value.includes(toAddRemove)) {
      onChange(_.filter(value, item => item !== toAddRemove));
    } else {
      onChange([...value, toAddRemove]);
    }
  }

  render() {
    const { items, classes, input: { value } } = this.props;

    return (
      <div className={classes}>
        {items.map((item, index) => (
          <label key={index} style={{ margin: '0 0.5%' }}>
            <Chip
              style={this.getChipStyle(item)}
              labelStyle={value.includes(item) ? { color: '#fff' } : { color: '#000' }}>
              {item}
              <MaterialCheckbox
                onCheck={this.onCheckBox.bind(this, item)}
                style={{ display: 'none' }} />
            </Chip>
          </label>
        ))}
      </div>
    );
  }
}

export class TimePickerComponent extends Component {
  constructor(props) {
    super(props);

    const { input: { value, onChange } } = this.props;

    if (!value) {
      onChange(
        moment()
          .startOf('day')
          .toDate()
      );
    }
  }

  timeChangeHandler = (n, time) => {
    this.props.input.onChange(time);
  };

  render() {
    const { meta: { error, dirty }, disabled, input: { name, value } } = this.props;

    return (
      <label className="time-picker">
        <p style={{ marginTop: '1rem' }}>
          {moment(value)
            .format('hh mm a')}
        </p>
        <TimePicker
          minutesStep={30}
          name={name}
          value={value || null}
          style={{ display: 'none' }}
          format="24hr"
          disabled={disabled}
          onChange={this.timeChangeHandler}
          underlineShow={false}
          textFieldStyle={{ width: '100%', fontSize: '1rem', ...(error && dirty ? ({ color: 'red' }) : {}) }}
        />
        <span style={{
          fontSize: '12px',
          color: 'red', position: 'absolute',
          bottom: '10px',
          whiteSpace: 'nowrap'
        }}>
          {dirty ? error : ''}
        </span>
      </label>
    );
  }
}

export class DateRangePickerComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      date: moment()
        .format('DD, MMMM, YYYY'),
      isOpen: false
    };
  }

  handleSelect = (range) => {
    let newDate = '';
    const start = moment(range.startDate)
      .format('DD, MMMM, YYYY')
      .split(', ');
    const end = moment(range.endDate)
      .format('DD, MMMM, YYYY')
      .split(', ');
    if (start[2] !== end[2]) {
      start[1] = start[1].slice(0, 3);
      end[1] = end[1].slice(0, 3);
      newDate = `${start.join(' ')} - ${end.join(' ')}`;
    } else if (start[1] !== end[1]) {
      start.pop();
      start[1] = start[1].slice(0, 3);
      end[1] = end[1].slice(0, 3);
      newDate = `${start.join(' ')} - ${end.join(' ')}`;
    } else if (start[0] !== end[0]) {
      newDate = `${start[0]} - ${end.join(' ')}`;
    } else {
      newDate = start.join(' ');
    }
    this.setState(() => ({
      date: newDate
    }));
  };

  handleRequestClose = () => {
    this.setState(() => ({
      isOpen: false,
    }));
  };

  handleOpen = (event) => {
    event.preventDefault();

    this.setState({
      isOpen: true,
      anchorEl: event.currentTarget,
    });
  };

  render() {
    const { styleText } = this.props;
    const { date, isOpen } = this.state;

    return (
      <label className="time-picker" style={{ position: 'relative' }}>
        <p
          style={styleText}
          onClick={(event) => this.handleOpen(event)}>{date} <i className="fa fa-calendar" aria-hidden="true"></i></p>
        <Popover
          open={isOpen}
          anchorEl={this.state.anchorEl}
          onRequestClose={this.handleRequestClose}
        >
          <Menu>
            <DateRange
              theme={{
                DaySelected: {
                  background: '#ffd210',
                  color: '#000'
                },
                DayInRange: {
                  background: '#ffe284',
                  color: '#000'
                },
              }}
              calendars={1}
              onInit={this.handleSelect}
              onChange={this.handleSelect}
            />
          </Menu>
        </Popover>
      </label>
    );
  }
}

export class CheckboxComponent extends Component {
  render() {
    const { input: { value, onChange } } = this.props;

    return (
      <MaterialCheckbox
        {...this.props}
        checked={!!value}
        onCheck={val => onChange(val)} />
    );
  }
}

export class CheckboxSelectComponent extends Component {
  dataSource = [];

  constructor(props) {
    super(props);

    this.state = {
      isOpened: false,
    };

    const { input: { onChange, value } } = this.props;

    if (!value) {
      onChange({});
    }
  }

  toggle = (isOpened) => {
    this.state = {
      isOpened,
    };
  };

  updateCheck(item) {
    const { input } = this.props;

    input.onChange({
      searchText: item,
      selected: item,
    });
  }

  handleRequestClose = () => {
    this.setState(() => ({
      isOpened: false,
    }));
  };

  autocompleteUpdateHandler = (searchText) => {
    this.setState({
      isOpened: true,
    });

    const { input } = this.props;

    input.onChange({
      ...input.value,
      searchText,
    });
  };

  render() {
    const {
      hintText,
      fullWidth,
      dataSource,
      dataSourceConfig,
      input: { name, value: { searchText, selected } }
    } = this.props;

    let { isOpened } = this.state;
    this.dataSource = _.map(dataSource, (item) => {

      return {
        text: item,
        value: (
          <MenuItem>
            <label>
              <MaterialCheckbox
                name={name}
                checked={item === selected}
                label={item}
                onCheck={this.updateCheck.bind(this, item)} />
            </label>
          </MenuItem>
        )
      };
    });

    return (
      <div className="checkbox-select-component">
        <AutoComplete
          dataSource={this.dataSource}
          dataSourceConfig={dataSourceConfig}
          textFieldStyle={InputStyles}
          searchText={searchText}
          open={isOpened}

          hintText={hintText}
          listStyle={{ display: 'flex', flexWrap: 'wrap', boxShadow: 'none', border: 'none' }}
          menuStyle={{ boxShadow: 'none', border: 'none' }}
          style={{ boxShadow: 'none', border: 'none' }}
          onUpdateInput={this.autocompleteUpdateHandler}
          onClose={this.handleRequestClose}
          fullWidth={fullWidth}>
        </AutoComplete>
        <span className="arrow">{isOpened ?
          <span onClick={() => this.setState({ isOpened: false })}>
            Hide full List <i className="fa fa-angle-up" aria-hidden="true"></i>
          </span> :
          <span onClick={() => this.setState({ isOpened: true })}>
            See full List <i className="fa fa-angle-down" aria-hidden="true"></i>
          </span>}
        </span>
      </div>
    );
  }
}

export class ButtonUpload extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  onFileUpload = (file) => {
    const { onPhotoLoad, input: { onChange } } = this.props;
    onPhotoLoad(file);
    onChange(file);
  };

  render() {
    const { raised } = this.props;

    return (
      <label className="button__upload">
        {raised ? <FlatButton
          labelStyle={{ color: '#fff', textTransform: 'normal' }}
          containerElement='label'
          htmlFor="photo_upload"
          label="Upload"
          icon={<img style={{ width: '1rem', height: '1rem' }} src={file_upload_white} />}
        /> :
          <RaisedButton
            containerElement='label'
            htmlFor="photo_upload"
            label="Upload"
            buttonStyle={{ backgroundColor: '#fff' }}
            overlayStyle={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}
            style={{
              width: '100%',
              borderRadius: '1.5rem',
              overflow: 'hidden',
              border: '1px solid #a3a3a3',
              boxShadow: 'none'
            }}
            labelStyle={{ color: '#000', textTransform: 'normal', fontSize: '0.75rem' }}
            icon={<img style={{ width: '0.75rem', height: '0.75rem' }} src={file_upload} />}
          />}
        <input type="file" onChange={(event) => {
          this.onFileUpload(event.target.files[0]);
        }} id="photo_upload" style={{ display: 'none' }} />
      </label>
    );
  }
}

export const Button = ({ className, children, style, onClick, ...rest }) => {
  return (<button
      className={`btn button ${className}`}
      style={style}
      onClick={onClick}
      {...rest}
      >{children}
    </button>)
};

export const MediumHeading = ({ children, center, className, ...restProps }) => <h5 className={classNames(`MediumHeading ${className}`, {center})} {...restProps}>{children}</h5>

export const SmallHeading = ({ children, center, className, ...restProps }) => <h6 className={classNames(`SmallHeading text-muted ${className}`, {center})} {...restProps}>{children}</h6>

export const Checkbox = ({ labelStyle, iconStyle, checkedIcon, uncheckedIcon, value, onChange, ...restProps }) => <MaterialCheckbox
  checked={value}
  labelStyle={{ color: '#000', ...labelStyle }}
  iconStyle={{ marginRight: 0, ...iconStyle }}
  checkedIcon={checkedIcon || <SVGInline svg={svg_checkbox} fill={PRIMARY_COLOR} height='14px' width='14px' />}
  uncheckedIcon={uncheckedIcon || <SVGInline svg={svg_checkbox_empty} fill={'#ccc'} height='14px' width='14px' />}
  onCheck={onChange}
  {...restProps}
/>

export const SmallContentToggler = ({ value, label, labelStyle, onChange, style, ...restProps }) => (
  <div
    style={{ cursor: 'pointer', ...style }}
    onClick={() => onChange(!value)}
    {...restProps}
  >
    {
      value ?
        <i className="fa fa-angle-down" style={{ fontSize: '1rem', marginRight: '.25rem' }} aria-hidden="true" />
        : <i className="fa fa-angle-right" style={{ fontSize: '1rem', marginRight: '.25rem' }} aria-hidden="true" />
    }
    <span style={labelStyle}>{label}</span>
  </div>
);

export const SimpleInputOrText = ({ value, onChange, isEditing, style }) => {
  return isEditing ? <input
      value={value}
      onChange={e => onChange(e.target.value)}
      style={{
          border: 'none',
          borderBottom: '1px solid #ccc',
          ...style
      }}
    /> :
    <span style={{ lineHeight: '28px' }}>{value || '-'}</span>
};

// circle its like a gigworks radio button - if isActive is true its filled
export const IconCircle = ({ isActive, style, className, large, small, ...restProps }) => {
  const getFontSize = () => {
    if (large) return '28px'
    if (small) return '14px'
    return '18px'
  };

  return <i
    className={`fa ${isActive ? 'fa-circle' : 'fa-circle-thin' } ${className}`}
    aria-hidden="true"
    style={{
      color: isActive ? PRIMARY_COLOR :  '#ccc',
      fontSize: getFontSize(),
      ...style
    }}
    {...restProps}
  />
};

export const IconInfo = ({ style, ...restProps }) => <div
  style={{
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexShrink: 0,
    borderRadius: '50%',
    border: `2px solid ${GREY_COLOR_DARKER}`,
    width: 28,
    height: 28,
    minWidth: 28,
    ...style
  }}
  {...restProps}>
  <i className="fa fa-info" style={{ fontSize: 16 }} />
</div>
