import React, { Component } from 'react';
import SkyLight from 'react-skylight';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  isIOSorAndroid
} from '../../helpers/methods';
import '../../styles/components/SharePopUp.scss';
import {
  WhatsAppShare,
  TwitterShare,
  PinterestShare,
  LinkedinShare,
  EmailShare,
  FacebookMessengerShare,
  FacebookShare,
} from './config';
import {
  ShareButton
} from '../index';

import SVGInline from 'react-svg-inline';

const svg_mail = require('!!svg-inline-loader?classPrefix!../../assets/share-icons/mail.svg');
const svg_facebook = require('!!svg-inline-loader?classPrefix!../../assets/share-icons/facebook.svg');
const svg_linkedin = require('!!svg-inline-loader?classPrefix!../../assets/share-icons/linkedin.svg');
const svg_messanger = require('!!svg-inline-loader?classPrefix!../../assets/share-icons/messanger.svg');
const svg_pinterest = require('!!svg-inline-loader?classPrefix!../../assets/share-icons/pinterest.svg');
const svg_twitter = require('!!svg-inline-loader?classPrefix!../../assets/share-icons/twitter.svg');
const svg_whatsapp = require('!!svg-inline-loader?classPrefix!../../assets/share-icons/whatsapp.svg');


const sharePopUpDialog = {
  backgroundColor: '#ffffff',
  color: '#ffffff',
  width: '40%',
  height: 'auto',
  // marginTop: '0%',
  marginLeft: '-20%',
  padding: '0',
};

@connect(({ globalConfig }) => ({
  isMobile: globalConfig.isMobile,
}))
export default class SharePopUp extends Component {
  static propTypes = {
    urlToShare: PropTypes.string.isRequired,
  };

  constructor(prop){
    super(prop);

    this.state = {
      selectedTypeOfShare: '',
      email: ''
    };
  }

  openPopUp = ()=>{
    this.refs.customDialog.show();
  };

  onSelectTypeOfShare = (type) => {
    this.setState({
      selectedTypeOfShare: type
    });
  };

  onEmailChange = (evt) => {
    this.setState({
      email: evt.target.value
    });
  };

  shareOnSocialMedia = ()=>{
    const { selectedTypeOfShare, email } = this.state;
    const { urlToShare } = this.props;
    if (selectedTypeOfShare){
      switch (selectedTypeOfShare){
        case 'EM' : {
          EmailShare(urlToShare, email);
          
          return;
        }
        case 'FB' : {
          FacebookShare(urlToShare);
          
          return;
        }
        case 'FBM' : {
          FacebookMessengerShare(urlToShare);
          
          return;
        }
        case 'LI' : {
          LinkedinShare(urlToShare);
          
          return;
        }
        case 'PI' : {
          PinterestShare(urlToShare);
          
          return;
        }
        case 'TW' : {
          TwitterShare(urlToShare);
          
          return;
        }
        case 'WU' : {
          WhatsAppShare(urlToShare);
          
          return;
        }
      }
    }
  };

  render() {
    const { selectedTypeOfShare, email } = this.state;
    const { colorShareIcon, isMobile } = this.props;

    return (
      <div className="share-pop-up">
        <SkyLight
          dialogStyles={{...sharePopUpDialog, ...(isMobile ? {
            left: '5%',
            marginLeft: 0,
            width: '90%',
          } : {})}}
          hideOnOverlayClicked
          ref="customDialog"
          title="">
          <div style={{zIndex: '50'}}>
            <div className="share-header">
              <button className="share-header__button"
                onClick={this.shareOnSocialMedia}>
                <span>Share on Social Media</span>
                <SVGInline svg={require('!!svg-inline-loader?classPrefix!./../../assets/share-icon.svg')}
                  fill={'#ffffff'}
                  width={'20px'}
                  height={'16px'}/>
              </button>
            </div>
            <div className="content">
              <span className="content-title">Choose the platform you want to share with</span>
              <div className="button-wrapper">
                <div className="button-wrapper__row">
                  <ShareButton selected={selectedTypeOfShare === 'EM'}
                    onSelect={() => this.onSelectTypeOfShare('EM')}>
                    <SVGInline svg={svg_mail} fill={selectedTypeOfShare === 'EM' ? '#ffd210' : '#000000'}/>
                  </ShareButton>
                  <ShareButton selected={selectedTypeOfShare === 'FB'}
                    onSelect={() => this.onSelectTypeOfShare('FB')}>
                    <SVGInline svg={svg_facebook} fill={selectedTypeOfShare === 'FB' ? '#ffd210' : '#000000'}/>
                  </ShareButton>
                  <ShareButton selected={selectedTypeOfShare === 'LI'}
                    onSelect={() => this.onSelectTypeOfShare('LI')}>
                    <SVGInline svg={svg_linkedin} fill={selectedTypeOfShare === 'LI' ? '#ffd210' : '#000000'}/>
                  </ShareButton>
                </div>
                <div className="button-wrapper__row">
                  <ShareButton selected={selectedTypeOfShare === 'PI'}
                    onSelect={() => this.onSelectTypeOfShare('PI')}>
                    <SVGInline svg={svg_pinterest} fill={selectedTypeOfShare === 'PI' ? '#ffd210' : '#000000'}/>
                  </ShareButton>
                  <ShareButton selected={selectedTypeOfShare === 'TW'}
                    onSelect={() => this.onSelectTypeOfShare('TW')}>
                    <SVGInline svg={svg_twitter} fill={selectedTypeOfShare === 'TW' ? '#ffd210' : '#000000'}/>
                  </ShareButton>
                  <ShareButton selected={selectedTypeOfShare === 'WU'}
                    onSelect={() => this.onSelectTypeOfShare('WU')}>
                    <SVGInline svg={svg_whatsapp} fill={selectedTypeOfShare === 'WU' ? '#ffd210' : '#000000'}/>
                  </ShareButton>
                </div>
                {
                  isIOSorAndroid()
                    ?
                    <div className="button-wrapper__row">
                      <ShareButton selected={selectedTypeOfShare === 'FBM'}
                        onSelect={() => this.onSelectTypeOfShare('FBM')}>
                        <SVGInline svg={svg_messanger} fill={selectedTypeOfShare === 'FBM' ? '#ffd210' : '#000000'}/>
                      </ShareButton>
                    </div>
                    :
                    ''
                }

                {
                  selectedTypeOfShare === 'EM'
                    ?
                    <input className="publish-input"
                      placeholder="Enter email"
                      value={email}
                      onChange={this.onEmailChange}/>
                    :
                    ''
                }
                <div className="publish-button-wrapper">
                  <button className="publish-button"
                    disabled={!selectedTypeOfShare}
                    onClick={this.shareOnSocialMedia}>
                    Publish
                  </button>
                </div>
              </div>
            </div>
          </div>
        </SkyLight>
        <SVGInline svg={require('!!svg-inline-loader?classPrefix!./../../assets/share-icon.svg')}
          fill={colorShareIcon || '#ffffff'}
          width={'20px'}
          height={'20px'}
          style={{zIndex: '0', opacity: '.99'}}
          onClick={this.openPopUp}/>
      </div>
    );
  }
}
