import React, { Component } from 'react';
import { Field, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import { ShowPassword } from '../components';
import validate from '../helpers/validators/registerFormValidator';
import RenderField from './RenderField';
import { Link } from 'react-router-dom';
import Checkbox from 'material-ui/Checkbox';
import '../styles/components/RegisterForm.scss';
import {
  RegisterAdditionalFields
} from '../components/register';
import { AlertPopUp } from './common/AlertPopUp/AlertPopUp';
import { registrationConfig } from '../config';
import { translate } from '../translator';

class RegisterForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showPassword: false,
      hasAdditionalFields: false,
      majorityAge: true
    };
  }

  setPasswordVisibility = (showPassword) => {
    this.setState({ showPassword });
  };

  handleFinishFillingAdditionalInfo = () => {
    if (!this.state.hasAdditionalFields)
      this.setState({ hasAdditionalFields: true });
  };

  handleMissingAdditionalInfo = () => {
    if (this.state.hasAdditionalFields)
      this.setState({ hasAdditionalFields: false });
  };

  openPopup = () => {
    if (!this.state.majorityAge) {
      this.refs.WizardPersonalInfoModal.show();
    } else {
      this.setState({
        majorityAge: false,
      });
    }
  };

  closePopup = () => {
    this.setState({
      majorityAge: true,
    });

    this.refs.WizardPersonalInfoModal.hide();
  };

  submitFormHandler = (values) => {
    //workaround TODO refactoring
    const { onSubmit } = this.props;
    let profile_subtype = null;
    let profile_type = null;
    switch (values.profile_type) {
      case 'artist' : {
        profile_type = values.explicit_type !== 'artist' ? values.explicit_type : values.profile_type;
        profile_subtype = values.explicit_type === 'group' && values.is_band === 'true' ? 'band' : 'basic';
        break;
      }
      case 'venue' : {
        profile_type = values.profile_type;
        profile_subtype = 'basic';
        break;
      }
      case 'client' : {
        profile_type = values.profile_type;
        profile_subtype = values.explicit_type !== 'client' ? values.explicit_type : 'basic';
        break;
      }
      case 'fan' : {
        profile_type = values.profile_type;
        profile_subtype = 'basic';
        break;
      }
    }
    onSubmit({
      email: values.email,
      password: values.password,
      profile_subtype: profile_subtype,
      profile_type: profile_type,
    });
  };

  render() {
    const {
      submitting,
      pristine,
      invalid,
      currentUser,
      currentForm,
      handleSubmit,
    } = this.props;

    let hasProfileType = false;
    let hasAdditionalFields = false;
    let hasEmail = false;
    let hasAuthType = false;
    let hasPassword = false;
    let hasAgreement = false;

    if (currentForm.values && currentForm.values.auth_type) {
      hasAuthType = true;
    }

    if (currentForm && currentForm.values) {
      hasProfileType = !!currentForm.values.profile_type;
      hasAdditionalFields = this.state.hasAdditionalFields;
      hasEmail = !!currentForm.values.email;
      hasPassword = !!currentForm.values.password && !!currentForm.values.confirmPassword;
      hasAgreement = !!currentForm.values.agreement2;
    }

    const showRegisterButton = hasPassword || hasAuthType;

    return (
      <div className="register-page">
        <form autoComplete="off" onSubmit={handleSubmit(data => this.submitFormHandler(data))}>
          <div className="account-info-form__item-header">

            <h4 className="account-info-form__title">
            {translate('registerfrom.accountInfo')}
            </h4>
          </div>
          <div className="radio-group-wrapper">
            <label className="radio-group radio-inline at-artist">
              <Field component="input"
                value="artist"
                type="radio"
                className="artist d-none"
                name="profile_type"
                disabled={currentUser && currentUser.profile_type}/>
              <span>{translate('registerfrom.artist')}</span>
            </label>
            <label className="radio-group radio-inline at-venue">
              <Field component="input"
                value="venue"
                type="radio"
                className="venue d-none"
                name="profile_type"
                disabled={currentUser && currentUser.profile_type}/>
              <span>{translate('registerfrom.venue')}</span>
            </label>
            <label className="radio-group radio-inline at-client">
              <Field component="input"
                value="client"
                type="radio"
                className="client-wrapper d-none"
                name="profile_type"
                disabled={currentUser && currentUser.profile_type}/>
              <span>{translate('registerfrom.client')}</span>
            </label>
            {/*<label className="radio-group radio-inline at-fan">
              <Field component="input"
                value="fan"
                type="radio"
                className="fan d-none"
                name="profile_type"
                disabled={currentUser && currentUser.profile_type}/>
              <span>{translate('registerfrom.fan')}</span>
            </label>*/}
          </div>

          {
            hasProfileType && (<div>
                <RegisterAdditionalFields
                  profileType={currentForm.values.profile_type}
                  onFilled={this.handleFinishFillingAdditionalInfo}
                  onMissing={this.handleMissingAdditionalInfo}
                  formValues={currentForm.values}
                />

                <Field
                  name="password"
                  type="hidden"
                  component={RenderField}
                  placeholder="password"
                />
              </div>)
          }

          {
            hasAdditionalFields && (
              <div>
                 { hasAuthType ? (
                    <Field
                      name="auth_type"
                      label={'You are connected through'}
                      component={RegistedWithInfo}
                    />
                    )
                    : (
                      <div>
                        <div className="account-info-form__item-header mt-2">
                          <span className="fa-stack info-popup-window" data-title={registrationConfig.popupText.emailAndLogin}>
                            <i className="fa fa-circle-thin fa-stack-2x"></i>
                            <i className="fa fa-info fa-stack-1x"></i>
                          </span>
                          <h4 className="account-info-form__title">
                          {translate('registerfrom.passwordInfo')}
                          </h4>
                        </div>

                        <Field
                          name="email"
                          type="text"
                          component={RenderField}
                          autoComplete="new-password"
                          classNames="input-field"
                          placeholder="Email"
                        />

                        <div>
                          <div className="password-field">
                            <Field name="password"
                              type={this.state.showPassword ? 'text' : 'password'}
                              component={RenderField}
                              autoComplete="new-password"
                              classNames="input-field"
                              placeholder={translate('registerfrom.pass')}/>
                            <ShowPassword isShown={this.state.showPassword}
                              toggleView={this.setPasswordVisibility}/>
                          </div>

                          <div className="password-field">
                            <Field name="confirmPassword"
                              type={this.state.showPassword ? 'text' : 'password'}
                              component={RenderField}
                              autoComplete="new-password"
                              classNames="input-field"
                              placeholder={translate('registerfrom.cPass')}/>
                            <ShowPassword isShown={this.state.showPassword}
                              toggleView={this.setPasswordVisibility}/>
                          </div>
                        </div>
                      </div>
                      )
                  }

                      {
                        (showRegisterButton) ? (<div>
                          <Field
                            name='opt_in'
                            classNameInput="confirm-second"
                            component={CheckboxWrapper}>
                             <span className="register-page__label">
                             {translate('registerfrom.confirmSecond')}
                            </span>
                          </Field>
                          <Field
                            name='agreement2'
                            classNameInput="confirm-third"
                            component={CheckboxWrapper}>
                            <span className="register-page__label">
                              {translate('registerfrom.confirmThird1')}
                              <Link className="register-page--link" target="_blank" to={process.env.PUBLIC_URL + '/gigworks-privacy.pdf'}> {translate('registerfrom.confirmThird2')} </Link>
                              {translate('registerfrom.confirmThird3')}
                              <Link className="register-page--link" target="_blank" to={process.env.PUBLIC_URL + '/gigworks-terms.pdf'}> {translate('registerfrom.confirmThird4')}</Link>.
                            </span>
                          </Field>
                        </div>)
                        : null
                      }

                      {
                        hasAgreement ?
                        <button className="btn btn-block login-btn"
                          disabled={submitting || pristine || invalid}
                          type="submit">
                          <b>{translate('registerfrom.register')}</b>
                        </button> :
                        null
                      }
              </div>)
            }
        </form>

        <AlertPopUp
          ref="WizardPersonalInfoModal"
          title={`ALERT MESSAGE`}
          text={`You have confirmed that you are over the age of 18
           / Ich bestätige hiermit, dass Ich mindestens 18 Jahre alt bin`}
          okBtn={{ label: `Yes, I understand`, onClick: this.closePopup }}
        />
      </div>
    );
  }
}

RegisterForm = reduxForm({
  form: 'registerForm',
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: false,
  enableReinitialize: true,
  touchOnChange: true,
  touchOnBlur: true,
  validate,
})(RegisterForm);

export default connect(
    ({ user, form }) => ({
      currentUser: user.currentUser,
      initialValues: user.currentUser ? user.currentUser : {},
      currentForm: form.registerForm || { values: {} }
    }),
    {},
    (stateProps, dispatchProps, ownProps) => { return {...stateProps, ...dispatchProps, ...ownProps} }
)(RegisterForm);

export class CheckboxWrapper extends Component {
  UNSAFE_componentWillReceiveProps(newProps) {
    if ((newProps.checked || newProps.checked === false) && newProps.checked !== this.props.input.value) {
      this.props.input.onChange(newProps.checked);
    }
  }

  render() {
    const {
      input: { value, onChange },
      meta: { touched, error },
      children,
      classNameInput,
      ...checkboxProps
    } = this.props;

    return (
      <div className="register-page__checkbox-wrapper d-flex">
        <Checkbox
          style={{ width: 'auto' }}
          className={classNameInput}
          labelStyle={{ width: 'auto' }}
          checked={!!value}
          onCheck={() => onChange(!value)}
          {...checkboxProps} />
        {children}
      </div>
    );
  }
}

export class RegistedWithInfo extends Component {
  render(){
      const { input, label } = this.props;

      console.log('RegistedWithInfo', input, label)

      return <div className={`RegisteredWith__Wrapper ${input.value}`}>
        <h4 className={`bg-${input.value}`}>
          <input {...input} type="hidden" />
          { label } <b>{input.value}</b>
        </h4>
      </div>

  }
}
