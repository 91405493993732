import './HomeAction.scss';
import React from 'react';
import { LandingButton } from '../LandingButton/LandingButton';
import classNames from 'classnames';
import { block } from 'bem-cn';

const b = block('HomeAction');

export const HomeAction = (props) => {
  const { title, description, linkTitle, linkPath, className } = props;

  return (
    <div className={b()}>
      <h1 className={b('title')}>{title}</h1>
      <p className={b('description')}>{description}</p>
      <LandingButton className={classNames(className, b('button'))} large to={linkPath}>{linkTitle}</LandingButton>
    </div>
  );
};
