import moment from 'moment'
export const GET_COUPONS_LIST_START = 'GET_COUPONS_LIST_START'
export const GET_COUPONS_LIST_SUCCESS = 'GET_COUPONS_LIST_SUCCESS'
export const GET_COUPONS_LIST_FAIL = 'GET_COUPONS_LIST_FAIL'

const initialState = {
	allCoupons: [],
	isGettingCoupons: false,
}

export default function coupons(state = initialState, action) {
	switch (action.type) {
		case GET_COUPONS_LIST_START:
			return {
				...state,
				isGettingCoupons: true,
			}

		case GET_COUPONS_LIST_SUCCESS:
			return {
				...state,
				isGettingCoupons: false,
				allCoupons: action.result.data,
			}

		case GET_COUPONS_LIST_FAIL:
			return {
				...state,
				isGettingCoupons: false,
			}

		default:
			return state
	}
}

export function getCoupons() {
	return {
		types: [GET_COUPONS_LIST_START, GET_COUPONS_LIST_SUCCESS, GET_COUPONS_LIST_FAIL],
		promise: client => client.get('/api/promotions/get-for-user')

		//Promise.resolve({data:  [
		//	{
		//		usage_area: 'PLAN',
		//		id: 1,
		//		expires: moment().add(5, 'days'),
		//		value: '100%',
		//		target: 'ALL', // plan?
		//		type: 'LAUNCH', // LAUNCH, INSTANT
		//		valid_for: '6 months',
		//		applicable_for: 'PLAN', // ['venue.basic', 'artist.basic' ]
		//		code: '5aBV43gh8'
		//	},
		//	{
		//		usage_area: 'PLAN',
		//		id: 3,
		//		expires: moment().add(2, 'days'),
		//		value: '25%',
		//		sub_value: 'PREMIUM',
		//		target: 'ALL', // plan?
		//		type: 'LAUNCH', // LAUNCH, INSTANT
		//		valid_for: '6 months',
		//		applicable_for: [2,14,5], // ['venue.basic', 'artist.basic' ]
		//		code: 'G45Hj%$fds'
		//	},
		//	{
		//		usage_area: 'GIG',
		//		id: 4,
		//		expires: moment().add(45, 'days'),
		//		value: '25$',
		//		target: 'ALL', // plan?
		//		type: 'INSTANT', // LAUNCH, INSTANT
		//		valid_for: '6 months',
		//		applicable_for: [5,11,20], // ['venue.basic', 'artist.basic', '' ]
		//		code: '8Poda$%g3'
		//	},
		//	{
		//		usage_area: 'GIG',
		//		id: 4,
		//		expires: moment().add(45, 'days'),
		//		value: '5$',
		//		target: 'ALL', // plan?
		//		type: 'INSTANT', // LAUNCH, INSTANT
		//		valid_for: '12 months',
		//		applicable_for: [5,11,20], // ['venue.basic', 'artist.basic', '' ]
		//		code: '8Poda$%g3',
		//		background_img: 'https://backend.gigworks.apps-garden.com/storage/category_images/cake_designer.jpg'
		//
		//	}
		//]
		//}) //client => client.get(`/api/billing/transfer-list`),
	}
}
