import React, { Component } from 'react';
import PropTypes from 'prop-types';
export default class ShowPassword extends Component {
  static propTypes = {
    isShown: PropTypes.bool.isRequired,
    toggleView: PropTypes.func.isRequired
  };

  toggle = ()=>{
    const { toggleView, isShown } = this.props;

    toggleView(!isShown);
  };

  constructor(props) {
    super(props);
  }

  render() {
    const { isShown } = this.props;

    return (
      <button
        type="button"
        className="btn btn-link toggle-password"
        onClick={this.toggle}>
        {!isShown ?
          <img className="password-icon" src={require('../assets/login-closed-eye.svg')} alt={'hide'}/>
          :
          <img className="password-icon" src={require('../assets/login-eye.svg')} alt={'show'}/>
        }
      </button>
    );
  }
}
