import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import sortBy from 'lodash/sortBy';
import get from 'lodash/get';
import Collapse from 'antd/es/collapse/Collapse';
import 'antd/es/collapse/style/css';
import { RadioButton, RadioButtonGroup } from 'material-ui/RadioButton';
import { createSubcategorySelector } from '../../../../redux/selectors/category';
import './SearchBlockMobile.scss';
import Button from '../../../common/Button/Button';

const Panel = Collapse.Panel;

class SearchBlockMobile extends Component {
  state = {
    collapseActiveKey: null,
    isOpenedMobileModalSearch: false,
    subcategoryIds: [],
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
      if(nextProps.subcategoryIds !== this.props.subcategoryIds) {
          this.setState({ subcategoryIds: nextProps.subcategoryIds })
      }
  }

  onChangeCategories = id => {
    const { onChangeMainCategory } = this.props;

    this.onMobileModalSwitch()
    onChangeMainCategory({ id: id });
  };

  onMobileModalSwitch = () =>
    this.setState(prevState => ({
      isOpenedMobileModalSearch: !prevState.isOpenedMobileModalSearch
    }));

  render() {
    const { categories, categoriesMap, parentCategoryId, selectedCategory } = this.props;
    const { collapseActiveKey, isOpenedMobileModalSearch } = this.state;
    const categoriesSorted = sortBy(categories, 'name');

    return (
      <div className="SearchBlockMobile">
        <div className="header d-flex justify-content-between">
          <h3>
            {
              get(categoriesMap, `${selectedCategory ? selectedCategory.id : parentCategoryId}.name`)
            }
          </h3>
          <div
            onClick={this.onMobileModalSwitch}
            className="switchModalSearchMenu d-flex align-items-center">
            <span>Select Talent you'd like to view</span>
            <i className="fa fa-angle-right" />
          </div>
        </div>
        {
          isOpenedMobileModalSearch
            && (
              <div className="SearchBlockMobile__modal">
                <h3 className="title">{get(categoriesMap, `${parentCategoryId}.name`, '')}
                  <i
                    onClick={this.onMobileModalSwitch}
                    className="fa fa-angle-left" />
                </h3>
                <Collapse
                  accordion
                  onChange={(value) => {
                      if(value) {
                          const selectedId = Array.isArray(value) ? value.slice(-1) : value
                          const category = categoriesSorted[selectedId]

                          if(category) {
                              this.props.onChangeMainCategory(category);
                              this.setState({ collapseActiveKey: selectedId, subcategoryIds: [] });
                          }
                      }
                      else {
                          this.setState({ collapseActiveKey: null })
                      }
                  }}
                  activeKey={collapseActiveKey}>
                  {
                    categoriesSorted.map((category, idx) =>
                      <Panel
                        isActive={true}
                        key={idx}
                        header={<div>{category.name} <span>({category.users_count})</span></div>}>
                        <div>
                            {this.state.subcategoryIds.map(({ style, isSelected }) => {
								return (
									<span
										className={'subcategory-item' + (isSelected ? ' selected' : '')}
										onClick={() => this.props.switchSubcategory(style.id)}
									>
										{style.name}
										&nbsp;
										{/* <span className="users-count">({category.users_count})</span> */}
									</span>
								)
                            })}
                            <div style={{ padding: '0px 10px', border: 'none' }}>
                                <Button primary fullWidth label={'Show'} onClick={this.onMobileModalSwitch}/>
                            </div>
                        </div>
                      </Panel>
                    )
                  }
                </Collapse>
              </div>
            )
        }
      </div>
    );
  }
}

SearchBlockMobile.propTypes = {
  parentCategoryId: PropTypes.string,
  onChange: PropTypes.func
};

export default connect(createSubcategorySelector('common.artistsCategories.all'))(SearchBlockMobile);
