import React from 'react';
import { InlinePreLoader } from '../../common/InlinePreLoader/InlinePreLoader';

import './Loader.scss';

const Loader = props => (
  <div className="loader-container" style={props.style}>
    <InlinePreLoader />
  </div>
);

export default Loader;
