const validate = values => {
  const errors = {};

  if (!values.auth_type) {

    if (!values.email) {
      errors.email = 'Required';
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,10}$/i.test(values.email)) {
      errors.email = 'Invalid email address';
    }
    if (!values.password) {
      errors.password = 'Required';
    } else if (!/[A-Z]/.test(values.password)) {
      errors.password = 'Password should contain at least 1 capital letter';
    } else if (!/[0-9]/.test(values.password)) {
      errors.password = 'Password should contain at least 1 numeric character';
    } else if (!/[a-z]/.test(values.password)) {
      errors.password = 'Password should contain at least 1 small letter';
    } else if (!/[^A-Za-z0-9]/.test(values.password)) {
      errors.password = 'Password should contain at least 1 special character';
    } else if (values.password.length < 8) {
      errors.password = 'Password should be at least 8 characters';
    }

    if (!errors.password && values.password !== values.confirmPassword) {
      errors.confirmPassword = 'No password match.';
    }
  }

  return errors;
};

export default validate;
