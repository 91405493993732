const validate = values => {
	const errors = {};

	if (!values.name) {
		errors.name = 'Required';
	}
  if (!values.manager_first_name) {
    errors.manager_first_name = 'Required'
  }
  if (!values.manager_last_name) {
    errors.manager_last_name = 'Required'
  }
  if (!values.manager_company_title) {
    errors.manager_company_title = 'Required'
  }
  if (!values.manager_birth_date) {
    errors.manager_birth_date = 'Required'
  }
  if (!values.over18) {
    errors.over18 = 'Required'
  }
  if (!values.nationality) {
    errors.nationality = 'Required'
  }
  if (values.manager_location) {
    if (!values.manager_location.street) {
      errors.manager_location = 'Street required'
    }
    else if (!values.manager_location.city) {
      errors.manager_location = 'City required'
    }
    else if (!values.manager_location.state) {
      errors.manager_location = 'State required'
    }
    else if (!values.manager_location.postal_code) {
      errors.manager_location = 'Zip code required'
    }
    else if (!values.manager_location.country) {
      errors.manager_location = 'Country required'
    }
    else if (values.manager_location.formatted_address && !values.manager_location.place_id) {
      errors.manager_location = 'You must select a city from the list'
    }
  }

	return errors;
};

export default validate
