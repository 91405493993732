import React, { Component } from 'react'
import { Field, reduxForm } from 'redux-form'
import validate from '../../../helpers/validators/venue/BusinessInfo'
import Checkbox from 'material-ui/Checkbox'
import TextField from 'material-ui/TextField'
import '../../../styles/components/venue/WizardBusinessInfo.scss'
import { connect } from 'react-redux'
import { getValues, adjustQuestionsAnswersResponse } from '../../../helpers/methods'
import { TextFieldComponent, TimePickerComponent, SelectFieldComponent } from '../../CommonComponent'
import { ServiceInfoCheckBoxGroup } from '../CommonComponent'
import classnames from 'classnames'
import { Asterisk } from '../../common/Asterisk/Asterisk'
import { registrationConfig } from '../../../config'
import QuestionsDropdown from '../shared/QuestionsDropdown'
import {dropdownQuestions, MAX_BRIEF_INTRODUCTION_LENGTH} from '../../../utils/constants'

export class WizardBusinessInfo extends Component {
	render() {
	  
		return (
		  <div>
				<div className="business-info-form__wrapper">
					<BusinessInfoFirstForm />
				</div>
				<div className="business-info-form__wrapper">
					<Field
						name="questions_answers"
						component={QuestionsDropdown}
						questions={dropdownQuestions.venueQuestions}
						label="Tell us more about your venue (With proposed questions below)"/>
				</div>
				<div className="business-info-form__wrapper">
					<ServiceInfoCheckBoxForm />
				</div>
			</div>
		)
	}
}

WizardBusinessInfo = reduxForm({
	form: 'BookingInfo',
	destroyOnUnmount: false,
	forceUnregisterOnUnmount: false,
	enableReinitialize: true,
	validate,
})(WizardBusinessInfo)

export default connect(({ user, form }) => ({
	currentUser: user.currentUser,
	initialValues: getValues(
		form.BookingInfo,
		user.currentUser && user.currentUser.profile
			? {
					brief_introduction: user.currentUser.profile.brief_introduction,
					about: user.currentUser.profile.about,
					operation_hours: user.currentUser.profile.operation_days,
					type_of_food: user.currentUser.profile.type_of_food,
					attributes: user.currentUser.profile.attributes,
					serves: user.currentUser.profile.serves,
					drinks: user.currentUser.profile.drinks,
					payment_options: user.currentUser.profile.payment_options,
					accessible_by: user.currentUser.profile.accessible_by,
					smoking: user.currentUser.profile.smoking,
					dress_code: user.currentUser.profile.dress_code,
					equipment: user.currentUser.profile.equipment,
					parking_types: user.currentUser.profile.parking_types,
					business_services: user.currentUser.profile.business_services,
					facilities: user.currentUser.profile.facilities,
					features_and_guest_services: user.currentUser.profile.features_and_guest_services,
					recreational_services: user.currentUser.profile.recreational_services,
					setting: user.currentUser.profile.setting,
					questions_answers: adjustQuestionsAnswersResponse(user.currentUser.answers) || []
			  }
			: {}
	),
}))(WizardBusinessInfo)

class BusinessInfoFirstForm extends Component {
	render() {
		const { filled } = this.props

    const maxLength = max => value => {

      let v;
      let result = value.length > max;

      if(result === false) {
        v = value;
      }
      return v;
    };

		return (
			<div className="business-info-form__item business-info-form__content">
				<div className="business-info-form__item-header">
					<span className="fa-stack info-popup-window" data-title={registrationConfig.popupText.venueBriefIntroduction}>
						<i className="fa fa-circle-thin fa-stack-2x" />
						<i className="fa fa-info fa-stack-1x" />
					</span>
					<h4 className={classnames('service-info-form__title', { filled })}>
						Brief introduction <Asterisk /> <span className="description">(Max. 155 characters)</span>
					</h4>
				</div>
				<Field
					hintText="Start writing here"
					name="brief_introduction"
					type="text"
          inputStyle={{ color: '#000' }}
					fullWidth={true}
					style={{ margin: '10px 0 5px' }}
          normalize={maxLength(MAX_BRIEF_INTRODUCTION_LENGTH)}
          component={TextFieldComponent}
				/>
			</div>
		)
	}
}

const capacity = [
	{
		label: 'Standing',
		fieldName: 'standing',
	},
	{
		label: 'Seated',
		fieldName: 'seated',
	},
]

const operation_days = [
	{
		label: 'Monday',
		fieldName: 'monday'
	},
	{
		label: 'Tuesday',
		fieldName: 'tuesday'
	},
	{
		label: 'Wednesday',
		fieldName: 'wednesday'
	},
	{
		label: 'Thursday',
		fieldName: 'thursday'
	},
	{
		label: 'Friday',
		fieldName: 'friday'
	},
	{
		label: 'Saturday',
		fieldName: 'saturday'
	},
	{
		label: 'Sunday',
		fieldName: 'sunday'
	}
]

const hours = [
	{ label: '0:00', value: '000' },
	{ label: '0:30', value: '030' },
	{ label: '1:00', value: '100' },
	{ label: '1:30', value: '130' },
	{ label: '2:00', value: '200' },
	{ label: '2:30', value: '230' },
	{ label: '3:00', value: '300' },
	{ label: '3:30', value: '330' },
	{ label: '4:00', value: '400' },
	{ label: '4:30', value: '430' },
	{ label: '5:00', value: '500' },
	{ label: '5:30', value: '530' },
	{ label: '6:00', value: '600' },
	{ label: '6:30', value: '630' },
	{ label: '7:00', value: '700' },
	{ label: '7:30', value: '730' },
	{ label: '8:00', value: '800' },
	{ label: '8:30', value: '830' },
	{ label: '9:00', value: '900' },
	{ label: '9:30', value: '930' },
	{ label: '10:00', value: '1000' },
	{ label: '10:30', value: '1030' },
	{ label: '11:00', value: '1100' },
	{ label: '11:30', value: '1130' },
	{ label: '12:00', value: '1200' },
	{ label: '12:30', value: '1230' },
	{ label: '13:00', value: '1300' },
	{ label: '13:30', value: '1330' },
	{ label: '14:00', value: '1400' },
	{ label: '14:30', value: '1430' },
	{ label: '15:00', value: '1500' },
	{ label: '15:30', value: '1530' },
	{ label: '16:00', value: '1600' },
	{ label: '16:30', value: '1630' },
	{ label: '17:00', value: '1700' },
	{ label: '17:30', value: '1730' },
	{ label: '18:00', value: '1800' },
	{ label: '18:30', value: '1830' },
	{ label: '19:00', value: '1900' },
	{ label: '19:30', value: '1930' },
	{ label: '20:00', value: '2000' },
	{ label: '20:30', value: '2030' },
	{ label: '21:00', value: '2100' },
	{ label: '21:30', value: '2130' },
	{ label: '22:00', value: '2200' },
	{ label: '22:30', value: '2230' },
	{ label: '23:00', value: '2300' },
	{ label: '23:30', value: '2330' }
]

class ServiceInfoCheckBoxForm extends Component {
	render() {
		return (
			<div className="business-info-form__item mt-4 mt-sm-5">
				<Field
					name="operation_hours"
					component={OperationHours}
					required={false}
					operation_days={operation_days}
				/>
				<p style={{ marginBottom: '3rem', fontSize: '0.75rem' }}>The information below is optional. Although we encourage you to fill out as much as possible to ensure clients find your services.</p>
				<Field
					name="type_of_food"
					groupName="Type of Food"
					required={false}
					optionNames={['African', 'Albanian', 'American', 'Arabic', 'Argentine', 'Asian', 'Australian & New Zeland', 'Austrian', 'Belgian', 'Brazilian', 'Bulgarian', 'Canadian', 'Caribbean', 'Chinese', 'Creole', 'Croatian', 'Czech', 'Dutch', 'Egyptian', 'English', 'Finnish', 'French', 'Georgian', 'German', 'Greek', 'Hungarian', 'Icelandic', 'Indian', 'Indonesian', 'Irish', 'Israeli', 'Italian', 'Japanese', 'Korean', 'Lebanese', 'Mexican', 'Moroccan', 'Norwegian', 'Oriental', 'Polish', 'Romanian', 'Russian', 'Scandinavian', 'Serbian', 'South American', 'Spanish', 'Swedish', 'Swiss', 'Tex Mex', 'Thai', 'Tunisian', 'Turkish', 'Ukrainian', 'Vietnamese']}
					component={ServiceInfoCheckBoxGroup}
				/>
				<hr/>
				<Field
					name="attributes"
					groupName="Attributes"
					required={false}
					optionNames={['Diet', 'Gluten-free', 'Healthy-food', 'Kosher', 'Low carb', 'Organic', 'Spicy', 'Vegan', 'Vegetarian']}
					component={ServiceInfoCheckBoxGroup}
				/>
				<hr/>
				<Field
					name="serves"
					groupName="Serves"
					required={false}
					optionNames={['Appetizers', 'Barbecue', 'Breakfast', 'Cakes & Bakery', 'Coffee', 'Dessert', 'Dinner', 'Finger Food', 'Fish', 'Fries', 'Fruits', 'Ice Cream', 'Lunch', 'Main Courses', 'Meat', 'Pasta', 'Rice', 'Salads', 'Sandwiches', 'Seafood', 'Snacks', 'Soups', 'Tapas']}
					component={ServiceInfoCheckBoxGroup}
				/>
				<hr/>
				<Field
					name="drinks"
					groupName="Drinks"
					required={false}
					optionNames={['Alcoholic Drinks', 'Cocktails', 'Non Alcoholic', 'Smoothies', 'Soft Drinks']}
					component={ServiceInfoCheckBoxGroup}
				/>
				<hr/>
				<Field
					name="payment_options"
					groupName="Payment Options"
					required={false}
					optionNames={['Bank Transfers', 'Cash', 'Credit Card', 'Debit Card']}
					component={ServiceInfoCheckBoxGroup}
				/>
				<hr/>
				<Field
					name="accessible_by"
					groupName="Accessible by"
					required={false}
					optionNames={['Bus', 'Subway', 'Train', 'Shuttle', 'Taxi', 'Tram']}
					component={ServiceInfoCheckBoxGroup}
				/>
				<hr/>
				<Field
					name="smoking"
					groupName="Smoking"
					required={false}
					optionNames={['Yes', 'No', 'Smoking area only']}
					component={ServiceInfoCheckBoxGroup}
				/>
				<hr/>
				<Field
					name="dress_code"
					groupName="Suggested Attire / Dress Code"
					required={false}
					optionNames={['Anything goes', 'Beach', 'Business', 'Casual', 'Cosplay', 'Formal', 'Kinky', 'Latex', 'Leather', 'Naked', 'Semi-casual', 'Sexy', 'Themed']}
					component={ServiceInfoCheckBoxGroup}
				/>
				<hr/>
				<Field
					name="equipment"
					groupName="Equipment"
					required={false}
					optionNames={['Dance Floors', 'Flipchart', 'LCD Projector', 'Loading Dock', 'Piano', 'Portable Heaters', 'Portable Walls', 'Projection Screen', 'Staging']}
					component={ServiceInfoCheckBoxGroup}
				/>
				<hr/>
				<Field
					name="parking_types"
					groupName="Parking"
					required={false}
					optionNames={['Bus Parking', 'Complimentary Parking', 'Paid Parking', 'Street Parking', 'Valet Parking']}
					component={ServiceInfoCheckBoxGroup}
				/>
				<hr/>
				<Field
					name="business_services"
					groupName="Business Services"
					required={false}
					optionNames={['A/V Capabilities', 'Business Center', 'Video Conferencing', 'VIP Services']}
					component={ServiceInfoCheckBoxGroup}
				/>
				<hr/>
				<Field
					name="facilities"
					groupName="Facilities"
					required={false}
					optionNames={['All Inclusive', 'Braille Signs', 'Elevator', 'Environmentally Friendly', 'Free Shuttle', 'Lighting', 'Onsite Catering', 'Onsite Gift Shop', 'Onsite Restaurant', 'Onsite Security', 'Outside Caterers Allowed', 'Pet Friendly', 'Rental Car Services', 'Counter', 'Space - Outdoor', 'Space - Private', 'Space - Semi-Private', 'Terrace', 'Wheelchair Accessible']}
					component={ServiceInfoCheckBoxGroup}
				/>
				<hr/>
				<Field
					name="features_and_guest_services"
					groupName="Features and Guest Services"
					required={false}
					optionNames={['Wake Up Calls', 'Concierge Services', 'Internet', 'Laundry', 'Luggage Storage', 'Room Service', 'View - Garden', 'View - Mountain', 'View - Ocean / Water', 'View - Urban', 'Voicemail Box', 'Smoking Area', 'Non Smoking Area', 'Tech Support', 'Bar', 'Elevator', 'Environmentally Friendly', 'Space - Outdoor', 'Sound System', 'Sound Support']}
					component={ServiceInfoCheckBoxGroup}
				/>
				<hr/>
				<Field
					name="recreational_services"
					groupName="Recreational Services"
					required={false}
					optionNames={['Basketball', 'Billiards', 'Bowling', 'Golf on Grounds', 'Health Club', 'Indoor Pool', 'Outdoor Pool', 'Skiing', 'Spa / Salon', 'Tennis', 'Volleyball', 'Water Sports', 'Whirpool', 'Other']}
					component={ServiceInfoCheckBoxGroup}
				/>
				<hr/>
				<Field
					name="setting"
					groupName="Setting"
					required={false}
					optionNames={['Bay', 'Canyon', 'Cityscape', 'Coastline', 'Courtyard', 'Creek', 'Desert', 'Farm View', 'Forest', 'Garden', 'Golf Course', 'Historical Monument', 'Jungle', 'Lagoon / Pond', 'Lake', 'Landscaped Grounds', 'Meadow / Field', 'Mountains', 'No View', 'Ocean', 'Park', 'Rainforest', 'River', 'Rolling Hills', 'Skyline', 'Street', 'Valley', 'Vineyard', 'Water Feature', 'Waterfall']}
					component={ServiceInfoCheckBoxGroup}
				/>
				<hr/>
				{/* <Field
					name="capacity"
					component={ServiceInfoCheckBoxGroupInput}
					required={false}
					groupName="Capacity"
					optionsArray={capacity}
				/> */}
			</div>
		)
	}
}

class OperationHours extends Component {
	onValueChange = newValue => {
		this.props.input.onChange(newValue)
	}

	render() {
		const {
			operation_days,
			input: { value },
		} = this.props

		console.log("OperationHours", this. props)

		return (
			<div className="business-info-form__content">
				<h5 className="business-info-form__checkbox-title">
					Operation hours
				</h5>
				<div className="business-info-form__capacity d-block">
					{operation_days.map((day, index) => (
						<div className="d-inlinle" style={{marginTop: '-1rem'}} >
					    	<OperationDay day={day} value={value} onChange={this.onValueChange} />
						</div>
					))}
				</div>
			</div>
		)
	}
}

class OperationDay extends Component {
	onCheckBoxChange = isChecked => {
		const { day, value, onChange } = this.props
		if (isChecked) {
			let newValue = Object.assign({}, value)
			newValue[day.fieldName] = { from: '', to: '' }
			onChange(newValue)
		} else {
			let newValue = Object.assign({}, value)
			delete newValue[day.fieldName]
			onChange(newValue)
		}
	}

	onFromTimeChange = (evt, val) => {
		const { day, value, onChange } = this.props

		let newValue = Object.assign({}, value)
		newValue[day.fieldName].from = val
		onChange(newValue)
	}

	onToTimeChange = (evt, val) => {
		const { day, value, onChange } = this.props

		let newValue = Object.assign({}, value)
		newValue[day.fieldName].to = val
		onChange(newValue)
	}

	render() {
		const { day, value, onChange } = this.props

		let isChecked = typeof value[day.fieldName] !== 'undefined'
		let labelStyle = isChecked ? { fontWeight: '500' } : { fontWeight: '300' }

		return (
			<div className="business-info-form__checkbox-wrapper">
				<div className="business-info-form__container operation-day">
					<Checkbox
						className="business-info-form__container__checkbox mt-2 mb-0"
						checked={isChecked}
						onCheck={() => this.onCheckBoxChange(!isChecked)}
						label={day.label}
						id={day.label}
						type="checkbox"
						labelStyle={{ ...labelStyle, color: '#000000' }}
						// style={{ marginLeft: '0rem'}}
					/>
				</div>

				{isChecked && (
                    <div className="d-flex flex-wrap hour-wrapper">
                        <div className="operation-hour">
                            <Field
                                name={day.fieldName + "_from"}
                                style={{ fontSize: '0.9rem', textAlign: 'center' }}
                                fullWidth
                                maxHeight={250}
                                hintText="From"
                                component={SelectFieldComponent}
                                onChange={this.onFromTimeChange}
                                value={value[day.fieldName].from}
                                options={hours}
                            />
                        </div>
                        <div className="operation-hour">
                            <Field
                                name={day.fieldName + "_to"}
                                style={{ fontSize: '0.9rem', textAlign: 'center' }}
                                fullWidth
                                maxHeight={250}
                                hintText="To"
                                component={SelectFieldComponent}
                                onChange={this.onToTimeChange}
                                value={value[day.fieldName].to}
                                options={hours}
                            />
                        </div>
                    </div>
				)}
			</div>
		)
	}
}

class ServiceInfoCheckBoxGroupInput extends Component {
	onValueChange = newValue => {
		this.props.input.onChange(newValue)
	}

	render() {
		const {
			groupName,
			optionsArray,
			input: { value },
			required,
		} = this.props
		let options = []
		optionsArray.forEach((option, index) => {
			options.push(<ServiceInfoCheckBoxInput option={option} onChange={this.onValueChange} value={value} key={index} />)
		})

		return (
			<div className="business-info-form__content">
				<h5 className="business-info-form__checkbox-title">
					{groupName} {required && <Asterisk />}
				</h5>
				<div className="business-info-form__checkbox-wrapper business-info-form__capacity">{options}</div>
			</div>
		)
	}
}

class ServiceInfoCheckBoxInput extends Component {
	onCheckBoxChange = isChecked => {
		const { option, value, onChange } = this.props
		if (isChecked) {
			let newValue = Object.assign({}, value)
			newValue[option.fieldName] = ''
			onChange(newValue)
		} else {
			let newValue = Object.assign({}, value)
			delete newValue[option.fieldName]
			onChange(newValue)
		}
	}

	onInputChange = peopleQnt => {
		const { option, value, onChange } = this.props
		if (typeof value[option.fieldName] === 'string' && peopleQnt.match(/^[0-9]{0,4}$/i)) {
			let newValue = Object.assign({}, value)
			newValue[option.fieldName] = parseInt(peopleQnt, 10).toString()
			onChange(newValue)
		}
	}

	render() {
		const { option, value } = this.props

		console.log('Capacity value')
		console.log(value)
		console.log('isLabelDisabled')
		console.log(typeof value[option.fieldName] === 'undefined')
		console.log('Checked')
		console.log(typeof value[option.fieldName] === 'string')

		let labelStyle = typeof value[option.fieldName] === 'undefined' ? { fontWeight: '300' } : { fontWeight: '500' }
		let isLabelDisabled = typeof value[option.fieldName] === 'undefined'
		let peopleValue = parseInt(value[option.fieldName] || '00').toString() // (parseInt(value[option.fieldName] || '00').toString() === '') ? '00' : parseInt(value[option.fieldName]).toString();

		return (
			<div className="business-info-form__container business-info-form__container--full business-info-form__container--input">
				<div className="business-info-form__container--in">
					<Checkbox
						className="business-info-form__container__checkbox"
						checked={typeof value[option.fieldName] === 'string'}
						onCheck={() => this.onCheckBoxChange(!(typeof value[option.fieldName] === 'string'))}
						label={option.label}
						id={option.label}
						type="checkbox"
						labelStyle={{ ...labelStyle, color: '#000000' }}
						style={{ marginTop: '15px'}}
					/>
				</div>

				<div className="business-info-form__container--in">
					<div className="number-input">
						<TextField
							id={`${option.label}-input`}
							onChange={(event, val) => this.onInputChange(val)}
							hintStyle={{ color: isLabelDisabled ? 'rgba(0, 0, 0, 0.3)' : '#000000' }}
							value={peopleValue === '0' || peopleValue === 'NaN' ? '' : peopleValue}
							disabled={isLabelDisabled}
							type="number"
							min={0}
							max={999}
							style={{ width: '5rem', marginLeft: '1rem' }}
						/>
						<span className="unit">ppl</span>
					</div>
				</div>
			</div>
		)
	}
}
