import React from 'react';
import { IconButton } from 'material-ui';
import Rating from 'react-rating';
import SVGInline from 'react-svg-inline';
import { UserPic } from '../../../common/UserPic/UserPic';
import './SuggestedProfile.scss';
import { connect } from 'react-redux';

import { PRIMARY_COLOR } from '../../../../utils/constants'
const svg_compare = require('!!svg-inline-loader?classPrefix!../../../../assets/icon-2.svg');
const svg_plane = require('!!svg-inline-loader?classPrefix!../../../../assets/plane.svg');

import {
  addItemToCompare,
  removeItemFromCompare
} from '../../../../redux/modules/compare'

@connect(({ compare }) => ({
  itemsToCompare: compare.itemsToCompare,
}), {
  addItemToCompare,
  removeItemFromCompare,
})
class SuggestedProfile extends React.Component {
  constructor(props)  {
    super(props);
  }

  handleCompareClick = () => {
    const { profile, addItemToCompare, itemsToCompare, onRemove } = this.props;

    if (this.isCompared()) {
      onRemove({ id: profile.id });
    } else {
      addItemToCompare(profile);
    }
  }

  isCompared = () => {
    const { profile, itemsToCompare } = this.props;
    return itemsToCompare.some(x => profile.id === x.id)
  }

  render() {
    const { profile, itemsToCompare } = this.props;

    return (
      <div className='SuggestedProfile'>
        <div className="userPic">
          <UserPic src={profile.avatar}  profileCompleted ={true} />
        </div>

        <div className='fee'>
          {profile.fee_primary &&
             <span>
               <b>{profile.fee_primary.value} {profile.fee_primary.currency}</b> /{profile.fee_primary.period}
              </span>}
        </div>

        <div className='name'>
          <b>{profile.name}</b>
        </div>

        {profile.styles && profile.styles.length > 0 && <div className="type">
          {profile.styles[0]}
        </div>}

        {profile.location && <div className='location'>
          {profile.location.country} , {profile.location.city}
        </div>}

        <Rating
          className="rating"
          empty="fa fa-star-o fa-fw"
          initialRate={profile.average_rating}
          readonly
          full="fa fa-star fa-fw"
        />

        <IconButton
          disabled={itemsToCompare.length >= 3 && !this.isCompared()}
          className='compare-btn'
          onClick={this.handleCompareClick}>
          <SVGInline svg={svg_compare} fill={this.isCompared() ? PRIMARY_COLOR : "#000"} width={'1rem'}/>
        </IconButton>

        <div className='availability'>
          <span>Global availability</span>
          <SVGInline svg={svg_plane} fill='#000' width={'1rem'}/>
        </div>
      </div>
    );
  }
};

export default SuggestedProfile;
