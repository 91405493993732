import * as _ from 'lodash'

export const CREATE_PAYMENT_START = 'CREATE_PAYMENT_START'
export const CREATE_PAYMENT_SUCCESS = 'CREATE_PAYMENT_SUCCESS'
export const CREATE_PAYMENT_FAIL = 'CREATE_PAYMENT_FAIL'

export const SET_PAYMENT_IN_PROGRESS = 'SET_PAYMENT_IN_PROGRESS'
export const SET_PAYMENT_NOT_IN_PROGRESS = 'SET_PAYMENT_NOT_IN_PROGRESS'


const initialState = {
	currentPayment: null,
    isPaymentInProgress: false,
    currentStatus: null
}

export default function actions(state = initialState, action = {}) {
	switch (action.type) {
		case CREATE_PAYMENT_START:
			return {
				...state,
				isPaymentInProgress: true,
            }
        case CREATE_PAYMENT_SUCCESS:
            return {
                ...state,
                isPaymentInProgress: false,
            }
        case CREATE_PAYMENT_FAIL:
            return {
                ...state,
                isPaymentInProgress: false,
            }
        case SET_PAYMENT_IN_PROGRESS: {
            return {
                ...state,
                isPaymentInProgress: true,
            }
        }
        case SET_PAYMENT_NOT_IN_PROGRESS: {
            return {
                ...state,
                isPaymentInProgress: false,
            }
        }
        default:
			return {
				...state,
            }
    }
}

export function createPayment({ hash, transferType, cardId, bankAccountId, removeCardAfterRedirect, coupon }) {
	return {
		types: [CREATE_PAYMENT_START, CREATE_PAYMENT_SUCCESS, CREATE_PAYMENT_FAIL],
		promise: client => client.post(`/api/payment/pay-in`, {
			hash,
			method: transferType,
			card_id: cardId,
			bank_account_id: bankAccountId,
            ...(removeCardAfterRedirect && { remove_after_payment: removeCardAfterRedirect }),
            coupon
		}),
	}
}

export function setPaymentInProgress() {
    return {
        type: SET_PAYMENT_IN_PROGRESS,
    }
}

export function setPaymentNotInProgress() {
    return {
        type: SET_PAYMENT_NOT_IN_PROGRESS,
    }
}
