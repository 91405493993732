export const GET_ALL_MEDIA_COMMENTS_START = 'GET_ALL_MEDIA_COMMENTS_START'
export const GET_ALL_MEDIA_COMMENTS_SUCCESS = 'GET_ALL_MEDIA_COMMENTS_SUCCESS'
export const GET_ALL_MEDIA_COMMENTS_FAIL = 'GET_ALL_MEDIA_COMMENTS_FAIL'

export const ADD_COMMENT_TO_MEDIA_START = 'ADD_COMMENT_TO_MEDIA_START'
export const ADD_COMMENT_TO_MEDIA_SUCCESS = 'ADD_COMMENT_TO_MEDIA_SUCCESS'
export const ADD_COMMENT_TO_MEDIA_FAIL = 'ADD_COMMENT_TO_MEDIA_FAIL'

export const SAVE_MEDAI_LIST_ORDER_START = 'SAVE_MEDAI_LIST_ORDER_START'
export const SAVE_MEDAI_LIST_ORDER_SUCCESS = 'SAVE_MEDAI_LIST_ORDER_SUCCESS'
export const SAVE_MEDAI_LIST_ORDER_FAIL = 'SAVE_MEDAI_LIST_ORDER_FAIL'

const initialState = {
	comments: [],
	isCommentsFetching: false,
}

export default function media(state = initialState, action = {}) {
	switch (action.type) {
		case GET_ALL_MEDIA_COMMENTS_START:
			return {
				...state,
				isCommentsFetching: true,
			}

		case GET_ALL_MEDIA_COMMENTS_SUCCESS:
			return {
				...state,
				isCommentsFetching: false,
				comments: action.result.data,
			}
		case GET_ALL_MEDIA_COMMENTS_FAIL:
			return {
				...state,
				isCommentsFetching: false,
			}

		default:
			return state
	}
}

export function getMediaComments(mediaId) {
	return {
		types: [GET_ALL_MEDIA_COMMENTS_START, GET_ALL_MEDIA_COMMENTS_SUCCESS, GET_ALL_MEDIA_COMMENTS_FAIL],
		promise: client => client.get(`/api/media/${mediaId}/comments`),
	}
}

export function addCommentToMedia(mediaId, comment) {
	return {
		types: [ADD_COMMENT_TO_MEDIA_START, ADD_COMMENT_TO_MEDIA_SUCCESS, ADD_COMMENT_TO_MEDIA_FAIL],
		promise: client => client.post(`/api/media/${mediaId}/comments`, { content: comment }),
	}
}

export function saveMediaListOrder(media_list) {
	return {
		types: [SAVE_MEDAI_LIST_ORDER_START, SAVE_MEDAI_LIST_ORDER_SUCCESS, SAVE_MEDAI_LIST_ORDER_FAIL],
		promise: client => client.put(`/api/users/me`, { media_list }),
	}
}
