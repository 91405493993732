const validate = values => {
	const errors = {};
	if (!values.name) {
		errors.name = 'Required'
	}
  if (!values.manager_email) {
    errors.manager_email = 'Required'
  } else if (!validateEmail(values.manager_email)) {
    errors.manager_email = 'Invalid email address';
  }
	if (!values.established_since) {
		errors.established_since = 'Required'
	}
	if (!values.manager_first_name) {
		errors.manager_first_name = 'Required'
	}
	if (!values.manager_last_name) {
		errors.manager_last_name = 'Required'
	}
	if (!values.manager_nationality) {
    errors.manager_nationality = 'Required'
  }
  if (!values.over18) {
    errors.over18 = 'Required'
  }
	if (values.location) {
		if (!values.location.street) {
			errors.location = 'Street required'
		}
		else if (!values.location.city) {
			errors.location = 'City required'
		}
		else if (!values.location.state) {
			errors.location = 'State required'
		}
		else if (!values.location.postal_code) {
			errors.location = 'Zip code required'
		}
		else if (!values.location.country) {
			errors.location = 'Country required'
		}
		else if (values.location.formatted_address && !values.location.place_id) {
			errors.location = 'You must select a city from the list'
		}
	}

  if (values.manager_location) {
    if (!values.manager_location.street) {
      errors.manager_location = 'Street required'
    }
    else if (!values.manager_location.city) {
      errors.manager_location = 'City required'
    }
    else if (!values.manager_location.state) {
      errors.manager_location = 'State required'
    }
    else if (!values.manager_location.postal_code) {
      errors.manager_location = 'Zip code required'
    }
    else if (!values.manager_location.country) {
      errors.manager_location = 'Country required'
    }
    else if (values.manager_location.formatted_address && !values.manager_location.place_id) {
      errors.manager_location = 'You must select a city from the list'
    }
  }

	return errors
};

const validateEmail = (email) => {
  let re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email);
}

export default validate
