export const artistRegistrationProfileInfoSteps = [
  {
    target: '#content-wrapper > div.nav-menu > div > div:nth-child(2) > div > div.profile-edit__info.show-sm > div > div:nth-child(2) > button:nth-child(2)',
    content: 'You\'ll need to enter all the Group/Band/Artist\'s information in this section. The information you provide here will be displayed for clients who could potentially hire you. Please take your time to fill this out carefully.'
  },
  {
    target: '#content-wrapper > div.nav-menu > div > div:nth-child(2) > div > div.profile-edit__info.show-sm > div > div:nth-child(2) > button:nth-child(3)',
    content: 'Over here, please tell us your talent or your Group/Band\'s talent and add all the members here. If they are not registered with Gigworks, we can send them an invite on your behalf. You\'ll need to provide us with their email to do so.'
  },
  {
    target: '#content-wrapper > div.nav-menu > div > div:nth-child(2) > div > div.profile-edit__info.show-sm > div > div:nth-child(2) > button:nth-child(4)',
    content: 'Do write something witty or thoughtful about yourself or group. The brief description is a quick thought that makes you memorable to the client.'
  },
  {
    target: '#content-wrapper > div.nav-menu > div > div:nth-child(2) > div > div.profile-edit__info.show-sm > div > div:nth-child(2) > button:nth-child(5)',
    content: 'Next, establish your fees that you charge.'
  },
  {
    target: '#content-wrapper > div.nav-menu > div > div:nth-child(2) > div > div.profile-edit__info.show-sm > div > div:nth-child(2) > button:nth-child(6)',
    content: 'Read your cancellation policy carefully. It will be important in case there are any disputes with the client in the future.'
  },
  {
    target: '#content-wrapper > div.nav-menu > div > div:nth-child(2) > div > div.profile-edit__info.show-sm > div > div:nth-child(2) > button:nth-child(7)',
    content: 'KYC is extremely important. Please pay close attention to this. It is required by GDPR that every vendor who is paid must pass through KYC to receive funds on Gigworks. Please gather the appropriate documents and submit it as soon as you can.'
  },
  {
    target: '#content-wrapper > div.nav-menu > div > div:nth-child(2) > div > div.profile-edit__info.show-sm > div > div:nth-child(2) > button:nth-child(8)',
    content: 'When ready, you can choose a plan of your choice. You can start out with Free, however, do visit our promotion section to see if we are running any membership promotions before you sign up.'
  },
  {
    target: '#root-wrapper > div > div.RegisterWizard > div.profile-edit > div.type-switcher > button:nth-child(2)',
    content: 'Finally, don\'t forget to upload media to your profile. 2 types of media are required for your profile to go live.'
  },
];

export const artistRegistrationProfileMediaSteps = [
  {
    target: '#wizard-content > div > div > div > div.photo-editor-wrapper.photo-editor-wrapper--avatar > div > div.control > div > div.advanced-control-item.uploaded-config > div.canvas-wrapper',
    content: 'Give us your best smile. It\'s what most users will see on the platform.'
  },
  {
    target: '#wizard-content > div > div > div > div.photo-editor-wrapper.photo-editor-wrapper--photo > div > div.content > div > div.cover-preview',
    content: 'Your cover photo should make a statement. Make it a great photo.'
  },
  {
    target: '#wizard-content > div > div > div > div.photo-editor-wrapper.photo-editor-wrapper--preview > div > div.content > div > div.profile-picture-preview.col-12.col-sm-6 > div > div.width-card > div > div.preview-cover.preview-cover-component',
    content: 'Your Profile Card photo is extremely important. Most clients will click on your profile based on this card, so choose your photo wisely.'
  },
  {
    target: '#wizard-content > div > div > div > div.photo-editor-wrapper.photo-editor-wrapper--preview > div > div.content > div > div.profile-picture-upload.col-12.col-sm-6 > div > div.embed-responsive.embed-responsive-4by3',
    content: 'Finally, link a short video of your talent. It gives the potential client a quick glimpse of who you are as an artist.'
  },
  {
    target: '#content-wrapper > div.nav-menu > div > div:nth-child(2) > div > div.profile-edit__info.show-sm',
    content: 'As a reminder, 2 media are required for your profile to go live. Don\'t forget to upload it in these sections here.'
  },
];

export const vendorDashboardOverviewSteps = [
  {
    target: '#root-wrapper > div > div.DashBoard > div > div > div > div > div:nth-child(1) > div > div > div:nth-child(1) > button:nth-child(1)',
    content: 'Within the My Gigs section, everything related to gigs you\'ve Applied to, Created, Saved will be displayed here.'
  },
  {
    target: '#root-wrapper > div > div.DashBoard > div > div > div > div > div:nth-child(1) > div > div > div:nth-child(1) > button:nth-child(2)',
    content: 'Any messages you\'ve received or sent will show up here, organized by Talent or Gig.'
  },
  {
    target: '#root-wrapper > div > div.DashBoard > div > div > div > div > div:nth-child(1) > div > div > div:nth-child(1) > button:nth-child(3)',
    content: 'Gigs that you\'ve successfully created or been booked for will appear in your calendar.'
  },
  {
    target: '#root-wrapper > div > div.DashBoard > div > div > div > div > div:nth-child(1) > div > div > div:nth-child(1) > button:nth-child(4)',
    content: 'Your favorite Artists or Venues will appear here.'
  },
  {
    target: '#root-wrapper > div > div.DashBoard > div > div > div > div > div:nth-child(1) > div > div > div:nth-child(1) > button:nth-child(5)',
    content: 'To view your funds or manage your financial data, click on the Admin area.'
  },
  {
    target: '#root-wrapper > div > div.DashBoard > div > div > div > div > div.no-gutters.row > div.DashboardLayout__LeftColumn.d-none.d-md-block.border-right.pr-0.col-3.col-xl-2 > div:nth-child(2) > div > div:nth-child(1)',
    content: 'Any "Invites to Apply" or "Booking Confirmations" will appear here. Do confirm Apply or Confirmations promptly to make sure you get the gig.',
    styles: {
      spotlight: {
        backgroundColor: '#ffffff36'
      }
    }
  },
  {
    target: '#root-wrapper > div > div.DashBoard > div > div > div > div > div.no-gutters.row > div.DashboardLayout__LeftColumn.d-none.d-md-block.border-right.pr-0.col-3.col-xl-2 > div:nth-child(2) > div > div:nth-child(2)',
    content: 'If you\'ve Applied to any gigs, your timeline for that gig, contracts that clients send you will appear here. They will be updated accordingly. Don\'t forget to sign the contract after reviewing it carefully.',
    styles: {
      spotlight: {
        backgroundColor: '#ffffff36'
      }
    }
  },
  {
    target: '#root-wrapper > div > div.DashBoard > div > div > div > div > div.no-gutters.row > div.DashboardLayout__LeftColumn.d-none.d-md-block.border-right.pr-0.col-3.col-xl-2 > div:nth-child(2) > div > div:nth-child(3)',
    content: 'If you\'ve saved any interesting gigs, they will show up here.',
    styles: {
      spotlight: {
        backgroundColor: '#ffffff36'
      }
    }
  },
  {
    target: '#root-wrapper > div > div.DashBoard > div > div > div > div > div.no-gutters.row > div.DashboardLayout__LeftColumn.d-none.d-md-block.border-right.pr-0.col-3.col-xl-2 > div:nth-child(2) > div > div:nth-child(4)',
    content: 'When Gigworks Recommends any gigs to you, they will show up here.',
    styles: {
      spotlight: {
        backgroundColor: '#ffffff36'
      }
    }
  },
  {
    target: '#root-wrapper > div > div.DashBoard > div > div > div > div > div.no-gutters.row > div.DashboardLayout__LeftColumn.d-none.d-md-block.border-right.pr-0.col-3.col-xl-2 > div:nth-child(2) > div > div:nth-child(5)',
    content: 'When hiring an Artist or Venue, your Created gigs will appear here. Do follow the timeline and view Candidates that have applied. Contracts will need your signature in order to proceed to payment for the gig.',
    styles: {
      spotlight: {
        backgroundColor: '#ffffff36'
      }
    }
  },
  {
    target: '#root-wrapper > div > div.DashBoard > div > div > div > div > div.no-gutters.row > div.DashboardLayout__LeftColumn.d-none.d-md-block.border-right.pr-0.col-3.col-xl-2 > div:nth-child(2) > div > div:nth-child(6)',
    content: 'Gigs you\'ve applied to in the past or you\'ve created will appear here.',
    styles: {
      spotlight: {
        backgroundColor: '#ffffff36'
      }
    }
  },
  {
    target: '#root-wrapper > div > div.DashBoard > div > div > div > div > div.no-gutters.row > div.d-none.d-xl-block.border-left.pl-0.col-2',
    content: 'All updates pertaining to Applied or Created gigs will appear here.',
    styles: {
      spotlight: {
        backgroundColor: '#ffffff36'
      }
    }
  },
];

export const vendorDashboardAdminSteps = [
  {
    target: '#root-wrapper > div > div.DashBoard > div > div > div.no-gutters.row > div.DashboardLayout__LeftColumn.d-none.d-md-block.border-right.pr-0.col-12.col-sm-12.col-md-3.col-lg-2.col-xl-2 > div > a.AdminMenu__MenuItem.underlined.active',
    content: 'All transactions will show up in this area.',
    styles: {
      spotlight: {
        backgroundColor: '#ffffff36'
      }
    }
  },
  {
    target: '#root-wrapper > div > div.DashBoard > div > div > div.no-gutters.row > div.main.Admin.col-12.col-sm-12.col-md-9.col-lg-10.col-xl-10 > div > div > div > div > div:nth-child(1) > div > a:nth-child(1)',
    content: 'This will show any payments for gigs you\'ve hired a Vendor for.',
    styles: {
      spotlight: {
        backgroundColor: '#ffffff36'
      }
    }
  },
  {
    target: '#root-wrapper > div > div.DashBoard > div > div > div.no-gutters.row > div.main.Admin.col-12.col-sm-12.col-md-9.col-lg-10.col-xl-10 > div > div > div > div > div:nth-child(1) > div > a.active',
    content: 'Any contracts that you\'ve engaged in with a Client will show all the incoming funds here.',
    styles: {
      spotlight: {
        backgroundColor: '#ffffff36'
      }
    }
  },
  {
    target: '#root-wrapper > div > div.DashBoard > div > div > div.no-gutters.row > div.main.Admin.col-12.col-sm-12.col-md-9.col-lg-10.col-xl-10 > div > div > div > div > div:nth-child(1) > div > a:nth-child(3)',
    content: 'Any funds transferred or "Pay Outs" to your bank account will appear here along with any SEPA fee that may be applied.',
    styles: {
      spotlight: {
        backgroundColor: '#ffffff36'
      }
    }
  },
  {
    target: '#root-wrapper > div > div.DashBoard > div > div > div.no-gutters.row > div.main.Admin.col-12.col-sm-12.col-md-9.col-lg-10.col-xl-10 > div > div > div > div > div:nth-child(1) > div > a:nth-child(4)',
    content: 'If you\'ve purchased a membership plan, all fees paid will appear here.',
    styles: {
      spotlight: {
        backgroundColor: '#ffffff36'
      }
    }
  },
  {
    target: '#root-wrapper > div > div.DashBoard > div > div > div.no-gutters.row > div.DashboardLayout__LeftColumn.d-none.d-md-block.border-right.pr-0.col-12.col-sm-12.col-md-3.col-lg-2.col-xl-2 > div > a:nth-child(2)',
    content: 'In the "My Wallet" area, you will see how much you\'ve earned through contracts to date and once you\'ve attached a bank account to your profile, you can transfer or "Pay Out" to you bank, those funds.',
    styles: {
      spotlight: {
        backgroundColor: '#ffffff36'
      }
    }
  },
  {
    target: '#root-wrapper > div > div.DashBoard > div > div > div.no-gutters.row > div.DashboardLayout__LeftColumn.d-none.d-md-block.border-right.pr-0.col-12.col-sm-12.col-md-3.col-lg-2.col-xl-2 > div > a:nth-child(3)',
    content: 'To pay for any services, attach a credit card. Or to transfer paid contract funds, attach a bank account here to get paid.',
    styles: {
      spotlight: {
        backgroundColor: '#ffffff36'
      }
    }
  },
  {
    target: '#root-wrapper > div > div.DashBoard > div > div > div.no-gutters.row > div.DashboardLayout__LeftColumn.d-none.d-md-block.border-right.pr-0.col-12.col-sm-12.col-md-3.col-lg-2.col-xl-2 > div > a:nth-child(4)',
    content: 'Once you\'ve entered your tax ID, you\'ll only need to do this once.',
    styles: {
      spotlight: {
        backgroundColor: '#ffffff36'
      }
    }
  },
  {
    target: '#root-wrapper > div > div.DashBoard > div > div > div.no-gutters.row > div.DashboardLayout__LeftColumn.d-none.d-md-block.border-right.pr-0.col-12.col-sm-12.col-md-3.col-lg-2.col-xl-2 > div > a:nth-child(5)',
    content: 'Any promotions that you may receive will appear here, you have seen this in the Registration area as well.',
    styles: {
      spotlight: {
        backgroundColor: '#ffffff36'
      }
    }
  },
  {
    target: '#root-wrapper > div > div.DashBoard > div > div > div.no-gutters.row > div.DashboardLayout__LeftColumn.d-none.d-md-block.border-right.pr-0.col-12.col-sm-12.col-md-3.col-lg-2.col-xl-2 > div > a:nth-child(6)',
    content: 'To view the analytics for your account, it will show here.',
    styles: {
      spotlight: {
        backgroundColor: '#ffffff36'
      }
    }
  },
];

export const venueRegistrationProfileInfoSteps = [
  {
    target: '#content-wrapper > div.nav-menu > div > div:nth-child(2) > div > div.profile-edit__info.show-sm > div > div:nth-child(2) > button:nth-child(2)',
    content: 'You\'ll need to enter all the Venue\'s information in this section. The information you provide here will be displayed for clients who could potentially book you. If there are any other contacts that should be listed for your account, you can enter them here as well. Please take your time to fill this out carefully.'
  },
  {
    target: '#content-wrapper > div.nav-menu > div > div:nth-child(2) > div > div.profile-edit__info.show-sm > div > div:nth-child(2) > button:nth-child(3)',
    content: 'Over here, please tell us what your is Venue type, the styles of your venue and the occasions you service. If they are not registered with Gigworks, we can send them an invite on your behalf. You\'ll need to provide us with their email to do so.'
  },
  {
    target: '#content-wrapper > div.nav-menu > div > div:nth-child(2) > div > div.profile-edit__info.show-sm > div > div:nth-child(2) > button:nth-child(4)',
    content: 'Do write something witty or thoughtful about your Venue. The brief description is a quick thought that makes you memorable to the client.'
  },
  {
    target: '#content-wrapper > div.nav-menu > div > div:nth-child(2) > div > div.profile-edit__info.show-sm > div > div:nth-child(2) > button:nth-child(5)',
    content: 'Next, establish your fees that you charge and what capacity will your venue hold.'
  },
  {
    target: '#content-wrapper > div.nav-menu > div > div:nth-child(2) > div > div.profile-edit__info.show-sm > div > div:nth-child(2) > button:nth-child(6)',
    content: 'Read your cancellation policy carefully. It will be important in case there are any disputes with the client in the future.'
  },
  {
    target: '#content-wrapper > div.nav-menu > div > div:nth-child(2) > div > div.profile-edit__info.show-sm > div > div:nth-child(2) > button:nth-child(7)',
    content: 'KYC is extremely important. Please pay close attention to this. It is required by GDPR that every vendor who is paid must pass through KYC to receive funds on Gigworks. Please gather the appropriate documents and submit it as soon as you can.'
  },
  {
    target: '#content-wrapper > div.nav-menu > div > div:nth-child(2) > div > div.profile-edit__info.show-sm > div > div:nth-child(2) > button:nth-child(8)',
    content: 'When ready, you can choose a plan of your choice. You can start out with Free, however, do visit our promotion section to see if we are running any membership promotions before you sign up.'
  },
  {
    target: '#root-wrapper > div > div.RegisterWizard > div.profile-edit > div.type-switcher > button:nth-child(2)',
    content: 'Finally, don\'t forget to upload media to your profile. 2 types of media are required for your profile to go live.'
  },
];


export const venueRegistrationProfileMediaSteps = [
  {
    target: '#wizard-content > div > div > div > div.photo-editor-wrapper.photo-editor-wrapper--avatar > div > div.control > div > div.advanced-control-item.uploaded-config > div.canvas-wrapper',
    content: 'A logo would be recommended here. It\'s what most users will see on the platform.'
  },
  {
    target: '#wizard-content > div > div > div > div.photo-editor-wrapper.photo-editor-wrapper--photo > div > div.content > div > div.cover-preview',
    content: 'Your cover photo should make a statement. Make it a great photo.'
  },
  {
    target: '#wizard-content > div > div > div > div.photo-editor-wrapper.photo-editor-wrapper--preview > div > div.content > div > div.profile-picture-preview.col-12.col-sm-6 > div > div.width-card > div > div.preview-cover.preview-cover-component',
    content: 'Your Profile Card photo is extremely important. Most clients will click on your profile based on this card, so choose your photo wisely.'
  },
  {
    target: '#wizard-content > div > div > div > div.photo-editor-wrapper.photo-editor-wrapper--preview > div > div.content > div > div.profile-picture-upload.col-12.col-sm-6 > div > div.embed-responsive.embed-responsive-4by3',
    content: 'Finally, link a short video of your talent. It gives the potential client a quick glimpse of who you are as an artist.'
  },
  {
    target: '#content-wrapper > div.nav-menu > div > div:nth-child(2) > div > div.profile-edit__info.show-sm',
    content: 'As a reminder, 2 media are required for your profile to go live. Don\'t forget to upload it in these sections here.'
  },
];

export const vendorMyAccountSteps = [
  {
    target: '#root-wrapper > div > div:nth-child(2) > div > div.main-container > div.details > div.avatar-column > div > div > div > div.preview-card > div.info > div.UserPic.default.light',
    content: 'The Profile video you uploaded in the media section can be played here for any user visiting your card.'
  },
  {
    target: '#root-wrapper > div > div:nth-child(2) > div > div.main-container > div.details > div.table > div:nth-child(1)',
    content: 'If you purchase any services on the Gigworks platform, we will use this information to pay for those services.'
  },
  {
    target: '#root-wrapper > div > div:nth-child(2) > div > div.main-container > div.details > div.table > div:nth-child(2)',
    content: 'When receiving funds from clients, you will receive funds in this bank account.'
  },
  {
    target: '#root-wrapper > div > div:nth-child(2) > div > div.main-container > div.details > div.table > div:nth-child(3)',
    content: 'Don\'t forget to enter your tax ID here. It will be displayed on each contract you enter into on the platform.'
  },
];

export const clientMyAccountSteps = [
  {
    target: '#root-wrapper > div > div:nth-child(2) > div > div.main-container > div.details > div.table > div:nth-child(1)',
    content: 'If you purchase any services on the Gigworks platform, we will use this information to pay for those services.'
  },
  {
    target: '#root-wrapper > div > div:nth-child(2) > div > div.main-container > div.details > div.table > div:nth-child(3)',
    content: 'Don\'t forget to enter your tax ID here. It will be displayed on each contract you enter into on the platform.'
  },
];

export const clientRegistrationProfileInfoSteps = [
  {
    target: '#content-wrapper > div.nav-menu > div > div:nth-child(2) > div > div.profile-edit__info.show-sm > div > div:nth-child(2) > button:nth-child(2)',
    content: 'Please enter your personal or company\'s information here. This information will be listed on any Gig Cards or Contracts that you participate in. Please take your time to fill this out.'
  },
  {
    target: '#content-wrapper > div.nav-menu > div > div:nth-child(2) > div > div.profile-edit__info.show-sm > div > div:nth-child(2) > button:nth-child(3)',
    content: 'Do write something witty or thoughtful about yourself or company.'
  },
  {
    target: '#content-wrapper > div.nav-menu > div > div:nth-child(2) > div > div.profile-edit__info.show-sm > div > div:nth-child(2) > button:nth-child(4)',
    content: 'Every user on the platform would need to submit identification to our payment processor to verify their identity. You can simply take a quick picture, front and back of an ID card, upload and voila, you\'re done.'
  },
  {
    target: '#content-wrapper > div.nav-menu > div > div:nth-child(2) > div > div.profile-edit__info.show-sm > div > div:nth-child(2) > button:nth-child(5)',
    content: 'Do visit our promotion section to see if we are running any promotions for artists or venues you may hire.'
  },
  {
    target: '#root-wrapper > div > div.RegisterWizard > div.profile-edit > div.type-switcher > button:nth-child(2)',
    content: 'Finally, don\'t forget to upload media to your profile. 2 types of media are required for your profile to go live.'
  },
];

export const clientRegistrationProfileMediaSteps = [
  {
    target: '#wizard-content > div > div > div > div.photo-editor-wrapper.photo-editor-wrapper--avatar > div > div.control > div > div.advanced-control-item.uploaded-config > div.canvas-wrapper',
    content: 'Give us your best smile or company logo. This will be displayed throughout the platform, so a great photo goes a long way.'
  },
  {
    target: '#wizard-content > div > div > div > div.photo-editor-wrapper.photo-editor-wrapper--photo > div > div.content > div > div.cover-preview',
    content: 'The cover photo is also required, why not make a statement?'
  },
  {
    target: '#wizard-content > div > div > div > div.photo-editor-wrapper.photo-editor-wrapper--preview > div > div.content > div > div.profile-picture-preview.col-12.col-sm-6 > div > div.width-card > div > div.preview-cover.preview-cover-component',
    content: 'Lastly, your profile card is what\'s left to upload. After uploading the above, you\'re all set to start creating gigs and booking vendors that you need for your amazing event! '
  },
];

export const vendorDiscoverySteps = [
  {
    target: '#root-wrapper > div > div.Discover > div.Discover-content > div > div.gig-tab-content > div > div > div.ArtistCategory > div',
    content: 'Within the Gig Feed, all gigs for Artists will be sorted in each one of these categories and their Talents.',
  },
  {
    target: '#root-wrapper > div > div.Discover > div.Discover-content > div > div.gig-tab-content > div > div > div.ArtistCategory > div > div.information > div',
    content: 'If you don\'t wish to browse any of the categories, you can "Jump to..." any Talent directly and see if there are gigs for you to apply to.!',
  },
  {
    target: '#root-wrapper > div > div.Discover > div.Discover-content > div > div.gig-tab-content > div > div > div.VenueCategory > div',
    content: 'These are gigs for Venues.',
  },
  {
    target: '#root-wrapper > div > div.Discover > div.Discover-content > div > div.gig-tab-content > div > div > div.VenueCategory > div > div.information > div',
    content: 'You can also "Jump to..." any Type or Occasion directly here.',
  },
  {
    target: '#root-wrapper > div > div.Discover > div.Discover-content > div > div:nth-child(1) > button:nth-child(2)',
    content: 'The Spotlight is a section hand picked by Gigworks to highlight a rising talent.',
  },
  {
    target: '#root-wrapper > div > div.Discover > div.Discover-content > div > div:nth-child(1) > button:nth-child(3)',
    content: 'These areas are further browsing areas if you don\'t know what you\'re looking for but curious to see what\'s out there.',
  },
  {
    target: '#root-wrapper > div > div.Navbar__Wrapper > div > div.SearchStuffWrapper > div > div',
    content: 'Or if you wish to go directly to where you want to Search for what you need, go here.',
  },
];

export const clientDiscoverySteps = [
  {
    target: '#root-wrapper > div > div.Discover > div.Discover-content > div > div.gig-tab-content > div > div > div.GeneralCategories > div:nth-child(1) > div > div > div.category-artists > div:nth-child(1)',
    content: 'By clicking on each Profile Card, you will see the Artist or Venues\' full profile where you’ll be able to Invite them to Apply or Book them directly.',
  },
  {
    target: '#root-wrapper > div > div.Discover > div.Discover-content > div > div:nth-child(1) > button:nth-child(2)',
    content: 'The Spotlight is a section hand picked by Gigworks to highlight a rising talent.',
  },
  {
    target: '#root-wrapper > div > div.Discover > div.Discover-content > div > div:nth-child(1) > button:nth-child(3)',
    content: 'These areas are further browsing areas if you’re not sure what you\'re looking for but curious to see what\'s out there.',
  },
  {
    target: '#root-wrapper > div > div.Navbar__Wrapper > div > div.SearchStuffWrapper > div > div',
    content: 'Or if you wish to go directly to where you want to Search for what you need, go here.',
  },
];


export const clientDashboardOverviewSteps = [
  {
    target: '#root-wrapper > div > div.DashBoard > div > div > div > div > div:nth-child(1) > div > div > div:nth-child(1) > button:nth-child(1)',
    content: 'Within the My Gigs section, everything related to gigs you\'ve Created, Saved will be displayed here.'
  },
  {
    target: '#root-wrapper > div > div.DashBoard > div > div > div > div > div.no-gutters.row > div.DashboardLayout__LeftColumn.d-none.d-md-block.border-right.pr-0.col-3.col-xl-2 > div:nth-child(2) > div > div:nth-child(1)',
    content: 'When hiring an Artist or Venue, your Created gigs will appear here. Do follow the timeline and view Candidates that have applied. Contracts will need your signature in order to proceed to payment for the gig.',
    styles: {
      spotlight: {
        backgroundColor: '#ffffff36'
      }
    }
  },
  {
    target: '#root-wrapper > div > div.DashBoard > div > div > div > div > div.no-gutters.row > div.DashboardLayout__LeftColumn.d-none.d-md-block.border-right.pr-0.col-3.col-xl-2 > div:nth-child(2) > div > div:nth-child(2)',
    content: 'Gigs you\'ve created in the past will appear here.',
    styles: {
      spotlight: {
        backgroundColor: '#ffffff36'
      }
    }
  },
  {
    target: '#root-wrapper > div > div.DashBoard > div > div > div > div > div:nth-child(1) > div > div > div:nth-child(1) > button:nth-child(2)',
    content: 'Any messages you\'ve received or sent will show up here, organized by Talent or Gig.'
  },
  {
    target: '#root-wrapper > div > div.DashBoard > div > div > div > div > div:nth-child(1) > div > div > div:nth-child(1) > button:nth-child(3)',
    content: 'Gigs that you\'ve successfully created will appear in your calendar.'
  },
  {
    target: '#root-wrapper > div > div.DashBoard > div > div > div > div > div:nth-child(1) > div > div > div:nth-child(1) > button:nth-child(4)',
    content: 'Your favorite Artists or Venues will appear here.'
  },
  {
    target: '#root-wrapper > div > div.DashBoard > div > div > div > div > div:nth-child(1) > div > div > div:nth-child(1) > button:nth-child(5)',
    content: 'To view your funds or manage your financial data, click on the Admin area.'
  },
  {
    target: '#root-wrapper > div > div.DashBoard > div > div > div > div > div.no-gutters.row > div.d-none.d-xl-block.border-left.pl-0.col-2',
    content: 'All updates pertaining to your Created gigs will appear here.',
    styles: {
      spotlight: {
        backgroundColor: '#ffffff36'
      }
    }
  },
];


export const clientDashboardAdminSteps = [
  {
    target: '#root-wrapper > div > div.DashBoard > div > div > div.no-gutters.row > div.DashboardLayout__LeftColumn.d-none.d-md-block.border-right.pr-0.col-12.col-sm-12.col-md-3.col-lg-2.col-xl-2 > div > a.AdminMenu__MenuItem.underlined.active',
    content: 'All transactions will show up in this area.',
    styles: {
      spotlight: {
        backgroundColor: '#ffffff36'
      }
    }
  },
  {
    target: '#root-wrapper > div > div.DashBoard > div > div > div.no-gutters.row > div.main.Admin.col-12.col-sm-12.col-md-9.col-lg-10.col-xl-10 > div > div > div > div > div:nth-child(1) > div > a:nth-child(1)',
    content: 'This will show any payments for gigs you\'ve hired a Vendor for.',
    styles: {
      spotlight: {
        backgroundColor: '#ffffff36'
      }
    }
  },
  {
    target: '#root-wrapper > div > div.DashBoard > div > div > div.no-gutters.row > div.DashboardLayout__LeftColumn.d-none.d-md-block.border-right.pr-0.col-12.col-sm-12.col-md-3.col-lg-2.col-xl-2 > div > a:nth-child(2)',
    content: 'In the "My Wallet" area, you will see how much you\'ve earned through contracts to date and once you\'ve attached a bank account to your profile, you can transfer or "Pay Out" to you bank, those funds.',
    styles: {
      spotlight: {
        backgroundColor: '#ffffff36'
      }
    }
  },
  {
    target: '#root-wrapper > div > div.DashBoard > div > div > div.no-gutters.row > div.DashboardLayout__LeftColumn.d-none.d-md-block.border-right.pr-0.col-12.col-sm-12.col-md-3.col-lg-2.col-xl-2 > div > a:nth-child(3)',
    content: 'To pay for any services, attach a credit card. Or to transfer paid contract funds, attach a bank account here to get paid.',
    styles: {
      spotlight: {
        backgroundColor: '#ffffff36'
      }
    }
  },
  {
    target: '#root-wrapper > div > div.DashBoard > div > div > div.no-gutters.row > div.DashboardLayout__LeftColumn.d-none.d-md-block.border-right.pr-0.col-12.col-sm-12.col-md-3.col-lg-2.col-xl-2 > div > a:nth-child(4)',
    content: 'Once you\'ve entered your tax ID, you\'ll only need to do this once.',
    styles: {
      spotlight: {
        backgroundColor: '#ffffff36'
      }
    }
  },
  {
    target: '#root-wrapper > div > div.DashBoard > div > div > div.no-gutters.row > div.DashboardLayout__LeftColumn.d-none.d-md-block.border-right.pr-0.col-12.col-sm-12.col-md-3.col-lg-2.col-xl-2 > div > a:nth-child(5)',
    content: 'Any promotions that you may receive will appear here, you have seen this in the Registration area as well.',
    styles: {
      spotlight: {
        backgroundColor: '#ffffff36'
      }
    }
  },
];
