import React from 'react';

import * as _ from 'lodash'
import { connect } from 'react-redux'
import Echo from 'laravel-echo'
import Pusher from 'pusher-js'
import config from '../../config'
import { addNotification, setPusherStatus, getNotifications } from '../../redux/modules/notifications';
import Button from '../common/Button/Button';
import { NOTIFICATIONS_STATUSES, VENDOR_ACTIONS, OWNER_ACTIONS, DASHBOARD_BOOKING_ACTIONS, PLAN_UPGRADE_ACTIONS, PROFILE_ACTIONS, HIDDEN_ACTIONS, KYC_ACTIONS } from '../../utils/constants';
import apiClient from '../../helpers/apiClient';
// const PUSHER_APP_KEY = '13edf1ae05c452e6615f'
const PUSHER_APP_KEY = ''

@connect(({ user }) => ({
    currentUser: user.currentUser,
}))
class PusherStaff extends React.Component {
    shouldComponentUpdate(nextProps) {
        if (_.isEmpty(this.props.currentUser) && !_.isEmpty(nextProps.currentUser) ||
            !_.isEmpty(this.props.currentUser) && _.isEmpty(nextProps.currentUser)
        ) return true
        return false
    }

    render() {
        if (this.props.currentUser && !_.isEmpty(this.props.currentUser))
            return <PusherInside currentUser={this.props.currentUser} />
        return null
    }
}
export default PusherStaff


@connect(({ notifications }) => ({
    allNotifications: notifications.notifications
}), {
    addNotification, setPusherStatus, getNotifications
})
class PusherInside extends React.PureComponent {
    reauthorizeNeeded = false;

    generateItemUrl = i => {
        const { currentUser } = this.props;

        if (i && i.link) return i.link
        if (i && i.action) {
            if (NOTIFICATIONS_STATUSES.GIG_REVIEW === i.action) return `/profile/${currentUser.id}`
            if (PROFILE_ACTIONS.indexOf(i.action) > -1) return '/profile/edit'
            if (NOTIFICATIONS_STATUSES.GIG_CANCELED === i.action) return `/dashboard/mygigs/created`
            if (NOTIFICATIONS_STATUSES.INSTANT_BOOKING === i.action) return `/profile/`
            if (NOTIFICATIONS_STATUSES.GIG_V_INVITE_ACCEPT === i.action) return `/dashboard/mygigs/created/${_.defaultTo(i.id, '')}/candidates/applied/`
            if (DASHBOARD_BOOKING_ACTIONS.indexOf(i.action) > -1) return '/dashboard/mygigs/bookingconfirmations'
            if (PLAN_UPGRADE_ACTIONS.indexOf(i.action) > -1) return '/profile/edit/plans'
            if (OWNER_ACTIONS.indexOf(i.action) > -1) return `/dashboard/mygigs/created/${_.defaultTo(i.id, '')}`
            if (VENDOR_ACTIONS.indexOf(i.action) > -1) return `/dashboard/mygigs/applied/${_.defaultTo(i.id, '')}`
            if (KYC_ACTIONS.indexOf(i.action) > -1) return '/profile/edit/kyc'
        }
        return `/dashboard/mygigs/`
    }

    componentDidMount() {
      this.refreshNotificationsTimeInterval = setInterval(() => this.GetNotificationsGIG(), 30000);

      this.registerPusher()

        setInterval(() => {
            if (this.reauthorizeNeeded) {
                this.registerPusher()
            }
        }, 60 * 1000);

    }

  GetNotificationsGIG() {
    const {addNotification, allNotifications} = this.props;

    this.props.getNotifications().then(res => {
      var notifications = res.data.data.map(item => {
        if (!_.isArray(item)) {
          return {
            ...item.data,
            link: this.generateItemUrl(item.data),
            read: item.read_at ? true : false,
          }
        }
      }).filter((item) => item.read == false);

      let mergedNotifications = notifications
      if(!_.isEmpty(allNotifications)){
        mergedNotifications = []
        notifications.forEach((item) => {

          let found = false
          allNotifications.forEach((existingItem) => {
              if((existingItem.id && item.id) && existingItem.id == item.id && existingItem.action == item.action) {
                found = true
                return
              }
          })

          if(!found) {
            mergedNotifications.push(item)
          }
        })
      }

      if (mergedNotifications) {
        addNotification(mergedNotifications);
      }
    })
  }

  registerPusher = () => {
        const { currentUser, addNotification } = this.props;
        console.log("-- PUSHER AUTHORIZATION --")
        this.reauthorizeNeeded = false;

        if (this.pusher) {
            this.disconnectPusher();
        }

        Pusher.logToConsole = true;
        console.log('connect to pusher auth url:', `${config.protocol}://${config.host}:${config.port}/api/notification/authorize`)

        this.pusher = new Pusher(PUSHER_APP_KEY, {
            cluster: 'eu',
            authorizer: function (channel, options) {
                return {
                    authorize: function (socketId, callback) {
                        apiClient.post(`${config.protocol}://${config.host}:${config.port}/api/notification/authorize`, {
                            socket_id: socketId,
                            channel_name: channel.name
                        }).then(res => {
                            callback(false, res.data);
                        })
                    }
                }
            }
        });

        // this.pusher = new Pusher(PUSHER_APP_KEY, {
        //     cluster: 'eu',
        //     authEndpoint: `${config.protocol}://${config.host}:${config.port}/api/notification/authorize`,
        //     auth: {
        //         headers: {
        //           'Authorization': `Bearer ${localStorage.getItem('gigAuthHeader')}`
        //         }
        //       }

        // });

        // this.pusher.connection.bind('disconnected', () => {
        //     console.log("-- PUSHER DISCONNECTED --")
        //     this.disconnectPusher();
        //     this.reauthorizeNeeded = true;
        // });

        const channel = this.pusher.subscribe(`private-App.User.${currentUser.id}`);


        channel.bind('Illuminate\\Notifications\\Events\\BroadcastNotificationCreated', (data) => {
            console.log("Pusher data", data, this.props.allNotifications)
            if (!_.isArray(data)) {
                const item = data.data;

                addNotification({
                    ...item,
                    link: this.generateItemUrl(item),
                    read: false
                });

                // const notificationArray = this.props.allNotifications || []
                // console.log("nArray", notificationArray, !notificationArray.some(n => n.id === item.id))

                // if( !notificationArray.some(n => n.id === item.id) ) {
                //     console.error("-- NOTIFICATION ADDED --")
                //     addNotification({
                //         ...item,
                //         link: this.generateItemUrl(item),
                //         read: false
                //     });
                // }
            }
        });
    }

    disconnectPusher = () => {
        this.pusher.unsubscribe(`private-App.User.${this.props.currentUser.id}`);
        this.pusher.disconnect();
    }

    componentWillUnmount() {
        clearInterval(this.refreshNotificationsTimeInterval);
        this.disconnectPusher()
    }

    shouldComponentUpdate() {
        return false
    }


    render() {
        return <div>
            {/* <Button
                label="Add notification"
                style={{ position: 'fixed',  zIndex:55435345435434, backgroundColor: 'orange', top: 90, padding: 20}}
                onClick={() => {
                    console.log('Dodałem')
                    this.props.addNotification({
                        action: 'GIG_CONTRACT_CREATED',
                        message: 'The contract has been created. Please sign it.',
                        place: 'DASHBOARD',
                        progress: {
                            id: 1124,
                            status_id: 9,
                            user_id: 365,
                            gig_id: "Nazwa giga"
                        },
                        time: '2019-06-12 09:19:47',
                        type: 'App\\Notifications\\NotifyProgressGit',
                        picture: { url: 'https://backend.gigworks.apps-garden.com/storage/pictures/TqOialuJK3atchkuJ5sL5EMvo8oSwyAKHShiiW8r.jpeg' }
                    })}}
            /> */}
        </div>
    }
}
