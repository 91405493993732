import './BudgetFilter.scss';
import React, { Component } from 'react';
import { currency } from '../../../../utils/constants';
import { InputStyles, PlaceholderStyles } from '../../../register/CommonComponent';
import { Range } from 'rc-slider';
import { MenuItem, SelectField, TextField } from 'material-ui';

export class BudgetFilter extends Component {
  innerDevStyle = {
    padding: '0',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
  };

  handleStyle = [
    { borderColor: 'black', backgroundColor: '#fff' },
    { borderColor: 'black', backgroundColor: '#fff' }
  ];

  constructor(props) {
    super(props);

    this.state = {
      minRange: 0,
      maxRange: 50000,
      currency: currency[0].value
    };
  }

  currencyChangeHandler = (evt, index, val) => {
    const { value, onChange } = this.props;

    onChange({
      ...value,
      currency: value.currency,
    });
  };

  rangeChangeHandler = val => {
    const { value, onChange } = this.props;

    onChange({
      ...value,
      min: val[0],
      max: val[1],
    });
  };

  changeVal = (which, val) => {
    const value = {
      ...this.props.value,
      [which]: +val
    };

    this.props.onChange(value);
  };

  render() {
    const { value, onChange } = this.props;
    const { minRange, maxRange, currencyVal } = this.state;

    return (
      <div className='BudgetFilter budget-filter'>
        <SelectField
          id='currency-field'
          className='currency'
          value={value.currency}
          fullWidth={true}
          hintStyle={PlaceholderStyles}
          labelStyle={InputStyles}
          onChange={this.currencyChangeHandler}>
          {currency.map((cur, index) =>
            <MenuItem
              key={'curr' + index}
              onClick={(e) => e.stopPropagation()}
              value={cur.value}
              primaryText={cur.label}
              innerDivStyle={this.innerDevStyle}
            />
          )}
        </SelectField>
        <TextField
          className='budgetFrom'
          id='budget-field-from'
          name='budget-field-from'
          value={value.min.toString().match(/^[^0]*.*/)}
          min={0}
          max={value.max}
          type='number'
          onChange={(evt, val) =>
            this.changeVal('min', val < value.max ? val.toString().match(/^[^0]*.*/) : value.max - 1)}
          hintStyle={PlaceholderStyles}
          fullWidth={true}
          inputStyle={InputStyles} />
        <div className='range-wrapper'>
          <Range
            min={0}
            max={50000}
            count={2}
            id='range-field'
            name='range-field'
            value={[value.min, value.max]}
            onChange={this.rangeChangeHandler}
            trackStyle={[{ backgroundColor: 'black' }]}
            handleStyle={this.handleStyle}
            defaultValue={[0, 50000]}
            tipFormatter={value => `${value}`} />
        </div>
        <TextField
          id='budget-field-to'
          name='budget-field-to'
          type='number'
          min={0}
          max={50000}
          value={value.max.toString().match(/^[^0]*.*/)}
          className='budgetTo'
          onChange={(evt, val) =>
            this.changeVal('max', val > value.min ? val.toString().match(/^[^0]*.*/) : value.min + 1)}
          hintStyle={PlaceholderStyles}
          fullWidth={true}
          inputStyle={InputStyles}
        />
      </div>
    );
  }
}
