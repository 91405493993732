import React, { Component } from 'react'
import config from '../../../../config'
import * as _ from 'lodash'
import { withRouter } from 'react-router-dom'
import apiClient from '../../../../helpers/apiClient';

import SVGInline from 'react-svg-inline'

import queryString from 'query-string'
import Spinner from '../../../common/Spinner/Spinner';

const svg_arrow_next = require('!!svg-inline-loader?classPrefix!assets/icons/go-forward.svg');

class RealContractPreview extends React.PureComponent {
	constructor(props) {
		super(props);
		this.state = {
			fileURL: null,
			backLink: null,
		}
	}

	shouldComponentUpdate(_, nextState) {
		if (this.state.backLink !== nextState.backLink) {
			return false
		}
		return true
	}

	componentDidMount(){
		this.fetchContract();

		const queryStringParams = queryString.parse(this.props.location.search);
		if (queryStringParams.back_link) {
			this.setState({ backLink: queryStringParams.back_link })
		}
	}

	fetchContract = () => {
		const hash = _.get(this.props, 'match.params.hash', this.props.hash)
		const baseURL = `${config.protocol}://${config.host}:${config.port}/api/contract/fetch?hash=${hash}&type=download`

		apiClient({
			url: baseURL,
			method: 'GET',
			responseType: 'blob', // important
		}).then(response => {
			const file = new Blob([response.data], { type: 'application/pdf' })
			const fileURL = URL.createObjectURL(file)
			this.setState({ fileURL })
		})
	}
	
	goBack = () => {
		this.props.history.push(this.state.backLink)
	}
    
    render() {
		const { fileURL } = this.state;

		return <div style={{ paddingTop: 75}}>
			<TopSection
				label="Contract Preview"
				action={this.goBack}
				actionLabel={'Back'}
			/>

			{ 
				fileURL ?
					<iframe 
						src={fileURL} 
						type="application/pdf" 
						width="100%"
						style={{ height: '100vh' }}
					/> 
					:
					<div style={{ position: 'relative', height: '100vh', width: '100%' }}>
						<Spinner />
					</div>
				}
		</div>
    }
}

export default withRouter(RealContractPreview)

const TopSection = ({ label, action, actionLabel }) => <div style={{ 
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: '#fff',
    padding: '4vh 6vh',
   }}>
    <h4 style={{ fontWeight: 600 }}>
        {label}
    </h4>
    
    <div 
        style={{display: 'flex', alignItems: 'center', cursor: 'pointer'}}
        onClick={action}
        >
        {actionLabel}
        <SVGInline 
            svg={svg_arrow_next}
            style={{ marginLeft: '.5rem' }}
            fill='#13698e'
            height="1.2rem"
            width="1.2rem" 
        />
    </div>
</div>