import React from 'react'
import SVGInline from 'react-svg-inline';
import { IconButton } from 'material-ui';

const  close_svg = require('!!svg-inline-loader?classPrefix!../../../assets/x-mark.svg');

const CloseButton = ({ onClick, iconProps, ...restProps }) => <IconButton
    onClick={onClick}
    {...restProps}>
        <SVGInline
            svg={close_svg}
            fill="#000"
            width='20px'
            {...iconProps}
        />
</IconButton>
export default CloseButton