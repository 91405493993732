import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Field} from 'redux-form';
import { translate } from '../../translator';


export default class RegisterAdditionalFields extends Component{
  static propTypes = {
    profileType: PropTypes.string.isRequired,
    formValues: PropTypes.object.isRequired,
    onFilled: PropTypes.func.isRequired,
    onMissing: PropTypes.func.isRequired,
  };

  constructor(props){
    super(props);
  }

  getAdditionalContent = ()=>{
    const { profileType, formValues } = this.props;
    switch (profileType){
      case 'group':
      case 'band':
      case 'artist' :{
        if (formValues.explicit_type === 'artist'){
          this.props.onFilled();
        } else if ( formValues.explicit_type === 'group' && typeof formValues.is_band !== 'undefined'){
          this.props.onFilled();
        } else {
          this.props.onMissing();
        }

        return <ArtistAdditionalInfo profileType={profileType}
          formValues={formValues} />;
      }
      case 'venue' :{
        this.props.onFilled();

        return '';
      }
      case 'fan' :{
        this.props.onFilled();

        return '';
      }
      case 'client':
      case 'company':{
        if (formValues.explicit_type){
          this.props.onFilled();
        } else {
          this.props.onMissing();
        }

        return <ClientAdditionalInfo profileType={profileType}
          formValues={formValues} />;
      }
    }
  };

  render(){
    return (<div>
      {this.getAdditionalContent()}
    </div>);
  }
}

class ClientAdditionalInfo extends Component{
  render(){
    return (
      <div>
        <span><strong>{translate('registeradditionalfields.component1')}</strong></span>
        <div className="radio-group-wrapper">
          <label className="radio-group radio-inline">
            <Field component="input"
              value="client"
              type="radio"
              className="client d-none"
              name="explicit_type"/>
            <span>{translate('registeradditionalfields.private')}</span>
          </label>
          <label className="radio-group radio-inline">
            <Field component="input"
              value="company"
              type="radio"
              className="company d-none"
              name="explicit_type" />
            <span>{translate('registeradditionalfields.company')}</span>
          </label>
        </div>
      </div>
    );
  }
}
class ArtistAdditionalInfo extends Component{
  render(){
    const { formValues } = this.props;

    return (
      <div>
        <span><strong>{translate('registeradditionalfields.component2')}</strong></span>
        <div className="radio-group-wrapper">
          <label className="radio-group radio-inline at-individual">
            <Field component="input"
              value="artist"
              type="radio"
              className="individual d-none"
              name="explicit_type"/>
            <span>{translate('registeradditionalfields.individual')}</span>
          </label>
          <label className="radio-group radio-inline at-group">
            <Field component="input"
              value="group"
              type="radio"
              className="group-wrapper d-none"
              name="explicit_type" />
            <span>{translate('registeradditionalfields.group')}</span>
          </label>
        </div>
        {
          formValues && formValues.explicit_type === 'group'
            ?
            <div>
              <span><strong>{translate('registeradditionalfields.areYou')}</strong></span>
              <div className="radio-group-wrapper">
                <label className="radio-group radio-inline">
                  <Field component="input"
                    value='true'
                    type="radio"
                    className="group d-none"
                    name="is_band"/>
                  <span>{translate('registeradditionalfields.yes')}</span>
                </label>
                <label className="radio-group radio-inline">
                  <Field component="input"
                    value='false'
                    type="radio"
                    className="band d-none"
                    name="is_band" />
                  <span>{translate('registeradditionalfields.no')}</span>
                </label>
              </div>
            </div>
            :
            ''
        }
      </div>
    );
  }
}
