import './FilterInput.scss';
import React, { Component } from 'react';

export class FilterInput extends Component {
  render() {
    const { onChange } = this.props;

    return (
      <div className='FilterInput'>
        <i className="fa fa-search"/>
        <input placeholder='Search' onChange={onChange}/>
      </div>
    );
  }
}
