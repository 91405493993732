import React, { Component } from 'react'
import PropTypes from 'prop-types'

import TextField from 'material-ui/TextField'

import { compose } from 'recompose'
import { connect } from 'react-redux'
import { Row, Col } from 'reactstrap'
import { isEmpty, get } from 'lodash'

import { getCurrentUser } from '../../../../redux/modules/user'
import { saveProfileInfo } from '../../../../redux/modules/register'

import './TaxId.scss'
import { Checkbox } from '../../../CommonComponent';
import Button from '../../../common/Button/Button';
import { DANGER_COLOR, GREY_COLOR_DARKER } from '../../../../utils/constants';
import Spinner from '../../../common/Spinner/Spinner';
import notification from 'antd/es/notification'
import 'antd/es/notification/style/css'
import {Asterisk} from "../../../common/Asterisk/Asterisk";

const HorizontalSeparator = () => <hr style={{ marginTop: '.75rem', marginBottom: '.75rem' }} />

@connect(({ globalConfig }) => ({
	isMobile: globalConfig.isMobile,
	isTablet: globalConfig.isTablet,
}), {})
class TaxId extends Component {
	state = {
		checked: false,
    type: false,
		changed: false,
	}

	componentDidMount() {
        const { isAuthorized, getCurrentUser, currentUser } = this.props

		if (isAuthorized) {
			if (isEmpty(currentUser)) {
			  getCurrentUser()
			}
			else if(get(currentUser, 'profile.tax_id', false)) {
			  this.setState({
          checked: true,
          type: currentUser.profile.tax_type_number
			  })
			}
		}
	}

    UNSAFE_componentWillReceiveProps(nextProps) {
        if(get(nextProps, 'currentUser.profile.tax_id', false)) {
            this.setState({
              checked: true,
              type: nextProps.currentUser.profile.tax_type_number
            })
        }
    }

	_saveTaxId = () => {
		const { saveProfileInfo } = this.props

		saveProfileInfo({ tax_id: this.tax_id.input.value, tax_type_number: this.state.type })
			.then(res => {
				notification.success({
					message: 'Success!',
					description: 'Tax Id successfully saved!',
				});
			})
			.catch(err => {
        if([400, 404, 422].includes(err.response.status)) {
          notification.error({
            message: 'Error!',
            description: 'Invalid VAT number format',
          });
        } else {
          notification.error({
            message: 'Error!',
            description: get(err, 'data.result_message'),
          });
        }
			})
	}

	_onCheck = (_, isInputChecked) => {
		this.setState({
			checked: isInputChecked,
		})
	}

  checkTaxIdNumberType = (type) => {
    this.setState({
      type: type,
    })
  }

	_onBlur = event => {
		this.setState({
			changed: event.target.value !== '',
		})
	}
	_onChange = event => {
		this.setState({
			changed: event.target.value !== '',
		})
	}

	render() {
		const { currentUser, isMobile } = this.props

		if (isEmpty(currentUser)) return null

		return (
			<div>
				<Row>
					<Col md={9} xs={12}>
						<div className="p-3 p-md-5">
              <div>
                <Checkbox
                  label="Tax ID"
                  labelStyle={{
                    fontSize: '.8rem',
                    color: GREY_COLOR_DARKER
                  }}
                  value={this.state.type === 'TAX_ID'}
                  onCheck={() => this.checkTaxIdNumberType('TAX_ID')}
                />

                <Checkbox
                  label="EIN (Employee Identification Number)"
                  labelStyle={{
                    fontSize: '.8rem',
                    color: GREY_COLOR_DARKER
                  }}
                  value={this.state.type === 'EIN'}
                  onCheck={() => this.checkTaxIdNumberType('EIN')}
                />

                <Checkbox
                  label="VAT #"
                  labelStyle={{
                    fontSize: '.8rem',
                    color: GREY_COLOR_DARKER
                  }}
                  value={this.state.type === 'VAT_NUMBER'}
                  onCheck={() => this.checkTaxIdNumberType('VAT_NUMBER')}
                />
              </div>
              <HorizontalSeparator />
              <div>
								{currentUser.profile.name}
							</div>

							<div>
								<TextField
									ref={ref => this.tax_id = ref }
									hintText="Please fill your tax number"
									style={{ width: '366px', maxWidth: '100%' }}
									onBlur={this._onBlur}
									onChange={this._onChange}
									defaultValue={currentUser.profile.tax_id}
								/>
							</div>

							<div>
								<Checkbox
									label={
									  <>
                      I declare the information provided above is accurate. I, solely will be held responsible if any information is incorrect.
                      <div
                        style={{
                          marginTop: '5px'
                        }}
                      >
                        <Asterisk /> Please note: The tax ID will appear on all contracts in plain text for taxation purposes. Gigworks does not suggest entering your Social Security number as your tax ID. Please apply for a business tax ID to be used here. Gigworks will not be held responsible for your Social Security number if you decide to use that as your tax ID.
                      </div>
                    </>
									}
									labelStyle={{
										fontSize: '.8rem',
										color: GREY_COLOR_DARKER
                                    }}
                                    value={this.state.checked}
									onCheck={this._onCheck}
								/>
							</div>
						</div>
					</Col>

					{
						isMobile ? <Col xs={12} className="my-5">
							<Button
								primary
								fullWidth
								label="Save Changes"
								onClick={this._saveTaxId}
								disabled={!this.state.changed || !this.state.checked}
							/>
						</Col> :
						<Col xs={12} md={3} style={{ borderLeft: '1px solid #ccc', paddingLeft: 0, minHeight: '600px' }}>
							<Button
								primary
								fullWidth
								label="Save Changes"
								style={{ borderWidth: 0, borderBottomWidth: '1px' }}
								onClick={this._saveTaxId}
								disabled={!this.state.changed || !this.state.checked || !this.state.type}
							/>
							{!currentUser.profile.tax_id && (!this.state.changed || !this.state.checked || !this.state.type) &&
								<div style={{ color: DANGER_COLOR, fontSize: '.8rem', padding: '8px' }}>
									<p>Tax Id or VAT# or SS# is required to finalize contract</p>
								</div>
							}
						</Col>
					}
				</Row>
			</div>
		)
	}
}

const mapStateToProps = ({ auth, user }) => {
	return {
		currentUser: user.currentUser,
		isAuthorized: auth.isAuthorized,
	}
}

TaxId.propTypes = {
	isAuthorized: PropTypes.bool.isRequired,
	currentUser: PropTypes.object.isRequired,
	getCurrentUser: PropTypes.func.isRequired,
	saveProfileInfo: PropTypes.func.isRequired,
}

export default compose(
	connect(
		mapStateToProps,
		{ getCurrentUser, saveProfileInfo }
	)
)(TaxId)

@connect(({ user }) => ({
	currentUser: user.currentUser,
}), { saveProfileInfo, getCurrentUser })
export class TaxIdForm extends React.PureComponent {
	constructor(props) {
		super(props);
		this.state = {
			checkboxValue: false,
			loading: false,
			value: _.get(props, 'currentUser.profile.tax_id', '')
		}
	}

	handleSubmit = () => {
		const { saveProfileInfo, getCurrentUser, onSuccess, onClose } = this.props

		this.setState({ loading: true })
		saveProfileInfo({ tax_id: this.state.value, tax_type_number: this.state.type })
			.then(() => {
                this.setState({ loading: false })
				getCurrentUser().then(() => {
                    onClose && onClose();
                    onSuccess && onSuccess();
				})
			})
			.catch(() => {
				this.setState({ loading: false });
			})
	}

  checkTaxIdNumberType = (type) => {
    this.setState({
      type: type,
    })
  }

	render() {
		const { value, loading, checkboxValue } = this.state;
		const { currentUser } = this.props;

		return <div>
			{loading && <Spinner />}

      <div>
        <Checkbox
          label="Tax ID"
          labelStyle={{
            fontSize: '.8rem',
            color: GREY_COLOR_DARKER
          }}
          value={this.state.type === 'TAX_ID'}
          onCheck={() => this.checkTaxIdNumberType('TAX_ID')}
        />

        <Checkbox
          label="EIN (Employee Identification Number)"
          labelStyle={{
            fontSize: '.8rem',
            color: GREY_COLOR_DARKER
          }}
          value={this.state.type === 'EIN'}
          onCheck={() => this.checkTaxIdNumberType('EIN')}
        />

        <Checkbox
          label="VAT #"
          labelStyle={{
            fontSize: '.8rem',
            color: GREY_COLOR_DARKER
          }}
          value={this.state.type === 'VAT_NUMBER'}
          onCheck={() => this.checkTaxIdNumberType('VAT_NUMBER')}
        />
      </div>
      <HorizontalSeparator />
			<div>
				{currentUser.profile.name}
			</div>

			<div>
				<TextField
					value={value}
					hintText="Please fill your tax number"
					style={{ width: '366px', maxWidth: '100%' }}
					onChange={e => this.setState({ value: e.target.value })}
					defaultValue={currentUser.profile.tax_id}
				/>
			</div>

			<div className="mt-2">
				<Checkbox
					value={checkboxValue}
					label={
            <>
              I declare the information provided above is accurate. I, solely will be held responsible if any information is incorrect.
              <div
                style={{
                  marginTop: '5px'
                }}
              >
                <Asterisk /> Please note: The tax ID will appear on all contracts in plain text for taxation purposes. Gigworks does not suggest entering your Social Security number as your tax ID. Please apply for a business tax ID to be used here. Gigworks will not be held responsible for your Social Security number if you decide to use that as your tax ID.
              </div>
            </>
          }
					labelStyle={{
						fontSize: '.8rem',
						color: GREY_COLOR_DARKER
					}}
					onChange={(_, checkboxValue) => this.setState({ checkboxValue })}
				/>
			</div>

			<div className="mt-2 d-flex justify-content-center">
				<Button
					label="Save"
					disabled={!value || !checkboxValue}
					onClick={this.handleSubmit}
				/>
			</div>
		</div>
	}
}
