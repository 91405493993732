import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Field, reduxForm } from 'redux-form'

import moment from 'moment'

import { getValues } from '../../../helpers/methods'
import validate from '../../../helpers/validators/artist/PersonalInfo'
import '../../../styles/components/WizardPersonalInfo.scss'
import * as _ from 'lodash'
import { Gender, FileInput } from '../../register/CommonComponent'
import {
	TextFieldComponent,
	GoogleMapsAutocomplete,
	BirthDatePickerComponent,
	PhoneComponent,
	SelectFieldComponent,
	CheckboxComponent,
} from '../../CommonComponent'
import { Asterisk } from '../../common/Asterisk/Asterisk'
import classnames from 'classnames'
import { registrationConfig } from '../../../config'
import { getCountriesTree } from '../../../redux/modules/dashboard'
import Loader from '../../discover/Loader/Loader'
import { translate } from '../../../translator'
import store from "../../../redux/create";

class ArtistPersonalInfo extends Component {
	constructor(props) {
		super(props);
		this.state = {}
	}

	componentDidMount() {
    // const { getCountriesTree } = this.props
	  this.props.getCountriesTree();
    let currentValue
    function handleChange() {
      let previousValue = currentValue
      if(store.getState().user.currentProfile.location != undefined){
        currentValue = store.getState().user.currentProfile.location.country
        if (previousValue !== currentValue && previousValue != undefined) {
          if(store.getState().dashboard.countriesTree != undefined){
            const arr = store.getState().dashboard.countriesTree
            const countryCode = store.getState().common.country_code
            for(let i = 0; i < arr.length; i++){
              if(arr[i].code == countryCode){
                store.getState().user.currentProfile.countries[0].temp = arr[i].id
              }
            }
          }
        }
      }
    }
    this.unsubscribe = store.subscribe(() => {
      handleChange()
    });
	}

  componentWillUnmount() {
    this.unsubscribe();
  }

	render() {
	  const { initialValues, touch, currentUser } = this.props;
    const isLocationEditable = _.get(currentUser, 'isLocationEditable', false);
    const addressLastUpdate = _.get(currentUser, 'profile.address_last_update', null);

		return (
			<form className="personal-info">
				<div className="personal-info__fields">
					<div
						className={classnames('personal-info__info', {
							filled: initialValues.first_name && initialValues.last_name,
						})}
					>
						{translate('artistpersonalinfo.legalName')} <Asterisk />
					</div>
					<Field
						name="first_name"
						type="text"
						style={{ margin: '10px 0 5px' }}
						fullWidth
						component={TextFieldComponent}
						hintText={translate('artistpersonalinfo.firstName')}
					/>
					<Field
						name="last_name"
						type="text"
						style={{ margin: '10px 0 5px' }}
						fullWidth
						component={TextFieldComponent}
						hintText={translate('artistpersonalinfo.lastName')}
					/>
				</div>
				<div className="personal-info__gender">
					<div
						className={classnames('personal-info__info d-flex align-items-center', { filled: initialValues.gender })}
					>
						<span>{translate('artistpersonalinfo.gender')} <Asterisk /></span>
						<span className="band-contact-info__optional" />
					</div>
					<div className="text-left">
						<Field name="gender" component={Gender} />
					</div>
				</div>
				<div className="personal-info__nationality">
					<div className={classnames('personal-info__info', { filled: initialValues.nationality })}>
						{translate('artistpersonalinfo.nationality')} <Asterisk />
					</div>
					<div className="personal-info__nationality" style={{ width: '100%' }}>
            {(this.props.countriesList && this.props.countriesList.length) ?
              <Field
                name="nationality"
                type="string"
                required
                fullWidth
                style={{ height: '3rem', textAlign: 'left' }}
                options={this.props.countriesList}
                hintText="Please select your nationality"
                component={SelectFieldComponent}
              />
              :
              <div className="d-flex justify-content-center">
                <Loader />
              </div>
            }
					</div>
				</div>
				<div className="personal-info__date">
					<div className={classnames('personal-info__info', { filled: initialValues.birth_date })}>
						{translate('artistpersonalinfo.dateOfBirth')}
						<Asterisk />
					</div>
					<div className="personal-info__date-of-birth" style={{ width: '100%' }}>
						<Field name="birth_date" hintText="Birth day" component={BirthDatePickerComponent} />
					</div>
					<div className="mt-3">
						<Field
							name="over18"
							label={<>{translate('artistpersonalinfo.iConfirm')} <Asterisk /></>}
							labelStyle={{ width: 'initial' }}
							component={CheckboxComponent}
						/>
					</div>
				</div>
				<div className="personal-info__contact-information">
					<div
						className={classnames('personal-info__info', {
							filled: initialValues.location
								? Object.keys(initialValues.location).length || initialValues.phone_number
								: {},
						})}
					>
						<span
							className="fa-stack info-popup-window"
							data-title={registrationConfig.popupText.artistContactInformation}
						>
							<i className="fa fa-circle-thin fa-stack-2x" />
							<i className="fa fa-info fa-stack-1x" />
						</span>
						{translate('artistpersonalinfo.contactInformation')}
						<Asterisk />
					</div>
          {
            !isLocationEditable ?
              <div className="personal-info__address-additional-info">
                Since you have updated your location in the past 3 months, this part of the form is now disabled.
                You will be able to change it after {moment(addressLastUpdate).add(3, 'M').format('D MMM YYYY')}.
              </div> :
              null
          }
					<div className="contact-information__select-wrapper google-location-input" style={{ width: '100%' }}>
						<Field
							makeTouched={() => touch('location')}
							onBlur={() => touch('location')}
							fullWidth
							name="location"
              isLocationEditable={isLocationEditable}
							component={GoogleMapsAutocomplete}
							hintText="Enter Address"
							hintStyle={{
								fontWeight: '300',
								color: '#a3a3a3',
							}}
							style={{
								textAlign: 'left',
								marginRight: '20px',
							}}
						/>
            {(this.props.phoneCodeList && this.props.phoneCodeList.length) ?
              <Field
                onBlur={control => touch(control)}
                makeTouched={control => touch(control)}
                name="phone_number"
                style={{ margin: '10px 0 5px' }}
                fullWidth
                component={PhoneComponent}
                countries={this.props.phoneCodeList}
              />
              :
              <div className="d-flex justify-content-center">
                <Loader />
              </div>
            }
					</div>
				</div>
				<div className="personal-info__curriculum-vitae">
					<ServiceInfoThirdForm {...this.props} showValidationError={this.props.showValidationError} />
				</div>
			</form>
		)
	}
}

ArtistPersonalInfo = reduxForm({
	form: 'ArtistPersonalInfo',
	// destroyOnUnmount: false,
	// forceUnregisterOnUnmount: false,
	// enableReinitialize: true,
	// keepDirtyOnReinitialize: true,
	destroyOnUnmount: false,
	forceUnregisterOnUnmount: true,
	validate,
})(ArtistPersonalInfo)

export default connect(({ user, form, dashboard }) => ({
    currentUser: user.currentUser,
    isGettingUser: user.isGettingUser,
    countriesList: dashboard.countriesList,
    phoneCodeList: dashboard.phoneCodeList,
    initialValues: getValues(
      form.ArtistPersonalInfo,
      user.currentUser && user.currentUser.profile
			?
      {
        first_name: user.currentUser.profile.first_name,
				last_name: user.currentUser.profile.last_name,
				birth_date: user.currentUser.profile.birth_date,
				over18: !!user.currentUser.profile.over18,
				gender: user.currentUser.profile.gender,
				nationality: String(user.currentUser.profile.nationality),
				location: user.currentUser.profile.location || {},
				phone_number: user.currentUser.profile.phone_number,
				country_code: user.currentUser.profile.phone_number ? user.currentUser.profile.phone_number.country_code : null,
      }
      : {}
      ),
  }), {
  getCountriesTree,
})(ArtistPersonalInfo)

export const ServiceInfoThirdForm = props => {
	const { initialValues, showValidationError } = props;

  const TEMP_cvWrapperStyle = {
	  display: 'none'
  };

	return (
		<div className="curriculum-vitae-form__item">
			<div className="curriculum-vitae-form__item-header">
				<span
					className={classnames('curriculum-vitae-form__title d-flex align-items-center', 'personal-info__info', {
						filled: initialValues.cv,
					})}
				>
					<span style={TEMP_cvWrapperStyle} className="-band-contact-info__optional">{translate('artistpersonalinfo.cv')}</span>{' '}
					<span style={TEMP_cvWrapperStyle} className="band-contact-info__optional">{translate('artistpersonalinfo.optional')}</span>
				</span>
			</div>
			<div className="curriculum-vitae-form__file-wrapper" style={TEMP_cvWrapperStyle}>
				<p className="curriculum-vitae-form__text">{translate('artistpersonalinfo.cvText')}</p>
				<Field
					style={{ display: 'none' }}
					name="cv"
					type="file"
					accept=".pdf"
					className="mw-100"
					label={translate('artistpersonalinfo.uploadCV')}
					showValidationError={showValidationError}
					component={FileInput}
				/>
			</div>
		</div>
	)
};
