import React, { Component } from 'react'
import { Field, reduxForm } from 'redux-form'
import {
  getValues,
  adjustQuestionsAnswersResponse,
} from '../../../helpers/methods'
import {
  TextFieldComponent,
  SelectFieldComponent,
} from '../../CommonComponent'
import validate from '../../../helpers/validators/artist/TalentInfo'
import '../../../styles/components/WizardOverviewServiceInfo.scss'
import { connect } from 'react-redux'
import { Asterisk } from '../../common/Asterisk/Asterisk'
import { registrationConfig } from '../../../config'
import QuestionsDropdown from '../shared/QuestionsDropdown'
import {dropdownQuestions, MAX_BRIEF_INTRODUCTION_LENGTH} from '../../../utils/constants'

export class WizardOverviewTalentInfoForm extends Component {

  render () {
    return (
      <div>
        <div className="service-info-form__wrapper">
          <ServiceInfoThirdForm/>
        </div>
        <div className="service-info-form__wrapper">
          <ServiceInfoFirstForm/>
        </div>
        <div className="service-info-form__wrapper">
          <Field
            name="questions_answers"
            component={QuestionsDropdown}
            questions={dropdownQuestions.artistQuestions}
          />
        </div>
      </div>
    )
  }
}

WizardOverviewTalentInfoForm = reduxForm({
  form:                     'TalentDetails',
  destroyOnUnmount:         false,
  forceUnregisterOnUnmount: false,
  enableReinitialize:       true,
  validate,
})(WizardOverviewTalentInfoForm);

export default connect(
  ({ user, form }) => ({
    currentUser:   user.currentUser,
    initialValues: getValues(form.TalentDetails,
      user.currentUser && user.currentUser.profile ? {
        brief_introduction: user.currentUser.profile.brief_introduction,
        about:              user.currentUser.profile.about,
        name:               user.currentUser.profile.name,
        questions_answers:  adjustQuestionsAnswersResponse(
          user.currentUser.answers) || [],
      } : {}),
  }),
)(WizardOverviewTalentInfoForm)

class ServiceInfoFirstForm extends Component {

  render () {

    const maxLength = max => value => {

      let v;
      let result = value.length > max;

      if(result === false) {
        v = value;
      }
      return v;
    };

    return (
      <div className="service-info-form__item">
        <div className="service-info-form__item-header">
          <span className="fa-stack info-popup-window"
                data-title={registrationConfig.popupText.artistBriefIntroduction}>
            <i className="fa fa-circle-thin fa-stack-2x"/>
            <i className="fa fa-info fa-stack-1x"/>
          </span>
          <h4 className="service-info-form__title">
            Brief introduction <Asterisk/> <span className="description">(Max. 155 characters)</span>
          </h4>
        </div>
        <Field
          hintText="Start writing here"
          name="brief_introduction"
          inputStyle={{ color: '#000' }}
          style={{ fontSize: '20px', margin: '10px 0 5px', lineHeight: 1.4 }}
          fullWidth={true}
          normalize={maxLength(MAX_BRIEF_INTRODUCTION_LENGTH)}
          component={TextFieldComponent}/>
      </div>
    )
  }
}

class ServiceInfoThirdForm extends Component {
  render () {
    return (
      <div
        className="service-info-form__item service-info-form__item--large-size">
        <div className="service-info-form__item-header">
          <h4 className="service-info-form__title">
            Your profile name (artistic name) <Asterisk/>
          </h4>
        </div>
        <Field
          hintText="Tell us who you are? What you do?"
          name="name"
          inputStyle={{ color: '#000' }}
          style={{ fontSize: '20px', margin: '10px 0 5px' }}
          fullWidth={true}
          component={TextFieldComponent}
        />
      </div>
    )
  }
}
