import React, { Component } from 'react'
import { InputStyles, SortableList, PlaceholderStyles } from './CommonComponent'
import { TextFieldComponent, GoogleMapsAutocomplete, PhoneComponent } from './../CommonComponent'
import SelectField from 'material-ui/SelectField'
import MenuItem from 'material-ui/MenuItem'
import TextField from 'material-ui/TextField'
import AutoComplete from 'material-ui/AutoComplete'
import { Field } from 'redux-form'
import { arrayMove } from 'react-sortable-hoc'
import { autocompleteTags } from '../../redux/modules/profile'
import { connect } from 'react-redux'
import { includes, cloneDeep } from 'lodash'
import { currency, YEARS_IN_BUSINESS_LOW, YEARS_IN_BUSINESS_HIGH } from '../../utils/constants'
import classnames from 'classnames'
import { Asterisk } from '../common/Asterisk/Asterisk'
import { registrationConfig } from '../../config'
import { getTextForProfileType } from '../../helpers/textForProfileTypes'
import { Meta } from 'antd/lib/list/Item'
import Loader from "../discover/Loader/Loader";

export class Experience extends Component {
	clickHandler = val => {
		const {
			input: { onChange },
		} = this.props

		onChange(val)
	}

	render() {
		const {
			input: { value },
			meta,
			typeProfile,
		} = this.props

		return (
			<div className="service-detail-form__item">
				<div className="service-detail-form__item-header">
					<h4 className="service-detail-form__title">
						Professional experience <Asterisk className="text-danger ml-1" />
					</h4>
				</div>
				<div className="radio-group-wrapper__small at-m">
					{YEARS_IN_BUSINESS_LOW.map((item, index) => (
						<label key={index} className="radio-group radio-inline" style={{ cursor: 'pointer' }}>
							<input
								type="radio"
								name="experience"
								className="d-none"
								{...{ checked: value === item.value }}
								onClick={() => this.clickHandler(item.value)}
							/>
							<span>{item.label}</span>
						</label>
					))}
				</div>
				<div className="radio-group-wrapper__small at-y">
					{YEARS_IN_BUSINESS_HIGH.map((item, index) => (
						<label key={index} className="radio-group radio-inline" style={{ cursor: 'pointer' }}>
							<input
								type="radio"
								name="experience"
								className="d-none"
								{...{ checked: value === item.value }}
								onClick={() => this.clickHandler(item.value)}
							/>
							<span>{item.label}</span>
						</label>
					))}
				</div>
				{meta.error && meta.error ? <div className="expirience-error">{meta.error}</div> : null}
			</div>
		)
	}
}
@connect(({ user }) => ({
	currentUserType: user.currentUser.profile_type,
}))
export class ContactInformationComponent extends Component {
	render() {
		const { fieldNames, filled, currentUserType, touch, labelText } = this.props;

		return (
			<div className="band-contact-info__contact-information band-contact-info__container google-location-input">
				<div className={classnames('band-contact-info__info', { filled })}>
					{labelText ? labelText : 'Contact Information'}
					<span className="fa-stack info-popup-window info-popup-window pl-2"
						data-title={getTextForProfileType('registration', 'contactInformation', currentUserType)}
					>
						<i className="fa fa-circle-thin fa-stack-2x"/>
              			<i className="fa fa-info fa-stack-1x"/>
					</span>
          <Asterisk />
				</div>
				<Field
					makeTouched={() => touch(fieldNames[0])}
					onBlur={() => touch(fieldNames[0])}
					fullWidth
					name={fieldNames[0]}
					component={GoogleMapsAutocomplete}
					hintText="Enter Address"
					hintStyle={{
						fontWeight: '300',
						color: '#a3a3a3',
					}}
					style={{
						textAlign: 'left',
						marginRight: '20px',
					}}
				/>
        {(this.props.phoneCodeList && this.props.phoneCodeList.length) ?
          <Field
            hintText="Phone Number"
            name={fieldNames[1]}
            style={{margin: '10px 0 5px'}}
            fullWidth={true}
            countries={this.props.phoneCodeList}
            component={PhoneComponent}
          /> :
          <div className="d-flex justify-content-center">
            <Loader />
          </div>
        }
			</div>
		)
	}
}

@connect(
	({ profile }) => ({
		tags: profile.tags,
	}),
	{
		autocompleteTags,
	}
)
export class Tags extends Component {
	constructor(props) {
		super(props)
		this.state = {
			value: [],
			tagName: '',
		}

		this.materialStyle = {
			chip: {
				margin: 4,
			},
		}
	}

	handleRequestDelete = key => {
		const {
			input: { onChange, value },
		} = this.props
		let tags = cloneDeep(value.basic)
		tags.splice(key, 1)
		onChange({ basic: tags })
	}

	addTag() {
		const {
			input: { onChange, value },
		} = this.props
		const { tagName } = this.state

		if (tagName) {
			const alreadyExist = value.basic ? !!value.basic.find(tag => tag.content === tagName) : false

			if (!alreadyExist) {
				onChange({ basic: [...cloneDeep(value.basic), { content: this.state.tagName }] })

				this.setState({ tagName: '' })
			}
		}
	}

	onSortEnd = ({ oldIndex, newIndex }) => {
		const {
			input: { onChange, value },
		} = this.props
		onChange({ basic: arrayMove(cloneDeep(value.basic), oldIndex, newIndex) })
	}

	autocompleteTagsHandler = (searchText, dataSource, params) => {
		this.setState({
			tagName: searchText,
		})

		if (searchText) {
			this.props.autocompleteTags(searchText)
		}
	}

	render() {
		const {
			input: { value },
		} = this.props

		return (
			<div className="service-detail-form__item">
				<div className="service-detail-form__item-header">
					<h4 className="service-detail-form__title">Tags</h4>
				</div>
				<div className="service-detail-form__item-content">
					<div className="talent-tags-controller">
						<div className="talent-tags-controller__control">
							<div className="talent-tags-controller__control-form">
								<AutoComplete
									fullWidth={true}
									inputStyle={InputStyles}
									searchText={this.state.tagName}
									hintStyle={PlaceholderStyles}
									hintText="Enter your tag"
									onUpdateInput={this.autocompleteTagsHandler}
									onNewRequest={this.addTag}
									dataSourceConfig={{ text: 'content', value: 'mentions_count' }}
									dataSource={this.props.tags}
								/>
								<div className="talent-tags-controller__control-add">
									<button type="button" onClick={() => this.addTag(this.state.tagName)}>
										Click to create new tag
									</button>
									<i className="fa fa-search" aria-hidden="true" />
								</div>
								<p className="talent-tags-controller__subtitle">
									Tags help clients and collaborators find you. Choose single-word search times that best describe you.
								</p>
							</div>
						</div>
						<p className="talent-tags-controller__subtitle-big">Your Selected Tags</p>
						<SortableList
							tags={value.basic || []}
							onSortEnd={this.onSortEnd}
							onTagDelete={this.handleRequestDelete}
							axis="x"
						/>
					</div>
				</div>
			</div>
		)
	}
}
