import React from "react";
import * as _ from "lodash";
import {documentErrors} from "../../../utils/constants";
import SVGInline from "react-svg-inline";
import { KycDocumentType, KycStatusType } from "./KycConstants";

const file_upload_white = require('../../../assets/file_upload_white.svg')
const cross_symbol = require('!!svg-inline-loader?classPrefix!../../../assets/icons/cross-symbol.svg')

const MAX_FILE_SIZE_IN_MB = 5;

export class FileInput extends React.Component {
    input = null

    constructor(props) {
        super(props)

        this.state = {}
    }

    onRemoveFile = (idx) => {
        const {
            input: {onChange, value},
        } = this.props

        const newValue = [...value].filter((_, i) => i !== idx)

        onChange(newValue)
    }

    onChange = e => {
        const {
            input: {onChange, value},
            showValidationError,
        } = this.props
        const maxFileSize = MAX_FILE_SIZE_IN_MB * 1024 * 1024

        const files = value ? [...value, ...e.target.files] : [...e.target.files]
        const size = files.reduce((a, b) => {
            return b.size ? +a + +b.size : +a
        }, 0)


        if (size < maxFileSize) {
            onChange(files)
        } else {
            showValidationError(`${MAX_FILE_SIZE_IN_MB} files size exceeded`)
        }
    }

    getDocType = (type) => {
        switch (type) {
            case 'kyc_identity_proof':
                return KycDocumentType.IDENTITY_PROOF
            case 'kyc_registration_proof':
                return KycDocumentType.REGISTRATION_PROOF
            case 'kyc_articles_association':
                return KycDocumentType.ARTICLES_OF_ASSOCIATION
            default:
                return null
        }
    }

    getStatusIndicator = (files, doc_type) => {
        if (!files || !files.length) {
            return <span/>
        } else {
            const reason_type = files[0].mp_response
            const document_type = this.getDocType(doc_type)

            switch (files[0].status) {
                case KycStatusType.VALIDATED:
                    return <span className="indicator small green">verified</span>
                case KycStatusType.REFUSED:
                    return <span className="indicator small red info-popup-window"
                                 data-title={_.get(documentErrors, `${reason_type}.${document_type}`, 'N/A')}>unverified</span>
                case KycStatusType.CREATED:
                case KycStatusType.VALIDATION_ASKED:
                default:
                    return <span className="indicator small grey">pending</span>
            }
        }
    }

    cutFileName = (name) => {
        if (!name || name.length <= 25) {
            return name;
        }
        return name.slice(0, 15) + '...'
    }

    render() {
        const {
            input: {value, name},
            label = 'Upload',
            withStatus = true,
        } = this.props

        return (
            <div className='input-wrapper'>
                <div className="files">
                    Files:
                    <div className="files-wrapper">
                        {value && value.map((item, idx) => <div className="indicator small grey" key={idx}>
                            <span onClick={() => this.onRemoveFile(idx)} style={{cursor: 'pointer'}}>
                                <SVGInline svg={cross_symbol} width="12px" fill="#444"/>
                            </span>&nbsp;
                                <span>
                                {this.cutFileName(item.name)}
                            </span>
                            </div>
                        )}
                    </div>
                </div>
                {withStatus ?
                    <div className="status">
                        Status:
                        {this.getStatusIndicator(value, name)}
                    </div>
                    : <div className="status"/>
                }
                <div className="upload">
                    <label onClick={() => this.input.click()}>
                        <div className="input-file-button">
                            <span className="upload-icon">
                                <img src={file_upload_white}/>
                            </span>
                            {` ${label}`}
                        </div>
                    </label>

                    <input
                        type="file"
                        ref={r => {
                            this.input = r
                        }}
                        style={{display: 'none'}}
                        onChange={this.onChange}
                        accept=".pdf, .jpg, .jpeg, .png, .gif"
                        multiple
                    />

                    <p className="info">size maximum {MAX_FILE_SIZE_IN_MB}MB<br/>(only .pdf, .jpeg, .jpg, .gif and .png)</p>
                </div>
            </div>
        )
    }
}
