import ReviewStars from './profile/ReviewStars'
import ContractPreview from './DashBoard/Contracts/ContractPreview/ContractPreview'

export LoginForm from './LoginForm'
export ContactForm from './ContactForm'
export RegisterForm from './RegisterForm'
export ShowPassword from './ShowPassword'
export EditProfile from './EditProfile'
export NavBar from './NavBar/NavBar'
export { Hero } from './HomePage/Hero/Hero'
export * as register from './register'
export { HomeEvent } from './HomePage/HomeEvent/HomeEvent'
export { HomeArticle } from './HomePage/HomeArticle/HomeArticle'
export { HomeAction } from './HomePage/HomeAction/HomeAction'
export HomeProfiles from './HomePage/HomeProfiles/HomeProfiles'
export { HomeProfile } from './HomePage/HomeProfiles/HomeProfile/HomeProfile'
export ProfilePhoto from './profile/ProfilePhoto'
export ProfileTags from './profile/ProfileTags'
export ProfileActions from './profile/ProfileActions'
export MobileProfileActions from './profile/MobileProfileActions'
export ProfilePrices from './profile/ProfilePrices'
export ProfileEvents from './profile/ProfileEvents/ProfileEvents'
export ProfileAbout from './profile/ProfileAbout/ProfileAbout'
export ProfileCancellationPolicy from './profile/ProfileCancellationPolicy/ProfileCancellationPolicy'
export ReviewStars from './profile/ReviewStars'
export ReviewsRating from './profile/ReviewsRating'

export VideoUploadBlock from './VideoUploadBlock'
export ReviewForm from './profile/ReviewForm'
export AddNewBlock from './AddNewBlock'
// export AllinBlock from './HomePage/AllinBlock'
// export GeneralFooter from './HomePage/GeneralFooter'

export MediaBlock from './ContentBlock/MediaBlock'
export Footer from './Footer/Footer'
export AvatarEditorComp from './AvatarEditorComp'
export PhotoEditor from './PhotoEditor'
export CardEditor from './CardEditor'

export ShareBar from './share/ShareBar'
export ShareBlock from './share/ShareBlock'
export SharePopUp from './share/SharePopUp'
export ShareButton from './share/ShareButton'
export CustomAudioPlayer from './CustomAudioPlayer'
export SpotifyAudioPlayer from './SpotifyAudioPlayer'

export ProfileSideBar from './profile/ProfileSideBar'
export ProfileMap from './profile/ProfileMap'

export SelectedProfile from './compare/CompareModal/SelectedProfile/SelectedProfile'
export SuggestedProfile from './compare/CompareModal/SuggestedProfile/SuggestedProfile'
export CompareModal from './compare/CompareModal/CompareModal'
export ComparePopUp from './compare/ComparePopUp/ComparePopUp'
export SearchFilterBar from './search/SearchFilterBar/SearchFilterBar'
//discover page
export GigFeedTab from './discover/GigFeedTab/GigFeedTab/GigFeedTab'
export SpotlightTab from './discover/SpotlightTab/SpotlightTab'
export ArtistsTab from './discover/ArtistsTab/ArtistsTab'
export PrimaryGigFeed from './discover/GigFeedTab/PrimaryGigFeed/PrimaryGigFeed'

export ContractPreview from './DashBoard/Contracts/ContractPreview/ContractPreview.jsx'
