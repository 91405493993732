import React, { Component } from 'react'
import { isEmpty } from 'lodash'

import missingFields from '../../helpers/missingFields'

import './MissingFields.scss'

export default class MissingFields extends Component {

  getListWithDisableLeftMenuFields = (profile, missingFieldsIdList, registerProfileTabCodes) => {
    return profile.map((form, index, profileArr) => {
      if (index > 0) {
        return missingFieldsIdList.indexOf(registerProfileTabCodes[profileArr[index - 1]]) > -1;
      } else {
        return false;
      }
    })
  };

	render() {
		const { currentUser, currentKYC, currentType, currentForm, onClickFn } = this.props;

		if (isEmpty(currentUser) || currentUser.profile_type === null) return null;

    const missProfileFields = missingFields({currentUser, currentKYC}, 'profile');
    const missMediaFields = missingFields({currentUser, currentKYC}, 'media');

    const missingProfileFieldIdsList = missProfileFields.map(x => x.form);
    const nextFormId = missingProfileFieldIdsList[0] || null;
    const profileFieldIdsInactive = missingProfileFieldIdsList.filter(
      profileFieldId => profileFieldId !== currentForm && (nextFormId && profileFieldId !== nextFormId)
    );

		return (
			<div className="missing-fields">
        {/*{missProfileFields.length !== 0 && (
          <div>
            <div className="title">Profile Info Missing</div>
            <div className="sub-title">Click and fill your missing info </div>
            {missProfileFields.map((field, index) => {
              return (
                profileFieldIdsInactive.includes(field.form) ?
                  <div
                    key={`missing-fild-${index}`}
                    className="missing-field-link"
                  >
                  {field.text}
                  </div> :
                  <div
                    key={`missing-fild-${index}`}
                    onClick={() => onClickFn(field.form, 'profile')}
                    className="missing-field-link"
                  >
                    {field.text}
                  </div>
              )
            })}
          </div>
        )}
        {missMediaFields.length !== 0 && (
          <div>
            <div className="title">Media Info Missing</div>
            <div className="sub-title">Click and fill your missing info </div>
            {missMediaFields.map((field, index) => {
              return (
                <div key={`missing-fild-${index}`} onClick={() => onClickFn(field.form, 'media')} className="missing-field-link">
                  {field.text}
                </div>
              )
            })}
          </div>
        )}*/}

				{/* {missFields.length !== 0 && (
					<div>
						{currentType === 'profile' ? (
							<div className="title">Profile Info Missing</div>
						) : (
							<div className="title">Media Info Missing</div>
						)}
						<div className="sub-title">Click and fill your missing info </div>
						{missFields.map((field, index) => {
							return (
								<div key={`missing-fild-${index}`} onClick={() => onClickFn(field.form)} className="missing-field-link">
									{field.text}
								</div>
							)
						})}
					</div>
				)} */}
			</div>
		)
	}
}
