import React from 'react'
import './Coupon.scss'
import moment from 'moment'
import classNames from 'classnames'

const gigworksLogoWhite = require('../../../assets/images/gigworks-logo-white.png')
const gigworksLogo = require('../../../assets/images/gigworks-logo.png')
import {connect} from 'react-redux'
import PromotionDiscountTypes from "../../../utils/PromotionDiscountTypes";
import PromotionTypes from "../../../utils/PromotionTypes";
import {PAYMENT_CYCLES} from "../../../utils/constants";

const APPLICABLE_TO_TEXTS = {
  'client'  : 'Clients Only',
  'venue'  : 'Venues Only',
  'artist' : 'Artists Only',
};

const PLAN_TYPES = {
  1 : "Pro",
  2 : "Premium"
}

@connect(
  ({common}) => ({
    //categoriesMap: common.categoriesMap,
  })
)
class  Coupon extends React.Component {

  render() {
    const {
      type_id,
      title,
      description,
      code,
      valid_to,
      path : image_path
    } = this.props;

    let background_img = image_path ? process.env.REACT_APP_API_BACKEND_PROTOCOL + "://" + process.env.REACT_APP_API_BACKEND_URL + "/" + image_path : null;

    if(type_id === PromotionTypes.MembershipPromo) { background_img = null; }

    return (
      <div
        className={classNames('Coupon p-3 d-flex flex-column', {
          'is-gig-coupon': true,
          'has-background-image': background_img
        })}
        style={background_img ? {backgroundImage: `url(${background_img})`} : null}>

        <div className="d-flex align-items-start justify-content-between">
          <img src={background_img ? gigworksLogoWhite : gigworksLogo} className="Coupon__Logo" alt="Gigworks logo"/>

          <span className="Coupon__SmallText">
                  Expires {moment(valid_to).format("MMM D, YYYY")}
              </span>
        </div>

        <div className="Coupon__InnerWrapper d-flex flex-column align-items-center justify-content-stretch flex-grow-1">

          {this.renderHeading()}

          {this.renderSmallText()}

          <div className="Coupon__SmallText text-center mb-2">{description}</div>

          {type_id === PromotionTypes.MembershipPromo ? this.renderMembershipValidity() : this.renderGigValidity() }

          <div
            className="Coupon__Code Coupon__MediumText mt-auto mb-0 py-1 px-3 d-flex align-items-center justify-content-center">
            <i className="fa fa-clone mr-2"/>
            {code}
          </div>
        </div>
      </div>
    );
  }

  renderHeading() {
    if(this.props.type_id === PromotionTypes.GigPromo) {
      return this.renderGigPromoHeading();
    }
    else {
      return this.renderMembershipPromoHeading();
    }
  }

  renderSmallText() {
    const smallText = this.props.type_id === PromotionTypes.GigPromo ?
      this.getGigPromoSmallText() :
      this.getMembershipPromoSmallText();

    return (
      <div className="d-flex justify-content-center mb-3">
        <span className="Coupon__SmallText Coupon__Type py-1 px-3">{smallText}</span>
      </div>
    )
  }

  renderGigPromoHeading() {
    const title = this.props.title;

    return (
      <div className="Coupon__Heading d-flex align-items-center justify-content-center my-3">
        <span className="Coupon__Heading__Free text-center">{title}</span>
      </div>
    );
  }

  renderMembershipPromoHeading() {
    const title = this.props.title;
    const planTypeId = this.props.plan_type_id;
    const planTypeString = PLAN_TYPES[planTypeId];

    return (
      <>
        <div className="Coupon__Heading d-flex align-items-center justify-content-center my-3">
          <span className="Coupon__Heading text-center">{title}</span>
        </div>
        <div className="Coupon__Heading__Subheading d-flex align-items-center justify-content-center mt-1 mb-3">
          <span className="Coupon__Heading__Subheading text-center">{planTypeString.toUpperCase()}</span>
        </div>
      </>
    );
  }

  getGigPromoSmallText() {
    const { discount_type_id, value } = this.props;

    if(discount_type_id === PromotionDiscountTypes.Waiver || discount_type_id === PromotionDiscountTypes.TaxWaiver) {
      return "Fee Waiver";
    }
    else if(discount_type_id === PromotionDiscountTypes.Cash || discount_type_id === PromotionDiscountTypes.CashContractMin) {
      return "-" + value + "€";
    }
    else if(discount_type_id === PromotionDiscountTypes.Percent || discount_type_id === PromotionDiscountTypes.PercentContractMin) {
      return value + "%";
    }
  }

  getMembershipPromoSmallText() {
    const { discount_type_id, value } = this.props;

    if(discount_type_id === PromotionDiscountTypes.Waiver) {
      return "Fee Waiver";
    }
    else if(discount_type_id === PromotionDiscountTypes.Cash) {
      return "-" + value + "€";
    }
    else if(discount_type_id === PromotionDiscountTypes.Percent) {
      return "-" + value + "%";
    }
    else if(discount_type_id === PromotionDiscountTypes.FreeUpgrade) {
      return "Free Upgrade";
    }
  }

  renderGigValidity() {
    const { contract_max, contract_min } = this.props;

    return (
      <div className="Coupon__SmallText text-center mb-2">
        <span className="Coupon__SmallText">Applicable to:</span>
        <br/>
        <span className="Coupon__MediumText font-weight-bolder">{this.getApplicableToText()}</span>
        <div style={{marginTop: '5px'}}>
          {contract_max ? `Maximum Contract Amount: ${contract_max} Euros` : null}
          {contract_min ? `Minimum Contract Amount: ${contract_min} Euros` : null}
        </div>
      </div>
    );
  }

  renderMembershipValidity() {
    return (
      <>
        <div className="Coupon__SmallText text-center mb-2">
          <span className="Coupon__SmallText">Valid for: </span>
          <span className="Coupon__MediumText font-weight-bolder">{this.getValidForText()}</span>
        </div>
        <div className="Coupon__SmallText text-center mb-2">
          <span className="Coupon__SmallText">Applicable to: </span>
          <span className="Coupon__MediumText font-weight-bolder">{this.getApplicableToText()}</span>
        </div>
      </>
    );
  }

  getValidForText() {
    const cycle = this.props.cycle;

    switch (cycle) {
      case '1_MONTH':
        return '1 month';
      case '3_MONTHS':
        return '3 months';
      case '6_MONTHS':
        return '6 months';
      case '12_MONTHS':
        return '12 months';
      default:
        return '1 month';
    }
  }

  getApplicableToText() {
    const profile_type = this.props.profile_type;
    if(profile_type in APPLICABLE_TO_TEXTS) {
      return APPLICABLE_TO_TEXTS[profile_type];
    }

    return 'All Users';
  }
}

export default Coupon
