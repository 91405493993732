import React, { Component } from 'react'
import { Field, reduxForm } from 'redux-form'
import validate from '../../../../helpers/validators/venue/VenueInfo'
import { getValues } from '../../../../helpers/methods'
import './WizardVenueInfo.scss'
import { connect } from 'react-redux'
import Checkbox from 'material-ui/Checkbox'
import {
	TextFieldComponent,
	GoogleMapsAutocomplete,
	BirthDatePickerComponent,
	VenueBirthDatePickerComponent,
	PhoneComponent,
    CheckboxComponent,
    SelectFieldComponent
} from '../../../CommonComponent'
import { ContactInformationComponent } from '../../CommonRegistrationComponent'
import { genders, nationalities, defaultFontSize } from '../../../../utils/constants'
import { getCountriesTree } from '../../../../redux/modules/dashboard'
import Loader from '../../../discover/Loader/Loader'
import classnames from 'classnames'
import { Asterisk } from '../../../common/Asterisk/Asterisk'
import { registrationConfig } from '../../../../config'
import TextField from "material-ui/TextField";

class WizardVenueInfo extends Component {
	constructor(props) {
		super(props)

		this.state = {
			showVenueContactInfo: true,
			showManagerOrRepresentativeInfo: true,
			showAdditionalContact: false
		}
	}

	toggleContactInfo = () => {
		this.setState({ showVenueContactInfo: !this.state.showVenueContactInfo });
	};

	toggleManagerOrRepresentativeInfo = () => {
		this.setState({ showManagerOrRepresentativeInfo: !this.state.showManagerOrRepresentativeInfo });
	};

	toggleAdditionalContact = () => {
		this.setState({ showAdditionalContact: !this.state.showAdditionalContact });
	};

  componentDidMount() {
    this.props.getCountriesTree()
  }

	render() {
		const { initialValues } = this.props;
		return (
			<form className="venue-info plans-and-venue-profile">
				{ this.state.showVenueContactInfo ?
					<div>
						<div className="venue-info__fields venue-info__container">
							<div className={classnames('venue-info__info-bigger', { filled: initialValues.name })}
								onClick={this.toggleContactInfo}
							>
								<i className="fa fa-vertical-line" />Venue Contact Info <Asterisk />
							</div>
							<Field
								name="name"
								type="text"
								style={{ margin: '10px 0 5px' }}
								fullWidth={true}
								component={TextFieldComponent}
								hintText="Venue name"
							/>

							<Field
								name={'manager_email'}
								type="text"
								style={{ margin: '10px 0 5px' }}
								fullWidth={true}
								component={TextFieldComponent}
								hintText="Manager email"
							/>
						</div>

						<ContactInformationComponent
							filled={(initialValues.location && Object.keys(initialValues.location).length) || initialValues.phone_number}
							fieldNames={['location', 'phone_number']}
							touch={this.props.touch}
              phoneCodeList={this.props.phoneCodeList}
              labelText={'Venue Address and Phone Number'}
						/>

						<div className="venue-info__date venue-info__container">
							<div className={classnames('venue-info__info', { filled: initialValues.established_since })}>
								Established since <Asterisk />
							</div>
							<div className="venue-info__date-of-birth" style={{ width: '100%' }}>
								<Field name="established_since" hintText="Established since" component={VenueBirthDatePickerComponent} />
							</div>
						</div>
					</div>
				:
					<div className="venue-info__fields venue-info__container">
						<div className={classnames('venue-info__info-bigger', { filled: initialValues.name })}
							onClick={this.toggleContactInfo}
						>
							<i className="fa fa-plus" />Venue Contact Info <Asterisk />
						</div>
					</div>
				}
				{ this.state.showManagerOrRepresentativeInfo ?
					<div>
						<div className="venue-info__fields venue-info__container">
							<div className="venue-info__info-bigger"
                   onClick={this.toggleManagerOrRepresentativeInfo}
							>
								<i className="fa fa-vertical-line" />
								Manager or Representative Info
								<span
									className="fa-stack info-popup-window pl-2"
									data-title={registrationConfig.popupText.venueContactInformation}
								>
									<i className="fa fa-circle-thin fa-stack-2x"/>
									<i className="fa fa-info fa-stack-1x"/>
								</span>
							</div>
						</div>

						<BirthNameComponent filled={initialValues.manager_first_name && initialValues.manager_last_name} />
						<CompanyTitleComponent filled={initialValues.manager_company_title} fieldName={'manager_company_title'} />

						<ContactInformationManagerComponent
							filled={
								(initialValues.manager_location && Object.keys(initialValues.manager_location).length) ||
								initialValues.manager_phone_number
							}
              fieldNames={['manager_location', 'manager_phone_number']}
              phoneCodeList={this.props.phoneCodeList}
              touch={this.props.touch}
            />
						<div className="venue-info__gender venue-info__container">
							<div
								className={classnames('venue-info__info d-flex align-items-center', {
									filled: initialValues.manager_gender,
								})}
							>
								<span>Gender</span>
								<span className="venue-info__optional" />
							</div>
							<div>
								<Field name="manager_gender" component={Gender} />
							</div>
						</div>

            <div className="venue-info__nationality venue-info__container">
              <div className={classnames('venue-info__info', { filled: initialValues.manager_nationality })}>
                  Nationality <Asterisk />
              </div>
              <div className="personal-info__nationality" style={{ width: '100%' }}>
                {
                  (this.props.countriesList && this.props.countriesList.length) ?
                    <Field
                      name="manager_nationality"
                      type="string"
                      fullWidth
                      style={{ height: '3rem', textAlign: 'left' }}
                      options={this.props.countriesList}
                      hintText="Please select your nationality"
                      component={SelectFieldComponent}
                    />
                    :
                    <div className="d-flex justify-content-center">
                      <Loader />
                    </div>
                }
              </div>
            </div>

						<div className="venue-info__date venue-info__container">
							<div className={classnames('venue-info__info', { filled: initialValues.manager_birth_date })}>
								Date of birth <Asterisk />
							</div>
							<div className="venue-info__date-of-birth" style={{ width: '100%' }}>
								<Field
									name="manager_birth_date"
									hintText="Birth day"
									mode="landscape"
									component={BirthDatePickerComponent}
								/>
							</div>
							<div className="mt-3">
								<Field
                  name="over18"
                  label={<>I confirm that I'm over 18 years old <Asterisk /></>}
                  labelStyle={{ width: 'initial'}}
                  component={CheckboxComponent}
                />
							</div>
						</div>

						{ this.state.showAdditionalContact ?
							<div>
								<div className="venue-info__fields venue-info__container">
									<div className="venue-info__info-additional-contact open"
										onClick={this.toggleAdditionalContact}
									>
										<i className="fa fa-vertical-line" />
										Additional Contact
									</div>
								</div>
								<BirthNameAdditionalComponent
									filled={initialValues.additional_first_name && initialValues.additional_last_name}
								/>
								<CompanyTitleComponent filled={initialValues.additional_company_title} fieldName={'additional_company_title'} />
								<div className="venue-info__contact-information venue-info__container">
									<div
										className={classnames('venue-info__info', {
											filled: initialValues.additional_email || initialValues.additional_phone_number,
										})}
									>
										Contact Information
									</div>
									<Field
										hintText="Email"
										name="additional_email"
										fullWidth={true}
										style={{ margin: '5px 0' }}
										component={TextFieldComponent}
									/>
                  {(this.props.phoneCodeList && this.props.phoneCodeList.length) ?
                    <Field
                      name="additional_phone_number"
                      additional_name="additional_country_code"
                      style={{ margin: '10px 0 5px' }}
                      fullWidth={true}
                      countries={this.props.phoneCodeList}
                      component={PhoneComponent}
                    /> :
                    <div className="d-flex justify-content-center">
                      <Loader />
                    </div>
                  }
								</div>
							</div>
						:
							<div className="venue-info__fields venue-info__container">
								<div className="venue-info__info-additional-contact"
									onClick={this.toggleAdditionalContact}
								>
									<i className="fa fa-plus" />
									Add Additional Contact
								</div>
							</div>
						}
					</div>
				:
					<div className="venue-info__fields venue-info__container">
						<div className="venue-info__info-bigger"
							 onClick={this.toggleManagerOrRepresentativeInfo}
						>
							<i className="fa fa-plus" />
							Manager or Representative Info
							<span
								className="fa-stack info-popup-window pl-2"
								data-title={registrationConfig.popupText.venueContactInformation}
							>
								<i className="fa fa-circle-thin fa-stack-2x"/>
              					<i className="fa fa-info fa-stack-1x"/>
							</span>
						</div>
					</div>
				}
			</form>
		)
	}
}

WizardVenueInfo = reduxForm({
	form: 'VenueInfo',
	// destroyOnUnmount: false,
	// forceUnregisterOnUnmount: false,
	// enableReinitialize: true,
	// keepDirtyOnReinitialize: true,
	destroyOnUnmount: false,
	forceUnregisterOnUnmount: true,
	validate,
})(WizardVenueInfo)

export default connect(({ user, form, dashboard }) => ({
  currentUser: user.currentUser,
  countriesList: dashboard.countriesList,
  phoneCodeList: dashboard.phoneCodeList,
	initialValues: getValues(
		form.VenueInfo,
		user.currentUser && user.currentUser.profile
			? {
        name: user.currentUser.profile.name.trim(),
        manager_email: user.currentUser.profile.manager_email,
        established_since: user.currentUser.profile.established_since,
        manager_first_name: user.currentUser.profile.manager_first_name,
        manager_last_name: user.currentUser.profile.manager_last_name,
        location: user.currentUser.profile.location || {},
        phone_number: user.currentUser.profile.phone_number,
        country_code: user.currentUser.profile.phone_number ? user.currentUser.profile.phone_number.country_code : null,
        manager_location: user.currentUser.profile.manager_location || {},
        manager_phone_number: user.currentUser.profile.manager_phone_number,
        manager_country_code: user.currentUser.profile.manager_phone_number ? user.currentUser.profile.manager_phone_number.country_code : null,
        manager_company_title: user.currentUser.profile.manager_company_title,
        manager_gender: user.currentUser.profile.manager_gender,
        manager_nationality: user.currentUser.profile.nationality ? String(user.currentUser.profile.nationality) : null,
        manager_birth_date: user.currentUser.profile.manager_birth_date,
        over18: !!user.currentUser.profile.over18,
        additional_first_name: user.currentUser.profile.additional_first_name,
        additional_last_name: user.currentUser.profile.additional_last_name,
        additional_company_title: user.currentUser.profile.additional_company_title,
        additional_email: user.currentUser.profile.additional_email,
        additional_phone_number: user.currentUser.profile.additional_phone_number,
        additional_country_code: user.currentUser.profile.additional_phone_number ? user.currentUser.profile.additional_phone_number.country_code : null,
			  }
			: {}
	),
}), {
    getCountriesTree
})(WizardVenueInfo)


class Gender extends Component {
	render() {
		const {
			input: { value, onChange },
		} = this.props

		return (
			<div className="venue-info__checkbox-wrapper">
				{genders.map((gender, index) => (
					<Checkbox
						key={index}
						style={{ ...defaultFontSize, width: 'auto' }}
						labelStyle={{ width: 'auto' }}
						label={gender.label}
						checked={value === gender.value}
						onCheck={() => {
							onChange(gender.value)
						}}
					/>
				))}
			</div>
		)
	}
}

class BirthNameComponent extends Component {
	render() {
		const { filled } = this.props

		return (
			<div className="venue-info__fields venue-info__container">
				<div className={classnames('venue-info__info', { filled })}>
					Legal name <Asterisk />
				</div>
				<Field
					name="manager_first_name"
					type="text"
					style={{ margin: '10px 0 5px' }}
					fullWidth={true}
					component={TextFieldComponent}
					hintText="First Name"
				/>
				<Field
					name="manager_last_name"
					type="text"
					style={{ margin: '10px 0 5px' }}
					fullWidth={true}
					component={TextFieldComponent}
					hintText="Last Name"
				/>
			</div>
		)
	}
}

class BirthNameAdditionalComponent extends Component {
	render() {
		const { filled } = this.props

		return (
			<div className="venue-info__fields venue-info__container">
				<div className={classnames('venue-info__info', { filled })}>Legal name</div>
				<Field
					name="additional_first_name"
					type="text"
					style={{ margin: '10px 0 5px' }}
					fullWidth={true}
					component={TextFieldComponent}
					hintText="First Name"
				/>
				<Field
					name="additional_last_name"
					type="text"
					style={{ margin: '10px 0 5px' }}
					fullWidth={true}
					component={TextFieldComponent}
					hintText="Last Name"
				/>
			</div>
		)
	}
}

class ContactInformationManagerComponent extends Component {
	constructor(props) {
		super(props)

		this.state = {
			dataSource: ['Pushkinskaya', 'Wall Street'],
		}
	}

	render() {
	  const { filled, fieldNames, touch } = this.props;

		return (
			<div className="venue-info__contact-information venue-info__container google-location-input">
				<div className={classnames('venue-info__info text-left ', { filled })}>
					<span
						className="fa-stack info-popup-window"
						data-title={registrationConfig.popupText.venueContactInformation}
					>
						<i className="fa fa-circle-thin fa-stack-2x"/>
            <i className="fa fa-info fa-stack-1x"/>
					</span>
					Manager or Representative's Address and Phone Number
          <Asterisk />
				</div>
				<div style={{ width: '100%' }}>
					<Field
            makeTouched={() => touch(fieldNames[0])}
            onBlur={() => touch(fieldNames[0])}
            fullWidth
						name="manager_location"
						component={GoogleMapsAutocomplete}
						hintText="Enter Address"
						hintStyle={{
							fontWeight: '300',
							color: '#a3a3a3',
						}}
						style={{
							textAlign: 'left',
							marginRight: '20px',
						}}
					/>
          {(this.props.phoneCodeList && this.props.phoneCodeList.length) ?
            <Field
              name="manager_phone_number"
              additional_name="manager_country_code"
              style={{ margin: '10px 0 5px' }}
              fullWidth={true}
              countries={this.props.phoneCodeList}
              component={PhoneComponent}
            /> :
            <div className="d-flex justify-content-center">
              <Loader />
            </div>
          }
				</div>
			</div>
		)
	}
}

class CompanyTitleComponent extends Component {
	render() {
		const { filled } = this.props

		return (
			<div className="venue-info__fields venue-info__container">
				<div className={classnames('venue-info__info', { filled })}>Title</div>
				<Field
					name={this.props.fieldName}
					type="text"
					style={{ margin: '10px 0 5px' }}
					fullWidth={true}
					component={TextFieldComponent}
					hintText="What is the manager or representative's title within your company?"
				/>
			</div>
		)
	}
}
