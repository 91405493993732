import './Asterisk.scss'
import React from 'react'

export const Asterisk = props => {
	return (
		<span className={`Asterisk ${props.className}`} style={{ color: props.color || 'red' }}>
			*
		</span>
	)
}
