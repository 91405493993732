import React, { Component } from 'react';
import { Link } from 'react-router-dom';

export default class ProfileTags extends Component {
  render() {
    const { profile } = this.props;

    return (
      <div className="profile__tags d-flex flex-wrap">
        {profile.tag_list && profile.tag_list.map((tag, index) => <Link key={index} to="/">{tag}</Link>)}
      </div>
    );
  }
}
