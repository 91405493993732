import React from 'react';
import { reduxForm, SubmissionError, Field } from 'redux-form';
import TextField from 'material-ui/TextField/TextField';
import RaisedButton from 'material-ui/RaisedButton/RaisedButton';
import apiClient from '../../../helpers/apiClient';
import { camelCase, mapKeys } from 'lodash';
import { defaultFontSize } from '../../../utils/constants'

import './PassordChange.scss';

const passwordRenderer = props => {
  const {
    hintText,
    onTouch,
    meta: { touched, error },
    input: { value, onChange }
  } = props;

  return (
    <TextField
      fullWidth
      type="password"
      value={value}
      style={defaultFontSize}
      hintText={hintText}
      errorText={touched && error}
      onBlur={onTouch}
      onChange={onChange} />
  );
};

const validationRules = [
  val => !/[A-Z]/.test(val)
    ? 'Password should contain at least 1 capital letter.'
    : undefined,
  val => !/[0-9]/.test(val)
    ? 'Password should contain at least 1 numeric character.'
    : undefined,
  val => !/[a-z]/.test(val)
    ? 'Password should contain at least 1 small letter.'
    : undefined,
  val => !/[^A-Za-z0-9]/.test(val)
    ? 'Password should contain at least 1 special character.'
    : undefined,
  val => val.length < 8
    ? 'Password should be at least 8 characters.'
    : undefined
];

const PasswordChange = props => {
  const {
    touch,
    handleSubmit,
    valid,
    submitting,
    style
  } = props;

  return (
    <form
      className="password-change"
      style={style}
      onSubmit={handleSubmit}>
      <h1>Change your password</h1>
      <Field
        name="oldPassword"
        onTouch={() => touch('oldPassword')}
        hintText="Old Password"
        component={passwordRenderer} />
      <Field
        name="password"
        onTouch={() => touch('password')}
        hintText="New Password"
        validate={validationRules}
        component={passwordRenderer} />
      <Field
        name="passwordConfirmation"
        onTouch={() =>
          touch('passwordConfirmation')
        }
        hintText="Repeat New Password"
        component={passwordRenderer} />
      <div className="btn-container">
        <RaisedButton
          label="Change Password"
          disabled={submitting || !valid}
          type="submit" />
      </div>
    </form>
  );
};

const validate = values => {
  const errors = {};
  const requiredFields = [
    'oldPassword',
    'password',
    'passwordConfirmation'
  ];

  requiredFields.forEach(field =>
    !values[field] && (errors[field] = 'Required.')
  );

  if (values.password !== values.passwordConfirmation)
    errors.passwordConfirmation =
      `Password confirmation doesn't match.`;

  return errors;
};

const onSubmit = (values, _, { reset }) =>
  apiClient.post('/api/users/me/password/change', {
    password: values.password,
    old_password: values.oldPassword
  })
    .then(() => {
      reset();
      alert('Password has been successfully changed.');
    })
    .catch(({ response }) => {
      const errors = (response.status === 422)
        ? mapKeys(response.data.errors, (_, key) => camelCase(key))
        : { _error: 'Something went wrong.' };

      throw new SubmissionError(errors);
    });

const enhance = reduxForm({
  form: 'PasswordChange',
  validate,
  onSubmit
});

export default enhance(PasswordChange);
