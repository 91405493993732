import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import SVGInline from 'react-svg-inline'
import PropTypes from 'prop-types';
import FlatButton from 'material-ui/FlatButton';
import SharePopUp from '../../components/share/SharePopUp';
import Rating from 'react-rating';
import { getRealTypeOfUser } from '../../helpers/methods';
import PriceListPopup from '../PriceListPopup';
import FreeQuote from './FreeQuote/FreeQuote';
import { setFavorited } from '../../redux/modules/profile';
import { getCurrentUser } from '../../redux/modules/user'

import {default as _, get} from 'lodash';
import notification from 'antd/es/notification'
import defaultAvatar from '../../assets/images/default_avatar.jpg';

import OperationHours from '../common/OperationHours/OperationHours'

import {
  ProfilePhoto,
  ProfileTags,
  ProfileActions,
  MobileProfileActions,
  ProfilePrices,
  ProfileEvents,
  ProfileMap,
  ProfileAbout,
  ProfileCancellationPolicy,
} from '../../components';
import { connect } from 'react-redux';
import LikeButton from '../common/LikeButton/LikeButton';
import { IconButton } from 'material-ui';
import { bookForGig, inviteToGig } from '../../redux/modules/actions';
import { addToFavorites, removeFromFavorites } from '../../redux/modules/favorites';
import BookNowButton from './BookNowButton';
import getSymbolCurrency from '../../helpers/getSymbolCurrency';
import { SkyLightStateless } from 'react-skylight';

const svg_comment = require('!!svg-inline-loader?classPrefix!../../assets/profile/InboxMessage.svg')


@connect(
    ({ common, user, auth, globalConfig }) => ({
        countriesList: common.countriesList,
        currentUser: user.currentUser,
        isAuthorized: auth.isAuthorized,
        isMobile: globalConfig.isMobile
    }), { getCurrentUser }
)
export default class ProfileSideBar extends Component {
  static propTypes = {
    profile: PropTypes.object.isRequired,
    media: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      showMore: true,
      openAboutSection: false,
    }

  }

  componentDidUpdate(prevProps) {
    if (this.props.isAuthorized && this.props.currentUser === null && this.props.profile !== prevProps.profile) {
      this.props.getCurrentUser();
    }
  }

  floorNumber(num) {
    num = +num || 0;

    if (num >= 1000) {
      return `${Math.floor(num / 1000)}k`;
    }

    return num;
  }

  getLocation = (id) => {
    const { countriesList } = this.props;
    let country = countriesList.find((loc) => id === loc.id);

    return country ? country.name : null;
    // return countriesList.find((loc)=> id === loc.id).name
  };

  getAbility = (countries) => {
    return countries.map(country => country.name).join(", ");
  }

  getLocation = () => {
    const { profile } = this.props;

    let location = [
        get(profile, 'location.city', get(profile, 'business_locations.city')),
        get(profile, 'location.country', get(profile, 'business_locations.country'))
    ]
    return location.join(', ');
  }


  viewFile = (url) => {
    window.open(url);
  };

  onButtonToggle = () => {
    this.setState({ openAboutSection: !this.state.openAboutSection })
  }

  render() {
    const { profile, media, match, currentUser, isAuthorized, history, isMobile } = this.props;
    const profile_type = getRealTypeOfUser(profile);
    const current_user_type = getRealTypeOfUser(currentUser)
    const profile_talents = profile.styles ? (
      profile.styles
                .filter(style => style.type !== 'venue_occasion' && style.type !== 'artist_occasion')
                .filter((v, i, a) => a.findIndex(val => val.name == v.name) === i)
                .map(style => style.name)
    ) : []
    const profile_occassions = profile.styles ? (
      profile.styles
                .filter(style => style.type === 'venue_occasion' || style.type === 'artist_occasion')
                .filter((v, i, a) => a.findIndex(val => val.name == v.name) === i)
                .map(style => style.name)
    ) : []
    const profile_styles = profile.styles ? (
        profile.styles
                  .filter((v, i, a) => a.findIndex(val => val.name == v.name) === i)
                  .map(style => style.name)
      ) : []
    const isKindOfArtist = profile_type === 'artist' || profile_type === 'band' || profile_type === 'group'

    const extendedView = !isMobile || this.state.showMore

    const isAboutSectionOpen = !isMobile || (isMobile && this.state.openAboutSection);

    const currentUrl = window.location.href;

    return (
      <section className='profile'>
        <div className='profile__bg'>

          { extendedView &&
          <ProfileIcons
            profile={profile}
            current_user_type={current_user_type}
            currentUser={currentUser}
            history={history}
            isMobile={isMobile}
          />
          }

          <ProfilePhoto profile={profile} match={match} isMobile={isMobile}/>
          <SharePopUp urlToShare={currentUrl} colorShareIcon={'#000000'}/>
          <ProfileMainDescription profile={profile} profile_talents={profile_talents} isMobile={isMobile} extendedView={extendedView} />
          {/* { extendedView === false &&
            <div className="toggle-button" onClick={() => this.setState({ showMore: true })}>
              <span>Info</span>
            </div>
          } */}

          {/* { isMobile &&
            <div
              className="toggle-button"
              onClick={() => this.setState({ showMore: !this.state.showMore })}>
              <strong>{this.state.showMore ? 'less info...' : 'more info...'}</strong>
            </div>
          } */}

          {
            (isKindOfArtist) &&
            <div className="d-flex justify-content-between location">
              <div>
                <span><i className="fa fa-map-marker" /></span>
                {this.getLocation()}
              </div>
              {profile.countries && profile.countries.length
                  ? <div className='profile__travel'>
                    <span className='circle'><i className='fa fa-plane' aria-hidden='true' /></span>
                    {this.getAbility(profile.countries)}
                  </div>
                  : null
              }
            </div>
          }
          {
            (isKindOfArtist) &&
            <ProfilePrices profile={profile} isCompare={false} />
          }

          {
            isMobile &&
            <MobileProfileActions
              clappers_count={profile.clappers_count}
              followers_count={profile.followers_count}
              history={history}
              currentUser={currentUser}
              isKindOfArtist={isKindOfArtist}
              onButtonToggle={this.onButtonToggle}
              openAboutSection={this.state.openAboutSection}
            />
          }

          { !isMobile && extendedView && <ProfileActions
              clappers_count={profile.clappers_count}
              followers_count={profile.followers_count}
              history={history}
              currentUser={currentUser}
              isKindOfArtist={isKindOfArtist}
            />
          }
          { extendedView && <ProfileSideBarrAdditionalActions profile={profile} isMobile={isMobile}/> }
          { extendedView && profile.success_gigs > 0 &&
            <div className="SuccessfulGigs d-flex">
              <i className="fa fa-check" /><b>{profile.success_gigs}</b><text>{profile.success_gigs > 1 ? 'Successful gigs' : 'Successful gig'}</text>
              <div className="underline" />
            </div>
          }
          {
            (profile_type !== 'client' && profile_type !== 'company') && <FreeQuote currentUser={currentUser} history={history} profileId={profile.id} />
          }

          {
            isMobile &&
            <ViewInfo
              profile_type={profile_type}
              profile={profile}
              is_favorite={get(profile, 'is_favorite', false)}
              extendedView={extendedView}
            />
          }

          {
            (profile_type === 'band' || profile_type === 'group') && extendedView && <BandMembers profile={profile} type={profile_type}/>
          }

          {
            (profile_type === 'venue') && extendedView && <OperationHours value={profile.operation_hours || {}} />
          }

          {
            (profile_type === 'venue') && extendedView && <ProfileMap profile={profile}/>
          }

          {
            (profile_type === 'venue') && extendedView && <BusinessInfo profile={profile}/>
          }

          { isAboutSectionOpen && extendedView && <ProfileAbout currentUser={currentUser} profile={profile}/> }

          { isAboutSectionOpen && extendedView && <Occassions profile_occassions={profile_occassions} /> }

          { isAboutSectionOpen && extendedView && <AdditionalStylesSection profile_talents={profile_talents} /> }

          { isAboutSectionOpen && extendedView && <ProfileCancellationPolicy currentUser={currentUser} profile={profile}/> }

          { isAboutSectionOpen && extendedView && <ProfileEvents id={profile.id} /> }

          {
            !isMobile &&
            <ViewInfo
              profile_type={profile_type}
              profile={profile}
              is_favorite={get(profile, 'is_favorite', false)}
              extendedView={extendedView}
            />
          }
        </div>
      </section>
    );
  }
}

class ProfileIcons extends Component {
  render() {
    const { profile, current_user_type, isMobile, currentUser, history } = this.props;

    return (
      <div className={'profile__icons' + (isMobile ? ' mobile' : '') }>
        { profile.id &&
            <LikeButton
              targetId={profile.id}
              defaultValue={profile.is_favorite}
              iconProps={{ width: '25px' }}
            />
        }
        { current_user_type !== 'client' && current_user_type !== 'company' &&
            <IconButton onClick={() => !currentUser || !currentUser.id ? history.push('/login') : console.log('chat bubbles!')}>
              <SVGInline svg={svg_comment} width="25px" />
            </IconButton>
        }
      </div>
    )
  }
}

class Occassions extends Component {
  render() {
    const { profile_occassions } = this.props

    return (
      (profile_occassions && profile_occassions.length) ? (
        <div className='occassions'>
          <p>Occasions</p>
          <div className='profile__occassions'>{profile_occassions.map((name, index) => <span key={index} className='occassion'>{name}</span>)}</div>
        </div>
      ) : <div></div>
    )
  }
}

class ProfileMainDescription extends Component {
  getRating() {
    const { average_rating } = this.props.profile;

    return isNaN(average_rating) ?
      0 :
      parseFloat(average_rating).toFixed(2)
  }

  render() {
    const { profile, profile_talents, isMobile, extendedView} = this.props;
    const top_profile_talents = profile_talents.slice(0, 5);

    const profile_type = getRealTypeOfUser(profile);
    const categorieName = (() => {
      if (profile.categories && profile.categories.length) {
        //category type for artists and groups
        let userCategoryType = 'talent';
        if (profile_type === 'venue') {
          userCategoryType = 'venue_type';
        }
        if (profile_type === 'band') {
          userCategoryType = 'music_genre';
        }

        const category = profile.categories.find(category => {
          return category.type === userCategoryType;
        });

        return category ? category.name : '';
      }
    })();

    const rating = this.getRating()


    switch (profile_type) {
      case 'group' :
      case 'band' : {
        return (
          <div>
            <div className={'profile__rating' + (isMobile ? ' mobile' : '')}>
              <Rating
                empty='fa fa-star-o fa-fw'
                initialRate={rating}
                readonly
                full='fa fa-star fa-fw'/>
              <span className='ml-2 overall'>
                {rating > 0 ? rating : ''}
              </span>
            </div>

            <div className={'profile__data' + (isMobile ? ' mobile' : '')}>
              <h1 className='name'>{`${profile.name}`}</h1>
              <h2 className='categorie-name'>{categorieName}</h2>
            </div>

            {extendedView &&
              <div className={'profile__talents-tags' + (isMobile ? ' mobile' : '')}>
                <div className='profile__talents'>{top_profile_talents.map((name, index) => <span key={index} className='talent'>{name}</span>)}</div>
                <ProfileTags profile={profile}/>
              </div>
            }
          </div>
        );
      }
      case 'artist' : {
        return (
          <div>
            <div className={'profile__rating' + (isMobile ? ' mobile' : '')}>
              <Rating
                empty='fa fa-star-o fa-fw'
                initialRate={rating}
                readonly
                full='fa fa-star fa-fw'/>
              <span className='ml-2 overall'>
                {rating > 0 ? rating : ''}
              </span>
            </div>

            <div className={'profile__data' + (isMobile ? ' mobile' : '')}>
              <h1 className='name'>{profile.name}</h1>
              <h2 className='categorie-name'>{categorieName}</h2>
            </div>

            {extendedView &&
              <div className={'profile__talents-tags' + (isMobile ? ' mobile' : '')}>
                <div className='profile__talents'>{top_profile_talents.map((name, index) => <span key={index} className='talent'>{name}</span>)}</div>
                <ProfileTags profile={profile}/>
              </div>
            }
          </div>
        );
      }
      case 'venue' : {
        return (
          <div>
            <div className={'profile__rating' + (isMobile ? ' mobile' : '')}>
              <Rating
                empty='fa fa-star-o fa-fw'
                initialRate={rating}
                readonly
                full='fa fa-star fa-fw'/>
              <span className='ml-2 overall'>
                {rating > 0 ? rating : ''}
              </span>
            </div>

            <div className={'profile__data' + (isMobile ? ' mobile' : '')}>
              <h1 className='name'>{`${profile.name}`}</h1>
              <h2 className='categorie-name'>{categorieName}</h2>
            </div>

            {extendedView &&
              <div className={'profile__talents-tags' + (isMobile ? ' mobile' : '')}>
                <div className='profile__talents d-flex flex-wrap '>{top_profile_talents.map((name, index) => <span key={index} className='talent'>{name}</span>)}</div>
                <h2 className='profile__type'>
                  <i className="fa fa-map-marker" />
                  {profile.location
                    ? `${profile.location.city
                      ? profile.location.city : ''}, ${profile.location.country
                      ? profile.location.country : ''}` : null}
                </h2>
                <ProfileTags profile={profile}/>
              </div>
            }
          </div>
        );
      }
      case 'client':
      case 'fan': {
        return (
          <div>
            <div className={'profile__rating' + (isMobile ? ' mobile' : '')}>
              <Rating
                empty='fa fa-star-o fa-fw'
                initialRate={rating}
                readonly
                full='fa fa-star fa-fw'/>
              <span className='ml-2 overall'>
                {rating > 0 ? rating : ''}
              </span>
            </div>

            <div className={'profile__data' + (isMobile ? ' mobile' : '')}>
              <h1 className='name'>
                {profile.first_name} {profile.last_name}</h1>
            </div>

            {extendedView &&
              <div className={'profile__talents-tags' + (isMobile ? ' mobile' : '')}>
                <div className='profile__talents'>{top_profile_talents.map((name, index) => <span key={index} className='talent'>{name}</span>)}</div>
                <ProfileTags profile={profile}/>
              </div>
            }
          </div>
        );
      }
      case 'company': {
        return (
          <div>
            <div className={'profile__rating' + (isMobile ? ' mobile' : '')}>
              <Rating
                empty='fa fa-star-o fa-fw'
                initialRate={rating}
                readonly
                full='fa fa-star fa-fw'/>
              <span className='ml-2 overall'>
                {rating > 0 ? rating : ''}
              </span>
            </div>

            <div className={'profile__data' + (isMobile ? ' mobile' : '')}>
              <h1 className='name'>{`${profile.name}`}</h1>
            </div>

            {extendedView &&
              <div className={'profile__talents-tags' + (isMobile ? ' mobile' : '')}>
                <div className='profile__talents'>{top_profile_talents.map((name, index) => <span key={index} className='talent'>{name}</span>)}</div>
                <ProfileTags profile={profile}/>
              </div>
            }
          </div>
        );
      }
      default : {
        return (null);
      }
    }
  }
}

class AdditionalStylesSection extends Component {

  render() {
    const { profile_talents } = this.props;
    const additional_profile_talents = profile_talents.slice(5, profile_talents.length);

    return (
      <div className={"occassions"}>
        <p>Additional styles</p>
        <div className='profile__talents'>{additional_profile_talents.map((name, index) => <span key={index} className='talent'>{name}</span>)}</div>
      </div>
    );
  }
}

class ProfileSideBarrAdditionalActions extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isPopupOpened: false,
      isMenuOpened: false,
    };
  }

  switchPopup = () => this.setState(({ isPopupOpened }) => ({
    isPopupOpened: !isPopupOpened
  }));

  viewFile = (url) => window.open(url);

  render() {
    const { profile, isMobile } = this.props;
    const profile_type = getRealTypeOfUser(profile);
    const fee_primary = profile.fee_primary || {};

    const mobileDialogStyle = {
      width: '90vw',
      height: '80vh',
      marginTop: 'calc(80vh / -2)',
      marginLeft: '-45%',
      borderRadius: '16px'
    }

    return (profile_type === 'venue') && (
      <div>
        <PriceListPopup
          isOpened={this.state.isPopupOpened}
          onClose={this.switchPopup}
          priceList={profile.services}
          name={profile.name}
          dialogStyles={isMobile ? mobileDialogStyle : {}}
        />
        <SkyLightStateless
          closeOnEsc
          hideOnOverlayClicked
          dialogStyles={isMobile ? mobileDialogStyle : {
            width: '60%',
            height: '80vh',
            marginTop: 'calc(80vh / -2)',
            marginLeft: '-30%',
            borderRadius: '16px'
          }}
          isVisible={this.state.isMenuOpened}
          onCloseClicked={() => this.setState({ isMenuOpened: false })}
        >
          <iframe src={profile.menu_list} style={{ width: '100%', height: 'calc(100% - 1.5rem)', marginTop: '1.5rem' }}></iframe>
        </SkyLightStateless>
        <div className='actions-wrapper'>
          <button
            className='venue-right-button'
            onClick={() => this.setState({ isMenuOpened: true })}>
            <strong>Menu</strong>
          </button>
          <div className="venue-center-fee">
            <text>
              <b>{fee_primary.value || '-'}</b>{getSymbolCurrency(fee_primary.currency) || ''}
            </text>
            <br /><span style={{fontSize: '0.76rem' }}>per {fee_primary.period || 'period'}</span>
          </div>
          <button
            className='venue-left-button'
            onClick={this.switchPopup}>
            <strong>Price List</strong>
          </button>
        </div>
      </div>
    );
  }
}

@connect(({ auth, user }) => ({
  isAuthorized: auth.isAuthorized,
  currentUser: user.currentUser
}),
{
	addToFavorites,
    removeFromFavorites,
    bookForGig,
    inviteToGig,
})
class ViewInfo extends Component {
  constructor(props) {
    super(props);

    this.state = {
      is_favorite: props.is_favorite || false
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if(nextProps.is_favorite !== this.state.is_favorite) {
      this.setState({ is_favorite: nextProps.is_favorite })
    }
  }

  handleChangeFavourities = (profileId) => {
    const { addToFavorites, removeFromFavorites } = this.props;
    const nextValue = !this.state.is_favorite;

    const action = nextValue ? addToFavorites : removeFromFavorites;

    action(profileId)
      .then(res =>
        this.setState({ is_favorite: nextValue })
      )
  }

  render() {
    const { profile, profile_type, isAuthorized, extendedView, bookForGig, inviteToGig } = this.props;


    return (
      <div className='viewed'>
        {profile_type !== 'client' && profile_type !== 'company' && (
          <div className='viewed__header'>
            <i className='fa fa-address-card' aria-hidden='true'>
            </i>
            <p className='viewed__copy'>
              <b>People are watching Michelle.<br/>
              It has been viewed by more than</b><br/>
              9,200 people this week.
            </p>
          </div>
        )}
        {profile_type !== 'client' && profile_type !== 'company' &&
          <BookNowButton
            label="Invite to Apply"
            insideModalButtonLabel="Proceed to Invite"
            style={{
              cursor: 'pointer',
              width: `${90}%`,
              marginBottom: `${1}rem`,
              borderRadius: '8px',
              color: 'black',
              backgroundColor: 'white',
              border: '2px solid rgb(255, 210, 16)',
            }}
            onSuccess={gigId => {
              inviteToGig(gigId, profile.id)
              .then(res => {
                notification.success({
                    message: 'Successs!',
                    description: "Invited",
                })
              })
              .catch(err => {
                if(err.status === 500 || !_.get(err, 'response.data.message', false)) {
                  notification.error({
                    message: 'Error!',
                    description: "Please, try again later.",
                  })
                }
                else {
                  notification.info({
                    message: 'Info',
                    description: err.response.data.message,
                  })
                }
              })
            }}
          />
        }
        {profile_type !== 'client' && profile_type !== 'company' &&
          <BookNowButton
            label="Book now"
            style={{
              cursor: 'pointer',
              width: `${90}%`,
              marginBottom: `${1}rem`,
              borderRadius: '8px',
            }}
            onSuccess={gigId => {
              bookForGig(gigId, profile.id)
              .then(res => {
                notification.success({
                    message: 'Successs!',
                    description: "Booked",
                })
              })
              .catch(err => {
                if(err.status === 500 || !_.get(err, 'response.data.message', false)) {
                  notification.error({
                    message: 'Error!',
                    description: "Please, try again later.",
                  })
                }
                else {
                  notification.info({
                    message: 'Info',
                    description: err.response.data.message,
                  })
                }
              })
            }}
          />
        }
        {/*{*/}
        {/*  isAuthorized && extendedView && profile.profile_type !== 'client' && profile_type !== 'company' && (*/}
        {/*    <div*/}
        {/*      className="g-Button"*/}
        {/*      style={{*/}
        {/*        cursor: 'pointer',*/}
        {/*        width: `${90}%`,*/}
        {/*        margin: `0 auto ${1}rem`,*/}
        {/*        backgroundColor: '#fff',*/}
        {/*        color: 'black',*/}
        {/*        border: '2px solid rgb(255, 210, 16)',*/}
        {/*        borderRadius: '8px',*/}
        {/*      }}*/}
        {/*      onClick={() => this.handleChangeFavourities(profile.id)}*/}
        {/*    >*/}
        {/*      {this.state.is_favorite*/}
        {/*        ? 'Remove from Favorites'*/}
        {/*        : 'Add to Favkorites'*/}
        {/*      }*/}
        {/*    </div>*/}
        {/*  )*/}
        {/*}*/}
      </div>
    );
  }
}

export class BusinessInfo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isReadMore: false
    };
  }

  switchReadMore = () => this.setState({ isReadMore: !this.state.isReadMore });

  render() {
    const profile = this.props.profile;

    let capacity = 0;
    if (profile.capacity) {
      if (profile.capacity.seated)
        capacity += +profile.capacity.seated;
      if (profile.capacity.standing)
        capacity += +profile.capacity.standing;
    }

    return (
      <section className='business-info'>
        <header className='business-info__header'>
          <h1 className='business-info__title'>Business Info</h1>
        </header>
        <div className='business-info__content'>
          {
            profile.capacity ?
              <div className='item'>
                <span>Capacity</span>
                <strong>{capacity}</strong>
              </div> : ''
          }
          {
            profile.accessible_by ?
              profile.accessible_by.map((val, index) =>
                <div
                  className='item'
                  key={'accessible_by' + index}>
                  <span>{val}</span>
                  <span><strong>Yes</strong></span>
                </div>) : ''
          }
          {
            !this.state.isReadMore
              ?
              <span>
                <FlatButton
                  style={
                    {
                      lineHeight: 'auto',
                      height: 'auto',
                      padding: '0 2px',
                      margin: '0 2px',
                      minWidth: '0'
                    }
                  }
                  labelStyle={{
                    color: 'red',
                    fontWeight: '500',
                    fontSize: '0.75rem',
                    textTransform: 'normal',
                    padding: '0'
                  }}
                  label={'Read more'}
                  onClick={this.switchReadMore}/>
              </span>
              :
              <div>
                {
                  profile.equipment ?
                    profile.equipment.map((val, index) =>
                      <div
                        className='item'
                        key={'equipment' + index}>
                        <span>{val}</span>
                        <span><strong>Yes</strong></span>
                      </div>) : ''
                }
                {
                  profile.parking_types ?
                    profile.parking_types.map((val, index) =>
                      <div
                        className='item'
                        key={'parking_types' + index}>
                        <span>{val}</span>
                        <span><strong>Yes</strong></span>
                      </div>) : ''
                }
                {
                  profile.business_services ?
                    profile.business_services.map((val, index) =>
                      <div
                        className='item'
                        key={'business_services' + index}>
                        <span>{val}</span><span>
                          <strong>Yes</strong></span>
                      </div>) : ''
                }
                {
                  profile.facilities ?
                    profile.facilities.map((val, index) =>
                      <div
                        className='item'
                        key={'facilities' + index}>
                        <span>{val}</span>
                        <span><strong>Yes</strong></span>
                      </div>) : ''
                }
                {
                  profile.features_and_guest_services ?
                    profile.features_and_guest_services.map((val, index) =>
                      <div className='item' key={'features_and_guest_services' + index}>
                        <span>{val}</span>
                        <span><strong>Yes</strong></span>
                      </div>) : ''
                }
                <span>
                  <FlatButton
                    style={{
                      lineHeight: 'auto',
                      height: 'auto',
                      padding: '0 2px',
                      margin: '0 2px',
                      minWidth: '0'
                    }}
                    labelStyle={{
                      color: 'red',
                      fontWeight: '500',
                      fontSize: '0.75rem',
                      textTransform: 'normal',
                      padding: '0'
                    }}
                    label={'Hide'}
                    onClick={this.switchReadMore}/>
                </span>
              </div>
          }
        </div>
      </section>
    );
  }
}

/**
 * todo imo thers no reason this compoentn take whole profile as prop
 */
export class BandMembers extends Component {

  getMemberDescription(member) {
    const { type } = this.props;
    const location = member.location ? JSON.parse(member.location) : undefined;
    const fieldToShow = type == 'band' ? 'instrument' : 'talent';

    let description = (
      <div>
        {`${member[fieldToShow] || ''}`} { location ? ', ' + location.city + ', ' + location.country : null }
      </div>
    );
    return description;
  }

  buildUsernameForUrl = (name) => {
    return name.replace(/\s/g, '.');
  };

  render() {
    const { type } = this.props;

    // members were usually in profile.members but getBulkProfiles use members_details property
    let members;
    if (this.props.profile && this.props.profile.members){
      members = this.props.profile.members
    } else if (this.props.profile && this.props.profile.members_details) {
      members = this.props.profile.members_details
    } else {
      members = []
    }


    return (
      <section className='band-members'>
        <header className='band-members__header'>
          <h1 className='band-members__title'>{type} Members</h1>
        </header>
        <div className='band-members__content'>
          {
            members.filter(member => member.is_verified).map((member) => {
              // const username = this.buildUsernameForUrl(_.get(member, 'first_name'), _.get(member, 'last_name'));
                let username = '';
                if (_.get(member, 'name')) {
                  username = this.buildUsernameForUrl(_.get(member, 'name'));
                }

                return (
                <div className='member-wrapper'>
                  <Link
                    to={`/profile/${username}`}
                    target="_blank"
                    style={{ display: 'flex', flexGrow: 1 }}>

                    <div className='member-avatar-wrapper'>
                      <div className='member-avatar'>
                        <img src={ member.avatar ? JSON.parse(member.avatar).url : defaultAvatar} />
                      </div>
                    </div>

                    <div>
                      <div className='member-name'>{`${member.first_name} ${member.last_name}`}</div>
                      <div className='member-description'>
                        { this.getMemberDescription(member) }
                      </div>
                    </div>
                  </Link>

                  <LikeButton targetId={member.user_id} defaultValue={member.is_favorite} />
                </div>)
            }
            )
          }
        </div>
      </section>
    );
  }
}
