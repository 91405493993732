import React, { Component } from 'react';
import PropTypes from 'prop-types';

export default class PictureBlock extends Component {
  static propTypes = {
    media: PropTypes.object.isRequired,
  };

  constructor (props) {
    super(props);
  }

  //TODO
  render () {
    const { media } = this.props;
    
    return (
      <div className="picture-block" style={{height: '100%'}}>
        <div style={{backgroundImage: `url(${media.content_url})`, backgroundSize: 'cover', height: '100%'}}></div>
        {/*<img src={media.content_url}/>*/}
      </div>
    );
  }
}
