import React, { Component } from 'react'
import { Field, reduxForm } from 'redux-form'
import TextField from 'material-ui/TextField'
import SelectField from 'material-ui/SelectField'
import MenuItem from 'material-ui/MenuItem'
import { connect } from 'react-redux'
import { adjustQuestionsAnswersResponse, getValues } from '../../../helpers/methods'
import validate from '../../../helpers/validators/venue/BookingInfo'
import '../../../styles/components/venue/WizardBookingInfo.scss'
import { currency, period } from '../../../utils/constants'
import { Tags, Experience } from '../CommonRegistrationComponent'
import { InputStyles, PlaceholderStyles, FileInput } from '../CommonComponent'
import { PriceListNew } from '../PriceList/PriceListNew'
import { Asterisk } from '../../common/Asterisk/Asterisk'
import { registrationConfig } from '../../../config'
import SVGInline from 'react-svg-inline'
import { WizardBusinessInfo } from './WizardBusinessInfo'

class WizardBookingInfo extends Component {
	render() {
		const { handleSubmit } = this.props

		return (
			<form className="booking-info-form" onSubmit={handleSubmit}>
        <div className="booking-info-form__wrapper">
          <Field name="questions_answers" component={WizardBusinessInfo} />
        </div>
				<div className="booking-info-form__wrapper">
					<Field name="capacity" component={Capacity} />
				</div>
				<div className="booking-info-form__wrapper">
					<Field name="booking_method" component={BookingMethod} />
				</div>
				<div className="booking-info-form__wrapper">
					<Field name="experience" component={Experience} />
				</div>
				<div className="booking-info-form__wrapper">
					<div className="booking-info-form__item-header">
						<span className="fa-stack info-popup-window" data-title={registrationConfig.popupText.venueServiceFees}>
							<i className="fa fa-circle-thin fa-stack-2x" />
							<i className="fa fa-info fa-stack-1x" />
						</span>
						<h4 className="booking-info-form__title">Service fees</h4>
					</div>
					<Field name="fee_primary" feeLabel={'Primary'} component={Fee} required />
					<Field name="fee_secondary" feeLabel={'Other'} component={Fee} />
				</div>
				<div className="booking-info-form__wrapper">
					<div className="booking-info-form__item">
						<div className="booking-info-form__item-header">
							<span className="fa-stack info-popup-window" data-title={registrationConfig.popupText.venueMenuList}>
								<i className="fa fa-circle-thin fa-stack-2x" />
								<i className="fa fa-info fa-stack-1x" />
							</span>
							<h4 className="booking-info-form__title">Menu List</h4>
						</div>
						<Field
							style={{ display: 'none' }}
							name="menu_list"
							type="file"
							label="Upload Menu"
							showValidationError={this.props.showValidationError}
							component={FileInput}
						/>
					</div>
				</div>
				<div className="booking-info-form__wrapper">
					<Field name="services" component={PriceListNew} />
				</div>
				<div className="booking-info-form__wrapper">
					<Field name="tags" component={Tags} />
				</div>
			</form>
		)
	}
}

WizardBookingInfo = reduxForm({
	form: 'BookingInfo',
	destroyOnUnmount: false,
	forceUnregisterOnUnmount: false,
	enableReinitialize: true,
	keepDirtyOnReinitialize: true,
	validate,
})(WizardBookingInfo)

export default connect(({ user, form }) => ({
	currentUser: user.currentUser,
	initialValues: getValues(
		form.BookingInfo,
		user.currentUser && user.currentUser.profile
			? {
					booking_method: user.currentUser.profile.booking_method,
					experience: user.currentUser.profile.experience,
					capacity: user.currentUser.profile.capacity,
					fee_primary: user.currentUser.profile.fee_primary,
					fee_secondary: user.currentUser.profile.fee_secondary,
					business_locations: user.currentUser.profile.business_locations,
					services:
						user.currentUser.services && user.currentUser.services.length ? user.currentUser.services : undefined,
					tags:
						user.currentUser.tags && user.currentUser.tags.length
							? {
									basic: user.currentUser.tags
										.filter(tag => tag.type === 'basic')
										.map(tag => ({ content: tag.content })),
							  }
							: { basic: [] },
          brief_introduction: user.currentUser.profile.brief_introduction,
          about: user.currentUser.profile.about,
          operation_hours: user.currentUser.profile.operation_days,
          type_of_food: user.currentUser.profile.type_of_food,
          attributes: user.currentUser.profile.attributes,
          serves: user.currentUser.profile.serves,
          drinks: user.currentUser.profile.drinks,
          payment_options: user.currentUser.profile.payment_options,
          accessible_by: user.currentUser.profile.accessible_by,
          smoking: user.currentUser.profile.smoking,
          dress_code: user.currentUser.profile.dress_code,
          equipment: user.currentUser.profile.equipment,
          parking_types: user.currentUser.profile.parking_types,
          business_services: user.currentUser.profile.business_services,
          facilities: user.currentUser.profile.facilities,
          features_and_guest_services: user.currentUser.profile.features_and_guest_services,
          recreational_services: user.currentUser.profile.recreational_services,
          setting: user.currentUser.profile.setting,
          questions_answers: adjustQuestionsAnswersResponse(user.currentUser.answers) || []
			  }
			: {}
	),
}))(WizardBookingInfo)

class BookingMethod extends Component {
	onTypeSelect(type) {
		const {
			input: { onChange },
		} = this.props
		onChange(type)
	}

	//string
	render() {
		const {
			input: { value },
		} = this.props

		return (
			<div className="booking-info-form__item">
				<div className="booking-info-form__item-header">
					<span className="fa-stack info-popup-window" data-title={registrationConfig.popupText.venueBookingMethod}>
						<i className="fa fa-circle-thin fa-stack-2x" />
						<i className="fa fa-info fa-stack-1x" />
					</span>
					<h4 className="booking-info-form__title">
						Booking method <Asterisk className="text-danger ml-1" />
					</h4>
				</div>
				<div className="radio-group-wrapper">
					<label className="radio-group radio-inline">
						<input
							onClick={() => this.onTypeSelect('normal')}
							{...{ checked: value === 'normal' }}
							name="booking"
							type="radio"
							className="d-none"
						/>
						<span>Normal Booking</span>
					</label>
					<label className="radio-group radio-inline">
						<input
							onClick={() => this.onTypeSelect('instant')}
							{...{ checked: value === 'instant' }}
							name="booking"
							type="radio"
							className="d-none"
						/>
						<span>Instant Booking</span>
					</label>
				</div>
			</div>
		)
	}
}

class Fee extends Component {
	constructor(props) {
		super(props)
	}

	onChangeValue = (evt, newVal, field = 'value') => {
		const {
			input: { value, onChange },
		} = this.props

		onChange({
			...value,
			[field]: newVal,
		})
	}

	render() {
		const {
			feeLabel,
			input: { value },
			required,
		} = this.props

		return (
			<div className="booking-info-form__item">
				<h4 className="booking-info-form__subtitle">
					{feeLabel} fee structure
					{required ? <Asterisk className='Asterisk ml-1' /> : null}
				</h4>

				<div className="booking-info-form__price-wrapper">
					<div className="number-input price-count">
						<TextField
							value={value.value}
							onChange={(evt, val) => this.onChangeValue(evt, val)}
							inputStyle={InputStyles}
							hintStyle={PlaceholderStyles}
							hintText={feeLabel}
							fullWidth={true}
						/>
					</div>
					<div className="price-currency">
						<SelectField
							hintStyle={PlaceholderStyles}
							labelStyle={InputStyles}
							fullWidth={true}
							value={value.currency}
							onChange={(evt, index, val) => this.onChangeValue(evt, val, 'currency')}
						>
							{currency.map((cur, index) => (
								<MenuItem key={index} value={cur.value} primaryText={cur.label} />
							))}
						</SelectField>
					</div>
					<div className="price-period">
						<SelectField
							hintStyle={PlaceholderStyles}
							labelStyle={InputStyles}
							fullWidth={true}
							value={value.period}
							onChange={(evt, index, val) => this.onChangeValue(evt, val, 'period')}
						>
							{period.venue.map((per, index) => (
								<MenuItem key={index} value={per.value} primaryText={per.label} />
							))}
						</SelectField>
					</div>
				</div>
			</div>
		)
	}
}

class Capacity extends Component {
	constructor(props) {
		super(props)
	}

	onChangeValue = (newVal, field) => {
		const {
			input: { value, onChange },
		} = this.props

		onChange({
			...value,
			[field]: newVal,
		})
	}

	render() {
		const {
			input: { value },
		} = this.props

		const svg_m2 = require('!!svg-inline-loader?classPrefix!../../../assets/icons/m2.svg')
		const svg_person = require('!!svg-inline-loader?classPrefix!../../../assets/icons/person.svg')
		const svg_chair = require('!!svg-inline-loader?classPrefix!../../../assets/icons/chair.svg')

    const number = value => value && isNaN(Number(value)) ? 'Must be a number' : undefined;

    return (
			<div className="booking-info-form__item">
				<div className="booking-info-form__item-header">
					<h4 className="booking-info-form__title">
						Capacity <Asterisk />
					</h4>
				</div>
				<div>
					<SVGInline svg={svg_m2} fill="#000" width={'1.5rem'} height={'1.5rem'} />
					<div className="d-inline-block ml-2 capacity-label">
						Space
					</div>
					<div className="d-inline-block number-input capacity-input">
						<TextField
							value={value.space}
              validate
							onChange={(_, val) => this.onChangeValue(val, 'space')}
							inputStyle={InputStyles}
							hintStyle={PlaceholderStyles}
							hintText=''
							fullWidth={true}
						/>
					</div>
					<span>sq/m2</span>
				</div>
				<div>
					<SVGInline svg={svg_person} fill="#000" width={'1.5rem'} height={'1.5rem'} />
					<div className="d-inline-block ml-2 capacity-label">
						Standing
					</div>
					<div className="d-inline-block number-input capacity-input">
						<TextField
							value={value.standing}
							onChange={(_, val) => this.onChangeValue(val, 'standing')}
							inputStyle={InputStyles}
							hintStyle={PlaceholderStyles}
							hintText=''
							fullWidth={true}
						/>
					</div>
					<span>ppl</span>
				</div>
				<div>
					<SVGInline svg={svg_chair} fill="#000" width={'1.5rem'} height={'1.5rem'} />
					<div className="d-inline-block ml-2 capacity-label">
						Seated
					</div>
					<div className="d-inline-block number-input capacity-input">
						<TextField
							value={value.seated}
							onChange={(_, val) => this.onChangeValue(val, 'seated')}
							inputStyle={InputStyles}
							hintStyle={PlaceholderStyles}
							hintText=''
							fullWidth={true}
						/>
					</div>
					<span>ppl</span>
				</div>
			</div>
		)
	}
}
