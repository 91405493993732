import React, { Component } from 'react';
import SVGInline from 'react-svg-inline';

import {
  WhatsAppShare,
  TwitterShare,
  PinterestShare,
  LinkedinShare,
  EmailShare,
  FacebookMessengerShare,
  FacebookShare,
} from '../../../../components/share/config';

import './SharedPopUpSocialNetworks.scss';

const svg_mail = require('!!svg-inline-loader?classPrefix!../../../../assets/share-icons/mail.svg');
const svg_message = require('!!svg-inline-loader?classPrefix!../../../../assets/share-icons/message.svg');
const svg_facebook = require('!!svg-inline-loader?classPrefix!../../../../assets/share-icons/facebook.svg');
const svg_linkedin = require('!!svg-inline-loader?classPrefix!../../../../assets/share-icons/linkedin.svg');
const svg_pinterest = require('!!svg-inline-loader?classPrefix!../../../../assets/share-icons/pinterest.svg');
const svg_twitter = require('!!svg-inline-loader?classPrefix!../../../../assets/share-icons/twitter.svg');
const svg_messanger = require('!!svg-inline-loader?classPrefix!../../../../assets/share-icons/messanger.svg');
const svg_whatsapp = require('!!svg-inline-loader?classPrefix!../../../../assets/share-icons/whatsapp.svg');
const svg_instagram = require('!!svg-inline-loader?classPrefix!../../../../assets/instagram.svg');

const LIST_SOCIAL_NETWORKS = [
  {
    type: 'FA',
    svg: svg_facebook,
  },
  {
    type: 'INS',
    svg: svg_instagram,
  },
  {
    type: 'LIN',
    svg: svg_linkedin,
  },
  {
    type: 'PI',
    svg: svg_pinterest,
  },
  {
    type: 'TW',
    svg: svg_twitter,
  },
  {
    type: 'FM',
    svg: svg_messanger,
  },
  {
    type: 'WA',
    svg: svg_whatsapp,
  },
];

export default class SharedPopUpSocialNetworks extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedTypeOfShare: null,
      isOpenedEmailInput: false,
      email: '',
    };
  }

  onShare = () => {
    const { selectedTypeOfShare, email } = this.state;
    const { urlToShare } = this.props;
    switch (selectedTypeOfShare) {
      case 'MAIL' : {
        EmailShare(urlToShare, email);
        break;
      }
      case 'FA' : {
        FacebookShare(urlToShare);
        break;
      }
      case 'INS' : {
        FacebookShare(urlToShare);
        break;
      }
      case 'LIN' : {
        LinkedinShare(urlToShare);
        break;
      }
      case 'PI' : {
        PinterestShare(urlToShare);
        break;
      }
      case 'TW' : {
        TwitterShare(urlToShare);
        break;
      }
      case 'FM' : {
        FacebookMessengerShare(urlToShare);
      }
      case 'WA' : {
        WhatsAppShare(urlToShare);
        break;
      }
    }
  };

  onSelectTypeOfShare = type => {
    this.setState({
      selectedTypeOfShare: type
    });
  };

  switchInputEmail = () => {
    this.onSelectTypeOfShare('MAIL');
    this.setState({
      isOpenedEmailInput: !this.state.isOpenedEmailInput,
    });
  };

  updateEmail = evt => {
    this.setState({
      email: evt.target.value
    });
  };

  render() {

    const { share } = this.props;
    const { selectedTypeOfShare, isOpenedEmailInput, email } = this.state;

    return (
      <div className="SharedPopUpSocialNetworks">
        <div className="SharedPopUpSocialNetworks__share">Share</div>
        <div className="SharedPopUpSocialNetworks__main">
          <div className="SharedPopUpSocialNetworks__main_choose-platform">
            <p>Choose the platform you want to share with</p>
          </div>
          <div className="d-flex justify-content-around SharedPopUpSocialNetworks__main_mail-message">
            <SVGInline
              onClick={this.switchInputEmail}
              width="1.5rem"
              svg={svg_mail}
              fill={selectedTypeOfShare === 'MAIL' ? '#ffd210' : '#fff'} />
            {/* <SVGInline
              onClick={() => this.onSelectTypeOfShare('MSG')}
              width="1.5rem"
              svg={svg_message}
              fill={selectedTypeOfShare === 'MSG' ? '#ffd210' : '#fff'} /> */}
          </div>
          <div className="d-flex justify-content-around SharedPopUpSocialNetworks__main_social-list">
            {
              !isOpenedEmailInput ?
                LIST_SOCIAL_NETWORKS.map(item =>
                  <SVGInline
                    className={selectedTypeOfShare === item.type && 'checked'}
                    fill='#fff'
                    onClick={() => this.onSelectTypeOfShare(item.type)}
                    svg={item.svg} />
                ) :
                <input
                  value={email}
                  onChange={this.updateEmail}
                  type="email"
                  className="email"
                  autoFocus />
            }
          </div>
          <div className="d-flex SharedPopUpSocialNetworks__main_send">
            <button
              onClick={this.onShare}
              disabled={!selectedTypeOfShare}
              className="button-send">
              {!(selectedTypeOfShare === 'MAIL' || selectedTypeOfShare === 'MSG') ? 'Publish' : 'Send'}
            </button>
          </div>
        </div>
        <span className="closeCss" onClick={share} />
      </div>
    );
  }
}
