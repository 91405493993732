import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {
  isIOSorAndroid
} from '../../helpers/methods';
import '../../styles/components/ShareBlock.scss';
import {
  WhatsAppShare,
  TwitterShare,
  PinterestShare,
  LinkedinShare,
  EmailShare,
  FacebookMessengerShare,
  FacebookShare,
} from './config';
import {
  ShareButton
} from '../index';
import SVGInline from 'react-svg-inline';

const svg_mail = require('!!svg-inline-loader?classPrefix!../../assets/share-icons/mail.svg');
const svg_facebook = require('!!svg-inline-loader?classPrefix!../../assets/share-icons/facebook.svg');
const svg_linkedin = require('!!svg-inline-loader?classPrefix!../../assets/share-icons/linkedin.svg');
const svg_messanger = require('!!svg-inline-loader?classPrefix!../../assets/share-icons/messanger.svg');
const svg_pinterest = require('!!svg-inline-loader?classPrefix!../../assets/share-icons/pinterest.svg');
const svg_twitter = require('!!svg-inline-loader?classPrefix!../../assets/share-icons/twitter.svg');
const svg_whatsapp = require('!!svg-inline-loader?classPrefix!../../assets/share-icons/whatsapp.svg');


export default class ShareBlock extends Component {
  static propTypes = {
    urlToShare: PropTypes.string.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      isExpanded: false,
      selectedTypeOfShare: '',
      email: ''
    };
  }

  toggleBlock = (isExpanded) => {
    this.setState({
      isExpanded
    });
  };

  shareOnSocialMedia = () => {
    const {selectedTypeOfShare, email} = this.state;
    const {urlToShare} = this.props;
    if (selectedTypeOfShare) {
      switch (selectedTypeOfShare) {
        case 'EM' : {
          EmailShare(urlToShare, email);
          break;
        }
        case 'FB' : {
          FacebookShare(urlToShare);
          break;
        }
        case 'FBM' : {
          FacebookMessengerShare(urlToShare);
          break;
        }
        case 'LI' : {
          LinkedinShare(urlToShare);
          break;
        }
        case 'PI' : {
          PinterestShare(urlToShare);
          break;
        }
        case 'TW' : {
          TwitterShare(urlToShare);
          break;
        }
        case 'WU' : {
          WhatsAppShare(urlToShare);
          break;
        }
      }
      this.toggleBlock(false);
    }
  };

  onSelectTypeOfShare = (type) => {
    this.setState({
      selectedTypeOfShare: type
    });
  };

  render() {
    const {
      selectedTypeOfShare,
      email,
      isExpanded
    } = this.state;

    return (
      <div className="share-block">
        {
          isExpanded
            ?
            <div className="expanded share-block__content">
              <div className="share-header">
                <button className="share-header__button"
                  onClick={this.shareOnSocialMedia}>
                  <span className="share-header__button-text">Share on Social Media</span>
                  <SVGInline svg={require('!!svg-inline-loader?classPrefix!./../../assets/share-icon.svg')}
                    fill={'#ffffff'}
                    width={'20px'}
                    height={'16px'}/>
                </button>
              </div>
              <h4 className="content-title">Choose the platform you want to share with</h4>
              <div className="button-wrapper">
                <div className="button-wrapper__row">
                  <ShareButton selected={selectedTypeOfShare === 'EM'}
                    onSelect={() => this.onSelectTypeOfShare('EM')}>
                    <SVGInline svg={svg_mail} fill={selectedTypeOfShare === 'EM' ? '#ffd210' : '#ffffff'}/>
                  </ShareButton>
                  <ShareButton selected={selectedTypeOfShare === 'FB'}
                    onSelect={() => this.onSelectTypeOfShare('FB')}>
                    <SVGInline svg={svg_facebook} fill={selectedTypeOfShare === 'FB' ? '#ffd210' : '#ffffff'}/>
                  </ShareButton>
                  <ShareButton selected={selectedTypeOfShare === 'LI'}
                    onSelect={() => this.onSelectTypeOfShare('LI')}>
                    <SVGInline svg={svg_linkedin} fill={selectedTypeOfShare === 'LI' ? '#ffd210' : '#ffffff'}/>
                  </ShareButton>
                </div>
                <div className="button-wrapper__row">
                  <ShareButton selected={selectedTypeOfShare === 'PI'}
                    onSelect={() => this.onSelectTypeOfShare('PI')}>
                    <SVGInline svg={svg_pinterest} fill={selectedTypeOfShare === 'PI' ? '#ffd210' : '#ffffff'}/>
                  </ShareButton>
                  <ShareButton selected={selectedTypeOfShare === 'TW'}
                    onSelect={() => this.onSelectTypeOfShare('TW')}>
                    <SVGInline svg={svg_twitter} fill={selectedTypeOfShare === 'TW' ? '#ffd210' : '#ffffff'}/>
                  </ShareButton>
                  <ShareButton selected={selectedTypeOfShare === 'WU'}
                    onSelect={() => this.onSelectTypeOfShare('WU')}>
                    <SVGInline svg={svg_whatsapp} fill={selectedTypeOfShare === 'WU' ? '#ffd210' : '#ffffff'}/>
                  </ShareButton>
                </div>
                {
                  isIOSorAndroid()
                    ?
                    <div className="button-wrapper__row">
                      <ShareButton selected={selectedTypeOfShare === 'FBM'}
                        onSelect={() => this.onSelectTypeOfShare('FBM')}>
                        <SVGInline svg={svg_messanger} fill={selectedTypeOfShare === 'FBM' ? '#ffd210' : '#ffffff'}/>
                      </ShareButton>
                    </div>
                    :
                    ''
                }

                {
                  selectedTypeOfShare === 'EM'
                    ?
                    <input className="publish-input"
                      placeholder="Enter email"
                      value={email}
                      onChange={this.onEmailChange}/>
                    :
                    ''
                }
                <div className="publish-button-wrapper">
                  <button className="publish-button"
                    disabled={!selectedTypeOfShare}
                    onClick={this.shareOnSocialMedia}>
                    Publish
                  </button>
                </div>
              </div>
            </div>
            :
            <div className="collapsed share-block__button-open">
              <SVGInline svg={require('!!svg-inline-loader?classPrefix!./../../assets/share-icon.svg')}
                fill={'#ffffff'}
                width={'29px'}
                height={'26px'}
                onClick={() => this.toggleBlock(true)}/>
            </div>
        }
      </div>
    );
  }
}
