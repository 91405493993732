/**
 * Common
 */
export WizardOverviewStep from './WizardOverviewStep';
export RegisterHeader from './RegisterHeader/RegisterHeader';
export WizardAccountInfo from './WizardAccountInfo';
export RegisterAdditionalFields from './RegisterAdditionalFields';
export { SkipThisStep } from './CommonComponent';
export CancellationPolicy from './../CancellationPolicy/CancellationPolicy';
/**
 * Media
 */
export WizardOverviewPhotosEditStep from './media/WizardOverviewPhotosEditStep';
export WizardPhotoHistory from './media/WizardPhotoHistory';
export VideoUploadWrapper from './media/VideoUploadWrapper';
export AudioUploadWrapper from './media/AudioUploadWrapper';
export PicturesUploadWrapper from './media/PicturesUploadWrapper';

/**
 * Artist
 */
export WizardPlansAndTalentsForm from './artist/WizardPlansAndTalents';
export ArtistPersonalInfo from './artist/ArtistPersonalInfo';
export WizardOverviewTalentInfoForm from './artist/WizardOverviewTalentInfoForm';
export WizardOverviewTalentDetailsForm from './artist/WizardOverviewTalentDetailsForm';
export WizardOverviewPlansAndPayments from './WizardOverviewPlansAndPayments';

/**
 * Venue
 */
export WizardBookingInfo from './venue/WizardBookingInfo';
export WizardVenueInfo from './venue/WizardVenueInfo/WizardVenueInfo';
export WizardBusinessInfo from './venue/WizardBusinessInfo';
export WizardPlansAndVenueProfile from './venue/WizardPlansAndVenueProfile';
/**
 * Band
 */
export WizardBandDetails from './band/BandDetails';
export WizardBandInfoDetails from './band/BandInfo';
export WizardPlansAndBandGenre from './band/PlansAndBandGenre';
export WizardBandContactInfo from './band/BandContactInfo';
/**
 * Group
 */
export GroupInfo from './group/GroupInfo';
export GroupContactInfo from './group/GroupContactInfo';
export GroupDetails from './group/GroupDetails';
export PlansAndGroupTalents from './group/PlansAndGroupTalents';
/**
 * Company
 */
export CompanyInfo from './company/CompanyInfo';
export CompanyContactInfo from './company/CompanyContactInfo';
/**
 * Client
 */
export ClientInfo from './client/ClientInfo';
export ClientPersonalInfo from './client/ClientPersonalInfo';
/**
 * Fan
 */
export FanInfo from './fan/FanInfo';
export FanPersonalInfo from './fan/FanPersonalInfo';
