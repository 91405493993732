import './Hero.scss';
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { block } from 'bem-cn';
import { LandingButton } from '../LandingButton/LandingButton';

const b = block('Hero');

export class Hero extends Component {

  render() {
    const { subtitle, title, linkTitle, linkPath, image, mobileImage, scrollTo } = this.props;

    return (
      <div className={b()}>
        <picture>
          <source srcSet={mobileImage} media="(min-width: 768px)"/>
          <img className={b('image')} src={image} alt={subtitle}/>
        </picture>
        <h2 className={b('subtitle')}>{subtitle}</h2>
        <h1 className={b('title')}>{title}</h1>
        <LandingButton className={b('button')} to={linkPath}>{linkTitle}</LandingButton>
        {
          scrollTo && <a className={b('cta')} href={`#${scrollTo}`}>
            <i className="fa fa-chevron-down" aria-hidden="true"/>
          </a>
        }
      </div>
    );
  }
}
