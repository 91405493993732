import React from 'react'

import { Row, Col } from 'reactstrap';

import * as _ from 'lodash';
import { connect } from 'react-redux';

import 'antd/es/radio/style/css'

import 'antd/es/button/style/css'

import 'antd/es/form/style/css'
import 'antd/es/input/style/css'

import './SelectCardForm.scss';
import { Button as AntdButton } from 'antd';

import { IconCircle, Checkbox } from '../../../CommonComponent';
import Modal from '../../../common/Modal/Modal';
import Button from '../../../common/Button/Button';
import { getCardsList } from '../../../../redux/modules/cards';
import Loader from '../../../discover/Loader/Loader';
import NewCardForm from '../../Admin/Cards/NewCardForm';

@connect(({ cards }) => ({
    cardsList: cards.cardsList,
    isCardsListFetching: cards.isCardsListFetching,
}), { getCardsList })
class SelectCardForm extends React.PureComponent {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        const { cardsList, getCardsList } = this.props;

        if (!cardsList || cardsList.length === 0) {
            getCardsList();
        }
    }

    handleChange = value => {
        const { onChange } = this.props;
        onChange(value);
    }

    render() {
        const { cardsList, value, isCardsListFetching, alwaysSave } = this.props;

        if (isCardsListFetching) return <div className="SelectCardForm">
            <Loader />
        </div>

        return <div className="SelectCardForm">
            {[
                ...cardsList.map(item => <Card
                    item={item}
                    valueId={_.get(value, 'Id', null)}
                    onChange={this.handleChange}
                />),
                <Card
                    item={{ Id: 'OTHER' }}
                    valueId={_.get(value, 'Id', null)}
                    label="New Card"
                    style={{ position: 'relative', zIndex: 3 }}
                    onChange={this.handleChange}
                />
            ]}

            { value && value.Id === 'OTHER' && <div
                style={{
                    position: 'relative',
                    top: '-16px',
                    padding: '16px',
                    paddingTop: '32px',
                    marginLeft: '1rem',
                    marginRight: '1rem',
                    border: '1px solid #d9d9d9',
                    borderTopWidth: alwaysSave ? 1 : 0,
                    borderRadius: '5px'
                }}>

                <NewCardForm
                    onChange={value => this.handleChange({
                        ...value,
                        Id: 'OTHER',
                        save: alwaysSave ? true : value.save
                    })}
                />
            </div>}
        </div>
    }
}

export default SelectCardForm;

class Card extends React.PureComponent {
    render() {
        const { item, label, style, onChange, valueId } = this.props;

        return <AntdButton
            className="SelectCardForm__Item"
            type={valueId === item.Id ? "primary" : null}
            style={style}
            size="large"
            onClick={() => onChange(item)}
        >
            { label ? label : `${item.CardProvider} ending in **** ${item.Alias.slice(-4)}`}
        </AntdButton>
    }
}
