import React from 'react';
import './Spinner.scss';

const Spinner = ({ children, show }) => {
    if (show === undefined || show) {
        return <div className="Spinner">
            {children}
        </div> 
    }

    return null
}

export default Spinner;