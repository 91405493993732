import './SelectWithStepsLocation.scss';

import React, { Component } from 'react';
import { connect } from 'react-redux';

import PropTypes from 'prop-types';
import isEqual from 'lodash/isEqual';
import orderBy from 'lodash/orderBy';

import { FilterRadio } from '../FilterRadio/FilterRadio';

@connect(({ common }) => ({
  countriesList: common.countriesList
}))
export class SelectWithStepsLocation extends Component {
  static propTypes = {
    countriesList: PropTypes.array,
    value: PropTypes.array,
    index: PropTypes.number,
    locationType: PropTypes.string,
    onChange: PropTypes.func
  };

  _filteredLocations = null;

  constructor(props) {
    super(props);

    this.state = {};
    this._filteredLocations = this.getFilteredLocations();
  }

  UNSAFE_componentWillReceiveProps(next) {
    if (isEqual(next.value, this.props.value)) return;

    this._filteredLocations = this.getFilteredLocations(next);
  }

  getIndex() {
    return this.props.index || 0;
  }

  getFilteredLocations(props) {
    const _props = props || this.props;
    const filteredCountries = orderBy(_props.countriesList, item => item.name);
    if (this.getIndex() === 0) {
      // Continent level
      return filteredCountries.filter(country => {
        return country.type === _props.locationType;
      });
    } else if (this.getIndex() === 1) {
      // Country level
      return filteredCountries.filter(country => {
        return country.type === _props.locationType
          && _props.value[0].country_ids.indexOf(country.id) !== -1;
      });
    } else {
      // Stop tree
      return []
    }
  }

  render() {
    if (!this._filteredLocations.length) {
      return null;
    }

    const { value, onChange, countriesList } = this.props;

    const calculatedValue = value ? value[this.getIndex()] : {};

    return (
      <div className="SelectWithStepsLocation" key={`loc-${calculatedValue}`}>
        <div className="parent">
          {
            this._filteredLocations.map((loc, index) => (
              <FilterRadio
                key={`radioLoc-${loc.id}-${index}`}
                value={loc.id}
                label={loc.name}
                selected={calculatedValue && calculatedValue.id}
                groupName={`step-loc-${loc.id}`}
                onChange={() => onChange(this.getIndex(), loc)}
              />
            ))
          }
        </div>
        <div>
          {calculatedValue && (
            <SelectWithStepsLocation
              index={this.getIndex() + 1}
              locationType="country"
              value={value}
              onChange={onChange}
              countriesList={countriesList}
            />
          )}
        </div>
      </div>
    );
  }
}
