import React from 'react'
import { addToFavorites, removeFromFavorites } from '../../../redux/modules/favorites';
import { IconButton } from 'material-ui';
import SVGInline from 'react-svg-inline';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom'

const svg_heart = require('!!svg-inline-loader?classPrefix!../../../assets/icon-1.svg')
const svg_heart_fill = require('!!svg-inline-loader?classPrefix!../../../assets/GW_Heart.svg');

/**
 * self-sufficient like icon - it change state when users click and after
 * response fail it back to previous state
 *
 * @param {bool} defaultValue just to fill inital value, changing it doesnt update icon!
 * @param {func} callback exec after state updated on server with arg: (isSuccesfullyChanged)
 * @param {bool} white by default icon has black stroke add this to make it white
 * @param {number} targetId REQUIRED - profile id that icon use
 */
@connect(
	({ favorites, user }) => ({
        currentUser: user.currentUser
    }),
	{
		addToFavorites,
		removeFromFavorites,
	}
)
class LikeButton extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            value: props.defaultValue || false
        }
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if(nextProps.defaultValue !== this.state.value) {
          this.setState({ value: nextProps.defaultValue })
        }
      }

    handleChange = () => {
        const { history, targetId, addToFavorites, removeFromFavorites, callback, currentUser } = this.props;
        const nextValue = !this.state.value;

        if (!currentUser || !currentUser.id) {
            history.push('/login');
        } else {
            const action = nextValue ? addToFavorites : removeFromFavorites;
            action(targetId).then(
                _ => callback && callback(true),
                // todo res.statusCode 409 jak juz jest polubione -> wtedy ma jednak nie wracać
                _ => this.setState({ value: !nextValue }, () => callback && callback(false))
            )

            this.setState({ value: nextValue });
        }
    }

    render() {
        const { iconProps, white } = this.props;
        const { value } = this.state;

        return <IconButton onClick={this.handleChange}>
            {
                value ?
                    <SVGInline
                        svg={svg_heart_fill}
                        fill={'#f00'}
                        width="30px"
                        {...iconProps}
                    /> :
                    <SVGInline
                        svg={svg_heart}
                        width="30px"
                        fill={white ? '#fff' : '#000'}
                        style={white && { stroke: '#fff', strokeWidth: '1.2px' }}
                        {...iconProps}
                    />
            }
        </IconButton>
    }
}
export default withRouter(LikeButton);
