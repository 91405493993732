import React from 'react'

import Loader from '../Loader/Loader';

const coverImg = require('../../../assets/categories/default.jpg');

const defaultAd = {
  name: 'PickAd',
  description: 'Brooklyn Art Run with honeygrow',
  www: '#',
  logo_img: '#'
};

export default ({ isFetchingAds, adsByCategory, isCompareModal = false }) => {
  if (isFetchingAds) {
    return <Loader />;
  }

  const ad = adsByCategory[0] ?? defaultAd;

  if (isCompareModal) {
    return (
      <a href={ad.www} target="_blank" rel="noopener noreferrer">
        <div className="top-img">
          <div className="grdnt" />
          <p>{ad.description}</p>
            <img src={ad.logo_img} />
        </div>
      </a>
    );
  }

  const descriptionPosition = ad.descriptionPosition || 'center';
  const nameColor = ad.nameColor || 'white';
  const descriptionColor = ad.descriptionColor || 'white';
  const textFontSize = ad.textFontSize || '14px';

  return (
    <>
      <div className="col-sm-12 col-lg-6 col-xl-7 thumbnail">
        <span className="suggestion">
          <a style={{
            color: nameColor,
            fontSize: textFontSize
          }}>{ad.name}</a>
        </span>
        <a href={ad.www} target="_blank" rel="noopener noreferrer">
          <img src={ad.logo_img} />
        </a>
        <p
          className={`label-img ${descriptionPosition}`}
          style={{
            color: descriptionColor,
            fontSize: textFontSize,
          }}
        >
          {ad.description}
        </p>
      </div>
    </>
  );
}
