import React from 'react';
import ReactDOM from 'react-dom'

import Cleave from 'cleave.js/react';
import { DANGER_COLOR } from '../../../../../utils/constants';
import { Input } from 'antd';
import * as _ from 'lodash'

class SimpleFormattedInput extends React.PureComponent {
	constructor(props){
		super(props);
		this.state = {
			isValid: false,
			isTouched: false,
		}

		this.inputRef = null
		this.setInputRef = e => {
			this.inputRef = e;
		}
	}

	handleChange = value => {
		const { validator, onChange, onlyNumbers, noSpecialChars, maxLength } = this.props;

		if (('' + value).length > maxLength) {
			console.log('handleChange', ('' + value).length, maxLength)
			return
		}

		const isValid = validator(value);

		this.setState({
			isTouched: true,
			isValid
		})

		onChange({
			value,
			isValid
		});
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		if (!this.props.basicInput) {
			if (JSON.stringify(_.get(nextProps, 'options.blocks', null)) !== JSON.stringify(_.get(this.props, 'options.blocks', null))) {
				console.log('usuwam komponent')
				console.log(this.inputRef)
				ReactDOM.unmountComponentAtNode(ReactDOM.findDOMNode(this.inputRef));
			}
		}
	}

	render() {
		const { isValid, isTouched } = this.state
		const { label, validateError, onlyNumbers, basicInput, helpText, ...restProps } = this.props;
		const LABEL_LEFT_MARGIN = 12
		const Component = basicInput ? Input : Cleave

		return <label>
			{label && <span style={{
				marginLeft: LABEL_LEFT_MARGIN,
				fontWeight: 600,
			}}>{label}</span>}
			<Component
				{...(basicInput ? {
					ref: this.setInputRef
				 } : {
					htmlRef: this.setInputRef
				 })
			}
				style={{
					width: '100%',
					border: `1px solid #ccc`,
					borderRadius: '5px',
					padding: `6px ${LABEL_LEFT_MARGIN}px`
				}}
				// order below is important
				{...restProps}
				onChange={e => this.handleChange(e.target.value)}
			/>
			{helpText && <span className="d-inline-block text-muted mt-1" style={{
					fontSize: '.8rem',
					marginLeft: LABEL_LEFT_MARGIN
				}}>
				{helpText}
			</span>}

			{isTouched && !isValid && <span className="d-inline-block mt-1" style={{
					fontSize: '.8rem',
					color: DANGER_COLOR,
					marginLeft: LABEL_LEFT_MARGIN
				}}>
				{validateError}
			</span>}
		</label>
	}
}

export default SimpleFormattedInput
