import React, {Component} from 'react';
import {
  DatePickerComponent,
  AutoCompleteComponent,
} from './CommonComponent';
import {Field} from 'redux-form';
import Checkbox from 'material-ui/Checkbox';
import { connect } from 'react-redux';
import {Asterisk} from "./common/Asterisk/Asterisk";

export class DateOfBirth extends Component {
  alertPopup = null;

  render() {
    return (
      <div className="band-contact-info__date band-contact-info__container">
        <div className="band-contact-info__info">
          Date of birth
        </div>
        <div className="band-contact-info__date-of-birth">
          <Field name="birth_date"
            hintText="Birth day"
            container="inline"
            mode="landscape"
            textFieldStyle={{fontSize: 'calc(50rem / 32)'}}
            component={DatePickerComponent}/>
        </div>
        <div>
          <AlertPopUp ref={ref => {
            this.alertPopup = ref;
          }}/>
          <Field onCheck={() => this.alertPopup.openPopup()}
            name="majority_age"
            id="majority_age"
            style={{width: 'auto'}}
            labelStyle={{width: 'auto'}}
            label={<>I confirm that I'm over 18 years old <Asterisk /></>}
            component={Checkbox}/>
        </div>
      </div>
    );
  }
}

//TODO only one location for now
@connect(({ common }) => ({
  countriesList: common.countriesList,
}), {
})
export class BusinessLocations extends Component{
  constructor(props){
    super(props);
    this.state = {

    };
  }

  handleLocationChange = (val)=>{
    const { countriesList } = this.props;
    const newValue = countriesList.find(loc=> loc.name === val);

    this.setState({
      location: val
    });

    if (val){
      this.props.input.onChange([{id: newValue ? newValue.id : null, type: newValue ? newValue.type : null}]);
    } else {
      this.props.input.onChange(null);
    }
  };

  render(){
    const { input: { value }, hintText, floatingLabelText, fullWidth, textFieldStyle, countriesList } = this.props;
    const dataSource = countriesList.map(location=> location.name);
    const { location } = this.state;
    const stateValue = countriesList.find(loc=> loc.name === location);
    const propsValue = value[0] ? countriesList.find(loc=> loc.id === value[0].id) : null;

    const locationValue = stateValue ? stateValue.name : propsValue ? propsValue.name : location;

    return (
      <AutoCompleteComponent
        {...this.props}
        input={{value: locationValue, onChange: this.handleLocationChange}}
        hintText={hintText}
        textFieldStyle={textFieldStyle}
        dataSource={dataSource}
        floatingLabelText={floatingLabelText}
        fullWidth={fullWidth} />
    );
  }
}
