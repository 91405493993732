import './RecommendationProfile.scss';
import React from 'react';
import Rating from 'react-rating';
import { UserPic } from '../../common/UserPic/UserPic';

export const RecomendationProfile = props => {
  const { name, style, rating, avatar, id } = props;

  const urlName = name.replace(/\s/g, '.');

  return (
    <div className="RecommendationProfile">
      <div className="col-3 RecommendationProfile__photo">
          <UserPic
            href={`/profile/${urlName}/`}
            src={{ url: avatar ? avatar.url : null }}
            profileCompleted ={true}
          />
      </div>

      <div className="col-9 RecommendationProfile__information">
        Are you looking for a { style }?
        <b><a href={`/profile/${urlName}/`}>Check { name } Profile</a></b>
        <Rating
          className="RecommendationProfile__information_rating"
          readonly
          initialRate={+rating}
          empty="fa fa-star-o fa-fw"
          full="fa fa-star fa-fw"/>
      </div>
    </div>
  );
};
