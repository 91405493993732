import { logout } from '../modules/auth';
import { setPopupVisible } from '../modules/common';

export default function clientMiddleware(client) {
	return ({ dispatch, getState }) => next => action => {
		if (typeof action === 'function') {
			return action(dispatch, getState);
		}

		const { promise, types, ...rest } = action;

		if (!promise) {
			return next(action);
		}

		const [REQUEST, SUCCESS, FAILURE] = types;
		const actionPromise = promise(client);

		next({ ...rest, type: REQUEST });

		actionPromise.then(result => next({ ...rest, result, type: SUCCESS })).catch(error => {
			console.error(error)
			if (error && error.response && error.response.status === 401) {
			  dispatch(logout());
			} else if (error && error.response && error.response.status === 500) {
			  dispatch(setPopupVisible(true));
			// } else if (error && error.response && error.response.status === 400) {
			//   let urlEndpoint = error.request.responseURL.split('/').pop()
      //   if(urlEndpoint != 'apply') {
			//     //  Validation error coming from the backend
      //     dispatch(setPopupVisible(true, error.response.data));
      //   }
      }
			console.error(error)

			next({ ...rest, error, type: FAILURE })
		});

		return actionPromise;
	}
}
