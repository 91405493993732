import React, { Component } from 'react'
import { Field } from 'redux-form'
import { TextFieldComponent, PhoneComponent } from '../../CommonComponent'
import { Asterisk } from '../../common/Asterisk/Asterisk'
import { debounce } from 'lodash'
import Checkbox from 'material-ui/Checkbox';
import apiClient from '../../../helpers/apiClient';
import { defaultFontSize } from '../../../utils/constants';
import Loader from "../../discover/Loader/Loader";

export class NewMemberForm extends Component {
	contactInformationPopup = 'Please input your phone number with country code. For example +48';

	changeField = (value, fieldName) => {
		const { member, index, onMemberChange } = this.props
		const toChange = {
			...member,
			[fieldName]: value,
		}
		onMemberChange(toChange, index)
	}

	render() {
		const { member, isBand, phoneCodeList } = this.props;

		return (
			<div>
				<div className="band-contact-info__fields band-contact-info__container">
					<div className="band-contact-info__info">Email <Asterisk/></div>
					<TextFieldComponent
						hintText="Email"
						input={{ value: member.email, onChange: val => this.changeField(val, 'email') }}
						style={{ margin: '10px 0 5px' }}
						fullWidth={true}
					/>
				</div>
				<div className="band-contact-info__fields band-contact-info__container">
					<div className="band-contact-info__info">Legal name <Asterisk/></div>
					<TextFieldComponent
						input={{ value: member.first_name, onChange: val => this.changeField(val, 'first_name') }}
						type="text"
						style={{ margin: '10px 0 5px' }}
						fullWidth={true}
						hintText="First Name"
					/>
					<TextFieldComponent
						input={{ value: member.last_name, onChange: val => this.changeField(val, 'last_name') }}
						type="text"
						style={{ margin: '10px 0 5px' }}
						fullWidth={true}
						hintText="Last Name"
					/>
				</div>
				<div className="band-contact-info__fields band-contact-info__container add-member-popup-header">
					<div className="band-contact-info__info">Contact Information
						<span className="band-contact-info__optional">(optional)</span>
						<span className="fa-stack info-popup-window pl-2" data-title={this.contactInformationPopup}>
							<i className="fa fa-circle-thin fa-stack-2x"/>
              <i className="fa fa-info fa-stack-1x"/>
						</span>
					</div>
          {(phoneCodeList && phoneCodeList.length) ?
            <Field
              hintText="Phone Number"
              input={{value: member.phone_number, onChange: val => this.changeField(val, 'phone_number')}}
              style={{margin: '10px 0 5px'}}
              fullWidth={true}
              component={PhoneComponent}
              countries={this.props.phoneCodeList}
            />
            :
            <div className="d-flex justify-content-center">
              <Loader />
            </div>
          }
				</div>
				<div className="band-contact-info__fields band-contact-info__container add-member-popup-header">
					<div className="band-contact-info__info">{ isBand ? 'Musical Instrument' : 'Talent' } <Asterisk/>
						<span className="fa-stack info-popup-window pl-2" data-title={`Please select your ${isBand ? 'instrument' : 'talent'}`}>
							<i className="fa fa-circle-thin fa-stack-2x"/>
              <i className="fa fa-info fa-stack-1x"/>
						</span>
					</div>
          { isBand ?
              <TalentDropdown
                onChange={(option) => this.changeField(option, 'instrument')}
                searchEndpoint={'instrument-categories'}
                hintText={'Select instrument'}
              />
            :
              <TalentDropdown
                onChange={(option) => this.changeField(option, 'talent')}
                searchEndpoint={'talent-categories'}
                hintText={'Select talent'}
              />
          }
				</div>
			</div>
		)
	}
}

const DEBOUNCE_TIME = 500;

export class TalentDropdown extends Component {
	state = {
		options: [],
		isSearching: false,
		selectedOption: undefined,
  };

  chooseOption(option) {
		//for situation when we execute this request but option is already selected [not show dropdown that time]
		if (!this.state.selectedOption) {
			this.setState({
				isSearching: false,
				selectedOption: option
			})
			const { onChange } = this.props;
			onChange(option.id);
		}
  }

  clearSelected() {
    this.setState({
      selectedOption: undefined
    })
    const { onChange } = this.props;
    onChange(0);
  }

  onQueryChange = debounce((_, v) => {
    const { searchEndpoint } = this.props;
    if (v.length == 0) {
      this.setState({
        isSearching: false
      })
    } else {
      apiClient.get(`/api/${searchEndpoint}?q=${v}`)
        .then((res) => {
          this.setState({
            options: res.data,
            isSearching: true
          });
      });
    }
  }, DEBOUNCE_TIME);

	render() {
    const { hintText } = this.props;

		return (
			<div>
			{
				this.state.selectedOption ?
				  <div className="add-member-popup-proposition dropdown-select-field">
            <div className="add-member-popup-proposition-element dropdown-field">
              <p>{this.state.selectedOption.name}</p>
              <div className="close" onClick={() => this.clearSelected()}>x</div>
            </div>
				  </div>
				:
				  <Field
            name="option_name"
            type="text"
            style={defaultFontSize}
            fullWidth={true}
            value={this.state.queryText}
            onChange={this.onQueryChange}
            component={TextFieldComponent}
            hintText={hintText}
				  />
			  }
			  {
				this.state.isSearching ?
				  <div className="add-member-popup-proposition dropdown">
            {this.state.options.map((item, idx) => (
              <div className="add-member-popup-proposition-dropdown-element" onClick={() => this.chooseOption(item)}>
                <p>{item.name}</p>
              </div>
            ))}
				  </div>
				: null
			  }
			</div>
		);
	}
}
