import { get, isEmpty } from 'lodash'
import { registerProfileTabCodes } from '../utils/constants';

const does_user_have_payments = currentUser => {
	if (get(currentUser, 'plan.payment_status') === 'SET_PLAN') {
		return {
			isValid: false,
			errors: [{
				text: 'Payments',
				form: registerProfileTabCodes.Payments
			}]
		}
	}
	return { isValid: true }
}

const does_venue_have_occassions = currentUser => {
    const styles = get(currentUser, 'styles')
    if (styles && styles.filter(style => style.type === 'venue_occasion').length > 0) {
		return {
			isValid: true,
		}
    }
	return {
        isValid: false,
        errors: [{
            text: 'Venue occasions',
            form: 12
        }]
    }
}

const missing = {
	artist: {
		basic: {
			profile: [
				{
					path: 'email',
					text: 'Confirm email',
					form: 1,
				},
				{
					path: 'profile.first_name',
					text: 'First name',
					form: 2,
				},
				{
					path: 'profile.last_name',
					text: 'Last name',
					form: 2,
				},
				{
					path: 'profile.birth_date',
					text: 'Date of birth',
					form: 2,
				},
				{
					path: 'profile.location.formatted_address',
					text: 'Address',
					form: 2,
				},
				{
					path: 'profile.nationality',
					text: 'Nationality',
					form: 2,
                },
                {
                    path: 'plan',
                    text: 'Plan',
                    form: 3,
                },
                {
					path: 'categories[0].name',
					text: 'Primary talent',
					form: 7,
                },
				{
					path: 'profile.name',
					text: 'Profile name',
					form: 8,
				},
				{
					path: 'profile.brief_introduction',
					text: 'Brief Description',
					form: 8,
				},
				{
					path: 'answers',
					text: 'About you',
					form: 8,
				},
				{
					path: 'profile.booking_method',
					text: 'Booking method',
					form: 9,
				},
				{
					path: 'profile.experience',
					text: 'Professional experience',
					form: 9,
				},
				{
					path: 'profile.fee_primary',
					text: 'Service fees',
					form: 9,
				},
				{
					path: 'countries',
					text: 'Ability to travel',
					form: 3,
				},
				{
					path: 'profile.cancellation_policy',
					text: 'Policy',
					form: 10,
				}
			],
			media: [
				{
					path: 'profile.avatar',
					text: 'Profile Picture',
					form: 1,
				},
				{
					path: 'profile.cover_img',
					text: 'Cover Picture',
					form: 1,
				},
				{
					path: 'profile.card_img',
					text: 'Card Picture',
					form: 1,
				},
				{
					path: 'media_items',
					text: '2 media in any combination of picture/video/audio',
					form: 2,
				},
			],
		},
	},
	group: {
		band: {
			profile: [
                {
					path: 'email',
					text: 'Confirm email',
					form: 1,
				},
				{
					path: 'profile.name',
					text: 'Your band',
					form: 14,
				},
				{
					path: 'profile.manager_company_title',
					text: 'Manager Role',
					form: 14,
				},
				{
					path: 'profile.manager_first_name',
					text: 'Manager First name',
					form: 14,
				},
				{
					path: 'profile.manager_last_name',
					text: 'Manager Last name',
					form: 14,
				},
				{
					path: 'profile.manager_birth_date',
					text: 'Manager Date of birth',
					form: 14,
				},
				{
					path: 'profile.manager_location',
					text: 'Manager Address',
					form: 14,
                },
                {
                    path: 'plan',
                    text: 'Plan',
                    form: 4,
                },
				{
					path: 'categories[0]',
					text: 'Primary genre Instruments',
					form: 15,
				},
				{
					path: 'profile.brief_introduction',
					text: 'Brief Description',
					form: 17,
				},
				{
					path: 'answers',
					text: 'About you',
					form: 17,
				},
				{
					path: 'profile.booking_method',
					text: 'Booking method',
					form: 18,
				},
				{
					path: 'profile.experience',
					text: 'Professional experience',
					form: 18,
				},
				{
					path: 'profile.fee_primary',
					text: 'Service fees',
					form: 18,
				},
				{
					path: 'countries',
					text: 'Ability to travel',
					form: 4,
				},
				{
					path: 'profile.cancellation_policy',
					text: 'Policy',
					form: 9,
				},
        {
          path: 'profile.avatar',
          text: 'Profile Picture',
          form: 30,
        },
        {
          path: 'profile.cover_img',
          text: 'Cover Picture',
          form: 30,
        },
        {
          path: 'profile.card_img',
          text: 'Card Picture',
          form: 30,
        },
        {
          path: 'media_items',
          text: '2 media in any combination of picture/video/audio',
          form: 33,
        }
			],
			media: [
			],
		},
		basic: {
			profile: [
                {
					path: 'email',
					text: 'Confirm email',
					form: 1,
				},
				{
					path: 'profile.name',
					text: 'Your group',
					form: 21,
				},
				{
					path: 'profile.manager_company_title',
					text: 'Manager Role',
					form: 21,
				},
				{
					path: 'profile.manager_first_name',
					text: 'Manager First name',
					form: 21,
				},
				{
					path: 'profile.manager_last_name',
					text: 'Manager Last name',
					form: 21,
				},
				{
					path: 'profile.manager_birth_date',
					text: 'Manager Date of birth',
					form: 21,
				},
				{
					path: 'profile.manager_location',
					text: 'Manager Address',
					form: 21,
                },
                {
                    path: 'plan',
                    text: 'Plan',
                    form: 5,
                },
				{
					path: 'categories[0]',
					text: 'Primary group talent',
					form: 18,
				},
				{
					path: 'profile.brief_introduction',
					text: 'Brief Description',
					form: 20,
				},
				{
					path: 'answers',
					text: 'About you',
					form: 20,
				},

				{
					path: 'profile.booking_method',
					text: 'Booking method',
					form: 20,
				},
				{
					path: 'profile.fee_primary',
					text: 'Service fees',
					form: 20,
				},
				{
					path: 'countries',
					text: 'Ability to travel',
					form: 5,
				},
				{
					path: 'profile.cancellation_policy',
					text: 'Policy',
					form: 9,
				},
        {
          path: 'profile.avatar',
          text: 'Profile Picture',
          form: 30,
        },
        {
          path: 'profile.cover_img',
          text: 'Cover Picture',
          form: 30,
        },
        {
          path: 'profile.card_img',
          text: 'Card Picture',
          form: 30,
        },
        {
          path: 'media_items',
          text: '2 media in any combination of picture/video/audio',
          form: 33,
        }
			],
			media: [
				{
					path: 'profile.avatar',
					text: 'Profile Picture',
					form: 1,
				},
				{
					path: 'profile.cover_img',
					text: 'Cover Picture',
					form: 1,
				},
				{
					path: 'profile.card_img',
					text: 'Card Picture',
					form: 1,
				},
				{
					path: 'media_items',
					text: '2 media in any combination of picture/video/audio',
					form: 2,
				},
			],
		},
	},
	client: {
		basic: {
			profile: [
				{
					path: 'email',
					text: 'Confirm email',
					form: 1,
				},
				{
					path: 'profile.first_name',
					text: 'First name',
					form: 26,
				},
				{
					path: 'profile.last_name',
					text: 'Last name',
					form: 26,
				},
				{
					path: 'profile.birth_date',
					text: 'Date of birth',
					form: 26,
				},
				{
					path: 'profile.nationality',
					text: 'Nationality',
					form: 26,
				},
				{
					path: 'profile.location.formatted_address',
					text: 'Address',
					form: 26,
				},
				{
					path: 'profile.brief_introduction',
					text: 'Brief Description',
					form: 24,
				},
				{
					path: 'profile.about',
					text: 'About you',
					form: 24,
				},
			],
			media: [
				{
					path: 'profile.avatar',
					text: 'Profile Picture',
					form: 1,
				},
				{
					path: 'profile.cover_img',
					text: 'Cover Picture',
					form: 1,
				},
				{
					path: 'profile.card_img',
					text: 'Card Picture',
					form: 1,
				},
			],
		},
		company: {
			profile: [
				{
					path: 'email',
					text: 'Confirm email',
					form: 1,
				},
				{
					path: 'profile.name',
					text: 'Company Name',
					form: 23,
				},
				{
					path: 'profile.company_type',
					text: 'Company Type',
					form: 23,
				},
				{
					path: 'profile.manager_first_name',
					text: 'First Name',
					form: 23,
				},
				{
					path: 'profile.manager_last_name',
					text: 'Last Name',
					form: 23,
				},
				{
					path: 'profile.manager_birth_date',
					text: 'Date of birth',
					form: 23,
				},
				{
					path: 'profile.manager_location',
					text: 'Address',
					form: 23,
				},
				{
					path: 'profile.nationality',
					text: 'Manager Nationality',
					form: 23,
				},
				{
					path: 'profile.brief_introduction',
					text: 'Brief Description',
					form: 22,
				},
				{
					path: 'profile.about',
					text: 'About you',
					form: 22,
				},
        {
          path: 'profile.avatar',
          text: 'Profile Picture',
          form: 30,
        },
        {
          path: 'profile.cover_img',
          text: 'Cover Picture',
          form: 30,
        },
        {
          path: 'profile.card_img',
          text: 'Card Picture',
          form: 30,
        },
			],
			media: [
				{
					path: 'profile.avatar',
					text: 'Profile Picture',
					form: 1,
				},
				{
					path: 'profile.cover_img',
					text: 'Cover Picture',
					form: 1,
				},
				{
					path: 'profile.card_img',
					text: 'Card Picture',
					form: 1,
				},
			],
		},
	},
	venue: {
		basic: {
			profile: [
				{
					path: 'email',
					text: 'Confirm email',
					form: 1,
				},
				{
					path: 'profile.name',
					text: 'Venue Name',
					form: 10,
				},
				{
					path: 'profile.location',
					text: 'Address',
					form: 10,
				},
				{
					path: 'profile.established_since',
					text: 'Established since',
					form: 10,
				},
				{
					path: 'profile.manager_first_name',
					text: 'Manager First Name',
					form: 10,
				},
				{
					path: 'profile.manager_last_name',
					text: 'Manager Last Name',
					form: 10,
				},
				{
					path: 'profile.manager_company_title',
					text: 'Manager Company Title',
					form: 10,
                },
                {
                    path: 'plan',
                    text: 'Plan',
                    form: 6,
                },
				{
					path: 'categories[0]',
					text: 'Primary Business Type',
					form: 10,
                },
                {
					validator: does_venue_have_occassions,
					text: 'Venue occasions',
					form: 11
				},
				{
					path: 'profile.brief_introduction',
					text: 'Brief Description',
					form: 13,
				},
				{
					path: 'answers',
					text: 'About you',
					form: 13,
				},
				{
					path: 'profile.capacity',
					text: 'Capacity (at least one)',
					form: 13,
				},
				{
					path: 'profile.booking_method',
					text: 'Booking method',
					form: 13,
				},
				{
					path: 'profile.experience',
					text: 'Professional experience',
					form: 13,
				},
				{
					path: 'profile.fee_primary',
					text: 'Service fees',
					form: 13,
				},
				{
					path: 'profile.cancellation_policy',
					text: 'Policy',
					form: 9,
				},{
          path: 'profile.avatar',
          text: 'Profile Picture',
          form: 30,
        },
        {
          path: 'profile.cover_img',
          text: 'Cover Picture',
          form: 30,
        },
        {
          path: 'profile.card_img',
          text: 'Card Picture',
          form: 30,
        },
        {
          path: 'media_items',
          text: '2 media in any combination of picture/video/audio',
          form: 33,
        }
			],
			media: [
				{
					path: 'profile.avatar',
					text: 'Profile Picture',
					form: 1,
				},
				{
					path: 'profile.cover_img',
					text: 'Cover Picture',
					form: 1,
				},
				{
					path: 'profile.card_img',
					text: 'Card Picture',
					form: 1,
				},
				{
					path: 'media_items',
					text: '2 media in any combination of picture/video/audio',
					form: 2,
				},
			],
		},
	},
	fan: {
		basic: {
			profile: [
				{
					path: 'email',
					text: 'Confirm email',
					form: 1,
				},
				{
					path: 'profile.first_name',
					text: 'First Name',
					form: 28,
				},
				{
					path: 'profile.last_name',
					text: 'Last Name',
					form: 28,
				},
				{
					path: 'profile.location',
					text: 'Address',
					form: 28,
				},

				{
					path: 'profile.brief_introduction',
					text: 'Brief Description',
					form: 27,
				},
				{
					path: 'profile.about',
					text: 'About you',
					form: 27,
				},
			],
			media: [
				{
					path: 'profile.avatar',
					text: 'Profile Picture',
					form: 1,
				},
				{
					path: 'profile.cover_img',
					text: 'Cover Picture',
					form: 1,
				},
				{
					path: 'profile.card_img',
					text: 'Card Picture',
					form: 1,
				},
				{
					path: 'media_items',
					text: '2 media in any combination of picture/video/audio',
					form: 2,
				},
			],
		},
	},
}


// { text: red label, form: form id }

const missingFields = ({currentUser = {}, currentKYC = false}, currentType = 'profile', onClickFn) => {
	let result = [];

  if (isEmpty(currentUser)) return [];

	if (currentUser.profile_type === null || currentUser.profile_subtype === null) return [];

	get(missing, `${currentUser.profile_type}.${currentUser.profile_subtype}`, [])[currentType].forEach(item => {
        if (item.path) {
            if (item.path === 'media_items' && currentUser[item.path].length < 2) {
                result = [...result, { text: item.text, form: item.form }]
            }
            else {
                let value = get(currentUser, item.path, null)
                if (!value || value.length == 0) {
                    result = [...result, { text: item.text, form: item.form }]
                }
            }
        }
        else if (item.validator) { // it only checks if field should show in right side column
			// error format: { text, form }
			const { isValid, errors } = item.validator(currentUser)
			if (!isValid) {
				result = [ ...result, ...errors]
			}
		}
	})

  const kycStatus = get(currentKYC, 'status', 'INCOMPLETE')
  const showKycMissing = kycStatus !== 'VALIDATED' ? kycStatus !== 'VALIDATION_ASKED' : false

	if (showKycMissing && currentType === 'profile') result.push({ text: 'KYC', form: 28 })
	console.log(currentKYC, result )


	return result
}

export default missingFields

// get(missing, `${currentUser.profile_type}.${currentUser.profile_subtype}`, missing.artist.basic)[currentType].forEach(

