import React from 'react';
import { connect } from 'react-redux';
import { get } from 'lodash';
import SVGInline from 'react-svg-inline';
import {
  setClapped,
  setFollowed,
} from '../../redux/modules/profile';
import notification from 'antd/es/notification'

const svg_clap_black = require('!!svg-inline-loader?classPrefix!../../assets/profile/Clap-black.svg');
const svg_follow_black = require('!!svg-inline-loader?classPrefix!../../assets/profile/Follow-black.svg');
const svg_clap_yellow = require('!!svg-inline-loader?classPrefix!../../assets/profile/Clap-yellow.svg');
const svg_follow_yellow = require('!!svg-inline-loader?classPrefix!../../assets/profile/Follow-yellow.svg');


@connect(
	({profile}) => ({
    userId: get(profile, 'profile.id', null),
    clapped: get(profile, 'profile.clapped', false),
    followed: get(profile, 'profile.followed', false),
    isSettingClapped: get(profile, 'isSettingClapped'),
    isSettingFollowed: get(profile, 'isSettingFollowed')
  }),
	{
    setClapped,
    setFollowed,
	}
)
class ProfileActions extends React.Component {
  constructor(props) {
    super(props);

    const {
      clapped = false,
      followed = false,
      clappers_count,
      followers_count
    } = props;

    this.state = {
      clapped, followed, clappers_count, followers_count
    }
  }
  UNSAFE_componentWillReceiveProps(nextProps) {
    const { clapped, followed, clappers_count, followers_count } = nextProps;

    if (typeof this.state.clappers_count === 'undefined' && 
        typeof this.state.followers_count === 'undefined') {
      this.setState({ clapped, followed, clappers_count, followers_count })
    }
  }

  handleClapClick = () => {
    const { history, currentUser, userId, isSettingClapped, setClapped } = this.props;
    const { clapped } = this.state;

    if (currentUser && userId && !isSettingClapped) {
        this.setState(
          clapped ?
            { clapped: false, clappers_count: this.state.clappers_count - 1 }:
            { clapped: true, clappers_count: this.state.clappers_count + 1 },
            () => setClapped(!clapped, userId)
        )
    } else if (!currentUser) {
      history.push('/login');
      // notification.error({
      //   message: 'Error!',
      //   description: 'Please log in to perform this action.'
      // });
    }
  }

  handleFollowClick = () => {
    const { history, currentUser, userId, isSettingFollowed, setFollowed } = this.props;
    const { followed } = this.state;

    if (currentUser && userId && !isSettingFollowed) {
      this.setState(
        followed ?
          { followed: false, followers_count: this.state.followers_count - 1 }:
          { followed: true, followers_count: this.state.followers_count + 1 },
          () => setFollowed(!followed, userId)
      )
    } else if (!currentUser) {
      history.push('/login');
      // notification.error({
      //   message: 'Error!',
      //   description: 'Please log in to perform this action.'
      // });
    }
  }

  render() {

    const { 
      clappers_count,
      followers_count,
      clapped,
      followed
    } = this.state;

    const { isKindOfArtist } = this.props;

    return (
      <div className="profile__actions">
        {!isKindOfArtist && <div className="underline" />}
        <div className="profile__actions_clap"
          onClick={this.handleClapClick}>
          <SVGInline svg={clapped ? svg_clap_yellow : svg_clap_black} width="1.2rem" />
          <div>
            <b>{clappers_count}</b><br />
            <small>Claps</small>
          </div>
        </div>
        <div className="profile__actions_follow"
          onClick={this.handleFollowClick}>
          <SVGInline svg={followed ? svg_follow_yellow : svg_follow_black} width="1.2rem" />
          <div>
            <b>{followers_count}</b><br />
            <small>Followers</small>
          </div>
        </div>
        {isKindOfArtist && <div className="underline" />}
      </div>
    )    
  }
}

export default ProfileActions