const validate = values => {
	const errors = {}

	if (!values.booking_method) {
		errors.booking_method = 'Required'
	}

	if (!values.experience) {
		errors.experience = 'Required'
	}

  if (!values.fee_primary) {
    errors.fee_primary = 'Required'
  } else {
    if (!values.fee_primary.value) {
      errors.fee_primary = 'Required'
    }
    if (0 > values.fee_primary.value || parseFloat(values.fee_primary.value) === 0) {
      errors.fee_primary = 'Price should be bigger than 0'
    }
    if (isNaN(values.fee_primary.value)) {
      errors.fee_primary = 'Price is not valid'
    }
    if (!values.fee_primary.period) {
      errors.fee_primary = 'Period Required'
    }
    if (!values.fee_primary.currency) {
      errors.fee_primary = 'Currency Required'
    }
  }

	return errors
};

export default validate
