import React from 'react'
import { KycStatusType } from "./KycConstants";
import {translate} from "../../../translator";

function renderStatus(status, small) {
  console.log('Rendering status ', status);

  switch (status) {
    case KycStatusType.VALIDATED:
      return <span className={generateClassName('green', small)}>verified</span>
    case KycStatusType.CREATED:
      return <span className={generateClassName('grey', small)}>to be submitted</span>
    case KycStatusType.VALIDATION_ASKED:
      return <span className={generateClassName('grey', small)}>pending</span>
    case KycStatusType.REFUSED:
    default:
      return <span className={generateClassName('red', small)}>refused</span>
  }
}

/**
 * A component used to show the status of a kyc document.
 */
export default function KycStatusIndicator(props) {
  const { status, small, outsideStyle } = props;

  if (!status) { return null; }

  return (
    <div className="section" style={outsideStyle}>
      <span className="section_header">{translate('kyc.status')}</span>
      { renderStatus(status, small) }
    </div>
  );
}

function generateClassName(color, small) {
  return 'indicator ' + color + (small ? ' small' : '');
}
