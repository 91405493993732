import React, { Component } from 'react'
import { Field, reduxForm } from 'redux-form'
import TextField from 'material-ui/TextField'
import SelectField from 'material-ui/SelectField'
import MenuItem from 'material-ui/MenuItem'
import { connect } from 'react-redux'
import validate from '../../../helpers/validators/artist/TalentDetails'
import { adjustQuestionsAnswersResponse, getValues } from '../../../helpers/methods'
import { currency, period } from '../../../utils/constants'
import '../../../styles/components/WizardOverviewTalentDetailsForm.scss'
import { InputStyles, PlaceholderStyles } from '../CommonComponent'
import { Tags, Experience } from '../CommonRegistrationComponent'
import { PriceListNew } from '../PriceList/PriceListNew'
import { Asterisk } from '../../common/Asterisk/Asterisk'
import { registrationConfig } from '../../../config'

import { WizardOverviewTalentInfoForm } from './WizardOverviewTalentInfoForm'

class WizardOverviewTalentDetailsForm extends Component {
	render() {
		const { handleSubmit } = this.props

		return (
			<form className="service-detail-form" onSubmit={handleSubmit}>
        <div className="service-detail-form__wrapper">
          <Field name="questions_answers" component={WizardOverviewTalentInfoForm} />
        </div>
				<div className="service-detail-form__wrapper">
					<Field name="booking_method" component={BookingMethod} />
				</div>
				<div className="service-detail-form__wrapper">
					<Field name="experience" component={Experience} />
				</div>
				<div className="service-detail-form__wrapper">
					<Field name="fee_primary" feeLabel={'Primary'} component={Fee} required primary />
					<Field name="fee_secondary" feeLabel={'Secondary'} heading={false} component={Fee} />
				</div>
				<div className="service-detail-form__wrapper">
					<Field name="services" component={PriceListNew} />
				</div>
				<div className="service-detail-form__wrapper">
					<Field name="tags" component={Tags} />
				</div>
			</form>
		)
	}
}

WizardOverviewTalentDetailsForm = reduxForm({
	form: 'TalentDetails',
	destroyOnUnmount: false,
	forceUnregisterOnUnmount: false,
	enableReinitialize: true,
	validate,
})(WizardOverviewTalentDetailsForm)
//TODO refactoring

export default connect(
	({ user, form }) => ({
		currentUser: user.currentUser,
		initialValues: getValues(
			form.TalentDetails,
			user.currentUser && user.currentUser.profile
				? {
						booking_method: user.currentUser.profile.booking_method,
						experience: user.currentUser.profile.experience,
						fee_primary: user.currentUser.profile.fee_primary,
						fee_secondary: user.currentUser.profile.fee_secondary,
						business_locations: user.currentUser.profile.business_locations,
						services:
							user.currentUser.services && user.currentUser.services.length ? user.currentUser.services : undefined,
						tags:
							user.currentUser.tags && user.currentUser.tags.length
								? {
										basic: user.currentUser.tags
											.filter(tag => tag.type === 'basic')
											.map(tag => ({ content: tag.content })),
								  }
								: { basic: [] },
            brief_introduction: user.currentUser.profile.brief_introduction,
            about:              user.currentUser.profile.about,
            name:               user.currentUser.profile.name,
            questions_answers:  adjustQuestionsAnswersResponse(
              user.currentUser.answers) || []
				  }
				: {},
		),
	}),
	{}
)(WizardOverviewTalentDetailsForm)

class BookingMethod extends Component {
	onTypeSelect(type) {
		const {
			input: { onChange },
		} = this.props
		onChange(type)
		// onChange('')
	}

	//string
	render() {
		const {
			input: { value },
			meta,
		} = this.props

		return (
			<div className="service-detail-form__item">
				<div className="service-detail-form__item-header">
					<span className="fa-stack info-popup-window" data-title={registrationConfig.popupText.artistBookingMethod}>
						<i className="fa fa-circle-thin fa-stack-2x" />
						<i className="fa fa-info fa-stack-1x" />
					</span>
					<h4 className="service-detail-form__title">
						Booking method <Asterisk />
					</h4>
				</div>
				<div className="radio-group-wrapper">
					<label className="radio-group radio-inline at-normal">
						<input
							onClick={() => this.onTypeSelect('normal')}
							{...{ checked: value === 'normal' }}
							name="booking"
							type="radio"
							className="d-none"
						/>
						<span>Normal Booking</span>
					</label>
					<label className="radio-group radio-inline at-instant">
						<input
							onClick={() => this.onTypeSelect('instant')}
							{...{ checked: value === 'instant' }}
							name="booking"
							type="radio"
							className="d-none"
						/>
						<span>Instant Booking</span>
					</label>
				</div>
				{meta.error && meta.error ? <div className="expirience-error">{meta.error}</div> : null}
			</div>
		)
	}
}

class Fee extends Component {
	constructor(props) {
		super(props)

		this.state = {}
	}

    // componentDidMount() {
    //     this.onChangeValue(null, period.common[0].value, 'period')
    // }

	onChangeValue = (evt, newVal, field = 'value') => {
		const {
			input: { value, onChange },
		} = this.props

		onChange({
			...value,
			[field]: newVal,
		})
	}

	render() {
		const {
			feeLabel,
			input: { value, name },
			heading,
            required,
            primary
        } = this.props

        const options = /*primary ? [period.common[0]] :*/ period.common

		return (
			<div className="service-detail-form__item">
				{heading === false ? (
					''
				) : (
					<div className="service-detail-form__item-header">
						<span className="fa-stack info-popup-window" data-title={registrationConfig.popupText.artistServiceFees}>
							<i className="fa fa-circle-thin fa-stack-2x" />
							<i className="fa fa-info fa-stack-1x" />
						</span>
						<h4 className="service-detail-form__title">Service fees</h4>
					</div>
				)}
				<h4 className="service-detail-form__subtitle">
					{feeLabel} fee
					{required ? <Asterisk className="ml-1" /> : null}
				</h4>
				<div className="service-detail-form__price-wrapper">
					<div className="number-input price-count">
						<TextField
							value={value.value}
							inputStyle={InputStyles}
							hintStyle={PlaceholderStyles}
							onChange={(evt, val) => this.onChangeValue(evt, val)}
							hintText="Price"
							fullWidth={true}
						/>
					</div>
					<div className={`price-currency at-${name}`}>
						<SelectField
							hintStyle={PlaceholderStyles}
							labelStyle={InputStyles}
							fullWidth={true}
							value={value.currency}
							onChange={(evt, index, val) => this.onChangeValue(evt, val, 'currency')}
						>
							{currency.map((cur, index) => (
								<MenuItem key={index} value={cur.value} primaryText={cur.label} />
							))}
						</SelectField>
					</div>
					<div className={`price-period at-${name}`}>
						<SelectField
							hintStyle={PlaceholderStyles}
							labelStyle={InputStyles}
							fullWidth={true}
							value={value.period}
							onChange={(evt, index, val) => this.onChangeValue(evt, val, 'period')}
						>
							{options.map((per, index) => (
								<MenuItem key={index} value={per.value} primaryText={per.label} />
							))}
						</SelectField>
					</div>
				</div>
			</div>
		)
	}
}

// <SelectField
//   hintStyle={PlaceholderStyles}
//   labelStyle={InputStyles}
//   fullWidth={true}
//   value={value.period}
//   onChange={(evt, index, val) => this.onChangeValue(evt, val, 'period')}>
//   {period.map((per, index) => <MenuItem key={index} value={per.value} primaryText={per.label} />)}
// </SelectField>

{
	/* <TextField
								value="hour"
								inputStyle={InputStyles}
								hintStyle={PlaceholderStyles}
								onChange={(evt, val) => this.onChangeValue(evt, val, 'period')}
								hintText="hour"
								fullWidth={true}
							/> */
}
