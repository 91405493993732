import React, { Component } from 'react';
import '../../../styles/components/band/BandInfo.scss';
import { Field, reduxForm } from 'redux-form';
import { TextFieldComponent } from '../../CommonComponent';
import validate from '../../../helpers/validators/band/BandInfo';
import { connect } from 'react-redux';
import { getValues, adjustQuestionsAnswersResponse } from '../../../helpers/methods';
import { Asterisk } from '../../common/Asterisk/Asterisk';
import classnames from 'classnames';
import { registrationConfig } from '../../../config';
import QuestionsDropdown from '../shared/QuestionsDropdown';
import {dropdownQuestions, MAX_BRIEF_INTRODUCTION_LENGTH} from '../../../utils/constants';

export class WizardBandInfoDetails extends Component {
  render() {

    return (
      <div>
        <div className='service-info-form__wrapper'>
          <ServiceInfoFirstForm />
        </div>
        <div className='service-info-form__wrapper'>
          <Field
            name="questions_answers"
            component={QuestionsDropdown}
            questions={dropdownQuestions.bandQuestions}
          />
        </div>
      </div>
    );
  }
}

WizardBandInfoDetails = reduxForm({
  form: 'BandDetails',
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: false,
  enableReinitialize: true,
  validate
})(WizardBandInfoDetails);

export default connect(
  ({ user, form }) => (
    {
      currentUser: user.currentUser,
      initialValues: getValues(form.BandDetails, user.currentUser && user.currentUser.profile ? {
        brief_introduction: user.currentUser.profile.brief_introduction,
        about: user.currentUser.profile.about,
        questions_answers: adjustQuestionsAnswersResponse(user.currentUser.answers) || []
      } : {})
    })
)(WizardBandInfoDetails);

class ServiceInfoFirstForm extends Component {
  render() {
    const { filled } = this.props;

    const maxLength = max => value => {

      let v;
      let result = value.length > max;

      if(result === false) {
        v = value;
      }
      return v;
    };

    return (
      <div className='service-info-form__item'>
        <div className='service-info-form__item-header'>
          <span className='fa-stack info-popup-window' data-title={registrationConfig.popupText.briefIntroduction}>
            <i className='fa fa-circle-thin fa-stack-2x'/>
            <i className='fa fa-info fa-stack-1x'/>
          </span>
          <h4 className={classnames('service-info-form__title', { filled })}>
            Brief introduction <Asterisk/> <span
            className='description'>(Max. 155 characters)</span>
          </h4>
        </div>
        <Field
          hintText='Start writing here'
          name='brief_introduction'
          inputStyle={{ color: '#000' }}
          style={{ margin: '10px 0 5px' }}
          fullWidth={true}
          normalize={maxLength(MAX_BRIEF_INTRODUCTION_LENGTH)}
          component={TextFieldComponent}/>
      </div>
    );
  }
}
