import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import InfiniteScroll from 'react-infinite-scroller'
import GigCard from '../../../common/GigCard/GigCard'
import SVGInline from 'react-svg-inline'
import { get, isEmpty } from 'lodash'
import { Container } from 'reactstrap'
import { connect } from 'react-redux'
import './FeedCategory.scss'
import { MIN_BUDGET, MAX_BUDGET } from '../../../../utils/constants'
import { getCategories } from '../../../../redux/modules/common'
import './icons.css'
import { compose } from 'recompose'

const buttonBack = require('!!svg-inline-loader?classPrefix!../../../../assets/icons/left-arrow.svg')


class FeedCategory extends Component {
	constructor(props) {
		super(props)
		this.state = {
			viewMore: false,
			gigs: [],
			filterValue: null,
		}

		//this.throttledLoadGigs = throttle(this.loadGigs, 500)
		//this.loadGigs = this.loadGigs.bind(this)
		//this.handleFilterChange = this.handleFilterChange.bind(this)
		//this.getGigs = this.getGigs.bind(this)
	}

	//componentWillUnmount() {
	//	document.body.style.overflow = 'auto'
	//	document.body.style.height = 'auto'
	//}

	// toggleViewMore = () => {
	// 	//console.log("I got you!!!", this.state.viewMore)
	// 	const {
	// 		categoriesMap,
	// 		feedCategory: {
	// 			category: { id: categoryId, is_parent },
	// 		},
	// 	} = this.props
	// 	const category = categoriesMap[categoryId]

	// 	if (is_parent && +this.props.history.location.pathname.split('/').slice(-1) !== category.id) {
	// 		return this.props.history.push(`/discover/feed/${category.id}`)
	// 	}

	// 	const { viewMore } = this.state
	// 	const { scrollTo } = this.props
	// 	const bodyGigFeed = document.body

	// 	//if (!this.state.gigs.length) {
	// 	//	this.getGigs()
	// 	//}
	// 	this.setState({
	// 		viewMore: !viewMore,
	// 	})

	// 	bodyGigFeed.style.overflow = viewMore ? 'auto' : 'hidden'
	// 	bodyGigFeed.style.height = viewMore ? 'auto' : '100vh'

	// 	if (viewMore && bodyGigFeed.style.overflow === 'auto') {
	// 		scrollTo(this.props.feedCategory.category.id)
	// 	}
    // }

    componentDidMount() {
        if(isEmpty(this.props.categoriesMap)) {
            this.props.getCategories();
        }
    }

	onScrollHandler = e => {
		if (this.state.viewMore) {
			e.stopPropagation()
			e.nativeEvent.stopPropagation()
			e.nativeEvent.stopImmediatePropagation()
		}
	}

	getSuperCategoryId = () => this.props.match.params.superCategoryId

	goToCategory = categoryName => {
	  const { history } = this.props;

    categoryName = categoryName.replace(/\s/g, '');

		const superCategoryId = this.getSuperCategoryId();
		if(superCategoryId) {
			const superCategory = this.props.categoriesMap[superCategoryId];

			if(superCategory && superCategory.name === 'Production' && superCategory.tree_path.length === 1) {
				history.push(`/discover/GigFeed/${categoryName}`)
			} else {
				history.push(`/discover/GigFeed/${superCategoryId}/${categoryName}`);
			}
		}
	}

	render() {
		const { feedCategory, isMobile, imageUrl, rootCategory, categoriesMap } = this.props
		const { viewMore } = this.state

    const sortedGigs = feedCategory.gigs.sort((a, b) => {
      return new Date(b.start_date) - new Date(a.start_date);
    });

		const defaultImageUrl = require('../../../../assets/categories/0.jpg')

		return (
			<div
				onClick={() => isMobile && this.goToCategory(feedCategory.category.name)}
				onWheel={this.onScrollHandler}
				id={`FeedCategory-${feedCategory.category.id}`}
				className={['FeedCategory', viewMore && 'expanded'].join(' ')}
				style={ viewMore ? { overflowY: 'auto' } : null }
			>
				<div className={'d-flex FeedCategory__main'}>
					<div
						className="cover"
						style={{
							backgroundImage: `url(${imageUrl ? imageUrl : defaultImageUrl})`,
						}}/>
					<div className="gradient" />
					{isMobile
					  ? <div className="FeedCategoryName">{feedCategory.category.name}</div>
					  : <Container
							className="d-flex FeedCategoryContentContainer"
						>
							<div className="option" style={{ width: '280px'}}> {/* sidebar width */}
								<div className="option-top">
									<h3 className="option-title">{feedCategory.category.name}</h3>
									<p className="option-description">
										Gigs you may be interested in apply as a<strong> {feedCategory.category.name}</strong>
									</p>
								</div>
								<button
									onClick={() => this.goToCategory(feedCategory.category.name)}
									className="option-button"
									>
									<span>View all</span>
								</button>
							</div>

							<div style={{
								display: 'grid',
								gridGap: '16px',
								padding: '32px 16px',
								width: '100%',
								gridAutoFlow: 'row',
								gridTemplateColumns: '1fr 1fr 1fr'
							}}>
							{sortedGigs.map((gig, key) => (
								<GigCard
									style={{ margin: 0 }}
									key={gig.id}
									gig={gig}
									onViewMore={this.props.onViewMore}
									categoryName={get(categoriesMap, `${gig.category_id}.name`, null)}
                  feedCategoryName = {feedCategory.category.name}
									//performer_type={gig.performer_type}
									//apply={applyBtnHandler}
									//save={saveBtnHandler}
								/>
							))}
							</div>
						</Container>
					}
				</div>
			</div>
		)
	}
}

export default compose(
	withRouter,
	connect(({ globalConfig, user, common }) => ({
		isMobile: globalConfig.isMobile,
		currentUser: user.currentUser,
		categoriesMap: common.categoriesMap,
	}),
	{
		getCategories,
	})
)(FeedCategory)
