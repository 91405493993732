import React from 'react'
import {Field} from "redux-form";
import {translate} from "../../../translator";
import {Asterisk} from "../../common/Asterisk/Asterisk";
import KycStatusIndicator from "./KycStatusIndicator";

/**
 * Component that represents the header portion of the kyc.
 * The header is the part that explains what the KYC is and shows the status of the user's KYC validation.
 */
export default function KycHeader(props) {

  const { status } = props;

  console.log('Rendering header with status ', status);

  return (
    <>
      <Field name="kyc_person_type" component="input" type="hidden" />
      <Field name="kyc_entity_type" component="input" type="hidden" />

      <div className="title">{translate('kyc.euCompliance')} <Asterisk /></div>
      <div>{translate('kyc.euComplianceSub')}</div>

      <KycStatusIndicator status={status}/>
    </>
  );
}
