import config from './../../config'
import { routes } from './../../utils/constants'
import { setToken, removeToken } from '../../helpers/apiClient'
import { loginOAuth } from '../../helpers/oauth'
import { REGISTER_SUCCESS, VERIFY_EMAIL } from './register'

export const START_SIGNIN = 'auth/SIGNIN_START'
export const SIGNIN_SUCCESS = 'auth/SIGNIN_SUCCESS'
export const SIGNIN_FAIL = 'auth/SIGNIN_FAIL'

export const LOG_OUT_SUCCESS = 'auth/LOG_OUT_SUCCESS'

export const START_SIGNIN_OAUTH = 'auth/START_SIGNIN_OAUTH'
export const SIGNIN_SUCCESS_OAUTH = 'auth/SIGNIN_SUCCESS_OAUTH'
export const SIGNIN_FAIL_OAUTH = 'auth/SIGNIN_FAIL_OAUTH'

export const FORGOT_PASSWORD_SUCCESS = 'password/FORGOT_PASSWORD_SUCCESS'
export const RESET_PASSWORD_SUCCESS = 'password/RESET_PASSWORD_SUCCESS'

const initialState = {
	isAuthorized: Boolean(localStorage.getItem('gigAuthHeader')),
	loggingIn: false,
	user: null,
	loginError: null,
}

export default function auth(state = initialState, action = {}) {
	switch (action.type) {
		case START_SIGNIN:
			return {
				...state,
				loggingIn: true,
			}
		case SIGNIN_SUCCESS:
		case REGISTER_SUCCESS:
			setToken(action.result.data.token)

			return {
				...state,
				isAuthorized: true,
				loggingIn: false,
			}
		case SIGNIN_FAIL:
			return {
				...state,
				loggingIn: false,
				loginError: action.error,
			}
		case START_SIGNIN_OAUTH:
			return {
				...state,
				loggingIn: true,
			}
		case SIGNIN_SUCCESS_OAUTH:
			setToken(action.result.token)

			return {
				...state,
				isAuthorized: true,
				loggingIn: false,
			}
		case SIGNIN_FAIL_OAUTH:
			return {
				...state,
				loggingIn: false,
				loginError: action.error,
			}
		case LOG_OUT_SUCCESS:
			return {
				...state,
				isAuthorized: false,
			}
		default:
			return state
	}
}

export function signIn(data) {
	return {
		types: [START_SIGNIN, SIGNIN_SUCCESS, SIGNIN_FAIL],
		promise: client => client.post(`/${routes.login}`, data),
	}
}

export function forgotPassword(data) {
  return {
    types: [null, FORGOT_PASSWORD_SUCCESS, null],
    promise: client => client.post(`/${routes.forgotPassword}`, data),
  }
}

export function resetPassword(data) {
  return {
    types: [null, RESET_PASSWORD_SUCCESS, null],
    promise: client => client.post(`/${routes.resetPassword}`, data),
  }
}

export function signInOAuth(type) {
	return {
		types: [START_SIGNIN_OAUTH, SIGNIN_SUCCESS_OAUTH, SIGNIN_FAIL_OAUTH],
		promise: client => loginOAuth(type),
	}
}

export function checkLocalHeader() {
	return {
		types: [START_SIGNIN, SIGNIN_SUCCESS, SIGNIN_FAIL],
		promise: client =>
			new Promise((resolve, reject) => {
				if (localStorage.getItem('gigAuthHeader')) {
					resolve({ data: { token: localStorage.getItem('gigAuthHeader') } })
				} else {
					reject()
				}
			}),
	}
}

export function logout() {
	return {
		types: [null, LOG_OUT_SUCCESS, null],
		promise: client => client.post(`/${routes.logout}`).then(
      removeToken()
    ),
	}
}
