export const GET_ALL_CHATS_START = 'GET_ALL_CHATS_START'
export const GET_ALL_CHATS_SUCCESS = 'GET_ALL_CHATS_SUCCESS'
export const GET_ALL_CHATS_FAIL = 'GET_ALL_CHATS_FAIL'

export const GET_UNSEEN_MESSAGES_START = 'GET_UNSEEN_MESSAGES_START'
export const GET_UNSEEN_MESSAGES_SUCCESS = 'GET_UNSEEN_MESSAGES_SUCCESS'
export const GET_UNSEEN_MESSAGES_FAIL = 'GET_UNSEEN_MESSAGES_FAIL'

export const CREATE_NEW_CHAT_START = 'CREATE_NEW_CHAT_START'
export const CREATE_NEW_CHAT_SUCCESS = 'CREATE_NEW_CHAT_SUCCESS'
export const CREATE_NEW_CHAT_FAIL = 'CREATE_NEW_CHAT_FAIL'

export const GET_MESSAGES_FOR_CHAT_START = 'GET_MESSAGES_FOR_CHAT_START'
export const GET_MESSAGES_FOR_CHAT_SUCCESS = 'GET_MESSAGES_FOR_CHAT_SUCCESS'
export const GET_MESSAGES_FOR_CHAT_FAIL = 'GET_MESSAGES_FOR_CHAT_FAIL'

export const GET_NEW_MESSAGES_FOR_CHAT_START = 'GET_NEW_MESSAGES_FOR_CHAT_START'
export const GET_NEW_MESSAGES_FOR_CHAT_SUCCESS = 'GET__NEWMESSAGES_FOR_CHAT_SUCCESS'
export const GET_NEW_MESSAGES_FOR_CHAT_FAIL = 'GET_NEW_MESSAGES_FOR_CHAT_FAIL'

export const SEND_NEW_MESSAGE_FOR_CHAT_START = 'SEND_NEW_MESSAGE_FOR_CHAT_START'
export const SEND_NEW_MESSAGE_FOR_CHAT_SUCCESS = 'SEND_NEW_MESSAGE_FOR_CHAT_SUCCESS'
export const SEND_NEW_MESSAGE_FOR_CHAT_FAIL = 'SEND_NEW_MESSAGE_FOR_CHAT_FAIL'

export const GET_CHAT_INFO_START = 'GET_CHAT_INFO_START'
export const GET_CHAT_INFO_SUCCESS = 'GET_CHAT_INFO_SUCCESS'
export const GET_CHAT_INFO_FAIL = 'GET_CHAT_INFO_FAIL'

export const GET_ALL_CHATS_WITH_LAST_MSG_START = 'GET_ALL_CHATS_WITH_LAST_MSG_START'
export const GET_ALL_CHATS_WITH_LAST_MSG_SUCCESS = 'GET_ALL_CHATS_WITH_LAST_MSG_SUCCESS'
export const GET_ALL_CHATS_WITH_LAST_MSG_FAIL = 'GET_ALL_CHATS_WITH_LAST_MSG_FAIL'

export const GET_ALL_CHATS_BY_GIG_ID_START = 'GET_ALL_CHATS_BY_GIG_ID_START'
export const GET_ALL_CHATS_BY_GIG_ID_SUCCESS = 'GET_ALL_CHATS_BY_GIG_ID_SUCCESS'
export const GET_ALL_CHATS_BY_GIG_ID_FAIL = 'GET_ALL_CHATS_BY_GIG_ID_FAIL'

const initialState = {
	unseenInboxCount: 0,
  isGettingUnseen: false,
  unseenMessages: [],

	allChats: [],
	chat: {},
	messages: [],
	newMessages: [],
	isCreatingChat: false,
	isGettingChatsList: false,
	isGettingMessages: false,
	isGettingNewMessages: false,
	isSendingNewMessage: false,

	chatInfo: {},
	isGettingChatInfo: false,

  allChatsWithLastMessage: [],
  isGettingAllChatsWithLastMessage: false,

  allChatsByGigId: [],
  isGettingAllChatsByGigId: false,
};

export default function inbox(state = initialState, action = {}) {
	switch (action.type) {
		case GET_ALL_CHATS_START:
			return {
				...state,
				isGettingChatsList: true,
			}
		case GET_ALL_CHATS_SUCCESS:
			return {
				...state,
				isGettingChatsList: false,
				allChats: action.result.data,
			}
		case GET_ALL_CHATS_FAIL:
			return {
				...state,
				isGettingChatsList: false,
			}
		case GET_UNSEEN_MESSAGES_START:
			return {
				...state,
				isGettingUnseen: true,
			}
		case GET_UNSEEN_MESSAGES_SUCCESS:
			return {
				...state,
				isGettingUnseen: false,
                unseenInboxCount: action.result.data.length,
                unseenMessages: action.result.data
			}
		case GET_UNSEEN_MESSAGES_FAIL:
			return {
				...state,
				isGettingUnseen: false,
			}

		case CREATE_NEW_CHAT_START:
			return {
				...state,
				isCreatingChat: true,
			}
		case CREATE_NEW_CHAT_SUCCESS:
			return {
				...state,
				isCreatingChat: false,
			}
		case CREATE_NEW_CHAT_FAIL:
			return {
				...state,
				isCreatingChat: false,
			}

		case GET_MESSAGES_FOR_CHAT_START:
			return {
				...state,
				isGettingMessages: true,
			}
		case GET_MESSAGES_FOR_CHAT_SUCCESS:
			return {
				...state,
				messages: action.result.data,
				isGettingMessages: false,
			}
		case GET_MESSAGES_FOR_CHAT_FAIL:
			return {
				...state,
				isGettingMessages: false,
			}

		case GET_NEW_MESSAGES_FOR_CHAT_START:
			return {
				...state,
				isGettingNewMessages: true,
			}
		case GET_NEW_MESSAGES_FOR_CHAT_SUCCESS:
			return {
				...state,
				newMessages: action.result.data,
				isGettingNewMessages: false,
			}
		case GET_NEW_MESSAGES_FOR_CHAT_FAIL:
			return {
				...state,
				isGettingNewMessages: false,
			}

		case SEND_NEW_MESSAGE_FOR_CHAT_START:
			return {
				...state,
				isSendingNewMessage: true,
			}
		case SEND_NEW_MESSAGE_FOR_CHAT_SUCCESS:
			return {
				...state,
				isSendingNewMessage: false,
			}
		case SEND_NEW_MESSAGE_FOR_CHAT_FAIL:
			return {
				...state,
				isSendingNewMessage: false,
			}

		case GET_CHAT_INFO_START:
			return {
				...state,
				isGettingChatInfo: true,
			}
		case GET_CHAT_INFO_SUCCESS:
			return {
				...state,
				chatInfo: action.result.data,
				isGettingChatInfo: false,
			}
		case GET_CHAT_INFO_FAIL:
			return {
				...state,
				isGettingChatInfo: false,
			}

    case GET_ALL_CHATS_WITH_LAST_MSG_START:
      return {
        ...state,
        isGettingAllChatsWithLastMessage: true,
      }
    case GET_ALL_CHATS_WITH_LAST_MSG_SUCCESS:
      return {
        ...state,
        allChatsWithLastMessage: action.result.data,
        isGettingAllChatsWithLastMessage: false,
      }
    case GET_ALL_CHATS_WITH_LAST_MSG_FAIL:
      return {
        ...state,
        isGettingAllChatsWithLastMessage: false,
      }

    case GET_ALL_CHATS_BY_GIG_ID_START:
      return {
        ...state,
        isGettingAllChatsByGigId: true,
      }
    case GET_ALL_CHATS_BY_GIG_ID_SUCCESS:
      return {
        ...state,
        allChatsByGigId: action.result.data,
        isGettingAllChatsByGigId: false,
      }
    case GET_ALL_CHATS_BY_GIG_ID_FAIL:
      return {
        ...state,
        isGettingAllChatsByGigId: false,
      }

		default:
			return {
				...state,
			}
	}
}

export function createChat(gigId, userId) {
	return {
		types: [CREATE_NEW_CHAT_START, CREATE_NEW_CHAT_SUCCESS, CREATE_NEW_CHAT_FAIL],
		promise: client => client.post(`/api/chat/create?gigId=${gigId}&profileId=${userId}`),
	}
}

export function getMessages(chatId) {
	return {
		types: [GET_MESSAGES_FOR_CHAT_START, GET_MESSAGES_FOR_CHAT_SUCCESS, GET_MESSAGES_FOR_CHAT_FAIL],
		promise: client => client.get(`/api/chat/message?id=${chatId}&type=all`),
	}
}

export function getNewMessages(chatId) {
	return {
		types: [GET_NEW_MESSAGES_FOR_CHAT_START, GET_NEW_MESSAGES_FOR_CHAT_SUCCESS, GET_NEW_MESSAGES_FOR_CHAT_FAIL],
		promise: client => client.get(`/api/chat/message?id=${chatId}&type=new`),
	}
}

export function createMessage(chatId, message) {
	return {
		types: [SEND_NEW_MESSAGE_FOR_CHAT_START, SEND_NEW_MESSAGE_FOR_CHAT_SUCCESS, SEND_NEW_MESSAGE_FOR_CHAT_FAIL],
		promise: client => client.post(`/api/chat/message`, {
			id: chatId,
			message,
		}),
	}
}

export function getChatsList() {
	return {
		types: [GET_ALL_CHATS_START, GET_ALL_CHATS_SUCCESS, GET_ALL_CHATS_FAIL],
		promise: client => client.get(`/api/chat/list`),
	}
}

export function getChatsInfo(chatId, additional) {
	return {
		types: [GET_CHAT_INFO_START, GET_CHAT_INFO_SUCCESS, GET_CHAT_INFO_FAIL],
		promise: client => client.get(`/api/chat/info?id=${chatId}&with=${additional}`),
	}
}

export function refreshUnseenMessagesCount() {
	return {
		types: [GET_UNSEEN_MESSAGES_START, GET_UNSEEN_MESSAGES_SUCCESS, GET_UNSEEN_MESSAGES_FAIL],
		promise: client => client.get(`/api/chat/no-seen-message`),
	}
}

export function getAllChatsWithLastMessages() {
  return {
    types: [GET_ALL_CHATS_WITH_LAST_MSG_START, GET_ALL_CHATS_WITH_LAST_MSG_SUCCESS, GET_ALL_CHATS_WITH_LAST_MSG_FAIL],
    promise: client => client.get(`/api/chat/last-messages`),
  }
}

export function getAllChatsByGigId(gigId) {
  return {
    types: [GET_ALL_CHATS_BY_GIG_ID_START, GET_ALL_CHATS_BY_GIG_ID_SUCCESS, GET_ALL_CHATS_BY_GIG_ID_FAIL],
    promise: client => client.get(`/api/chat/list/${gigId}`),
  }
}
