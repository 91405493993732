import React, { Component } from 'react';
import { connect } from 'react-redux'
import * as _ from 'lodash'
import apiClient from '../../helpers/apiClient';
import './CancellationPolicy.scss';
import Loader from '../discover/Loader/Loader';

export const CANCELLATION_POLICY_ARTIST_TYPES = [
  {
    name: 'Easy Going',
    value: 'EASY_GOING',
    content: '<p>More than 15&nbsp;days notice: <b>Refund</b><p/>' +
      '<p>More than 7&nbsp;days notice, less than 15&nbsp;days: <b>50%&nbsp;refund with original payment method</b><p/>' +
      '<p>Less than 7&nbsp;days notice: <b>No&nbsp;refund</b></p>',
  },
  {
    name: 'Moderate',
    value: 'MODERATE',
    content: '<p>More than 30&nbsp;days notice: <b>Refund</b><p/>' +
      '<p>More than 15&nbsp;days notice, less than 30&nbsp;days: <b>50%&nbsp;refund with original payment method</b><p/>' +
      '<p>Less than 15&nbsp;days notice: <b>No&nbsp;refund</b></p>',
  },
  {
    name: 'Strict',
    value: 'STRICT',
    content: '<p>More than 60&nbsp;days notice: <b>Refund</b><p/>' +
      '<p>More than 30&nbsp;days notice, less than 60&nbsp;days: <b>50%&nbsp;refund with original payment method</b><p/>' +
      '<p>Less than 30&nbsp;days notice: <b>No&nbsp;refund</b></p>',
  },
  {
    name: 'No Refund policy',
    value: 'NO_REFUND',
    content: 'If user chooses this policy, once you book their services, payment is&nbsp;final and can not be&nbsp;refunded.',
  }
];

export const CANCELLATION_POLICY_VENUE_TYPES = [
  {
    name: 'Easy Going',
    value: 'EASY_GOING',
    content: '<p>More than 30&nbsp;days notice: <b>Full credit</b><p/>' +
      '<p>More than 15&nbsp;days notice, less than 30&nbsp;days: <b>50%&nbsp;refund with original payment method</b><p/>' +
      '<p>Less than 15&nbsp;days notice: <b>No&nbsp;refund</b></p>',
  },
  {
    name: 'Moderate',
    value: 'MODERATE',
    content: '<p>More than 60&nbsp;days notice: <b>Refund</b><p/>' +
      '<p>More than 30&nbsp;days notice, less than 60&nbsp;days: <b>50%&nbsp;refund with original payment method</b><p/>' +
      '<p>Less than 30&nbsp;days notice: <b>No&nbsp;refund</b></p>',
  },
  {
    name: 'Strict',
    value: 'STRICT',
    content: '<p>More than 90&nbsp;days notice: <b>Refund</b><p/>' +
      '<p>More than 60&nbsp;days notice, less than 90&nbsp;days: <b>50%&nbsp;refund with original payment method</b><p/>' +
      '<p>Less than 60&nbsp;days notice: <b>No&nbsp;refund</b></p>',
  },
  {
    name: 'No Refund policy',
    value: 'NO_REFUND',
    content: 'If user chooses this policy, once you book their services, payment is&nbsp;final and can not be&nbsp;refunded.',
  }
];

@connect(({ user }) => ({
  currentUser: user.currentUser
}))
export default class CancellationPolicy extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedType: props.selectedPolicy || null
    };
  }

  selectTypeCancellationPolicy = type => {
    this.setState({
      selectedType: type,
    });

    apiClient.put('/api/users/me', {
      cancellation_policy: type
    })
      .catch(console.error);
  };

  render() {
    const { currentUser } = this.props;
    console.log('currentuser', currentUser)
    if (!currentUser || _.isEmpty(currentUser)) return <div>
      <Loader />
    </div>

    const policies = currentUser.profile_type === 'venue' ?
      CANCELLATION_POLICY_VENUE_TYPES : CANCELLATION_POLICY_ARTIST_TYPES

    return (
      <div className="CancellationPolicy">
        <div className="CancellationPolicy__header">
          <b>Cancellation policy</b><br />
          <p>Please choose your cancellation policy for gigs when entering into a contract with a client.</p>
        </div>
        <div className="CancellationPolicy__content flex-column">
          {
            policies.map(item =>
              <div className="height-250 d-flex">
                <div className="CancellationPolicy__content_switcher">
                  <button
                    className={this.state.selectedType === item.value ? 'selected' : ''}
                    onClick={() => !this.props.locked && this.selectTypeCancellationPolicy(item.value)}>
                    Select
                  </button>
                </div>
                <div className="CancellationPolicy__content_type">
                  <h3>{item.name}</h3>
                  <p
                    className="content-value"
                    dangerouslySetInnerHTML={{ __html: item.content }} />
                </div>
              </div>
            )
          }
        </div>
        <div className="copyright" style={{ color: '#d3d3d3', paddingTop: '10px' }}>
          &copy; 2018 Gigworks
        </div>
      </div>
    );
  }
}
