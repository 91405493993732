import './ConfirmPopUp.scss';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import classNames from 'classnames';
import { block } from 'bem-cn';
import { Link } from 'react-router-dom';

const b = block('ConfirmPopUp');

export class ConfirmPopUp extends Component {

  static propTypes = {
    isOpen: PropTypes.bool,
    title: PropTypes.string,
    text: PropTypes.string,
    okBtn: PropTypes.shape({
      label: PropTypes.string,
      onClick: PropTypes.func,
      to: PropTypes.string
    }),
    cancelBtn: PropTypes.shape({
      label: PropTypes.string,
      onClick: PropTypes.func,
      to: PropTypes.string
    }),
    onClose: PropTypes.func
  };

  constructor(props) {
    super(props);
    this.state = {
      isOpen: false
    };
  }

  show = () => {
    this.setState({ isOpen: true });
  };

  hide = () => {
    this.setState({ isOpen: false });
  };

  UNSAFE_componentWillReceiveProps(newProps) {
    const { isOpen } = newProps;
    if (this.props.isOpen !== isOpen) {
      this.setState({ isOpen });
    }
  }

  render() {
    const { title, text, okBtn, cancelBtn, onClose } = this.props;
    let okButton, cancelButton;
    okButton = (okBtn && okBtn.to)
      ? <Link className="ok-btn" to={okBtn.to}>{okBtn.label}</Link>
      : <button className="ok-btn btn" onClick={okBtn ? okBtn.onClick : this.hide}>{okBtn.label}</button>;
    cancelButton = (cancelBtn && cancelBtn.to)
      ? <Link className="cancel-btn" to={cancelBtn.to}>{cancelBtn.label}</Link>
      : <button className="cancel-btn btn" onClick={cancelBtn ? cancelBtn.onClick : this.hide}>{cancelBtn.label}</button>;

    return (
      <div className={classNames(b())}>
        <Modal
          isOpen={this.state.isOpen}
          backdrop
          className={classNames(b('modal'))}
          onExit={onClose}
          toggle={this.hide}
        >
          <ModalHeader toggle={this.hide}>
            {title || `CONFIRM MESSAGE`}
          </ModalHeader>
          <ModalBody>
            {text}
          </ModalBody>
          <ModalFooter>
            {okButton}
            {cancelButton}
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}
