const validate = values => {
  const errors = {};
  if (!values.rating) {
    errors.rating = 'Required';
  }
  if (!values.title) {
    errors.title = 'Required';
  } else if (values.title.length > 155) {
    errors.title = 'Title cannot be longer then 155 characters';
  }
  if (!values.content) {
    errors.content = 'Required';
  } else if (values.content.length > 1000) {
    errors.content = 'Content cannot be longer then 1000 characters';
  }

  return errors;
};

export default validate;
