import React, { Component } from 'react';
import { connect } from 'react-redux'
import { Field } from 'redux-form';
import {
  TextFieldComponent,
  SelectFieldComponent
 } from '../../CommonComponent';
import { registrationConfig } from '../../../config';
import { Asterisk } from '../../common/Asterisk/Asterisk';

export default class QuestionsDropdown extends Component {

	constructor(props) {
    super(props);
    const { questions } = this.props;

    this.state = {
      questions: questions,
      originalQuestions: questions
    }
  }

  addNewQuestionField() {
    const {
      input: { value, onChange },
      questions
    } = this.props

    if (this.state.questions.length > 0) {
      const newField = {
        name: '',
        question: '',
        questions: questions,
        answer: '',
        isEntered: false
      }
      onChange([...value, newField])
    }
  }

  handleQuestionObjectChange = (questionObject, index, questionChoosen, previousQuestion) => {
		const {
			input: { value, onChange },
		} = this.props
    value[index] = questionObject

    if (questionChoosen) {
      if (previousQuestion) {
        this.changeQuestion(previousQuestion, questionObject.question)
      }
      else {
        this.removeQuestion(questionObject.question);
      }
    }

		onChange([...value])
		this.forceUpdate()
  }

  handleRemoveAnsweredQuestions = (answeredQuestions) => {
    const toRemove = [];
    answeredQuestions.map((question, index) => {
      toRemove.push(question.question);
    });
    let newArr = this.state.questions.filter(item =>
      toRemove.indexOf(item.value) === -1
    );

    this.setState({
      questions: newArr
    });
  }

  removeQuestion(questionValue) {
    var array = [...this.state.questions];
    this.setState({questions: array.filter(function(q) {
        return q.value !== questionValue
    })});
  }

  restoreQuestion(questionValue) {
    var array = [...this.state.questions];
    var foundQuestion = this.state.originalQuestions.filter(function(e) {
      return e.value == questionValue;
    });
    if (foundQuestion.length > 0) {
      array.push(foundQuestion[0]);
      this.setState({
        questions: array
      });
    }
  }

  changeQuestion(prevQuestion, currQuestion) {
    var array = [...this.state.questions];
    var foundQuestion = this.state.originalQuestions.filter(function(e) {
      return e.value == prevQuestion;
    });
    if (foundQuestion.length > 0) {
      array.push(foundQuestion[0]);
      this.setState({
        questions: array
                    .filter(q => q.value !== currQuestion )
                    .sort((a,b) => a.value-b.value)
      });
    }
    this.forceUpdate()
  }

  handleRemove = (questionObject, index) => {
    this.restoreQuestion(questionObject.question);
    const {
			input: { value, onChange },
		} = this.props
    var index = value.indexOf(questionObject);
    if (index > -1) {
      value.splice(index, 1);
    }
		onChange([...value])
		this.forceUpdate()
  }

  render() {
    // value is array of questions_answers
    const {
      input: { value },
      label
    } = this.props

    return (
      <div className="service-info-form__item">
        <div className="service-info-form__item-header">
          <span className="fa-stack info-popup-window" data-title={registrationConfig.popupText.artistAboutSection}>
            <i className="fa fa-circle-thin fa-stack-2x"/>
            <i className="fa fa-info fa-stack-1x"/>
          </span>
          <h4 className="service-info-form__title">
            {label ? label : 'About you'} <Asterisk /><span className="description">(Max. 800 characters per question)</span>
          </h4>
        </div>
        {value && value.map((question, index) => (
            <QuestionComponent
              questions={this.state.questions}
              question={question}
              key={index}
              index={index}
              onQuestionChange={this.handleQuestionObjectChange}
              onRemove={this.handleRemove}
              answeredQuestions={value}
              onRemoveAnsweredQuestions={this.handleRemoveAnsweredQuestions}
              originalQuestions={this.state.originalQuestions}
            />
        ))}
        { this.state.questions.length > 0 ?
          <p
            style={{
              marginTop: '1rem',
              cursor: 'pointer'
            }}
            onClick={() => this.addNewQuestionField()}
          >
            <i className="fa fa-plus-circle pr-1" aria-hidden="true"></i> Answer next question
          </p>
        :
          null
        }
      </div>
    );
  }
}

@connect(
	({ globalConfig }) => ({
		isMobile: globalConfig.isMobile,
	})
)
export class QuestionComponent extends Component {
  constructor(props) {
    super(props);
    const {
      question,
      onRemoveAnsweredQuestions,
      answeredQuestions
    } = this.props;
    this.state = {
      displayedQuestion: question.question,
      //props: props
    };
    if (question.isEntered) {
      onRemoveAnsweredQuestions(answeredQuestions);
    }
  }

  // UNSAFE_componentWillReceiveProps(nextProps) {
  //   this.setState({...this.state, nextProps})
  // }

  changeField = (value, fieldName) => {
    const { question, index, onQuestionChange } = this.props;
    var toChange = {...question}
    //if choose question, then disable question changing and remove chosen question from questionList
    if (fieldName == 'question') {
      toChange = {
        ...question,
        [fieldName]: value,
        isEntered: true
      }
      let displayedQuestion = this.state.displayedQuestion
      onQuestionChange(toChange, index, true, displayedQuestion)
      this.setState({ displayedQuestion: value })
    }
    else {
      toChange = {
        ...question,
        [fieldName]: value,
      }
      onQuestionChange(toChange, index)
    }
  }

  remove = () => {
    const { question, index, onRemove } = this.props;
    onRemove(question, index);
  }

  render() {
    const { question, index, questions, originalQuestions, isMobile } = this.props;

    var array = [...questions];
    if(question.question) {
      array.push(originalQuestions.find(item => item.value === question.question))
    }
    var innerQuestions = array.sort((a,b) => a.value-b.value);

    const divStyle = {
      width: 'calc(100% - 20px)',
      fallbacks: [
        { width: '-moz-calc(100% - 20px)' },
        { width: '-webkit-calc(100% - 20px)' },
        { width: '-o-calc(100% - 20px)' },
      ],
      height: '3rem',
      float: 'left'
    };

    return (
      <div>
        <Field
          name="question"
          type="string"
          autoWidth
          style={divStyle}
          labelStyle={{ fontSize: '1rem', color: '#000', padding: '0', maxWidth: '90%' }}
          options={innerQuestions}
          input={{
            value: question.question,
            onChange: val => this.changeField(val, 'question')
          }}
          hintText="Select question"
          component={SelectFieldComponent}
        />
        { index == 0 ? null : <p onClick={this.remove} style={{ float: 'right', marginTop: '0.7rem' }}><i className="fa fa-times" aria-hidden="true"></i></p> }
        <TextFieldComponent
          input={{
            value: question.answer,
            onChange: val => this.changeField(val, 'answer')
          }}
          type="text"
          name="answer"
          maxLength="800"
          multiLine={true}
          style={ isMobile ? { margin: '-10px 0 5px', lineHeight: 1.4 } : { margin: '10px 0 5px', lineHeight: 1.4 }}
          fullWidth={true}
          hintText="Start writing here"
      />
      </div>
    );
  }
}
