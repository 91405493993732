import React, { Component } from 'react'
import { connect } from 'react-redux'
import { createSelector } from 'reselect'
import { get, reduce } from 'lodash'
import TreeSelect from 'antd/es/tree-select'
import { Container } from 'reactstrap'
import './PrimaryGigFeed.scss'
import Footer from '../../../Footer/Footer'
import { SortBar } from '../../../common/SortBar/SortBar'
import Loader from '../../Loader/Loader';

import { getParentCategories, getChildrenCategories, getCategoryTree } from '../../../../redux/modules/common'
import {defaultFontSize} from "../../../../utils/constants";
import notification from "antd/es/notification";
import {IconButton} from "material-ui";
import SVGInline from "react-svg-inline";
import {translate} from "../../../../translator";

const ARTIST_CATEGORY_NAMES = ['Visual Arts', 'Music', 'Performing Arts', 'Production']
const VENUE_CATEGORY_NAMES = ['Venues']
const DESCRIPTION_CATEGORY = {
	artistCategories: "You've got talent, we already know that. <b>Put it to good use and apply for gigs.</b>",
	venueCategories: "Got space people want? <b>Let them know it's available.</b>",
}

const svg_arrow_left = require('!!svg-inline-loader?classPrefix!../../../../assets/icons/left-arrow.svg')

export const IMAGES_CATEGORY = {
	artistCategories: {
		'Visual Arts': require('../../../../assets/images/gigfeed/artist-visual-arts.jpeg'),
		Music: require('../../../../assets/images/gigfeed/artist-music.jpeg'),
		'Performing Arts': require('../../../../assets/images/gigfeed/artist-perfoming-arts.jpeg'),
		'Pre-production': require('../../../../assets/images/gigfeed/artist-pre-production.jpg'),
		Production: require('../../../../assets/images/gigfeed/artist-production.jpeg'),
		'Post-production': require('../../../../assets/images/gigfeed/artist-post-production.jpg'),
		Occasions: require('../../../../assets/images/gigfeed/artist-occasion.jpeg'),
		"Artist Occasions": require('../../../../assets/images/gigfeed/artist-occasion.jpeg'),
	},
	venueCategories: {
		Types: require('../../../../assets/images/gigfeed/venue-type.jpeg'),
		Occasions: require('../../../../assets/images/gigfeed/venue-occasion.jpeg'),
	},
}

@connect(
	state => ({
		superCategories: superCategoriesSelector(state),
		categoriesMap: state.common.categoriesMap,
		categories: state.common.categories.all,
    categoryTree: state.common.categoryTree,
    isGettingCategoryTree: state.common.isGettingCategoryTree,
    parentCategories: state.common.parentCategories,
		childrenCategories: state.common.childrenCategories,
    isMobile: state.globalConfig.isMobile,
  }),
	{
		getParentCategories,
		getChildrenCategories,
    getCategoryTree
	}
)
export default class PrimaryGigFeed extends Component {
  constructor(props) {
    super(props)

    this.state = {
      showMore: false
    }
  }

	componentDidMount() {
		if (!this.props.parentCategories.length) {
			this.props.getParentCategories().then(res => {
				res.data.map(cat => this.props.getChildrenCategories(cat.id))
			})
		}

    if(this.props.categoryTree.length === 0) {
      this.props.getCategoryTree();
    }
	}

	componentWillUnmount() {}

  searchTree(element, matchingValue) {
    if (element.value == matchingValue) {
      return element;
    }
    else if (element.children != null) {
      let i;
      let result = null;
      for (i = 0; result == null && i < element.children.length; i++) {
        result = this.searchTree(element.children[i], matchingValue);
      }

      return result;
    }

    return null;
  }

  getNode = (value) => {
    let node = null;
    _.forOwn(this.props.categoryTree, (val, key) => {
      const element = this.searchTree(val, value);
      if (element) {
        node = element;
        return element;
      }
    });

    return node;
  };

  onChange = (value) => {
    const node = this.getNode(value);
    if (node.clickable) {

      let url = '';
      if (node.tree_path.length > 1) {
        const mainCategory = this.props.categoriesMap[node.parent_id];
        const mainCategoryName = mainCategory.name.replace(/\s/g, '');

        const categoryName = node.title.replace(/\s/g, '');
        url = `${mainCategoryName === 'Occasions' ? 'occasions' : mainCategoryName}/${categoryName}`;
      } else {
        const categoryName = node.title.replace(/\s/g, '');
        url = `${categoryName === 'Occasions' ? 'occasions' : categoryName}`;
      }

      this.props.history.push(`/discover/GigFeed/${url}`);

      return true;
    }

    value = false;
    return value;
  };

  showMoreCategories = () =>
    this.setState(prevState => ({
      showMore: !prevState.showMore,
    }))

  getChildCategoriesByParentName = (parentName) => {
    const { parentCategories, childrenCategories } = this.props;

    const parentCategory = parentCategories.find(parentCategory => parentCategory.service_name === parentName);

    if (parentCategory && Object.keys(parentCategory).length === 0 && parentCategory.constructor === Object) {
      return [];
    }

    return childrenCategories.filter(cat => cat.parent_category_id === parentCategory.id);
  }

  getArtistChildCategories = () => {
    const { showMore } = this.state;
    const { isMobile } = this.props;
    const childrenCategories = this.getChildCategoriesByParentName('artists');

    if (isMobile) {
      return childrenCategories;
    }

    const removeCategoryName = showMore ? 'Visual Arts' : 'Artist Occasions';

    return childrenCategories.filter(category => category.name !== removeCategoryName);
  }

	render() {
		const { parentCategories, isGettingCategoryTree, categoryTree, isMobile } = this.props;
		const { showMore } = this.state;

		const artistChildCategories = this.getArtistChildCategories().sort((a, b) => a.id - b.id);
		const venueChildCategories = this.getChildCategoriesByParentName('venues').sort((a, b) => a.id - b.id);

		const childrenCategories = [...venueChildCategories, ...artistChildCategories];

		return (
			<div className="gig-feed-primary">
				{parentCategories.map((category, idx) => (
					<div key={idx} className={category.service_name === 'artists' ? 'ArtistCategory' : 'VenueCategory'}>
						<Container className={`d-flex ${isMobile ? ' flex-wrap' : null}`}>
							<div className="information">
                <h3>{`${category.name} Gigs`}</h3>
                <p className="description" dangerouslySetInnerHTML={{ __html: category.description }} />
                <div>
                  {!isGettingCategoryTree ?
                    <TreeSelect
                      showSearch
                      className="ability-tree-gig-feed"
                      dropdownClassName="ability-tree-dropdown"
                      style={{ ...defaultFontSize, width: '100%' }}
                      dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                      treeData={categoryTree[category.id] ? categoryTree[category.id]['children'] : []}
                      placeholder={translate('discover.gigFeedArtistsGigsDropDown')}
                      treeDefaultExpandAll={true}
                      onChange={this.onChange}
                    />
                    :
                    <div
                      className="d-flex justify-content-center">
                      <Loader />
                    </div>
                  }
                </div>
							</div>
              {
                category.service_name === 'artists' && !isMobile ?
                  <div className="arrow-icon-button">
                    <IconButton onClick={this.showMoreCategories} disabled={!showMore}>
                      <SVGInline
                        svg={svg_arrow_left}
                        fill="#000"
                        width='18px'
                      />
                    </IconButton>
                  </div> :
                  !isMobile ? <div style={{width: '48px'}}></div> : null
              }
							{childrenCategories.filter(cat => cat.parent_category_id === category.id).map((subCategory, idx) => (
								<div
                  key={idx}
									onClick={() => {
                    const categoryName = subCategory.name.replace(/\s/g, '');
                    this.props.history.push(
                      `/discover/GigFeed/${subCategory.name === 'Occasions' ? 'occasions' : categoryName}`
                    )
                  }}
                  className="category-item"
								>
									<div className="category-item__card">
										<div className="full-width">
											<h5>{subCategory.name}</h5>
											<p>
												{subCategory.tags} <i className="fa fa-camera" />
											</p>
										</div>
										<div className="photo">
											<img src={subCategory.img_url} alt="" />
										</div>
									</div>
								</div>
							))}
              {
                category.service_name === 'artists' && !isMobile ?
                  <div className="arrow-icon-button" style={{ order: 6 }}>
                    <IconButton
                      onClick={this.showMoreCategories}
                      disabled={showMore}
                      style={{ transform: 'rotate(180deg)' }}
                    >
                      <SVGInline
                        svg={svg_arrow_left}
                        fill="#000"
                        width='18px'
                      />
                    </IconButton>
                  </div> :
                  !isMobile ? <div style={{width: '48px'}}></div> : null
              }
						</Container>
					</div>
				))}
				<Footer
					media={{
						type: 'video',
						src: 'https://player.vimeo.com/video/230747958',
						content_url: 'https://www.youtube.com/watch?v=l1u1_ws6TBg&feature=youtu.be',
						clapQuantity: 0,
						commentQuantity: 0,
						shareQuantity: 0,
						tags: [],
						location: '',
					}}
					type={'home'}
				/>
			</div>
		)
	}
}

const venueOccasion = {
	name: 'Occasions',
	id: 'occasions',
}

const superCategoriesSelector = createSelector(
	state => get(state, 'common.categories.all', []),
	categories =>
		reduce(
			categories,
			(acc, category) => {
				if (category.is_parent) {
					if (ARTIST_CATEGORY_NAMES.includes(category.name)) acc.artistCategories.push(category)
					else if (VENUE_CATEGORY_NAMES.includes(category.name)) acc.venueCategories.push(category)
				}
				return acc
			},
			{ artistCategories: [], venueCategories: [venueOccasion] }
		)
)
