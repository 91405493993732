import React, { Component } from 'react';
import { Footer } from './index';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import { TextFieldComponent } from './CommonComponent';
import { RadioComponent } from './register/CommonComponent';
import validate from '../helpers/validators/ContactForm';
import RaisedButton from 'material-ui/RaisedButton';
import '../styles/components/ContactForm.scss';
import { translate } from '../translator';

@connect(({user, form}) => ({
  currentUser: user.currentUser,
}))
@reduxForm({
  form: 'ContactForm',
  destroyOnUnmount: true,
  forceUnregisterOnUnmount: true,
  enableReinitialize: false,
  validate,
})
export default class ContactForm extends Component {
  textHintStyle = {
    fontSize: '1.5rem',
    fontWeight: '300',
  }

  textInputStyle = {
    fontSize: '1.5rem',
    fontWeight: '300',
  }
  handleSubmit = (values)=>{
    const { submitHandler } = this.props;
    submitHandler(values);
  };

  render() {
    const { handleSubmit } = this.props;

    const media = {
      type: 'video',
      src: 'https://player.vimeo.com/video/230747958',
      content_url: 'https://www.youtube.com/watch?v=l1u1_ws6TBg&feature=youtu.be',
      clapQuantity: 0,
      commentQuantity: 0,
      shareQuantity: 0,
      tags: [],
      location: '',
    };
    
    return (
      <div className="static-pages">
        <div className="static-pages-header static-pages-header__contact">
          <div className="container">
            <h1 className="static-pages-header--header">{translate('contactfrom.header')}</h1>
            <p className="static-pages-header--description" dangerouslySetInnerHTML={{ __html: translate('contactfrom.headerDescription') }}/>
          </div>
        </div>
        <div className="contact-form container-fluid">
          <form className="row justify-content-center" onSubmit={handleSubmit((data)=> this.handleSubmit(data))}>
            <div className="col-lg-6 col-sm-8 col-10">
              <div className="row form-item justify-content-center">
                <div className="col-sm-6 col-12">
                  <h5>{translate('contactfrom.name')}<span style={{ color: 'red' }}>* </span></h5>
                  <Field
                    fullWidth
                    name="first_name"
                    hintStyle={this.textHintStyle}
                    inputStyle={this.textInputStyle}
                    hintText={translate('contactfrom.firstName')}
                    component={TextFieldComponent}/>
                </div>
                <div className="col-sm-6 col-12">
                  <h5 style={{opacity: '0'}}>{translate('contactfrom.name')}</h5>
                  <Field
                    name="last_name"
                    fullWidth
                    hintStyle={this.textHintStyle}
                    inputStyle={this.textInputStyle}
                    hintText={translate('contactfrom.firstName')}
                    component={TextFieldComponent}
                  />
                </div>
              </div>
              <div className="row form-item justify-content-center">
                <div className="col-sm-6 col-12">
                  <h5>{translate('contactfrom.city')}</h5>
                  <Field
                    name={translate('contactfrom.city')}
                    fullWidth
                    hintStyle={this.textHintStyle}
                    inputStyle={this.textInputStyle}
                    hintText={translate('contactfrom.typeHere')}
                    component={TextFieldComponent}
                  />
                </div>
                <div className="col-sm-6 col-12">
                  <h5>{translate('contactfrom.country')}</h5>
                  <Field
                    name="country"
                    fullWidth
                    hintStyle={this.textHintStyle}
                    inputStyle={this.textInputStyle}
                    hintText={translate('contactfrom.typeHere')}
                    component={TextFieldComponent}
                  />
                </div>
              </div>
              <div className="row form-item justify-content-center">
                <div className="col-12">
                  <h5>{translate('contactfrom.email')} <span style={{ color: 'red' }}>* </span></h5>
                  <Field
                    name="email"
                    fullWidth
                    hintStyle={this.textHintStyle}
                    inputStyle={this.textInputStyle}
                    hintText={translate('contactfrom.typeHere')}
                    component={TextFieldComponent}
                  />
                </div>
              </div>
              <div className="row form-item justify-content-center">
                <div className="col-sm-6 col-12">
                  <h5>{translate('contactfrom.phone')}</h5>
                  <Field
                    name="phone_number"
                    fullWidth
                    hintStyle={this.textHintStyle}
                    inputStyle={this.textInputStyle}
                    hintText={translate('contactfrom.typeHere')}
                    component={TextFieldComponent}
                  />
                </div>
                <div className="col-sm-6 col-12">
                  <h5>{translate('contactfrom.company')}</h5>
                  <Field
                    name="company"
                    fullWidth
                    hintStyle={this.textHintStyle}
                    inputStyle={this.textInputStyle}
                    hintText={translate('contactfrom.typeHere')}
                    component={TextFieldComponent}
                  />
                </div>
              </div>
              <div className="row form-item justify-content-center">
                <div className="col-12">
                  <h5>{translate('contactfrom.reasonContact')}<span style={{ color: 'red' }}>* </span></h5>
                  <Field
                    name="reason"
                    fullWidth
                    required={true}
                    hintStyle={this.textHintStyle}
                    inputStyle={this.textInputStyle}
                    hintText={translate('contactfrom.typeHere')}
                    component={TextFieldComponent}
                  />
                </div>
              </div>
              <div className="row form-item">
                <div className="col-12">
                  <div>
                    <Field
                      name="interested_as"
                      groupName={translate('contactfrom.iAm')}
                      required={true}
                      component={RadioComponent}
                      optionNames={[
                        'Artist', 'Band', 'Group', 'Venue',
                        'Client', 'Fan', 'Investor', 'Partner', 'Other'
                      ]}/>
                  </div>
                </div>
              </div>
              <div className="row form-item">
                <div className="col-12">
                  <div>
                    <Field
                      name="registered_user"
                      groupName={translate('contactfrom.areYou')}
                      required={true}
                      component={RadioComponent}
                      optionNames={['Yes', 'No']}/>
                  </div>
                </div>
              </div>
              <div className="row form-item">
                <div className="col-12">
                  <h5>{translate('contactfrom.yourMessage')}<span style={{ color: 'red' }}>* </span></h5>
                  <div className="message-wrapper">
                    <Field
                      name="message"
                      fullWidth
                      required={true}
                      hintStyle={{...this.textHintStyle, position: 'absolute', top: '0.7rem', left: '0'}}
                      inputStyle={this.textInputStyle}
                      multiLine={true}
                      underlineShow={false}
                      rows={6}
                      hintText={translate('contactfrom.typeHere')}
                      component={TextFieldComponent}/>

                    <RaisedButton
                      label={translate('contactfrom.submit')}
                      type="submit"
                      labelStyle={{color: '#000', fontWeight: '600', textTransform: 'none', fontSize: '17px'}}
                      overlayStyle={{padding: '0.5rem 4.25rem', height: 'auto'}}
                      buttonStyle={{height: 'auto', backgroundColor: '#f3f3f3'}}
                      style={{
                        position: 'absolute', 
                        bottom: '-2rem', 
                        right: '2rem', 
                        border: '1px solid #c4c4c4', 
                        borderRadius: '0px',
                        boxShadow: 'none'
                      }}/>
                  </div>
                </div>
              </div>
              <div className="row form-item">
                <div className="col-12 bottom-description"dangerouslySetInnerHTML={{ __html: translate('contactfrom.address') }}/>
              </div>
            </div>
          </form>
          <img className="static-pages-content--investors-img" src={require('../assets/images/red-diamonds.png')} alt=""/>
        </div>
        <div>
          <Footer media={media} type={'home'}/>
        </div>
      </div>
    );
  }
}
