import React from 'react'
import { Modal as ReactstrapModal, ModalBody, ModalHeader } from 'reactstrap';
import CloseButton from '../CloseButton/CloseButton'

const PADDING_PX = 40

/** Now its only wrapper on Reactstrap modal */
const Modal = ({ 
    isOpen,
    children,
    onClose,
    dialogStyles,
    closeButtonStyle,
    innerStyle,
    noAccidentallyClose,
    ...restProps
}) => {
    return (<ReactstrapModal 
        isOpen={isOpen}
        onClose={onClose}
        toggle={onClose}
        style={{ ...dialogStyles }}
        backdrop={noAccidentallyClose ? 'static' : true}
        {...restProps}
        >
            <ModalBody style={{ padding: PADDING_PX, ...innerStyle }}>
                { !noAccidentallyClose && <CloseButton
                    onClick={onClose}
                    style={{ position: 'absolute', right: PADDING_PX / 2 , top: PADDING_PX / 2 }}
                />}
                {children}
            </ModalBody>
    </ReactstrapModal>)
}

export default Modal
