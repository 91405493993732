import React, {Component} from 'react';
import {Field, FieldArray, reduxForm} from 'redux-form';
import validate from '../../../helpers/validators/company/CompanyInfo';
import { connect } from 'react-redux';
import { getValues } from '../../../helpers/methods';
import { Interests, Industries } from '../CommonComponent';
import { TextFieldComponent } from '../../CommonComponent';
import { Asterisk } from '../../common/Asterisk/Asterisk';
import { registrationConfig } from '../../../config';
import {MAX_BRIEF_INTRODUCTION_LENGTH} from "../../../utils/constants";

class CompanyInfo extends Component{
  render(){
    const { handleSubmit } = this.props;

    const maxLength = max => value => {

      let v;
      let result = value.length > max;

      if(result === false) {
        v = value;
      }
      return v;
    };

    return (
      <form className="service-info-form" onSubmit={handleSubmit}>
        <div className="service-info-form__wrapper">
          <div className="service-info-form__item-header">
            <span className="fa-stack info-popup-window" data-title={registrationConfig.popupText.companyBriefIntroduction}>
              <i className="fa fa-circle-thin fa-stack-2x"/>
              <i className="fa fa-info fa-stack-1x"/>
            </span>
            <h4 className="service-info-form__title">
              Brief introduction <Asterisk /><span className="description">(Max. 155 characters)</span>
            </h4>
          </div>
          <Field
            hintText="Start writing here"
            name='brief_introduction'
            inputStyle={{ color: '#000' }}
            style={{margin: '10px 0 5px'}}
            fullWidth={true}
            normalize={maxLength(MAX_BRIEF_INTRODUCTION_LENGTH)}
            component={TextFieldComponent}/>
        </div>
        <div className="service-info-form__wrapper">
          <div className="service-info-form__item-header">
            <span className="fa-stack info-popup-window" data-title={registrationConfig.popupText.companyAboutYou}>
              <i className="fa fa-circle-thin fa-stack-2x"/>
              <i className="fa fa-info fa-stack-1x"/>
            </span>
            <h4 className="service-info-form__title">
              About section <Asterisk /><span className="description">(Max. 1000 characters)</span>
            </h4>
          </div>
          <Field
            hintText="Tell us more about your company and what you do"
            name='about'
            style={{margin: '10px 0 5px'}}
            multiLine={true}
            fullWidth={true}
            component={TextFieldComponent}/>
        </div>
        <div className="service-info-form__wrapper">
          <div className="service-info-form__item-header">
            <span className="fa-stack info-popup-window" data-title={registrationConfig.popupText.companySelectYourInterest}>
              <i className="fa fa-circle-thin fa-stack-2x"></i>
              <i className="fa fa-info fa-stack-1x"></i>
            </span>
            <h4 className="service-info-form__title">
              Select your industry <span className="description">(Select up to 5)</span>
            </h4>
          </div>
          <Field name="industries" component={Industries} />
        </div>
        <div className="service-info-form__wrapper">
          <div className="service-info-form__item-header">
            <span className="fa-stack info-popup-window" data-title={registrationConfig.popupText.companySelectYourInterest}>
              <i className="fa fa-circle-thin fa-stack-2x"></i>
              <i className="fa fa-info fa-stack-1x"></i>
            </span>
            <h4 className="service-info-form__title">
              Select your interests
            </h4>
          </div>
          <Field
            name='categories'
            component={Interests}/>
        </div>
      </form>
    );
  }
}

CompanyInfo = reduxForm({
  form: 'CompanyInfo',
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: false,
  enableReinitialize: true,
  validate
})(CompanyInfo);

//TODO fields
export default connect(
  ({ user, form }) => (
    {
      currentUser: user.currentUser,
      initialValues: getValues(form.CompanyInfo, user.currentUser && user.currentUser.profile? {
        brief_introduction: user.currentUser.profile.brief_introduction,
        about: user.currentUser.profile.about,
        industries: user.currentUser.industries.map(x => x.id) || [],
        categories: user.currentUser.categories || [],
      } : {})
    })
)(CompanyInfo);
