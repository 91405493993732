import React from 'react';
import { Row, Col } from 'reactstrap';
import {connect} from 'react-redux';


import Collapse from 'antd/es/collapse'
import 'antd/es/collapse/style/css'
import DirectDebitForm from '../../DashBoard/Common/DirectDebitForm/DirectDebitForm';
import { IconCircle } from '../../CommonComponent';
import SelectCardForm from '../../DashBoard/Common/SelectCardForm/SelectCardForm';
import SelectBankAccountForm from '../../DashBoard/Common/SelectBankAccountForm/SelectBankAccountForm';
const Panel = Collapse.Panel;

/**
 * Typical button
 * todo: add large size, outlined
 * @param {object} paymentInfo
 * @param {string} selectedPaymentMethod - CARD / DIRECT_DEBIT / BANK_TRANSFER
 * @param {string, node} label button label/text
 * @event {onChange} give { selectedPAymentMethod, paymentInfo }
 */
@connect(({ billing }) => ({
    transfer: billing.transfer,
}))
class PaymentMethodSelect extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            openedPanel: null
        }
    }

    handleChange = ({
        selectedPaymentMethod,
        paymentInfo = null,
    }) => {
        const { onChange } = this.props;

        onChange && onChange({
            selectedPaymentMethod,
            paymentInfo: selectedPaymentMethod === 'BANK_TRANSFER' ? true : paymentInfo
        })
    }

    getPanelIcon = isActive => <IconCircle
            isActive={isActive}
            large
            className="mr-3"
        />

    render() {
        const { selectedPaymentMethod, paymentInfo, noBankTransfer, alwaysSave, disableCreditCard = false } = this.props;
        const { openedPanel } = this.state;

        return <Row className="PaymentSteps">
                <Col style={{ display: 'flex', justifyContent: 'center' }}>
                    <Collapse
                        accordion
                        bordered={false}
                        style={{ width: '100%' }}
                        onChange={key => {
                            this.setState({ openedPanel: key });
                            this.handleChange({ selectedPaymentMethod: key });
                        }}
                    >
                        <Panel
                            header={<PanelHeaderContent>
                                {this.getPanelIcon(openedPanel === 'CARD')}
                                <span className="mr-3">Credit or Debit Card</span>
                                <i className="pf pf-visa" />
                                <i className="pf pf-mastercard" />
                                <i className="pf pf-discover" />
                                <i className="pf pf-american-express" />
                            </PanelHeaderContent>}
                            key="CARD"
                            showArrow={false}
                            disabled={disableCreditCard}
                        >
                            <SelectCardForm
                                value={paymentInfo}
                                alwaysSave={alwaysSave}
                                onChange={paymentInfo => this.handleChange({
                                    selectedPaymentMethod: 'CARD',
                                    paymentInfo,
                                    save: alwaysSave ? true : undefined
                                })}
                            />
                        </Panel>

                        <Panel
                          disabled={true}
                            header={<PanelHeaderContent>
                                {this.getPanelIcon(openedPanel === 'DIRECT_DEBIT')}
                                Direct Debit
                            </PanelHeaderContent>}
                            key="DIRECT_DEBIT"
                            showArrow={false}>
                            <SelectBankAccountForm
                                value={paymentInfo}
                                alwaysSave={alwaysSave}
                                onChange={paymentInfo => { this.handleChange({
                                    selectedPaymentMethod: 'DIRECT_DEBIT',
                                    paymentInfo,
                                    save: alwaysSave ? true : undefined
                               }) }}
                            />

                        </Panel>

                        {
                            !noBankTransfer && <Panel
                              disabled={true}
                                header={<PanelHeaderContent>
                                    {this.getPanelIcon(openedPanel === 'BANK_TRANSFER')}
                                    Bank Transfer
                                </PanelHeaderContent>}
                                key="BANK_TRANSFER"
                                showArrow={false}>
                            </Panel>
                        }
                    </Collapse>
                </Col>
            </Row>
    }
}

const PanelHeaderContent = ({ children }) => <div
    style={{
        display: 'flex',
        alignItems: 'center'
    }}>
    {children}
</div>

export default PaymentMethodSelect
