import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { handleResize } from '../redux/modules/globalConfig'
import { checkLocalHeader, logout } from '../redux/modules/auth'

import { getCurrentUser, getCurrentProfile } from '../redux/modules/user'
import { getCountriesList } from '../redux/modules/common'
import {get} from "lodash";
import {Cookies} from "react-cookie";

const domain = process.env.REACT_APP_URL;

@connect(
	({ common, user, auth }) => ({
		countriesList: common.countriesList,
    isAuthorized: auth.isAuthorized,
    currentUser: auth.isAuthorized ? get(user, 'currentUser', null) : null,
    statisticCookies: auth.isAuthorized ? get(user, 'currentUser.cookies_settings.statistics', null) : null,
	}),
	{
		handleResize,
		checkLocalHeader,
		getCurrentUser,
		getCurrentProfile,
		getCountriesList,
    logout,
	}
)
class WrapperComponent extends Component {
	constructor(props) {
		super(props)
		window.onerror = this.onErrorHandler
	}

	UNSAFE_componentWillMount() {
		this.props
			.checkLocalHeader()
			.then(() => this.props.getCurrentUser())
			.then(res => this.props.getCurrentProfile(res.data.id))
			.catch(() => {
        this.props.logout();
			})

		if (this.props.countriesList.lenght === 0) {
			console.log('Get countries list')
			this.props.getCountriesList()
		}

		// this.props.getCountriesList();

		window.addEventListener('resize', e => this.props.handleResize(window.innerWidth))
		this.props.handleResize(window.innerWidth)
	}

	onErrorHandler = (errorMsg, url, lineNumber, column, errorObj) => {
		console.error(
			'Error: ' +
				errorMsg +
				' Script: ' +
				url +
				' Line: ' +
				lineNumber +
				' Column: ' +
				column +
				' StackTrace: ' +
				errorObj
		)

		// window.alert('Error: ' + errorMsg + ' Script: ' + url + ' Line: ' + lineNumber
		//   + ' Column: ' + column + ' StackTrace: ' +  errorObj)
	}

	render() {
    const { isAuthorized, statisticCookies, location } = this.props;

    if (isAuthorized && statisticCookies !== null && !statisticCookies) {
      const cookies = new Cookies();
      cookies.remove("_ga", {path: location.pathname, domain: domain})
      cookies.remove("_gid", {path: location.pathname, domain: domain})
    }

		return <div id="root-wrapper">{this.props.children}</div>
	}
}

export default withRouter(WrapperComponent)
