import React, { PureComponent } from "react";
import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";

import '../../../styles/components/register/media/CropImage.scss'

export default class CropImage extends PureComponent {
	constructor(props){
		super(props);
		
		this.state = {
            input: props.input,
            src: props.src,
			crop: {
				width: 200,
				x: 0,
                y: 0,
                aspect: 29/20
			}
		}
    }
    
	onImageLoaded = (image, crop) => {
        this.imageRef = image;
	};
    
	onCropComplete = crop => {
        this.makeClientCrop(crop);
	};
    
	onCropChange = crop => {
        this.setState({ crop });
	};
    
	makeClientCrop(crop) {
        if (this.imageRef && crop.width && crop.height) {
            const croppedImageUrl = this.getCroppedImg(
                this.imageRef,
                crop,
                "newFile.jpeg"
            );
            this.setState({ croppedImageUrl });
        }
    }
    getCroppedImg(image, crop, fileName) {
        const canvas = document.createElement("canvas");
        const scaleX = image.naturalWidth / image.width;
        const scaleY = image.naturalHeight / image.height;

        canvas.width = crop.width * scaleX;
        canvas.height = crop.height * scaleY;

        const ctx = canvas.getContext("2d");
        
        ctx.drawImage(
            image,
            crop.x * scaleX,
            crop.y * scaleY,
            crop.width * scaleX,
            crop.height * scaleY,
            0,
            0,
            crop.width * scaleX,
            crop.height * scaleY
        );

        const base64Image = canvas.toDataURL('image/jpeg');
        return base64Image
    }

    switchAspect = () => {
        const { crop: { width, height, aspect }} = this.state
        if (this.imageRef) {
            let newAspect = 1/aspect
            let newWidth = width
            let newHeight = width / newAspect

            if(newHeight > this.imageRef.height) {
                newHeight = this.imageRef.height
                newWidth = newHeight * newAspect
            }
            else if(newWidth > this.imageRef.width) {
                newWidth = this.imageRef.width
                newHeight = newWidth / newAspect
            }

            let newCrop = {
                ...this.state.crop,
                x: 0,
                y: 0,
                aspect: newAspect,
                height: newHeight,
                width: newWidth,
            }

            this.setState({
                crop: newCrop,
            },
            this.onCropComplete(newCrop))
        }
    }
    
    render() {
        const { crop, croppedImageUrl, src, input } = this.state;
        const { onDone, onCancel } = this.props;
        
        return (
            <div className="crop-image">
		        {src && (
                    <ReactCrop
                        src={src}
                        crop={crop}
                        onImageLoaded={this.onImageLoaded}
                        onComplete={this.onCropComplete}
                        onChange={this.onCropChange}
                    />    
                )}
                {croppedImageUrl && (
                    <div>
                        <button className="btn btn__switch" onClick={this.switchAspect}>{(this.state.crop.aspect > 1) ? 'Change to portrait' : 'Change to landscape'}</button>
                    </div>
                )}
                <div className="btn__wrapper">
                    <button className="btn btn__done" onClick={() => croppedImageUrl ? (onDone && onDone(croppedImageUrl, input)) : onCancel()}>Done</button>
                    <button className="btn btn__cancel" onClick={() => onCancel && onCancel()}>Cancel</button>
                </div>
		    </div> 
	    );
	}    
}