import './HomeEvent.scss';
import React from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import { block } from 'bem-cn';

const b = block('HomeEvent');

export const HomeEvent = (props) => {
  const { name, image, from, type, className } = props;

  return (
    <div className={classNames(className, b(), 'col-6', 'col-lg-4', 'text-center', 'mb-4')}>
      <div className="text-left">
        <div className="image-size">
          <div className="hover-image">
            <div className="hover-image__background" />
            <img className="d-block" src={image} alt={name}/>
          </div>
        </div>
        <h5 className="text-uppercase mt-1">{name}</h5>
        <p className="m-0">{type}</p>
        <hr className="line-before-left m-0 mb-2"/>
        <p className="m-0 mt-2">{from}</p>
        <hr className="line-before-left m-0 mb-2"/>
        <Link to="/" className="d-inline-block">
          <img src={require('../../../assets/applaud-icon.svg')}/>
        </Link>
        <Link to="/" className="d-inline-block ml-2">
          <img src={require('../../../assets/comment-icon.svg')}/>
        </Link>
        <Link to="/" className="ml-2 float-right">
          <img src={require('../../../assets/like-icon.svg')}/>
        </Link>
        <Link to="/" className="float-right">
          <img src={require('../../../assets/share-icon.svg')}/>
        </Link>
      </div>
    </div>
  );
};
