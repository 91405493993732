import React, { Component } from 'react';

import ProfileCard from '../../common/ProfileCard/ProfileCard';
import { connect } from 'react-redux';
import {
  getArtistsByCategory,
  getSpotlightInfo
} from '../../../redux/modules/discover';

import './SpotlightTab.scss';
import classNames from 'classnames';
import { find } from 'lodash';
import { Container, Row } from 'reactstrap';
import { ModalPreloader } from '../../common/ModalPreLoader/ModalPreLoader';

//TODO
const events = [];

@connect(({ discover, compare }) => ({
  spotlightInfo: discover.spotlightInfo,
  itemsToCompare: compare.itemsToCompare,
}), {
  getSpotlightInfo,
})
export default class SpotlightTab extends Component {
  constructor(props) {
    super(props);
    this.state = {
      itemsToCompare: [],
    };
  }

  UNSAFE_componentWillMount() {
    this.props.getSpotlightInfo();
  }

  render() {
    const {
      spotlightInfo,
      sortBar,
    } = this.props;

    const spotlightResult = []
    const { spotlightDescriptions } = spotlightInfo;

    if (spotlightInfo){
      _.forOwn(spotlightInfo, (value, key) => {
        if (key !== 'spotlightDescriptions') {

          const categoryDescription = spotlightDescriptions[key] || '';
          spotlightResult.push(<div
            className={classNames('top-five-artists')}>
            <div className='title text-capitalize'>
              <b>{ key }</b> <span>{ categoryDescription }</span>
            </div>

            <div className='top-five-artists__wrapper'>
              {value.map((artistItem, index) => {
                if (artistItem && artistItem.is_complete) {
                  return (
                    <ProfileCard
                      key={index}
                      profile={artistItem}
                    />);
                }
              }
              )}
            </div>
          </div>)
        }
      })
    }

    return (
      <div className="SpotlightTab">
        {sortBar}
        <Container>
          {spotlightResult}
          {/* <div className={classNames('top-five-artists', { 'd-none': !(artist && artist.length) })}>
            <div className='title'>
              <b>Artists</b> <span>top 5 of the week</span>
            </div>
            {((!artist || !artist.length) && (!venue || !venue.length)) && <ModalPreloader/>}
            {artist && <div className='top-five-artists__wrapper'>
              {artist.map((artistItem, index) =>
                <ProfileCard
                  key={index}
                  profile={artistItem}
                />
              )}
            </div>}
          </div>
          <div className={classNames('top-five-venues', { 'd-none': !(venue && venue.length) })}>
            <div className='title'>
              <b>Venues</b> <span>top 5 of the week</span>
            </div>
            {venue && <div className='top-five-venues__wrapper'>
              {venue.map((venueItem, index) =>
                <ProfileCard
                  key={index}
                  profile={venueItem}
                />
              )}
            </div>}
          </div>
          <div className={classNames('top-five-events', { 'd-none': !(events && events.length) })}>
            <div className='title'>
              <b>Events</b> <span>top 5 of the week</span>
            </div>
            {events && <Row className='top-five-events__wrapper'>
              {
                events.map((eventsItem, index) =>
                  <ProfileCard
                    key={index}
                    profile={eventsItem}
                  />
                )
              }
            </Row>}
          </div> */}
        </Container>
      </div>
    );
  }
}
