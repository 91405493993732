import './AlertPopUp.scss';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import classNames from 'classnames/bind';

export class AlertPopUp extends Component {

  static propTypes = {
    isOpen: PropTypes.bool,
    title: PropTypes.string,
    text: PropTypes.string,
    okBtn: PropTypes.shape({
      label: PropTypes.string,
      onClick: PropTypes.func
    }),
    onClose: PropTypes.func
  };

  constructor(props) {
    super(props);
    this.state = {
      isOpen: props.isOpen || false
    };
  }

  show = () => {
    this.setState({ isOpen: true });
  };

  hide = () => {
    this.setState({ isOpen: false });
  };

  UNSAFE_componentWillReceiveProps(newProps) {
    const { isOpen } = newProps;
    if (this.props.isOpen !== isOpen) {
      this.setState({ isOpen });
    }
  }

  render() {
    const { title, text, okBtn, onClose } = this.props;

    return (
      <div>
        <Modal
          isOpen={this.state.isOpen}
          className={classNames('AlertPopUp__modal')}
          onExit={onClose}
          toggle={this.hide}
        >
          <ModalHeader toggle={this.hide}>
            {title || `ALERT MESSAGE`}
          </ModalHeader>
          <ModalBody>
            {text}
          </ModalBody>
          <ModalFooter>
            <button className="ok-btn" onClick={okBtn.onClick ? okBtn.onClick : this.hide}>{okBtn.label}</button>
          </ModalFooter>
        </Modal>
      </div>
    );
  }

}
