import React, { Component } from 'react'
import PropTypes from 'prop-types'

import { isBoolean } from 'lodash'
// import { MdDone } from 'react-icons/md'
import MdDone from 'react-icons/lib/md/done'


class PlansListCell extends Component {
    constructor(props) {
		super(props)

		this.state = {
			showInfo: false,
		}
    }
    
    toggleVisibility() {
		this.setState({ showInfo: !this.state.showInfo });
    }
    
    render() {
		const { value } = this.props;
		let displayValue = '';

	    if (isBoolean(value)) {
		    displayValue = value && <MdDone size={32} color="#17C317" />
        }
        else {
			if (value.indexOf(';') > 0) {			
				[bigText, smallText] = value.split(';')				
			}
			else if (value.indexOf('(') > 0 && value.indexOf(')') > 0){

				const bigText = value.replace(/ *\([^)]*\) */g, "")				
				const smallText = value.match(/\((.*)\)/)[1]

				displayValue = 
				<div>
					<span className="d-block text-center" onClick={this.toggleVisibility.bind(this)}>
						{bigText}
						{this.state.showInfo
							? <i className="fa fa-minus-circle" style={{ color: '#ffd210', marginLeft: '0.25rem' }} />
							: <i className="fa fa-plus-circle" style={{ color: '#ffd210', marginLeft: '0.25rem' }} />
						}
					</span>
					{this.state.showInfo && <small className="d-block text-center">{smallText}</small>}
				</div>
			}
			else {
				displayValue = value
			}
	    }
	    return (
            <div className="cell">
                {displayValue}
            </div>
        )
    }
}

PlansListCell.propTypes = {
	value: PropTypes.oneOf([PropTypes.bool, PropTypes.string]),
}

export default PlansListCell