import React from 'react'
import SVGInline from 'react-svg-inline'
import { TextField, IconButton } from 'material-ui'
import { fontSizes, defaultFontSize } from '../utils/constants'
import ReactPlayer from 'react-player'
const file_upload_icon = require('!!svg-inline-loader?classPrefix!../assets/file_upload.svg')


class VideoUrlField extends React.Component {
    constructor(props) {
        super(props);

        this.inputRef = null;

        this.state = {
            showError: false,
        }
    }

    isValid = (url) => {
        const youtube = /(?:https?:\/\/)?(?:(www|m)\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-)+)(?:\S+)?/;
        const vimeo = /(http|https)?:\/\/(www\.)?vimeo.com\/(?:channels\/(?:\w+\/)?|groups\/([^\/]*)\/videos\/|)(\d+)(?:|\/\?)/;
    
        if (!(youtube.test(url) || vimeo.test(url))) {
            return false
        }
        return true
    }

    handleChange = () => {
        const url = this.inputRef.input.value

        if (this.isValid(url)) {
            this.props.input.onChange(url)
        }
        else {
            this.setState({ showError: true })
        }
    }

    render() {
        const { input: { value } } = this.props;
        return <div style={{
                display: 'flex',
                flexDirection: 'column',
                height: '100%',
                justifyContent: 'center'
            }}>
        
            <div className={'embed-responsive embed-responsive-4by3'}>
                    <ReactPlayer
                      url={value}
                      onPlay={() => {}}
                      onPause={() => {}}
                      playing={false}
                      loop={() => {}}
                      className={'embed-responsive-item'}
                    />
            </div>                 

            <div style={{ display: 'flex', width: '100%', marginTop: '1.5rem', }}>
              <IconButton
                  style={{ fontSize: fontSizes.normal }}
                  onClick={this.handleChange}
                  >                            
                  <SVGInline
                      svg={file_upload_icon}
                      fill="#000"
                      width="1rem"
                      height="1rem"
                  />                            
              </IconButton>

              <TextField
                ref={element => { this.inputRef = element }}
                defaultValue={value}
                style={{ fontSize: defaultFontSize, flexGrow: 1 }}
                type="text"
                name="card_video_url"
                placeholder="URL Here"
                errorText={ this.state.showError ? "This url seems to be invalid" : null }
                onChange={() => this.setState({showError: false})}
              />        
            </div>  

            <div style={{ marginTop: '1.5rem'}}>
                <p 
                    className="picture-upload-title"
                    style={{ margin: 0, marginBottom: '10px'}}>
                    Link a Video to your card
                </p>
                <p className="picture-upload-desc">
                    Paste a Vimeo or YouTube link to upload your video. The first 10 seconds will play on your virtual card.
                </p>
            </div>              
        </div>
    }
}

export default VideoUrlField