import './AddMemberPopup.scss';
import React, { Component } from 'react';
import { Field } from 'redux-form'
import { Modal, ModalBody } from 'reactstrap';
import { get, debounce } from 'lodash'
import { TextFieldComponent } from '../../CommonComponent';
import defaultAvatar from '../../../assets/images/default_avatar.jpg';
import { NewMemberForm, TalentDropdown } from '../NewMeberForm/NewMemberForm';
import apiClient from '../../../helpers/apiClient';
import { Asterisk } from '../../common/Asterisk/Asterisk';
import { AlertPopUp } from '../../../components/common/AlertPopUp/AlertPopUp';
import Checkbox from 'material-ui/Checkbox';
import { defaultFontSize } from '../../../utils/constants';
import TextField from 'material-ui/TextField';
const DEBOUNCE_TIME = 800;

export class AddMemberPopup extends Component {
  addAutomaticallyPopup = `Please input first name, last name or email of person which have a profile on Gigworks`;
  addManuallyPopup = `With this button you can send invitiation to person which is not in Gigworks yet.`;

  initialState = {
      queryText: '',
      isOpenErrorPopUp: false,
      isOpen: false,
      users: [],
      isSearching: false,
      selectedMember: undefined,
      isAddManually: false,
      member: {
        first_name: '',
        last_name: '',
        email: '',
        phone_number: {},
        talent: 0,
        instrument: 0,
        is_manager: false
      }
    }

  constructor(props) {
    super(props);
    this.state = { ...this.initialState }
  }

  show = () => {
    this.state = { ...this.initialState }
    this.setState({ isOpen: true });
  };

  hide = () => {
    if(this.props.clearPopupCache) {
      this.props.clearPopupCache()
    }
    this.setState({ ...this.initialState }, () => console.log(this.state) )
  };

  sendInvitation = () => {
    let sendData = this.state.member;
    if (this.state.selectedMember) {
      const { isBand } = this.props;
      const fieldToChange = isBand ? 'instrument' : 'talent';
      sendData = { user_id: this.state.selectedMember.id }
      sendData[fieldToChange] = this.state.selectedMember[fieldToChange];
    }

    if (this.props.invitationInfo.hasOwnProperty('group_name') || this.props.invitationInfo.hasOwnProperty('manager_name')) {
      sendData['group_name'] = this.props.invitationInfo['group_name'];
      sendData['manager_name'] = this.props.invitationInfo['manager_name'];
    }

    apiClient.post('/api/members', sendData)
        .then((res) => {
          this.hide();
          const { onSuccesfullyAdded } = this.props;
          onSuccesfullyAdded();
      }).catch(err => {
				this.showValidationError('This user already exist on your members list.');
			})
  }

  showValidationError = error => {
		this.setState({
			errorsModalContent: error,
			isOpenErrorPopUp: true,
		})
  }

  onCloseErrorPopUp = () => {
		this.setState({
			errorsModalContent: '',
			isOpenErrorPopUp: false,
    })
	}

  chooseMember(member) {
    this.setState({
      isSearching: false,
      selectedMember: {
        is_manager: false,
        ...member
      }
    })
  }

  clearSelected() {
    this.setState({
      selectedMember: undefined
    })
  }

  onQueryChange = (_, value) => {
    this.setState({ queryText: value });

    this.fetchMembersList(value);
  }

  fetchMembersList = debounce(value => {
    if (value.length == 0) {
      this.setState({
        isSearching: false
      })
    } else {
      apiClient.get(`/api/users/member?q=${value}`)
        .then((res) => {
          //for situation when we execute this request but member is already selected [not show dropdown that time]
          if (!this.state.selectedMember) {
            this.setState({
              users: res.data,
              isSearching: true
            });
          }
      });
    }
  }, DEBOUNCE_TIME);

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { isOpen } = nextProps;
    if (this.props.isOpen !== isOpen) {
      this.setState({ isOpen });
    }
  }

	handleMemberChange = (member, index) => {
    this.setState({
      member: member
    })
  }

  changeSelectedMember = (value, fieldToChange) => {
    let _selectedMember = this.state.selectedMember;
    //we lost information about text representation of talent, change to id in next command
    if (isNaN(this.state.selectedMember['talent'])) {
      _selectedMember['talent_desc'] = this.state.selectedMember['talent'];
    }
    _selectedMember[fieldToChange] = value;
    this.setState({
      selectedMember: _selectedMember
    })
  }

  toggleManuallyAdd() {
    this.setState({
      isAddManually: !this.state.isAddManually
    })
  }

  isValid = () => {
    return this.isRequiredFieldsFilledWhenAutomatically() || this.isRequiredFieldsFilledWhenManually();
  }

  isRequiredFieldsFilledWhenAutomatically() {
    const { isBand } = this.props;
    return this.state.selectedMember && ((isBand && this.state.selectedMember.instrument > 0) || (!isBand && this.state.selectedMember.talent > 0));
  }

  isRequiredFieldsFilledWhenManually() {
    const { isBand } = this.props;
    const member = this.state.member;
    let isValid = false;
    if (member
      && member.first_name.length > 1
      && member.last_name.length > 1
      && ((isBand && member.instrument > 0) || (!isBand && member.talent > 0))
      && this.validateEmail(member.email)) {
        isValid = true;
    }

    return isValid;
  }

  validateEmail(email) {
    var re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  }

  render() {
    const { onClose, isBand, phoneCodeList } = this.props;

    return (
      <div>
        <Modal
          isOpen={this.state.isOpen}
          backdrop
          className="modal-dialog-centered"
          onExit={onClose}
          toggle={this.hide}
          size="lg"
        >
          <ModalBody>
            <div className="band-contact-info add-member-popup">
              <div className="band-contact-info__fields band-contact-info__container">
                <div className="band-contact-info__info-big-title">
                  Add New { isBand ? 'Band' : 'Group'} Member Automatically
                  <span className="fa-stack info-popup-window pl-2" data-title={this.addAutomaticallyPopup}>
                    <i className="fa fa-circle-thin fa-stack-2x"/>
                    <i className="fa fa-info fa-stack-1x"/>
                  </span>
                </div>
                {
                  this.state.selectedMember ?
                    <div>
                      <div className="add-member-popup-proposition field">
                        <div className="add-member-popup-proposition-element field">
                          <div className="avatar"
                            style={{
                              backgroundImage: `url(${ this.state.selectedMember.img_url ? this.state.selectedMember.img_url.replace(/^\"+|\"+$/g, '') : defaultAvatar })`,
                              backgroundRepeat: 'no-repeat',
                              backgroundSize: 'cover'
                            }}>
                          </div>
                          <div>
                            <p>{this.state.selectedMember.first_name} {this.state.selectedMember.last_name}</p> <span className="profession">{isNaN(this.state.selectedMember.talent) ? this.state.selectedMember.talent : this.state.selectedMember.talent_desc}</span> <span>{ this.state.selectedMember.location && JSON.parse(this.state.selectedMember.location).country }</span>
                          </div>
                          <div className="close" onClick={() => this.clearSelected()}>x</div>
                        </div>
                      </div>

                      <div
                        className="band-contact-info__fields band-contact-info__container add-member-popup-header"
                        style={{ marginLeft: '20px', marginTop: '1.5rem', width: '100%' }}>
                        <div className="band-contact-info__info">{ isBand ? 'Musical Instrument' : 'Talent' } <Asterisk/>
                          <span className="fa-stack info-popup-window pl-2" data-title={`Please select member's ${isBand ? 'instrument' : 'talent'}`}>
                            <i className="fa fa-circle-thin fa-stack-2x"/>
                            <i className="fa fa-info fa-stack-1x"/>
                          </span>
                        </div>
                        { isBand ?
                            <TalentDropdown
                              onChange={(option) => this.changeSelectedMember(option, 'instrument')}
                              searchEndpoint={'instrument-categories'}
                              hintText={'Select instrument'}
                            />
                          :
                            <TalentDropdown
                              onChange={(option) => this.changeSelectedMember(option, 'talent')}
                              searchEndpoint={'talent-categories'}
                              hintText={'Select talent'}
                            />
                        }
                      </div>
                    </div>
                  :
                  <TextField
                    name={`new_member_name`}
                    type="text"
                    value={this.state.queryText}
                    style={{ ...defaultFontSize, ...{ margin: '40px 0px 5px 20px', width: '100%' } }}
                    onChange={this.onQueryChange}
                    hintText="Enter Email or GigWorks User Name"
                  />
                }
                {
                  this.state.isSearching ?
                    <div className="add-member-popup-proposition">
                      {this.state.users.map((item, idx) => (
                        <div className="add-member-popup-proposition-element" onClick={() => this.chooseMember(item)}>
                            <div className="avatar"
                                style={{
                                backgroundImage: `url(${ item.img_url ? item.img_url.replace(/^\"+|\"+$/g, '') : defaultAvatar })`,
                                backgroundRepeat: 'no-repeat',
                                backgroundSize: 'cover'
                                }}
                            >
                            </div>
                            <div className="d-flex flex-wrap">
                                <p>{item.first_name} {item.last_name}</p>
                                <span className="profession">{item.talent}</span>
                                <span>{ item.location && JSON.parse(item.location).country }</span>
                          </div>
                        </div>
                      ))}
                    </div>
                  : null
                }
              </div>
              { !this.state.selectedMember ?
                  <div>
                    { this.state.isAddManually ?
                        <div>
                          <div className="band-contact-info__fields band-contact-info__container">
                            <div className="band-contact-info__info-big-title" onClick={this.toggleManuallyAdd.bind(this)}>
                              Add New { isBand ? 'Band' : 'Group'} Member Manually
                              <span className="fa-stack info-popup-window pl-2" data-title={this.addManuallyPopup}>
                                <i className="fa fa-circle-thin fa-stack-2x"/>
                                <i className="fa fa-info fa-stack-1x"/>
                              </span>
                            </div>
                          </div>
                          <NewMemberForm
                            member={this.state.member}
                            onMemberChange={this.handleMemberChange}
                            isBand={isBand}
                            phoneCodeList={phoneCodeList}
                          />
                        </div>
                      :
                        <div className="band-contact-info__fields band-contact-info__container">
                          <div className="band-contact-info__info-big-title add-member-popup-header" onClick={this.toggleManuallyAdd.bind(this)}>
                            <i className="fa fa-plus" aria-hidden="true"/> Add New { isBand ? 'Band' : 'Group'} Member Manually
                            <span className="fa-stack info-popup-window pl-2" data-title={this.addManuallyPopup}>
                              <i className="fa fa-circle-thin fa-stack-2x"/>
                              <i className="fa fa-info fa-stack-1x"/>
                            </span>
                          </div>
                        </div>
                    }
                  </div>
                : null
              }

              <div className="add-member-popup-button">
                <button onClick={this.sendInvitation} disabled={!this.isValid()}>
                  <i className="fa fa-paper-plane" aria-hidden="true"></i> Send Invitation
                </button>
              </div>
            </div>
          </ModalBody>
        </Modal>
        <div className="errors-popup">
					<AlertPopUp
						isOpen={this.state.isOpenErrorPopUp}
						title={`ALERT MESSAGE`}
						text={this.state.errorsModalContent}
						okBtn={{
							label: `Ok, I understand.`,
							onClick: this.onCloseErrorPopUp,
						}}
					/>
				</div>
      </div>
    );
  }
}
